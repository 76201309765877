import { RESET_ASSET_PERFORMANCE_ACTIVATION_REPORT, SEARCH_ASSET_PERFORMANCE_ACTIVATION, SEARCH_ASSET_PERFORMANCE_ACTIVATION_FAILED, SEARCH_ASSET_PERFORMANCE_ACTIVATION_SUCCESS } from "./actionTypes"

export const searchAssetPerformanceActivationData = (searchData) => {
    return {
        type: SEARCH_ASSET_PERFORMANCE_ACTIVATION,
        payload: searchData
    }
}

export const searchAssetPerformanceActivationDataSuccess = (manualActivationData) => {
    return {
        type: SEARCH_ASSET_PERFORMANCE_ACTIVATION_SUCCESS,
        payload: manualActivationData
    }
}

export const searchAssetPerformanceActivationDataFailed = (error) => {
    return {
        type: SEARCH_ASSET_PERFORMANCE_ACTIVATION_FAILED,
        payload: error
    }
}

export const resetAssetPerformanceActivation = () => {
    return {
        type: RESET_ASSET_PERFORMANCE_ACTIVATION_REPORT,
    }
}