import React from 'react'
import { decrypt } from '../helpers/encrypt_decrypt_helper';
import { Link, withRouter } from 'react-router-dom';
import moment from "moment";
import { connect, useDispatch } from 'react-redux';
import { closeAccessTransactionModal,activationRequestInfo } from '../store/viewActivations/actions';


const ActivationViewModal = (props) => {
    const dispatch = useDispatch();

    const handleToShowActivationHistory = (id) => {
         dispatch(activationRequestInfo(id));
    }

    const handleCloseModal=()=>{
        dispatch(closeAccessTransactionModal())
    }

  return (
    <div>
      {props.showTransactionModal && <div className="modal fade show" tabIndex="-1" id="modalLarge" style={{ "display": "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        {!props.modalViewLoader && props.requestData && props.requestData.length > 0 && props.requestData.map((data, index) => {
                            const parsedChildData = data.Multiline > 0 ? JSON.parse(data.ChildActivation) : null
                            return (<>
                                <div className="modal-header" style={{ backgroundColor: data.Multiline ? '#fff8fb' : '' }}>
                                    <div className='d-flex align-items-center'><h5 className="modal-title">CHANNEL TXN ID : <span className='text-muted'>{data.ChannelTxnID}</span></h5>{data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? <h6 className={`${data.ActivationSource === 1 ? 'text-danger' : 'text-primary'} mx-2`}>({data.ActivationSource === 1 ? 'Manual' : 'Automation'})</h6> : null}</div>
                                    <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={handleCloseModal}><em className="icon ni ni-cross"></em></div>
                                </div>
                                <div className="modal-body" style={{ backgroundColor: data.Multiline ? '#fff8fb' : '' }}>
                                    <div className='border-bottom mb-3'>
                                        <h6 className="title">PLAN INFORMATION</h6>
                                        <hr></hr>
                                        <div className='row'>
                                            <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Category</label></div>
                                            <div className='col-sm-9'><p>{data.PlanCategory}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Name</label></div>
                                            <div className='col-sm-9'><p>{data.PlanName}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Price</label></div>
                                            <div className='col-sm-3'><p>{data.CurrencyCode}{data.PlanPrice}</p></div>
                                            <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Month</label></div>
                                            <div className='col-sm-3 text-right'><p>{data.PlanMonth} month(s)</p></div>
                                        </div>

                                    </div>


                                    <div className='border-bottom my-3' >
                                        <h6 className="title">DEALER INFORMATION</h6>
                                        <hr></hr>
                                        <div className="row">
                                            <div className="col-sm-6 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Dealer Name</label>
                                                <p>{data.DealerName}</p>
                                            </div>
                                        </div>
                                        <div className="row g-4">
                                            <div className="col-sm-6">
                                                <div className='d-flex justify-content-between ' ><label className="form-label" htmlFor="accountNumber">TMobile Username</label><span>{data.TMobileUsername}</span></div>
                                                <div className='d-flex justify-content-between' ><label className="form-label" htmlFor="accountNumber">EPay Username</label><span>{data.EPayUsername}</span></div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className='d-flex justify-content-between ' ><label className="form-label" htmlFor="accountNumber">TMobile Password</label><p className=''><span>{decrypt(data.TMobilePassword)}</span></p></div>
                                                <div className='d-flex justify-content-between '><label className="form-label" htmlFor="accountNumber">EPay Password</label><p className=''><span>{decrypt(data.EPayPassword)}</span></p></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='border-bottom my-3' >
                                        <h6 className="title">CUSTOMER INFORMATION</h6>
                                        <hr></hr>
                                        <div className="row">
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">First Name</label>
                                                <p>{data.FirstName ?? data.FirstName}</p>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Middle Name</label>
                                                <p>{data.MiddleInitial ?? data.MiddleInitial}</p>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Last Name</label>
                                                <p>{data.LastName ?? data.LastName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Address 1</label>
                                                <p>{data.Address1 ?? data.Address1}</p>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Address 2</label>
                                                <p>{data.Address2 ?? data.Address2}</p>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">ZipCode</label>
                                                <p>{data.ZipCode ?? data.ZipCode}</p>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>


                                    <div className='border-bottom'>
                                        <h6 className="title">REQUEST INFORMATION</h6>
                                        <hr></hr>
                                        <div className={`row g-4 ${data.Multiline > 0 ? 'box-bor' : ''}`} style={{ top: '-33px !important' }}>
                                            <div className="col-sm-8">
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="IMEI">IMEI</label><p>{data.IMEI}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Serial">Serial #</label><p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{data.SerialNumber}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Request">Activation Type</label><p>{data.RequestType === 'PortIn' ? data.RequestType : 'Normal'}</p></div>
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Account">Account #</label><p>{data.AccountNumber}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Account">Account #</label><p>{data.AccountNumber}</p></div> : null}

                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Customer">Customer Email</label><p>{data.CustomerEmail}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Customer">WiFi Calling </label><p>{data.WiFiCalling && data.WiFiCalling ? 'Yes' : 'No'}</p></div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="SIM">SIM Type</label><p>{data.SimType}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Zip">Zip Code</label><p>{data.AreaCode}</p></div>
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{data.MSISDN}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{data.MSISDN}</p></div> : null}
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Order">Order #</label><p>{data.OrderNumber}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Order">Order # </label><p>{data.OrderNumber}</p></div> : null}
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="SIM">T-Mobile PIN</label><p>{data.SimPin}</p></div>
                                            </div>
                                            <div className='row m-0 p-0'>
                                                <div className='col-sm-3'><label className="form-label mb-0" htmlFor="Services">Add On Services</label></div>
                                                {data.AddOnServices && data.AddOnServices.split(',').map((item, index) => {
                                                    var serviceData = item.split('_');
                                                    return <><div className={`col-sm-7 ${(index > 0) ? 'offset-3' : ''}`}><span><em className="icon ni ni-check text-success"></em> {serviceData[0]}</span></div><div className='col-sm-2 text-right'><span>${serviceData[1]}</span></div></>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    {data.Multiline > 0 && <div className='border-bottom my-3'>
                                        <h6 className="title">CHILD ACTIVATION DATA</h6>
                                        <hr></hr>
                                        {parsedChildData !== null && parsedChildData.map((item, key) => {
                                            return (
                                                <div className='p-2 line-2'>
                                                    <h4>Line {item.LineNumber}</h4>
                                                    <div className='row mt-2'>
                                                        <div className="col-sm-6">
                                                            <div className='d-flex justify-content-between'><label className="form-label" htmlFor="IMEI">IMEI</label><p>{item.IMEI}</p></div>
                                                            <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Serial">Serial #</label><p className={item.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{item.SerialNumber}</p></div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className='d-flex justify-content-between'><label className="form-label" htmlFor="sim type">SIM Type</label><p>{item.SimType}</p></div>
                                                            <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{item.MSISDN}</p></div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-sm-3'><label className="form-label mb-0" htmlFor="Services">Add On Services</label></div>
                                                        {item.AddOnServices && item.AddOnServices.split(',').map((addon, childIndex) => {
                                                            var childServiceData = addon.split('_');
                                                            return <><div className={`col-sm-7 ${(childIndex > 0) ? 'offset-3' : ''}`}><span><em className="icon ni ni-check text-success"></em> {childServiceData[0]}</span></div><div className='col-sm-2 text-right'><span>${childServiceData[1]}</span></div></>
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>}
                                    {/* <div className="row line-2">
                                            <div className="col-sm-3"><label className="form-label" htmlFor="Mobile">IMEI</label></div>
                                            <div className="col-sm-2"><label className="form-label" htmlFor="Mobile">SIM Type</label></div>
                                            <div className="col-sm-5"> <label className="form-label" htmlFor="Portin">Serial #</label></div>
                                            <div className="col-sm-2"><label className="form-label" htmlFor="Portin">Mobile #</label></div>
                                            {childDataDisp}
                                        </div> */}
                                    {data.RequestType === "PortIn" && <div className='border-bottom my-3'>
                                        <h6 className="title">PORTIN INFORMATION</h6>
                                        <hr></hr>
                                        <div className="row">
                                            <div className="col-sm-4 "><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{data.MSISDN}</p></div>
                                            <div className="col-sm-4 "><label className="form-label" htmlFor="Portin">Portin Account #</label> <p>{data.PortInAccountNo}</p></div>
                                            <div className="col-sm-4 "> <label className="form-label" htmlFor="Portin">Portin PIN</label><p>{data.PortInPIN}</p></div>
                                        </div>
                                    </div>
                                    }
                                    <Link to="#" className="title my-2" style={{ 'textAlign': 'center', 'textDecoration': 'underline' }} onClick={() => handleToShowActivationHistory(data.ID)}>REQUEST JOURNEY</Link>
                                    {/* <button type="submit" className="btn btn-lg btn-primary" style={{'text-align':'center'}}  >More</button> */}
                                    {props.modalFlag && <div>
                                        <hr></hr>
                                        <div className="timeline">
                                            {/* <h6 className="timeline-head">November, 2019</h6> */}
                                            <div>
                                                <ul className="timeline-list">
                                                    {props.activationRequestHistory && props.activationRequestHistory.length > 0 && props.activationRequestHistory.map((val, key) => {
                                                        return (
                                                            <li className="timeline-item" key={key}>
                                                                <div className={`timeline-status ${val.IsError === 0 ? 'bg-success' : 'bg-danger'}`}></div>
                                                                <div className="mx-4">{val.CreatedDate ? moment(new Date(val.CreatedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm") : null} PST</div>
                                                                <div className="timeline-data">
                                                                    <div className=' d-flex'>
                                                                        {val.IsError === 0 ? <em className="icon ni ni-check-circle me-1" style={{ 'color': '#1ee0ac', 'fontSize': '20px' }}></em> : <em className="icon ni ni-cross-circle me-1" style={{ 'color': '#e85347', 'fontSize': '20px' }}></em>}
                                                                        <h6 className="timeline-title mb-1"> {val.IsError === 0 ? (val.Note.charAt(0) != '{' ? val.Note : JSON.parse(val.Note).Value !== '' ? JSON.parse(val.Note).Value : null) : JSON.parse(val.Note).jarAssetName != undefined ? val.ErrorDesc + ' (' + JSON.parse(val.Note).jarAssetName + ')' : val.ErrorDesc}</h6>
                                                                        {val.Note.charAt(0) != '{' ? '' : JSON.parse(val.Note).RequestStatus !== "" ? <span className='mx-4'>{JSON.parse(val.Note).RequestStatus === 18 ? '(' + JSON.parse(val.Note).MSISDN + ')' : JSON.parse(val.Note).RequestStatus === 12 ? '(' + val.OrderNumber + ')' : null}</span> : null}
                                                                    </div>

                                                                    <p className='ms-4'>{val.IsError === 1 ? JSON.parse(val.Note).ErrorDesc : ''}</p>
                                                                </div>

                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>}
                                    {/* {props.activationRequestHistory && props.activationRequestHistory.length === 0 && <h6 style={{ 'color': 'red' }}> No Data Found !</h6>} */}
                                </div>
                                {/* <div className="modal-footer bg-light"><span className="sub-text">Modal Footer Text</span></div> */}
                            </>
                            )
                        })}
                    </div>
                </div>
            </div >}
    </div>
  )
  }

const mapStateToProps = state => {
    const {showTransactionModal, activationRequestHistory,modalViewLoader ,requestData, modalFlag,  } = state.View
    return { showTransactionModal,activationRequestHistory,modalViewLoader, requestData, modalFlag, };
}

export default withRouter(connect(mapStateToProps, { activationRequestInfo,closeAccessTransactionModal})(ActivationViewModal));
