import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData, getList } from '../../helpers/fackBackend_Helper';
import { getPendingActivationSuccess, getPendingActivationFailed, addPendingActivationSuccess } from './actions';
import { PENDING_ACTIVATION_LIST, PENDING_ACTIVATION_ADD } from './actionTypes';

function* getPendingActivationData({ payload: { channelId } }) {
    try {
        const response = yield call(getList, '/pendingActivationListByChannelId/' + channelId);
        if (response.Status) {
            yield put(getPendingActivationSuccess(response.pendingData));
        } else {
            yield put(getPendingActivationFailed(response));
        }
    } catch (error) {
        yield put(getPendingActivationFailed(error));
    }
}

export function* watchGetPendingActivation() {
    yield takeEvery(PENDING_ACTIVATION_LIST, getPendingActivationData)
}

function* addPendingActivationData({ payload: { pendingData } }) {
    try {
        const response = yield call(addData, '/createActivationRequestBulk', pendingData);
        if (response) {
            yield put(addPendingActivationSuccess(response.response));
        } else {
            yield put(getPendingActivationFailed(response));
        }
    } catch (error) {
        yield put(getPendingActivationFailed(error));
    }
}

export function* watchAddPendingActivation() {
    yield takeEvery(PENDING_ACTIVATION_ADD, addPendingActivationData)
}

function* pendingActivationSaga() {
    yield all([
        fork(watchGetPendingActivation),
        fork(watchAddPendingActivation)
    ]);
}

export default pendingActivationSaga;

