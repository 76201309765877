import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import DataTable from 'react-data-table-component';
// import momentTimezone from 'moment-timezone';
import { userList, addUser, deleteUser, resetAlert, editUser, userPasswordChange } from "../store/user/actions";
import FilterComponent from "./FilterComponent";
import Spinner from "../components/layout/Spinner";
import { PERMISSIONS } from '../Permission/Permission';

function User(props) {
    const timezone = process.env.REACT_APP_TIMEZONE;
    const dispatch = useDispatch();
    const [allUserData, setAllUserData] = useState({
        name: "",
        email: "",
        username: "",
        password: "",
        dealerId: '',
        userRole: null,
        createdBy: '',
        isActive: 1,
    });
    const [passwordChangeData, setPasswordChangeData] = useState({
        userId: null,
        newPassword: "",
        confirmPassword: ""
    });

    const [deleteUserData, setDeleteUserData] = useState({
        modifiedByLoginID: null,
        modifiedDtTm: ''
    })
    const [RoleID, setRoleID] = useState(null);
    const [addSideForm, setAdddSideForm] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [CompanyId, setCompanyId] = useState('');
    const [loginID, setLoginID] = useState('');
    const [dealerID, setDealerID] = useState('');
    const [editId, setEditId] = useState();
    const [userStatus, setUserStatus] = useState(null)
    const [modalHeading, setModalHeading] = useState('New User');
    const [modalPara, setModalPara] = useState('Add information about new user.')
    const [showModal, setshowModal] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [userrole, setuserrole] = useState(false);
    const [name, setname] = useState(false);
    const [email, setemail] = useState(false);
    const [username, setusername] = useState(false);
    const [password, setpassword] = useState(false);
    const [newPassword, setnewPassword] = useState(false);
    const [confirmPassword, setconfirmPassword] = useState(false);
    const [popUp, setpopUp] = useState(false);
    const [popUpDeleteContent, setPopUpDeleteContent] = useState('');

    useEffect(() => {
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        const dealerId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : null;
        const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        setRoleID(roleId);
        setCompanyId(companyId);
        setLoginID(loginId);
        setDealerID(dealerId);

        setAllUserData(prevState => ({
            ...prevState,
            createdBy: loginId,
            dealerId: dealerId,
            userRole: (roleId === 33) ? 34 : null,
        }));
        props.userList(dealerId);
        setEditFlag(false);

        setDeleteUserData(prevState => ({
            ...prevState,
            modifiedByLoginID: loginId,
            modifiedDtTm: moment(new Date()).tz(timezone).format()
        }))
    }, []);

    // permission data manage
    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    const columns = [
        {
            name: '#',
            width: "40px",
            selector: row => row.serial,
        },
        {
            name: 'NAME',
            selector: row => row.name,
        },
        {
            name: 'EMAIL',
            selector: row => row.email,
        },
        {
            name: 'USERNAME',
            selector: row => row.userName,
        },
        {
            name: 'STATUS',
            selector: row => row.status,
        },
        {
            name: 'ROLE',
            selector: row => row.userRole,
        },
        {
            name: 'CREATED DATE',
            selector: row => row.createdDate,
        },
        filterArray && filterArray.length > 0 && filterArray[0].includes("edit_user", "delete_user", "reset_password") && {
            name: 'ACTION',
            width: '150px',
            selector: row => row.actions,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '0px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
    };

    const filteredItems = props.userData && props.userData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1

    );

    const filterData = filteredItems && filteredItems.map((user, key) => {
        return {
            serial: (<p>{key + 1}</p>),
            name: (<p style={{ "whiteSpace": "break-spaces" }}>{user.Name}</p>),
            email: (<a href={`mailto:${user.EmailID}`} target="_blank" rel="noreferrer" style={{ "whiteSpace": "break-spaces" }}>{user.EmailID}</a>),
            userName: (<p>{user.Username}</p>),
            status: (user.IsActive === 1 ? <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">Active</span> : <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">Inactive</span>),
            userRole: (<p>{user.UserRole}</p>),
            createdDate: (<p>{moment(new Date(user.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
            actions: (user.ID === loginID ? '' : <span><em className="icon icon-circle bg-warning ni ni-edit-alt" style={{ cursor: 'pointer' }} onClick={() => handleEditForm(user.ID)}></em>
                {user.IsActive === 1 ? <em className="icon icon-circle ni ni-trash-alt bg-danger mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleOpenPopUpAlertInActive(user.ID, 0)}></em> :
                    <em className="icon icon-circle icon ni ni-check-thick bg-success mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleOpenPopUpAlertActive(user.ID, 1)}></em>}
                <em className="icon icon-circle icon ni ni-lock-alt-fill bg-info" style={{ 'cursor': 'pointer' }} onClick={() => openPasswordModal(user.ID)}></em>
            </span>)
        }
    })

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        setAllUserData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }));

        setPasswordChangeData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }
    const handlekeyup = (e) => {
        document.getElementById('passwordError').innerText = "";
    }

    const handleAddForm = () => {
        dispatch(resetAlert())
        setEditFlag(false);
        setAdddSideForm(true);
        setAdddSideForm(true);
        setModalHeading('New User')
        setModalPara('Add information about new user.');
        setAllUserData(prevState => ({
            ...prevState,
            name: '',
            email: '',
            username: '',
            password: '',
            userRole: (RoleID === 33) ? 34 : null,
        }));
    }

    const handleEditForm = (userId) => {
        dispatch(resetAlert())
        setAdddSideForm(true);
        setModalHeading('Edit User')
        setModalPara('Edit information about the user');
        setEditFlag(true);
        setEditId(userId);
        var selectedUser = props.userData.filter(user => user.ID === userId)
        setAllUserData(prevState => ({
            ...prevState,
            name: selectedUser[0].Name,
            email: selectedUser[0].EmailID,
            username: selectedUser[0].Username,
            password: selectedUser[0].Password,
            userRole: selectedUser[0].RoleID
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setuserrole(false);
        setname(false);
        setemail(false);
        setusername(false);
        setpassword(false);
        const pass = document.getElementById('password').value;
        const cPass = document.getElementById('confirmPassword').value;
        if (allUserData.userRole === null && allUserData.name === '' && allUserData.email === '' && allUserData.username === '' && allUserData.password === '') {
            setuserrole(true);
            setname(true);
            setemail(true);
            setusername(true);
            setpassword(true);
            return false;
        } else {
            if (allUserData.userRole === null) {
                setuserrole(true);
                return false;
            }
            if (allUserData.name === '') {
                setname(true);
                return false;
            }
            if (allUserData.email === '') {
                setemail(true);
                return false;
            }
            if (allUserData.username === '') {
                setusername(true);
                return false;
            }
            if (allUserData.password === '') {
                setpassword(true);
                return false;
            }
            if (pass !== cPass) {
                document.getElementById('show').innerHTML = "Confirm password does not match!";
                return false;
            } else {
                setuserrole(false);
                setname(false);
                setemail(false);
                setusername(false);
                setpassword(false);
                dispatch(addUser(allUserData, dealerID));
                document.getElementById('show').innerHTML = null
            }
        }
    }

    const handleUpdate = (e) => {
        setname(false);
        setemail(false);
        e.preventDefault();
        allUserData.modifiedByLoginID = loginID;
        allUserData.modifiedDtTm = moment(new Date()).tz(timezone).format()
        if (allUserData.userRole === null && allUserData.name === '' && allUserData.email === '') {
            setname(true);
            setemail(true);
            return false;
        } else {
            if (allUserData.name === '') {
                setname(true);
                return false;
            }
            if (allUserData.email === '') {
                setemail(true);
                return false;
            }
            setuserrole(false);
            setname(false);
            setemail(false);
            dispatch(editUser(allUserData, editId, dealerID));
        }

    }

    const handleCancel = (e) => {
        setAdddSideForm(false);
        setuserrole(false);
        setname(false);
        setemail(false);
        setusername(false);
        setpassword(false);
        setnewPassword(false);
        setconfirmPassword(false);
        setshowModal(false);
    }

    const handleOpenPopUpAlertInActive = (userId, userStatus) => {
        dispatch(resetAlert());
        setpopUp(true);
        setPopUpDeleteContent('Are you sure, you want to Inactive the user ?')
        setEditId(userId);
        setUserStatus(userStatus);
    }

    const handleOpenPopUpAlertActive = (userId, userStatus) => {
        dispatch(resetAlert());
        setpopUp(true);
        setPopUpDeleteContent('Are you sure, you want to Active the user ?')
        setEditId(userId);
        setUserStatus(userStatus);
    }

    const handleStatusChange = () => {
        dispatch(deleteUser(editId, userStatus, deleteUserData, dealerID));
        setpopUp(false);
    }

    const openPasswordModal = (id) => {
        dispatch(resetAlert())
        setshowModal(true);
        setPasswordChangeData(prevState => ({
            ...prevState,
            userId: id,
            newPassword: "",
            confirmPassword: ""
        }))
    }

    const handleChangePassword = (e) => {
        e.preventDefault()
        const userPassword = document.getElementById('newPassword').value
        const userConfirmPassword = document.getElementById('confirmPassword').value
        setnewPassword(false);
        setconfirmPassword(false);
        if (passwordChangeData.newPassword === '' && passwordChangeData.confirmPassword === '') {
            setnewPassword(true);
            setconfirmPassword(true);
            return false;
        } else {
            if (passwordChangeData.newPassword === '') {
                setnewPassword(true);
                return false;
            }
            if (passwordChangeData.confirmPassword === '') {
                setconfirmPassword(true);
                return false;
            }
            if (userPassword !== userConfirmPassword) {
                document.getElementById('passwordError').innerHTML = "Confirm password does not match!"
                return false;
            } else {
                setnewPassword(false);
                setconfirmPassword(false);
                dispatch(userPasswordChange(passwordChangeData, dealerID));
            }
        }
    }

    if (props.modalMessage) {
        setTimeout(() => {
            setAdddSideForm(false);
            setshowModal(false);
        }, 1500);
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <div className="nk-block-head-content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h3 className="nk-block-title page-title">Users</h3>
                {filterArray && filterArray.length > 0 && filterArray[0].includes("add_user") && <>
                    <div
                        data-target="addProduct"
                        className="toggle btn btn-icon btn-primary d-md-none"
                        onClick={handleAddForm}
                    >
                        <em className="icon ni ni-plus"></em>
                    </div>
                    <div
                        data-target="addProduct"
                        className="toggle btn btn-primary d-none d-md-inline-flex"
                        onClick={handleAddForm}
                    >
                        <em className="icon ni ni-plus"></em>
                        <span>Add User</span>
                    </div>
                </>}
            </div>
            {/* card */}
            <div className="card p-2" style={{ marginTop: '12px' }}>
                {props.Message &&
                    <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.Message}</strong></div>}
                <div className="card-inner p-0">
                    <DataTable
                        pagination
                        columns={columns}
                        data={filterData}
                        customStyles={customStyles}
                        subHeaderComponent={subHeaderComponent}
                        progressPending={props.loading}
                        progressComponent={<Spinner />}
                        subHeader
                        striped
                        responsive
                    />
                </div>
                {addSideForm && <div className="toggle-overlay" data-target="addProduct"></div>}
            </div>
            {/* slideform */}
            {addSideForm && <div className="nk-add-product toggle-slide toggle-slide-right toggle-screen-any content-active" data-content="addProduct"
                data-toggle-screen="any" data-toggle-overlay="true" data-toggle-body="true" data-simplebar="init" style={{ "zIndex": "1052" }}>
                <div className="simplebar-wrapper" style={{ 'margin': '-24px' }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset" style={{ 'right': '0px', 'bottom': '0px' }}>
                            <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content"
                                style={{ 'height': '100%', 'overflow': 'hidden scroll' }}>
                                <div className="simplebar-content" style={{ 'padding': '24px' }}>
                                    <div className="nk-block-head">
                                        <div className="nk-block-head-content">
                                            {/* <span className="hideform" onClick={() => setAdddSideForm(false)}><em className="icon ni ni-arrow-right"></em> </span> */}
                                            <h5 className="nk-block-title">{modalHeading}</h5>
                                            <div className="nk-block-des">
                                                <p>{modalPara}</p>
                                                {props.modalMessage && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.modalMessage}</strong></div></div>}
                                                {props.apiError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <div className="row g-3">
                                            <form onSubmit={editFlag ? handleUpdate : handleSubmit} method="post" >
                                                {RoleID === 1 && <div className="col-lg-12">
                                                    <div className="form-group">
                                                        {editFlag ? <ul className="custom-control-group g-3 align-center flex-wrap">
                                                            <li>
                                                                <div className="custom-control custom-radio">
                                                                    {(allUserData.userRole === 1 ? <input type="radio" className="custom-control-input" name="userRole" id="admin" value={1} onChange={handleChange} checked disabled={true} />
                                                                        : <input type="radio" className="custom-control-input" name="userRole" id="admin" value={1} onChange={handleChange} disabled={true} />)}
                                                                    <label className="custom-control-label" htmlFor="admin" style={{ 'color': userrole ? 'red' : '' }}>Admin</label></div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-radio">
                                                                    {(allUserData.userRole === 2 ? <input type="radio" className="custom-control-input" name="userRole" id="user" value={2} onChange={handleChange} checked disabled={true} />
                                                                        : <input type="radio" className="custom-control-input" name="userRole" id="user" value={2} onChange={handleChange} disabled={true} />)}
                                                                    <input type="radio" className="custom-control-input" name="userRole" id="user" value={2} onChange={handleChange} disabled={true} />
                                                                    <label className="custom-control-label" htmlFor="user" style={{ 'color': userrole ? 'red' : '' }}>User</label></div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-radio">
                                                                    {(allUserData.userRole === 30 ? <input type="radio" className="custom-control-input" name="userRole" id="MIS" value={30} onChange={handleChange} checked disabled={true} />
                                                                        : <input type="radio" className="custom-control-input" name="userRole" id="MIS" value={30} onChange={handleChange} disabled={true} />)}
                                                                    <input type="radio" className="custom-control-input" name="userRole" id="MIS" value={30} onChange={handleChange} disabled={true} />
                                                                    <label className="custom-control-label" htmlFor="MIS" style={{ 'color': userrole ? 'red' : '' }}>MIS</label></div>
                                                            </li>
                                                            <li>
                                                                <div className="custom-control custom-radio">
                                                                    {(allUserData.userRole === 31 ? <input type="radio" className="custom-control-input" name="userRole" id="support" value={31} onChange={handleChange} checked disabled={true} />
                                                                        : <input type="radio" className="custom-control-input" name="userRole" id="support" value={31} onChange={handleChange} disabled={true} />)}
                                                                    <input type="radio" className="custom-control-input" name="userRole" id="support" value={31} onChange={handleChange} disabled={true} />
                                                                    <label className="custom-control-label" htmlFor="support" style={{ 'color': userrole ? 'red' : '' }}>Support</label></div>
                                                            </li>
                                                        </ul>
                                                            : <ul className="custom-control-group g-3 align-center flex-wrap">
                                                                <li><div className="custom-control custom-radio">
                                                                    {(allUserData.userRole === 1 ? <input type="radio" className="custom-control-input" name="userRole" id="admin" value={1} onChange={handleChange} checked />
                                                                        : <input type="radio" className="custom-control-input" name="userRole" id="admin" value={1} onChange={handleChange} />)}
                                                                    <label className="custom-control-label" htmlFor="admin" style={{ 'color': userrole ? 'red' : '' }}>Admin</label></div></li>
                                                                <li><div className="custom-control custom-radio">
                                                                    {(allUserData.userRole === 2 ? <input type="radio" className="custom-control-input" name="userRole" id="user" value={2} onChange={handleChange} checked />
                                                                        : <input type="radio" className="custom-control-input" name="userRole" id="user" value={2} onChange={handleChange} />)}
                                                                    <input type="radio" className="custom-control-input" name="userRole" id="user" value={2} onChange={handleChange} />
                                                                    <label className="custom-control-label" htmlFor="user" style={{ 'color': userrole ? 'red' : '' }}>User</label></div></li>
                                                                <li><div className="custom-control custom-radio">
                                                                    {(allUserData.userRole === 30 ? <input type="radio" className="custom-control-input" name="userRole" id="MIS" value={30} onChange={handleChange} checked />
                                                                        : <input type="radio" className="custom-control-input" name="userRole" id="MIS" value={30} onChange={handleChange} />)}
                                                                    <input type="radio" className="custom-control-input" name="userRole" id="MIS" value={30} onChange={handleChange} />
                                                                    <label className="custom-control-label" htmlFor="MIS" style={{ 'color': userrole ? 'red' : '' }}>MIS</label></div></li>
                                                                <li><div className="custom-control custom-radio">
                                                                    {(allUserData.userRole === 31 ? <input type="radio" className="custom-control-input" name="userRole" id="support" value={31} onChange={handleChange} checked />
                                                                        : <input type="radio" className="custom-control-input" name="userRole" id="support" value={31} onChange={handleChange} />)}
                                                                    <input type="radio" className="custom-control-input" name="userRole" id="support" value={31} onChange={handleChange} />
                                                                    <label className="custom-control-label" htmlFor="support" style={{ 'color': userrole ? 'red' : '' }}>Support</label></div></li>
                                                            </ul>}
                                                    </div>
                                                </div>}
                                                <div className="col-12 my-2">
                                                    <div className="form-group"><label className="form-label" htmlFor="name">Name</label>
                                                        <div className="form-control-wrap"><input type="text" name="name" value={allUserData.name} className="form-control" id="name" onChange={handleChange} placeholder="Name" autoComplete="off" style={{ 'border': name ? '1px solid red' : '' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <div className="form-group"><label className="form-label" htmlFor="email">Email</label>
                                                        <div className="form-control-wrap"><input type="email" className="form-control" value={allUserData.email} onChange={handleChange} id="email" name="email" placeholder="Email" autoComplete="off" style={{ 'border': email ? '1px solid red' : '' }}
                                                        /></div>
                                                    </div>
                                                </div>
                                                <div className="col-12 my-2">
                                                    <div className="form-group"><label className="form-label" htmlFor="username">Username
                                                    </label>
                                                        <div className="form-control-wrap"><input type="text" name="username" value={allUserData.username} pattern="^[a-zA-Z0-9_]*$" className="form-control" id="username" onChange={handleChange} disabled={editFlag} placeholder="Username" autoComplete="off" style={{ 'border': username ? '1px solid red' : '' }} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {!editFlag && <div className="col-12 my-2">
                                                    <div className="form-group"><label className="form-label" htmlFor="password">Password</label>
                                                        <div className="form-control-wrap"><input type="password" name="password" className="form-control" id="password" onChange={handleChange} placeholder="Password" autoComplete="off" style={{ 'border': password ? '1px solid red' : '' }} /></div>
                                                    </div>
                                                </div>}
                                                {!editFlag && <div className="col-12 my-2">
                                                    <div className="form-group"><label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                                        <div className="form-control-wrap"><input type="password" name="confirmPassword" className="form-control" id="confirmPassword" placeholder="Confirm Password" autoComplete="off" onChange={handleChange} />
                                                        </div>
                                                        <p style={{ 'color': 'red' }} id="show"></p>
                                                    </div>
                                                </div>}
                                                <div className="d-flex my-3">
                                                    <button type='submit' disabled={props.loading} className="btn btn-primary">{props.loading === true && <span
                                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                        <span>Save</span> </button>
                                                    <button className="btn btn-secondary mx-2" onClick={handleCancel}>Cancel</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="simplebar-placeholder" style={{ 'width': 'auto', 'height': '864px' }}></div>
                </div>
                <div className="simplebar-track simplebar-horizontal" style={{ 'visibility': 'hidden' }}>
                    <div className="simplebar-scrollbar" style={{ 'width': '0px', 'display': 'none' }}></div>
                </div>
                <div className="simplebar-track simplebar-vertical simplebar-hover" style={{ 'visibility': 'visible' }}>
                    <div className="simplebar-scrollbar" style={{ 'height': '606px', 'display': 'block', 'transform': 'translate3d(0px, 0px, 0px)' }}>
                    </div>
                </div>

            </div>
            }
            {/* modal form  */}
            {showModal && <div className="modal fade show" tabIndex="-1" id="modalTop" style={{ 'display': 'block' }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-top" role="document">
                    <div className="modal-content"><div className="modal-header">
                        <h5 className="modal-title">Reset Password</h5>
                        <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setshowModal(false)}><em className="icon ni ni-cross"></em></div>
                    </div>
                        <div className="modal-body">
                            {props.modalMessage && <div className="example-alert"><div className="alert alert-primary alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.modalMessage}</strong></div></div>}
                            <form className="form-validate is-alter" onSubmit={handleChangePassword}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="newPassword">Password</label>
                                    <div className="form-control-wrap">
                                        <input type="password" className="form-control" name="newPassword" id="newPassword" onChange={handleChange} placeholder="Password" autoComplete="off" style={{ 'border': newPassword ? '1px solid red' : '' }} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                    <div className="form-control-wrap">
                                        <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" onChange={handleChange} onInput={handlekeyup} placeholder="Confirm Password" autoComplete="off" style={{ 'border': confirmPassword ? '1px solid red' : '' }} />
                                    </div>
                                    <p style={{ 'color': 'red' }} id="passwordError"></p>
                                </div>
                                <div className="form-group text-right">
                                    <button type="submit" disabled={props.loading} className="btn btn-lg btn-primary">{props.loading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}<span>Update</span></button>
                                    <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleCancel}>Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>}

            {/* pop up */}
            {popUp && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
                        </div>
                        <div className="modal-body">
                            <p>{popUpDeleteContent}</p>
                        </div>
                        <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={() => handleStatusChange()}>Yes</div></div>
                    </div>
                </div>
            </div>}

        </div>
    );
}
const mapStateToProps = state => {
    const { userData, apiError, Message, loading, userId, modalMessage } = state.user;
    return { userData, apiError, Message, loading, userId, modalMessage };
}
// export default Users;
export default withRouter(connect(mapStateToProps, { userList, addUser, resetAlert, deleteUser, editUser, userPasswordChange })(User));
