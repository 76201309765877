import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { CHART_DATA, MONTHTLY_CHART, OVERALL_CHART, COUNTACTIVATIONBY_NETWORKTYPE, WIDGET_DATA, SEARCH_ACTIVATION_BY_SOURCE, CHANNEL_ACTIVATION_CHART_DATA, GET_ACTIVATION_COUNT_BY_USER } from './actionTypes';
import { chartDataSuccessful, monthlyChartSuccessful, dashboardApiError, overAllChartSuccessful, countActivationByNetworkTypeSuccessful, widgetDataSuccessFull, searchActivationBySourceSuccessfull, searchActivationBySourceFailed, channelActivationChartDataSuccessfull, getUserActivationCountStatusSuccess, getUserActivationCountStatusFailed } from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* getAllChartData({ payload: { companyId, dealerId } }) {
    try {
        const response = yield call(getList, '/getChartData/' + companyId + '/' + dealerId);
        if (response.Status) {
            yield put(chartDataSuccessful(response.allChartData));
        }
        else {
            yield put(dashboardApiError(response.Message));
        }
    } catch (error) {
        yield put(dashboardApiError(error));
    }
}

export function* watchGetAllChartData() {
    yield takeEvery(CHART_DATA, getAllChartData)
}

function* getMonthlyDashboardData({ payload: { companyId, dealerId } }) {
    try {
        const response = yield call(getList, '/monthlyActivationChartData/' + companyId + '/' + dealerId);
        if (response.Status) {
            yield put(monthlyChartSuccessful(response.monthlyActivationChartData[0]));
        }
        else {
            yield put(dashboardApiError(response.Message));
        }
    } catch (error) {
        yield put(dashboardApiError(error));
    }
}

export function* watchGetMonthlyDashboardData() {
    yield takeEvery(MONTHTLY_CHART, getMonthlyDashboardData)
}

function* getOverAllDashboardData({ payload: { companyId, dealerId } }) {
    try {
        const response = yield call(getList, '/totalActivationChartData/' + companyId + '/' + dealerId);
        if (response.Status) {
            yield put(overAllChartSuccessful(response.totalActivationChartData[0]));
        }
        else {
            yield put(dashboardApiError(response.Message));
        }
    } catch (error) {
        yield put(dashboardApiError(error));
    }
}

export function* watchGetOverAllDashboardData() {
    yield takeEvery(OVERALL_CHART, getOverAllDashboardData)
}

function* getCountActivationByNetworkDashboardData({ payload: companyId }) {
    try {
        const response = yield call(getList, '/activationByNetworkType/' + companyId);
        if (response.Status) {
            yield put(countActivationByNetworkTypeSuccessful(response.countActivationByNetworkType));
        }
        else {
            yield put(dashboardApiError(response.Message));
        }
    } catch (error) {
        yield put(dashboardApiError(error));
    }
}

export function* watchGetCountActivationByNetworkDashboardData() {
    yield takeEvery(COUNTACTIVATIONBY_NETWORKTYPE, getCountActivationByNetworkDashboardData)
}

function* getWidgetData({ payload: { companyId, dealerId } }) {
    try {
        const response = yield call(getList, '/activationRequestCountOfAutomationAndMannual/' + companyId + '/' + dealerId);
        if (response.Status) {
            yield put(widgetDataSuccessFull(response.automationAndMannualData));
        } else {
            yield put(dashboardApiError(response))
        }

    } catch (error) {
        yield put(dashboardApiError(error));
    }
}

export function* watchWidgetData() {
    yield takeEvery(WIDGET_DATA, getWidgetData)
}

function* getSearchDataBySource({ payload: searchData }) {
    try {
        const response = yield call(addData, '/activationDataBySource', searchData);
        if (response.Status) {
            yield put(searchActivationBySourceSuccessfull(response.activationData));
        } else {
            yield put(searchActivationBySourceFailed(response));
        }
    } catch (error) {
        yield put(searchActivationBySourceFailed(error));
    }
}

export function* watchSearchDataBySource() {
    yield takeEvery(SEARCH_ACTIVATION_BY_SOURCE, getSearchDataBySource);
}

function* getChannelChartData({ payload: { companyId, dealerId } }) {
    try {
        const response = yield call(getList, '/getChannelChartData/' + companyId + '/' + dealerId);
        if (response.Status) {
            yield put(channelActivationChartDataSuccessfull(response.allChartData));
        } else {
            yield put(dashboardApiError(response))
        }

    } catch (error) {
        yield put(dashboardApiError(error));
    }
}

export function* watchGetChannelChartData() {
    yield takeEvery(CHANNEL_ACTIVATION_CHART_DATA, getChannelChartData)
}

function* getActivationCountStatusDataResponse({ payload: {companyId,dealerId} }) {
    try {
        const response = yield call(getList, '/getUserActivationCount/' + companyId +'/'+ dealerId);
        if (response.Status) {
            yield put(getUserActivationCountStatusSuccess(response.allUserActivationCount));
        } else {
            yield put(getUserActivationCountStatusFailed(response.Message))
        }

    } catch (error) {
        yield put(getUserActivationCountStatusFailed(error));
    }
}

export function* watchActivationCountStatusDataResponse() {
    yield takeEvery(GET_ACTIVATION_COUNT_BY_USER, getActivationCountStatusDataResponse)
}

function* dashboardSaga() {
    yield all([
        fork(watchGetMonthlyDashboardData),
        fork(watchGetOverAllDashboardData),
        fork(watchGetCountActivationByNetworkDashboardData),
        fork(watchWidgetData),
        fork(watchSearchDataBySource),
        fork(watchGetAllChartData),
        fork(watchGetChannelChartData),
        fork(watchActivationCountStatusDataResponse),
    ]);
}

export default dashboardSaga;