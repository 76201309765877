import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { errorCodeList } from '../store/viewActivations/actions';
import Papa from "papaparse";
import { validateRequestForBulkCancelation, formReset, updateRemarkForBulkCancelation, reset ,getErrorCodeList} from '../store/bulkCancelation/actions';
import DataTable from 'react-data-table-component';
import Spinner from '../components/layout/Spinner';

const BASE_URL = process.env.REACT_APP_SITE_BASE_URL;

const BulkCancelation = (props) => {
    const dispatch = useDispatch();
    const inputRef = useRef(null);
    const [remarkError, setRemarkError] = useState(false);
    const [errorCodeField, setErrorCodeField] = useState(false);
    const [invalidFileError, setinvalidFileError] = useState('');
    const [filterText, setFilterText] = useState("");
    const [popUp, setpopUp] = useState(false);
    const [dataSearch, setDataSearch] = useState(false);

    const [remarkContent, setremarkContent] = useState({
        remark: '',
        errorCode: '',
        requestIds: [],
        modifiedBy: ''
    });

    useEffect(() => {
        dispatch(getErrorCodeList())
        dispatch(formReset())
    }, [])

    useEffect(() => {
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        setremarkContent(prevState => ({
            ...prevState,
            modifiedBy: loginId
        }));
    }, [])

    useEffect(() => {
        if (props.bulkCancelationResult && props.bulkCancelationResult.length > 0) {
            props.bulkCancelationResult.filter(item => item.ID && item.Status).map(item => {
                setremarkContent(prev => ({
                    ...prev,
                    requestIds: [...prev.requestIds, item.ID]
                }))
            }
            )
        }

    }, [props.bulkCancelationResult])

    useEffect(() => {
        if (props.updateBulkCancelationMessage) {
            dispatch(reset())
            inputRef.current.value = null;
            setremarkContent(prev => ({
                ...prev,
                remark: '',
                errorCode: '',
                requestIds: [],
            }))
        }
    }, [props.updateBulkCancelationMessage])

    const columns = [
        {
            name: "#",
            width: "60px",
            selector: row => row.serial,
        },
        {
            name: 'CHANNEL TXN ',
            // width: "180px",
            width: 'calc(100% - 140px)',
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'STATUS',
            width: "80px",
            selector: row => row.Status,
        },
    ]

    const filteredItems = props.bulkCancelationResult && props.bulkCancelationResult.length > 0 && props.bulkCancelationResult.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var filterData = filteredItems && filteredItems.length > 0 ? filteredItems.map((data, key) => {
        return {
            serial: key + 1,
            ChannelTxnID: data.ChannelTxnId,
            Status: (<p className='validClass' style={data.Status == 1 ? { color: '#2ecc71' } : { color: 'red' }}>{data.Status == 1 ? 'Valid' : 'Invalid'}</p>),
        }
    }) : [];
    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        var validFalg = false;
        if (!remarkContent.errorCode) {
            validFalg = true
            setErrorCodeField(true)
        }
        if (!remarkContent.remark) {
            validFalg = true
            setRemarkError(true)
        }

        if (!validFalg) {
            setpopUp(true)
        }
    }

    const handleUpdateBulkTxn=()=>{
       dispatch(updateRemarkForBulkCancelation(remarkContent))
       setpopUp(false)
    }

    const handleErrorChange = (e) => {
        const value = e.target.value;
        setErrorCodeField(false)
        setremarkContent(prev => ({
            ...prev,
            errorCode: value
        }))
    }


    const handleRemark = (e) => {
        const value = e.target.value;
        setRemarkError(false)
        setremarkContent(prev => ({
            ...prev,
            remark: value
        }))
    }

    const exportToCSV = async () => {
        let url = `${BASE_URL}/assets/csv/Bulk-Cancel-file.csv`;
        let link = document.createElement('a');
        link.href = url;
        link.download = 'Bulk-Cancel-file.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleExcelUpload = (e) => {
        const fileReader = new FileReader();
        let file = e.target.files[0];
        setinvalidFileError('')
        setRemarkError(false)
        setErrorCodeField(false)
        setremarkContent(prev => ({
            ...prev,
            requestIds: []
        }))

        if (file) {
            const fileType = file.type;
            if (fileType !== 'text/csv' && !file.name.endsWith('.csv')) {
                setinvalidFileError('Please upload a valid .csv file !')
                e.target.value = '';
                dispatch(formReset())
                setDataSearch(false)
            } else {
                setinvalidFileError('')
                fileReader.onload = async ({ target }) => {
                    const csvOutput = Papa.parse(target.result, { header: true });
                    const parsedData = csvOutput?.data;
                    const columns = Object.keys(parsedData[0]);

                    const specifiedColumn = ['CHANNEL TXN'];
                    const isValidCsvHeading = specifiedColumn.every((item) => columns.includes(item));
                    if (isValidCsvHeading) {
                        let uploadData = parsedData.filter(item => item['CHANNEL TXN'] != '').map((item1) => item1['CHANNEL TXN']);
                        setDataSearch(true)
                        dispatch(validateRequestForBulkCancelation(uploadData))
                    }
                    else {
                        setinvalidFileError('Invalid Csv Selected!')
                        dispatch(formReset())
                        setDataSearch(false)
                    }

                };
                fileReader.readAsText(file);
            }

        }
    }


    const handleCancel = () => {
        setinvalidFileError('')
        setRemarkError(false)
        setErrorCodeField(false)
        inputRef.current.value = null;
        setremarkContent(prev => ({
            ...prev,
            remark: '',
            errorCode: '',
            requestIds: [],
        }))
        dispatch(formReset())
        dispatch(reset())
        setDataSearch(false)
    }

    return (
        <div className="nk-block-head-content">
            <div className='d-flex justify-content-between'>
                <h3 className="nk-block-title page-title">Bulk Cancellation</h3>
                <Link to="#" className="btn btn-success mt-2" onClick={() => exportToCSV()}><em className="icon ni ni-download"></em>Download Sample File</Link>
            </div>
            <div className='card p-2 mt-2'>
                <div className="card-inner p-0" >
                    <div className="nk-block">
                        <form className='form-validate is-alter' onSubmit={handleSubmit}>
                            <div className="row g-3">
                                <div className="col-sm-4 col-md-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="errorCode" >Errors</label>
                                        <div className="form-control-wrap">
                                            <select
                                                name="errorCode"
                                                className="form-control"
                                                id="errorCode"
                                                onChange={handleErrorChange}
                                                value={remarkContent.errorCode}
                                                style={{ 'border': errorCodeField ? '1px solid red' : '' }}
                                            >
                                                <option value="" name="select">Select</option>
                                                {props.errorCodeList && props.errorCodeList.map(error => (
                                                    <option value={error.ErrorCode} name="errorCode" key={error.ErrorCode}>{error.ErrorDesc}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-4 col-md-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="remark">Remark</label>
                                        <div className="form-control-wrap">
                                            <textarea className="form-control bulk_textarea"
                                                id="remark" placeholder='Enter Remark here....' name="remark" value={remarkContent.remark} onChange={handleRemark} style={{ 'border': remarkError ? '1px solid red' : '', }}></textarea>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-sm-4 col-md-4">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="customFileLabel">Upload File</label>
                                        <div className="form-control-wrap">
                                            <div className="container d-flex" style={{ padding: 'unset' }}>
                                                <input
                                                    type="file"
                                                    className="form-control-file"
                                                    onChange={handleExcelUpload}
                                                    ref={inputRef}
                                                />

                                                {invalidFileError !== "" ? <p style={error}>{invalidFileError}</p> : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {filterData && filterData.length > 0 && dataSearch ? <div className=''>
                                    <div style={{ color: '#FF0000' }}>Note : <i>Only valid txn will get cancelled.</i></div>
                                </div> : ''}

                                {props.updateBulkCancelationError && <div className="alert alert-danger alert-icon" style={alrtmsg}><em className="icon ni ni-cross-circle"></em> <strong>{props.updateBulkCancelationError}</strong></div>}

                                {dataSearch ? <div className='bulkCanceldataTable'>
                                    <DataTable pagination={false} columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                                </div> : ''}
                                {filterData && filterData.length > 0 && dataSearch ? <div className="form-group text-right">
                                    <hr></hr>
                                    <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleCancel}>Cancel</button>
                                    {remarkContent && remarkContent.requestIds && remarkContent.requestIds.length > 0 && <button type='submit' disabled={props.updateBulkCancelatiloading} className="btn btn-lg btn-primary">{props.updateBulkCancelatiloading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Submit</span> </button>}
                                </div> : ''}


                                {/* pop up */}
                                {popUp && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                                    <div className="modal-dialog" role="document">
                                        <div className="modal-content">
                                            <div className="modal-header bg-danger text-white">
                                                <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
                                            </div>
                                            <div className="modal-body">
                                                <p>Are you sure you want to cancel these txn?</p>
                                            </div>
                                            <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={() => handleUpdateBulkTxn()}>Yes</div></div>
                                        </div>
                                    </div>
                                </div>}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {props.updateBulkCancelationMessage && <div className="alert alert-success alert-icon" style={alrtmsg}><em className="icon ni ni-check-circle"></em> <strong>{props.updateBulkCancelationMessage}</strong></div>}
        </div>
    )
}

// Style here
const styles = {
    version: {
        fontSize: '16px',
        fontWeight: '400',
        letterSpacing: '1px',
    },
    textarea: {
        resize: 'none'
    },
    commonError: {
        color: "#f00",
    }
}

const error = {
    position: "absolute",
    top: "25px",
    float: "left",
    color: "#f00",
};

const alrtmsg = {
    top: "7px",
};


const mapStateToProps = state => {
    const { bulkCancelationResult, loading, updateBulkCancelatiloading, updateBulkCancelationMessage, updateBulkCancelationError,errorCodeList } = state.bulkCancelation
    return {bulkCancelationResult, loading, updateBulkCancelatiloading, updateBulkCancelationMessage, updateBulkCancelationError,errorCodeList };
}

export default withRouter(connect(mapStateToProps, { validateRequestForBulkCancelation, formReset, updateRemarkForBulkCancelation, reset ,getErrorCodeList})(BulkCancelation));