import { ADD_NOTIFICATION, ADD_NOTIFICATION_SUCCESS, EDIT_NOTIFICATION, NOTIFICATION_API_ERROR, NOTIFICATION_API_ERROR_MODAL, NOTIFICATION_LIST, NOTIFICATION_LIST_SUCCESSFUL, RESET_NOTIFICATION_ALERT, SET_ISCURRENT_STATUS, SET_ISCURRENT_STATUS_SUCCESSFULL } from "./actionTypes";

const initialState = {
    apiError: null, loading: false, plan: []
}

const notification = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATION_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
            }
            break;

        case NOTIFICATION_LIST_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                notification: action.payload,
                apiError: null,
            }
            break;

        case NOTIFICATION_API_ERROR:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;
        case ADD_NOTIFICATION:
            state = {
                ...state,
                loading:true,
            }
            break;
        case ADD_NOTIFICATION_SUCCESS:
            state = {
                ...state,
                loading:false,
                modalMessage: action.payload.message,
                notification: action.payload.notification
            }
            break;
        case EDIT_NOTIFICATION:
            state = {
                ...state,
                loading:true,
            }
            break;
        case NOTIFICATION_API_ERROR_MODAL:
            state = {
                ...state,
                apiErrorModal: action.payload,
                loading:false,
            }
            break;
        case RESET_NOTIFICATION_ALERT:
            state = {
                ...state,
                apiErrorModal: null,
                modalMessage: null,
                message:null,
                apiError: null
            }
            break;
        case SET_ISCURRENT_STATUS:
            state = {
                ...state,
                loading:true
            }
            break;
        case SET_ISCURRENT_STATUS_SUCCESSFULL:
            state = {
                ...state,
                message: action.payload.message,
                notification: action.payload.notification,
                loading:false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default notification;