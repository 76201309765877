import { GET_LOCKED_ACTIVATION_DATA, GET_LOCKED_ACTIVATION_DATA_FAILED, GET_LOCKED_ACTIVATION_DATA_SUCCESSFULL, UNLOCK_ACTIVATION_BY_OTHER, UNLOCK_ACTIVATION_BY_OTHER_FAILED, UNLOCK_ACTIVATION_BY_OTHER_SUCCESS } from "./actionTypes";

const initialState = {
    apiError: null, Message: null, loading: false, modalMessage: null
}

const lockedActivationData = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOCKED_ACTIVATION_DATA:
            state = {
                ...state,
                loading: true,
            }
            break;
        case GET_LOCKED_ACTIVATION_DATA_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                lockedData: action.payload
            }
            break;

        case GET_LOCKED_ACTIVATION_DATA_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload
            }
            break;
        case UNLOCK_ACTIVATION_BY_OTHER:
            state = {
                ...state,
                loading: true,
            }
            break;
        case UNLOCK_ACTIVATION_BY_OTHER_SUCCESS:
            state = {
                ...state,
                message: action.payload.message,
                lockedData: action.payload.lockedData,
                loading: false
            }
            break;
        case UNLOCK_ACTIVATION_BY_OTHER_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default lockedActivationData;