import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList, addData, editData,userDeleteData } from '../../helpers/fackBackend_Helper';
import { addNotificationSuccess, notificationApiError, notificationApiErrorModal, notificationListSuccess,setIsCurrentStatusSuccessfull } from './actions';
import { ADD_NOTIFICATION, EDIT_NOTIFICATION, NOTIFICATION_LIST, SET_ISCURRENT_STATUS } from './actionTypes';

function* getNotificationList({ payload: companyId }) {
    try {
        const response = yield call(getList, '/getNotification/' + companyId);
        if (response.Status) {
            yield put(notificationListSuccess(response.notification));
        }
        else {
            yield put(notificationApiError(response));
        }
    } catch (error) {
        yield put(notificationApiError(error));
    }
}

export function* watchNotificationList() {
    yield takeEvery(NOTIFICATION_LIST, getNotificationList)
}

function* addNotification({ payload: { notification, companyId } }) {
    try {
        const response = yield call(addData, '/notification', notification);
        if (response.Status) {
            const responseList = yield call(getList, '/getNotification/' + companyId);
            if (responseList.Status) {
                yield put(addNotificationSuccess(responseList.notification, response.Message));
            }
            else {
                yield put(notificationApiErrorModal(responseList.Message));
            }
        }
        else {
            yield put(notificationApiErrorModal(response.Message));
        }
    } catch (error) {
        yield put(notificationApiError(error));
    }
}

export function* watchAddNotification() {
    yield takeEvery(ADD_NOTIFICATION, addNotification)
}

function* editNotification({ payload: { notification, id, companyId } }) {
    try {
        const response = yield call(editData, '/notification/',notification, id);
        if (response.Status) {
            const responseList = yield call(getList, '/getNotification/' + companyId);
            if (responseList.Status) {
                yield put(addNotificationSuccess(responseList.notification, response.Message));
            }
            else {
                yield put(notificationApiErrorModal(responseList.Message));
            }
        }
        else {
            yield put(notificationApiError(response.Message));
        }
    } catch (error) {
        yield put(notificationApiError(error));
    }
}
export function* watchEditNotification() {
    yield takeEvery(EDIT_NOTIFICATION, editNotification)
}

function* activeNotificationIsCurrent({ payload: { id, status, notification } }) {
    try {
        const response = yield call(userDeleteData, '/activeNotification/' + id + '/' + status, notification);
        if (response.Status) {
            const responseList = yield call(getList, '/getNotification/' + notification.CompanyId);
            if (responseList.Status) {
                yield put(setIsCurrentStatusSuccessfull(responseList.notification, response.Message));
            }
            else {
                yield put(notificationApiError(responseList.Message));
            }
        }
        else {
            yield put(notificationApiError(response.Message));
        }
    } catch (error) {
        yield put(notificationApiError(error));
    }
}

export function* watchActiveNotificationIsCurrent() {
    yield takeEvery(SET_ISCURRENT_STATUS, activeNotificationIsCurrent)
}

function* notificationSaga() {
    yield all([
        fork(watchNotificationList),
        fork(watchAddNotification),
        fork(watchEditNotification),
        fork(watchActiveNotificationIsCurrent)
    ]);
}

export default notificationSaga;