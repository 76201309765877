import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList, editData, userDeleteData } from '../../helpers/fackBackend_Helper';
import { deleteJarDataSuccessfully, editJarDataSuccessFully, jarListFailed, jarListSuccessFull } from './actions';
import { DELETE_JAR_DATA, EDIT_JAR_DATA, GET_JAR_LIST } from './actionTypes';

function* getJarList() {
    try {
        const response = yield call(getList, '/getJarPath');
        if (response.Status) {
            yield put(jarListSuccessFull(response.jar));
        } else {
            yield put(jarListFailed(response))
        }
    } catch (error) {
        yield put(jarListFailed(error))
    }

}
export function* watchJarList() {
    yield takeEvery(GET_JAR_LIST, getJarList)
}

function* editJarPath({ payload: { id, jarData } }) {
    try {
        const response = yield call(editData, '/editJarPath/', jarData, id);
        if (response.Status) {
            const responseList = yield call(getList, '/getJarPath');
            if (responseList.Status) {
                yield put(editJarDataSuccessFully(responseList.jar, response.Message));
            }
            else {
                yield put(jarListFailed(responseList.Message));
            }
        }
        else {
            yield put(jarListFailed(response.Message));
        }
    } catch (error) {
        yield put(jarListFailed(error));
    }
}
export function* watchEditJarPath() {
    yield takeEvery(EDIT_JAR_DATA, editJarPath)
}

function* deleteJarPath({ payload: { id, status, deleteData } }) {
    try {
        const response = yield call(userDeleteData, '/deleteJarPath/' + id + '/' + status, deleteData);
        if (response.Status) {
            const responseList = yield call(getList, '/getJarPath');
            if (responseList.Status) {
                yield put(deleteJarDataSuccessfully(responseList.jar, response.Message));
            }
            else {
                yield put(jarListFailed(responseList.message));
            }
        }
        else {
            yield put(jarListFailed(response.message));
        }
    } catch (error) {
        yield put(jarListFailed(error));
    }
}

export function* watchDeleteJarPath() {
    yield takeEvery(DELETE_JAR_DATA, deleteJarPath)
}

function* jarSaga() {
    yield all([
        fork(watchJarList),
        fork(watchEditJarPath),
        fork(watchDeleteJarPath)
    ]);
}

export default jarSaga;