import { GET_ALL_USER_LIST, GET_ALL_USER_LIST_SUCCESS, SEARCH_ACTIVATION_FAILED, SEARCH_USER_ACTIVATION, SEARCH_USER_ACTIVATION_SUCCESSFULL } from "./actionTypes";


const initialState = {
    apiError: null, Message: null, loading: false, modalMessage: null,searchReportData:[]
}

const userActivationReport = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_USER_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;

        case GET_ALL_USER_LIST_SUCCESS:
            state = {
                ...state,
                loading: false,
                userData: action.payload,
                apiError: null,
                message: null
            }
            break;
        case SEARCH_USER_ACTIVATION:
            state={
                ...state,
                loading:true,
            }
            break;
        case SEARCH_USER_ACTIVATION_SUCCESSFULL:
            state={
                ...state,
                loading:false,
                searchReportData:action.payload,
            }
            break;

        case SEARCH_ACTIVATION_FAILED:
            state={
                ...state,
                loading:false,
                message:action.payload
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default userActivationReport;