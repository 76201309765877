import { GET_PLAN_WISE_ACTIVATION_REPORT_LIST,GET_PLAN_WISE_ACTIVATION_REPORT_LIST_FAIL, GET_PLAN_WISE_ACTIVATION_REPORT_LIST_SUCCESSFUL } from "./actionTypes"


export const getPlanWiseActivationReportList = (data) => {
    return {
        type: GET_PLAN_WISE_ACTIVATION_REPORT_LIST,
        payload: data
    }
}

export const getPlanWiseActivationReportListSuccessfull = (data) => {
    return {
        type: GET_PLAN_WISE_ACTIVATION_REPORT_LIST_SUCCESSFUL,
        payload: data
    }
}

export const getPlanWiseActivationReportListFail = (apiError) => {
    return {
        type: GET_PLAN_WISE_ACTIVATION_REPORT_LIST_FAIL,
        payload: apiError
    }
}

