import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList, addData, editData } from '../../helpers/fackBackend_Helper';
import { jarConfigurationDataSuccess, jarConfigurationApiError } from './actions';
import { JAR_CONFIGURATION_DATA, JAR_ADD_CONFIGURATION, JAR_EDIT_CONFIGURATION } from './actionTypes';

function* getJARChannelConfigData({ payload: companyId }) {
    try {
        const response = yield call(getList, '/getJARChannelConfigData/' + companyId);
        if (response.Status) {
            yield put(jarConfigurationDataSuccess(response.configData));
        }
        else {
            yield put(jarConfigurationApiError(response));
        }
    } catch (error) {
        yield put(jarConfigurationApiError(error));
    }
}

export function* watchJArChannelConfigData() {
    yield takeEvery(JAR_CONFIGURATION_DATA, getJARChannelConfigData)
}

function* addJARChannelRelationConfig({ payload: { configuration, companyId } }) {
    try {
        const response = yield call(addData, '/addJARChannelRelation', configuration);
        if (response.Status) {
            const responseList = yield call(getList, '/getJARChannelConfigData/' + companyId);
            if (responseList.Status) {
                yield put(jarConfigurationDataSuccess(responseList.notification, response.Message));
            }
            else {
                yield put(jarConfigurationApiError(responseList.Message));
            }
        }
        else {
            yield put(jarConfigurationApiError(response.Message));
        }
    } catch (error) {
        yield put(jarConfigurationApiError(error));
    }
}

export function* watchAddJARChannelConfig() {
    yield takeEvery(JAR_ADD_CONFIGURATION, addJARChannelRelationConfig)
}

function* editJARChannelRelationConfig({ payload: { configData, companyId } }) {
    try {
        const response = yield call(editData, '/editJARChannelRelationConfig/',configData);
        if (response.Status) {
            const responseList = yield call(getList, '/getJARChannelConfigData/' + companyId);
            if (responseList.Status) {
                yield put(jarConfigurationDataSuccess(responseList.configData, response.Message));
            }
            else {
                yield put(jarConfigurationApiError(responseList.Message));
            }
        }
        else {
            yield put(jarConfigurationApiError(response.Message));
        }
    } catch (error) {
        yield put(jarConfigurationApiError(error));
    }
}
export function* watchEditJARChannelConfig() {
    yield takeEvery(JAR_EDIT_CONFIGURATION, editJARChannelRelationConfig)
}

function* jarConfigSaga() {
    yield all([
        fork(watchJArChannelConfigData),
        fork(watchAddJARChannelConfig),
        fork(watchEditJARChannelConfig)
    ]);
}

export default jarConfigSaga;