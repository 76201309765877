import { JAR_CONFIGURATION_DATA, JAR_CONFIGURATION_DATA_SUCCESSFUL, JAR_CONFIGURATION_API_ERROR, JAR_EDIT_CONFIGURATION, JAR_ADD_CONFIGURATION } from "./actionTypes"

export const jarConfigurationData = (companyId) => {
    return {
        type: JAR_CONFIGURATION_DATA,
        payload: companyId
    }
}

export const jarConfigurationDataSuccess = (configuration) => {
    return {
        type: JAR_CONFIGURATION_DATA_SUCCESSFUL,
        payload: configuration
    }
}

export const jarConfigurationApiError = (error) => {
    return {
        type: JAR_CONFIGURATION_API_ERROR,
        payload: error
    }
}

export const editJarConfiguration = (configData, companyId) => {
    return {
        type: JAR_EDIT_CONFIGURATION,
        payload: { configData, companyId }
    }
}

export const addJarConfiguration = (configuration, companyId) => {
    return {
        type: JAR_ADD_CONFIGURATION,
        payload: { configuration, companyId }
    }
}