import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData } from '../../helpers/fackBackend_Helper';
import { SEARCH_ZIPCODE_ACTIVATION, UPDATE_ZIPCODE_ACTIVATION } from './actionTypes';
import { searchZipcodeActivationFailed, searchZipcodeActivationSuccessfull, updateZipcodeActivationSuccessfull } from './actions';

function* getZipcodeActivation({ payload: searchData }) {
    try {
        const response = yield call(addData, '/getAllActivationByZipcode', searchData);
        if (response.Status) {
            yield put(searchZipcodeActivationSuccessfull(response.activationdata));
        } else {
            yield put(searchZipcodeActivationFailed(response.message));
        }
    } catch (error) {
        yield put(searchZipcodeActivationFailed(error));
    }
}

export function* watchGetZipcodeActivation() {
    yield takeEvery(SEARCH_ZIPCODE_ACTIVATION, getZipcodeActivation)
}

function* updateZipcode({ payload: zipcodeData }) {
    try {
        const response = yield call(addData, '/updateActivationZipcode', zipcodeData);
        if (response.Status) {
            yield put(updateZipcodeActivationSuccessfull(response.Message));
        } else {
            yield put(searchZipcodeActivationFailed(response.Message));
        }
    } catch (error) {
        yield put(searchZipcodeActivationFailed(error));
    }
}

export function* watchUpdateZipcode() {
    yield takeEvery(UPDATE_ZIPCODE_ACTIVATION, updateZipcode)
}

function* zipcodeActivationSaga() {
    yield all([
        fork(watchGetZipcodeActivation),
        fork(watchUpdateZipcode),
    ]);
}
export default zipcodeActivationSaga;

