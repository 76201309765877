export const CHART_DATA = 'CHART_DATA';
export const CHART_DATA_SUCCESSFUL = 'CHART_DATA_SUCCESSFUL';
export const MONTHTLY_CHART = 'MONTHTLY_CHART';
export const MONTHTLY_CHART_SUCCESSFUL = 'MONTHTLY_CHART_SUCCESSFUL';
export const OVERALL_CHART = 'OVERALL_CHART';
export const OVERALL_CHART_SUCCESSFUL = 'OVERALL_CHART_SUCCESSFUL';
export const COUNTACTIVATIONBY_NETWORKTYPE = 'COUNTACTIVATIONBY_NETWORKTYPE';
export const COUNTACTIVATIONBY_NETWORKTYPE_SUCCESSFUL = 'COUNTACTIVATIONBY_NETWORKTYPE_SUCCESSFUL';
export const DASHBOARD_API_ERROR = 'DASHBOARD_API_ERROR';
export const WIDGET_DATA = "WIDGET_DATA";
export const WIDGET_DATA_SUCCESSFULL = "WIDGET_DATA_SUCCESSFULL";
export const SEARCH_ACTIVATION_BY_SOURCE = "SEARCH_ACTIVATION_BY_SOURCE";
export const ACTIVATION_BY_SOURCE_DATA_SUCCESSFULL = "ACTIVATION_BY_SOURCE_DATA_SUCCESSFULL";
export const ACTIVATION_BY_SOURCE_DATA_FAILED = "ACTIVATION_BY_SOURCE_DATA_FAILED";
export const CHANNEL_ACTIVATION_CHART_DATA = "CHANNEL_ACTIVATION_CHART_DATA";
export const CHANNEL_ACTIVATION_CHART_DATA_SUCCESSFULL = "CHANNEL_ACTIVATION_CHART_DATA_SUCCESSFULL";
export const GET_ACTIVATION_COUNT_BY_USER = "GET_ACTIVATION_COUNT_BY_USER";
export const GET_ACTIVATION_COUNT_BY_USER_SUCCESS = "GET_ACTIVATION_COUNT_BY_USER_SUCCESS";
export const GET_ACTIVATION_COUNT_BY_USER_FAILED = "GET_ACTIVATION_COUNT_BY_USER_FAILED";
