import { PENDING_ACTIVATION_LIST, PENDING_ACTIVATION_SUCCESSFULL, PENDING_ACTIVATION_FAILED, PENDING_ACTIVATION_ADD, PENDING_ACTIVATION_ADD_SUCCESSFULL } from "./actionTypes"

export const getPendingActivationOnPOS = (channelId) => {
    return {
        type: PENDING_ACTIVATION_LIST,
        payload: { channelId }
    }
}

export const getPendingActivationSuccess = (pendingData, message) => {
    return {
        type: PENDING_ACTIVATION_SUCCESSFULL,
        payload: { pendingData, message }
    }
}

export const getPendingActivationFailed = (error) => {
    return {
        type: PENDING_ACTIVATION_FAILED,
        payload: error
    }
}

export const addPendingActivation = (pendingData) => {
    return {
        type: PENDING_ACTIVATION_ADD,
        payload: { pendingData }
    }
}

export const addPendingActivationSuccess = (pendingResponse) => {
    return {
        type: PENDING_ACTIVATION_ADD_SUCCESSFULL,
        payload: { pendingResponse }
    }
}