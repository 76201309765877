import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from "moment";
import momentTimezone from 'moment-timezone';
import DataTable from 'react-data-table-component';
import { accessTransactionModal, closeAccessTransactionModal, compeleteActivation, errorCodeList, remarkCancel, viewActivationList, resetAlertActivation, activationRequestInfo, lockActivation, unLockActivation, assignActivation, updateRequestforRefund, messageAlertHide, closeActivationCancelModal, notifyPos } from '../store/viewActivations/actions';
import FilterComponent from "./FilterComponent";
import Spinner from '../components/layout/Spinner';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatPhoneNumber } from 'react-phone-number-input';
import QR_CODE from '../assets/images/QR_Code.png';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { PERMISSIONS } from '../Permission/Permission';
import { decrypt } from '../helpers/encrypt_decrypt_helper';

function ViewActivations(props) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const dispatch = useDispatch();
    const timezone = process.env.REACT_APP_TIMEZONE;
    const [filterText, setFilterText] = useState("");
    const [dataSearch, setDataSearch] = useState(false);
    const [RoleID, setRoleID] = useState(null);
    const [companyId, setcompanyId] = useState("");
    const [loginID, setloginID] = useState(null)
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [disableEID, setDisableEID] = useState(false);
    const [mobileDisable, setmobileDisable] = useState(false);
    const [chooseFileDisable, setchooseFileDisable] = useState(false);
    const [remarkModal, setRemarkModal] = useState(false);
    const [fDate, setFdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [tDate, setTdate] = useState(new Date());
    const [showimgModal, setimgModal] = useState(false);
    const [imgurl, setimgUrl] = useState('');
    const [MSISDN, setimgMSISDN] = useState('');
    const [editId, seteditId] = useState(null);
    const [showingTransaction, setshowingTransaction] = useState(false);
    const [eidField, seteidField] = useState(false);
    const [mobileField, setmobileField] = useState(false)
    const [accountField, setaccountField] = useState(false);
    const [orderField, setorderField] = useState(false);
    const [terminalIdField, setTerminalIdField] = useState(false);
    const [productField, setProductField] = useState(false);
    const [transactionField, setTransactionField] = useState(false);
    const [errorCodeField, setErrorCodeField] = useState(false);
    const [remarkContentField, setRemarkContentField] = useState(false);
    const [activationIdData, setActivationIdData] = useState([]);
    const [checkAllFlag, setCheckAllFlag] = useState(false);
    const [showAssign, setShowAssign] = useState(false);
    const [refundModal, setRefundModal] = useState(false);
    const [refundRemarkField, setrefundRemarkField] = useState(false);
    const [isTransactionMultiLine, setIsTransactionMultiLine] = useState(false);
    const [childDataDisp, setChildDataDisp] = useState('');
    const [fDateError, setFdateError] = useState(false);
    const [tDateError, setTdateError] = useState(false);
    const [imeiSearch, setImeiSearch] = useState(false);
    const [fromdate, setFromdate] = useState(false);
    const [dateValidationError, setDateValidationError] = useState(false);
    const [toDate, setTodate] = useState(false);
    const [pinError, setPinError] = useState(false);
    const [pinErrorMessage, setPinErrorMessage] = useState('');

    const [searchData, setSearchData] = useState({
        statusID: '27',
        serialNumber: '',
        dealerId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : 0,
        fromDate: fDate,
        toDate: tDate,
    });

    const [infoData, setinfoData] = useState({
        eId: '',
        MSISDN: '',
        orderNumber: '',
        accountNumber: '',
        duplicateEmail: '',
        terminalId: '',
        productSKU: '',
        transactionId: '',
        Imagetobase64: '',
        imageRead: 0,
        ImageExt: '',
        ChannelTxnID: "",
        SerialNumber: '',
        ChildData: null,
        pin: '',
    });

    const [errors, setErrors] = useState({
        mobile: '',
        serialNumber: '',
        // Add more error fields for other form fields if needed
    });

    const [remarkContent, setremarkContent] = useState({
        remark: '',
        errorCode: '',
        ChannelTxnID: "",
        SerialNumber: "",
    });

    const [refundData, setrefundData] = useState({
        SerialNumber: "",
        AccountNumber: "",
        ChannelTxnID: "",
        ChannelName: "",
        DealerName: "",
        AmountCharged: "",
        PlanName: "",
        RemarkRefund: ""
    })

    const [modalActivationData, setModalActivationData] = useState(null);
    const [childFormDataShow, setchildFormDataShow] = useState()
    const [childDatashowFlag, setchildDatashowFlag] = useState(false)

    // const [popUp, setpopUp] = useState(false);
    // const [popUpContent, setPopUpContent] = useState('');

    const CompID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
    const logId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;

    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        const dealerId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : null;
        setRoleID(roleId);
        setloginID(loginId);
        setcompanyId(CompanyID);
        setDisableEID(false);
        setmobileDisable(false);
        setchooseFileDisable(false);

        setSearchData(prevState => ({
            ...prevState,
            dealerId: dealerId,
        }));

        setinfoData(prevState => ({
            ...prevState,
            modifiedDtTm: moment(new Date()).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
            modifiedBy: loginId,
        }));

        setremarkContent(prevState => ({
            ...prevState,
            modifiedDtTm: moment(new Date()).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
            modifiedBy: loginId
        }))

        // props.viewActivationList(CompanyID, loginId, searchData)
    }, []);


    useEffect(() => {
        if (props.requestData && props.requestData.length > 0) {
            // var selectedActivation = props.requestData;
            var childDataView = '';
            if (props.requestData[0].Multiline === 1 && props.requestData[0].ChildActivation) {
                var childData = JSON.parse(props.requestData[0].ChildActivation);
                if (childData.length > 0) {
                    childDataView = childData.map((item, key) => (
                        <span key={key} className='line-2' style={{ border: 'none' }}>
                            <h4>Line {item.LineNumber}</h4>
                            <div className="row">
                                <div className="col-sm-3"><p>{item.IMEI}</p></div>
                                <div className="col-sm-2"><p>{item.SimType}</p></div>
                                <div className={item.SerialNumber.toString().length > 19 ? 'col-sm-5 serial-number-size-reduce' : 'col-sm-5'}><p>{item.SerialNumber}</p></div>
                                <div className="col-sm-2"><p>{item.MSISDN}</p></div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3"><p style={{ color: 'red' }}>Add On Services</p></div>
                                {item.AddOnServices && item.AddOnServices.split(',').map((item, index) => {
                                    var serviceData = item.split('_');
                                    return <><div className={`col-sm-7 ${(index > 0) ? 'offset-3' : ''}`}><span><small><em className="icon ni ni-check text-success"></em> {serviceData[0]}</small></span></div><div className='col-sm-2'><span><small>${serviceData[1]}</small></span></div></>
                                })}
                                {/* <div className="col-sm-9"><p>{item.AddOnServices}</p></div>    */}
                            </div>
                        </span>)
                    );
                }
            }

            setChildDataDisp(childDataView);
            setModalActivationData(props.requestData);

            // Multiline complete form changes
            if (props.requestData && props.requestData.length > 0) {
                var selectedReq = props.requestData && props.requestData;

                if (selectedReq[0].SimType === 'P-SIM') {
                    if (selectedReq[0].RequestType === "Activation") {
                        setDisableEID(true);
                        setinfoData(prevState => ({
                            ...prevState,
                            eId: "",
                            ChannelTxnID: selectedReq[0].ChannelTxnID,
                            SerialNumber: selectedReq[0].SerialNumber,
                        }))
                        setchooseFileDisable(true);
                        setmobileDisable(false);
                    } else if (selectedReq[0].RequestType === 'PortIn') {
                        setDisableEID(true);
                        setinfoData(prevState => ({
                            ...prevState,
                            eId: "",
                            ChannelTxnID: selectedReq[0].ChannelTxnID,
                            SerialNumber: selectedReq[0].SerialNumber,
                            MSISDN: selectedReq[0].MSISDN
                        }))
                        setchooseFileDisable(true);
                        setmobileDisable(true);
                    }
                } else if (selectedReq[0].SimType === 'E-SIM') {
                    if (selectedReq[0].RequestType === "Activation") {
                        setDisableEID(false);
                        setinfoData(prevState => ({
                            ...prevState,
                            eId: selectedReq[0].SerialNumber && selectedReq[0].SerialNumber !== "null" && selectedReq[0].SerialNumber !== "NULL" ? selectedReq[0].SerialNumber : '',
                            ChannelTxnID: selectedReq[0].ChannelTxnID,
                            SerialNumber: selectedReq[0].SerialNumber,
                        }))
                        setmobileDisable(false);
                        setchooseFileDisable(false);
                    } else if (selectedReq[0].RequestType === 'PortIn') {
                        setDisableEID(false);
                        setinfoData(prevState => ({
                            ...prevState,
                            eId: selectedReq[0].SerialNumber && selectedReq[0].SerialNumber !== "null" && selectedReq[0].SerialNumber !== "NULL" ? selectedReq[0].SerialNumber : '',
                            ChannelTxnID: selectedReq[0].ChannelTxnID,
                            SerialNumber: selectedReq[0].SerialNumber,
                            MSISDN: selectedReq[0].MSISDN
                        }));

                        setmobileDisable(true);
                        setchooseFileDisable(false);
                    }
                }

                if (selectedReq[0].Multiline === 1) {
                    const parseChildData = JSON.parse(selectedReq[0].ChildActivation)
                    setchildFormDataShow(parseChildData);
                    setinfoData(prevState => ({
                        ...prevState,
                        ChildData: parseChildData
                            .map(child => ({
                                ID: child.ID,
                                SerialNumber: child.SerialNumber,
                                IMEI: child.IMEI,
                                MSISDN: '',
                                LineNumber: child.LineNumber,
                                SimTypeID: child.SimTypeID
                            })),
                    }));
                } else {
                    setinfoData(prevState => ({
                        ...prevState,
                        ChildData: null,
                    }));
                }
            }

        }
    }, [props.requestData]);

    // permission data manage
    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    const checkAll = (event) => {
        var tempData = [];
        if (event.target.checked) {
            setCheckAllFlag(true);
            props.viewActivation.map((data, key) => {
                if (data.RequestStatusID === 10) {
                    tempData.push(data.ID);
                }
            });
        }
        else {
            setCheckAllFlag(false);
        }

        setActivationIdData(tempData);
    }

    // const checkRow = (e, actId) => {
    //     // console.log(e);
    //     // console.log(actId);
    //     var tempData = activationIdData;

    //     const index = tempData.indexOf(actId);
    //     if (e.target.checked) {
    //         tempData[index] = actId;
    //     }
    //     else {
    //         if (index > -1) {
    //             tempData.splice(index, 1);
    //         }
    //     }
    //     debugger
    //     setActivationIdData(tempData);
    // }

    const checkRow = (e, actId) => {
        var tempData = activationIdData;
        if (e.target.checked) {
            tempData.push(actId);
        }
        else {
            const index = tempData.indexOf(actId);
            if (index > -1) {
                tempData.splice(index, 1);
            }
        }

        setActivationIdData(tempData);
    }



    const columns = [
        {
            name: showAssign && <><input type="checkbox" id="checkAll" key="checkAll" onChange={checkAll} /></>,
            width: "50px",
            selector: row => row.serial,
        },
        // filterArray && filterArray.length > 0 && filterArray[0].includes("lock_activation", "complete_activation", "cancel_activation", "refund_activation") && 
        {
            name: 'ACTION',
            width: "200px",
            selector: row => row.action,
        },
        {
            name: 'QR IMAGE',
            // width: "100px",
            selector: row => row.showImage,
        },
        {
            name: 'CHANNEL TXN ',
            width: "130px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'CHANNEL NAME',
            width: "130px",
            selector: row => row.ChannelName,
        },
        {
            name: 'STATUS',
            width: "150px",
            selector: row => row.MasterStatus,
        },
        {
            name: 'RETRY IN',
            width: "100px",
            selector: row => row.TimeToRetry,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'ORDER NUMBER',
            width: "170px",
            selector: row => row.OrderNumber,
        },
        {
            name: 'MOBILE NUMBER',
            width: "150px",
            selector: row => row.MobileNumber,
        },
        {
            name: 'ACCOUNT NUMBER',
            width: "170px",
            selector: row => row.AccountNumber,
        },
        {
            name: 'CHANNEL DEALER NAME ',
            width: "200px",
            selector: row => row.ChannelDealerName,
        },
        {
            name: 'CHANNEL DEALER HIERARCHY',
            width: "220px",
            selector: row => row.ChannelDealerHierarchy,
            wrap: true
        },
        {
            name: 'DATE',
            selector: row => row.RequestedDtTm,
        },
        {
            name: 'TYPE',
            selector: row => row.RequestType,
        },
        {
            name: 'SIM',
            selector: row => row.SimType,
        },
        {
            name: 'API STATUS',
            width: "120px",
            selector: row => row.RequestStatus,
        },
        {
            name: 'SOURCE',
            width: "120px",
            selector: row => row.activationSource,
        },
        {
            name: 'SERVER',
            width: "120px",
            selector: row => row.JARServerName,
        },
        {
            name: 'REMARK',
            width: "600px",
            selector: row => row.Remark,
        },
    ];

    const tableColumn = columns.filter((item, index) => {
        if (filterArray && filterArray.length > 0 && filterArray[0].includes("lock_activation", "complete_activation", "cancel_activation", "refund_activation")) {
            return true;
        } else {
            return item.name != 'ACTION';
        }
    });

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.viewActivation && props.viewActivation.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    // const imgpath = process.env.REACT_APP_BASEURL + '/screenshots/';

    // console.log(props.viewActivation);

    const handleNotifyPos = (activationID) => {
        let selectedActivation = props.viewActivation.filter((item) => item.ID == activationID);
        let request = {
            transactionid: selectedActivation[0].ChannelTxnID,
            channelName: selectedActivation[0].ChannelName,
            SerialNumber: selectedActivation[0].SerialNumber ? selectedActivation[0].SerialNumber : '',
            activationstatus: 22 // for sim blocked
        }
        // console.log(request);
        dispatch(notifyPos(request, activationID));
    }

    var filterData = filteredItems && filteredItems.map((data, key) => {
        var showTime = '';
        if (data.RequestStatusID === 19 || data.RequestStatusID === 22 || (data.RequestStatusID === 27 && data.TimerFlag === 1)) {
            var nowTime = moment(new Date()).tz(process.env.REACT_APP_TIMEZONE);
            var endTime = moment(data.ModifiedDate).tz(process.env.REACT_APP_TIMEZONE).add(2, 'hours');
            var timeDifference = moment.duration(endTime.diff(nowTime, 'minutes'));

            if (timeDifference > 0) {
                showTime = timeDifference + ' minutes';
            }
            else {
                showTime = 'Now';
            }
        }

        return {
            serial: (data.RequestStatusID === 10 && <><input type="checkbox" checked={(checkAllFlag) ? activationIdData.includes(data.ID) : null} onChange={(e) => checkRow(e, data.ID)} id={`check_${data.ID}`} key={`check_${data.ID}`}></input></>),
            // assign:<input type="checkbox" className="" id=""></input>,
            action: (data.RequestStatusID === 27 ? (
                data.lockFlag === 0 ? <span>{(data.lockFlag === 0) && (data.otherLockFlag === 0) ?
                    <span>
                        {
                            data.lockLoading ? <em title="To Lock" className="icon icon-circle ni ni-eye bg-indigo lo-be button-loader-spinner" style={{ 'cursor': 'pointer' }}>
                                <div id="loading-bar-spinner-button" class="spinner-button"><div class="spinner-icon"></div></div>
                            </em> : <em className='icon icon-circle icon ni ni-lock-alt bg-indigo' data-bs-toggle="tooltip" data-bs-placement="top" title="To Lock" style={{ 'cursor': 'pointer' }} onClick={() => handleLockActivation(data.ID)}></em>
                        }
                    </span> :
                    <em className='icon icon-circle icon ni ni-na bg-danger' data-bs-toggle="tooltip" data-bs-placement="top" title="To Lock" style={{ 'cursor': 'pointer' }} onClick={() => handleLockActivation(data.ID)}></em>}</span> :
                    <>
                        <em className="icon icon-circle icon bg-success ni ni-done" data-bs-toggle="tooltip" data-bs-placement="top" title="Complete" style={{ 'cursor': 'pointer' }} onClick={() => handleCompleteModalOpen(data.ID)}></em>
                        {
                            data.cancelModalLoading ? <em title="Cancel" className="icon icon-circle ni ni-eye bg-danger lo-be button-loader-spinner mx-2" style={{ 'cursor': 'pointer' }}>
                                <div id="loading-bar-spinner-button" class="spinner-button"><div class="spinner-icon"></div></div>
                            </em> : <em className="icon icon-circle icon bg-danger ni ni-cross mx-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel" style={{ 'cursor': 'pointer' }} onClick={() => handleRemarkModalOpen(data.ID, data.ChannelTxnID, data.SerialNumber)}></em>
                        }
                        {
                            data.releaseLoading ? <em title="Release" className="icon icon-circle ni ni-eye bg-orange lo-be button-loader-spinner" style={{ 'cursor': 'pointer' }}>
                                <div id="loading-bar-spinner-button" class="spinner-button"><div class="spinner-icon"></div></div>
                            </em> : <em className="icon icon-circle icon ni ni-unlock bg-orange" data-bs-toggle="tooltip" data-bs-placement="top" title="Release" onClick={() => handleUnLockActivation(data.ID)} style={{ 'cursor': 'pointer' }}></em>
                        }

                        {/* {
                        data.notifyPosLoading ? <em title="Release" className="icon icon-circle ni ni-eye bg-primary lo-be button-loader-spinner" style={{ 'cursor': 'pointer', marginLeft: '10px' }}>
                            <div id="loading-bar-spinner-button" class="spinner-button"><div class="spinner-icon"></div></div>
                        </em> : <em className="icon icon-circle icon bg-primary ni ni-notify" data-bs-toggle="tooltip" data-bs-placement="top" title="Complete" style={{ 'cursor': 'pointer', marginLeft: '10px' }} onClick={() => handleNotifyPos(data.ID)}></em>
                    } */}


                    </>
            ) : ''
            ),

            showImage: (data.SimType === 'E-SIM' && (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? <span onClick={() => handleImgShow(data.ScreenshotImage, data.MSISDN)} ><img src={QR_CODE} alt="QR Image" width={"70px"} height={"35px"} /></span> : null)),
            ChannelTxnID: (<Link to="#" className={data.Multiline ? `badge rounded-pill bg-outline-danger px-1` : ''} style={{ 'textDecoration': 'underline' }} onClick={() => openTransactionModal(data.ID)}>{data.ChannelTxnID}</Link>),
            ChannelName: (<p>{data.ChannelName}</p>),
            TimeToRetry: ((showTime) ? <ul className='list-status'><li><em className="icon text-danger ni ni-alarm-alt"></em><span>{showTime}</span></li></ul> : null),
            MasterStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : (data.RequestStatusID === 32) ? 'bg-dark' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : data.RequestStatusID === 27 ? 'Assigned to Care' : (data.RequestStatusID === 32) ? 'Refunded' : 'Pending'} </span>),
            SerialNumber: (data.SerialNumber !== "null" ? <p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p> : ''),
            OrderNumber: (data.OrderNumber !== "null" ? <p style={{ "whiteSpace": "break-spaces" }}>{data.OrderNumber}</p> : ''),
            MobileNumber: (data.MSISDN !== "null" ? <p>{data.MSISDN}</p> : ''),
            AccountNumber: (data.AccountNumber !== "null" ? <p>{data.AccountNumber}</p> : ''),
            ChannelDealerName: (<p>{data.ChannelDealerName}</p>),
            ChannelDealerHierarchy: (<p>{data.ChannelDealerHierarchy}</p>),
            RequestedDtTm: (<p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
            RequestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 'SimSwap' ? 'text-warning' : data.RequestType === 'PortIn' ? 'text-primary' : 'text-success'}`} >{data.RequestType[0]}</p>),
            // activationSource: (data.RequestStatusID === 12 || data.RequestStatusID === 13 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? <p>{data.ActivationSource === 1 ? 'Manual' : 'Automation'}</p> : null),
            activationSource: <p>{data.ActivitySourceText}</p>,
            SimType: (<p >{data.SimType}</p>),
            Remark: (<p>{data.Remark === 'null' ? '' : data.Remark}</p>),
            RequestStatus: (<span className={`${(data.RequestStatusID === 13 || data.RequestStatusID === 24) ? 'text-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'text-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{data.RequestStatus}</strong></span>),
            JARServerName: (<p>{data.JARServerName}</p>),
        }
    })

    const listData = props.viewActivation && props.viewActivation.map((data, key) => {
        return {
            SNo: key + 1,
            CHANNELTXN: data.ChannelTxnID,
            CHANNELNAME: data.ChannelName,
            STATUS: (data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : data.RequestStatusID === 27 ? 'Assigned to Care' : 'Pending',
            SERIAL: data.SerialNumber,
            ORDERNUMBER: data.OrderNumber,
            MOBILENUMBER: data.MSISDN,
            ACCOUNTNUMBER: data.AccountNumber,
            CHANNELDEALERNAME: data.ChannelDealerName,
            CHANNELDEALERHIERARCHY: data.ChannelDealerHierarchy,
            DATE: (moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")),
            TYPE: data.RequestType,
            SIM: data.SimType,
            APISTATUS: data.RequestStatus,
            SOURCE: (data.RequestStatusID === 12 || data.RequestStatusID === 13 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? data.ActivationSource === 1 ? 'Manual' : 'Automation' : null),
            SERVER: data.JARServerName,
            REMARK: data.Remark,
        }
    });

    const handleCloseModal = () => {
        setshowingTransaction(false)
        dispatch(closeAccessTransactionModal())
    }

    const handleRefreshData = () => {
        setFilterText("");
        dispatch(messageAlertHide())
        dispatch(viewActivationList(CompID, logId, searchData));
    }

    // const subHeaderComponent = useMemo(() => {
    //     const handleClear = () => {
    //         if (filterText) {
    //             setResetPaginationToggle(!resetPaginationToggle);
    //             setFilterText("");
    //         }
    //     };

    //     return (
    //         <>
    //             <div>
    //                 {/* <Link to="#" className="btn btn-round btn-icon btn-light" onClick={handleRefreshData}><em className="icon ni ni-redo"></em> </Link> */}

    //             </div>
    //             <FilterComponent
    //                 onFilter={e => setFilterText(e.target.value)}
    //                 onClear={handleClear}
    //                 filterText={filterText}
    //             />
    //         </>
    //     );
    // }, [filterText, resetPaginationToggle]);

    const handleChange1 = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleChange2 = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setinfoData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }


    const handlePinNumber = (e) => {
        const inputValue = e.target.value;
        setPinErrorMessage('')
        if (inputValue && inputValue.length < 6 || inputValue.length > 15) {
            setPinError(true);
        } else {
            setPinError(false);
        }

    };

    const handleChange3 = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setremarkContent(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))

        setrefundData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleChange4 = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setrefundData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))

    }

    const handleTxnChange = (e) => {
        const fname = e.target.name;
        const newValue = e.target.value;
        const sanitizedValue = newValue.replace(/[^0-9]/g, '');
        setinfoData(prevState => ({
            ...prevState,
            [fname]: sanitizedValue
        }))
    }

    const handleImgShow = (ScreenshotImage, msisdn) => {
        msisdn = '+1' + msisdn
        setimgUrl(ScreenshotImage);
        setimgMSISDN(msisdn);
        // console.log(msisdn);
        setimgModal(true);
    }

    const handleToDateChange = (d) => {
        setTdate(d);
        setSearchData(prevState => ({
            ...prevState,
            toDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
        if (!d) {
            setTodate(true)
        }
        else {
            setTodate(false)
        }
    }

    const handleFromDateChange = (d) => {
        setFdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))
        if (!d) {
            setFromdate(true)
        }
        else {
            setFromdate(false)
        }
    }

    const handleStatusChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            statusID: e.target.value
        }));
    }

    const handleSearchData = (e) => {
        dispatch(resetAlertActivation());
        e.preventDefault();
        if (searchData.statusID === '10') {
            setShowAssign(true);
        }
        else {
            setShowAssign(false);
        }

        let check = false;

        if ((!fDate || fDate === "") && searchData.serialNumber === "") {
            setFdateError(true);
            check = true;
            setDataSearch(false);
        } else {
            setFdateError(false);
        }

        if ((!tDate || tDate === "") && searchData.serialNumber === "") {
            setTdateError(true);
            check = true;
            setDataSearch(false);
        } else {
            setTdateError(false);
        }
        // const todateres = moment(tDate, "YYYY-MM-DD");
        // const fromdateres = moment(fDate, "YYYY-MM-DD");
        // let result = todateres.diff(fromdateres, 'days');
        // if (result > 7) {
        //     setDateValidationError(true);
        //     check = true;
        // }
        // else {
        //     setDateValidationError(false);
        //     dispatch(resetField())
        // }

        if (check) {
            return;
        }

        searchData.serialNumber === "" ? setImeiSearch(false) : setImeiSearch(true);
        let searchDataManage = searchData;
        searchDataManage.fromDate = fromdate ? '' : moment(searchDataManage.fromDate).format('YYYY-MM-DD');
        searchDataManage.toDate = toDate ? '' : moment(searchDataManage.toDate).format('YYYY-MM-DD');

        if (!check) {
            dispatch(viewActivationList(companyId, loginID, searchDataManage));
            setDataSearch(true);
        }

        // dispatch(viewActivationList(companyId, loginID, searchDataManage));
        setFilterText("");
    }

    const handleCompleteModalOpen = (id) => {
        let selectedRow = props.viewActivation.filter((item) => item.ID === id);

        if (selectedRow[0].Multiline) {
            setIsTransactionMultiLine(true);
        } else {
            setIsTransactionMultiLine(false);
        }

        dispatch(messageAlertHide());
        dispatch(resetAlertActivation());
        dispatch(accessTransactionModal(id));
        setshowModal(true);
        seteditId(id);
        setinfoData(prevState => ({
            ...prevState,
            MSISDN: '',
            orderNumber: '',
            accountNumber: '',
            duplicateEmail: '',
            terminalId: '',
            productSKU: '',
            transactionId: '',
            Imagetobase64: '',
            imageRead: 0,
            ImageExt: '',
            ChannelTxnID: '',
            SerialNumber: '',
            pin: ''
        }));

        setremarkContent(prevState => ({
            ...prevState,
            remark: '',
            errorCode: '',
            ChannelTxnID: '',
            SerialNumber: '',
        }));
    }

    const handleRemarkModalOpen = (id, TXNID, SIMNo) => {
        let selectedRow = props.viewActivation.filter((item) => item.ID === id);
        if (selectedRow[0].Multiline) {
            setIsTransactionMultiLine(true);
        } else {
            setIsTransactionMultiLine(false);
        }

        dispatch(messageAlertHide())
        dispatch(resetAlertActivation());
        setinfoData(prevState => ({
            ...prevState,
            MSISDN: '',
            orderNumber: '',
            accountNumber: '',
            duplicateEmail: '',
            terminalId: '',
            productSKU: '',
            transactionId: '',
            Imagetobase64: '',
            imageRead: 0,
            ImageExt: '',
            ChannelTxnID: '',
            SerialNumber: '',
            pin: ''
        }));

        setremarkContent(prevState => ({
            ...prevState,
            remark: '',
            errorCode: '',
            ChannelTxnID: TXNID,
            SerialNumber: SIMNo,
        }));

        seteditId(id);
        // setRemarkModal(true);
        dispatch(errorCodeList(id));
    }

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const onImageChange = async (e) => {
        const fileObj = e.target.files[0];
        const extenstion = e.target.files[0] && e.target.files[0].name.split('.')[1];

        if (!fileObj.name.match(/\.(jpg|jpeg|png)$/)) {
            document.getElementById('imageError').innerHTML = "Upload only jpg,jpeg,png file."
            return false;
        } else {
            const base64 = await convertToBase64(fileObj);
            setinfoData(prevState => ({
                ...prevState,
                Imagetobase64: base64,
                imageRead: 1,
                ImageExt: '.' + extenstion
            }));
        };
    }

    const handleCompeletedData = (e) => {
        e.preventDefault();
        seteidField(false);
        setmobileField(false);
        setaccountField(false);
        setorderField(false);
        setTerminalIdField(false);
        setProductField(false);
        setTransactionField(false);

        setremarkContent(prevState => ({
            ...prevState,
            remark: '',
            errorCode: '',
        }))

        var checkFlag = false;
        const quoteRegex = /['"]/;
        if (disableEID === false) {
            if (infoData.eId === "") {
                seteidField(true);
                checkFlag = true;
            }
        }
        if (mobileDisable === false) {
            if (infoData.MSISDN === "") {
                setmobileField(true);
                checkFlag = true;
            }

            if (infoData.MSISDN !== '' && infoData.MSISDN.length !== 10) {
                document.getElementById('mobileError').innerHTML = "Please enter 10 digit mobile number";
                checkFlag = true;
            }
        }
        if (infoData.duplicateEmail !== "" && quoteRegex.test(infoData.duplicateEmail)) {
            document.getElementById('emailError').innerHTML = "Email cannot contain quotes";
            checkFlag = true;
        }
        if (infoData.accountNumber === "") {
            setaccountField(true);
            checkFlag = true;
        }
        if (infoData.orderNumber === "") {
            setorderField(true);
            checkFlag = true;
        }
        // if (infoData.terminalId === "") {
        //     setTerminalIdField(true);
        //     checkFlag = true;
        // }
        // if (infoData.productSKU === "") {
        //     setProductField(true);
        //     checkFlag = true;
        // }
        if (infoData.transactionId === "") {
            setTransactionField(true);
            checkFlag = true;
        }
        if (infoData.transactionId !== '' && infoData.transactionId.length !== 12) {
            document.getElementById('transactionIdError').innerHTML = "Please enter 12 digit transaction id";
            checkFlag = true;
        }
        if (infoData.transactionId.length === 12) {
            if (infoData.transactionId.slice(0, 4) !== '0000' || infoData.transactionId[4] === '0') {
                document.getElementById('transactionIdError').innerHTML = "Please enter valid transaction id";
                checkFlag = true;
            }
        }
        if (infoData.ChildData !== null) {
            const childMobileError = infoData.ChildData.map((child, index) =>
                child.MSISDN.trim() === "" ? 'Please enter mobile number' : child.MSISDN.length !== 10 ? `mobile number must be 10 digit` : ''
            )
            const childSerialNumberError = infoData.ChildData.map((child, index) =>
                child.SerialNumber.trim() === '' ? `Please enter serial number` : ''
            );
            const hasErrors = childMobileError.some(error => error !== '') || childSerialNumberError.some(error => error !== '');

            if (hasErrors) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    mobile: childMobileError,
                    serialNumber: childSerialNumberError,
                }));
                checkFlag = true;
            } else {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    mobile: '',
                    serialNumber: '',
                }));
            }
        }

        if (pinError) {
            setPinErrorMessage('Please enter 6 to 15 digits pin')
            checkFlag = true;
        }

        if (!checkFlag) {
            // console.log("infoData", infoData);
            dispatch(compeleteActivation(infoData, editId, companyId, loginID, searchData));
        } else {
            return false;
        }
    }

    const openTransactionModal = (id) => {
        setshowingTransaction(true);
        // dispatch(messageAlertHide())
        dispatch(accessTransactionModal(id));
        // var selectedActivation = props.viewActivation.filter(view => view.ID === id);        
    }

    const handleRemarkSubmit = (e) => {
        e.preventDefault();
        setinfoData(prevState => ({
            ...prevState,
            MSISDN: '',
            orderNumber: '',
            accountNumber: '',
            duplicateEmail: '',
            terminalId: '',
            productSKU: '',
            transactionId: '',
            Imagetobase64: '',
            imageRead: 0,
            ImageExt: '',
            ChannelTxnID: '',
            SerialNumber: '',
            pin: ''
        }))
        if (remarkContent.errorCode === "") {
            setErrorCodeField(true);
            return false;
        }
        setErrorCodeField(false);
        if (remarkContent.remark === "") {
            setRemarkContentField(true);
            return false;
        }
        setRemarkContentField(false);
        dispatch(remarkCancel(remarkContent, editId, companyId, loginID, searchData))
    }

    const handleMobileError = () => {
        document.getElementById('mobileError').innerHTML = "";
    }

    const handleTxnIdError = () => {
        document.getElementById('transactionIdError').innerHTML = "";
    }
    const handleEmailError = () => {
        document.getElementById('emailError').innerHTML = ""
    }
    const handleImageError = () => {
        document.getElementById('imageError').innerHTML = ""
    }

    const handleLockActivation = (activationID) => {
        dispatch(messageAlertHide())
        const lockActivationData = {
            activationId: activationID,
            loginId: loginID,
            companyId: companyId
        }
        dispatch(lockActivation(lockActivationData, searchData));
    }

    const handleUnLockActivation = (activationID) => {
        dispatch(messageAlertHide())
        const unLockData = {
            activationId: activationID,
            loginId: loginID,
            companyId: companyId
        }
        dispatch(unLockActivation(unLockData, searchData));
    }

    const handleBack = () => {
        setPinErrorMessage('')
        setinfoData(prevState => ({
            ...prevState,
            MSISDN: '',
            orderNumber: '',
            accountNumber: '',
            duplicateEmail: '',
            terminalId: '',
            productSKU: '',
            transactionId: '',
            Imagetobase64: '',
            imageRead: 0,
            ImageExt: '',
            ChannelTxnID: '',
            SerialNumber: '',
            ChildData: null,
            pin: ''
        }))
        setremarkContent(prevState => ({
            ...prevState,
            remark: '',
            errorCode: '',
            ChannelTxnID: '',
            SerialNumber: '',
        }))
        setErrors({
            mobile: '',
            serialNumber: '',
        })
        setshowModal(false)
        seteidField(false);
        setmobileField(false);
        setaccountField(false);
        setorderField(false);
        setTerminalIdField(false);
        setProductField(false);
        setTransactionField(false);
        // setRemarkModal(false);
        dispatch(closeActivationCancelModal());
        setRemarkContentField(false);
        setErrorCodeField(false);
        setRefundModal(false);
        setchildDatashowFlag(false);
        setchildFormDataShow(null);

    }

    const handleCancelAlert = () => {
        dispatch(resetAlertActivation());
        dispatch(viewActivationList(CompID, logId, searchData));
    }

    // const handleCancelAlertLock = () => {
    //     dispatch(resetAlertActivation());
    // }
    // const handleSelectedRowData = (state) =>{
    //     setSelectedData(state.selectedRows);
    //     setSelected(state.allSelected);
    //     console.log(state);
    // }

    const handlePendingAssign = (e) => {
        let text = activationIdData.join();
        const pendingRequests = {
            selectedRquests: text
        }
        // console.log(pendingRequests);
        dispatch(assignActivation(companyId, loginID, searchData, pendingRequests));
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    };

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'ActivationDetails' + fileExtension);
        }
    }

    // refund functionality here
    
    const handleRefundModalOpen = (id) => {
        setRefundModal(true);
        seteditId(id);
        var selectedData = props.viewActivation && props.viewActivation.filter(activation => activation.ID === id);
        setrefundData(prevState => ({
            ...prevState,
            SerialNumber: selectedData[0].SerialNumber,
            AccountNumber: selectedData[0].AccountNumber,
            ChannelTxnID: selectedData[0].ChannelTxnID,
            ChannelName: selectedData[0].ChannelName,
            DealerName: selectedData[0].DealerName,
            AmountCharged: selectedData[0].AmountCharged,
            PlanName: selectedData[0].PlanName,
        }))
    }

    const handleRefundSubmit = (e) => {
        e.preventDefault();
        if (refundData.RemarkRefund === "") {
            setrefundRemarkField(true);
            return false;
        }
        if (refundData.RemarkRefund !== "") {
            setrefundRemarkField(false);
            const submitRefundData = {
                refundRemark: refundData.RemarkRefund,
                amountCharged: refundData.AmountCharged
            }
            dispatch(updateRequestforRefund(submitRefundData, editId, companyId, loginID, searchData))
        }
    }

    // const handlePopAlert = (id) => {
    //     setpopUp(true);
    //     seteditId(id);
    //     setPopUpContent('Are you sure want to repush this activation?');
    // }

    // const handleRepushActivation = () => {
    //     dispatch(messageAlertHide())
    //     dispatch(repushActivation(editId, companyId, loginID, searchData))
    //     setpopUp(false);
    // }

    const handleToShowActivationHistory = (id) => {
        dispatch(activationRequestInfo(id));
    }

    if (props.messageModal) {
        setTimeout(() => {
            // setRemarkModal(false);
            dispatch(closeActivationCancelModal());
            setshowModal(false);
            setRefundModal(false);
        }, 2000);
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    const handleChangeChildMobileNumber = (index, e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        setinfoData(prevInfoData => {
            const updatedChildData = [...prevInfoData.ChildData];
            updatedChildData[index].MSISDN = fvalue;
            return {
                ...prevInfoData,
                ChildData: updatedChildData,
            };
        });
    };

    const handleChangeChildSerialNumber = (index, e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        setinfoData(prevInfoData => {
            const updatedChildData = [...prevInfoData.ChildData];
            updatedChildData[index].SerialNumber = fvalue;
            return {
                ...prevInfoData,
                ChildData: updatedChildData,
            };
        });
    }

    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Activation Request</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-3 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="statusID">Status</label>
                                            <div className="form-control-wrap">
                                                <select name="statusID" className="form-control" id="statusID" defaultValue={searchData.statusID} onChange={(e) => handleStatusChange(e)}>
                                                    <option value="" name="All">All</option>
                                                    <option value="27" name="AssignedToCare">Assigned to Care</option>
                                                    <option value="10" name="Pending">Pending</option>
                                                    <option value="11, 18, 19, 20, 21, 22, 27" name="Processing">Processing</option>
                                                    <option value="12, 23, 24" name="Processed">Processed</option>
                                                    <option value="13" name="Cancelled">Cancelled</option>
                                                    {/* <option value="32" name="Refunded">Refunded</option> */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor="fromDate">From Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleFromDateChange(d)} className={fDateError ? "form-control date-picker border border-danger" : "form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="fromDate" closeOnScroll={true} selected={fDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                        {/* {dateValidationError ? <div style={{color:'red'}}>Data difference should be less then or equal to 7 days</div> :''} */}
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor='toDate'>To Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleToDateChange(d)} className={tDateError ? "form-control date-picker border border-danger" : "form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="toDate" closeOnScroll={true} selected={tDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-4">
                                        <div className="form-group"><label className="form-label" htmlFor='serialNumber'>IMEI/Serial No/Channel TXN/Mobile No</label>
                                            <div className="form-control-wrap">
                                                <input type="text" className="form-control" id="serialNumber" name="serialNumber" placeholder="IMEI/Serial No/Channel TXN/Mobile No" onChange={(e) => handleChange1(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2 col-md-1'>
                                        <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Search</span> </button>
                                        {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {dateValidationError ? <div className="alert alert-danger alert-icon mt-2"><em className="icon ni ni-cross-circle"></em> <strong>Date difference should be less than or equal to 7 days.</strong></div> : ''}

                {
                    dataSearch ?

                        <div className="card p-2" style={{ marginTop: '12px' }}>
                            <div className="card-inner p-0" >
                                {props.notifyPosMessage && <div className="example-alert mb-2"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.notifyPosMessage}</strong></div></div>}
                                {props.assignToCareMessage && <div className="example-alert mb-2"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.assignToCareMessage}</strong></div></div>}
                                {props.notifyPosError && <div className="example-alert mb-2"><div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.notifyPosError}</strong></div></div>}
                                {/* {props.repushMessage && <div className="example-alert mb-2"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.repushMessage}</strong></div></div>} */}
                                {props.viewActivation && props.viewActivation.length > 0 ? <div className='d-flex justify-content-between align-items-center mb-3'>
                                    <FilterComponent
                                        onFilter={e => setFilterText(e.target.value)}
                                        onClear={handleClear}
                                        filterText={filterText}
                                    />
                                    <div className='d-flex flex-direction-row'>
                                        <em className="icon icon-circle icon bg-light icon ni ni-reload mx-4" onClick={handleRefreshData} style={{ 'cursor': 'pointer' }}></em>
                                        <Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                                    </div>
                                </div> : ''}
                                <DataTable pagination columns={tableColumn} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                                {filterArray && filterArray.length > 0 && filterArray[0].includes("assign_to_care") && (showAssign && <Link to="#" style={{ 'cursor': 'pointer' }} onClick={handlePendingAssign} className="btn btn-info mx-2">{props.assignToCareLoading ? 'Loading..' : 'Assign To Care'}</Link>)}
                            </div>
                        </div>
                        : ''
                }
            </div>

            {/* compelete activation modal */}
            {showModal && <div className="modal fade show modalCloseClass" tabIndex="-1" id="modalLarge" style={{ 'display': 'block' }} aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document" >
                    <div className="modal-content" style={{ backgroundColor: isTransactionMultiLine ? '#fff8fb' : '' }}>
                        <div className="modal-header">
                            <div><h5 className="modal-title">Complete Activation Request - {infoData.ChannelTxnID}</h5></div>
                            <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={handleBack}><em className="icon ni ni-cross"></em></div>
                        </div>
                        <div className="modal-body">
                            {/* <div className="row g-3"><p>{infoData.SerialNumber}</p></div> */}
                            {props.apiErrorModal && <div className="example-alert"><div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiErrorModal} !</strong></div></div>}
                            {props.messageModal && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.messageModal}</strong></div></div>}
                            <form className="form-validate is-alter" onSubmit={handleCompeletedData} method="post">
                                <div className="row g-3 box-bor">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="eId">EID</label>
                                            <div className="form-control-wrap">
                                                <input type="number" className="form-control" name="eId" id="eId" placeholder="EID" autoComplete="off" value={infoData.eId} disabled={disableEID} onChange={handleChange2} style={{ 'border': eidField ? '1px solid red' : '' }} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="MSISDN">Mobile Number</label>
                                            <div className="form-control-wrap">
                                                <input type="number" className="form-control" name="MSISDN" id="MSISDN" value={infoData.MSISDN} placeholder="Mobile Number" onInput={handleMobileError} autoComplete="off" onChange={handleChange2} disabled={mobileDisable} style={{ 'border': mobileField ? '1px solid red' : '' }} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                            </div>
                                            <p id="mobileError" className='text-danger'></p>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="accountNumber">Account Number</label>
                                            <div className="form-control-wrap">
                                                <input type="number" className="form-control" name="accountNumber" id="accountNumber" value={infoData.accountNumber} placeholder="Account Number" autoComplete="off" onChange={handleChange2} style={{ 'border': accountField ? '1px solid red' : '' }} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="orderNumber">Order Number</label>
                                            <div className="form-control-wrap">
                                                <input type="text" className="form-control" name="orderNumber" id="orderNumber" placeholder="Order Number" value={infoData.orderNumber} onChange={handleChange2} autoComplete="off" style={{ 'border': orderField ? '1px solid red' : '' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="duplicateEmail">Email <span className='text-soft'>(optional)</span></label>
                                            <div className="form-control-wrap">
                                                <input type="email" className="form-control" name="duplicateEmail" id="duplicateEmail" placeholder="Email" value={infoData.duplicateEmail} onInput={handleEmailError} onChange={handleChange2} autoComplete="off" />
                                            </div>
                                            <p id="emailError" className='text-danger'></p>
                                        </div>
                                    </div>

                                    {/* <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="terminalId">Terminal ID</label>
                                            <div className="form-control-wrap">
                                                <input type="text" className="form-control" name="terminalId" id="terminalId" placeholder="Terminal ID" value={infoData.terminalId} onChange={handleChange2} style={{ 'border': terminalIdField ? '1px solid red' : '' }} autoComplete="off" />
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-sm-6">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="productSKU">Product SKU</label>
                                            <div className="form-control-wrap">
                                                <input type="text" className="form-control" name="productSKU" id="productSKU" placeholder="Product SKU" value={infoData.productSKU} onChange={handleChange2} style={{ 'border': productField ? '1px solid red' : '' }} autoComplete="off" />
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="transactionId">Transaction ID</label>
                                            <div className="form-control-wrap">
                                                <input type="text" className="form-control" name="transactionId" id="transactionId" value={infoData.transactionId} onInput={handleTxnIdError} placeholder="Transaction ID" onChange={handleTxnChange} style={{ 'border': transactionField ? '1px solid red' : '' }} autoComplete="off" />
                                            </div>
                                            <p id="transactionIdError" className='text-danger'></p>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-12">
                                        <div className="form-group"><label className="form-label" htmlFor="completeRemark">Remark</label>
                                            <div className="form-control-wrap"><textarea className="form-control"
                                                id="completeRemark" placeholder='Enter Remark here....' name="completeRemark" onChange={handleChange} required></textarea>
                                                </div>
                                        </div>
                                    </div> */}

                                    <div className="col-sm-4">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="pin">PIN <span className='text-soft'>(optional)</span></label>
                                            <div className="form-control-wrap">
                                                <input type="number" maxLength={15} minLength={6} className="form-control" name="pin" id="pin" placeholder="PIN" value={infoData.pin} onInput={handlePinNumber} onChange={handleChange2} autoComplete="off" />
                                            </div>
                                            {pinErrorMessage ? <p className='text-danger'>{pinErrorMessage}</p> : ''}
                                        </div>
                                    </div>

                                    <div className="col-sm-4">
                                        <div className="form-group"><label className="form-label" htmlFor="Imagetobase64">File Upload</label>
                                            <div className="form-control-wrap">
                                                <div className="form-file"><input type="file" id="Imagetobase64" name="Imagetobase64" onClick={handleImageError} onChange={onImageChange} accept="image/*" disabled={chooseFileDisable} /></div>
                                                <p id="imageError" className='text-danger'></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {childDatashowFlag && <div className='my-2'><h5 className="modal-title">Child Activation Data</h5></div>}
                                {infoData.ChildData !== null && infoData.ChildData.length > 0 && infoData.ChildData.map((data, index) => {
                                    return (
                                        <div className="row g-3 line-2">
                                            <h4 className='line_heading'>Line {data.LineNumber}</h4>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="serialNumber">Serial Number</label>
                                                    <div className="form-control-wrap">
                                                        <input type="number" className="form-control" name="SerialNumber" id="serialNumber" value={data.SerialNumber} onChange={(e) => handleChangeChildSerialNumber(index, e)} placeholder="Serial Number" autoComplete="off" disabled={data.SimTypeID === 25 ? true : false} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                    </div>
                                                    {errors && errors.serialNumber[index] && (
                                                        <span style={{ color: 'red' }}>{errors.serialNumber[index]}</span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="imeiNumber">IMEI</label>
                                                    <div className="form-control-wrap">
                                                        <input type="number" className="form-control" name="IMEI" id="imei" placeholder="IMEI Number" value={data.IMEI} autoComplete="off" disabled={true} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="MSISDN">Mobile Number</label>
                                                    <div className="form-control-wrap">
                                                        <input type="number" className="form-control" name="MSISDN" id="MSISDN" placeholder="Mobile Number" autoComplete="off" onChange={(e) => handleChangeChildMobileNumber(index, e)} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} />
                                                    </div>
                                                    {errors.mobile[index] && (
                                                        <span style={{ color: 'red' }}>{errors.mobile[index]}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>)
                                })}
                                <div className="form-group text-right">
                                    <hr></hr>
                                    <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleBack}>Back</button>
                                    <button type='submit' disabled={props.buttonLoading} className="btn btn-lg btn-primary">{props.buttonLoading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Submit</span> </button>
                                    {/* <button type="submit" className="btn btn-lg btn-primary">Submit</button> */}
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>}

            {/* remark modal */}
            {props.cancelModal && <div className="modal fade show" tabIndex="-1" id="modalLarge" style={{ 'display': 'block' }} aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content" style={{ background: isTransactionMultiLine ? '#fff8fb' : '' }}><div className="modal-header">
                        <div><h5 className="modal-title">Cancel Activation Request - {remarkContent.ChannelTxnID}</h5></div>
                        {/* <div className='d-flex justify-content-between'><p>{remarkContent.SerialNumber}</p></div> */}
                        <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={handleBack}><em className="icon ni ni-cross"></em></div>
                    </div>
                        <div className="modal-body">
                            {props.apiErrorModal && <div className="example-alert"><div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiErrorModal} !</strong></div></div>}
                            {props.messageModal && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.messageModal}</strong></div></div>}
                            <form className="form-validate is-alter" onSubmit={handleRemarkSubmit} method="post">
                                <div className="form-group">
                                    <label className="form-label" htmlFor="errorCode">Errors</label>
                                    <div className="form-control-wrap">
                                        <select
                                            name="errorCode"
                                            className="form-control"
                                            id="errorCode"
                                            onChange={handleChange3}
                                            value={remarkContent.errorCode}
                                            style={{ 'border': errorCodeField ? '1px solid red' : '' }}
                                        >
                                            <option value="" name="select">Select</option>
                                            {props.errorList && props.errorList.map(error => (
                                                <option value={error.ErrorCode} name="errorCode" key={error.ErrorCode}>{error.ErrorDesc}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-group"><label className="form-label" htmlFor="remark">Remark</label>
                                    <div className="form-control-wrap"><textarea className="form-control"
                                        id="remark" placeholder='Enter Remark here....' name="remark" value={remarkContent.remark} onChange={handleChange3} style={{ 'border': remarkContentField ? '1px solid red' : '' }}></textarea></div>
                                </div>
                                <div className="form-group text-right">
                                    <hr></hr>
                                    <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleBack}>Back</button>
                                    <button type='submit' disabled={props.buttonLoading} className="btn btn-lg btn-primary">{props.buttonLoading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Submit</span> </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>}
            {/* image modal */}
            {showimgModal && <div className="imgmodal fade show" tabIndex="-1" id="modalTop" style={{ 'display': 'block' }} aria-hidden="true">
                <Link to="#" className="close" data-bs-dismiss="modal" onClick={() => setimgModal(false)} style={{ "marginRight": "50px", "marginTop": "50px", "color": "#fff", "fontSize": "30px", "fontWeight": "bold", "fontFamily": "sans-serif", "opacity": "inherit" }}>
                    X
                </Link>
                <div style={{ "minHeight": "100vh", "display": "flex", "alignItems": "center", "justifyContent": "center" }}>
                    <div className="box" style={{ "width": "40.125rem", "padding": "30px 50px", "borderRadius": "0px", "boxShadow": "0px 0px 5px 2px rgb(0 0 0 / 10%)", "backgroundColor": "#fff" }}>
                        <h1 style={{ "fontSize": "1.375rem", "fontWeight": "700", "color": "#000", "margin": "0" }}>Scan this code QR code if necessary</h1>
                        <p style={{ "fontSize": "15px", "fontWeight": "400", "color": "#000" }}>If you have trouble scanning it. use the below information to activate manualy.</p>
                        <div className="row-box" style={{ "display": "flex", "msFlexWrap": "wrap", "flexWrap": "wrap" }}>
                            <div className="img-box">
                                {/* <img src={imgpath + imgurl} alt="QR Code" style={{ "width": "8.125rem", "borderRadius": "5px" }} /> */}
                                <img src={QR_CODE} alt="QR Code" style={{ "width": "8.125rem", "borderRadius": "5px" }} />
                            </div>
                            <div className="para-detail" style={{ "marginLeft": "1em" }}>
                                <p style={{ "fontSize": "14px", "fontWeight": "500", "color": "rgb(138 138 138)", "marginBottom": "-0.1rem" }}>SM-DP + address: T-MOBILE GDSB NET</p>
                                <p style={{ "fontSize": "14px", "fontWeight": "500", "color": "rgb(138 138 138)", "marginBottom": "-0.1rem" }}>Activation Code</p>
                                <p style={{ "fontSize": "14px", "fontWeight": "500", "color": "rgb(138 138 138)", "marginBottom": "-0.1rem" }}>Confirmation Code</p>
                            </div>
                        </div>
                        <p className="text-left mt-3" style={{ "fontSize": "14px", "fontWeight": "500", "color": "rgb(138 138 138)", "marginBottom": "0.1rem" }}>Need more help? Visit our <a target="_blank" rel="noreferrer" href="https://www.t-mobile.com/support/devices/sim-esim" style={{ "fontWeight": "600", "color": "#e50078" }}>eSim FAQ Page</a></p>
                        <hr style={{ "border": "1px solid rgb(138 138 138)", "margin": "2em 0", "color": "rgb(138 138 138)" }} />
                        <h1 style={{ "fontSize": "1.375rem", "fontWeight": "700", "color": "#000", "marginBottom": "0.1rem" }}>Line</h1>
                        <p style={{ "fontSize": "14px", "fontWeight": "600", "marginBottom": "-0.2rem", "color": "#000" }}><strong>Your new line</strong></p>
                        <p style={{ "fontSize": "14px", "fontWeight": "600", "color": "rgb(138 138 138)", "marginBottom": "0.1rem" }}>Line 01 : {formatPhoneNumber(MSISDN)}</p>
                    </div>
                </div>
            </div>}

            {/* showing transaction detail modal */}
            {showingTransaction && <div className="modal fade show" tabIndex="-1" id="modalLarge" style={{ "display": "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        {!props.modalViewLoader && modalActivationData && modalActivationData.length > 0 && modalActivationData.map((data, index) => {
                            const parsedChildData = data.Multiline > 0 ? JSON.parse(data.ChildActivation) : null
                            return (<>
                                <div className="modal-header" style={{ backgroundColor: data.Multiline ? '#fff8fb' : '' }}>
                                    <div className='d-flex align-items-center'><h5 className="modal-title">CHANNEL TXN ID : <span className='text-muted'>{data.ChannelTxnID}</span></h5>{data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? <h6 className={`${data.ActivationSource === 1 ? 'text-danger' : 'text-primary'} mx-2`}>({data.ActivationSource === 1 ? 'Manual' : 'Automation'})</h6> : null}</div>
                                    <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={handleCloseModal}><em className="icon ni ni-cross"></em></div>
                                </div>
                                <div className="modal-body" style={{ backgroundColor: data.Multiline ? '#fff8fb' : '' }}>
                                    <div className='border-bottom mb-3'>
                                        <h6 className="title">PLAN INFORMATION</h6>
                                        <hr></hr>
                                        <div className='row'>
                                            <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Category</label></div>
                                            <div className='col-sm-9'><p>{data.PlanCategory}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Name</label></div>
                                            <div className='col-sm-9'><p>{data.PlanName}</p></div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Price</label></div>
                                            <div className='col-sm-3'><p>{data.CurrencyCode}{data.PlanPrice}</p></div>
                                            <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Month</label></div>
                                            <div className='col-sm-3 text-right'><p>{data.PlanMonth} month(s)</p></div>
                                        </div>

                                    </div>


                                    <div className='border-bottom my-3' >
                                        <h6 className="title">DEALER INFORMATION</h6>
                                        <hr></hr>
                                        <div className="row">
                                            <div className="col-sm-6 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Dealer Name</label>
                                                <p>{data.DealerName}</p>
                                            </div>
                                        </div>
                                        <div className="row g-4">
                                            <div className="col-sm-6">
                                                <div className='d-flex justify-content-between ' ><label className="form-label" htmlFor="accountNumber">TMobile Username</label><span>{data.TMobileUsername}</span></div>
                                                <div className='d-flex justify-content-between' ><label className="form-label" htmlFor="accountNumber">EPay Username</label><span>{data.EPayUsername}</span></div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className='d-flex justify-content-between ' ><label className="form-label" htmlFor="accountNumber">TMobile Password</label><p className=''><span>{decrypt(data.TMobilePassword)}</span></p></div>
                                                <div className='d-flex justify-content-between '><label className="form-label" htmlFor="accountNumber">EPay Password</label><p className=''><span>{decrypt(data.EPayPassword)}</span></p></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='border-bottom my-3' >
                                        <h6 className="title">CUSTOMER INFORMATION</h6>
                                        <hr></hr>
                                        <div className="row">
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">First Name</label>
                                                <p>{data.FirstName ?? data.FirstName}</p>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Middle Name</label>
                                                <p>{data.MiddleInitial ?? data.MiddleInitial}</p>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Last Name</label>
                                                <p>{data.LastName ?? data.LastName}</p>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Address 1</label>
                                                <p>{data.Address1 ?? data.Address1}</p>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Address 2</label>
                                                <p>{data.Address2 ?? data.Address2}</p>
                                            </div>
                                            <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">ZipCode</label>
                                                <p>{data.ZipCode ?? data.ZipCode}</p>
                                            </div>
                                        </div>


                                    </div>


                                    <div className='border-bottom'>
                                        <h6 className="title">REQUEST INFORMATION</h6>
                                        <hr></hr>
                                        <div className={`row g-4 ${data.Multiline > 0 ? 'box-bor' : ''}`} style={{ top: '-33px !important' }}>
                                            <div className="col-sm-8">
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="IMEI">IMEI</label><p>{data.IMEI}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Serial">Serial #</label><p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{data.SerialNumber}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Request">Activation Type</label><p>{data.RequestType === 'PortIn' ? data.RequestType : 'Normal'}</p></div>
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Account">Account #</label><p>{data.AccountNumber}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Account">Account #</label><p>{data.AccountNumber}</p></div> : null}

                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Customer">Customer Email</label><p>{data.CustomerEmail}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Customer">WiFi Calling </label><p>{data.WiFiCalling && data.WiFiCalling ? 'Yes' : 'No'}</p></div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="SIM">SIM Type</label><p>{data.SimType}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Zip">Zip Code</label><p>{data.AreaCode}</p></div>
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{data.MSISDN}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{data.MSISDN}</p></div> : null}
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Order">Order #</label><p>{data.OrderNumber}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Order">Order # </label><p>{data.OrderNumber}</p></div> : null}
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="SIM">T-Mobile PIN</label><p>{data.SimPin}</p></div>
                                            </div>
                                            <div className='row m-0 p-0'>
                                                <div className='col-sm-3'><label className="form-label mb-0" htmlFor="Services">Add On Services</label></div>
                                                {data.AddOnServices && data.AddOnServices.split(',').map((item, index) => {
                                                    var serviceData = item.split('_');
                                                    return <><div className={`col-sm-7 ${(index > 0) ? 'offset-3' : ''}`}><span><em className="icon ni ni-check text-success"></em> {serviceData[0]}</span></div><div className='col-sm-2 text-right'><span>${serviceData[1]}</span></div></>
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    {data.Multiline > 0 && <div className='border-bottom my-3'>
                                        <h6 className="title">CHILD ACTIVATION DATA</h6>
                                        <hr></hr>
                                        {parsedChildData !== null && parsedChildData.map((item, key) => {
                                            return (
                                                <div className='p-2 line-2'>
                                                    <h4>Line {item.LineNumber}</h4>
                                                    <div className='row mt-2'>
                                                        <div className="col-sm-6">
                                                            <div className='d-flex justify-content-between'><label className="form-label" htmlFor="IMEI">IMEI</label><p>{item.IMEI}</p></div>
                                                            <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Serial">Serial #</label><p className={item.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{item.SerialNumber}</p></div>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <div className='d-flex justify-content-between'><label className="form-label" htmlFor="sim type">SIM Type</label><p>{item.SimType}</p></div>
                                                            <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{item.MSISDN}</p></div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-sm-3'><label className="form-label mb-0" htmlFor="Services">Add On Services</label></div>
                                                        {item.AddOnServices && item.AddOnServices.split(',').map((addon, childIndex) => {
                                                            var childServiceData = addon.split('_');
                                                            return <><div className={`col-sm-7 ${(childIndex > 0) ? 'offset-3' : ''}`}><span><em className="icon ni ni-check text-success"></em> {childServiceData[0]}</span></div><div className='col-sm-2 text-right'><span>${childServiceData[1]}</span></div></>
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>}
                                    {/* <div className="row line-2">
                                            <div className="col-sm-3"><label className="form-label" htmlFor="Mobile">IMEI</label></div>
                                            <div className="col-sm-2"><label className="form-label" htmlFor="Mobile">SIM Type</label></div>
                                            <div className="col-sm-5"> <label className="form-label" htmlFor="Portin">Serial #</label></div>
                                            <div className="col-sm-2"><label className="form-label" htmlFor="Portin">Mobile #</label></div>
                                            {childDataDisp}
                                        </div> */}
                                    {data.RequestType === "PortIn" && <div className='border-bottom my-3'>
                                        <h6 className="title">PORTIN INFORMATION</h6>
                                        <hr></hr>
                                        <div className="row">
                                            <div className="col-sm-4 "><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{data.MSISDN}</p></div>
                                            <div className="col-sm-4 "><label className="form-label" htmlFor="Portin">Portin Account #</label> <p>{data.PortInAccountNo}</p></div>
                                            <div className="col-sm-4 "> <label className="form-label" htmlFor="Portin">Portin PIN</label><p>{data.PortInPIN}</p></div>
                                        </div>
                                    </div>
                                    }
                                    <Link to="#" className="title my-2" style={{ 'textAlign': 'center', 'textDecoration': 'underline' }} onClick={() => handleToShowActivationHistory(data.ID)}>REQUEST JOURNEY</Link>
                                    {/* <button type="submit" className="btn btn-lg btn-primary" style={{'text-align':'center'}}  >More</button> */}
                                    {props.modalFlag && <div>
                                        <hr></hr>
                                        <div className="timeline">
                                            {/* <h6 className="timeline-head">November, 2019</h6> */}
                                            <div>
                                                <ul className="timeline-list">
                                                    {props.activationRequestHistory && props.activationRequestHistory.length > 0 && props.activationRequestHistory.map((val, key) => {
                                                        return (
                                                            <li className="timeline-item" key={key}>
                                                                <div className={`timeline-status ${val.IsError === 0 ? 'bg-success' : 'bg-danger'}`}></div>
                                                                <div className="mx-4">{val.CreatedDate ? moment(new Date(val.CreatedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm") : null} PST</div>
                                                                <div className="timeline-data">
                                                                    <div className=' d-flex'>
                                                                        {val.IsError === 0 ? <em className="icon ni ni-check-circle me-1" style={{ 'color': '#1ee0ac', 'fontSize': '20px' }}></em> : <em className="icon ni ni-cross-circle me-1" style={{ 'color': '#e85347', 'fontSize': '20px' }}></em>}
                                                                        <h6 className="timeline-title mb-1"> {val.IsError === 0 ? (val.Note.charAt(0) != '{' ? val.Note : JSON.parse(val.Note).Value !== '' ? JSON.parse(val.Note).Value : null) : JSON.parse(val.Note).jarAssetName != undefined ? val.ErrorDesc + ' (' + JSON.parse(val.Note).jarAssetName + ')' : val.ErrorDesc}</h6>
                                                                        {val.Note.charAt(0) != '{' ? '' : JSON.parse(val.Note).RequestStatus !== "" ? <span className='mx-4'>{JSON.parse(val.Note).RequestStatus === 18 ? '(' + JSON.parse(val.Note).MSISDN + ')' : JSON.parse(val.Note).RequestStatus === 12 ? '(' + val.OrderNumber + ')' : null}</span> : null}
                                                                    </div>

                                                                    <p className='ms-4'>{val.IsError === 1 ? JSON.parse(val.Note).ErrorDesc : ''}</p>
                                                                </div>

                                                            </li>
                                                        )
                                                    })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>}
                                    {/* {props.activationRequestHistory && props.activationRequestHistory.length === 0 && <h6 style={{ 'color': 'red' }}> No Data Found !</h6>} */}
                                </div>
                                {/* <div className="modal-footer bg-light"><span className="sub-text">Modal Footer Text</span></div> */}
                            </>
                            )
                        })}
                    </div>
                </div>
            </div >}

            {
                props.LockFailAlert === true ? <div className="swal2-container swal2-center swal2-backdrop-show" style={{ "overflowY": "auto" }}>
                    <div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-show"
                        tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{ "display": "flex" }}>
                        <div className="swal2-header">
                            <div className="swal2-icon swal2-error swal2-icon-show" style={{ "display": "flex" }}><span className="swal2-x-mark">
                                <span className="swal2-x-mark-line-left"></span>
                                <span className="swal2-x-mark-line-right"></span>
                            </span>
                            </div>
                            <h2 className="swal2-title" id="swal2-title" style={{ "display": "flex", "fontSize": '1.2rem' }}>{props.LockedFailedmessage} </h2>
                            <h4>{props.LockedByUser && ` by ${props.LockedByUser}`}</h4>
                            <button
                                type="button" className="swal2-close" aria-label="Close this dialog" style={{ "display": "none" }}>×</button>
                        </div>

                        <div className="swal2-actions"><button type="button" className="swal2-confirm swal2-styled" aria-label=""
                            style={{ "display": "inline-block", "borderLeftColor": "rgb(30, 224, 172)", "borderRightColor": "rgb(30, 224, 172)" }} onClick={handleCancelAlert}>OK</button>
                        </div>
                    </div>
                </div> : null
            }

            {/* refund modal */}
            {
                refundModal && <div className="modal fade show" tabIndex="-1" id="modalLarge" style={{ 'display': 'block' }} aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content"><div className="modal-header">
                            <h5 className="modal-title">Refund Activation Request</h5>
                            <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={handleBack}><em className="icon ni ni-cross"></em></div>
                        </div>
                            <div className="modal-body">
                                {props.apiErrorModal && <div className="example-alert"><div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiErrorModal} !</strong></div></div>}
                                {props.messageModal && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.messageModal}</strong></div></div>}
                                <form className="form-validate is-alter" onSubmit={handleRefundSubmit} method="post">
                                    <div className="row g-3">
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="SerialNumber">Serial Number</label>
                                                <div className="form-control-wrap">
                                                    <input type="number" className="form-control" name="SerialNumber" id="SerialNumber" placeholder="Serial Number" autoComplete="off" value={refundData.SerialNumber} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="AccountNumber">Account Number</label>
                                                <div className="form-control-wrap">
                                                    <input type="number" className="form-control" name="AccountNumber" id="AccountNumber" placeholder="Account Number" autoComplete="off" value={refundData.AccountNumber} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="ChannelTxnID">ChannelTxnID</label>
                                                <div className="form-control-wrap">
                                                    <input type="text" className="form-control" name="ChannelTxnID" id="ChannelTxnID" placeholder="ChannelTxnID" autoComplete="off" value={refundData.ChannelTxnID} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="ChannelName">ChannelName</label>
                                                <div className="form-control-wrap">
                                                    <input type="text" className="form-control" name="ChannelName" id="ChannelName" placeholder="Channel Name" autoComplete="off" value={refundData.ChannelName} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="DealerName">Dealer Name</label>
                                                <div className="form-control-wrap">
                                                    <input type="text" className="form-control" name="DealerName" id="DealerName" placeholder="Dealer Name" autoComplete="off" value={refundData.DealerName} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="AmountCharged">Amount Charged</label>
                                                <div className="form-control-wrap">
                                                    <input type="number" className="form-control" name="AmountCharged" id="AmountCharged" placeholder="AmountCharged" autoComplete="off" value={refundData.AmountCharged} disabled />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <label className="form-label" htmlFor="PlanName">Plan Name</label>
                                                <div className="form-control-wrap">
                                                    <input type="text" className="form-control" name="PlanName" id="PlanName" placeholder="Plan Name" autoComplete="off" value={refundData.PlanName} disabled />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="form-group"><label className="form-label" htmlFor="RemarkRefund">Remark</label>
                                            <div className="form-control-wrap"><textarea className="form-control"
                                                id="RemarkRefund" placeholder='Enter Remark here....' name="RemarkRefund" onChange={handleChange4} style={{ 'border': refundRemarkField ? '1px solid red' : '' }}></textarea></div>
                                        </div>

                                        <div className="form-group text-right">
                                            <hr></hr>
                                            <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleBack}>Back</button>
                                            <button type="submit" className="btn btn-lg btn-primary">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/* popup for confirmation for repush*/}
            {/* popUp && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header bg-danger text-white">
                            <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
                        </div>
                        <div className="modal-body">
                            <p>{popUpContent}</p>
                        </div>
                        <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={handleRepushActivation}>Yes</div></div>
                    </div>
                </div>
            </div> */}

            {/* show repush pop alert */}
            {
                props.repushError && <div className="swal2-container swal2-center swal2-backdrop-show" style={{ "overflowY": "auto" }}>
                    <div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-show"
                        tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{ "display": "flex" }}>
                        <div className="swal2-header">
                            <div className="swal2-icon swal2-error swal2-icon-show" style={{ "display": "flex" }}><span className="swal2-x-mark">
                                <span className="swal2-x-mark-line-left"></span>
                                <span className="swal2-x-mark-line-right"></span>
                            </span>
                            </div>
                            <h2 className="swal2-title" id="swal2-title" style={{ "display": "flex", "fontSize": '1.2rem' }}>{props.repushError} </h2>
                            <button
                                type="button" className="swal2-close" aria-label="Close this dialog" style={{ "display": "none" }}>×</button>
                        </div>

                        <div className="swal2-actions"><button type="button" className="swal2-confirm swal2-styled" aria-label=""
                            style={{ "display": "inline-block", "borderLeftColor": "rgb(30, 224, 172)", "borderRightColor": "rgb(30, 224, 172)" }} onClick={handleCancelAlert}>OK</button>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

const mapStateToProps = state => {
    const { viewActivation, apiError, loading, errorList, apiErrorModal, messageModal, requestData, LockedFailedmessage, LockFailAlert, LockedByUser, modalFlag, buttonLoading, noListData, assignToCareLoading, assignToCareMessage, cancelModal, notifyPosMessage, notifyPosError, activationRequestHistory, modalViewLoader } = state.View
    return { viewActivation, apiError, loading, errorList, apiErrorModal, messageModal, requestData, LockedFailedmessage, LockFailAlert, LockedByUser, modalFlag, buttonLoading, noListData, assignToCareLoading, assignToCareMessage, cancelModal, notifyPosMessage, notifyPosError, activationRequestHistory, modalViewLoader };
}

export default withRouter(connect(mapStateToProps, { viewActivationList, compeleteActivation, updateRequestforRefund })(ViewActivations));