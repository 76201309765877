import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import Spinner from "../components/layout/Spinner";
import moment from "moment/moment";
import { jarConfigurationData } from '../store/jarConfiguration/actions';
import { getAllChannelList } from '../store/dealer/actions';
import DatePicker from 'react-datepicker';
import { PERMISSIONS } from '../Permission/Permission';

function JARConfiguration(props) {
    const dispatch = useDispatch();
    const [CompanyId, setCompanyId] = useState('');
    const [LoginId, setLoginId] = useState('');
    const [RoleID, setRoleID] = useState(null);
    const [showModal, setshowModal] = useState(false);
    const [title, settitle] = useState('')
    const [sDate, setSDate] = useState('');
    const [eDate, setEDate] = useState('');
    const [editId, setEditId] = useState();
    const [contentField, setcontentField] = useState(false);
    const [startField, setstartField] = useState(false);
    const [endField, setendField] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [addNotificationData, setaddNotificationData] = useState({
        CompanyId: "",
        Content: "",
        StartDate: "",
        EndDate: "",
        CreatedBy: ""
    })

    useEffect(() => {
        const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
       
        setRoleID(roleId);
        setCompanyId(companyId);
        setLoginId(loginId);
        
        props.getAllChannelList(dealerId);
        // props.jarConfigurationData(companyId);
        setaddNotificationData(prevState => ({
            ...prevState,
            CompanyId: companyId,
            CreatedBy: loginId
        }));
    }, []);

    if(props.channelData){
        debugger
    }

    const handleAddForm = () => {

    }

    const handleSubmit = () => {

    }

    const handleChange = () => {

    }

    const handleCancel = () => {

    }

    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '0px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
    };

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <div className="nk-block-head-content">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h3 className="nk-block-title page-title">JAR Channel Configuration</h3>
                {filterArray && filterArray.length > 0 && filterArray[0].includes("jar_configuration") && <>
                    <div
                        data-target="addProduct"
                        className="toggle btn btn-icon btn-primary d-md-none"
                        onClick={(handleAddForm)}
                    >
                        <em className="icon ni ni-plus"></em>
                    </div>
                    <div
                        data-target="addProduct"
                        className="toggle btn btn-primary d-none d-md-inline-flex"
                        onClick={(handleAddForm)}
                    >
                        <em className="icon ni ni-plus"></em>
                        <span>Add New JAR IP</span>
                    </div>
                </>}
            </div>

            <div className="card p-2" style={{ marginTop: '12px' }}>
                {props.message &&
                    <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
                <div className="card-inner p-0" >
                    
                </div>
            </div>
            {showModal && <div className="modal fade show" tabIndex="-1" id="modalTop" style={{ 'display': 'block' }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-top" role="document">
                    <div className="modal-content"><div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setshowModal(false)}><em className="icon ni ni-cross"></em></div>
                    </div>
                        <div className="modal-body">
                            {props.modalMessage && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.modalMessage}</strong></div></div>}
                            {/* {props.apiError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>} */}
                            <form className="form-validate is-alter" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="newPassword">Content</label>
                                    <div className="form-control-wrap">
                                        <textarea type="text" className="form-control" name="Content" id="Content" onChange={handleChange} value={addNotificationData.Content} placeholder="Content" autoComplete="off" style={{ 'border': contentField ? '1px solid red' : '' }} />
                                    </div>
                                </div>
                    
                                <div className="form-group text-right">
                                    <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleCancel}>Cancel</button>
                                    <button type="submit" className="btn btn-lg btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

const mapStateToProps = state => {
    const { channelData } = state.dealer;
    const { configData, apiError, loading } = state.jarConfiguration;
    return { channelData, configData, apiError, loading };
}

// export default Users;
export default withRouter(connect(mapStateToProps, { getAllChannelList, jarConfigurationData })(JARConfiguration));