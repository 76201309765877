import { 
    GET_DEALER_POS,
    GET_DEALER_POS_SUCCESS,
    GET_DEALER_POS_FAILED,
    GET_DEALER_BY_CHANNEL_ID_AND_ACODE,
    GET_DEALER_BY_CHANNEL_ID_AND_ACODE_SUCCESS,
    GET_DEALER_BY_CHANNEL_ID_AND_ACODE_FAILED,
    RESET_ADDON_FORM,
    POS_DEALER_MAP,
    POS_DEALER_MAP_SUCCESS,
    POS_DEALER_MAP_FAILED,
    POS_DEALER_MAP_MODAL,
} from "./actionTypes"

export const posDealerMapModal = (modal) => {
    return {
        type: POS_DEALER_MAP_MODAL,
        payload: { modal }
    }
}

export const posDealerMap = (dealerPosId, dealerId, loginId) => {
    return {
        type: POS_DEALER_MAP,
        payload: { dealerPosId, dealerId, loginId }
    }
}

export const posDealerMapSuccessful = (data) => {
    return {
        type: POS_DEALER_MAP_SUCCESS,
        payload: data
    }
}

export const posDealerMapFailed = (error) => {
    return {
        type: POS_DEALER_MAP_FAILED,
        payload: error
    }
}

export const resetAddonForm = () => {
    return {
        type: RESET_ADDON_FORM
    }
}

export const getDealerByChannelIdAndAcode = (channelId,acode) => {
    return {
        type: GET_DEALER_BY_CHANNEL_ID_AND_ACODE,
        payload: { channelId, acode }
    }
}

export const getDealerByChannelIdAndAcodeSuccessful = (data) => {
    return {
        type: GET_DEALER_BY_CHANNEL_ID_AND_ACODE_SUCCESS,
        payload: data
    }
}

export const getDealerByChannelIdAndAcodeFailed = (error) => {
    return {
        type: GET_DEALER_BY_CHANNEL_ID_AND_ACODE_FAILED,
        payload: error
    }
}

export const getDealerPos = (channelId) => {
    return {
        type: GET_DEALER_POS,
        payload: { channelId }
    }
}

export const getDealerPosSuccessful = (data) => {
    return {
        type: GET_DEALER_POS_SUCCESS,
        payload: data
    }
}

export const getDealerPosFailed = (error) => {
    return {
        type: GET_DEALER_POS_FAILED,
        payload: error
    }
}


/****************************************************************/
