export const GET_DEALER_POS = 'GET_DEALER_POS';
export const GET_DEALER_POS_SUCCESS = 'GET_DEALER_POS_SUCCESS';
export const GET_DEALER_POS_FAILED = 'GET_DEALER_POS_FAILED';

export const GET_DEALER_BY_CHANNEL_ID_AND_ACODE = 'GET_DEALER_BY_CHANNEL_ID_AND_ACODE';
export const GET_DEALER_BY_CHANNEL_ID_AND_ACODE_SUCCESS = 'GET_DEALER_BY_CHANNEL_ID_AND_ACODE_SUCCESS';
export const GET_DEALER_BY_CHANNEL_ID_AND_ACODE_FAILED = 'GET_DEALER_BY_CHANNEL_ID_AND_ACODE_FAILED';

export const RESET_ADDON_FORM = 'RESET_ADDON_FORM';

export const POS_DEALER_MAP = 'POS_DEALER_MAP';
export const POS_DEALER_MAP_SUCCESS = 'POS_DEALER_MAP_SUCCESS';
export const POS_DEALER_MAP_FAILED = 'POS_DEALER_MAP_FAILED';

export const POS_DEALER_MAP_MODAL = 'POS_DEALER_MAP_MODAL';

/***************************************************/
