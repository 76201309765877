import { RESET_MANUAL_ACTIVATION_REPORT, SEARCH_MANUAL_ACTIVATION, SEARCH_MANUAL_ACTIVATION_FAILED, SEARCH_MANUAL_ACTIVATION_SUCCESS } from "./actionTypes";

const initialState = {
    apiError: null, loading: false, manualActivationData: [], showManualReportPage: false, userRoleData: []
}

const manualActivationReport = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_MANUAL_ACTIVATION:
            state = {
                ...state,
                loading: true,
                apiError: null,
                showManualReportPage: true
            }
            break;
        case SEARCH_MANUAL_ACTIVATION_SUCCESS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                showManualReportPage: true,
                manualActivationData: action.payload
            }
            break;
        case SEARCH_MANUAL_ACTIVATION_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.error,
                showManualReportPage: true,
            }
            break;
        case RESET_MANUAL_ACTIVATION_REPORT:
            state = {
                ...state,
                loading: false,
                apiError: null,
                showManualReportPage: false,
                manualActivationData : [],
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default manualActivationReport;