import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from "moment";
import DataTable from 'react-data-table-component';
import { getRepushActivation, addRepushActivation, getActivationChildList } from '../store/repushActivation/actions';
import FilterComponent from "./FilterComponent";
import Spinner from '../components/layout/Spinner';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import MultilineModalComponent from '../components/MultilineModalComponent';

function RepushActivation(props) {
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState("");
    const [companyId, setcompanyId] = useState("");
    const [loginID, setloginID] = useState(null);
    const [fDate, setFdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [tDate, setTdate] = useState(new Date());
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [checkAllFlag, setCheckAllFlag] = useState(false);
    const [activationIdData, setActivationIdData] = useState([]);
    const [searchData, setSearchData] = useState({
        statusID: 27,
        dealerId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : 0,
        fromDate: fDate,
        toDate: tDate,
    });
    const [popUp, setpopUp] = useState(false);
    const [alert, setAlert] = useState(false);
    const [dataSearch, setDataSearch] = useState(false);
    const [resetState, setResetState] = useState(false);


    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        setloginID(loginId);
        setcompanyId(CompanyID);
        // props.getRepushActivation(CompanyID, loginId, searchData)
    }, []);

    const openTransactionModal = (id) => {
        dispatch(getActivationChildList(id))

    }

    const checkAll = (event) => {
        var tempData = [];
        if (event.target.checked) {
            setCheckAllFlag(true);
            props.repushData.map((data, key) => {
                if (data.RequestStatusID === 27) {
                    tempData.push(data.ID);
                }
            });
        }
        else {
            setCheckAllFlag(false);
        }

        setActivationIdData(tempData);
    }

    const checkRow = (e, actId) => {
        var tempData = activationIdData;
        if (e.target.checked) {
            tempData.push(actId);
        }else {
            const index = tempData.indexOf(actId);
            if (index > -1) {
                tempData.splice(index, 1);
            }
        }
        
        setActivationIdData(tempData);
        setResetState((pre) => !pre);
    }


    const columns = [
        {
            name: "#",
            width: "60px",
            selector: row => row.serial,
        },
        {
            name: <input type="checkbox" id="checkAll" key="checkAll" onChange={checkAll} />,
            width: "30px",
            selector: row => row.checkbox,
        },
        {
            name: 'CHANNEL TXN ',
            width: "170px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'CHANNEL',
            width: "80px",
            selector: row => row.ChannelName,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'CH DEALER NAME ',
            width: "150px",
            selector: row => row.ChannelDealerName,
        },
        {
            name: 'SUBMITTED DATE',
            width: "160px",
            selector: row => row.RequestedDtTm,
        },
        {
            name: 'TYPE',
            width: "60px",
            selector: row => row.RequestType,
        },
        {
            name: 'SIM',
            width: "60px",
            selector: row => row.SimType,
        },
        {
            name: 'REMARK',
            width: "250px",
            selector: row => row.Remark,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.repushData && props.repushData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            serial: key + 1 + '.',
            checkbox: (data.RequestStatusID === 27 && <><input type="checkbox" checked={(checkAllFlag) ? activationIdData.includes(data.ID) : null} onChange={(e) => checkRow(e, data.ID)} id={`check_${data.ID}`} key={`check_${data.ID}`}></input></>),
            ChannelTxnID: (
                <>
                    <p className={data.Multiline === 1 ? `badge rounded-pill bg-outline-danger px-1 d-inline` : 'd-inline'} >{data.ChannelTxnID}</p>
                    {data.Multiline === 1 ? <span onClick={() => openTransactionModal(data.ID)} style={{ 'position': 'absolute' }}><em className="icon ni ni-external" style={{ 'cursor': 'pointer', 'fontSize': '16px', 'marginLeft': '10px' }}></em></span> : ''}
                </>
            ),
            ChannelName: (<p>{data.ChannelName}</p>),
            SerialNumber: (data.SerialNumber != null ? (<p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>) : ''),
            ChannelDealerName: (<p>{data.ChannelDealerName}</p>),
            RequestedDtTm: (<p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm:ss")}</p>),
            RequestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 'SimSwap' ? 'text-warning' : data.RequestType === 'PortIn' ? 'text-primary' : 'text-success'}`} >{data.RequestType[0]}</p>),
            SimType: (<p >{data.SimType}</p>),
            Remark: (<p>{data.Remark == null ? '' : JSON.stringify(data.Note)}</p>),
        }
    });

    const handleToDateChange = (d) => {
        setTdate(d);
        setSearchData(prevState => ({
            ...prevState,
            toDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
    }

    const handleFromDateChange = (d) => {
        setFdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        setDataSearch(true);
        dispatch(getRepushActivation(companyId, loginID, searchData));
        setFilterText("");
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    };

    const handleConfirmRepushRequests = () => {
        if (activationIdData.length > 0) {
            setpopUp(true);
        }
        else {
            setAlert(true);
        }
    }

    const handleRepushActivation = (e) => {
        setpopUp(false);
        let text = activationIdData.join();
        const repushRequests = {
            selectedRquests: text
        }

        dispatch(addRepushActivation(companyId, loginID, searchData, repushRequests));
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Repush Activation</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor="fromDate">From Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleFromDateChange(d)} className={"form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="fromDate" closeOnScroll={true} selected={fDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor='toDate'>To Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleToDateChange(d)} className={"form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="toDate" closeOnScroll={true} selected={tDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2 col-md-1'>
                                        <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Search</span> </button>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {props.message && <div className="example-alert mb-2" style={{ 'marginTop': "0.75rem" }}><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div></div>}
                
                {props.repushData && dataSearch && <div className="card p-2" style={{ marginTop: '12px' }}>
                    <div className="card-inner p-0" >
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <FilterComponent
                                onFilter={e => setFilterText(e.target.value)}
                                onClear={handleClear}
                                filterText={filterText}
                            />
                        </div>
                        <DataTable columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />

                        {filterData.length > 0 && <div style={{ 'paddingTop': '10px', 'float': 'right' }}><Link to="#" style={{ 'cursor': 'pointer' }} onClick={handleConfirmRepushRequests} className="btn btn-info mx-2">Repush Requests</Link></div>}
                    </div>
                </div>}
                    

                {(popUp || alert) && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-danger text-white">
                                <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
                            </div>
                            <div className="modal-body">
                                {popUp && <p>Are you sure you want to repush these transactions for activation? Click Yes to continue.</p>}
                                {alert && <p>Select requests to continue.</p>}
                            </div>
                            {popUp && <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={handleRepushActivation}>Yes</div></div>}
                            {alert && <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setAlert(false)}>Ok</div></div>}
                        </div>
                    </div>
                </div>}
            </div>

            {props.childDataModalShow && <MultilineModalComponent activationChildData={props.activationChildData} />}

        </>
    )
}

const mapStateToProps = state => {
    const { repushData, apiError, loading, message, viewActivation, childDataModalShow, childDataModalShowID, activationChildData } = state.repushActivation
    return { repushData, apiError, loading, message, viewActivation, childDataModalShow, childDataModalShowID, activationChildData };
}

export default withRouter(connect(mapStateToProps, { getRepushActivation, getActivationChildList })(RepushActivation));