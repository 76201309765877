import { JAR_CONFIGURATION_DATA, JAR_CONFIGURATION_DATA_SUCCESSFUL, JAR_CONFIGURATION_API_ERROR, JAR_EDIT_CONFIGURATION, JAR_ADD_CONFIGURATION } from "./actionTypes";

const initialState = {
    apiError: null, loading: false, configData: []
}

const jarConfiguration = (state = initialState, action) => {
    switch (action.type) {
        case JAR_CONFIGURATION_DATA:
            state = {
                ...state,
                loading: true,
                apiError: null,
            }
            break;

        case JAR_CONFIGURATION_DATA_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                configData: action.payload,
                apiError: null,
            }
            break;

        case JAR_CONFIGURATION_API_ERROR:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;
        case JAR_EDIT_CONFIGURATION:
            state = {
                ...state,
            }
            break;
        case JAR_ADD_CONFIGURATION:
            state = {
                ...state,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default jarConfiguration;