import {
  REPUSH_ACTIVATION_LIST,
  REPUSH_ACTIVATION_SUCCESSFULL,
  REPUSH_ACTIVATION_FAILED,
  REPUSH_ACTIVATION,
  REPUSH_ACTIVATION_ADD_SUCCESSFULL,
  MESSAGE_ALERT_HIDE,
  RESET_ALERT_ACTIVATION,
  GET_ERROR_CODE,
  ACTIVATION_REQUEST_INFO,
  PREPARE_CHILD_DATA_MODAL,
  GET_ACTIVATION_CHILD_LIST,
  GET_ACTIVATION_CHILD_LIST_SUCCESSFUL,
  GET_ACTIVATION_CHILD_LIST_FAIL,
  RESET_CHILD_ACTIVATION_MODAL

} from "./actionTypes";

const initialState = {
  apiError: null,
  loading: false,
  repushData: [],
  message: null,
  viewActivation: [],
  childDataModalShow: false,
  childDataModalShowID: null
};

const repushActivation = (state = initialState, action) => {
  switch (action.type) {
    case REPUSH_ACTIVATION_LIST:
      state = {
        ...state,
        loading: true,
        apiError: null,
        message: null,
      };
      break;

    case REPUSH_ACTIVATION_SUCCESSFULL:
      state = {
        ...state,
        loading: false,
        repushData: action.payload.repushData,
        viewActivation: action.payload.repushData,
        apiError: null,
        message: null,
      };
      break;
    case REPUSH_ACTIVATION_FAILED:
      state = {
        ...state,
        loading: false,
        repushData: [],
        apiError: action.payload.message,
        message: null,
      };
      break;

    case REPUSH_ACTIVATION:
      state = {
        ...state,
        loading: true,
        apiError: null,
      };
      break;

    case REPUSH_ACTIVATION_ADD_SUCCESSFULL:
      state = {
        ...state,
        loading: false,
        repushData: action.payload.repushData,
        message: action.payload.message,
        apiError: null,
      };
      break;

    case ACTIVATION_REQUEST_INFO:
      state = {
        ...state,
        modalFlag: false,
        buttonLoading: false,
      };
      break;
    case MESSAGE_ALERT_HIDE:
      state = {
        ...state,
        modalFlag: false,
        buttonLoading: false,
        requestData: null
      };
      break;
    case RESET_ALERT_ACTIVATION:
      state = {
        ...state,
        loading: false,
        message: null,
        apiError: null,
        messageModal: null,
        apiErrorModal: null,
        modalFlag: false,
        LockFailAlert: false,
        // repushError: null,
        // repushMessage: null,
        modalFlag: false,
        buttonLoading: false,
        requestData: null,
      };
      break;
    case GET_ERROR_CODE:
      state = {
        ...state,
        loading: false,
        buttonLoading: false,
      };
      break;
    case GET_ACTIVATION_CHILD_LIST:
      state = {
        ...state,
        loading: false,
        apiError: null,
        message: null,
        childDataModalShow: false,
      }
      break;

    case GET_ACTIVATION_CHILD_LIST_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        activationChildData: action.payload,
        apiError: null,
        message: null,
        childDataModalShow: true,
      }
      break;
    case GET_ACTIVATION_CHILD_LIST_FAIL:
      state = {
        ...state,
        loading: false,
        apiError: action.payload,
        message: null,
        childDataModalShow: true,
      }
      break;
    case RESET_CHILD_ACTIVATION_MODAL:
      state = {
        ...state,
        loading: false,
        apiError: null,
        message: null,
        childDataModalShow: false,
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default repushActivation;
