export const DEALER_EMAIL_CONFIG_LIST = 'DEALER_EMAIL_CONFIG_LIST';
export const DEALER_EMAIL_CONFIG_SUCCESSFUL = 'DEALER_EMAIL_CONFIG_SUCCESSFUL';
export const DEALER_EMAIL_CONFIG_ERROR = 'DEALER_EMAIL_CONFIG_ERROR';

export const OPEN_EMAIL_CONFIG_MODAL = 'OPEN_EMAIL_CONFIG_MODAL';
export const CLOSE_EMAIL_CONFIG_MODAL = 'CLOSE_EMAIL_CONFIG_MODAL';

export const ADD_DEALER_EMAIL_CONFIG = 'ADD_DEALER_EMAIL_CONFIG';
export const ADD_DEALER_EMAIL_CONFIG_SUCCESS = 'ADD_DEALER_EMAIL_CONFIG_SUCCESS';
export const ADD_DEALER_EMAIL_CONFIG_FAILED = 'ADD_DEALER_EMAIL_CONFIG_FAILED';

export const EDIT_DEALER_EMAIL_CONFIG = 'EDIT_DEALER_EMAIL_CONFIG';
export const EDIT_DEALER_EMAIL_CONFIG_SUCCESS = 'EDIT_DEALER_EMAIL_CONFIG_SUCCESS';
export const EDIT_DEALER_EMAIL_CONFIG_FAILED = 'EDIT_DEALER_EMAIL_CONFIG_FAILED';

export const DELETE_DEALER_EMAIL_CONFIG = 'DELETE_DEALER_EMAIL_CONFIG';
export const DELETE_DEALER_EMAIL_CONFIG_SUCCESS = 'DELETE_DEALER_EMAIL_CONFIG_SUCCESS';
export const DELETE_DEALER_EMAIL_CONFIG_FAILED = 'DELETE_DEALER_EMAIL_CONFIG_FAILED';

export const RESET_DEALER_EMAIL_CONFIG_MESSAGE = 'RESET_DEALER_EMAIL_CONFIG_MESSAGE';

