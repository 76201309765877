import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {  getList,addData } from '../../helpers/fackBackend_Helper';
import { getLockedDataFailed, getLockedDataSuccess, unLockActivationByOtherFailed, unLockActivationByOtherSuccess } from './actions';
import { GET_LOCKED_ACTIVATION_DATA, UNLOCK_ACTIVATION_BY_OTHER } from './actionTypes';

function* getActivationLockedData({ payload: companyId }) {
    try {
        const response = yield call(getList, '/getLockActivationData/'+companyId);
        if (response.Status) {
            yield put(getLockedDataSuccess(response.lockData));
        } else {
            yield put(getLockedDataFailed(response.message));
        }
    } catch (error) {
        yield put(getLockedDataFailed(error));
    }
}

export function* watchGetActivationLockedData() {
    yield takeEvery(GET_LOCKED_ACTIVATION_DATA, getActivationLockedData)
}


function* getUnLockActivationData({ payload: unLockData}) {
    try {
        const response = yield call(addData, '/releaseLockActivation',unLockData);
        if (response.lockData[0].Status) {
            const responseList = yield call(getList, '/getLockActivationData/'+ unLockData.companyId);
            if (responseList.Status) {
                yield put(unLockActivationByOtherSuccess(response.lockData[0].Message, responseList.lockData));
            }
        } else {
            yield put(unLockActivationByOtherFailed(response.lockData[0]));
        }
    } catch (error) {
        yield put(getLockedDataFailed(error));
    }
}

export function* watchgetUnLockActivationData() {
    yield takeEvery(UNLOCK_ACTIVATION_BY_OTHER, getUnLockActivationData);
}

function* lockedActivationDataSaga() {
    yield all([
        fork(watchGetActivationLockedData),
        fork(watchgetUnLockActivationData),
    ]);
}
export default lockedActivationDataSaga;

