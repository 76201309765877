import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { getMailByMsisdn, readMail, closeMailTemplate, resetTemplateData } from '../store/emailInbox/actions';
import { PERMISSIONS } from '../Permission/Permission';
import './../assets/styles/style-email.css';
import noImage from '../assets/images/no-data-found.png'

function EmailInbox(props) {
    const dispatch = useDispatch();
    const [RoleID, setRoleID] = useState(null);
    const [companyId, setcompanyId] = useState("");
    const [loginID, setloginID] = useState(null);
    const [seachDone, setSeachDone] = useState(false);
    const [dealerID, setDealerID] = useState(null);


    const [searchData, setSearchData] = useState({
        mobileNumber: '',
    });

    let filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }



    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        const dealerId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : null;
        setRoleID(roleId);
        setloginID(loginId);
        setcompanyId(CompanyID);
        setDealerID(dealerId);
        dispatch(resetTemplateData());
    }, []);

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        setSeachDone(true);
        dispatch(getMailByMsisdn({ ...searchData, dealerID }));
    }

    const handleOpenEmailTepmlate = (mailID) => {
        dispatch(readMail(mailID));
    }

    const handleCloseEmaleTemp = (mailID) => {
        dispatch(closeMailTemplate(mailID));
    }

    return (
        <>
            <div className="nk-block-head-content">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    <h3 className="nk-block-title page-title">E-Mail Inbox</h3>
                </div>
                <div className='card p-2' style={{ marginTop: "12px", marginBottom: "25px" }}>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-3 col-md-4">
                                        <div className="form-group"><label className="form-label" htmlFor='mobileNumber'>Mobile No</label>
                                            <div className="form-control-wrap">
                                                <input type="text" required className="form-control" id="mobileNumber" name="mobileNumber" placeholder="Mobile No" onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2 col-md-1'>
                                        <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.getMailByMsisdnLoading === true && <span
                                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Search</span> </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {
                    props.getMailByMsisdnLoading ==false && seachDone && props.getMailByMsisdn.length === 0 ?
                        <div className='row'>
                            <div className='col-lg-12 col-md-12'>
                                <div className='no-data-img d-flex align-item-center justify-content-center pt-4'>
                                    <img src={noImage} class="img-fluid img-no-dta" alt="..." />
                                </div>
                            </div>
                        </div> : ''
                }

                {
                    props.getMailByMsisdn && props.getMailByMsisdn.length > 0 && props.getMailByMsisdn.map((item, index) => {
                        let html = JSON.parse(item.ReceivedData).data.html;
                        return <>

                            {!item.showMail ? <div className='row ' style={{ cursor: 'pointer' }} onClick={() => handleOpenEmailTepmlate(item.ID)}>
                                <div className='col-lg-12 col-md-12' >
                                    <div className={item.IsRead == 0 ? 'email-card-list read ' : 'email-card-list unread'}>
                                        <div className='row'>
                                            <div className='col-lg-3 col-md-3 email-name'><p >{item.ToEmail}</p></div>
                                            <div className='col-lg-7 col-md-7 second-email text-start'><p>{item.EmailSubject}</p></div>
                                            <div className='col-lg-2 col-md-2 third-email text-end'><p >{moment(item.CreatedDtTm).format("MMM Do")}</p></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                : <div className='row my-4'>
                                    <div className='col-lg-12 col-md-12'>
                                        <div className='card-email-detail-card'>
                                            <div className='wrong-icon'>
                                                <div className='feb-arrow-icon' style={{ cursor: 'pointer' }} onClick={() => handleCloseEmaleTemp(item.ID)}>
                                                    <i className='icon icon-circle ni ni-arrow-left'></i>
                                                </div>
                                            </div>
                                            <div className='details-inner-card'>
                                                <div dangerouslySetInnerHTML={{ __html: html }} />
                                            </div>

                                        </div>
                                    </div>
                                </div>}
                        </>
                    })
                }

            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { getMailByMsisdn, getMailByMsisdnLoading } = state.emailInbox
    return { getMailByMsisdn, getMailByMsisdnLoading };
}

export default withRouter(connect(mapStateToProps, {})(EmailInbox));