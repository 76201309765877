import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList } from '../../helpers/fackBackend_Helper';
import { PAUSED_ACTIVATION_LIST } from './actionTypes';
import { pausedActivationListSuccess, pausedActivationListFailed } from './actions';

function* getPausedActivationList({ payload: { companyId } }) {
    try {
        const response = yield call(getList, '/getPausedActivationRequest/' + companyId);
        if (response.Status) {
            yield put(pausedActivationListSuccess(response.pausedRequestData));
        } else {
            yield put(pausedActivationListFailed(response));
        }
    } catch (error) {
        yield put(pausedActivationListFailed(error));
    }
}

export function* watchGetPausedActivation() {
    yield takeEvery(PAUSED_ACTIVATION_LIST, getPausedActivationList)
}

function* pausedActivationSaga() {
    yield all([
        fork(watchGetPausedActivation),
    ])
}
export default pausedActivationSaga;