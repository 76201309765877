import { GET_ACTIVATION_REPORT, GET_ACTIVATION_REPORT_SUCCESSFULL, GET_ACTIVATION_REPORT_FAILED, RESET_VALIDATION_REPORT } from "./actionTypes";

const initialState = {
    apiError: null, loading: false, activationData: [], checkFlag: false
}

const validateEpayReport = (state = initialState, action) => {
    switch (action.type) {
        case GET_ACTIVATION_REPORT:
            state = {
                ...state,
                loading: true,
                apiError: null,
                activationData: [],
                checkFlag: false
            }
            break;

        case GET_ACTIVATION_REPORT_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                activationData: action.payload.activationData,
                apiError: (action.payload.activationData.length > 0) ? null : "No data available in care",
                checkFlag: (action.payload.activationData.length > 0) ? true : false,
            }
            break;
        case GET_ACTIVATION_REPORT_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload.Message,
                checkFlag: false
            }
            break;
        case RESET_VALIDATION_REPORT:
            state = {
                ...state,
                activationData: [],
                checkFlag: false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default validateEpayReport;