import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData } from '../../helpers/fackBackend_Helper';
import { SEARCH_LATE_ACTIVATED_TXN } from './actionTypes';
import { searchLateActivatedTxnFailed, searchLateActivatedTxnSuccessfull } from './actions';

function* getSearchLateActivatedTxn({ payload: searchData }) {
    try {
        const response = yield call(addData, '/getAllLateActivatedTxn', searchData);
        if (response.Status) {
            yield put(searchLateActivatedTxnSuccessfull(response.data));
        } else {
            yield put(searchLateActivatedTxnFailed(response.message));
        }
    } catch (error) {
        yield put(searchLateActivatedTxnFailed(error));
    }
}

export function* watchSearchLateActivatedTxn() {
    yield takeEvery(SEARCH_LATE_ACTIVATED_TXN, getSearchLateActivatedTxn)
}

function* lateAchievedTxnReportSaga() {
    yield all([
        fork(watchSearchLateActivatedTxn),
    ]);
}
export default lateAchievedTxnReportSaga;

