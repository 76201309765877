import { GET_ALL_CHANNEL_LIST, GET_ALL_CHANNEL_LIST_SUCCESS, PROCESSED_ACTIVATION, PROCESSED_ACTIVATION_SUCCESSFULL, RESET_DEALER_ACTIVATION_REPORT, SEARCH_DEALER_ACTIVATION, SEARCH_DEALER_ACTIVATION_REPORT_FAILED, SEARCH_DEALER_ACTIVATION_SUCCESSFULL } from "./actionTypes";

const initialState = {
    apiError: null, Message: null, loading: false, modalMessage: null, showReportPage: false, searchReportData: []
}

const dealerActivationReport = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_CHANNEL_LIST:
            state = {
                ...state,
                // loading: true,
                apiError: null,
                message: null
            }
            break;

        case GET_ALL_CHANNEL_LIST_SUCCESS:
            state = {
                ...state,
                // loading: false,
                channelData: action.payload,
                apiError: null,
                message: null
            }
            break;
        case SEARCH_DEALER_ACTIVATION:
            state = {
                ...state,
                loading: true,
                showReportPage: true
            }
            break;
        case SEARCH_DEALER_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                searchReportData: action.payload.searchReportData,
                searchExcelData: action.payload.searchExcelData,
                showReportPage: true
            }
            break;

        case SEARCH_DEALER_ACTIVATION_REPORT_FAILED:
            state = {
                ...state,
                loading: false,
                message: action.payload,
                showReportPage: true
            }
            break;
        case PROCESSED_ACTIVATION:
            state = {
                ...state,
                modalLoading: true
            }
            break;
        case PROCESSED_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                processedData: action.payload,
                modalLoading: false
            }
            break;
        case RESET_DEALER_ACTIVATION_REPORT:
            state = {
                ...state,
                showReportPage: false,
                message: null,
                apiError: null
            }
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default dealerActivationReport;