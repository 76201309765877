import { CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR, RESET_LOGIN_ALERT } from './actionTypes';

export const checkLogin = (user, history) => {
    return {
        type: CHECK_LOGIN,
        payload: { user, history }
    }
}

export const loginUserSuccessful = (user) => {
    return {
        type: LOGIN_USER_SUCCESSFUL,
        payload: user
    }
}

export const apiError = (error) => {
    return {
        type: API_ERROR,
        payload: error
    }
}

export const resetLoginAlert = () => {
    return {
        type: RESET_LOGIN_ALERT,
    }
}