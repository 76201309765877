export const USER_LIST = 'user_list';
export const USER_LIST_SUCCESSFUL = 'user_list_successfull';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';

export const EDIT_USER = 'EDIT_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'delete_user_success';

export const USER_API_ERROR = 'user_api_failed';
export const API_ERROR_MODAL = 'api_error_modal';
export const CHANGE_USER_PASSWORD ='CHANGE_USER_PASSWORD';
export const USER_PASSWORD_CHANGE_SUCCESSFULL ="USER_PASSWORD_CHANGE_SUCCESSFULL";

export const RESET_ALERT = 'reset_alert';