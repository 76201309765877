import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
// import momentTimezone from 'moment-timezone';
import moment from "moment/moment";
import FilterComponent from "./FilterComponent";
import Spinner from "../components/layout/Spinner";
import { nonVerifiedDealerList, verifyDealer, updateVerifyUserPassword, resetDealerAlert } from "../store/dealer/actions";
import { PERMISSIONS } from '../Permission/Permission';
import { decrypt } from '../helpers/encrypt_decrypt_helper';

function VerifyDealer(props) {
  const dispatch = useDispatch();
  const [CompanyId, setCompanyId] = useState("");
  const [filterText, setFilterText] = useState("");
  const [addSideForm, setAdddSideForm] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loginID, setLoginId] = useState(null);
  const [RoleID, setRoleID] = useState(null);
  const [allDealerData, setAllDealerData] = useState({
    tMobileUsername: "",
    tMobilePassword: "",
    ePayUsername: "",
    ePayPassword: "",
  });
  const [editId, setEditId] = useState();
  const [tmobileUsername, settmobileUsername] = useState(false);
  const [tmobilePassword, settmobilePassword] = useState(false);
  const [epayUsername, setepayUsername] = useState(false);
  const [epayPassword, setepayPassword] = useState(false);
  const [passTMobileIconClass, setPassTMobileIconClass] = useState('icon ni ni-eye-off');
  const [passEMobileIconClass, setPassEMobileIconClass] = useState('icon ni ni-eye-off');
  const [passwordTMobileType, setPasswordTMobileType] = useState('password');
  const [passwordEMobileType, setPasswordEMobileType] = useState('password');

  useEffect(() => {
    const loginId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).ID : null;
    const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
    const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
    setRoleID(roleId);
    setCompanyId(companyId);
    setLoginId(loginId);

    setAllDealerData((prevState) => ({
      ...prevState,
      modifiedByLoginID: loginId,
    }));

    props.nonVerifiedDealerList(companyId);

    // Encrypted data and secret key
    // const encryptedString = '8cf4063dff588d8e9e19cacc2d03d151fabbd8291327e5e6f3675ca269d4f8b6102ab4a0a7b6c9c682bd219e3897e6f0a702f0b23009e237a8af7e70c85795ac071804f6b70195a6794d59684b6c9651215b03351042b3e2097649ff1d1f3482991fb7ecc1d938d8d5f6';
    // const secretKey = 'secret_key';

    // // Decrypt using Crypto-JS
    // const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
    // const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
    // console.log('Decrypted string:', decryptedString);

  }, []);

  // permission data manage
  var filterArray = [];
  for (const [key, value] of Object.entries(PERMISSIONS)) {
    if (key == RoleID) {
      filterArray.push(value);
    }
  }

  const columns = [
    {
      name: "#",
      width: "30px",
      selector: row => row.serial,
    },
    {
      name: "DEALER NAME",
      selector: row => row.dealerName,
    },
    {
      name: "CHANNEL",
      selector: row => row.ChannelName,
    },
    {
      name: "TMO USERNAME",
      selector: row => row.tmobileUsername,
    },
    {
      name: "TMO PASSWORD",
      selector: row => row.tmobilePassword,
    },
    {
      name: "EPAY USERNAME",
      selector: row => row.epayUsername,
    },
    {
      name: "EPAY PASSWORD",
      selector: row => row.epayPassword,
      width: '150px'
    },
    {
      name: 'CREATED DATE',
      selector: row => row.createdDate,
    },
    filterArray && filterArray.length > 0 && filterArray[0].includes("is_verified_verify_dealer") &&
    {
      name: "IS VERIFIED",
      selector: row => row.isVerified,
    },
    filterArray && filterArray.length > 0 && filterArray[0].includes("edit_password_verify_dealer") && {
      name: "ACTION",
      selector: row => row.action,
    }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '0px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '0px',
      },
    },
  };

  const filteredItems = props.nonVerifiedDealer && props.nonVerifiedDealer.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const filterData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      dealerName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.DealerName}</p>),
      // contactEmailID: (<a href={`mailto:${data.ContactEmailID}`} style={{"whiteSpace": "break-spaces"}}>{data.ContactEmailID}</a>),
      ChannelName: (<p>{data.ChannelName}</p>),
      tmobileUsername: (<p>{data.TMobileUsername}</p>),
      tmobilePassword: (<p>{decrypt(data.TMobilePassword)}</p>),
      epayUsername: (<p>{data.EPayUsername}</p>),
      epayPassword: (<p>{decrypt(data.EPayPassword)}</p>),
      createdDate: (<p>{data.CreatedDtTm ? (moment(new Date(data.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")) : ''}</p>),
      isVerified: <label className="switch">
        <input type="checkbox" defaultChecked={data.IsVerified} onClick={(e) => { handleIsVerifiedFlag(e, data.ID); }} />
        <span className="slider round isVerified"></span>
      </label>,
      action: <em className="icon icon-circle bg-warning ni ni-edit-alt" style={{ cursor: 'pointer' }} onClick={() => handleEditForm(data.ID)}></em>
    }
  })

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const handleChange = (e) => {
    const fname = e.target.name;
    const fvalue = e.target.value;

    setAllDealerData((prevState) => ({
      ...prevState,
      [fname]: fvalue,
    }));
  };

  const handleEditForm = (dealerId) => {
    dispatch(resetDealerAlert());
    setAdddSideForm(true);
    setEditId(dealerId);
    var selectedDealer = props.nonVerifiedDealer.filter(dealer => dealer.ID === dealerId)
    setAllDealerData(prevState => ({
      ...prevState,
      tMobileUsername: selectedDealer[0].TMobileUsername,
      tMobilePassword: decrypt(selectedDealer[0].TMobilePassword),
      ePayUsername: selectedDealer[0].EPayUsername,
      ePayPassword: decrypt(selectedDealer[0].EPayPassword)
    }));
  }

  const handleIsVerifiedFlag = (e, dealerId) => {
    var flag = (e.target.checked) ? 1 : 0;
    dispatch(verifyDealer(flag, dealerId, loginID, CompanyId));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    settmobileUsername(false);
    settmobilePassword(false);
    setepayUsername(false);
    setepayPassword(false);
    var checkFlag = false;

    if (allDealerData.tMobileUsername === '') {
      settmobileUsername(true);
      checkFlag = true;
    }
    if (allDealerData.tMobilePassword === '') {
      settmobilePassword(true);
      checkFlag = true;
    }
    if (allDealerData.ePayUsername === '') {
      setepayUsername(true);
      checkFlag = true;
    }
    if (allDealerData.ePayPassword === '') {
      setepayPassword(true);
      checkFlag = true;
    }

    if (!checkFlag) {
      dispatch(updateVerifyUserPassword(allDealerData, editId, CompanyId));
    }
    else {
      return false;
    }
    // dispatch(updateVerifyUserPassword(allDealerData, editId, CompanyId));
  }

  const handleChangeTypeTMobilePassword = () => {
    passwordTMobileType === 'password' ? setPasswordTMobileType('text') : setPasswordTMobileType('password');
    passwordTMobileType === 'password' ? setPassTMobileIconClass('icon ni ni-eye') : setPassTMobileIconClass('icon ni ni-eye-off');
  }

  const handleChangeTypeEMobilePassword = () => {
    passwordEMobileType === 'password' ? setPasswordEMobileType('text') : setPasswordEMobileType('password');
    passwordEMobileType === 'password' ? setPassEMobileIconClass('icon ni ni-eye') : setPassEMobileIconClass('icon ni ni-eye-off');
  }

  const handleCancel = (e) => {
    setAdddSideForm(false);
    settmobileUsername(false);
    settmobilePassword(false);
    setepayUsername(false);
    setepayPassword(false);
  }

  if (props.modalMessage) {
    setTimeout(() => {
      setAdddSideForm(false);
    }, 1500);
  }

  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }

  return (
    <div className="nk-block-head-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="nk-block-title page-title">Verify Dealer</h3>
      </div>
      <div className="card p-2" style={{ marginTop: "12px" }}>
        {props.message &&
          <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
        {props.apiError &&
          <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}
        <div className="card-inner p-0">
          <DataTable
            pagination
            columns={columns}
            data={filterData}
            customStyles={customStyles}
            subHeaderComponent={subHeaderComponent}
            progressPending={props.loading}
            progressComponent={<Spinner />}
            subHeader
            striped
            responsive
          />
        </div>
      </div>
      {addSideForm && <div className="toggle-overlay" data-target="addProduct"></div>}
      {addSideForm && <div className="nk-add-product toggle-slide toggle-slide-right toggle-screen-any content-active" data-content="addProduct"
        data-toggle-screen="any" data-toggle-overlay="true" data-toggle-body="true" data-simplebar="init" style={{ "zIndex": "1052" }}>
        <div className="simplebar-wrapper" style={{ 'margin': '-24px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer"></div>
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset" style={{ 'right': '0px', 'bottom': '0px' }}>
              <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content"
                style={{ 'height': '100%', 'overflow': 'hidden scroll' }}>
                <div className="simplebar-content" style={{ 'padding': '24px' }}>
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      {/* <span className="hideform" onClick={() => setAdddSideForm(false)}><em className="icon ni ni-arrow-right"></em> </span> */}
                      <h5 className="nk-block-title">Edit Credential</h5>
                      <div className="nk-block-des">
                        <p>Edit automation credential of the Dealer.</p>
                        {props.modalMessage && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.modalMessage}</strong></div></div>}
                        {props.apiError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="row g-3">
                      <form onSubmit={handleSubmit}>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="tMobileUsername">
                              TMobile Username
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                name="tMobileUsername"
                                className="form-control"
                                id="tMobileUsername"
                                value={allDealerData.tMobileUsername}
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="TMobile Username"
                                style={{ 'border': tmobileUsername ? '1px solid red' : '' }}
                                pattern="^[a-zA-Z0-9_]*$"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="tMobilePassword">
                              TMobile Password
                            </label>
                            <div className="form-control-wrap">
                              <div className="form-icon form-icon-right" onClick={handleChangeTypeTMobilePassword}><em className={passTMobileIconClass}></em></div>
                              <input
                                type={passwordTMobileType}
                                name="tMobilePassword"
                                className="form-control"
                                id="tMobilePassword"
                                value={allDealerData.tMobilePassword}
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="TMobile Password"
                                style={{ 'border': tmobilePassword ? '1px solid red' : '' }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="ePayUsername">
                              EPAY Username
                            </label>
                            <div className="form-control-wrap">
                              <input
                                type="text"
                                name="ePayUsername"
                                className="form-control"
                                id="ePayUsername"
                                value={allDealerData.ePayUsername}
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="EPAY Username"
                                pattern="^[a-zA-Z0-9_]*$"
                                style={{ 'border': epayUsername ? '1px solid red' : '' }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="ePayPassword">
                              EPAY Password
                            </label>
                            <div className="form-control-wrap">
                              <div className="form-icon form-icon-right" onClick={handleChangeTypeEMobilePassword}><em className={passEMobileIconClass}></em></div>
                              <input
                                type={passwordEMobileType}
                                name="ePayPassword"
                                className="form-control"
                                id="ePayPassword"
                                value={allDealerData.ePayPassword}
                                onChange={handleChange}
                                autoComplete="off"
                                placeholder="EPAY Password"
                                style={{ 'border': epayPassword ? '1px solid red' : '' }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-2">
                          <button type='submit' disabled={props.loading} className="btn btn-primary">{props.loading === true && <span
                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                            <span>Save</span> </button>
                          {/* <button className="btn btn-primary">Save</button> */}
                          <button
                            className="btn btn-secondary mx-2"
                            onClick={handleCancel}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="simplebar-placeholder" style={{ 'width': 'auto', 'height': '864px' }}></div>
        </div>
        <div className="simplebar-track simplebar-horizontal" style={{ 'visibility': 'hidden' }}>
          <div className="simplebar-scrollbar" style={{ 'width': '0px', 'display': 'none' }}></div>
        </div>
        <div className="simplebar-track simplebar-vertical simplebar-hover" style={{ 'visibility': 'visible' }}>
          <div className="simplebar-scrollbar" style={{ 'height': '606px', 'display': 'block', 'transform': 'translate3d(0px, 0px, 0px)' }}>
          </div>
        </div>

      </div>
      }
    </div>
  );
}
const mapStateToProps = (state) => {
  const { nonVerifiedDealer, apiError, message, loading, modalMessage } = state.dealer;
  return { nonVerifiedDealer, apiError, message, loading, modalMessage };
};
// export default Users;
export default withRouter(connect(mapStateToProps, { nonVerifiedDealerList })(VerifyDealer));
