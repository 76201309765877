export const USER_LOGOUT = 'user_logout';
export const USER_LOGOUT_SUCCESS = 'user_logout_success';
export const USER_LOGOUT_FAILED =" user_logout_failed";

export const CHANGE_PASSWORD = 'change_password';
export const CHANGE_PASSWORD_SUCCESSFUL = 'change_password_successfull'
export const RESET_PASSWORD_ALERT = 'reset_password_alert';
export const API_PASSWORD_ERROR = 'api_failed';

export const JAR_STATUS_UPDATE = 'jar_status_update';
export const JAR_STATUS_UPDATE_SUCCESS = 'jar_status_update_success';

export const GET_TO_SHOW_MARQUEE = "GET_TO_SHOW_MARQUEE";
export const GET_TO_SHOW_MARQUEE_SUCCESSFULL = "GET_TO_SHOW_MARQUEE_SUCCESSFULL";
export const MARQUEE_API_ERROR= "MARQUEE_API_ERROR";

export const VALIDATE_TOKEN= "VALIDATE_TOKEN";
export const VALIDATE_TOKEN_SUCCESS= "VALIDATE_TOKEN_SUCCESS";
export const VALIDATE_TOKEN_FAILED= "VALIDATE_TOKEN_FAILED";
export const GET_COMPANY="GET_COMPANY_DATA";
export const GET_COMPANY_SUCCESS="GET_COMPANY_DATA_SUCCESS";
export const GET_COMPANY_FAILED="GET_COMPANY_FAILED";
export const RESET_HEADER_MESSAGE="RESET_HEADER_MESSAGE";

export const T_MOBILE_STATUS_UPDATE = 'T_MOBILE_STATUS_UPDATE';
export const T_MOBILE_STATUS_UPDATE_SUCCESS = 'T_MOBILE_STATUS_UPDATE_SUCCESS';
export const T_MOBILE_STATUS_UPDATE_FAILED = 'T_MOBILE_STATUS_UPDATE_FAILED';

export const CLOSE_TMOBILE_STATUS_ERROR_MODAL = 'CLOSE_TMOBILE_STATUS_ERROR_MODAL';
