import { ACTIVATION_REPORT_LIST, ACTIVATION_REPORT_SUCCESSFULL, ACTIVATION_REPORT_FAILED, RESET_ACTIVATION_REPORT_PAGE } from "./actionTypes";

const initialState = {
    apiError: null, loading: false,activationData:[], showactivationReportPage:false
}

const activationReport = (state = initialState, action) => {
    switch (action.type) {
        case ACTIVATION_REPORT_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                showactivationReportPage:true,
                activationData:[],
            }
            break;

        case ACTIVATION_REPORT_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                activationData: action.payload.activationData,
                apiError: null,
                showactivationReportPage:true
            }
            break;
        case ACTIVATION_REPORT_FAILED:
            state = {
                ...state,
                loading: false,
                showactivationReportPage:true
            }
            break;
            case RESET_ACTIVATION_REPORT_PAGE:
            state = {
                ...state,
                loading: false,
                showactivationReportPage:false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default activationReport;