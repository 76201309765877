import {
    USER_LIST, USER_LIST_SUCCESSFUL, USER_API_ERROR, ADD_USER, ADD_USER_SUCCESS, API_ERROR_MODAL, DELETE_USER
    , DELETE_USER_SUCCESS, EDIT_USER, CHANGE_USER_PASSWORD, USER_PASSWORD_CHANGE_SUCCESSFULL, RESET_ALERT
} from "./actionTypes"

export const userList = (dealerId) => {
    return {
        type: USER_LIST,
        payload: dealerId
    }
}

export const userListSuccessful = (userData) => {
    return {
        type: USER_LIST_SUCCESSFUL,
        payload: userData
    }
}

export const userApiError = (error) => {
    return {
        type: USER_API_ERROR,
        payload: error
    }
}

export const addUser = (user, dealerId) => {
    return {
        type: ADD_USER,
        payload: { user, dealerId }
    }
}

export const addUserSuccess = (user, Message) => {
    return {
        type: ADD_USER_SUCCESS,
        payload: { user, Message }
    }
}

export const apiErrorModal = (error) => {
    return {
        type: API_ERROR_MODAL,
        payload: error
    }
}

export const deleteUser = (id, status, deleteData, dealerId) => {
    return {
        type: DELETE_USER,
        payload: { id, status, deleteData, dealerId }
    }
}

export const deleteUserSuccess = (user, Message) => {
    return {
        type: DELETE_USER_SUCCESS,
        payload: { user, Message }
    }
}

export const editUser = (user, id, dealerId) => {

    return {
        type: EDIT_USER,
        payload: { user, id, dealerId }
    }
}

export const userPasswordChange = (changePasswordData, dealerId) => {
    return {
        type: CHANGE_USER_PASSWORD,
        payload: { changePasswordData, dealerId }
    }
}

export const userPasswordSuccessfull = (user, Message) => {
    return {
        type: USER_PASSWORD_CHANGE_SUCCESSFULL,
        payload: { user, Message }
    }
}

export const resetAlert = () => {
    return {
        type: RESET_ALERT,
    }
}