import { CHART_DATA, CHART_DATA_SUCCESSFUL, MONTHTLY_CHART, MONTHTLY_CHART_SUCCESSFUL, DASHBOARD_API_ERROR, OVERALL_CHART, OVERALL_CHART_SUCCESSFUL, COUNTACTIVATIONBY_NETWORKTYPE, COUNTACTIVATIONBY_NETWORKTYPE_SUCCESSFUL, WIDGET_DATA, WIDGET_DATA_SUCCESSFULL, SEARCH_ACTIVATION_BY_SOURCE, ACTIVATION_BY_SOURCE_DATA_SUCCESSFULL, ACTIVATION_BY_SOURCE_DATA_FAILED, CHANNEL_ACTIVATION_CHART_DATA, CHANNEL_ACTIVATION_CHART_DATA_SUCCESSFULL, GET_ACTIVATION_COUNT_BY_USER, GET_ACTIVATION_COUNT_BY_USER_SUCCESS, GET_ACTIVATION_COUNT_BY_USER_FAILED } from "./actionTypes";

const initialState = {
    apiError: null, message: null, loading: false, modalMessage: null, allchartData: [], monthlyChartData: [], overAllChartData: [], countActivationByNetworkData: [], widgets: null, sourceData: [], channelChart: null
    , widgetLoading: false, monthlyChartLoading: false,userCountDataLoading:false, userActivationCountData: []
}
const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case CHART_DATA:
            state = {
                ...state,
                loading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null,
                allchartData: []
            }
            break;
        case CHART_DATA_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                allchartData: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;
        case MONTHTLY_CHART:
            state = {
                ...state,
                loading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null,
                monthlyChartData: [],
                monthlyChartLoading: true
            }
            break;
        case MONTHTLY_CHART_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                monthlyChartData: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null,
                monthlyChartLoading: false
            }
            break;
        case OVERALL_CHART:
            state = {
                ...state,
                loading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null,
                overAllChartData: []
            }
            break;
        case OVERALL_CHART_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                overAllChartData: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;
        case COUNTACTIVATIONBY_NETWORKTYPE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null,
                countActivationByNetworkData: []
            }
            break;
        case COUNTACTIVATIONBY_NETWORKTYPE_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                countActivationByNetworkData: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;
        case DASHBOARD_API_ERROR:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                widgetLoading: false,
                monthlyChartLoading: false
            }
            break;
        case WIDGET_DATA:
            state = {
                ...state,
                widgetLoading: true,
                widgets: null,
                channelChart: []
            }
            break;
        case WIDGET_DATA_SUCCESSFULL:
            state = {
                ...state,
                widgets: action.payload,
                widgetLoading: false,
            }
            break;
        case SEARCH_ACTIVATION_BY_SOURCE:
            state = {
                ...state,
                loading: true,
                sourceData: []
            }
            break;
        case ACTIVATION_BY_SOURCE_DATA_SUCCESSFULL:
            state = {
                ...state,
                sourceData: action.payload,
                loading: false
            }
            break;
        case ACTIVATION_BY_SOURCE_DATA_FAILED:
            state = {
                ...state,
                apiError: action.payload,
                loading: false
            }
            break;
        case CHANNEL_ACTIVATION_CHART_DATA:
            state = {
                ...state,
                loading: true,
                channelChart: []
            }
            break;
        case CHANNEL_ACTIVATION_CHART_DATA_SUCCESSFULL:
            state = {
                ...state,
                channelChart: action.payload
            }
            break;
        case GET_ACTIVATION_COUNT_BY_USER:
            state = {
                ...state,
                userCountDataLoading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null,
                userActivationCountData: []
            }
            break;
        case GET_ACTIVATION_COUNT_BY_USER_SUCCESS:
            state = {
                ...state,
                userCountDataLoading: false,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null,
                userActivationCountData: action.payload
            }
            break;
        case GET_ACTIVATION_COUNT_BY_USER_FAILED:
            state = {
                ...state,
                userCountDataLoading: false,
                apiError: action.payload,
                apiErrorModal: null,
                messageModal: null,
                message: null,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default dashboard;