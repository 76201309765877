import React, { useEffect, useState, useMemo } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Draggable } from "react-drag-reorder";
import moment from "moment";
import FilterComponent from "./FilterComponent";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Spinner from '../components/layout/Spinner';
import DataTable from 'react-data-table-component';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { chartData, monthlyChart, overAllChart, countActivationByNetworkType, widgetData, searchActivationBySource, channelChartData, getUserActivationCountStatus } from "../store/dashboard/actions";
import { useHistory } from 'react-router-dom';
import { useDataContext } from "./DataContext";
import { PERMISSIONS } from '../Permission/Permission';
// import ReactApexChart from "react-apexcharts";
import { formateDigits } from "../helpers/commonFunction";

function Dashboard(props) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const dispatch = useDispatch();
    const [sDate, setSdate] = useState('');
    const [eDate, setEdate] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [companyID, setcompanyID] = useState("");
    const [dealerID, setDealerID] = useState(0);
    const [filterText, setFilterText] = useState("");
    const [dashboardTabs, setDashboardTabs] = useState(null);
    const [searchBySourcePage, setsearchBySourcePage] = useState(false);
    const [searchData, setSearchData] = useState({
        statusID: '12, 23, 24',
        activationSource: '',
        fromDate: '',
        toDate: '',
    });
    const [RoleID, setRoleID] = useState(null);
    const { setDashboardData } = useDataContext();
    const history = useHistory();

    useEffect(() => {
        const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
        const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        const dashboardTabOrder = localStorage.getItem('dashboardTabOrder') ? JSON.parse(localStorage.getItem('dashboardTabOrder')) : [0, 1, 2];
        setcompanyID(companyId);
        setDealerID(dealerId);
        setRoleID(roleId);
        setDashboardTabs(dashboardTabOrder);
        props.monthlyChart(companyId, dealerId);
        // props.overAllChart(companyId, dealerId);
        // props.countActivationByNetworkType(companyId);
        props.widgetData(companyId, dealerId);
        props.getUserActivationCountStatus(companyId, dealerId)
        // props.chartData(companyId, dealerId);
        // props.channelChartData(companyId);

        setSearchData(prevState => ({
            ...prevState,
            companyID: companyId,
            dealerId: dealerId
        }));
    }, []);

    var activationData = [];
    var processedData = [];
    var cancelledData = [];
    props.allchartData && props.allchartData.map((data, key) => {
        activationData.push(data.ActivationCounts.split(",").map(Number));
        processedData.push(data.ProcessedCounts.split(",").map(Number));
        cancelledData.push(data.CancelledCounts.split(",").map(Number));
    });
    var monthName = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec");
    var d = new Date();
    d.setDate(1);
    var month = [];
    for (var i = 0; i <= 11; i++) {
        month.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
        d.setMonth(d.getMonth() - 1);
    }

    const options = {
        xaxis: {
            categories: month.reverse()
        },
        chart: {
            foreColor: "#757373",
            toolbar: {
                autoSelected: "pan",
                show: false
            }
        },
        colors: ["#00BAEC", "#00ec95", "#ec1c00"],
        stroke: {
            width: 3
        },
        grid: {
            borderColor: "#e0dede",
            clipMarkers: false,
            yaxis: {
                lines: {
                    show: false
                }
            }
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            gradient: {
                enabled: true,
                opacityFrom: 0.55,
                opacityTo: 0
            }
        },
        markers: {
            size: 5,
            colors: ["#000524"],
            strokeColor: "#00BAEC",
            strokeWidth: 3
        },
        tooltip: {
            theme: "dark"
        },
        noData: {
            text: "Loading...",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000000",
                fontSize: '14px',
                fontFamily: "Helvetica"
            }
        }
    };

    const series = [
        {
            name: "Activation",
            data: activationData[0]
        },
        {
            name: "Processed",
            data: processedData[0]
        },
        {
            name: "Cancelled",
            data: cancelledData[0]
        }
    ];


    // channel chart data start here
    var monthNameData = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec");
    var d = new Date();
    d.setDate(1);
    const monthArray = [];
    for (i = 0; i <= 11; i++) {
        monthArray.push(monthNameData[d.getMonth()]);
        d.setMonth(d.getMonth() - 1);
    }

    monthArray.reverse();

    var channelNameObjectData = [];
    var checkArray = {};
    let channelNameArrayData = [];

    props.channelChart && props.channelChart.map((data, key) => {
        if (data.ChannelName in channelNameObjectData) {
            channelNameObjectData[data.ChannelName][data.month] = data.activationCount;
        }
        else { //  if(checkArray[data.ChannelName].indexOf(monthArray[i]) === -1)
            channelNameObjectData[data.ChannelName] = [];
            for (i = 0; i < monthArray.length; i++) {
                if (monthArray[i] == data.month) {
                    checkArray[data.ChannelName] = data.month;
                    channelNameObjectData[data.ChannelName][data.month.toString()] = data.activationCount;
                }
                else {
                    channelNameObjectData[data.ChannelName][monthArray[i].toString()] = 0;
                }
            }
        }
    })

    channelNameArrayData = Object.entries(channelNameObjectData);
    var seriesData = [];
    for (let index = 0; index < channelNameArrayData.length; index++) {
        const element = channelNameArrayData[index];
        let channelSeries = { "name": element[0], "data": Object.values(element[1]) };
        seriesData.push(channelSeries);
    }

    const optionsData = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: monthArray,
        },
        yaxis: {
            title: {
                text: 'Count'
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val
                }
            }
        },
        noData: {
            text: "Loading...",
            align: 'center',
            verticalAlign: 'middle',
            offsetX: 0,
            offsetY: 0,
            style: {
                color: "#000000",
                fontSize: '14px',
                fontFamily: "Helvetica"
            }
        }
    }

    const columns = [
        {
            name: '#',
            width: "40px",
            selector: row => row.Serial,
        },
        {
            name: 'CHANNEL TXN',
            width: "120px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'CHANNEL',
            width: "120px",
            selector: row => row.ChannelName,
        },
        {
            name: 'STATUS',
            width: "150px",
            selector: row => row.MasterStatus,
        },
        {
            name: 'SERIAL/EID',
            width: "180px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'ORDER #',
            width: "140px",
            selector: row => row.OrderNumber,
        },
        {
            name: 'MOBILE #',
            width: "140px",
            selector: row => row.MobileNumber,
        },
        {
            name: 'ACCOUNT #',
            width: "140px",
            selector: row => row.AccountNumber,
        },
        {
            name: 'CHANNEL DEALER',
            width: "180px",
            selector: row => row.ChannelDealerName,
        },
        {
            name: 'DEALER HIERARCHY',
            width: "220px",
            selector: row => row.ChannelDealerHierarchy,
        },
        {
            name: 'DATE',
            selector: row => row.RequestedDtTm,
        },
        {
            name: 'TYPE',
            width: "120px",
            selector: row => row.RequestType,
        },
        {
            name: 'IMEI',
            width: "180px",
            selector: row => row.Imei,
        },
        {
            name: 'SIM',
            selector: row => row.SimType,
        },
        {
            name: 'SOURCE',
            width: "170px",
            selector: row => row.ActivationSource,
        },
        {
            name: 'REMARK',
            width: "600px",
            selector: row => row.Remark,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.sourceData && props.sourceData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            Serial: key + 1,
            ChannelTxnID: (<p>{data.ChannelTxnID}</p>),
            SerialNumber: (<p>{data.SerialNumber}</p>),
            RequestedDtTm: (<p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
            Imei: (<p>{data.IMEI}</p>),
            MasterStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : 'Pending'} </span>),
            MobileNumber: (<p>{data.MSISDN}</p>),
            OrderNumber: (<p>{data.OrderNumber}</p>),
            AccountNumber: (<p>{data.AccountNumber}</p>),
            SimType: (<p >{data.SimType}</p>),
            RequestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 'SimSwap' ? 'text-warning' : data.RequestType === 'PortIn' ? 'text-primary' : 'text-success'}`} >{data.RequestType[0]}</p>),
            // PlanName: (<p>{data.PlanName}</p>),
            ChannelName: (<p>{data.ChannelName}</p>),
            ActivationSource: (<p>{data.ActivationSource === 1 ? 'Manual' : 'Automation'}</p>),
            ChannelDealerName: (<p>{data.ChannelDealerName}</p>),
            ChannelDealerHierarchy: (<p>{data.ChannelDealerHierarchy}</p>),
            Remark: (<p>{(data.Remark === '' || data.Remark === 'null') ? '' : data.Remark}</p>),
            // RequestStatus: (<span className={`${(data.RequestStatusID === 13 || data.RequestStatusID === 24) ? 'text-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'text-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{data.RequestStatus}</strong></span>),
        }
    })

    const listData = props.sourceData && props.sourceData.map((data, key) => {
        return {
            SNo: key + 1,
            CHANNEL_TXN: data.ChannelTxnID,
            CHANNEL: data.ChannelName,
            STATUS: (data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : 'Pending',
            SERIAL: data.SerialNumber,
            ORDERNO: data.OrderNumber,
            MOBILENO: data.MSISDN,
            ACCOUNTNO: data.AccountNumber,
            CHANNELDEALER: data.ChannelDealerName,
            DEALERHIERARCHY: data.ChannelDealerHierarchy,
            DATE: (moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")),
            TYPE: data.RequestType,
            SIM: data.SimType,
            SOURCE: (data.ActivationSource === 1 ? 'Manual' : 'Automation'),
            REMARK: data.Remark,
        }
    });

    var monthlyseriesData = [];
    var overAllseriesData = [];

    if (props.monthlyChartData && props.monthlyChartData) {
        monthlyseriesData = [props.monthlyChartData.Pending, props.monthlyChartData.Processing, props.monthlyChartData.AssignedToCare, props.monthlyChartData.Processed, props.monthlyChartData.Cancelled];
    }
    if (props.overAllChartData && props.overAllChartData) {
        overAllseriesData = [props.overAllChartData.Pending, props.overAllChartData.Processing, props.overAllChartData.AssignedToCare, props.overAllChartData.Processed, props.overAllChartData.Cancelled];
    }

    var widgetshowData = props.widgets && props.widgets.map((data, index) => {
        // console.log(dashboardTabs);
        let tabs = {
            '0': { titleName: "Today's", countAutomation: `${formateDigits(data.todayAutomation)}`, countManual: `${formateDigits(data.todayMannual)}`, sum: `${data.totalAutomation + data.totalMannual}`, icons: "icon ni bg-blue-dim ni ni-calendar-check" },
            '1': { titleName: 'Monthly', countAutomation: `${formateDigits(data.monthlyAutomation)}`, countManual: `${formateDigits(data.monthlyMannual)}`, sum: `${data.totalAutomation + data.totalMannual}`, icons: "icon bg-orange-dim ni ni-list-thumb" },
            '2': { titleName: 'Last Month', countAutomation: `${formateDigits(data.lastMonthlyAutomation)}`, countManual: `${formateDigits(data.lastMonthlyMannual)}`, sum: `${data.totalAutomation + data.totalMannual}`, icons: "icon bg-purple-dim ni ni-server" },

        }
        if (dashboardTabs) {
            let tabData = dashboardTabs.map((item) => tabs[item]);
            return tabData;
        }
        return [];
        // { titleName: 'Total ', countAutomation: `${data.totalAutomation}`, countManual: `${data.totalMannual}`, sum: `${data.totalAutomation + data.totalMannual}`, icons: "icon bg-purple-dim ni ni-shrink" },
        // { titleName: 'Yearly', countAutomation: `${data.yearlyAutomation}`, countManual: `${data.yearlyMannual}`, sum: `${data.totalAutomation + data.totalMannual}`, icons: "icon bg-pink-dim ni ni-minimize" },
    })

    const handleViewReportsBySource = (sourceID, titleName) => {
        setsearchBySourcePage(true);
        var date = new Date();
        let startingDate = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD");
        if (titleName === "Today's") {
            setSdate(new Date())
            setEdate(new Date())
            setSearchData(prevState => ({
                ...prevState,
                activationSource: sourceID,
                fromDate: moment(new Date()).format("YYYY-MM-DD"),
                toDate: moment(new Date()).format("YYYY-MM-DD")
            }));
        } else {
            setSdate(new Date(date.getFullYear(), date.getMonth(), 1));
            setEdate(new Date())
            setSearchData(prevState => ({
                ...prevState,
                activationSource: sourceID,
                fromDate: startingDate,
                toDate: moment(new Date()).format("YYYY-MM-DD")
            }));
        }

        const searchItemForToday = {
            companyID: companyID,
            dealerId: dealerID,
            statusID: '12, 23, 24',
            activationSource: sourceID,
            fromDate: moment(new Date()).format("YYYY-MM-DD"),
            toDate: moment(new Date()).format("YYYY-MM-DD"),
        }

        const searchItemForMonth = {
            companyID: companyID,
            dealerId: dealerID,
            statusID: '12, 23, 24',
            activationSource: sourceID,
            fromDate: startingDate,
            toDate: moment(new Date()).format("YYYY-MM-DD"),
        }

        if (titleName === "Today's") {
            dispatch(searchActivationBySource(searchItemForToday));
        } else {
            dispatch(searchActivationBySource(searchItemForMonth));
        }
    }

    const handleViewReportByStatus = (StatusID, titleName) => {
        setsearchBySourcePage(true);
        var date = new Date();
        let startingDate = moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD");
        if (titleName === "Monthly") {
            setSdate(new Date(date.getFullYear(), date.getMonth(), 1));
            setEdate(new Date())
            setSearchData(prevState => ({
                ...prevState,
                activationSource: '',
                statusID: StatusID,
                fromDate: startingDate,
                toDate: moment(new Date()).format("YYYY-MM-DD")
            }));
        } else {
            setSdate('');
            setEdate('')
            setSearchData(prevState => ({
                ...prevState,
                activationSource: '',
                statusID: StatusID,
                fromDate: '',
                toDate: ''
            }));
        }

        const searchItemByMonth = {
            companyID: companyID,
            statusID: StatusID,
            dealerId: dealerID,
            activationSource: '',
            fromDate: startingDate,
            toDate: moment(new Date()).format("YYYY-MM-DD"),
        }

        const searchItemByMonthByTotal = {
            companyID: companyID,
            dealerId: dealerID,
            statusID: StatusID,
            activationSource: '',
            fromDate: '',
            toDate: '',
        }

        if (titleName === "Monthly") {
            dispatch(searchActivationBySource(searchItemByMonth));
        } else {
            dispatch(searchActivationBySource(searchItemByMonthByTotal));
        }
    }

    const handleStatusChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            statusID: e.target.value
        }));
    }

    const handleStartDateChange = (d) => {
        setSdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))
    }

    const handleEndDateChange = (d) => {
        setEdate(d);
        setSearchData(prevState => ({
            ...prevState,
            toDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
    }

    const handleActivationSource = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            activationSource: e.target.value
        }));
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        dispatch(searchActivationBySource(searchData));
    }

    const handleBack = () => {
        setsearchBySourcePage(false)
        setSearchData(prevState => ({
            ...prevState,
            activationSource: '',
            fromDate: '',
            toDate: ''
        }));
        props.monthlyChart(companyID, dealerID);
        props.widgetData(companyID, dealerID);
        props.getUserActivationCountStatus(companyID, dealerID);
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    };

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'ActivationDetails' + fileExtension);
        }
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    const userActivationColumns = [
        {
            name: '#',
            width: "40px",
            selector: row => row.serial,
        },
        {
            name: 'NAME',
            selector: row => row.name,
        },
        {
            name: 'LAST MONTH',
            selector: row => row.lastMonth,
        },
        {
            name: 'MTD',
            // width: "140px",
            selector: row => row.currentMonth,
        },
        {
            name: 'LAST 7 DAYS',
            // width: "70px",
            selector: row => row.last7Days,
        }
    ];

    const filterUserDataCount = props.userActivationCountData && props.userActivationCountData.map((user, key) => {
        return {
            serial: (<p>{key + 1}</p>),
            name: (<p style={{ "whiteSpace": "break-spaces" }}>{user.NAME}</p>),
            userName: (<p>{user.Username}</p>),
            userRole: (<p>{user.UserRole}</p>),
            lastMonth: (<Link to="#" style={{ 'fontWeight': 'bold' }} onClick={() => handleRowClick(user.ID, 'lastMonth')}>{user.LockedLastMonth}</Link>),
            last7Days: (<Link to="#" style={{ 'fontWeight': 'bold' }} onClick={() => handleRowClick(user.ID, 'last7days')}>{user.LockedLast7Days}</Link>),
            currentMonth: (<Link to="#" style={{ 'fontWeight': 'bold' }} onClick={() => handleRowClick(user.ID, 'currentMonth')}>{user.LockedCurrentMonth}</Link>),
            // action: <em className="icon icon-circle bg-primary ni ni-eye" style={{ cursor: 'pointer' }} onClick={() => handleRowClick(user.ID)}></em>
        }
    })

    const handleRowClick = (ID, duration) => {
        let displayStartDate = null;
        let displayEndDate = new Date();
        let startDate = null;
        let endDate = moment();;


        if (duration === 'lastMonth') {
            startDate = moment().subtract(1, 'months').startOf('month');
            endDate = moment().subtract(1, 'months').endOf('month');
            displayStartDate = moment().subtract(1, 'months').startOf('month').toDate();
            displayEndDate = moment().subtract(1, 'months').endOf('month').toDate();
        } else if (duration === 'last7days') {
            displayStartDate = moment().subtract(6, 'days').toDate();
            startDate = moment().subtract(6, 'days');
        } else if (duration === 'currentMonth') {
            displayStartDate = moment().startOf('month').toDate();
            startDate = moment().startOf('month');
        }

        const redirectObj = {
            userId: ID,
            fromDate: startDate.format('YYYY-MM-DD'),
            toDate: endDate.format('YYYY-MM-DD'),
            displayFromDate: displayStartDate,
            displayToDate: displayEndDate
        }
        setDashboardData(redirectObj);
        history.push('/manual-activation-report');
    }

    useEffect(() => {
        return () => {
            setDashboardData(null);
        };
    }, [setDashboardData]);

    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    const tabChange = (currentPos, newPos) => {
        let previousOrder = localStorage.getItem('dashboardTabOrder');
        let initialOrder = [];
        if (previousOrder) {
            initialOrder = JSON.parse(previousOrder);
        } else {
            initialOrder = [0, 1, 2];
        }
        let cval = initialOrder[currentPos];
        let nval = initialOrder[newPos];
        console.log('cval ', cval);
        console.log('nval ', nval);
        initialOrder[currentPos] = nval;
        initialOrder[newPos] = cval;
        // if(cval && nval){
        // }
        // console.log(initialOrder);
        localStorage.setItem('dashboardTabOrder', JSON.stringify(initialOrder));
    }

    return (
        <>
            {!searchBySourcePage && <div className="container-fluid">
                <div className="nk-content-inner">
                    <div className="nk-content-body">
                        <div className="nk-block-head nk-block-head-sm">
                            <div className="nk-block-between">
                                <div className="nk-block-head-content">
                                    <h3 className="nk-block-title page-title">Dashboard</h3>
                                </div>
                            </div>
                        </div>

                        {props.widgetLoading === true ? <div className="text-center"><div className="spinner-border text-primary m-5" role="status">    <span className="visually-hidden">Loading...</span>  </div></div> : <div className="row g-gs mb-4">
                            <div className="flex-container">
                                <div className="row">
                                    <Draggable onPosChange={tabChange}>

                                        {widgetshowData && widgetshowData[0].map((value, key) => (
                                            <div className="col-md-12 col-sm-12 col-xs-12 col-lg-12" key={key}>
                                                <div className="card" style={{ 'borderRadius': '12px' }}>
                                                    <div className="nk-ecwg nk-ecwg6">
                                                        <div className="card-inner ">
                                                            <div className="card-title-group d-flex justify-content-between" style={{ 'borderBottom': "2px solid #6576ff", paddingBottom: '10px' }}>
                                                                <div className="card-title d-flex align-items-center ">
                                                                    <h5 className="card-title text-muted">{value.titleName}</h5> <span className="change up text-danger" style={{ 'fontSize': '12px', marginTop: '5px' }}><em
                                                                        className="icon ni ni-arrow-long-up"></em>Activation</span>
                                                                </div>
                                                                <em className={value.icons} style={{ 'fontSize': '1.5rem' }}></em>
                                                            </div>
                                                            <div className="data">
                                                                <div className="data-group">
                                                                    <h6 className="title">Automation</h6>
                                                                    <h6 className="title">Manual</h6>
                                                                </div>
                                                                <div className="data-group">
                                                                    {value.titleName === "Today's" || value.titleName === "Monthly" || value.titleName === "Last Monthly" ? <h6 className="title" >{value.countAutomation} <Link to="#" onClick={() => handleViewReportsBySource("0", value.titleName)}><em className="icon ni ni-external" style={{ 'cursor': 'pointer' }}></em></Link></h6> : <h6 className="title">{value.countAutomation}</h6>}
                                                                    {value.titleName === "Today's" || value.titleName === "Monthly" || value.titleName === "Last Monthly" ? <h6 className="title" >{value.countManual} <Link to="#" onClick={() => handleViewReportsBySource("1", value.titleName)}><em className="icon ni ni-external" style={{ 'cursor': 'pointer' }}></em></Link></h6> : <h6 className="title">{value.countManual}</h6>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </Draggable>
                                </div>
                            </div>


                        </div>}



                        {/* graph data  */}
                        {/* <div className="nk-block ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h6 className="title">Activation</h6>
                                                </div>
                                            </div>
                                            <ReactApexChart options={options} series={series} type="area" height={400} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="nk-block ">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h6 className="title">Channel Wise Activation <Link to="/channel-activation-report"><em className="icon ni ni-external" style={{ 'cursor': 'pointer' }}></em></Link></h6>
                                                </div>
                                            </div>
                                            <ReactApexChart options={optionsData} series={seriesData} type="bar" height={400} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* monthly activation data */}
                        <div className="nk-block ">
                            <div className="row">
                                <div className={filterArray && filterArray.length > 0 && filterArray[0].includes("show_user_activation_count_report_on_dashboard") ? 'col-md-4' : 'col-md-12'}>
                                    <div className="card my-2" style={{ height: '375px', borderRadius: '12px' }}>
                                        <em className="icon-circle bg-transparent icon ni ni-reload h4" title="Reload" onClick={() => dispatch(monthlyChart(companyID, dealerID))} style={styles.reloadIconMonthlyActivation}></em>
                                        <div className="card-inner" style={{ paddingTop: 'unset' }}>
                                            <div className="card-title-group">
                                                <div className="card-title d-flex">
                                                    <h6 className="title">Monthly Activation Status</h6>
                                                </div>
                                            </div>
                                            {props.monthlyChartLoading === true ? <div className="text-center"><div className="spinner-border text-primary m-5" role="status">    <span className="visually-hidden">Loading...</span>  </div></div> :
                                                <ul className="nk-store-statistics nk-store-statistics-dashboard my-1">
                                                    <li className="item">
                                                        <div className="info">
                                                            <div className="title">Pending </div>
                                                            <Link to="#" className="count" onClick={() => handleViewReportByStatus("10", "Monthly")}>{formateDigits(monthlyseriesData[0])}</Link>
                                                        </div>
                                                        <em className="icon bg-primary-dim ni ni-update"></em>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <div className="title">Processing</div>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Link to="#" className="count" onClick={() => handleViewReportByStatus("11, 18, 19, 20, 21, 22, 27", "Monthly")}>{formateDigits(monthlyseriesData[1])}</Link><Link to="/asset#assetReporttab"><em className="icon ni ni-external" style={{ 'cursor': 'pointer', fontSize: '15px', color: '#9d72ff' }}></em></Link></div>
                                                        </div>
                                                        <em className="icon bg-warning-dim ni ni-reload-alt"></em>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <div className="title">Assigned To Care</div>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}><Link to="#" className="count" onClick={() => handleViewReportByStatus("22, 27", "Monthly")}>{formateDigits(monthlyseriesData[2])}</Link><Link to="/asset#assetReporttab"><em className="icon ni ni-external" style={{ 'cursor': 'pointer', fontSize: '15px', color: '#9d72ff' }}></em></Link></div>
                                                        </div>
                                                        <em className="icon bg-pink-dim ni ni-chevrons-right"></em>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <div className="title">Processed</div>
                                                            <Link to="#" className="count" onClick={() => handleViewReportByStatus("12, 23, 24", "Monthly")}>{formateDigits(monthlyseriesData[3])}</Link>
                                                        </div>
                                                        <em className="icon bg-success-dim ni ni-done"></em>
                                                    </li>
                                                    <li className="item">
                                                        <div className="info">
                                                            <div className="title">Cancelled</div>
                                                            <Link to="#" className="count" onClick={() => handleViewReportByStatus("13", "Monthly")}>{monthlyseriesData[4]}</Link>
                                                        </div>
                                                        <em className="icon bg-danger-dim ni ni-cross"></em>
                                                    </li>
                                                </ul>}

                                        </div>
                                    </div>
                                </div>
                                {filterArray && filterArray.length > 0 && filterArray[0].includes("show_user_activation_count_report_on_dashboard") && <div className="col-md-8">
                                    <div className="card p-2 my-2 manual-activation-card" style={{ borderRadius: '12px' }}>
                                        <em className="icon-circle bg-transparent icon ni ni-reload h4" title="Reload" onClick={() => dispatch(getUserActivationCountStatus(companyID, dealerID))} style={styles.reloadIconManualActivation}></em>
                                        <div className="card-inner">
                                            <div className="card-title-group py-2">
                                                <div className="card-title">
                                                    <h6 className="title">Manual Activation Report</h6>
                                                </div>
                                            </div>
                                            {/* <div className="manual-datatable"> */}
                                            <DataTable
                                                pagination={false}
                                                columns={userActivationColumns}
                                                data={filterUserDataCount}
                                                customStyles={customStyles}
                                                progressPending={props.userCountDataLoading}
                                                progressComponent={<Spinner />}
                                                striped
                                                responsive
                                                fixedHeader
                                                className="manual-activation-datatable"
                                            />
                                            {/* </div> */}
                                        </div>
                                    </div>
                                </div>}

                                {/* <div className="col-md-6">
                                    <div className="card h-100 info-card">
                                        <div className="card-inner">
                                            <div className="card-title-group">
                                                <div className="card-title">
                                                    <h6 className="title">Total Activation Status</h6>
                                                </div>
                                            </div>
                                            <ul className="nk-store-statistics nk-store-statistics-dashboard my-1">
                                                <li className="item">
                                                    <div className="info">
                                                        <div className="title">Pending</div>
                                                        <Link to="#" className="count" onClick={() => handleViewReportByStatus("10", "Totally")}>{overAllseriesData[0]}</Link>
                                                    </div>
                                                    <em className="icon bg-primary-dim ni ni-update"></em>
                                                </li>
                                                <li className="item">
                                                    <div className="info">
                                                        <div className="title">Processing</div>
                                                        <Link to="#" className="count" onClick={() => handleViewReportByStatus("11, 18, 19, 20, 21, 22, 27", "Totally")}>{overAllseriesData[1]}</Link>
                                                    </div>
                                                    <em className="icon bg-warning-dim ni ni-reload-alt"></em>
                                                </li>
                                                <li className="item">
                                                    <div className="info">
                                                        <div className="title">Assigned To Care</div>
                                                        <Link to="#" className="count" onClick={() => handleViewReportByStatus("22, 27", "Totally")}>{overAllseriesData[2]}</Link>
                                                    </div>
                                                    <em className="icon bg-pink-dim ni ni-chevrons-right"></em>
                                                </li>
                                                <li className="item">
                                                    <div className="info">
                                                        <div className="title">Processed</div>
                                                        <Link to="#" className="count " onClick={() => handleViewReportByStatus("12, 23, 24", "Totally")}>{overAllseriesData[3]}</Link>
                                                    </div>
                                                    <em className="icon bg-success-dim ni ni-done"></em>
                                                </li>
                                                <li className="item">
                                                    <div className="info">
                                                        <div className="title">Cancelled</div>
                                                        <Link to="#" className="count" onClick={() => handleViewReportByStatus("13", "Totally")}>{overAllseriesData[4]}</Link>
                                                    </div>
                                                    <em className="icon bg-danger-dim ni ni-cross"></em>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {searchBySourcePage && <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">View Report</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="statusID">Status</label>
                                            <div className="form-control-wrap">
                                                <select name="statusID" className="form-control" id="statusID" value={searchData.statusID} onChange={(e) => handleStatusChange(e)}>
                                                    <option value="" name="AssignedToCare">All</option>
                                                    <option value="22, 27" name="AssignedToCare">Assigned to Care</option>
                                                    <option value="10" name="Pending">Pending</option>
                                                    <option value="11, 18, 19, 20, 21, 22, 27" name="Processing">Processing</option>
                                                    <option value="12, 23, 24" name="Processed">Processed</option>
                                                    <option value="13" name="Cancelled">Cancelled</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-2">
                                        <div className="form-group"><label className="form-label" htmlFor="stareDate">Start Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleStartDateChange(d)} className={"form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="stareDate" closeOnScroll={true} selected={sDate} placeholderText="MM/DD/YYYY" maxDate={new Date()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group"><label className="form-label" htmlFor='endDate'>End Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleEndDateChange(d)} className={"form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="endDate" closeOnScroll={true} selected={eDate} placeholderText="MM/DD/YYYY" maxDate={new Date()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="activationSource">Activation Source</label>
                                            <div className="form-control-wrap">
                                                <select
                                                    name="activationSource"
                                                    className="form-control"
                                                    id="activationSource"
                                                    onChange={(e) => handleActivationSource(e)}
                                                    value={searchData.activationSource}
                                                >
                                                    <option value="" name="select">All</option>
                                                    <option value="0" name="select">Automation</option>
                                                    <option value="1" name="select">Manual</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2'>
                                        <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Search</span> </button>
                                        {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="card p-2" style={{ marginTop: '12px' }}>
                    <div className="card-inner p-0" >
                        <div className='d-flex justify-content-between'>
                            <FilterComponent
                                onFilter={e => setFilterText(e.target.value)}
                                onClear={handleClear}
                                filterText={filterText}
                            /><Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                        </div>
                        <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                        <div className="text-right">
                            <Link to="#" className="btn btn-danger" onClick={() => handleBack()}>Back</Link>
                        </div>
                    </div>
                </div>
            </div>
            }
        </>
    )
}


const styles = {
    reloadIconMonthlyActivation: {
        cursor: "pointer",
        margin: "10px 10px 0 auto",
        padding: "14px",
        color: "#2d3436",
    },
    reloadIconManualActivation: {
        cursor: "pointer",
        // margin: "0px 2px 0px auto",
        // padding: "14px",
        // float : 'right',
        color: "#2d3436",
        position: 'absolute',
        top: '11px',
        right: '25px',
    },
};

const mapStateToProps = (state) => {
    const { allchartData, monthlyChartData, overAllChartData, countActivationByNetworkData, apiError, Message, loading, modalMessage, widgets, sourceData, channelChart, widgetLoading, monthlyChartLoading, userActivationCountData, userCountDataLoading } = state.dashboard;
    return { allchartData, monthlyChartData, overAllChartData, countActivationByNetworkData, apiError, Message, loading, modalMessage, widgets, sourceData, channelChart, widgetLoading, monthlyChartLoading, userActivationCountData, userCountDataLoading };
};
export default withRouter(connect(mapStateToProps, { chartData, monthlyChart, overAllChart, countActivationByNetworkType, widgetData, channelChartData, getUserActivationCountStatus })(Dashboard));