import { PLAN_LIST, PLAN_LIST_SUCCESSFUL, PLAN_API_ERROR } from "./actionTypes"

export const planList = () => {
    return {
        type: PLAN_LIST,
    }
}

export const planListSuccessful = (plan) => {
    return {
        type: PLAN_LIST_SUCCESSFUL,
        payload: plan
    }
}

export const planApiError = (error) => {
    return {
        type: PLAN_API_ERROR,
        payload: error
    }
}