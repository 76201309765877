import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { USER_LIST, ADD_USER, DELETE_USER, EDIT_USER, CHANGE_USER_PASSWORD } from './actionTypes';
import { userApiError, userListSuccessful, apiErrorModal, addUserSuccess, deleteUserSuccess, userPasswordSuccessfull } from './actions';
import { getList, addData, editData, userDeleteData } from '../../helpers/fackBackend_Helper';

function* getUserList({ payload: dealerId }) {
    try {
        const response = yield call(getList, '/users/' + dealerId);
        if (response.Status) {
            yield put(userListSuccessful(response.user));
        }
        else {
            yield put(userApiError(response.Message));
        }
    } catch (error) {
        yield put(userApiError(error));
    }
}

export function* watchGetUserList() {
    yield takeEvery(USER_LIST, getUserList)
}

function* addUser({ payload: { user, dealerId } }) {
    try {
        const response = yield call(addData, '/user', user);
        if (response.Status) {
            const responseList = yield call(getList, '/users/' + dealerId);
            if (responseList.Status) {
                yield put(addUserSuccess(responseList.user, response.Message));
            }
            else {
                yield put(apiErrorModal(responseList.Message));
            }
        }
        else {
            yield put(apiErrorModal(response.Message));
        }
    } catch (error) {
        yield put(userApiError(error));
    }
}

export function* watchAddUser() {
    yield takeEvery(ADD_USER, addUser)
}

function* editUser({ payload: { user, id, dealerId } }) {
    try {
        const response = yield call(editData, '/user/', user, id);
        if (response.Status) {
            const responseList = yield call(getList, '/users/' + dealerId);
            if (responseList.Status) {
                yield put(addUserSuccess(responseList.user, response.Message));
            }
            else {
                yield put(apiErrorModal(responseList.Message));
            }
        }
        else {
            yield put(apiErrorModal(response.Message));
        }
    } catch (error) {
        yield put(apiErrorModal(error));
    }
}
export function* watchEditUser() {
    yield takeEvery(EDIT_USER, editUser)
}

function* deleteUser({ payload: { id, status, deleteData, dealerId } }) {
    try {
        const response = yield call(userDeleteData, '/user/' + id + '/' + status, deleteData);
        if (response.Status) {
            const responseList = yield call(getList, '/users/' + dealerId);
            if (responseList.Status) {
                yield put(deleteUserSuccess(responseList.user, response.Message));
            }
            else {
                yield put(userApiError(responseList.message));
            }
        }
        else {
            yield put(userApiError(response.message));
        }
    } catch (error) {
        yield put(userApiError(error));
    }
}

export function* watchDeleteUser() {
    yield takeEvery(DELETE_USER, deleteUser)
}

function* changeUserPassword({ payload: { changePasswordData, dealerId } }) {
    try {
        const response = yield call(addData, '/changeUserPassword', changePasswordData);
        if (response.Status) {

            const responseList = yield call(getList, '/users/' + dealerId);
            if (responseList.Status) {

                yield put(userPasswordSuccessfull(responseList.user, response.Message))
            } else {
                yield put(userApiError(response.Message));
            }
        }
    } catch (error) {
        yield put(userApiError(error));
    }
}
export function* watchUserPasswordChange() {
    yield takeEvery(CHANGE_USER_PASSWORD, changeUserPassword)
}

function* userSaga() {
    yield all([
        fork(watchGetUserList),
        fork(watchAddUser),
        fork(watchDeleteUser),
        fork(watchEditUser),
        fork(watchUserPasswordChange)
    ]);
}

export default userSaga;