import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { getConfigData, updateChannelConfig, resetAlertConfig } from '../store/channelConfig/actions';
import { PERMISSIONS } from '../Permission/Permission';
import Spinner from '../components/layout/Spinner';

function ChannelConfig(props) {
    const dispatch = useDispatch();
    const [RoleID, setRoleID] = useState(null);
    const [CompanyID, setCompanyID] = useState('')
    const [loginID, setLoginID] = useState('')
    const [editPasswordPrefix, seteditPasswordPrefix] = useState({
        ChannelID: '',
        ID: '',
        PasswordPrefix: ''
    })

    const [arrayData, setArrayData] = useState([]);

    useEffect(() => {
        const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
        const loginId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).ID : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        setRoleID(roleId);
        setCompanyID(companyId)
        setLoginID(loginId);
        props.getConfigData(companyId);
        seteditPasswordPrefix(prevState => ({
            ...prevState,
            companyId: companyId,
            CreatedByLoginID: loginId
        }));
    }, [])

    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    const handleChange = (e, ChannelID) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setArrayData(prevState => ({
            ...prevState,
            [ChannelID]: fvalue,
        }));
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let newArray = []
        let entries = Object.entries(arrayData)
        entries.map(([key, val]) => {
            const dataObj = {
                "channelId": key,
                "passwordPrefix": val,
                "createdByLoginID": loginID
            }
            newArray.push(dataObj);

        });
        const configAddData = {};
        configAddData.allConfigData = newArray;

        if (newArray.length > 0) {
            dispatch(updateChannelConfig(configAddData, CompanyID));
        }
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Password Config</h3>
            <div className="card p-2" style={{ marginTop: '12px' }}>
                {props.Message &&
                    <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.Message}</strong></div>}
                <span id="passerror" style={{ 'color': "red" }}></span>
                <div className="card  -inner p-0" >

                    {props.loading === true ? <div style={{ 'display': 'flex', 'justifyContent': 'center', 'alignItems': "center" }}><Spinner></Spinner></div> : <form onSubmit={handleSubmit}>
                        <table className="configTable">
                            <tr className="configRow" key="0">
                                <th className="configHead">CHANNEL</th>
                                <th className='configHead'>EXISTING PREFIX</th>
                                <th className='configHead'>NEW PASSWORD PREFIX</th>
                            </tr>
                            {props.configData && props.configData.map((data, key) => {
                                return (
                                    <tr className="configRow" key={key}>
                                        <td className='configCol' >{data.ChannelName === null ? 'No Channel Name' : data.ChannelName}</td>
                                        <td className='configCol' >{data.PasswordPrefix === null ? 'No Password Prefix' : data.PasswordPrefix}</td>
                                        <td className='configCol'>
                                            {data.PasswordPrefix === null ? <input type="text" className='form-control' name="passwordPrefix" maxLength="1" onChange={(e) => handleChange(e, data.dealerID, data.ID, data.PasswordPrefix)}></input> : <input type="text" className='form-control' name="passwordPrefix" maxLength="1" onChange={(e) => handleChange(e, data.ChannelID, data.ID, data.PasswordPrefix)}></input>}
                                        </td>
                                    </tr>
                                )
                            })}
                        </table>
                        {filterArray && filterArray.length > 0 && filterArray[0].includes("add_password_config") &&
                            <button type='submit' disabled={props.loading} className="btn btn-primary m-2" style={{ "float": "right" }}>{props.loading === true && <span
                                className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                <span>Save</span> </button>
                            // <button className="btn btn-primary m-2" type='submit' style={{ "float": "right" }}>Save</button>
                        }
                    </form>}
                </div>
            </div>
        </div >
    )
}
const mapStateToProps = state => {
    const { configData, apiError, loading, Message } = state.channelConfig;
    return { configData, apiError, loading, Message };
}

export default withRouter(connect(mapStateToProps, { getConfigData, updateChannelConfig, resetAlertConfig })(ChannelConfig));