import {
    GET_DEALER_POS,
    GET_DEALER_POS_SUCCESS,
    GET_DEALER_POS_FAILED,
    GET_DEALER_BY_CHANNEL_ID_AND_ACODE,
    GET_DEALER_BY_CHANNEL_ID_AND_ACODE_SUCCESS,
    GET_DEALER_BY_CHANNEL_ID_AND_ACODE_FAILED,
    RESET_ADDON_FORM,
    POS_DEALER_MAP,
    POS_DEALER_MAP_SUCCESS,
    POS_DEALER_MAP_FAILED,
    POS_DEALER_MAP_MODAL,
} from "./actionTypes";

const initialState = {
    /*************/
    dealerPosData: [],
    // dealerPosLoading: false,
    // dealerPosMessage: null,
    // dealerPosError : null,
    dealerData: [],
    dealerLoading: false,
    dealerMessage: null,
    dealerError: null,
    dealerPosLoading: false,
    dealerPosMessage: null,
    dealerPosError: null,
    dealerMapLoading: false,
    dealerMapMessage: null,
    dealerMapError: null,
    dealerPosMapModal: false,
}

const dealerPos = (state = initialState, action) => {
    switch (action.type) {
        case POS_DEALER_MAP_MODAL:
            state = {
                ...state,
                dealerPosMapModal: action.payload.modal,
            }
            break;

        case POS_DEALER_MAP:
            state = {
                ...state,
                dealerMapLoading: true,
                dealerMapMessage: null,
                dealerMapError: null
            }
            break;

        case POS_DEALER_MAP_SUCCESS:
            state = {
                ...state,
                dealerMapLoading: false,
                dealerPosMapModal: false,
                dealerMapMessage: action.payload,
                dealerMapError: null
            }
            break;

        case POS_DEALER_MAP_FAILED:
            state = {
                ...state,
                dealerMapLoading: false,
                dealerMapMessage: action.payload,
                dealerMapError: action.payload,
            }
            break;

        case RESET_ADDON_FORM:
            state = {
                ...state,
                dealerLoading: false,
                dealerMessage: null,
                dealerError: null,
                dealerData: [],
            }
            break;

        case GET_DEALER_BY_CHANNEL_ID_AND_ACODE:
            state = {
                ...state,
                dealerLoading: true,
                dealerMessage: null,
                dealerError: null
            }
            break;

        case GET_DEALER_BY_CHANNEL_ID_AND_ACODE_SUCCESS:
            state = {
                ...state,
                dealerData: action.payload,
                dealerLoading: false,
                dealerMessage: null,
                dealerError: null
            }
            break;

        case GET_DEALER_BY_CHANNEL_ID_AND_ACODE_FAILED:
            state = {
                ...state,
                dealerLoading: false,
                dealerMessage: action.payload,
                dealerError: null,
                dealerData: [],
            }
            break;

        case GET_DEALER_POS:
            state = {
                ...state,
                dealerPosLoading: true,
                dealerPosMessage: null,
                dealerPosError: null,
                dealerMapMessage: null,
            }
            break;

        case GET_DEALER_POS_SUCCESS:
            state = {
                ...state,
                dealerPosData: action.payload,
                dealerPosLoading: false,
                dealerPosMessage: null,
                dealerPosError: null
            }
            break;

        case GET_DEALER_POS_FAILED:
            state = {
                ...state,
                dealerPosLoading: false,
                dealerPosMessage: action.payload,
                dealerPosError: null
            }
            break;
        /*************************************** */

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default dealerPos;