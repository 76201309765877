import { ASSIGN_DEALER_ACTIVATION, ASSIGN_DEALER_ACTIVATION_SUCCESSFULL, ASSIGN_DEALER_ACTIVATION_FAILED, RESET_ASSIGN_DEALER } from "./actionTypes";

const initialState = {
    apiError: null, loading: false, assignResponse: null
}

const assignActivation = (state = initialState, action) => {
    switch (action.type) {
        case ASSIGN_DEALER_ACTIVATION:
            state = {
                ...state,
                loading: true,
                apiError: null,
                assignResponse: null
            }
            break;

        case ASSIGN_DEALER_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                assignResponse: action.payload.assignResponse,
                apiError: null,
            }
            break;
        case ASSIGN_DEALER_ACTIVATION_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload.Message,
            }
            break;
        case RESET_ASSIGN_DEALER:
            state = {
                ...state,
                assignResponse: null,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default assignActivation;