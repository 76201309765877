import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import logologin from '../../assets/images/virtuzo-logo.png'
import logoSmall from '../../assets/images/logo-sm.png';
import { PERMISSIONS } from '../../Permission/Permission';

function SideNav() {

    const { pathname } = useLocation();
    const page = pathname.replace("/", "");
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const [sideBar, setSideBar] = useState(true);
    const [adminMenu, setAdminMenu] = useState(false);
    const [activation, setActivation] = useState(false);
    const [report, setreport] = useState(false);
    const [RoleID, setRoleId] = useState(1);
    const [LoginId, setLoginId] = useState(null);

    useEffect(() => {
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        const loginId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        setRoleId(roleId);
        setLoginId(loginId);
    }, []);

    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    function sideBarOpen() {
        setCollapseSidebar(true);
        setSideBar(false);
    }
    function handleSideCollapse() {
        setCollapseSidebar(false);
        setSideBar(true);
    }

    function handleAdmin() {
        adminMenu === false ? setAdminMenu(true) : setAdminMenu(false);
        setActivation(false);
        setreport(false);
    }

    function handleActivation() {
        activation === false ? setActivation(true) : setActivation(false);
        setAdminMenu(false);
        setreport(false);
    }

    function handleReport() {
        report === false ? setreport(true) : setreport(false);
        setActivation(false);
        setAdminMenu(false);
    }

    return (
        <>
            {sideBar && <div className="nk-sidebar nk-sidebar-fixed is-light " data-content="sidebarMenu" style={{ "zIndex": "1000" }}>
                <div className="nk-sidebar-element nk-sidebar-head">
                    <div className="nk-sidebar-brand">
                        <Link to="/dashboard" className="logo-link nk-sidebar-logo">
                            <img className="logo-dark logo-img" src={logologin} width="130px" alt="logo-dark" />
                            <img className="logo-small logo-img logo-img-small" src={logoSmall} alt="logo-small" />
                        </Link>
                    </div>
                    <div className="nk-menu-trigger me-n2">
                        <div className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></div>
                        <div className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex" data-target="sidebarMenu"><em className="icon ni ni-menu" onClick={sideBarOpen} style={{ 'cursor': 'pointer' }} ></em></div>
                    </div>
                </div>
                <div className="nk-sidebar-element">
                    <div className="nk-sidebar-content" style={{ 'overflow': 'auto' }}>
                        <div className="nk-sidebar-menu" data-simplebar>
                            <ul className="nk-menu">

                                {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dashboard_page") && <li className="nk-menu-item">
                                    <NavLink to="/dashboard" className="nk-menu-link" activeclassname="active">
                                        <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                                        <span className="nk-menu-text" style={{ background: page === "dashboard" }}>Dashboard</span>
                                    </NavLink>
                                </li>}

                                {filterArray && filterArray.length > 0 && filterArray[0].includes("view_verify_dealer_page") && <li className="nk-menu-item">
                                    <NavLink to="/verify-dealer" className="nk-menu-link" activeclassname="active">
                                        <span className="nk-menu-icon"><em className="icon ni ni-user-check"></em></span>
                                        <span className="nk-menu-text">Verify Dealer</span>
                                    </NavLink>
                                </li>}

                                {filterArray && filterArray.length > 0 && filterArray[0].includes("assign_dealer_activation") && <li className="nk-menu-item">
                                    <NavLink to="/assign-dealer" className="nk-menu-link" activeclassname="active">
                                        <span className="nk-menu-icon"><em class="icon ni ni-link-alt"></em></span>
                                        <span className="nk-menu-text">Assign Dealer</span>
                                    </NavLink>
                                </li>}

                                {RoleID !== 30 && RoleID !== 34 && <li className="nk-menu-item has-sub">
                                    <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleAdmin}>
                                        <span className="nk-menu-icon"><em className="icon ni ni-user-list-fill"></em></span>
                                        <span className="nk-menu-text mx-2">Admin</span>
                                    </div>
                                    {adminMenu && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_user_page") && <li className="nk-menu-item">
                                            <NavLink to="/user" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-user"></em></span>
                                                <span className="nk-menu-text">User</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dealer_page") && <li className="nk-menu-item">
                                            <NavLink to="/dealer" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-users"></em></span>
                                                <span className="nk-menu-text">Dealer</span>
                                            </NavLink>
                                        </li>}
                                        {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("pos_dealer_mapping") && <li className="nk-menu-item">
                                            <NavLink to="/pos_dealer_mapping" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-link"></em></span>
                                                <span className="nk-menu-text">POS Dealer Mapping</span>
                                            </NavLink>
                                        </li>} */}
                                        {LoginId === 2 && <li className="nk-menu-item">
                                            <NavLink to="/dealer-email-config" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-mail"></em></span>
                                                <span className="nk-menu-text">Dealer Email Config</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_plan_page") && <li className="nk-menu-item">
                                            <NavLink to="/plan" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-file-text"></em></span>
                                                <span className="nk-menu-text">Plan</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_addon_page") && <li className="nk-menu-item">
                                            <NavLink to="/addon-service" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-row-view"></em></span>
                                                <span className="nk-menu-text">Addon Service</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_password_config_page") && <li className="nk-menu-item">
                                            <NavLink to="/password-config" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-setting-fill"></em></span>
                                                <span className="nk-menu-text">Password Config</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_notification_page") && <li className="nk-menu-item">
                                            <NavLink to="/notification" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-notice"></em></span>
                                                <span className="nk-menu-text">Notification</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_server_page") && <li className="nk-menu-item">
                                            <NavLink to="/server" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-list-index"></em></span>
                                                <span className="nk-menu-text">Server List</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_asset_page") && <li className="nk-menu-item">
                                            <NavLink to="/asset" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-text-a"></em></span>
                                                <span className="nk-menu-text">Asset</span>
                                            </NavLink>
                                        </li>}
                                        {/* filterArray && filterArray.length > 0 && filterArray[0].includes("jar_upload") */}
                                        {true && <li className="nk-menu-item">
                                            <NavLink to="/upload-jar" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-b-chrome"></em></span>
                                                <span className="nk-menu-text">JAR Download</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("aws_ec2") && <li className="nk-menu-item">
                                            <NavLink to="/aws-instances" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-amazon"></em></span>
                                                <span className="nk-menu-text">AWS Instances</span>
                                            </NavLink>
                                        </li>}
                                        {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("jar_configuration") && <li className="nk-menu-item">
                                            <NavLink to="/jar-config" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-notice"></em></span>
                                                <span className="nk-menu-text">JAR Configuration</span>
                                            </NavLink>
                                        </li>} */}
                                        {/* <li className="nk-menu-item">
                                            <NavLink to="/jar-path" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-share-alt"></em></span>
                                                <span className="nk-menu-text">Jar X Path</span>
                                            </NavLink>
                                        </li> */}
                                    </ul>}
                                </li>}

                                {true && <li className="nk-menu-item has-sub">
                                    <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleActivation}>
                                        <span className="nk-menu-icon"><em className="icon ni ni-regen"></em></span>
                                        <span className="nk-menu-text mx-2">Activation</span>
                                    </div>
                                    {activation && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_activation_page") && <li className="nk-menu-item">
                                            <NavLink to="/view-activation" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-swap"></em></span>
                                                <span className="nk-menu-text">Activation Request</span>
                                            </NavLink>
                                        </li>}
                                        {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("view_paused_activation_page") && <li className="nk-menu-item">
                                            <NavLink to="/paused-activation" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-pause-fill"></em></span>
                                                <span className="nk-menu-text">Paused Request</span>
                                            </NavLink>
                                        </li>} */}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_lock_activation_request_page") && <li className="nk-menu-item">
                                            <NavLink to="/lock-activation" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-lock-alt-fill"></em></span>
                                                <span className="nk-menu-text">Locked Activation</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("pending_activation") && <li className="nk-menu-item">
                                            <NavLink to="/pending-activation" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-clock-fill"></em></span>
                                                <span className="nk-menu-text">Pending Activation</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("repush_activation") && <li className="nk-menu-item">
                                            <NavLink to="/repush-activation" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-redo"></em></span>
                                                <span className="nk-menu-text">Repush Activation</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("bulk_cancellation") && <li className="nk-menu-item">
                                            <NavLink to="/bulk-cancellation" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><i class="icon ni ni-book"></i></span>
                                                <span className="nk-menu-text">Bulk Cancellation</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("sim_swap_request") && <li className="nk-menu-item">
                                            <NavLink to="/sim-swap-request" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em class="icon ni ni-swap-alt"></em> </span>
                                                <span className="nk-menu-text">SIM Swap Request</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("email_inbox") && <li className="nk-menu-item">
                                            <NavLink to="/email-inbox" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em class="icon ni ni-mail"></em> </span>
                                                <span className="nk-menu-text">E-Mail Inbox</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("update_zipcode") && <li className="nk-menu-item">
                                            <NavLink to="/update-zipcode" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-location"></em></span>
                                                <span className="nk-menu-text">Update Zipcode</span>
                                            </NavLink>
                                        </li>}
                                    </ul>}
                                </li>}

                                <li className="nk-menu-item has-sub">
                                    <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleReport}>
                                        <span className="nk-menu-icon"><em className="icon ni ni-sign-idr-alt"></em></span>
                                        <span className="nk-menu-text mx-2">Report</span>
                                    </div>
                                    {report && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                        <li className="nk-menu-item">
                                            {/* <NavLink to="/user-activation-report" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-expand"></em></span>
                                                <span className="nk-menu-text">User Activation Report</span>
                                            </NavLink> */}
                                        </li>
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dealer_report_page") && <li className="nk-menu-item">
                                            <NavLink to="/dealer-activation-report" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-report-profit"></em></span>
                                                <span className="nk-menu-text">Dealer Activation Report</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_Activation_report_page") && <li className="nk-menu-item">
                                            <NavLink to="/activation-report" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-reports"></em></span>
                                                <span className="nk-menu-text">Activation Report</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_Channel_Activation_report") && <li className="nk-menu-item">
                                            <NavLink to="/channel-activation-report" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-layers-fill"></em></span>
                                                <span className="nk-menu-text">Channel Activation Report</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_manual_activation_report_page") && <li className="nk-menu-item">
                                            <NavLink to="/manual-activation-report" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-shuffle"></em></span>
                                                <span className="nk-menu-text">Manual Activation Report</span>
                                            </NavLink>
                                        </li>}

                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("plan_wise_activation_report") &&
                                            <li className="nk-menu-item">
                                                <NavLink to="/plan-wise-activation-report" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-book"></em></span>
                                                    <span className="nk-menu-text">Plan Wise Activation Report</span>
                                                </NavLink>
                                            </li>}

                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("asset_performance_report") && <li className="nk-menu-item">
                                            <NavLink to="/asset-performance-report" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-growth"></em></span>
                                                <span className="nk-menu-text">Asset Performance Report</span>
                                            </NavLink>
                                        </li>}
                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("validate_epay_report") && <li className="nk-menu-item">
                                            <NavLink to="/validate-epay-report" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em class="icon ni ni-file-check"></em></span>
                                                <span className="nk-menu-text">Validate Epay Report</span>
                                            </NavLink>
                                        </li>}

                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("late_activated_txn_report") &&
                                            <li className="nk-menu-item">
                                                <NavLink to="/late-activated-txn-report" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-book"></em></span>
                                                    <span className="nk-menu-text">Late Activation Report</span>
                                                </NavLink>
                                            </li>
                                        }
                                    </ul>}
                                </li>


                                {/* <li className="nk-menu-item" style={{ "marginTop": "148%", "borderTop": "1px solid #cacdd1" }}>
                                    <NavLink to="/help" className="nk-menu-link" activeclassname="active" data-bs-original-title="" title="">
                                        <span className="nk-menu-icon"><em className="icon ni ni-help"></em></span>
                                        <span className="nk-menu-text">Help</span>
                                    </NavLink>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>}

            {/* hover sidebar */}
            {collapseSidebar && <div className="nk-sidebar nk-sidebar-fixed is-light is-compact" data-content="sidebarMenu">
                <div className="nk-sidebar-element nk-sidebar-head">
                    <div className="nk-sidebar-brand">
                        <Link to="/dashboard" className="logo-link nk-sidebar-logo">
                            <img className="logo-dark logo-img" src={logologin} alt="logo-dark" />
                            <img className="logo-small logo-img logo-img-small" src={logoSmall} alt="logo-small" />
                        </Link>
                    </div>
                    <div className="nk-menu-trigger me-n2">
                        <div className="nk-nav-toggle nk-quick-nav-icon d-xl-none" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></div>
                        <div className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex compact-active" onClick={handleSideCollapse} data-target="sidebarMenu"><em className="icon ni ni-menu" style={{ 'cursor': 'pointer' }}></em></div>
                    </div>
                </div>
                <div className="nk-sidebar-element">
                    <div className="nk-sidebar-content">
                        <div className="nk-sidebar-menu" data-simplebar="init"><div className="simplebar-wrapper" style={{ 'margin': '-16px 0px -40px' }}><div className="simplebar-height-auto-observer-wrapper">
                            <div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask">
                                <div className="simplebar-offset" style={{ 'marginRight': '0px', 'marginBottom': '0px' }}>
                                    <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{ 'height': '100%', 'overflow': 'scroll' }}>
                                        <div className="simplebar-content" style={{ 'padding': '16px 0px 40px' }}>
                                            <ul className="nk-menu">
                                                {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dashboard_page") && <li className="nk-menu-item">
                                                    <NavLink to="/dashboard" className="nk-menu-link" activeclassname="active">
                                                        <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                                                        <span className="nk-menu-text" style={{ background: page === "dashboard" }}>Dashboard</span>
                                                    </NavLink>
                                                </li>}
                                                {filterArray && filterArray.length > 0 && filterArray[0].includes("view_verify_dealer_page") && <li className="nk-menu-item">
                                                    <NavLink to="/verify-dealer" className="nk-menu-link" activeclassname="active">
                                                        <span className="nk-menu-icon"><em className="icon ni ni-user-check"></em></span>
                                                        <span className="nk-menu-text">Verify Dealer</span>
                                                    </NavLink>
                                                </li>}
                                                {filterArray && filterArray.length > 0 && filterArray[0].includes("assign_dealer_activation") && <li className="nk-menu-item">
                                                    <NavLink to="/assign-dealer" className="nk-menu-link" activeclassname="active">
                                                        <span className="nk-menu-icon"><em class="icon ni ni-link-alt"></em></span>
                                                        <span className="nk-menu-text">Assign Dealer</span>
                                                    </NavLink>
                                                </li>}
                                                {RoleID !== 30 && RoleID !== 34 && <li className="nk-menu-item has-sub">
                                                    <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleAdmin}>
                                                        <span className="nk-menu-icon"><em className="icon ni ni-user-list-fill"></em></span>
                                                        <span className="nk-menu-text mx-2">Admin</span>
                                                    </div>
                                                    {adminMenu && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_user_page") && <li className="nk-menu-item">
                                                            <NavLink to="/user" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-user"></em></span>
                                                                <span className="nk-menu-text">User</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dealer_page") && <li className="nk-menu-item">
                                                            <NavLink to="/dealer" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-users"></em></span>
                                                                <span className="nk-menu-text">Dealer</span>
                                                            </NavLink>
                                                        </li>}
                                                        {LoginId === 2 && <li className="nk-menu-item">
                                                            <NavLink to="/dealer-email-config" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-mail"></em></span>
                                                                <span className="nk-menu-text">Dealer Email Config</span>
                                                            </NavLink>
                                                        </li>}
                                                        {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("pos_dealer_mapping") && <li className="nk-menu-item">
                                                            <NavLink to="/pos_dealer_mapping" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-link"></em></span>
                                                                <span className="nk-menu-text">POS Dealer Mapping</span>
                                                            </NavLink>
                                                        </li>} */}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_plan_page") && <li className="nk-menu-item">
                                                            <NavLink to="/plan" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-file-text"></em></span>
                                                                <span className="nk-menu-text">Plan</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_addon_page") && <li className="nk-menu-item">
                                                            <NavLink to="/addon-service" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-row-view"></em></span>
                                                                <span className="nk-menu-text">Addon Service</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_password_config_page") && <li className="nk-menu-item">
                                                            <NavLink to="/password-config" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-setting-fill"></em></span>
                                                                <span className="nk-menu-text">Password Config</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_notification_page") && <li className="nk-menu-item">
                                                            <NavLink to="/notification" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-notice"></em></span>
                                                                <span className="nk-menu-text">Notification</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_server_page") && <li className="nk-menu-item">
                                                            <NavLink to="/server" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-list-index"></em></span>
                                                                <span className="nk-menu-text">Server List</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_asset_page") && <li className="nk-menu-item">
                                                            <NavLink to="/asset" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-text-a"></em></span>
                                                                <span className="nk-menu-text">Asset</span>
                                                            </NavLink>
                                                        </li>}
                                                        {/* filterArray && filterArray.length > 0 && filterArray[0].includes("jar_upload") */}
                                                        {true && <li className="nk-menu-item">
                                                            <NavLink to="/upload-jar" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-b-chrome"></em></span>
                                                                <span className="nk-menu-text">JAR Download</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("aws_ec2") && <li className="nk-menu-item">
                                                            <NavLink to="/aws-instances" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-amazon"></em></span>
                                                                <span className="nk-menu-text">AWS Instances</span>
                                                            </NavLink>
                                                        </li>}
                                                        {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("jar_configuration") && <li className="nk-menu-item">
                                                            <NavLink to="/jar-config" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-notice"></em></span>
                                                                <span className="nk-menu-text">JAR Configuration</span>
                                                            </NavLink>
                                                        </li>} */}
                                                        {/* <li className="nk-menu-item">
                                            <NavLink to="/jar-path" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-share-alt"></em></span>
                                                <span className="nk-menu-text">Jar X Path</span>
                                            </NavLink>
                                        </li> */}
                                                    </ul>}
                                                </li>}

                                                {true && <li className="nk-menu-item has-sub">
                                                    <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleActivation}>
                                                        <span className="nk-menu-icon"><em className="icon ni ni-regen"></em></span>
                                                        <span className="nk-menu-text mx-2">Activation</span>
                                                    </div>
                                                    {activation && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_activation_page") && <li className="nk-menu-item">
                                                            <NavLink to="/view-activation" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-swap"></em></span>
                                                                <span className="nk-menu-text">Activation Request</span>
                                                            </NavLink>
                                                        </li>}
                                                        {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("view_paused_activation_page") && <li className="nk-menu-item">
                                                            <NavLink to="/paused-activation" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-pause-fill"></em></span>
                                                                <span className="nk-menu-text">Paused Request</span>
                                                            </NavLink>
                                                        </li>} */}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_lock_activation_request_page") && <li className="nk-menu-item">
                                                            <NavLink to="/lock-activation" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-lock-alt-fill"></em></span>
                                                                <span className="nk-menu-text">Locked Activation</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("pending_activation") && <li className="nk-menu-item">
                                                            <NavLink to="/pending-activation" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-clock-fill"></em></span>
                                                                <span className="nk-menu-text">Pending Activation</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("repush_activation") && <li className="nk-menu-item">
                                                            <NavLink to="/repush-activation" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-redo"></em></span>
                                                                <span className="nk-menu-text">Repush Activation</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("sim_swap_request") && <li className="nk-menu-item">
                                                            <NavLink to="/sim-swap-request" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em class="icon ni ni-swap-alt"></em> </span>
                                                                <span className="nk-menu-text">SIM Swap Request</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("email_inbox") && <li className="nk-menu-item">
                                                            <NavLink to="/email-inbox" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em class="icon ni ni-mail"></em> </span>
                                                                <span className="nk-menu-text">E-Mail Inbox</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("update_zipcode") && <li className="nk-menu-item">
                                                            <NavLink to="/update-zipcode" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-location"></em></span>
                                                                <span className="nk-menu-text">Update Zipcode</span>
                                                            </NavLink>
                                                        </li>}
                                                    </ul>}
                                                </li>}

                                                <li className="nk-menu-item has-sub">
                                                    <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleReport}>
                                                        <span className="nk-menu-icon"><em className="icon ni ni-sign-idr-alt"></em></span>
                                                        <span className="nk-menu-text mx-2">Report</span>
                                                    </div>
                                                    {report && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                                        <li className="nk-menu-item">
                                                            {/* <NavLink to="/user-activation-report" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-expand"></em></span>
                                                <span className="nk-menu-text">User Activation Report</span>
                                            </NavLink> */}
                                                        </li>
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dealer_report_page") && <li className="nk-menu-item">
                                                            <NavLink to="/dealer-activation-report" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-report-profit"></em></span>
                                                                <span className="nk-menu-text">Dealer Activation Report</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_Activation_report_page") && <li className="nk-menu-item">
                                                            <NavLink to="/activation-report" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-reports"></em></span>
                                                                <span className="nk-menu-text">Activation Report</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_Channel_Activation_report") && <li className="nk-menu-item">
                                                            <NavLink to="/channel-activation-report" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-layers-fill"></em></span>
                                                                <span className="nk-menu-text">Channel Activation Report</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("view_manual_activation_report_page") && <li className="nk-menu-item">
                                                            <NavLink to="/manual-activation-report" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-shuffle"></em></span>
                                                                <span className="nk-menu-text">Manual Activation Report</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("plan_wise_activation_report") && <li className="nk-menu-item">
                                                            <NavLink to="/plan-wise-activation-report" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-book"></em></span>
                                                                <span className="nk-menu-text">Plan Wise Activation Report</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("asset_performance_report") && <li className="nk-menu-item">
                                                            <NavLink to="/asset-performance-report" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-growth"></em></span>
                                                                <span className="nk-menu-text">Asset Performance Report</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("validate_epay_report") && <li className="nk-menu-item">
                                                            <NavLink to="/validate-epay-report" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em class="icon ni ni-file-check"></em></span>
                                                                <span className="nk-menu-text">Validate Epay Report</span>
                                                            </NavLink>
                                                        </li>}
                                                        {filterArray && filterArray.length > 0 && filterArray[0].includes("late_activated_txn_report") && <li className="nk-menu-item">
                                                            <NavLink to="/late-activated-txn-report" className="nk-menu-link" activeclassname="active">
                                                                <span className="nk-menu-icon"><em className="icon ni ni-book"></em> </span>
                                                                <span className="nk-menu-text">Late Activation Report</span>
                                                            </NavLink>
                                                        </li>}
                                                    </ul>}

                                                </li>


                                                {/* <li className="nk-menu-item" style={{ "marginTop": "148%", "borderTop": "1px solid #cacdd1" }}>
                                    <NavLink to="/help" className="nk-menu-link" activeclassname="active" data-bs-original-title="" title="">
                                        <span className="nk-menu-icon"><em className="icon ni ni-help"></em></span>
                                        <span className="nk-menu-text">Help</span>
                                    </NavLink>
                                </li> */}
                                            </ul>
                                        </div></div></div></div><div className="simplebar-placeholder" style={{ 'width': 'auto', 'height': '1044px' }}></div></div><div className="simplebar-track simplebar-horizontal" style={{ 'visibility': 'visible' }}><div className="simplebar-scrollbar" style={{ 'width': '25px', 'display': 'block', 'transform': 'translate3d(0px, 0px, 0px)' }}></div></div><div className="simplebar-track simplebar-vertical" style={{ 'visibility': 'visible' }}><div className="simplebar-scrollbar" style={{ 'height': '502px', 'transform': 'translate3d(0px, 0px, 0px)', 'display': 'block' }}></div></div></div>
                    </div>
                </div>
            </div>}
        </>
    )
}
export default SideNav;