import {
    DEALER_EMAIL_CONFIG_LIST,
    DEALER_EMAIL_CONFIG_SUCCESSFUL,
    DEALER_EMAIL_CONFIG_ERROR,
    CLOSE_EMAIL_CONFIG_MODAL,
    OPEN_EMAIL_CONFIG_MODAL,
    ADD_DEALER_EMAIL_CONFIG,
    ADD_DEALER_EMAIL_CONFIG_SUCCESS,
    ADD_DEALER_EMAIL_CONFIG_FAILED,
    EDIT_DEALER_EMAIL_CONFIG,
    EDIT_DEALER_EMAIL_CONFIG_SUCCESS,
    EDIT_DEALER_EMAIL_CONFIG_FAILED,
    DELETE_DEALER_EMAIL_CONFIG,
    DELETE_DEALER_EMAIL_CONFIG_SUCCESS,
    DELETE_DEALER_EMAIL_CONFIG_FAILED,
    RESET_DEALER_EMAIL_CONFIG_MESSAGE,
} from "./actionTypes"

export const dealerEmailConfigList = (companyID, channelId) => {
    return {
        type: DEALER_EMAIL_CONFIG_LIST,
        payload: { companyID, channelId }
    }
}

export const dealerEmailConfigListSuccessful = (dealerEmailData) => {
    return {
        type: DEALER_EMAIL_CONFIG_SUCCESSFUL,
        payload: dealerEmailData
    }
}

export const dealerEmailConfigError = (error) => {
    return {
        type: DEALER_EMAIL_CONFIG_ERROR,
        payload: error
    }
}

export const openEmailConfigModal = () => {
    return {
        type: OPEN_EMAIL_CONFIG_MODAL
    }
}
export const closeEmailConfigModal = () => {
    return {
        type: CLOSE_EMAIL_CONFIG_MODAL
    }
}


export const addDealerEmailConfig = (dealerEmailData, searchDealer) => {
    return {
        type: ADD_DEALER_EMAIL_CONFIG,
        payload: { dealerEmailData, searchDealer }
    }
}
export const addDealerEmailConfigsucess = (message) => {
    return {
        type: ADD_DEALER_EMAIL_CONFIG_SUCCESS,
        payload: {message }
    }
}
export const addDealerEmailConfigfailed = (error) => {
    return {
        type: ADD_DEALER_EMAIL_CONFIG_FAILED,
        payload: { error }
    }
}

export const editDealerEmailConfig = (dealerEmailData,id,searchDealer) => {
    return {
        type: EDIT_DEALER_EMAIL_CONFIG,
        payload: { dealerEmailData,id,searchDealer }
    }
}
export const editDealerEmailConfigsucess = (message) => {
    return {
        type: EDIT_DEALER_EMAIL_CONFIG_SUCCESS,
        payload: {message }
    }
}
export const editDealerEmailConfigfailed = (error) => {
    return {
        type: EDIT_DEALER_EMAIL_CONFIG_FAILED,
        payload: { error }
    }
}

export const deleteDealerEmailConfig = (dealerEmailData, id, status, searchDealer) => {
    return {
        type: DELETE_DEALER_EMAIL_CONFIG,
        payload: {dealerEmailData,id,status,searchDealer }
    }
}
export const deleteDealerEmailConfigSuccess = (message) => {
    return {
        type: DELETE_DEALER_EMAIL_CONFIG_SUCCESS,
        payload: {message }
    }
}
export const deleteDealerEmailConfigFailed = (error) => {
    return {
        type: DELETE_DEALER_EMAIL_CONFIG_FAILED,
        payload: { error }
    }
}
export const resetDealerEmailConfigMessage = () => {
    return {
        type: RESET_DEALER_EMAIL_CONFIG_MESSAGE,
    }
}



