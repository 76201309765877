import {
    GET_ALL_CHANNEL_LIST, GET_ALL_CHANNEL_LIST_SUCCESS, DEALER_LIST, DEALER_LIST_SUCCESSFUL, DEALER_API_ERROR, COUNTRY_LIST, COUNTRY_LIST_SUCCESS, STATE_LIST, STATE_LIST_SUCCESS, ADD_DEALER, ADD_DEALER_SUCCESS, RESET_ALERT, EDIT_DEALER, DELETE_DEALER, DELETE_DEALER_SUCCESS, API_ERROR_MODAL, CHANNEL_NAME, CHANNEL_NAME_SUCCESS, DEALER_FLAG_UPDATE, DEALER_FLAG_UPDATE_SUCCESS, NON_VERIFIED_DEALER_LIST, NON_VERIFIED_DEALER_LIST_SUCCESS, VERIFY_DEALER, VERIFY_DEALER_SUCCESS, CHANGE_VERIFY_USER_PASSWORD, CHANGE_VERIFY_USER_PASSWORD_SUCCESSFULLY, GET_DEALER_SUSPENSION_LOG, GET_DEALER_SUSPENSION_LOG_SUCCESS, GET_DEALER_SUSPENSION_LOG_FAILED,
    CLOSE_DEALER_SUSPENSION_LOG_MODAL, GET_DEALER_CONFIG, GET_DEALER_CONFIG_SUCCESS, GET_DEALER_CONFIG_FAILED, CLOSE_DEALER_CONFIG_MODAL, SET_DEALER_CONFIG_DATA, UPDATE_DEALER_CONFIG, UPDATE_DEALER_CONFIG_SUCCESS, UPDATE_DEALER_CONFIG_FAILED, GET_OTHERS_ALLOWED_DURATION, GET_OTHERS_ALLOWED_DURATION_SUCCESS, GET_OTHERS_ALLOWED_DURATION_FAILED, OPEN_EMAIL_CONFIG_MODAL, CLOSE_EMAIL_CONFIG_MODAL,
    UPDATE_DEALER_EMAIL_FAILED, UPDATE_DEALER_EMAIL_SUCCESS, UPDATE_DEALER_EMAIL
} from "./actionTypes";

const initialState = {
    apiError: null,
    message: null,
    loading: false,
    dealerData: [],
    modalMessage: null,
    nonVerifiedDealer: [],
    channelList: [],
    countries: [],
    dealerSuspensionLogs: [],
    dealerSuspensionLogLoading: false,
    dealerSuspensionLogMessage: null,
    dealerSuspensionLogError: null,
    dealerSuspensionLogModal: false,
    /* dealer config */
    dealerConfig: [],
    dealerConfigLoading: false,
    dealerConfigMessage: null,
    dealerConfigError: null,
    dealerConfigModal: false,
    /*Other Allowed Duration*/
    othersAllowedDuration: [],
    othersAllowedDurationLoading: false,
    othersAllowedDurationMessage: null,
    othersAllowedDurationError: null,
    /* Email Config */
    emailConfigModal: false,
    emailDomainConfig: [],
    emailConfigLoading: false,
    emailConfigMessage: null,
    emailConfigError: null,



}

const dealer = (state = initialState, action) => {
    let dealerListNewState;
    switch (action.type) {
        case GET_ALL_CHANNEL_LIST:
            state = {
                ...state,
                // loading: true,
                apiError: null,
                message: null
            }
            break;

        case GET_ALL_CHANNEL_LIST_SUCCESS:
            state = {
                ...state,
                // loading: false,
                channelData: action.payload,
                apiError: null,
                message: null
            }
            break;
        case DEALER_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;

        case DEALER_LIST_SUCCESSFUL:
            state = {
                ...state,
                dealerData: action.payload,
                loading: false,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;
        case ADD_DEALER:
            state = {
                ...state,
                loading: true,
                dealer: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            };
            break;

        case ADD_DEALER_SUCCESS:
            state = {
                ...state,
                loading: false,
                modalMessage: action.payload.message,
                dealerData: action.payload.dealer
            }
            break;
        case DEALER_API_ERROR:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;
        case COUNTRY_LIST:
            state = {
                ...state,
                // loading: false,
            }
            break;
        case COUNTRY_LIST_SUCCESS:
            state = {
                ...state,
                loading: false,
                countries: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;
        case CHANNEL_NAME:
            state = {
                ...state,
                // loading: false,
            }
            break;
        case CHANNEL_NAME_SUCCESS:
            state = {
                ...state,
                loading: false,
                channelList: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;
        case STATE_LIST:
            state = {
                ...state,
                // loading:true,
            }
            break;
        case STATE_LIST_SUCCESS:
            state = {
                ...state,
                loading: false,
                states: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;
        case EDIT_DEALER:
            state = {
                ...state,
                loading: true,
                messageModal: null,
            }
            break;
        case DELETE_DEALER:
            state = {
                ...state,
                loading: true,
            }
            break;
        case DELETE_DEALER_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.message,
                dealerData: action.payload.dealer,
            }
            break;
        case API_ERROR_MODAL:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;
        case RESET_ALERT:
            state = {
                ...state,
                loading: false,
                message: null,
                apiError: null,
                modalMessage: null,
                apiErrorModal: null,
                emailConfigMessage:null,
                
            }
            break;
        case DEALER_FLAG_UPDATE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null,
            }
            break;

        case DEALER_FLAG_UPDATE_SUCCESS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: action.payload.message,
                dealerData: action.payload.dealer,
                dealerSuspensionLogMessage: null,
                dealerSuspensionLogError: null,
                othersAllowedDurationMessage: null,
                othersAllowedDurationError: null,
                dealerConfigMessage: null,
                dealerConfigError: null,
            }
            break;

        case NON_VERIFIED_DEALER_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;

        case NON_VERIFIED_DEALER_LIST_SUCCESS:
            state = {
                ...state,
                loading: false,
                nonVerifiedDealer: action.payload,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;
        case VERIFY_DEALER:
            state = {
                ...state,
                loading: true,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: null
            }
            break;

        case VERIFY_DEALER_SUCCESS:
            state = {
                ...state,
                loading: false,
                nonVerifiedDealer: action.payload.dealerData,
                apiError: null,
                apiErrorModal: null,
                messageModal: null,
                message: action.payload.message
            }
            break;
        case CHANGE_VERIFY_USER_PASSWORD:
            state = {
                ...state,
                loading: true,
            }
            break;
        case CHANGE_VERIFY_USER_PASSWORD_SUCCESSFULLY:
            state = {
                ...state,
                loading: false,
                modalMessage: action.payload.message,
                nonVerifiedDealer: action.payload.dealerData,
            }
            break;

        case GET_DEALER_SUSPENSION_LOG:
            dealerListNewState = state.dealerData.map((item) => {
                let temp = item;
                if (item.ID == action.payload.dealerId) {
                    temp.loading = !Boolean(temp.loading);
                }
                return temp;
            });
            state = {
                ...state,
                dealerSuspensionLogLoading: true,
                dealerSuspensionLogMessage: null,
                dealerSuspensionLogError: null,
                dealerSuspensionLogModal: false,
                dealerData: dealerListNewState
            }
            break;

        case GET_DEALER_SUSPENSION_LOG_SUCCESS:
            dealerListNewState = state.dealerData.map((item) => {
                let temp = item;
                if (item.ID == action.dealerId) {
                    temp.loading = !temp.loading;
                }
                return temp;
            });

            state = {
                ...state,
                dealerSuspensionLogLoading: false,
                dealerSuspensionLogMessage: null,
                dealerSuspensionLogError: null,
                dealerSuspensionLogs: action.data,
                dealerSuspensionLogModal: true,
                dealerData: dealerListNewState
            }
            break;

        case GET_DEALER_SUSPENSION_LOG_FAILED:
            state = {
                ...state,
                dealerSuspensionLogLoading: false,
                dealerSuspensionLogMessage: null,
                dealerSuspensionLogError: action.data,
            }
            break;

        case CLOSE_DEALER_SUSPENSION_LOG_MODAL:
            state = {
                ...state,
                dealerSuspensionLogModal: false,
            }
            break;

        case GET_DEALER_CONFIG:
            dealerListNewState = state.dealerData.map((item) => {
                let temp = item;
                if (item.ID == action.payload.dealerId) {
                    temp.configLoading = !temp.configLoading;
                }
                return temp;
            });

            state = {
                ...state,
                dealerConfigLoading: true,
                dealerConfigMessage: null,
                dealerConfigError: null,
                dealerConfigModal: false,
                dealerData: dealerListNewState
            }
            break;

        case GET_DEALER_CONFIG_SUCCESS:

            dealerListNewState = state.dealerData.map((item) => {
                let temp = item;
                if (item.ID == action.dealerId) {
                    temp.configLoading = !temp.configLoading;
                }
                return temp;
            });

            if (action.data.length > 0) {
                state = {
                    ...state,
                    dealerData: dealerListNewState,
                    dealerConfigLoading: false,
                    dealerConfigMessage: null,
                    dealerConfigError: null,
                    dealerConfigModal: true,
                    dealerConfig: action.data,
                }
            } else {
                state = {
                    ...state,
                    dealerData: dealerListNewState,
                    dealerConfigLoading: false,
                    dealerConfigMessage: null,
                    dealerConfigError: null,
                    dealerConfigModal: true,
                    dealerConfig: [{
                        SelfActivation: 1,
                        OthersAllowed: 0,
                        OthersAllowedCount: 0,
                        OthersAllowedDuration: 0,
                        SelfBulkActivation: 0,
                        OthersBulkAllowed: 0,
                        OthersBulkCount: 0,
                        OthersBulkDuration: 0
                    }]
                }
            }

            break;

        case GET_DEALER_CONFIG_FAILED:
            state = {
                ...state,
                dealerConfigLoading: false,
                dealerConfigMessage: null,
                dealerConfigError: action.data,
            }
            break;

        case CLOSE_DEALER_CONFIG_MODAL:
            state = {
                ...state,
                dealerConfigModal: false,
            }
            break;

        case SET_DEALER_CONFIG_DATA:
            state = {
                ...state,
                dealerConfig: action.data,
            }
            break;


        case UPDATE_DEALER_CONFIG:
            state = {
                ...state,
                dealerConfigLoading: true,
                dealerConfigMessage: null,
                dealerConfigError: null,
            }
            break;

        case UPDATE_DEALER_CONFIG_SUCCESS:
            state = {
                ...state,
                message: null,
                dealerConfigLoading: false,
                dealerConfigMessage: action.data,
                dealerConfigError: null,
                dealerConfigModal: false,
                dealerSuspensionLogMessage: null,
                dealerSuspensionLogError: null,
                othersAllowedDurationMessage: null,
                othersAllowedDurationError: null,
            }
            break;

        case UPDATE_DEALER_CONFIG_FAILED:
            state = {
                ...state,
                dealerConfigLoading: false,
                dealerConfigMessage: null,
                dealerConfigModal: false,
                dealerConfigError: action.error,
            }
            break;

        case GET_OTHERS_ALLOWED_DURATION:
            state = {
                ...state,
                othersAllowedDurationLoading: true,
                othersAllowedDurationMessage: null,
                othersAllowedDurationError: null,
            }
            break;

        case GET_OTHERS_ALLOWED_DURATION_SUCCESS:
            state = {
                ...state,
                othersAllowedDurationLoading: false,
                othersAllowedDurationMessage: action.data,
                othersAllowedDurationError: null,
                othersAllowedDuration: action.data,
            }
            break;

        case GET_OTHERS_ALLOWED_DURATION_FAILED:
            state = {
                ...state,
                othersAllowedDurationLoading: false,
                othersAllowedDurationMessage: null,
                othersAllowedDurationError: action.data,
            }
            break;
        case OPEN_EMAIL_CONFIG_MODAL:
            state = {
                ...state,
                emailConfigModal: true,
            }
            break;
        case CLOSE_EMAIL_CONFIG_MODAL:
            state = {
                ...state,
                emailConfigModal: false,
            }
            break;

        case UPDATE_DEALER_EMAIL:
            state = {
                ...state,
                // emailDomainConfig: [],
                emailConfigLoading: true,
                emailConfigMessage: null,
                emailConfigError: null,
                emailConfigModal:true
            }
            break;

        case UPDATE_DEALER_EMAIL_SUCCESS:
            state = {
                ...state,
                // emailDomainConfig: [],
                emailConfigLoading: false,
                emailConfigMessage: action.message,
                emailConfigError: null,
                emailConfigModal:false
            }
            break;

        case UPDATE_DEALER_EMAIL_FAILED:
            state = {
                ...state,
                // emailDomainConfig: [],
                emailConfigLoading: false,
                emailConfigMessage: null,
                emailConfigError: action.error,
                emailConfigModal:true
            }
            break;


        default:
            state = { ...state };
            break;
    }
    return state;
}

export default dealer;