import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    DEALER_EMAIL_CONFIG_LIST, ADD_DEALER_EMAIL_CONFIG, EDIT_DEALER_EMAIL_CONFIG, DELETE_DEALER_EMAIL_CONFIG
} from "./actionTypes"
import { dealerEmailConfigError, dealerEmailConfigListSuccessful, addDealerEmailConfigfailed, addDealerEmailConfigsucess, editDealerEmailConfigfailed, editDealerEmailConfigsucess, deleteDealerEmailConfigFailed, deleteDealerEmailConfigSuccess, dealerEmailConfigList } from './actions';
import { getList, addData, editData, deleteData } from '../../helpers/fackBackend_Helper';

function* getDealerEmailConfigList({ payload: { companyID, channelId } }) {
    try {
        const response = yield call(getList, '/getdealerEmailConfig/' + companyID + '/' + channelId);
        if (response.Status) {
            yield put(dealerEmailConfigListSuccessful(response.Data));
        }
        else {
            yield put(dealerEmailConfigError(response.Message));
        }
    } catch (error) {
        yield put(dealerEmailConfigError(error));
    }
}
export function* watchGetDealerEmailConfigList() {
    yield takeEvery(DEALER_EMAIL_CONFIG_LIST, getDealerEmailConfigList)
}
function* addDealerEmailConfig({ payload: { dealerEmailData, searchDealer } }) {
    try {
        const response = yield call(addData, '/dealerEmailConfig', dealerEmailData)
        if (response.Status) {
            yield put(addDealerEmailConfigsucess(response.Message))
            yield put(dealerEmailConfigList(searchDealer.companyID, searchDealer.channelId));
        } else {
            yield put(addDealerEmailConfigfailed(response.Message));
        }
    } catch (error) {
        yield put(addDealerEmailConfigfailed(error));
    }
}
export function* watchAddDealerEmailConfig() {
    yield takeEvery(ADD_DEALER_EMAIL_CONFIG, addDealerEmailConfig)
}
function* editDealerEmailConfig({ payload: { dealerEmailData, id, searchDealer} }) {
       try {
        const response = yield call(addData, '/dealerEmailConfig/' + id, dealerEmailData);
        if (response.Status) {
            yield put(editDealerEmailConfigsucess(response.Message))
            yield put(dealerEmailConfigList(searchDealer.companyID, searchDealer.channelId));
        } else {
            yield put(editDealerEmailConfigfailed(response.Message));
        }
    } catch (error) {
        yield put(editDealerEmailConfigfailed(error));
    }
}
export function* watchEditDealerEmailConfig() {
    yield takeEvery(EDIT_DEALER_EMAIL_CONFIG, editDealerEmailConfig)
}
function* deleteDealerEmailConfig({ payload: { dealerEmailData, id, status, searchDealer } }) {
    try {
        const response = yield call(deleteData, '/dealerEmailConfig/' + id + '/' + status, dealerEmailData);
        if (response.Status) {
            yield put(deleteDealerEmailConfigSuccess(response.Message))
            yield put(dealerEmailConfigList(searchDealer.companyID, searchDealer.channelId))
        } else {
            yield put(deleteDealerEmailConfigFailed(response.Message));
        }
    } catch (error) {
        yield put(deleteDealerEmailConfigFailed(error));
    }
}

export function* watchDeleteDeleteEmailConfig() {
    yield takeEvery(DELETE_DEALER_EMAIL_CONFIG, deleteDealerEmailConfig)
}


function* dealerEmailConfigSaga() {
    yield all([
        fork(watchAddDealerEmailConfig),
        fork(watchGetDealerEmailConfigList),
        fork(watchEditDealerEmailConfig),
        fork(watchDeleteDeleteEmailConfig),
    ]);
}

export default dealerEmailConfigSaga;