import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker';
import moment from "moment";
import { Link, withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Spinner from '../components/layout/Spinner';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { useSelector, useDispatch } from 'react-redux';
import { getPlanWiseActivationReportList } from '../store/planWiseActivationReport/actions';

const fileExtension = '.xlsx';


const columns = [
    {
        name: '#',
        width: "40px",
        selector: row => row.ID,
    },
    {
        name: 'Plan Name',
        width: "500px",
        selector: row => row.PlanName,
    },
    {
        name: 'Price',
        selector: row => row.PlanPrice,
    },
    {
        name: 'Total',
        selector: row => row.Total,
    },
    {
        name: 'Processed',
        selector: row => row.Processed,
    },
    {
        name: 'Processing',
        selector: row => row.Processing,
    },
    {
        name: 'Pending',
        selector: row => row.Pending,
    },
    {
        name: 'Cancelled',
        selector: row => row.Cancelled,
    },
];

function PlanWiseActivationReport() {
    const rows = useSelector((state) => state.planWiseActivationReport.planWiseActivationReport);
    const loading = useSelector((state) => state.planWiseActivationReport.loading);
    const dispatch = useDispatch();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const [sDate, setSdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [eDate, setEdate] = useState(new Date());
    const [dataSearch, setDataSearch] = useState(false);
    const [searchData, setSearchData] = useState({
        dealerId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : 0,
        fromDate: sDate,
        toDate: eDate,
    });

    useEffect(() => {
        // console.log(searchData);
        // dispatch(getPlanWiseActivationReportList(searchData));

    }, []);

    const handleSearchData = (e) => {
        e.preventDefault();
        setDataSearch(true);
        let searchDataManage = searchData;
        searchDataManage.fromDate = moment(searchDataManage.fromDate).format('YYYY-MM-DD');
        searchDataManage.toDate = moment(searchDataManage.toDate).format('YYYY-MM-DD');
        dispatch(getPlanWiseActivationReportList(searchDataManage));
    }

    const tableData = rows.map((item, index) => {
        item.ID = ++index;
        return item;
    });

    const exportToCSV = async () => {
        if (tableData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(tableData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'PlanWiseActivationReport' + fileExtension);
        }
    }

    const handleEndDateChange = (d) => {
        setEdate(d);
        setSearchData(prevState => ({
            ...prevState,
            toDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
    }

    const handleStareDateChange = (d) => {
        setSdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))
    }

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };


    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Plan Wise Activation Report</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">

                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-3">
                                        <div className="form-group"><label className="form-label" htmlFor="startDate">From Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleStareDateChange(d)} className={"form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="startDate" closeOnScroll={true} selected={sDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group"><label className="form-label" htmlFor='endDate'>End Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleEndDateChange(d)} className={"form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="endDate" closeOnScroll={true} selected={eDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-3'>
                                        <button type='submit' disabled={false} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{false === true && <span
                                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Search</span> </button>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                
                {
                    dataSearch ? <div className='card px-3'>
                    <div className='d-flex justify-content-between mt-2'>
                        <Link to="#" style={{ marginLeft: 'auto' }} className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                    </div>
                    <DataTable columns={columns} data={tableData} customStyles={customStyles} progressPending={loading} progressComponent={<Spinner />} responsive striped />

                </div> : ''
                }

            </div>
        </>
    )
}

export default PlanWiseActivationReport;