import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList, addData } from '../../helpers/fackBackend_Helper';
import { USER_LOGOUT, CHANGE_PASSWORD, JAR_STATUS_UPDATE, GET_TO_SHOW_MARQUEE, VALIDATE_TOKEN, GET_COMPANY,T_MOBILE_STATUS_UPDATE } from './actionTypes';
import { userLogoutSuccess, userLogoutFailed, changePasswordSuccessful, apiPasswordError, jarStatusUpdateSuccessful, getToShowMarqueeSuccessfull, marqueeApiError, validateTokenSuccess, validateTokenFailure, getCompanyDataSuccessfull, getCompanyDataFailed,tMobileStatusUpdateSuccessful, tMobileStatusUpdateFailed, getCompany } from './actions'

function* logoutUser({ payload: { userId } }) {
    try {
        const response = yield call(getList, '/user_logout/' + userId);
        if (response.Status) {
            yield put(userLogoutSuccess(response.message));
        }
        else {
            yield put(userLogoutFailed(response.message));
        }
    } catch (error) {
        yield put(userLogoutFailed(error));
    }
}

export function* watchLogoutUser() {
    yield takeEvery(USER_LOGOUT, logoutUser)
}

function* changePassword({ payload: { passwordData } }) {
    try {
        const response = yield call(addData, '/changeAdminPassword', passwordData);
        if (response.Status) {
            yield put(changePasswordSuccessful(response.Message));
        }
        else {
            yield put(apiPasswordError(response.response.Message));
        }
    } catch (error) {
        yield put(apiPasswordError(error));
    }
}

export function* watchChangePassword() {
    yield takeEvery(CHANGE_PASSWORD, changePassword)
}
function* jarStatusUpdate({ payload: { companyId, status, loginId } }) {
    try {
        const response = yield call(getList, '/changeJARRunningStatus/' + companyId + '/' + status + '/' + loginId);
        if (response.Status) {
            if (response.jarStatus[0].Status === 1) {
                var tempUser = JSON.parse(localStorage.getItem('authUser'));
                tempUser.JARFlag = status;
                localStorage.setItem("authUser", JSON.stringify(tempUser));
                yield put(getCompany(companyId));
            }
            yield put(jarStatusUpdateSuccessful(response.jarStatus[0].Message));
        }
        else {
            yield put(apiPasswordError(response.response.Message));
        }
    } catch (error) {
        yield put(apiPasswordError(error));
    }
}

export function* watchJarStatusUpdate() {
    yield takeEvery(JAR_STATUS_UPDATE, jarStatusUpdate)
}

function* getToShowMarqueeStatus({ payload: companyId }) {
    try {
        const response = yield call(getList, '/getShowNotification/' + companyId);
        if (response.Status) {
            yield put(getToShowMarqueeSuccessfull(response.notification));
        }
        else {
            yield put(marqueeApiError(response));
        }
    } catch (error) {
        yield put(marqueeApiError(error));
    }
}

export function* watchGetToShowMarqueeStatus() {
    yield takeEvery(GET_TO_SHOW_MARQUEE, getToShowMarqueeStatus)
}

function* validateToken() {
    try {
        const response = yield call(getList, '/validate_token');
        if (response.Status) {
            yield put(validateTokenSuccess(response.Message));
        }
        else {
            yield put(validateTokenFailure(response.Message));
        }
    } catch (error) {
        yield put(validateTokenFailure(error));
    }
}

export function* watchValidateToken() {
    yield takeEvery(VALIDATE_TOKEN, validateToken)
}

function* getCompanyDataByCompanyId({ payload: companyId }) {
    try {
        const response = yield call(getList, '/getcompany/' + companyId);
        if (response.Status) {
            yield put(getCompanyDataSuccessfull(response.companyData));
        }
        else {
            yield put(getCompanyDataFailed(response.Message));
        }
    } catch (error) {
        yield put(getCompanyDataFailed(error));
    }
}

export function* watchCompanyData() {
    yield takeEvery(GET_COMPANY, getCompanyDataByCompanyId)
}

function* tMobileStatusUpdate({ payload: { companyId, status, loginId } }) {
    try {
        const response = yield call(getList, '/changeTMobileStatus/' + companyId + '/' + status + '/' + loginId);
        if (response.Status) {
            if (response.tMobileStatus[0].Status === 1) {
                var tempUser = JSON.parse(localStorage.getItem('authUser'));
                tempUser.TMobileStatus = status;
                localStorage.setItem("authUser", JSON.stringify(tempUser));

                yield put(getCompany(companyId));
            }
            yield put(tMobileStatusUpdateSuccessful(response.tMobileStatus[0].Message));
        }
        else {
            yield put(tMobileStatusUpdateFailed(response.Message));
        }
    } catch (error) {
        yield put(tMobileStatusUpdateFailed(error));
    }
}

export function* watchTMobileStatusUpdate() {
    yield takeEvery(T_MOBILE_STATUS_UPDATE, tMobileStatusUpdate)
}

function* headerSaga() {
    yield all([
        fork(watchLogoutUser),
        fork(watchChangePassword),
        fork(watchJarStatusUpdate),
        fork(watchGetToShowMarqueeStatus),
        fork(watchValidateToken),
        fork(watchCompanyData),
        fork(watchTMobileStatusUpdate),
    ]);
}

export default headerSaga;