export const VIEW_ACTIVATION_LIST = "VIEW_ACTIVATION_LIST";
export const VIEW_ACTIVATION_LIST_SUCCESSFULL = "VIEW_ACTIVATION_LIST_SUCCESSFULL";
export const VIEW_ACTIVATION_LIST_FAILED = "VIEW_ACTIVATION_LIST_FAILED";
export const RESET_ALERT_ACTIVATION ="RESET_ALERT_ACTIVATION";
export const COMPELETE_ACTIVATION ="COMPELETE_ACTIVATION";
export const COMPELETE_ACTIVATION_SUCCESSFULL="COMPELETE_ACTIVATION_SUCCESSFULL";
export const DATA_SUBMIT_FAILED="DATA_SUBMIT_FAILED";
export const GET_ERROR_CODE="GET_ERROR_CODE";
export const GET_ERROR_CODE_SUCCESSFULL="GET_ERROR_CODE_SUCCESSFULL";
export const REMARK_CANCEL ="REMARK_CANCEL";
export const REMARK_CANCEL_SUCCESSFULL = "REMARK_CANCEL_SUCCESSFULL";
export const ACTIVATION_REQUEST_INFO = "ACTIVATION_REQUEST_INFO";
export const ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL= "ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL";
export const LOCK_ACTIVATION = "LOCK_ACTIVATION";
export const LOCK_ACTIVATION_RESULT= "LOCK_ACTIVATION_RESULT";
export const LOCK_ACTIVATION_FAILED_RESULT = "LOCK_ACTIVATION_FAILED_RESULT";
export const UNLOCK_ACTIVATION = "UNLOCK_ACTIVATION";
export const UNLOCK_ACTIVATION_SUCCESS ="UNLOCK_ACTIVATION_SUCCESS";
export const RESET_FLAG_ALERT ="RESET_FLAG_ALERT";
export const ACCESS_TRANSACTION_MODAL = "ACCESS_TRANSACTION_MODAL";
export const ACCESS_TRANSACTION_MODAL_SUCCESS = "ACCESS_TRANSACTION_MODAL_SUCCESS";
export const ASSIGN_ACTIVATION = "ASSIGN_ACTIVATION";
export const ASSIGN_ACTIVATION_SUCCESSFULL = "ASSIGN_ACTIVATION_SUCCESSFULL";
export const UPDATE_REFUND_REQUEST='UPDATE_REFUND_REQUEST';
export const UPDATE_REFUND_REQUEST_SUCCESSFULL= "UPDATE_REFUND_REQUEST_SUCCESSFULL";
export const UPDATE_REFUND_REQUEST_FAILED="UPDATE_REFUND_REQUEST_FAILED";
// export const REPUSH_ACTIVATION="REPUSH_ACTIVATION";
// export const REPUSH_ACTIVATION_SUCCESS = "REPUSH_ACTIVATION_SUCCESS";
// export const REPUSH_ACTIVATION_FAILED = "REPUSH_ACTIVATION_FAILED";
export const MESSAGE_ALERT_HIDE="MESSAGE_ALERT_HIDE";
export const CLOSE_ACTIVATION_CANCEL_MODAL="CLOSE_ACTIVATION_CANCEL_MODAL";

export const NOTIFY_POS='NOTIFY_POS'; // point of sale
export const NOTIFY_POS_SUCCESSFULL= "NOTIFY_POS_SUCCESSFULL";
export const NOTIFY_POS_FAILED="NOTIFY_POS_FAILED";
export const CLOSE_ACCESS_TRANSACTION_MODAL = "CLOSE_ACCESS_TRANSACTION_MODAL";


