import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from 'react-redux';
import moment from "moment";
import DataTable from 'react-data-table-component';
import FilterComponent from "./FilterComponent";
import Spinner from "../components/layout/Spinner";
import { getLockedData, unlockActivationByOther } from '../store/lockActivationData/actions';
import { PERMISSIONS } from '../Permission/Permission';
import { getActivationChildList } from '../store/repushActivation/actions';
import MultilineModalComponent from '../components/MultilineModalComponent';

function LockActivationRequest(props) {
  const dispatch = useDispatch();
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [CompanyID, setcompanyID] = useState("");
  const [RoleID, setRoleID] = useState(null);
  const [loginID, setloginID] = useState(null);

  useEffect(() => {
    const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
    const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
    const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
    setRoleID(roleId);
    setloginID(loginId);
    setcompanyID(companyId);
    props.getLockedData(companyId)
  }, [])

  // permission data manage
  var filterArray = [];
  for (const [key, value] of Object.entries(PERMISSIONS)) {
    if (key == RoleID) {
      filterArray.push(value);
    }
  }

  const columns = [
    {
      name: "#",
      width: '60px',
      selector: row => row.serial,
    },
    {
      name: "CHANNEL TXN",
      width: "170px",
      selector: row => row.ChannelTxnID
    },
    {
      name: "SERIALNUMBER",
      width: '240px',
      selector: row => row.SerialNumber,
    },
    {
      name: "LOCKED BY",
      width: "170px",
      selector: row => row.LockedBy,
    },
    // {
    //   name: "TRANSACTION",
    //   width:"200px",
    //   selector: row => row.TransactionID,
    // },

    {
      name: "DATE",
      selector: row => row.CreatedDate,
    },

    {
      name: "CHANNEL NAME",
      width: "170px",
      selector: row => row.ChannelName
    },
    filterArray && filterArray.length > 0 && filterArray[0].includes("lock_activation_release") && {
      name: "ACTION",
      width: '150px',
      selector: row => row.action,
    }];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '0px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '0px',
      },
    },
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems = props.lockedData && props.lockedData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1

  );

  const openTransactionModal = (id) => {
      dispatch(getActivationChildList(id))

  }

  const filterData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      action: (<span><em className="icon icon-circle icon ni ni-unlock bg-orange" data-bs-toggle="tooltip" data-bs-placement="top" title="Release" onClick={() => handleUnLockActivation(data.ActivationID, data.LockedByID)} style={{ 'cursor': 'pointer' }}></em></span>),
      SerialNumber: (<p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{data.SerialNumber}</p>),
      TransactionID: (<p>{data.CurrencyCode}{data.TransactionID}</p>),
      LockedBy: (<p>{data.LockedBy}</p>),
      CreatedDate: (<p>{moment(new Date(data.CreatedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
      ChannelTxnID: (
        <>
              <p className={data.Multiline === 1 ? `badge rounded-pill bg-outline-danger px-1 d-inline` : 'd-inline'} >{data.ChannelTxnID}</p>
              {data.Multiline === 1 ? <span onClick={() => openTransactionModal(data.ActivationID)} style={{ 'position': 'absolute' }}><em className="icon ni ni-external" style={{ 'cursor': 'pointer', 'fontSize': '16px', 'marginLeft': '10px' }}></em></span> : ''}
          </>
      ),
      ChannelName: (<p>{data.ChannelName}</p>)
    }
  })

  const handleUnLockActivation = (activationID, lockedById) => {
    const unLockData = {
      activationId: activationID,
      loginId: lockedById,
      companyId: CompanyID
    }
    console.log(unLockData);
    dispatch(unlockActivationByOther(unLockData));
  }



  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }
  return (

    <>
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">Locked Activation</h3>
        <div className="card p-2" style={{ marginTop: '12px' }}>
          {props.message &&
            <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
          <div className="card-inner p-0" >
            <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} subHeaderComponent={subHeaderComponent} subHeader responsive striped />
          </div>
        </div>
      </div>

  {props.childDataModalShow && <MultilineModalComponent activationChildData={props.activationChildData} />}
    
    </>

        

  )
}

const mapStateToProps = state => {
  const { lockedData, apiError, loading, message } = state.lockedActivationData;
  const { childDataModalShow, activationChildData } = state.repushActivation
  return { lockedData, apiError, loading, message,childDataModalShow, activationChildData };
}

// export default Users;
export default withRouter(connect(mapStateToProps, { getLockedData })(LockActivationRequest));
