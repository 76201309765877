import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData } from '../../helpers/fackBackend_Helper';
import {  getActivationReportSuccess, getActivationReportFailed } from './actions';
import {  ACTIVATION_REPORT_LIST } from './actionTypes';

function* getActivationReport({ payload: { companyId, loginId, searchData } }) {
    try {
        const response = yield call(addData, '/activationDataListForReport/' + companyId + '/' + loginId, searchData);
        if (response.Status) {
            yield put(getActivationReportSuccess(response.activations));
        } else {
            yield put(getActivationReportFailed(response));
        }
    } catch (error) {
        yield put(getActivationReportFailed(error));
    }
}

export function* watchActivationReport() {
    yield takeEvery(ACTIVATION_REPORT_LIST, getActivationReport)
}

function* activationReportSaga() {
    yield all([
        fork(watchActivationReport)
    ]);
}
export default activationReportSaga;

