import { SEARCH_ZIPCODE_ACTIVATION, SEARCH_ZIPCODE_ACTIVATION_SUCCESSFULL, SEARCH_ZIPCODE_ACTIVATION_FAILED, UPDATE_ZIPCODE_ACTIVATION, UPDATE_ZIPCODE_ACTIVATION_SUCCESSFULL } from "./actionTypes"

export const searchZipcodeActivation= (searchData) =>{
    return {
        type: SEARCH_ZIPCODE_ACTIVATION,
        payload: searchData
    }
}

export const searchZipcodeActivationSuccessfull =(activationData)=>{
    return{
        type: SEARCH_ZIPCODE_ACTIVATION_SUCCESSFULL,
        payload: activationData
    }
}

export const searchZipcodeActivationFailed = (error) =>{
    return{
        type: SEARCH_ZIPCODE_ACTIVATION_FAILED,
        payload: error
    }
}

export const updateZipcode = (zipcodeData) =>{
    return{
        type: UPDATE_ZIPCODE_ACTIVATION,
        payload: zipcodeData
    }
}

export const updateZipcodeActivationSuccessfull =(message)=>{
    return{
        type: UPDATE_ZIPCODE_ACTIVATION_SUCCESSFULL,
        payload: message
    }
}