import { DELETE_JAR_DATA, DELETE_JAR_DATA_SUCCESSFULLY, EDIT_JAR_DATA, EDIT_JAR_DATA_SUCCESSFULLY, GET_JAR_LIST, GET_JAR_LIST_SUCCESSFULL, JAR_FAILED } from "./actionTypes"

export const jarList = (jarData) =>{
    return{
        type:GET_JAR_LIST,
        payload:jarData
    }
}

export const jarListSuccessFull = (jarData) =>{
    return{
        type:GET_JAR_LIST_SUCCESSFULL,
        payload:jarData
    }
}

export const jarListFailed = (error) =>{
    return{
        type:JAR_FAILED,
        payload:error
    }
}

export const editJarData = (id,jarData) =>{
    return{
        type:EDIT_JAR_DATA,
        payload:{id,jarData}
    }
}

export const editJarDataSuccessFully =(jarData,message)=>{
    return{
        type:EDIT_JAR_DATA_SUCCESSFULLY,
        payload:{jarData,message}
    }
}

export const deleteJarData=(id,status,deleteData)=>{
    return{
        type:DELETE_JAR_DATA,
        payload:{id,status,deleteData}
    }
}

export const deleteJarDataSuccessfully = (jarData,message) =>{
    return{
        type:DELETE_JAR_DATA_SUCCESSFULLY,
        payload:{jarData,message}
    }
}