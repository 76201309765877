import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import Spinner from "../components/layout/Spinner";
import moment from "moment/moment";
import { addNotification, editNotification, notificationList, resetNotificationAlert, setIsCurrentStatus } from '../store/notification/actions';
import DatePicker from 'react-datepicker';
import { PERMISSIONS } from '../Permission/Permission';

function Notification(props) {
    const dispatch = useDispatch();
    const [CompanyId, setCompanyId] = useState('');
    const [LoginId, setLoginId] = useState('');
    const [RoleID, setRoleID] = useState(null);
    const [showModal, setshowModal] = useState(false);
    const [title, settitle] = useState('')
    const [sDate, setSDate] = useState('');
    const [eDate, setEDate] = useState('');
    const [editId, setEditId] = useState();
    const [contentField, setcontentField] = useState(false);
    const [startField, setstartField] = useState(false);
    const [endField, setendField] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [addNotificationData, setaddNotificationData] = useState({
        CompanyId: "",
        Content: "",
        StartDate: "",
        EndDate: "",
        CreatedBy: ""
    })

    useEffect(() => {
        const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        setRoleID(roleId);
        setCompanyId(companyId);
        setLoginId(loginId);
        setEditFlag(false);
        props.notificationList(companyId);
        setaddNotificationData(prevState => ({
            ...prevState,
            CompanyId: companyId,
            CreatedBy: loginId
        }));
    }, [])

    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    const columns = [
        {
            name: "#",
            width: '60px',
            selector: row => row.serial,
        },
        {
            name: "NOTIFICATION",
            width: "600px",
            selector: row => row.NotificationContent,
        },
        {
            name: "START DATE",
            selector: row => row.StartDate,
        },
        {
            name: "END DATE",
            selector: row => row.EndDate,
        },
        filterArray && filterArray.length > 0 && filterArray[0].includes("edit_notification", "activate_notification") && {
            name: "ACTION",
            selector: row => row.action,
        },
    ];

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setaddNotificationData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleStartDate = (d) => {
        setSDate(d);
        setaddNotificationData(prevState => ({
            ...prevState,
            StartDate: d ? moment(new Date(d)).format("YYYY-MM-DD HH:mm:ss") : ''
        }));
    }

    const handleEndDate = (d) => {
        setEDate(d);
        // console.log(moment(new Date(d)).format("YYYY-MM-DD HH:mm:ss"));
        setaddNotificationData(prevState => ({
            ...prevState,
            EndDate: d ? moment(new Date(d)).format("YYYY-MM-DD HH:mm:ss") : ''
        }));
    }

    const handleCancel = () => {
        setshowModal(false)
    }

    const handleAddForm = () => {
        dispatch(resetNotificationAlert());
        setshowModal(true);
        setEditFlag(false);
        setcontentField(false);
        setstartField(false);
        setendField(false);
        settitle('Add Notification');
        setaddNotificationData(prevState => ({
            ...prevState,
            Content: "",
            StartDate: "",
            EndDate: "",
        }));
    }

    const handleSubmit = (e) => {
        // console.log(addNotificationData)
        e.preventDefault();
        setcontentField(false);
        setstartField(false);
        setendField(false);
        document.getElementById('startDateError').innerHTML = ""
        var checkFlag = false;
        if (addNotificationData.Content === "") {
            setcontentField(true);
            checkFlag = true;
        }
        if (addNotificationData.StartDate === "") {
            setstartField(true);
            checkFlag = true;
        }
        if (addNotificationData.EndDate === "") {
            setendField(true);
            checkFlag = true;
        }
        if (addNotificationData.StartDate > addNotificationData.EndDate) {
            document.getElementById('startDateError').innerHTML = "Start date should not be greater than End date"
            checkFlag = true;
        }
        if (!checkFlag) {
            dispatch(addNotification(addNotificationData, CompanyId));
        }
    }

    const handleEditForm = (id) => {
        setcontentField(false);
        setstartField(false);
        setendField(false);
        dispatch(resetNotificationAlert());
        setEditId(id);
        setEditFlag(true);
        setshowModal(true);
        settitle('Edit Notification')
        var selectedData = props.notification && props.notification.filter(notif => notif.ID === id);
        // console.log(selectedData[0]);
        setaddNotificationData(prevState => ({
            ...prevState,
            Content: selectedData[0].NotificationContent,
            StartDate: moment(new Date(selectedData[0].StartDate)).format("YYYY-MM-DD HH:mm:ss"),
            EndDate: moment(new Date(selectedData[0].EndDate)).format("YYYY-MM-DD HH:mm:ss")
        }));

    }

    const handleUpdate = (e) => {
        e.preventDefault();
        setcontentField(false);
        setstartField(false);
        setendField(false);
        document.getElementById('startDateError').innerHTML = ""
        const updateNotificationData = {
            CompanyId: addNotificationData.CompanyId,
            Content: addNotificationData.Content,
            ModifiedBy: LoginId,
            StartDate: addNotificationData.StartDate,
            EndDate: addNotificationData.EndDate
        }
        var checkFlag = false;
        if (addNotificationData.Content === "") {
            setcontentField(true);
            checkFlag = true;
        }
        if (addNotificationData.StartDate === "") {
            setstartField(true);
            checkFlag = true;
        }
        if (addNotificationData.EndDate === "") {
            setendField(true);
            checkFlag = true;
        }
        if (addNotificationData.StartDate > addNotificationData.EndDate) {
            document.getElementById('startDateError').innerHTML = "Start date should not be greater than End date"
            checkFlag = true;
        }
        // addNotificationData.modifiedBy = LoginId;
        if (!checkFlag) {
            dispatch(editNotification(updateNotificationData, editId, CompanyId))
        }
    }

    const handleActive = (id, status) => {
        const activeData = {
            CompanyId: CompanyId,
            ModifiedBy: LoginId
        }
        dispatch(setIsCurrentStatus(id, status, activeData));
        //   console.log(id,currentValue,activeData);
    }

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '0px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '0px',
            },
        },
    };

    const notificationData = props.notification && props.notification.map((data, key) => {
        return {
            serial: (<p>{key + 1}</p>),
            NotificationContent: (<p style={{ "whiteSpace": "break-spaces" }}>{data.NotificationContent}</p>),
            StartDate: (<p>{moment(new Date(data.StartDate)).format("MM/DD/YYYY LT")}</p>),
            EndDate: (<p>{moment(new Date(data.EndDate)).format("MM/DD/YYYY LT")}</p>),
            action: <div><em className="icon icon-circle bg-warning ni ni-edit-alt" style={{ cursor: 'pointer' }} onClick={() => handleEditForm(data.ID)}></em>
                <div className={`custom-control custom-switch mx-2`}>
                    {data.isCurrent === 1 ? <input type="checkbox" defaultChecked={data.isCurrent} className="custom-control-input" id={`customSwitch${data.ID}`} onClick={() => handleActive(data.ID, 0)} /> : <input type="checkbox" className="custom-control-input" id={`customSwitch${data.ID}`} onClick={() => handleActive(data.ID, 1)} />}
                    <label className="custom-control-label" htmlFor={`customSwitch${data.ID}`}></label>
                </div>
            </div>
        }
    })

    if (props.modalMessage) {
        setTimeout(() => {
            setshowModal(false);
        }, 1500);
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <div className="nk-block-head-content">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <h3 className="nk-block-title page-title">Notification</h3>
                {filterArray && filterArray.length > 0 && filterArray[0].includes("add_notification") && <>
                    <div
                        data-target="addProduct"
                        className="toggle btn btn-icon btn-primary d-md-none"
                        onClick={(handleAddForm)}
                    >
                        <em className="icon ni ni-plus"></em>
                    </div>
                    <div
                        data-target="addProduct"
                        className="toggle btn btn-primary d-none d-md-inline-flex"
                        onClick={(handleAddForm)}
                    >
                        <em className="icon ni ni-plus"></em>
                        <span>Add Notification</span>
                    </div>
                </>}
            </div>

            <div className="card p-2" style={{ marginTop: '12px' }}>
                {props.message &&
                    <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
                <div className="card-inner p-0" >
                    <DataTable pagination columns={columns} data={notificationData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                </div>
            </div>
            {showModal && <div className="modal fade show" tabIndex="-1" id="modalTop" style={{ 'display': 'block' }} aria-hidden="true">
                <div className="modal-dialog modal-dialog-top" role="document">
                    <div className="modal-content"><div className="modal-header">
                        <h5 className="modal-title">{title}</h5>
                        <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setshowModal(false)}><em className="icon ni ni-cross"></em></div>
                    </div>
                        <div className="modal-body">
                            {props.modalMessage && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.modalMessage}</strong></div></div>}
                            {/* {props.apiError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>} */}
                            <form className="form-validate is-alter" onSubmit={editFlag ? handleUpdate : handleSubmit}>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="newPassword">Content</label>
                                    <div className="form-control-wrap">
                                        <textarea type="text" className="form-control" name="Content" id="Content" onChange={handleChange} value={addNotificationData.Content} placeholder="Content" autoComplete="off" style={{ 'border': contentField ? '1px solid red' : '' }} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="confirmPassword">Start Date</label>
                                    <div className="form-control-wrap" style={{ 'border': startField ? '1px solid red' : '' }} onClick={() => { document.getElementById('startDateError').innerHTML = ""; }} >
                                        <DatePicker onChange={(d) => handleStartDate(d)} className={"form-control date-picker"} autoComplete="off" value={addNotificationData.StartDate} dateFormat="MM/dd/yyyy" name="StartDate" closeOnScroll={true} selected={sDate} placeholderText="MM/DD/YYYY" minDate={new Date()} showTimeSelect />
                                    </div>
                                    <p style={{ 'color': 'red' }} id="startDateError"></p>
                                </div>
                                <div className="form-group">
                                    <label className="form-label" htmlFor="confirmPassword">End Date</label>
                                    <div className="form-control-wrap" style={{ 'border': endField ? '1px solid red' : '' }}>
                                        <DatePicker onChange={(d) => handleEndDate(d)} className={"form-control date-picker"} autoComplete="off" value={addNotificationData.EndDate} dateFormat="MM/dd/yyyy" name="EndDate" closeOnScroll={true} selected={eDate} minDate={new Date()} placeholderText="MM/DD/YYYY" showTimeSelect />
                                    </div>
                                    <p style={{ 'color': 'red' }} id="passwordError"></p>
                                </div>
                                <div className="form-group text-right">
                                    <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleCancel}>Cancel</button>
                                    <button type='submit' disabled={props.loading} className="btn btn-lg btn-primary">{props.loading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Submit</span> </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

const mapStateToProps = state => {
    const { notification, apiError, loading, modalMessage, message } = state.notification;
    return { notification, apiError, loading, modalMessage, message };
}

// export default Users;
export default withRouter(connect(mapStateToProps, { notificationList })(Notification));