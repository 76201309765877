import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { serverListFailed, serverListSuccessFull } from "./actions";
import { GET_SERVER_LIST } from "./actionTypes";
import { getList } from "../../helpers/fackBackend_Helper";
function* getServerList({ payload: companyId }) {
  try {
    const response = yield call(getList, "/getserver/" + companyId);
    if (response.Status) {
      yield put(serverListSuccessFull(response.serverdata));
    } else {
      yield put(serverListFailed(response));
    }
  } catch (error) {
    yield put(serverListFailed(error));
  }
}
export function* watchServerList() {
  yield takeEvery(GET_SERVER_LIST, getServerList);
}

function* serverSaga() {
  yield all([fork(watchServerList)]);
}

export default serverSaga;
