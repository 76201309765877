import { CHART_DATA, CHART_DATA_SUCCESSFUL, MONTHTLY_CHART, MONTHTLY_CHART_SUCCESSFUL, DASHBOARD_API_ERROR, OVERALL_CHART, OVERALL_CHART_SUCCESSFUL, COUNTACTIVATIONBY_NETWORKTYPE, COUNTACTIVATIONBY_NETWORKTYPE_SUCCESSFUL, WIDGET_DATA, WIDGET_DATA_SUCCESSFULL, SEARCH_ACTIVATION_BY_SOURCE, ACTIVATION_BY_SOURCE_DATA_SUCCESSFULL, ACTIVATION_BY_SOURCE_DATA_FAILED, CHANNEL_ACTIVATION_CHART_DATA, CHANNEL_ACTIVATION_CHART_DATA_SUCCESSFULL, GET_ACTIVATION_COUNT_BY_USER, GET_ACTIVATION_COUNT_BY_USER_SUCCESS, GET_ACTIVATION_COUNT_BY_USER_FAILED } from "./actionTypes"

export const chartData = (companyId, dealerId) => {
    return {
        type: CHART_DATA,
        payload: { companyId, dealerId }
    }
}
export const chartDataSuccessful = (allChartData) => {
    return {
        type: CHART_DATA_SUCCESSFUL,
        payload: allChartData
    }
}
export const monthlyChart = (companyId, dealerId) => {
    return {
        type: MONTHTLY_CHART,
        payload: { companyId, dealerId }
    }
}
export const monthlyChartSuccessful = (monthlyChartData) => {
    return {
        type: MONTHTLY_CHART_SUCCESSFUL,
        payload: monthlyChartData
    }
}
export const overAllChart = (companyId, dealerId) => {
    return {
        type: OVERALL_CHART,
        payload: { companyId, dealerId }
    }
}
export const overAllChartSuccessful = (overallChartData) => {
    return {
        type: OVERALL_CHART_SUCCESSFUL,
        payload: overallChartData
    }
}
export const countActivationByNetworkType = (companyId) => {
    return {
        type: COUNTACTIVATIONBY_NETWORKTYPE,
        payload: companyId
    }
}
export const countActivationByNetworkTypeSuccessful = (countActivationByNetworkData) => {
    return {
        type: COUNTACTIVATIONBY_NETWORKTYPE_SUCCESSFUL,
        payload: countActivationByNetworkData
    }
}
export const dashboardApiError = (error) => {
    return {
        type: DASHBOARD_API_ERROR,
        payload: error
    }
}

export const widgetData = (companyId, dealerId) => {
    return {
        type: WIDGET_DATA,
        payload: { companyId, dealerId }
    }
}

export const widgetDataSuccessFull = (widgets) => {
    return {
        type: WIDGET_DATA_SUCCESSFULL,
        payload: widgets
    }
}

export const searchActivationBySource = (searchData) => {
    return {
        type: SEARCH_ACTIVATION_BY_SOURCE,
        payload: searchData
    }
}

export const searchActivationBySourceSuccessfull = (sourceData) => {
    return {
        type: ACTIVATION_BY_SOURCE_DATA_SUCCESSFULL,
        payload: sourceData
    }
}

export const searchActivationBySourceFailed = (error) => {
    return {
        type: ACTIVATION_BY_SOURCE_DATA_FAILED,
        payload: error
    }
}

export const channelChartData = (companyId) => {
    return {
        type: CHANNEL_ACTIVATION_CHART_DATA,
        payload: companyId
    }
}

export const channelActivationChartDataSuccessfull = (channelChart) => {
    return {
        type: CHANNEL_ACTIVATION_CHART_DATA_SUCCESSFULL,
        payload: channelChart
    }
}

export const getUserActivationCountStatus = (companyId,dealerId) => {
    return {
        type: GET_ACTIVATION_COUNT_BY_USER,
        payload: {companyId,dealerId}
    }
}
export const getUserActivationCountStatusSuccess = (userActivationCountData) => {
    return {
        type: GET_ACTIVATION_COUNT_BY_USER_SUCCESS,
        payload: userActivationCountData
    }
}
export const getUserActivationCountStatusFailed = (error) => {
    return {
        type: GET_ACTIVATION_COUNT_BY_USER_FAILED,
        payload: error
    }
}