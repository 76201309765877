import { ACTIVATION_REPORT_LIST, ACTIVATION_REPORT_SUCCESSFULL, ACTIVATION_REPORT_FAILED, RESET_ACTIVATION_REPORT_PAGE } from "./actionTypes"

export const getActivationReport = (companyId, loginId, searchData) => {
    return {
        type: ACTIVATION_REPORT_LIST,
        payload: { companyId, loginId, searchData }
    }
}

export const getActivationReportSuccess = (activationData, message) => {
    return {
        type: ACTIVATION_REPORT_SUCCESSFULL,
        payload: { activationData, message }
    }
}

export const getActivationReportFailed = (error) => {
    return {
        type: ACTIVATION_REPORT_FAILED,
        payload: error
    }
}

export const resetActivationReportPage = () => {
    return {
        type: RESET_ACTIVATION_REPORT_PAGE,
    }
}