import { CLOSE_SIM_SWAP_CANCEL_MODAL, COMPELETE_SIM_SWAP_ACTIVATION, COMPELETE_SIM_SWAP_ACTIVATION_SUCCESSFULL, GET_SIM_SWAP_ERROR_CODE, GET_SIM_SWAP_ERROR_CODE_SUCCESSFULL, RESET_SIM_SWAP_ALERT_ACTIVATION, RESET_SIM_SWAP_REQUEST_PAGE, SIM_SWAP_DATA_SUBMIT_FAILED, SIM_SWAP_LOCK_ACTIVATION, SIM_SWAP_LOCK_ACTIVATION_FAILED_RESULT, SIM_SWAP_LOCK_ACTIVATION_RESULT, SIM_SWAP_MESSAGE_ALERT_HIDE, SIM_SWAP_REMARK_CANCEL, SIM_SWAP_REMARK_CANCEL_SUCCESSFULL, SIM_SWAP_UNLOCK_ACTIVATION, SIM_SWAP_UNLOCK_ACTIVATION_SUCCESS, VIEW_SIM_SWAP_REQUEST, VIEW_SIM_SWAP_REQUEST_FAILED, VIEW_SIM_SWAP_REQUEST_SUCCESSFUL, GET_MSISDN_DETAILS, GET_MSISDN_DETAILS_SUCCESS, GET_MSISDN_DETAILS_FAILED, ADD_SIM_SWAP_REQUEST, ADD_SIM_SWAP_REQUEST_FAILED, GET_OTP, GET_OTP_SUCCESS, GET_OTP_FAILED, RESET_COMPLETE_SIM_SWAP, CHECK_SIM_SWAP_COMPLETION, CHECK_SIM_SWAP_COMPLETION_SUCCESS, CHECK_SIM_SWAP_COMPLETION_FAILED } from "./actionTypes";

const initialState = {
    loading: false,
    apiError: null,
    message: null,
    simSwapRequestData: [],
    buttonLoading: false,
    cancelModal: false,
    messageModal: false,
    apiErrorModal: false,
    showSimSwapPage: false,
    msisdnDetails: null,
    apiSwapError: null,
    otpDetails: null,
    otpError: null,
    showOtp: false,
    getOtpLoading: false,
    checkSimSwapCompletionLoading: false,
    checkSimSwapCompletionError: null,
    checkSimSwapCompletionMessage: null,
}

const simSwapRequest = (state = initialState, action) => {
    let activationListNewState;
    switch (action.type) {
        case VIEW_SIM_SWAP_REQUEST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null,
                showSimSwapPage: true,
                otpDetails: null,
                showOtp: false
            }
            break;

        case VIEW_SIM_SWAP_REQUEST_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                apiError: null,
                message: (action.payload.message !== '') ? action.payload.message : null,
                simSwapRequestData: action.payload.simSwapRequestData,
                buttonLoading: false,
                showSimSwapPage: true,
                addLoading: false,
                msisdnDetails: null,
                showOtp: false
            }
            break;

        case VIEW_SIM_SWAP_REQUEST_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                message: null,
                buttonLoading: false,
                showSimSwapPage: true,
                showOtp: false
            }
            break;
        case SIM_SWAP_LOCK_ACTIVATION:
            activationListNewState = state.simSwapRequestData.map((item) => {
                let temp = item;
                if (item.ID == action.payload.lockActivationData.activationId) {
                    temp.lockLoading = !temp.lockLoading;
                }
                return temp;
            });

            state = {
                ...state,
                simSwapRequestData: activationListNewState,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case SIM_SWAP_LOCK_ACTIVATION_RESULT:
            state = {
                ...state,
                Lockmessage: action.payload.Message,
                simSwapRequestData: action.payload.activationData,
                locksuccessAlert: true,
                buttonLoading: false,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case SIM_SWAP_LOCK_ACTIVATION_FAILED_RESULT:
            state = {
                ...state,
                LockFailAlert: true,
                LockedFailedmessage: action.payload.Message,
                LockedByUser: action.payload.LockedByUser,
                buttonLoading: false,
                showSimSwapPage: true,
                showOtp: false
            }
            break;
        case SIM_SWAP_UNLOCK_ACTIVATION:
            activationListNewState = state.simSwapRequestData.map((item) => {
                let temp = item;
                if (item.ID == action.payload.unLockData.activationId) {
                    temp.releaseLoading = !temp.releaseLoading;
                }
                return temp;
            });
            state = {
                ...state,
                simSwapRequestData: activationListNewState,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case SIM_SWAP_UNLOCK_ACTIVATION_SUCCESS:
            state = {
                ...state,
                unLockMessage: action.payload,
                simSwapRequestData: action.payload.activationData,
                buttonLoading: false,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case RESET_SIM_SWAP_ALERT_ACTIVATION:
            state = {
                ...state,
                loading: false,
                message: null,
                apiError: null,
                LockFailAlert: false,
                buttonLoading: false,
                showSimSwapPage: true,
                msisdnDetails: null,
                showOtp: false
            }
            break;

        case GET_SIM_SWAP_ERROR_CODE:
            activationListNewState = state.simSwapRequestData.map((item) => {
                let temp = item;
                if (item.ID == action.payload.activationId) {
                    temp.cancelModalLoading = !temp.cancelModalLoading;
                }
                return temp;
            });
            state = {
                ...state,
                loading: false,
                buttonLoading: false,
                simSwapRequestData: activationListNewState,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case GET_SIM_SWAP_ERROR_CODE_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                errorList: action.payload,
                apiError: null,
                buttonLoading: false,
                cancelModal: true,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case SIM_SWAP_REMARK_CANCEL:
            state = {
                ...state,
                buttonLoading: true,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case SIM_SWAP_REMARK_CANCEL_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                messageModal: action.payload.message,
                apiError: null,
                apiErrorModal: null,
                simSwapRequestData: action.payload.activations,
                buttonLoading: false,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case SIM_SWAP_DATA_SUBMIT_FAILED:
            state = {
                ...state,
                loading: false,
                apiErrorModal: action.payload,
                buttonLoading: false,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case SIM_SWAP_MESSAGE_ALERT_HIDE:
            state = {
                ...state,
                // repushError: null,
                // repushMessage: null,
                modalFlag: false,
                buttonLoading: false,
                requestData: null,
                showSimSwapPage: true,
                messageModal: false,
                apiErrorModal: false,
                showOtp: false
            }
            break;

        case CLOSE_SIM_SWAP_CANCEL_MODAL:
            activationListNewState = state.simSwapRequestData.map((item) => {
                let temp = item;
                temp.cancelModalLoading = false;
                return temp;
            });

            state = {
                ...state,
                cancelModal: false,
                simSwapRequestData: activationListNewState,
                showSimSwapPage: true,
                showOtp: false
            }
            break;

        case RESET_SIM_SWAP_REQUEST_PAGE:
            state = {
                ...state,
                loading: false,
                showSimSwapPage: false,
                otpDetails: null,
                showOtp: false
            }
            break;

        case COMPELETE_SIM_SWAP_ACTIVATION:
            state = {
                ...state,
                buttonLoading: true,
                otpDetails: null,
                showOtp: false
            }
            break;

        case COMPELETE_SIM_SWAP_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                messageModal: action.payload.message,
                loading: false,
                apiErrorModal: null,
                apiError: null,
                simSwapRequestData: action.payload.activations,
                buttonLoading: false,
                otpDetails: null,
                showOtp: false
            }
            break;

        case GET_MSISDN_DETAILS:
            state = {
                ...state,
                loading: true,
                msisdnDetails: null,
                apiError: null,
                otpDetails: null,
                showOtp: false
            }
            break;

        case GET_MSISDN_DETAILS_SUCCESS:
            state = {
                ...state,
                loading: false,
                msisdnDetails: action.payload.requestDetails,
                apiError: null,
                otpDetails: null,
                showOtp: false
            }
            break;

        case GET_MSISDN_DETAILS_FAILED:
            state = {
                ...state,
                loading: false,
                msisdnDetails: null,
                apiError: action.payload,
                otpDetails: null,
                showOtp: false
            }
            break;

        case ADD_SIM_SWAP_REQUEST:
            state = {
                ...state,
                addLoading: true,
                otpDetails: null,
                showOtp: false
            }
            break;

        case ADD_SIM_SWAP_REQUEST_FAILED:
            state = {
                ...state,
                addLoading: false,
                apiSwapError: action.payload.Message,
                otpDetails: null,
                showOtp: false
            }
            break;

        case GET_OTP:
            state = {
                ...state,
                otpDetails: null,
                otpError: null,
                showOtp: false,
                getOtpLoading: true
            }
            break;

        case GET_OTP_SUCCESS:
            state = {
                ...state,
                otpDetails: action.payload.otpDetails,
                otpError: null,
                showOtp: true,
                getOtpLoading: false
            }
            break;

        case GET_OTP_FAILED:
            state = {
                ...state,
                otpDetails: null,
                otpError: action.payload.Message,
                showOtp: true,
                getOtpLoading: false
            }
            break;

        case RESET_COMPLETE_SIM_SWAP:
            state = {
                ...state,
                otpDetails: null,
                otpError: null,
                showOtp: false,
                getOtpLoading: false,
                checkSimSwapCompletionLoading: false,
                checkSimSwapCompletionError: null,
                checkSimSwapCompletionMessage: null,
            }
            break;

        case CHECK_SIM_SWAP_COMPLETION:
            state = {
                ...state,
                checkSimSwapCompletionLoading: true,
                checkSimSwapCompletionError: null,
                checkSimSwapCompletionMessage: null,
            }
            break;

        case CHECK_SIM_SWAP_COMPLETION_SUCCESS:
            state = {
                ...state,
                checkSimSwapCompletionLoading: false,
                checkSimSwapCompletionError: null,
                checkSimSwapCompletionMessage: action.payload.message,
            }
            break;

        case CHECK_SIM_SWAP_COMPLETION_FAILED:
            state = {
                ...state,
                checkSimSwapCompletionLoading: false,
                checkSimSwapCompletionError: action.payload.error,
                checkSimSwapCompletionMessage: null,
            }
            break;

        default:
            state = { ...state };
    }
    return state;
}

export default simSwapRequest;