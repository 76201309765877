import React, { ChangeEvent, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { resetAssignDealer, assignDealerToActivation } from '../store/assignDealer/actions';
import { getAllChannelList } from '../store/dealer/actions';
import Spinner from '../components/layout/Spinner';
import FileSaver from "file-saver";
import Papa from "papaparse";

function AssignDealer(props) {
    const dispatch = useDispatch();
    const [popUp, setpopUp] = useState(false);
    const [uploadedCSVData, setUploadedCSVData] = useState(null);
    const [duplicateData, setDuplicateSimData] = useState([]);

    const [channelId, setChannelId] = useState(0);
    
    useEffect(() => {
        const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;

        props.getAllChannelList(dealerId);
    }, []);

    const columns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'SERIAL/EID',
            width: "350px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'ACODE',
            width: "80px",
            selector: row => row.ACode,
        },
    ];

    const responseColumns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'SERIAL/EID',
            width: "350px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'ACODE',
            width: "80px",
            selector: row => row.ACode,
        },
        {
            name: 'MESSAGE',
            width: "445px",
            selector: row => row.Message,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const handleExcelUpload = (e) => {
        dispatch(resetAssignDealer())
        setDuplicateSimData([]);
        const fileReader = new FileReader();
        
        var file = e.target.files[0];
        if (file) {
            fileReader.onload = ({target}) => {
                const csvOutput = Papa.parse(target.result, {header: true});
                const parsedData = csvOutput?.data;
                const columnsCsv = Object.keys(parsedData[0]);

                if(columnsCsv[0] === 'SerialNumber' && columnsCsv[1] === 'ACode'){
                    // sim duplicate checking
                    const validSimData = [];
                    const duplicateSimData = [];
                    const actualData = [];
                    setUploadedCSVData(parsedData);

                    parsedData.map((request, key) => {   
                        if (request.SerialNumber.length === 19 && Number.isInteger(Number(request.SerialNumber))) {
                            if(validSimData.includes(request.SerialNumber)){
                                duplicateSimData.push(request.SerialNumber);
                            }
                            else{
                                validSimData.push(request.SerialNumber);
                                actualData.push({ "SerialNumber": request.SerialNumber, "ACode": request.ACode });
                            }
                        }
                    });
                    
                    if(duplicateSimData.length === 0){
                        if(validSimData.length === 0){
                            alert('No valid SIM to upload');
                        }
                        else{
                            setUploadedCSVData(actualData);
                        }
                    }
                    else{
                        alert('Duplicate data in CSV file uploaded');
                    }
                }
                else{
                    alert(`Column header's not matching with csv sample file`);
                    return false;
                }
            };
            fileReader.readAsText(file);
        }
    }

    const handleChannelChange = (e) => {
        setChannelId(e.target.value);
    }

    const handleConfirmAssign = () => {
        if(channelId > 0) {
            setpopUp(true);
        }
        else{
            alert("Select channel");
        }
    }

    const handleAssignDealerToRequest = () => {
        setpopUp(false);

        const reqValidationData = {
            "reqData": uploadedCSVData
        };

        dispatch(assignDealerToActivation(Number(channelId), reqValidationData));
    }

    var filterData = uploadedCSVData && uploadedCSVData.map((data, key) => {
        return {
            serial: key + 1,
            SerialNumber: (<p style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>),
            ACode: (<p>{data.ACode}</p>),
        }
    })

    var responseFilterData = props.assignResponse && props.assignResponse.map((data, key) => {
        return {
            serial: key + 1,
            SerialNumber: (<p style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>),
            ACode: (<p>{data.ACode}</p>),
            Message: (<p style={{ 'fontWeight': 'bold' }} className={`${data.Status === 0 ? 'text-danger' : 'text-success'}`}>{data.Message}</p>),
        }
    })

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Assign Dealer To Activation</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <div className="row g-3">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="dealerID">Channel</label>
                                        <div className="form-control-wrap">
                                        <select name="channelId" className="form-control" id="channelId" onChange={(e) => handleChannelChange(e)} required>
                                            <option value="">Select Channel</option>
                                            {props.channelData && props.channelData.map(channel => (
                                            <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                                            ))}
                                        </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5">
                                    <div className="form-group">    
                                        <label className="form-label" for="customFileLabel">Upload File</label>    
                                        <div className="form-control-wrap">        
                                            <div className="form-file">
                                                <input type="file" onChange={handleExcelUpload} className="form-file-input" id="activationFile" accept=".csv" />
                                                <label className="form-file-label" for="activationFile">Choose file</label>        
                                            </div>    
                                        </div>
                                    </div>
                                </div>                              
                            </div>
                        </div>
                    </div>
                </div>
                {props.apiError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}
                {uploadedCSVData && !props.assignResponse && <div className="card p-2" style={{ marginTop: '12px' }}>
                    <div className="card-inner p-0" >
                        <DataTable columns={columns} data={filterData} customStyles={customStyles} progressComponent={<Spinner />} progressPending={props.loading} responsive striped />
                        
                        {filterData.length > 0 && <div style={{ 'paddingTop': '10px', 'float': 'right'}}><Link to="#" style={{ 'cursor': 'pointer' }} onClick={handleConfirmAssign} className="btn btn-info mx-2">Assign Dealer</Link></div>}
                    </div>
                </div>}
                {popUp && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-danger text-white">
                                <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to assign these dealers to provided requests? Click Yes to continue.</p>
                            </div>
                            <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={handleAssignDealerToRequest}>Yes</div></div>
                        </div>
                    </div>
                </div>}
                {props.assignResponse && <div className="card p-2" style={{ marginTop: '12px' }}>
                    <div className="card-inner p-0" >
                        <DataTable columns={responseColumns} data={responseFilterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                    </div>
                </div>}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const {channelData} = state.dealer
    const { apiError, loading, assignResponse } = state.assignDealer
    return { channelData, apiError, loading, assignResponse };
}

export default withRouter(connect(mapStateToProps, { getAllChannelList, assignDealerToActivation })(AssignDealer));