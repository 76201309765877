import {
    GET_SERVER_LIST,
    GET_SERVER_LIST_SUCCESSFULL,
    SERVER_FAILED,
  } from "./actionTypes";
  
  const initialState = { apiError: null, loading: false };
  
  const server = (state = initialState, action) => {
    switch (action.type) {
      case GET_SERVER_LIST:
        state = {
          ...state,
          loading: true,
        };
        break;
  
      case GET_SERVER_LIST_SUCCESSFULL:
        state = {
          ...state,
          loading: false,
          serverData: action.payload,
        };
        break;
  
      case SERVER_FAILED:
        state = {
          ...state,
          message: action.payload,
        };
        break;
      default:
        state = { ...state };
        break;
    }
    return state;
  };
  
  export default server;
  