import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import FilterComponent from "./FilterComponent";
import moment from "moment/moment";
import Spinner from "../components/layout/Spinner";
import { PERMISSIONS } from '../Permission/Permission';
import { locationList, addLocation, editLocation, assetList, addAsset, resetAssetAlert, editAsset, deleteAsset, deleteLocation, assetReport, assetReportByAssetID, assetRunningStatusChange } from "../store/asset/actions";
import { getAllChannelList, getCountryList } from "../store/dealer/actions";
import { Link } from 'react-router-dom';
import heartbeatGif from '../assets/images/heartbeat.gif';
import heartbeatLineGif from '../assets/images/heartbeatline.gif';

function Asset(props) {
  const timezone = process.env.REACT_APP_TIMEZONE;
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const dispatch = useDispatch();
  const [allAssetData, setAllAssetData] = useState({
    assetName: "",
    locationID: 0,
    channelID: 0,
    bulkFlag: 0,
    bulkQuantity: 0,
    companyID: 1,
    createdBy: "",
  });

  const [deleteAssetData, setDeleteAssetData] = useState({
    modifiedBy: null,
    modifiedDtTm: ''
  });

  const [allLocationData, setAllLocationData] = useState({
    locationName: '',
    address: '',
    countryID: 0,
    contactPerson: '',
    contactNumber: '',
    contactEmail: '',
    createdBy: '',
  });

  const [deleteLocationData, setDeleteLocationData] = useState({
    modifiedBy: null,
    modifiedDtTm: ''
  })

  const [addLocationForm, setAddLocationForm] = useState(false);
  const [addAssetForm, setAddAssetForm] = useState(false);
  const [RoleId, setRoleId] = useState(null);
  const [CompanyId, setCompanyId] = useState("");
  const [editLocationFlag, setEditLocationFlag] = useState(false);
  const [editAssetFlag, setEditAssetFlag] = useState(false);
  const [editLocationId, setEditLocationId] = useState();
  const [editAssetId, setEditAssetId] = useState();
  const [locValidationError, setLocValidationError] = useState('');
  const [assetValidationError, setAssetValidationError] = useState('');
  const [LoginId, setLoginID] = useState('');
  const [activeTab, setActiveTab] = useState("locationtab");
  const [showModal, setshowModal] = useState(false);
  const [popUp, setpopUp] = useState(false);
  const [popUpDeleteContent, setPopUpDeleteContent] = useState('');
  const [assetChangeStatus, setAssetChangeStatus] = useState(null);
  const [editId, setEditId] = useState();
  const [dataSearch, setDataSearch] = useState(false);

  const [searchAsset, setSearchAsset] = useState({
    companyID: 1,
    channelId: 0,
    assetType: 0,
    Status: 2
  });

  useEffect(() => {
    const loginId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).ID : null;
    const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
    const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
    const dealerId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : null;

    setLoginID(loginId);
    setRoleId(roleId);
    setCompanyId(companyId);

    setAllAssetData((prevState) => ({
      ...prevState,
      companyID: companyId,
      createdBy: loginId,
    }));

    setAllLocationData((prevState) => ({
      ...prevState,
      createdBy: loginId,
    }));

    setSearchAsset((prevState) => ({
      ...prevState,
      companyID: companyId,
    }));

    // props.assetList(companyId, searchAsset.channelId, searchAsset.assetType);
    props.locationList();
    props.assetReport(companyId);
    setEditLocationFlag(false);
    setEditAssetFlag(false);

    setDeleteLocationData(prevState => ({
      ...prevState,
      modifiedBy: loginId,
      modifiedDtTm: moment(new Date()).tz(process.env.REACT_APP_TIMEZONE).format()
    }));

    setDeleteAssetData(prevState => ({
      ...prevState,
      modifiedBy: loginId,
      modifiedDtTm: moment(new Date()).tz(process.env.REACT_APP_TIMEZONE).format()
    }));

    props.getAllChannelList(dealerId);
  }, []);

  useEffect(() => {
    const hrefData = window.location.href
    const splitedPath = hrefData.split('/')
    if (splitedPath[3] === "asset#assetReporttab") {
      setActiveTab('assetReporttab')
    }
  }, [])

  var filterArray = [];
  for (const [key, value] of Object.entries(PERMISSIONS)) {
    if (key == RoleId) {
      filterArray.push(value);
    }
  }

  const locationColumns = [
    {
      name: "#",
      width: "30px",
      selector: row => row.serial,
    },
    {
      name: "LOCATION NAME",
      selector: row => row.locationName,
    },
    {
      name: "ADDRESS",
      selector: row => row.address,
    },
    {
      name: "CONTACT DETAILS",
      selector: row => row.conatctDetails,
    },
    {
      name: "STATUS",
      selector: row => row.locStatus,
    },
    // {
    //   name: "CREATED BY",
    //   selector: row => row.createdBy,
    // },
    {
      name: "CREATED DATE",
      selector: row => row.createdDate,
    },
    filterArray && filterArray.length > 0 && filterArray[0].includes("edit_asset") && {
      name: "ACTION",
      selector: row => row.action,
    },
  ];

  const assetColumns = [
    {
      name: "#",
      width: "30px",
      selector: row => row.serial,
    },
    {
      name: "ASSET NAME",
      width: "150px",
      selector: row => row.assetName,
    },
    {
      name: "LOCATION",
      width: "100px",
      selector: row => row.locationName,
    },
    {
      name: "CHANNEL",
      width: "100px",
      selector: row => row.channelName,
    },
    {
      name: "BULK FLAG",
      width: "100px",
      selector: row => row.bulkFlag,
    },
    {
      name: "BULK QTY ",
      width: "100px",
      selector: row => row.bulkQty,
    },
    {
      name: "STATUS",
      width: "100px",
      selector: row => row.status,
    },
    // {
    //   name: "CREATED BY",
    //   width: "100px",
    //   selector: row => row.createdBy,
    // },
    {
      name: "CREATED DATE",
      width: "100px",
      selector: row => row.createdDate,
    },
    {
      name: "HEARTBEAT",
      width: "150px",
      selector: row => row.heartbeat,
    },
    filterArray && filterArray.length > 0 && filterArray[0].includes("edit_asset") && {
      name: "TURN ON/OFF",
      width: "130PX",
      selector: row => row.assetRunningStatus,
    },
    filterArray && filterArray.length > 0 && filterArray[0].includes("edit_asset") && {
      name: "ACTION",
      width: "150px",
      selector: row => row.action,
    },

  ];

  const assetReportColumns = [
    {
      name: "#",
      width: "30px",
      selector: row => row.serial,
    },
    {
      name: "ASSET NAME",
      selector: row => row.assetName,
    },
    {
      name: "PROCESSING",
      selector: row => row.processing,
    },
    {
      name: "ASSIGN TO CARE",
      selector: row => row.assignToCare,
    },
  ]

  const modalColumns = [
    {
      name: '#',
      width: "40px",
      selector: row => row.serial,
    },
    {
      name: 'ASSET NAME',
      width: "120px",
      selector: row => row.asset
    },
    {
      name: 'CHANNEL TXN',
      width: "120px",
      selector: row => row.channelTxnID,
    },
    {
      name: 'CHANNEL NAME',
      width: "120px",
      selector: row => row.channelName,
    },
    {
      name: 'SERIAL/EID',
      width: "180px",
      selector: row => row.serialNumber,
    },
    {
      name: 'DATE',
      width: "120px",
      selector: row => row.requestedDtTm,
    },
    {
      name: 'IMEI',
      width: "180px",
      selector: row => row.imei,
    },
    {
      name: 'SIM',
      selector: row => row.simType,
    },
    {
      name: 'STATUS',
      width: '180px',
      selector: row => row.requestStatus,
    },
    {
      name: 'TYPE',
      width: "120px",
      selector: row => row.requestType,
    }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '0px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '0px',
      },
    },
  };

  const filterLocationData = props.locationData && props.locationData.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      locationName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.LocationName}</p>),
      address: (<p>{data.Address}, {data.CountryName}</p>),
      conatctDetails: (<p>{data.ContactPerson}<br />{data.ContactNumber}<br />{data.ContactEmail}</p>),
      locStatus: (data.IsActive === 1 ? <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">Active</span> : <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">Inactive</span>),
      createdBy: (<p>{data.Name}</p>),
      createdDate: (<p>{data.CreatedDtTm !== null ? (moment(new Date(data.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")) : ''}</p>),
      action: (<span><em className="icon icon-circle bg-warning ni ni-edit-alt" style={{ cursor: 'pointer' }} onClick={() => handleEditLocForm(data.ID)}></em>
        {data.IsActive === 1 ? <em className="icon icon-circle ni ni-trash-alt bg-danger mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleLocStatusChange(data.ID, 0)}></em> :
          <em className="icon icon-circle icon ni ni-check-thick bg-success mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleLocStatusChange(data.ID, 1)}></em>}</span>),
    }
  });


  const filteredItems = props.assetData && props.assetData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  /*
  const filterAssetData = props.assetData && props.assetData.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      assetName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.AssetName}</p>),
      locationName: (<p>{data.LocationName}</p>),
      channelName: (<p>{data.ChannelName == null ? 'Common' : data.ChannelName}</p>),
      bulkFlag: (<p>{data.BulkFlag == 1 ? 'Yes' : 'No'}</p>),
      bulkQty: (<p>{data.BulkQuantity > 0 ? data.BulkQuantity : ''}</p>),
      status: (data.IsActive === 1 ? <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">Active</span> : <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">Inactive</span>),
      createdBy: (<p>{data.Name}</p>),
      createdDate: (<p>{data.CreatedDtTm !== null ? (moment(new Date(data.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")) : ''}</p>),
      heartbeat: (<p style={{textAlign: "center"}}>{(data.LastInteractedTime === null || data.LastInteractedTime === 0) ? <h6><em className="icon ni ni-minus text-danger"></em><em className="icon ni ni-minus text-danger"></em><em className="icon ni ni-minus text-danger"></em></h6> : <h6><em className="icon ni ni-activity text-success"></em><em className="icon ni ni-activity text-success"></em><em className="icon ni ni-activity text-success"></em></h6>}</p>),
      action: (<span><em className="icon icon-circle bg-warning ni ni-edit-alt" style={{ cursor: 'pointer' }} onClick={() => handleEditAssetForm(data.ID)}></em>
        {data.IsActive === 1 ? <em className="icon icon-circle ni ni-trash-alt bg-danger mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleAssetStatusChange(data.ID, 0)}></em> :
          <em className="icon icon-circle icon ni ni-check-thick bg-success mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleAssetStatusChange(data.ID, 1)}></em>}</span>),
    }
  });
  */

  const filterAssetData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      assetName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.AssetName}</p>),
      locationName: (<p>{data.LocationName}</p>),
      channelName: (<p>{data.ChannelName == null ? 'Common' : data.ChannelName}</p>),
      bulkFlag: (<p>{data.BulkFlag == 1 ? 'Yes' : 'No'}</p>),
      bulkQty: (<p>{data.BulkQuantity > 0 ? data.BulkQuantity : ''}</p>),
      status: (data.IsActive === 1 ? <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">Active</span> : <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">Inactive</span>),
      createdBy: (<p>{data.Name}</p>),
      createdDate: (<p>{data.CreatedDtTm !== null ? (moment(new Date(data.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")) : ''}</p>),
      heartbeat: (
        <p style={{ textAlign: "center" }}>{(data.LastInteractedTime === null || data.LastInteractedTime === 0) ? <h6><img src={heartbeatLineGif} /></h6> : <h6><img src={heartbeatGif} /></h6>}</p>
      ),
      action: (<span><em className="icon icon-circle bg-warning ni ni-edit-alt" style={{ cursor: 'pointer' }} onClick={() => handleEditAssetForm(data.ID)}></em>
        {data.IsActive === 1 ? <em className="icon icon-circle ni ni-trash-alt bg-danger mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleAssetStatusChange(data.ID, 0)}></em> :
          <em className="icon icon-circle icon ni ni-check-thick bg-success mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleAssetStatusChange(data.ID, 1)}></em>}
      </span>),
      assetRunningStatus: (<span>{data.AssetRunningStatus === 1 ? <em className="icon icon-circle ni ni-meter bg-success mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleOpenPopUpAlertInActive(data.ID, 0)}></em> :
        <em className="icon icon-circle icon ni ni-meter bg-danger mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleOpenPopUpAlertActive(data.ID, 1)}></em>}
      </span>),
    }
  });

  const filterAssetReportData = props.assetReportData && props.assetReportData.map((data, key) => {
    return {
      serial: key + 1,
      assetName: data.ServerName,
      processing: data.Processing !== 0 ? <Link style={{ color: 'blue' }} onClick={() => handleModalOpen(data.AssetID, 'processing')}>{data.Processing}</Link> : data.Processing,
      assignToCare: data.AssignedToCare !== 0 ? <Link style={{ color: 'blue' }} onClick={() => handleModalOpen(data.AssetID, 'assignToCare')}>{data.AssignedToCare}</Link> : data.AssignedToCare,
    }
  })

  const modalData = props.eachAssetReportData && props.eachAssetReportData.map((data, key) => {
    // const parseChildData = data.MultiLineFlag === 1 ? JSON.parse(data.ChildActivation) : null;
    return {
      ID: data.ID,
      serial: key + 1,
      channelTxnID: <p className={data.Multiline === 1 ? `badge rounded-pill bg-outline-danger` : ''}>{data.ChannelTxnID}</p>,
      serialNumber: (<p style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>),
      requestedDtTm: (<p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
      imei: (<p style={{ "whiteSpace": "break-spaces" }}>{data.IMEI}</p>),
      asset: (<p>{data.JARServerName}</p>),
      simType: (<p>{data.SimType}</p>),
      requestStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : 'Pending'} </span>),
      requestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 'SimSwap' ? 'text-warning' : data.RequestType === 'PortIn' ? 'text-primary' : 'text-success'}`} >{data.RequestType[0]}</p>),
      // networkName: (<p>{data.NetworkName}</p>),
      channelName: (<p>{data.ChannelName}</p>),
      childActivation: data.ChildActivation,
      multiLineFlag: data.Multiline
    }
  })

  const handleAssetChange = (e) => {
    setAssetValidationError('');
    const fname = e.target.name;
    const fvalue = e.target.value;

    setAllAssetData((prevState) => ({
      ...prevState,
      [fname]: fvalue,
    }));
  };

  const handleLocationChange = (e) => {
    setLocValidationError('')

    const fname = e.target.name;
    const fvalue = e.target.value;

    setAllLocationData((prevState) => ({
      ...prevState,
      [fname]: fvalue,
    }));
  };

  const handleAddLocationForm = () => {
    dispatch(getCountryList());
    setAddLocationForm(true);
    setAddAssetForm(false);
    setEditLocationFlag(false);
    setEditAssetFlag(false);
    dispatch(resetAssetAlert());
    setAllLocationData(prevState => ({
      ...prevState,
      locationName: '',
      address: '',
      countryID: 0,
      contactPerson: '',
      contactNumber: '',
      contactEmail: '',
    }));
  }

  const handleAddAssetForm = () => {
    setAddLocationForm(false);
    setAddAssetForm(true);
    setEditLocationFlag(false);
    setEditAssetFlag(false);
    dispatch(resetAssetAlert());
    setAllAssetData(prevState => ({
      ...prevState,
      assetName: "",
      locationID: "",
      channelID: 0,
      companyID: 1,
    }));
  }

  const handleEditLocForm = (locId) => {
    dispatch(resetAssetAlert());
    let editLocData = props.locationData && props.locationData.filter(
      item => item.ID === locId
    );
    setEditLocationFlag(true);
    setEditLocationId(locId);
    setAllLocationData(prevState => ({
      ...prevState,
      locationName: editLocData[0].LocationName,
      address: editLocData[0].Address,
      countryID: editLocData[0].CountryID,
      contactPerson: editLocData[0].ContactPerson,
      contactNumber: editLocData[0].ContactNumber,
      contactEmail: editLocData[0].ContactEmail,
    }));
    setAddLocationForm(true);
  };

  const handleEditAssetForm = (assetId) => {
    dispatch(resetAssetAlert());
    let editAssetData = props.assetData && props.assetData.filter(
      item => item.ID === assetId
    );
    setEditAssetFlag(true);
    setEditAssetId(assetId);
    setAllAssetData(prevState => ({
      ...prevState,
      assetName: editAssetData[0].AssetName,
      locationID: editAssetData[0].LocationID,
      channelID: editAssetData[0].ChannelID,
      bulkFlag: editAssetData[0].BulkFlag,
      bulkQuantity: editAssetData[0].BulkQuantity,
    }));
    setAddAssetForm(true);
  };

  const handleLocationSubmit = (e) => {
    e.preventDefault();

    if (allLocationData.locationName != '' && allLocationData.countryID > 0) {
      setLocValidationError('')
      dispatch(addLocation(allLocationData));
    }
    else {
      setLocValidationError('Input required data')
    }
  };

  const handleLocationUpdate = (e) => {
    e.preventDefault();
    allLocationData.modifiedBy = LoginId;
    allLocationData.modifiedDtTm = moment(new Date()).tz(timezone).format()
    dispatch(editLocation(allLocationData, editLocationId));
  }

  const handleAssetSubmit = (e) => {
    e.preventDefault();
    if (allAssetData.assetName != '' && allAssetData.locationID > 0) {
      setAssetValidationError('')
      dispatch(addAsset(allAssetData, CompanyId,searchAsset.channelId, searchAsset.assetType, searchAsset.Status));
    } else {
      setAssetValidationError('Input required data')
    }
  };

  const handleAssetUpdate = (e) => {
    e.preventDefault();
    allAssetData.modifiedBy = LoginId;
    allAssetData.modifiedDtTm = moment(new Date()).tz(timezone).format();
    dispatch(editAsset(allAssetData, editAssetId, CompanyId,searchAsset.channelId, searchAsset.assetType, searchAsset.Status));
  }

  const handleOpenPopUpAlertInActive = (userId, userStatus) => {
    setpopUp(true);
    setPopUpDeleteContent('Are you sure, you want to turn off the asset running status ?')
    setEditId(userId);
    setAssetChangeStatus(userStatus);
  }

  const handleOpenPopUpAlertActive = (userId, userStatus) => {
    setpopUp(true);
    setPopUpDeleteContent('Are you sure, you want to turn on the asset running status ?')
    setEditId(userId);
    setAssetChangeStatus(userStatus);
  }

  const handleLocStatusChange = (locId, locStatus) => {
    dispatch(deleteLocation(locId, locStatus, deleteLocationData));
  }

  const handleAssetStatusChange = (assetId, assetStatus) => {
    dispatch(deleteAsset(assetId, assetStatus, deleteAssetData, CompanyId, searchAsset.channelId, searchAsset.assetType));
  }

  const handleChangeAssetRunningStatus = () => {
    dispatch(assetRunningStatusChange(editId, assetChangeStatus, deleteAssetData, CompanyId, searchAsset.channelId, searchAsset.assetType));
    setpopUp(false);
  }

  const handleToCountry = (e) => {
    const countryId = Number(e.target.value);
    setAllLocationData(prevState => ({
      ...prevState,
      countryID: countryId
    }));
  }

  const handleLocationCancel = (e) => {
    setAddLocationForm(false);
  }

  const handleAssetCancel = (e) => {
    setAddAssetForm(false);
  }

  if (props.message) {
    setTimeout(() => {
      setAddLocationForm(false);
      setAddAssetForm(false);
    }, 800);
  }

  const checkBulk = (e) => {
    if (e.target.checked) {
      setAllAssetData(prevState => ({
        ...prevState,
        bulkFlag: 1
      }));
    }
    else {
      setAllAssetData(prevState => ({
        ...prevState,
        bulkFlag: 0
      }));
    }
  }

  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }

  const handleModalOpen = (assetId, requestStatus) => {
    setshowModal(true);
    let searchData = {
      assetID: assetId,
      statusID: requestStatus === 'processing' ? "11, 18, 19, 20, 21, 22" : "27"
    }
    dispatch(assetReportByAssetID(searchData))
  }

  const renderExpandableRow = (row) => {
    if (row.data.multiLineFlag === 0 || !row.data.childActivationData == null) {
      return null;
    } else {
      const childData = JSON.parse(row.data.childActivation);
      const childColumn = [
        {
          name: '#',
          width: "40px",
          selector: row => row.serial,
        },
        {
          name: 'SERIAL/EID',
          width: "180px",
          selector: row => row.serialNumber,
        },
        {
          name: 'IMEI',
          width: "180px",
          selector: row => row.imei,
        },
        {
          name: 'MSISDN',
          width: '190px',
          selector: row => row.MSISDN,
        },
        {
          name: 'STATUS',
          width: '180px',
          selector: row => row.requestStatus,
        },
        {
          name: 'SIM',
          width: "120px",
          selector: row => row.simType,
        }
        // Add more columns as needed
      ];

      const filterChildData = childData && childData.length > 0 && childData.map((data, key) => {
        return {
          serial: key + 1,
          serialNumber: (<p style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>),
          imei: (<p style={{ "whiteSpace": "break-spaces" }}>{data.IMEI}</p>),
          MSISDN: data.MSISDN,
          simType: (<p>{data.SimType}</p>),
          requestStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : 'Pending'} </span>),
        }
      })
      return (
        <DataTable
          columns={childColumn}
          data={filterChildData}
          customStyles={customStyles}
          pagination
          responsive
          striped
        />
      );
    }
  }

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  }

  const handleFilterChange = (e) => {
    setSearchAsset((prevState) => ({
      ...prevState,
      assetType: Number(e.target.value),
    }));
  }

  const handleStatusChange = (e) => {
    setSearchAsset((prevState) => ({
      ...prevState,
      Status: Number(e.target.value),
    }));
  }

  const handleAssetSearchData = (e) => {
    e.preventDefault();
    setDataSearch(true);
    dispatch(assetList(searchAsset.companyID, searchAsset.channelId, searchAsset.assetType, searchAsset.Status));
  }

  return (
    <>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a className={`nav-link ${(activeTab == 'locationtab') ? 'active' : ''}`} data-toggle="tab" href="#locationtab" onClick={() => setActiveTab('locationtab')}><span>Location</span></a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${(activeTab == 'assettab') ? 'active' : ''}`} data-toggle="tab" href="#assettab" onClick={() => setActiveTab('assettab')}><span>Asset</span></a>
        </li>
        <li className="nav-item">
          <a className={`nav-link ${(activeTab == 'assetReporttab') ? 'active' : ''}`} data-toggle="tab" href="#assetReporttab" onClick={() => setActiveTab('assetReporttab')}><span>Asset Report</span></a>
        </li>
      </ul>

      <div className="tab-content">

        <div className={`tab-pane ${(activeTab == 'locationtab') ? 'active' : ''}`} id="locationtab">
          <div className="nk-block-head-content">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
              <h3 className="nk-block-title page-title">Location</h3>
              {filterArray && filterArray.length > 0 && filterArray[0].includes("add_asset") && <>
                <div data-target="addProduct" className="toggle btn btn-primary d-none d-md-inline-flex" onClick={(handleAddLocationForm)}>
                  <em className="icon ni ni-plus"></em>
                  <span>Add Location</span>
                </div>
              </>}
            </div>
            <div className='card p-2' style={{ marginTop: "12px" }}>
              {props.message &&
                <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
              {props.apiError &&
                <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}

              <div className="card-inner p-0" >
                <DataTable
                  pagination
                  columns={locationColumns}
                  data={filterLocationData}
                  customStyles={customStyles}
                  // subHeaderComponent={subHeaderComponent}
                  progressPending={props.locationLoading}
                  progressComponent={<Spinner />}
                  // subHeader
                  striped
                  responsive
                />
              </div>
              {addLocationForm && <div className="toggle-overlay" data-target="addLocation"></div>}
            </div>
            {addLocationForm && (
              <div className="nk-add-product toggle-slide toggle-slide-right toggle-screen-any content-active" data-content="addLocation" data-toggle-screen="any" data-toggle-overlay="true" data-toggle-body="true" data-simplebar="init">
                <div className="simplebar-wrapper" style={{ margin: "-24px" }}>
                  <div className="simplebar-height-auto-observer-wrapper">
                    <div className="simplebar-height-auto-observer"></div>
                  </div>
                  <div className="simplebar-mask">
                    <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                      <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{ height: "100%", overflow: "hidden scroll" }}>
                        <div className="simplebar-content" style={{ padding: "24px" }}>
                          <div className="nk-block-head">
                            <div className="nk-block-head-content">
                              <h5 className="nk-block-title">Add Location</h5>
                              <p style={{ 'color': 'red' }}>{locValidationError}</p>
                            </div>
                          </div>
                          <div className="nk-block">
                            <form onSubmit={editLocationFlag ? handleLocationUpdate : handleLocationSubmit}>
                              <div className="row g-3">
                                <div className="col-lg-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="locationName"
                                    >
                                      Location Name
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="text"
                                        name="locationName"
                                        className="form-control"
                                        id="locationName"
                                        value={allLocationData.locationName}
                                        onChange={handleLocationChange}
                                        autoComplete="off"
                                        placeholder="Location Name"
                                        style={{ 'border': allLocationData.locationName == '' ? '1px solid red' : '' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="contactPerson"
                                    >
                                      Contact Person
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="text"
                                        name="contactPerson"
                                        className="form-control"
                                        id="contactPerson"
                                        value={allLocationData.contactPerson}
                                        onChange={handleLocationChange}
                                        autoComplete="off"
                                        placeholder="Contact Person"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="contactNumber">
                                      Contact Number
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="number"
                                        name="contactNumber"
                                        className="form-control"
                                        id="contactNumber"
                                        value={allLocationData.contactNumber}
                                        onChange={handleLocationChange}
                                        autoComplete="off"
                                        onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                        placeholder="Contact Number"
                                        onInput={() => { document.getElementById('contactError').innerHTML = ""; }}
                                      />
                                    </div>
                                    <p className="text-danger" id="contactError"></p>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="contactEmail">
                                      Email ID
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="email"
                                        name="contactEmail"
                                        className="form-control"
                                        id="contactEmail"
                                        value={allLocationData.contactEmail}
                                        onChange={handleLocationChange}
                                        autoComplete="off"
                                        placeholder="Email ID"
                                      // style={{ 'border': contactEmailID ? '1px solid red' : '' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="address">
                                      Address
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="text"
                                        name="address"
                                        className="form-control"
                                        id="address"
                                        value={allLocationData.address}
                                        onChange={handleLocationChange}
                                        autoComplete="off"
                                        placeholder="Address"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="countryID">
                                      Country
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <select
                                          name="countryID"
                                          className="form-control"
                                          id="countryID"
                                          value={allLocationData.countryID}
                                          onChange={handleToCountry}
                                          autoComplete="off"
                                          style={{ 'border': allLocationData.countryID == 0 ? '1px solid red' : '' }}
                                        >
                                          <option value="" key="0">Select Country</option>
                                          {props.countries && props.countries.length > 0 && props.countries.map(country => (
                                            <option name="countryID" value={country.ID} key={country.ID}>{country.Name}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex my-2">
                                  <button type='submit' disabled={props.buttonLoading} className="btn btn-primary">{props.buttonLoading === true && <span
                                    className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                    <span>Save</span> </button>
                                  <button
                                    className="btn btn-secondary mx-2"
                                    onClick={handleLocationCancel}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="simplebar-placeholder"
                    style={{ width: "auto", height: "864px" }}
                  ></div>
                </div>
                <div
                  className="simplebar-track simplebar-horizontal"
                  style={{ visibility: "hidden" }}
                >
                  <div
                    className="simplebar-scrollbar"
                    style={{ width: "0px", display: "none" }}
                  ></div>
                </div>
                <div
                  className="simplebar-track simplebar-vertical"
                  style={{ visibility: "visible" }}
                >
                  <div
                    className="simplebar-scrollbar"
                    style={{
                      height: "606px",
                      display: "block",
                      transform: "translate3d(0px, 0px, 0px)",
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`tab-pane ${(activeTab == 'assettab') ? 'active' : ''}`} id="assettab">
          <div className="nk-block-head-content">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
              <h3 className="nk-block-title page-title">Asset</h3>
              {filterArray && filterArray.length > 0 && filterArray[0].includes("add_asset") && <>
                <div data-target="addProduct" className="toggle btn btn-primary d-none d-md-inline-flex" onClick={(handleAddAssetForm)}>
                  <em className="icon ni ni-plus"></em>
                  <span>Add Asset</span>
                </div>
              </>}
            </div>

            <div className='card p-2' style={{ marginTop: "12px" }}>
              <div className="nk-block">
                <form method="post" onSubmit={handleAssetSearchData}>
                  <div className="row g-3">

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="filter">Heartbeat</label>
                        <div className="form-control-wrap">
                          <select name="channelId" onChange={(e) => handleFilterChange(e)} className="form-control" id="filter">
                            <option value="0" selected>All</option>
                            <option value="1">Live</option>
                            <option value="2">Dead</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-3">
                      <div className="form-group">
                        <label className="form-label" htmlFor="filter">Status</label>
                        <div className="form-control-wrap">
                          <select name="status" onChange={(e) => handleStatusChange(e)} className="form-control" id="filter">
                            <option value="2" selected>All</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                      </div>
                    </div>


                    <div className='col-sm-3'>
                      <button type='submit' disabled={props.assetLoading} className="btn btn-primary" style={{ marginTop: '1.9rem' }}>
                        {props.assetLoading === true && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                        <span>Search</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            {
              dataSearch ? <div className='card p-2' style={{ marginTop: "12px" }}>
                {props.message &&
                  <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
                {props.apiError &&
                  <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}



                <div className="card-inner p-0" >
                  <div className='d-flex justify-content-between'>
                    <FilterComponent
                      onFilter={e => setFilterText(e.target.value)}
                      onClear={handleClear}
                      filterText={filterText}
                    />
                  </div>

                  <DataTable
                    pagination={false}
                    columns={assetColumns}
                    data={filterAssetData}
                    customStyles={customStyles}
                    // subHeaderComponent={subHeaderComponent}
                    progressPending={props.assetLoading}
                    progressComponent={<Spinner />}
                    // subHeader
                    striped
                    responsive
                  />
                </div>


                {addAssetForm && <div className="toggle-overlay" data-target="addAsset"></div>}
              </div> : ''
            }

            {addAssetForm && (
              <div className="nk-add-product toggle-slide toggle-slide-right toggle-screen-any content-active" data-content="addAsset" data-toggle-screen="any" data-toggle-overlay="true" data-toggle-body="true" data-simplebar="init">
                <div className="simplebar-wrapper" style={{ margin: "-24px" }}>
                  <div className="simplebar-height-auto-observer-wrapper">
                    <div className="simplebar-height-auto-observer"></div>
                  </div>
                  <div className="simplebar-mask">
                    <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                      <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{ height: "100%", overflow: "hidden scroll" }}>
                        <div className="simplebar-content" style={{ padding: "24px" }}>
                          <div className="nk-block-head">
                            <div className="nk-block-head-content">
                              <h5 className="nk-block-title">Add Asset</h5>
                              <p style={{ 'color': 'red' }}>{assetValidationError}</p>
                            </div>
                          </div>
                          <div className="nk-block">
                            <form onSubmit={editAssetFlag ? handleAssetUpdate : handleAssetSubmit}>
                              <div className="row g-3">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="assetName"
                                    >
                                      Asset Name
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="text"
                                        name="assetName"
                                        className="form-control"
                                        id="assetName"
                                        value={allAssetData.assetName}
                                        onChange={handleAssetChange}
                                        autoComplete="off"
                                        placeholder="Asset Name"
                                        style={{ 'border': allAssetData.assetName == '' ? '1px solid red' : '' }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="contactPerson"
                                    >
                                      Location
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <select
                                          name="locationID"
                                          className="form-control"
                                          id="locationID"
                                          value={allAssetData.locationID}
                                          onChange={handleAssetChange}
                                          autoComplete="off"
                                          style={{ 'border': allAssetData.locationID == 0 ? '1px solid red' : '' }}
                                        >
                                          <option value="" key="0">Select Location</option>
                                          {props.locationData && props.locationData.length > 0 && props.locationData.map(data => (
                                            <option name="locationID" value={data.ID} key={data.ID} >{data.LocationName}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="parentID">
                                      Channel
                                    </label>
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <select
                                          name="channelID"
                                          className="form-control"
                                          id="channelID"
                                          value={allAssetData.channelID}
                                          onChange={handleAssetChange}
                                          autoComplete="off"
                                        >
                                          <option value="0" key="0">Common</option>
                                          {props.channelData && props.channelData.length > 0 && props.channelData.map(data => (
                                            <option name="channelID" value={data.ID} key={data.ID} >{data.ChannelName}</option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="contactPerson"
                                    >
                                      Bulk Activation
                                    </label>
                                    <div className="form-control-wrap">
                                      <input type="checkbox" checked={allAssetData.bulkFlag} onChange={(e) => checkBulk(e)} id='bulkFlag' key='bulkFlag'></input>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="form-label" htmlFor="bulkQuantity">
                                      Bulk Quantity
                                    </label>
                                    <div className="form-control-wrap">
                                      <select className="form-control" onChange={handleAssetChange} name="bulkQuantity">
                                        <option disabled selected>Select</option>
                                        {
                                          [1, 2, 3, 4, 5].map((item, index) => <option key={`bulkQuantity${index}`} value={item}>{item}</option>)
                                        }
                                      </select>
                                      {/* <input
                                        type="number"
                                        name="bulkQuantity"
                                        className="form-control"
                                        id="bulkQuantity"
                                        value={allAssetData.bulkQuantity}
                                        onChange={handleAssetChange}
                                        autoComplete="off"
                                        onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                        placeholder="Bulk Quantity"
                                        onInput={() => { document.getElementById('bulkQtyError').innerHTML = ""; }}
                                      /> */}
                                    </div>
                                    <p className="text-danger" id="bulkQtyError"></p>
                                  </div>
                                </div>
                                <div className="d-flex my-2">
                                  <button type='submit' disabled={props.buttonLoading} className="btn btn-primary">{props.buttonLoading === true && <span
                                    className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                    <span>Save</span> </button>
                                  <button
                                    className="btn btn-secondary mx-2"
                                    onClick={handleAssetCancel}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="simplebar-placeholder" style={{ width: "auto", height: "864px" }}></div>
                </div>
                <div
                  className="simplebar-track simplebar-horizontal"
                  style={{ visibility: "hidden" }}
                >
                  <div
                    className="simplebar-scrollbar"
                    style={{ width: "0px", display: "none" }}
                  ></div>
                </div>
                <div className="simplebar-track simplebar-vertical" style={{ visibility: "visible" }} >
                  <div className="simplebar-scrollbar"
                    style={{
                      height: "606px",
                      display: "block",
                      transform: "translate3d(0px, 0px, 0px)",
                    }}
                  ></div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`tab-pane ${(activeTab == 'assetReporttab') ? 'active' : ''}`} id="assetReporttab">
          <div className="nk-block-head-content">
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
              <h3 className="nk-block-title page-title">Asset Report</h3>
            </div>
            <div className='card p-2' style={{ marginTop: "12px" }}>
              {props.message &&
                <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
              {props.apiError &&
                <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}


              <div className="card-inner p-0" >
                <DataTable
                  pagination
                  columns={assetReportColumns}
                  data={filterAssetReportData}
                  customStyles={customStyles}
                  // subHeaderComponent={subHeaderComponent}
                  progressPending={props.assetReportLoading}
                  progressComponent={<Spinner />}
                  // subHeader
                  striped
                  responsive
                />
              </div>
            </div>
          </div>
        </div>

        {showModal && <div className="modal fade show" tabIndex="-1" id="modalTop" style={{ "display": "block" }} aria-modal="true" role="dialog">
          <div className="modal-dialog modal-dialog-top modal-xl" role="document">
            <div className="modal-content" >
              <div className="modal-header">
                <h5 className="modal-title">Asset Report</h5>
                <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setshowModal(false)}><em className="icon ni ni-cross"></em></div>
              </div>
              <div className="modal-body">
                <DataTable
                  pagination
                  columns={modalColumns}
                  data={modalData}
                  customStyles={customStyles}
                  progressPending={props.modalLoading}
                  progressComponent={<Spinner />}
                  responsive
                  striped
                  expandableRows
                  expandableRowsComponent={renderExpandableRow}
                  expandableRowDisabled={row => row.multiLineFlag === 0}
                />
              </div>
            </div>
          </div>
        </div>}
      </div>

      {popUp && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-danger text-white">
              <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
            </div>
            <div className="modal-body">
              <p>{popUpDeleteContent}</p>
            </div>
            <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={() => handleChangeAssetRunningStatus()}>Yes</div></div>
          </div>
        </div>
      </div>}
    </>
  );
}
const mapStateToProps = (state) => {
  const { locationData, assetData, apiError, message, loading, userId, assetReportData, eachAssetReportData, modalLoading, assetLoading, locationLoading, assetReportLoading, buttonLoading } = state.asset;
  const { channelData, countries } = state.dealer;
  return { channelData, locationData, assetData, apiError, message, loading, userId, countries, assetReportData, eachAssetReportData, modalLoading, assetLoading, locationLoading, assetReportLoading, buttonLoading };
};
// export default Users;
export default withRouter(connect(mapStateToProps, { getAllChannelList, assetList, locationList, addAsset, resetAssetAlert, editAsset, deleteAsset, getCountryList, assetReport, assetReportByAssetID })(Asset));