import { PAUSED_ACTIVATION_LIST, PAUSED_ACTIVATION_LIST_SUCCESSFULL, PAUSED_ACTIVATION_LIST_FAILED } from "./actionTypes"

export const pausedActivationList = (companyId) => {
    return {
        type: PAUSED_ACTIVATION_LIST,
        payload: { companyId }
    }
}

export const pausedActivationListSuccess = (pausedActivation, message) => {
    return {
        type: PAUSED_ACTIVATION_LIST_SUCCESSFULL,
        payload: { pausedActivation, message }
    }
}

export const pausedActivationListFailed = (error) => {
    return {
        type: PAUSED_ACTIVATION_LIST_FAILED,
        payload: error
    }
}
