import {
    VALIDATE_REQUEST_FOR_BULK_CANCELLATION,
    VALIDATE_REQUEST_FOR_BULK_CANCELLATION_SUCCESS,
    VALIDATE_REQUEST_FOR_BULK_CANCELLATION_FAILED,
    FORM_RESET,
    UPDATE_REMARK_FOR_BULK_CANCELLATION,
    UPDATE_REMARK_FOR_BULK__CANCELLATION_SUCCESS,
    UPDATE_REMARK_FOR_BULK_CANCELLATION_FAILED,
    RESET,
    GET_ERROR_CODE_LIST,
    GET_ERROR_CODE_LIST_SUCCESS,
    GET_ERROR_CODE_LIST_FAILED
} from "./actionTypes";

const initialState = {
    bulkCancelationError: null,
    bulkCancelationMessage: null,
    loading: false,
    bulkCancelationResult: [],

    updateBulkCancelationError: null,
    updateBulkCancelationMessage: null,
    updateBulkCancelatiloading: false,

    errorCodeList: [],
    errorCodeLoading: false,
    errorCodeListError: null,
}

const bulkCancelation = (state = initialState, action) => {
    switch (action.type) {

        case VALIDATE_REQUEST_FOR_BULK_CANCELLATION:
            state = {
                ...state,
                bulkCancelationError: null,
                bulkCancelationMessage: null,
                updateBulkCancelationError: null,
                updateBulkCancelationMessage: null,
                loading: true,
                bulkCancelationResult: [],
            }
            break;

        case VALIDATE_REQUEST_FOR_BULK_CANCELLATION_SUCCESS:
            state = {
                ...state,
                bulkCancelationError: null,
                bulkCancelationMessage: null,
                loading: false,
                bulkCancelationResult: action.result,
            }
            break;

        case VALIDATE_REQUEST_FOR_BULK_CANCELLATION_FAILED:
            state = {
                ...state,
                bulkCancelationError: action.error,
                bulkCancelationMessage: null,
                loading: false,
                bulkCancelationResult: [],
            }
            break;

        case UPDATE_REMARK_FOR_BULK_CANCELLATION:
            state = {
                ...state,
                updateBulkCancelationError: null,
                updateBulkCancelationMessage: null,
                updateBulkCancelatiloading: true,
            }
            break;

        case UPDATE_REMARK_FOR_BULK__CANCELLATION_SUCCESS:
            state = {
                ...state,
                updateBulkCancelationError: null,
                updateBulkCancelationMessage: action.message,
                updateBulkCancelatiloading: false,
            }
            break;

        case UPDATE_REMARK_FOR_BULK_CANCELLATION_FAILED:
            state = {
                ...state,
                updateBulkCancelationError: action.error,
                updateBulkCancelationMessage: null,
                updateBulkCancelatiloading: false,
            }
            break;

        case GET_ERROR_CODE_LIST:
            state = {
                ...state,
                errorCodeList: [],
                errorCodeLoading: true,
                errorCodeListError: null,
            }
            break;

        case GET_ERROR_CODE_LIST_SUCCESS:
            state = {
                ...state,
                errorCodeLoading: false,
                errorCodeList: action.payload,
                errorCodeListError: null,
            }
            break;

            case GET_ERROR_CODE_LIST_FAILED:
            state = {
                ...state,
                errorCodeLoading: false,
                errorCodeList: action.error,
                errorCodeListError: null,
            }
            break;

        case FORM_RESET:
            state = {
                ...state,
                bulkCancelationError: null,
                bulkCancelationMessage: null,
                updateBulkCancelationError: null,
                updateBulkCancelationMessage: null,
                updateBulkCancelatiloading: false,
            }
            break;

        case RESET:
            state = {
                ...state,
                bulkCancelationResult: [],
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default bulkCancelation;