import {
    USER_LIST, USER_LIST_SUCCESSFUL, USER_API_ERROR, ADD_USER, ADD_USER_SUCCESS, API_ERROR_MODAL,
    EDIT_USER, DELETE_USER, DELETE_USER_SUCCESS, USER_PASSWORD_CHANGE_SUCCESSFULL, CHANGE_USER_PASSWORD, RESET_ALERT
} from "./actionTypes";

const initialState = {
    apiError: null, Message: null, loading: false, userData: [], modalMessage: null
}

const user = (state = initialState, action) => {
    switch (action.type) {
        case USER_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            }
            break;

        case USER_LIST_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                userData: action.payload,
                apiError: null,
                message: null
            }
            break;

        case USER_API_ERROR:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;
        case ADD_USER:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null
            };
            break;
        case ADD_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
                modalMessage: action.payload.Message,
                userData: action.payload.user,
            }
            break;
        case API_ERROR_MODAL:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;
        case DELETE_USER:
            state = {
                ...state,
                loading: true,
            }
            break;
        case DELETE_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
                Message: action.payload.Message,
                userData: action.payload.user,
            }
            break;
        case EDIT_USER:
            state = {
                ...state,
                loading: true,
                messageModal: null,
            }
            break;
        case CHANGE_USER_PASSWORD:
            state = {
                ...state,
                loading: true,
            }
            break;
        case USER_PASSWORD_CHANGE_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                modalMessage: action.payload.Message,
                userData: action.payload.user
            }
            break;
        case RESET_ALERT:
            state = {
                ...state,
                loading: false,
                Message: null,
                apiError: null,
                modalMessage: null,
                apiErrorModal: null
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default user;