import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from "moment";
import DataTable from 'react-data-table-component';
import { getAllChannelList } from '../store/dealerActivationReport/actions';
import { addPendingActivation, getPendingActivationOnPOS } from '../store/pendingActivation/actions';
import FilterComponent from "./FilterComponent";
import Spinner from '../components/layout/Spinner';
import "react-datepicker/dist/react-datepicker.css";

function PendingActivation(props) {
    const dispatch = useDispatch();
    const [filterText, setFilterText] = useState("");
    const [responseFilterText, setResponseFilterText] = useState("");
    const [channelId, setChannelId] = useState("");
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [popUp, setpopUp] = useState(false);

    useEffect(() => {
        const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
        props.getAllChannelList(dealerId);
    }, []);

    const columns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'CHANNEL TXN ',
            width: "150px",
            selector: row => row.TxnID,
        },
        {
            name: 'IMEI',
            width: "120px",
            selector: row => row.IMEI,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'PLAN',
            width: "245px",
            selector: row => row.PlanDesc
        },
        {
            name: 'SUBMITTED DATE',
            width: "145px",
            selector: row => row.PlanDtTm,
        },
        {
            name: 'CHANNEL DEALER NAME ',
            width: "200px",
            selector: row => row.ChannelDealerName,
        },
        {
            name: 'CHANNEL DEALER HIERARCHY',
            width: "220px",
            selector: row => row.ChannelDealerHierarchy,
        },        
        {
            name: 'TYPE',
            selector: row => row.RequestType,
        },
        {
            name: 'SIM',
            selector: row => row.SimType,
        },
        {
            name: 'MONTH',
            width: "80px",
            selector: row => row.Month,
        },
        {
            name: 'AMOUNT',
            width: "80px",
            selector: row => row.AmountCharged,
        },
    ];

    const responseColumns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'CHANNEL TXN ',
            width: "150px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'TRANSACTION#',
            width: "160px",
            selector: row => row.TransactionNumber,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'PIN',
            width: "90px",
            selector: row => row.PIN
        },
        {
            name: 'MESSAGE',
            width: "445px",
            selector: row => row.Message,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.pendingData && props.pendingData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const responseFilteredItems = props.pendingResponse && props.pendingResponse.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(responseFilterText.toLowerCase()) !== -1
    );

    // console.log(props.pendingData);
    var filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            serial: key + 1,
            TxnID: (<p>{data.TxnID}</p>),
            IMEI: (<p>{data.IMEI}</p>),
            SerialNumber: (<p style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>),
            PlanDesc: (<p style={{ "whiteSpace": "break-spaces" }}>{data.PlanDesc}</p>),
            PlanDtTm: (<p>{data.PlanDtTm}</p>),
            ChannelDealerName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.ChannelDealerName}</p>),
            ChannelDealerHierarchy:(<p style={{ "whiteSpace": "break-spaces" }}>{data.ChannelDealerHierarchy}</p>),
            RequestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 8 ? 'text-primary' : 'text-success'}`} >{(data.RequestType === 8) ? 'P': 'A'}</p>),
            SimType: (data.SimType === 25) ? (<p >P_SIM</p>) : (<p >E_SIM</p>),
            Month: (<p>{data.Month}</p>),
            AmountCharged: (<p>${data.AmountCharged}</p>),
        }
    })

    var responseFilterData = responseFilteredItems && responseFilteredItems.map((data, key) => {
        return {
            serial: key + 1,
            ChannelTxnID: (<p>{data.ChannelTxnID}</p>),
            TransactionNumber: (<p>{data.TransactionNumber}</p>),
            SerialNumber: (<p style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>),
            PIN: (<p style={{ "whiteSpace": "break-spaces" }}>{data.PIN}</p>),
            Message: (<p style={{ 'fontWeight': 'bold' }} className={`${data.Status === 0 ? 'text-danger' : 'text-success'}`}>{data.Message}</p>),
        }
    })

    const handleChannelChange = (e) => {
        setChannelId(e.target.value);
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        dispatch(getPendingActivationOnPOS(channelId));
        setFilterText("");
        setResponseFilterText("");
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
            setResponseFilterText("");            
        }
    };

    const handleConfirmPOSPending = () => {
        setpopUp(true);
    }

    const handleAddPOSPending = () => {
        setpopUp(false);
        dispatch(addPendingActivation({"activationRequests": props.pendingData}));
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    if(props.message){

    }

    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Pending Activation</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-3 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="channelID">Channel</label>
                                            <div className="form-control-wrap">
                                                <select
                                                    name="channelID"
                                                    className="form-control"
                                                    id="channelID"
                                                    required
                                                    onChange={(e) => handleChannelChange(e)}
                                                >
                                                    <option value="" key="0" name="select">Select</option>
                                                    {props.channelData && props.channelData.map(channel => (
                                                        <option key={channel.ID} value={channel.ChannelName}>{channel.ChannelName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2 col-md-1'>
                                    <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Search</span> </button>
                                        {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {props.apiError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}
                {props.pendingData && <div className="card p-2" style={{ marginTop: '12px' }}>
                    <div className="card-inner p-0" >
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <FilterComponent
                                onFilter={e => setFilterText(e.target.value)}
                                onClear={handleClear}
                                filterText={filterText}
                            />
                        </div>
                        <DataTable columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                        
                        {filterData.length > 0 && <div style={{ 'paddingTop': '10px', 'float': 'right'}}><Link to="#" style={{ 'cursor': 'pointer' }} onClick={handleConfirmPOSPending} className="btn btn-info mx-2">Add To Care</Link></div>}
                    </div>
                </div>}
                {popUp && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header bg-danger text-white">
                                <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
                            </div>
                            <div className="modal-body">
                                <p>Are you sure you want to add these transactions to care? Click Yes to continue.</p>
                            </div>
                            <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={handleAddPOSPending}>Yes</div></div>
                        </div>
                    </div>
                </div>}
                {props.pendingResponse && <div className="card p-2" style={{ marginTop: '12px' }}>
                    <div className="card-inner p-0" >
                        <div className='d-flex justify-content-between align-items-center mb-3'>
                            <FilterComponent
                                onFilter={e => setResponseFilterText(e.target.value)}
                                onClear={handleClear}
                                filterText={responseFilterText}
                            />
                        </div>
                        <DataTable columns={responseColumns} data={responseFilterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                    </div>
                </div>}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { channelData } = state.dealerActivationReport;
    const { pendingData, apiError, loading, pendingResponse } = state.pendingActivation
    return { channelData, pendingData, apiError, loading, pendingResponse };
}

export default withRouter(connect(mapStateToProps, { getAllChannelList, getPendingActivationOnPOS })(PendingActivation));