import { 
    GET_INSTANCES,
    GET_INSTANCES_SUCCESS,
    GET_INSTANCES_FAILED,
    INSTANCES_STOP,
    INSTANCES_STOP_SUCCESS,
    INSTANCES_STOP_FAILED,
    INSTANCES_START,
    INSTANCES_START_SUCCESS,
    INSTANCES_START_FAILED,
    INSTANCES_START_MODAL_OPEN,
    INSTANCES_START_MODAL_CLOSE,
    INSTANCES_STOP_MODAL_OPEN,
    INSTANCES_STOP_MODAL_CLOSE,
    INSTANCES_PASSWORD_MODAL_OPEN,
    INSTANCES_PASSWORD_MODAL_CLOSE,
    INSTANCES_PASSWORD_UPDATE,
    INSTANCES_PASSWORD_UPDATE_SUCCESS,
    INSTANCES_PASSWORD_UPDATE_FAILED,
    TOGGLE_INSTANCES_PASSWORD
} from "./actionTypes"

export const toggleInstancePassword = (instanceId) => {
    return {
        type: TOGGLE_INSTANCES_PASSWORD,
        payload: {instanceId} 
    }
}

export const instancePasswordUpdate = (instanceId,data) => {
    return {
        type: INSTANCES_PASSWORD_UPDATE,
        payload: {instanceId, data} 
    }
}

export const instancePasswordUpdateSuccessful = (data) => {
    return {
        type: INSTANCES_PASSWORD_UPDATE_SUCCESS,
        payload: data
    }
}

export const instancePasswordUpdateFailed = (error) => {
    return {
        type: INSTANCES_PASSWORD_UPDATE_FAILED,
        payload: error
    }
}

export const instancePasswordModalClose = () => {
    return {
        type: INSTANCES_PASSWORD_MODAL_CLOSE,
    }
}

export const instancePasswordModalOpen = () => {
    return {
        type: INSTANCES_PASSWORD_MODAL_OPEN,
    }
}

export const instanceStopModalClose = (data) => {
    return {
        type: INSTANCES_STOP_MODAL_CLOSE,
    }
}

export const instanceStopModalOpen = (data) => {
    return {
        type: INSTANCES_STOP_MODAL_OPEN,
    }
}

export const instanceStartModalClose = (data) => {
    return {
        type: INSTANCES_START_MODAL_CLOSE,
    }
}

export const instanceStartModalOpen = (data) => {
    return {
        type: INSTANCES_START_MODAL_OPEN,
    }
}

export const instanceStart = (data) => {
    return {
        type: INSTANCES_START,
        payload: data 
    }
}

export const instanceStartSuccessful = (data) => {
    return {
        type: INSTANCES_START_SUCCESS,
        payload: data
    }
}

export const instanceStartFailed = (error) => {
    return {
        type: INSTANCES_START_FAILED,
        payload: error
    }
}

export const instanceStop = (data) => {
    return {
        type: INSTANCES_STOP,
        payload: data 
    }
}

export const instanceStopSuccessful = (data) => {
    return {
        type: INSTANCES_STOP_SUCCESS,
        payload: data
    }
}

export const instanceStopFailed = (error) => {
    return {
        type: INSTANCES_STOP_FAILED,
        payload: error
    }
}

export const getInstances = () => {
    return {
        type: GET_INSTANCES,
    }
}

export const getInstancesSuccessful = (data) => {
    return {
        type: GET_INSTANCES_SUCCESS,
        payload: data
    }
}

export const getInstancesFailed = (error) => {
    return {
        type: GET_INSTANCES_FAILED,
        payload: error
    }
}


