import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
// import momentTimezone from 'moment-timezone';
import moment from "moment/moment";
import FilterComponent from "./FilterComponent";
import Spinner from "../components/layout/Spinner";
import { PERMISSIONS } from '../Permission/Permission';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getAllChannelList, dealerList, addDealer, getCountryList, stateListing, resetDealerAlert, editDealer, deleteDealer, getChannelName, dealerFlagUpdate, getDealerSuspensionLog, closeDealerSuspensionLog, getDealerConfig, closeDealerConfigModal, setDealerConfigData, updateDealerConfig, getOthersAllowedDuration, closeEmailConfigModal, openEmailConfigModal, updateDealeremail } from "../store/dealer/actions";
import { decrypt } from '../helpers/encrypt_decrypt_helper';

function Dealer(props) {
  const timezone = process.env.REACT_APP_TIMEZONE;
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const dispatch = useDispatch();
  const [allDealerData, setAllDealerData] = useState({
    dealerName: "",
    parentID: "",
    companyID: "",
    isActive: 1,
    createdBy: "",
    contactPerson: "",
    contactNumber: "",
    contactEmailID: "",
    address: "",
    countryID: "",
    zipCode: "",
    stateID: "",
    city: "",
    tmobileUsername: "",
    tmobilePassword: "",
    epayUsername: "",
    epayPassword: "",
    isVerified: 1,
  });
  const [deleteDealerData, setDeleteDealerData] = useState({
    modifiedByLoginID: null,
    modifiedDtTm: ''
  })
  const [addSideForm, setAdddSideForm] = useState(false);
  const [configAcode, setconfigAcode] = useState("");
  const [configChannelName, setconfigChannelName] = useState("");
  const [countAllowedError, setcountAllowedError] = useState(false);
  const [countDurationError, setcountDurationError] = useState(false);
  const [countBulkAllowedError, setcountBulkAllowedError] = useState(false);
  const [countBulkDurationError, setcountBulkDurationError] = useState(false);
  const [RoleID, setRoleID] = useState(null);
  const [CompanyId, setCompanyId] = useState("");
  const [dealerSuspensionTitle, setdealerSuspensionTitle] = useState("");
  const [suspensionRemark, setsuspensionRemark] = useState("");
  const [suspensionRemarkError, setsuspensionRemarkError] = useState(null);
  const [DealerId, setDealerId] = useState(0);
  const [modalHeading, setModalHeading] = useState('New Dealer');
  const [modalPara, setModalPara] = useState('Add information about new Dealer.');
  const [editFlag, setEditFlag] = useState(false);
  const [editId, setEditId] = useState();
  const [LoginId, setLoginID] = useState('');
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [dealerName, setdealerName] = useState(false);
  const [contactEmailID, setcontactEmailID] = useState(false);
  const [countryID, setcountryID] = useState(false);
  const [tmobileUsername, settmobileUsername] = useState(false);
  const [tmobilePassword, settmobilePassword] = useState(false);
  const [epayUsername, setepayUsername] = useState(false);
  const [epayPassword, setepayPassword] = useState(false);
  const [parentID, seteparentID] = useState(false);
  const [dealerStatus, setDealerStatus] = useState(null)
  const [popUp, setpopUp] = useState(false);
  const [popUpDeleteContent, setPopUpDeleteContent] = useState('');
  const [passTMobileIconClass, setPassTMobileIconClass] = useState('icon ni ni-eye-off');
  const [passEMobileIconClass, setPassEMobileIconClass] = useState('icon ni ni-eye-off');
  const [passwordTMobileType, setPasswordTMobileType] = useState('password');
  const [passwordEMobileType, setPasswordEMobileType] = useState('password');
  const [showModal, setshowModal] = useState(false);
  const [dealerSuspensionConfirmation, setDealerSuspensionConfirmation] = useState(false);
  const [rowDealerId, setRowDealerId] = useState(0);
  const [rowFlag, setRowFlag] = useState('');
  const [rowSuspension, setRowSuspension] = useState(0);
  const [dataSearch, setDataSearch] = useState(false);
  const [updateDealerID, setUpdateDealerID] = useState('')
  const [emailData, setEmailData] = useState('')

  const [searchDealer, setSearchDealer] = useState({
    companyID: '',
    channelId: 0,
    status: 0,
  });
  useEffect(() => {
    const loginId = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).ID
      : null;
    const companyId = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).CompanyID
      : null;
    const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
    const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
    setRoleID(roleId);
    setCompanyId(companyId);
    setLoginID(loginId);
    setDealerId(dealerId);
    setAllDealerData((prevState) => ({
      ...prevState,
      companyID: companyId,
      createdBy: loginId,
    }));
    setSearchDealer((prevState) => ({
      ...prevState,
      companyID: companyId,
    }));
    setEditFlag(false);

    setDeleteDealerData(prevState => ({
      ...prevState,
      modifiedByLoginID: loginId,
      modifiedDtTm: moment(new Date()).tz(process.env.REACT_APP_TIMEZONE).format()
    }));
    props.getAllChannelList(dealerId);
    props.getOthersAllowedDuration();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetDealerAlert())
    }, 3000)
  }, [props.emailConfigMessage])

  var filterArray = [];
  for (const [key, value] of Object.entries(PERMISSIONS)) {
    if (key == RoleID) {
      filterArray.push(value);
    }
  }

  const columns = [
    {
      name: "#",
      width: "30px",
      selector: row => row.serial,
    },
    {
      name: "DEALER NAME",
      selector: row => row.dealerName,
    },
    {
      name: "ACode",
      selector: row => row.ACode,
    },
    {
      name: "CHANNEL",
      selector: row => row.ChannelName,
    },
    {
      name: "TMO USERNAME",
      selector: row => row.tmobileUsername,
    },
    // {
    //   name: "TMO PASSWORD",
    //   selector: row => row.tmobilePassword,
    // },
    {
      name: "EPAY USERNAME",
      selector: row => row.epayUsername,
    },
    // {
    //   name: "EPAY PASSWORD",
    //   selector: row => row.epayPassword,
    // },
    {
      name: "CREATED DATE",
      selector: row => row.createdDate,
    },
    {
      name: "VERIFIED DATE",
      selector: row => row.verifiedDate,
    },
    // {
    //   name: "DOMAIN",
    //   selector: row => row.EmailDomain,
    // },
    // {
    //   name: "STATUS",
    //   selector: row => row.status,
    // },
    filterArray && filterArray.length > 0 && filterArray[0].includes("suspend_dealer") && {
      name: "SUSPENDED",
      selector: row => row.suspense,
    },
    // {
    //   name: "INUSE",
    //   selector: row => row.inuse,
    // },
    {
      name: "ACTION",
      width: "150px",
      selector: row => row.action,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '0px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '0px',
      },
    },
  };

  const handleSuspenseInuseOnOff = (dealerId, typeFlag, flag, data) => {
    dispatch(dealerFlagUpdate(flag, dealerId, typeFlag, CompanyId, LoginId, data));
  }

  const filteredItems = props.dealerData && props.dealerData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1

  );

  const showDealerConfirmation = (dealerId, flag, suspension) => {
    let filterRow = props.dealerData.filter((item) => item.ID === dealerId);
    setdealerSuspensionTitle(filterRow[0].DealerName);
    setconfigAcode(filterRow[0].ACode);
    setconfigChannelName(filterRow[0].ChannelName);
    setsuspensionRemarkError(null);
    setsuspensionRemark('');
    setRowDealerId(dealerId);
    setRowFlag(flag);
    setRowSuspension(suspension);
    setDealerSuspensionConfirmation(true);
  }

  const updateDealerSuspension = () => {
    if (suspensionRemark == '') {
      setsuspensionRemarkError('Remark Required');
      return;
    } else {
      setsuspensionRemarkError(null);
    }
    let data = {
      remark: suspensionRemark.trim()
    }
    handleSuspenseInuseOnOff(rowDealerId, rowFlag, rowSuspension, data);
    setDealerSuspensionConfirmation(false);
  }

  const showDealerSuspensionLog = (dealerId) => {
    let filterRow = props.dealerData.filter((item) => item.ID === dealerId);
    setdealerSuspensionTitle(filterRow[0].DealerName);
    setconfigAcode(filterRow[0].ACode);
    setconfigChannelName(filterRow[0].ChannelName);
    dispatch(getDealerSuspensionLog(dealerId));
  }

  const showDealerConfig = (dealerId) => {
    let filterRow = props.dealerData.filter((item) => item.ID === dealerId);
    setdealerSuspensionTitle(filterRow[0].DealerName);
    setconfigAcode(filterRow[0].ACode);
    setconfigChannelName(filterRow[0].ChannelName);
    setDealerId(dealerId);
    dispatch(getDealerConfig(dealerId));
  }

  const filterData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      dealerName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.DealerName}</p>),
      // contactEmailID: (<a href={`mailto:${data.ContactEmailID}`} style={{"whiteSpace": "break-spaces"}}>{data.ContactEmailID}</a>),
      ChannelName: (<p>{data.ChannelName}</p>),
      ACode: (<p>{(data.ACode == null || data.ACode == '') ? '' : data.ACode}</p>),
      tmobileUsername: (<p>{data.TMobileUsername}</p>),
      tmobilePassword: (<p>{decrypt(data.TMobilePassword)}</p>),
      epayUsername: (<p>{data.EPayUsername}</p>),
      EmailDomain: (<p>{data.EmailDomain}</p>),
      epayPassword: (<p>{decrypt(data.EPayPassword)}</p>),
      createdDate: (<p>{data.CreatedDtTm !== null ? (moment(new Date(data.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")) : ''}</p>),
      verifiedDate: (<p>{data.VerifiedDate !== null ? (moment(new Date(data.VerifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")) : ''}</p>),
      // status: (data.IsActive === 1 ? <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">Active</span> : <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">Inactive</span>),
      suspense: (<p style={{ 'fontWeight': 'bold' }} className={`${data.SuspenseFlag === 1 ? 'text-danger' : 'text-success'}`}>{data.SuspenseFlag === 1 ? 'Yes' : 'No'}</p>),

      // <input type="checkbox" defaultChecked={data.SuspenseFlag} onClick={(e) => { handleSuspenseInuseOnOff(e, data.ID, 'S'); }} />

      // <span className="slider round suspenseInuse"></span>


      // (data.SuspenseFlag === 1 ? <span className="text-danger">Yes</span> : <span className="text-success">No</span>),
      // inuse: <label className="switch">
      //           <input type="checkbox" defaultChecked={data.InUse} onClick={(e) => { handleSuspenseInuseOnOff(e, data.ID, 'I'); }} />
      //           {/* checked={jarFlag ? true : false} */}
      //           <span className="slider round suspenseInuse"></span>
      //       </label>, // (data.InUse === 1 ? <span className="text-danger">Yes</span> : <span className="text-success">No</span>),

      /*handleSuspenseInuseOnOff(data.ID, 'S', 1)*/
      action: (<>
        <span>
          {data.SuspenseFlag === 1 ?
            <em title="Unsuspend" className="icon icon-circle-dealer  ni ni-unlock-fill bg-success" style={{ 'cursor': 'pointer', marginLeft: '2px' }} onClick={() => showDealerConfirmation(data.ID, 'S', 0)}></em> :
            <em title="Suspend" className="icon icon-circle-dealer  icon ni ni-lock-fill bg-danger" style={{ 'cursor': 'pointer', margin: '2px' }} onClick={() => showDealerConfirmation(data.ID, 'S', 1)}></em>
          }
          {
            (filterArray && filterArray.length > 0 && filterArray[0].includes("dealer_config")) ?
              data.configLoading ? <em title="Dealer Configuration" className="icon icon-circle-dealer ni ni-eye bg-warning lo-be button-loader-spinner" style={{ 'cursor': 'pointer' }}>
                <div id="loading-bar-spinner-button" class="spinner-button"><div class="spinner-icon"></div></div>
              </em> : <em title="Dealer Configuration" className="icon icon-circle-dealer ni ni-setting-fill bg-warning" style={{ 'cursor': 'pointer', margin: '2px' }} onClick={() => showDealerConfig(data.ID)}></em> : ''
          }
          {
            Boolean(data.loading) ?
              <em title="Suspension Log" className="icon icon-circle-dealer ni ni-eye bg-primary lo-be button-loader-spinner" style={{ 'cursor': 'pointer', margin: '2px' }}>
                <div id="loading-bar-spinner-button" class="spinner-button"><div class="spinner-icon"></div></div>
              </em> :
              <em title="Suspension Log" className="icon icon-circle-dealer ni ni-eye bg-primary" style={{ 'cursor': 'pointer', margin: '2px' }} onClick={() => showDealerSuspensionLog(data.ID)}></em>
          }
          {/* {LoginId === 2 &&
            <em title="Email Config" className="icon icon-circle-dealer ni ni-mail bg-info link" style={{ 'cursor': 'pointer', margin: '2px' }} onClick={() => showEmailConfig(data.ID, data.EmailDomain)}></em>
          } */}

        </span>
      </>)
    }
  })

  const listData = props.dealerData && props.dealerData.map((data, key) => {
    return {
      SNo: key + 1,
      DEALERNAME: data.DealerName,
      CHANNELNAME: data.ChannelName,
      TMOBILEUSERNAME: data.TMobileUsername,
      TMOBILEPASSWORD: decrypt(data.TMobilePassword),
      EPAYUSERNAME: data.EPayUsername,
      EPAYPASSWORD: decrypt(data.EPayPassword),
      EmailDomain: data.EmailDomain,
      CREATEDDATE: data.CreatedDtTm !== null ? (moment(new Date(data.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")) : '',
      VERIFIEDDATE: data.VerifiedDate !== null ? (moment(new Date(data.VerifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")) : '',
      STATUS: data.SuspenseFlag === 0 ? 'Active' : 'Suspended',
    }
  })

  const handleChange = (e) => {
    const fname = e.target.name;
    const fvalue = e.target.value;

    setAllDealerData((prevState) => ({
      ...prevState,
      [fname]: fvalue,
    }));
  };

  const handleAddForm = () => {
    dispatch(getCountryList());
    dispatch(getChannelName(DealerId));
    setAdddSideForm(true);
    setModalHeading('New Dealer');
    setEditFlag(false);
    dispatch(resetDealerAlert())
    setAllDealerData(prevState => ({
      ...prevState,
      dealerName: "",
      parentID: "",
      contactPerson: "",
      contactNumber: "",
      contactEmailID: "",
      address: "",
      countryID: "",
      zipCode: "",
      stateID: "",
      city: "",
      tmobileUsername: "",
      tmobilePassword: "",
      epayUsername: "",
      epayPassword: ""
    }));
  }

  // const handleEditForm = (dealerId) => {
  //   dispatch(getCountryList());
  //   dispatch(getChannelName());
  //   dispatch(resetDealerAlert())
  //   setAdddSideForm(true);
  //   setModalHeading('Edit Dealer')
  //   setModalPara('Edit information about the Dealer.');
  //   setEditFlag(true);
  //   setEditId(dealerId);
  //   var selectedDealer = props.dealerData.filter(dealer => dealer.ID === dealerId)
  //   dispatch(stateListing(selectedDealer[0].CountryID))
  //   setAllDealerData(prevState => ({
  //     ...prevState,
  //     dealerName: selectedDealer[0].DealerName,
  //     contactPerson: selectedDealer[0].ContactPerson,
  //     contactNumber: selectedDealer[0].ContactNumber,
  //     contactEmailID: selectedDealer[0].ContactEmailID,
  //     address: selectedDealer[0].Address,
  //     countryID: selectedDealer[0].CountryID,
  //     parentID: selectedDealer[0].ParentID,
  //     zipCode: selectedDealer[0].ZipCode,
  //     stateID: selectedDealer[0].State,
  //     city: selectedDealer[0].City,
  //     tmobileUsername: selectedDealer[0].TMobileUsername,
  //     tmobilePassword: selectedDealer[0].TMobilePassword,
  //     epayUsername: selectedDealer[0].EPayUsername,
  //     epayPassword: selectedDealer[0].EPayPassword
  //   }));
  // }

  const handleDealerChange = (e) => {
    const fname = e.target.name;
    const fvalue = e.target.value;
    setSearchDealer(prevState => ({
      ...prevState,
      [fname]: fvalue,
    }));
  }

  const handleSearchData = (e) => {
    e.preventDefault();
    setDataSearch(true);
    dispatch(dealerList(searchDealer.companyID, searchDealer.channelId, searchDealer.status));
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    setdealerName(false);
    setcontactEmailID(false);
    setcountryID(false);
    settmobileUsername(false);
    settmobilePassword(false);
    setepayUsername(false);
    setepayPassword(false);
    seteparentID(false);

    var checkFlag = false;

    if (allDealerData.parentID === '') {
      seteparentID(true);
      checkFlag = true;
    }

    if (allDealerData.dealerName === '') {
      setdealerName(true);
      checkFlag = true;
    }

    if (allDealerData.countryID === '') {
      setcountryID(true);
      checkFlag = true;
    }

    if (allDealerData.tmobileUsername === '') {
      settmobileUsername(true);
      checkFlag = true;
    }

    if (allDealerData.tmobilePassword === '') {
      settmobilePassword(true);
      checkFlag = true;
    }

    if (allDealerData.epayUsername === '') {
      setepayUsername(true);
      checkFlag = true;
    }

    if (allDealerData.epayPassword === '') {
      setepayPassword(true);
      checkFlag = true;
    }

    if (allDealerData.contactNumber !== '' && allDealerData.contactNumber.length !== 10) {
      document.getElementById('contactError').innerHTML = "Contact number must be 10 digit !";
      checkFlag = true;
    }
    if (allDealerData.zipCode !== '' && (allDealerData.zipCode.length < 5 || allDealerData.zipCode.length > 6)) {
      document.getElementById('zipError').innerHTML = "Zip code must be between 5 to 6 digit ! ";
      checkFlag = true;
    }

    if (!checkFlag) {
      dispatch(addDealer(allDealerData, CompanyId));
    }
    else {
      return false;
    }
  };

  const handleCancel = (e) => {
    setAdddSideForm(false);
    setdealerName(false);
    setcontactEmailID(false);
    setcountryID(false);
    settmobileUsername(false);
    settmobilePassword(false);
    setepayUsername(false);
    setepayPassword(false);
    seteparentID(false);
  }

  const handleUpdate = (e) => {
    e.preventDefault();
    setdealerName(false);
    setcontactEmailID(false);
    setcountryID(false);
    settmobileUsername(false);
    settmobilePassword(false);
    setepayUsername(false);
    setepayPassword(false);
    seteparentID(false);

    var checkFlag = false;
    if (allDealerData.parentID === '') {
      seteparentID(true);
      checkFlag = true;
    }

    if (allDealerData.dealerName === '') {
      setdealerName(true);
      checkFlag = true;
    }

    if (allDealerData.countryID === '' || Number(allDealerData.countryID) === 0) {
      setcountryID(true);
      checkFlag = true;
    }

    if (allDealerData.tmobileUsername === '') {
      settmobileUsername(true);
      checkFlag = true;
    }

    if (allDealerData.tmobilePassword === '') {
      settmobilePassword(true);
      checkFlag = true;
    }

    if (allDealerData.epayUsername === '') {
      setepayUsername(true);
      checkFlag = true;
    }

    if (allDealerData.epayPassword === '') {
      setepayPassword(true);
      checkFlag = true;
    }

    if (allDealerData.contactNumber !== '' && allDealerData.contactNumber.length !== 10) {
      document.getElementById('contactError').innerHTML = "Contact number must be 10 digit !";
      checkFlag = true;
    }
    if (allDealerData.zipCode !== '' && (allDealerData.zipCode.length < 5 || allDealerData.zipCode.length > 6)) {
      document.getElementById('zipError').innerHTML = "Zip code must be between 5 to 6 digit ! ";
      checkFlag = true;
    }

    if (!checkFlag) {
      allDealerData.modifiedByLoginID = LoginId;
      allDealerData.modifiedDtTm = moment(new Date()).tz(timezone).format()
      dispatch(editDealer(allDealerData, editId, CompanyId));
    }
    else {
      return false;
    }
  }

  // const handleOpenPopUpAlertInActive = (dealerId, dealerStatus) => {
  //   dispatch(resetDealerAlert());
  //   setpopUp(true);
  //   setPopUpDeleteContent('Are you sure, you want to Inactive the dealer ?')
  //   setEditId(dealerId);
  //   setDealerStatus(dealerStatus);
  // }

  // const handleOpenPopUpAlertActive = (dealerId, dealerStatus) => {
  //   dispatch(resetDealerAlert());
  //   setpopUp(true);
  //   setPopUpDeleteContent('Are you sure, you want to Active the dealer ?')
  //   setEditId(dealerId);
  //   setDealerStatus(dealerStatus);
  // }

  const handleStatusChange = () => {
    dispatch(deleteDealer(editId, dealerStatus, deleteDealerData, CompanyId));
    setpopUp(false);
  }

  const handleToCountry = (e) => {
    const countryId = Number(e.target.value);
    setAllDealerData(prevState => ({
      ...prevState,
      countryID: countryId
    }));
    dispatch(stateListing(countryId))
  }

  const handleTostate = (e) => {
    const stateId = Number(e.target.value);
    setAllDealerData(prevState => ({
      ...prevState,
      stateID: stateId
    }));
  }

  const handleChangeTypeTMobilePassword = () => {
    passwordTMobileType === 'password' ? setPasswordTMobileType('text') : setPasswordTMobileType('password');
    passwordTMobileType === 'password' ? setPassTMobileIconClass('icon ni ni-eye') : setPassTMobileIconClass('icon ni ni-eye-off');
  }

  const handleChangeTypeEMobilePassword = () => {
    passwordEMobileType === 'password' ? setPasswordEMobileType('text') : setPasswordEMobileType('password');
    passwordEMobileType === 'password' ? setPassEMobileIconClass('icon ni ni-eye') : setPassEMobileIconClass('icon ni ni-eye-off');
  }

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  }

  const exportToCSV = async () => {
    if (listData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(listData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      await FileSaver.saveAs(data, 'Dealer' + fileExtension);
    }
  }

  if (props.modalMessage) {
    setTimeout(() => {
      setAdddSideForm(false);
    }, 1500);
  }

  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }

  const handleConfigCheckBoxChange = (key) => {
    let updatedConfig = new Object();
    if (key.target.id === 'OthersAllowed') {
      updatedConfig = {
        ...props.dealerConfig[0],
        [key.target.id]: Number(key.target.checked),
        OthersAllowedCount: 0,
        OthersAllowedDuration: 0
      }
    } else if (key.target.id === 'SelfActivation') {
      updatedConfig = {
        ...props.dealerConfig[0],
        [key.target.id]: Number(key.target.checked),
        OthersAllowedCount: 0,
        OthersAllowedDuration: 0,
        OthersAllowed: 0
      }

    } else if (key.target.id === 'SelfBulkActivation') {
      updatedConfig = {
        ...props.dealerConfig[0],
        [key.target.id]: Number(key.target.checked),
        // OthersBulkAllowed : 0,
        // OthersBulkCount : 0,
        // OthersBulkDuration : 0
      }

    } else if (key.target.id === 'OthersBulkAllowed') {
      updatedConfig = {
        ...props.dealerConfig[0],
        [key.target.id]: Number(key.target.checked),
        OthersBulkCount: 0,
        OthersBulkDuration: 0
      }

    } else {
      updatedConfig = props.dealerConfig[0];
    }

    dispatch(setDealerConfigData([updatedConfig]));
  }

  const handleConfigInputChange = (key) => {
    let updatedConfig = {
      ...props.dealerConfig[0],
      [key.target.id]: Number(key.target.value)
    }
    dispatch(setDealerConfigData([updatedConfig]));
  }

  const handleUpdateDealerConfig = () => {

    if (props.dealerConfig.length > 0) {
      let error = false;
      setcountAllowedError(false);
      setcountDurationError(false);
      setcountBulkAllowedError(false);
      setcountBulkDurationError(false);

      if (props.dealerConfig[0].OthersAllowed && (props.dealerConfig[0].OthersAllowedCount === 0 || !props.dealerConfig[0].OthersAllowedCount)) {
        setcountAllowedError(true);
        error = true;
      }

      if (props.dealerConfig[0].OthersAllowed && (props.dealerConfig[0].OthersAllowedDuration === null || !props.dealerConfig[0].OthersAllowedDuration)) {
        setcountDurationError(true);
        error = true;
      }

      // if(props.dealerConfig[0].OthersBulkAllowed && (props.dealerConfig[0].OthersBulkCount === 0 || !props.dealerConfig[0].OthersBulkCount)){
      //   setcountBulkAllowedError(true);
      //   error = true;
      // }

      // if(props.dealerConfig[0].OthersBulkAllowed && (props.dealerConfig[0].OthersBulkDuration === 0 || !props.dealerConfig[0].OthersBulkDuration)){
      //   setcountBulkDurationError(true);
      //   error = true;
      // }

      if (!error) {
        dispatch(updateDealerConfig(DealerId, LoginId, props.dealerConfig[0]));
      }
    } else {
      dispatch(updateDealerConfig(DealerId, LoginId, props.dealerConfig[0]));
    }

  }

  const showEmailConfig = (id, email) => {
    dispatch(openEmailConfigModal())
    setUpdateDealerID(id)
    setEmailData(email)
  }


  const handleSubmitModal = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    dispatch(updateDealeremail({ EmailDomain: formDataObject.domain }, updateDealerID, CompanyId));
  };



  // const handleInput = (event) => {
  //   const value = event.target.value;
  //   const domainPattern = /^[a-zA-Z0-9.-]*$/;
  //   const fullDomainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  //   if (!domainPattern.test(value)) {
  //     event.target.value = value.replace(/[^a-zA-Z0-9.-]/g, '');
  //     return;
  //   }
  //   if (fullDomainPattern.test(value)) {
  //     event.target.setCustomValidity("");
  //   } else {
  //     event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");

  //   }

  //   const domainParts = value.split('.');
  //   if (domainParts.length > 1) {
  //     const tld = domainParts[domainParts.length - 1];
  //     const secondLevelDomain = domainParts[domainParts.length - 2];

  //     if (tld.length > 6 || (tld.length > 0 && tld.length < 2)) {
  //       event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");
  //     }
  //     if (secondLevelDomain.length === 0 || value.endsWith('.')) {
  //       event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");
  //     }
  //   } else {
  //     event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");
  //   }

  //   event.target.value = value.replace(/[^a-zA-Z0-9.-]/g, '');
  //   if (/\.{2,}/.test(value) || value.startsWith('.') || value.endsWith('.')) {
  //     event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");

  //   }
  // };

  return (
    <div className="nk-block-head-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="nk-block-title page-title">Dealer</h3>
        {filterArray && filterArray.length > 0 && filterArray[0].includes("add_dealer") && <>
          <div
            data-target="addProduct"
            className="toggle btn btn-icon btn-primary d-md-none"
            onClick={(handleAddForm)}
          >
            <em className="icon ni ni-plus"></em>
          </div>
          <div
            data-target="addProduct"
            className="toggle btn btn-primary d-none d-md-inline-flex"
            onClick={(handleAddForm)}
          >
            <em className="icon ni ni-plus"></em>
            <span>Add Dealer</span>
          </div>
        </>}
      </div>
      <div className="card p-2" style={{ marginTop: "12px" }}>
        {props.dealerConfigMessage &&
          <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.dealerConfigMessage}</strong></div>}
        {props.dealerConfigError &&
          <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.dealerConfigError}</strong></div>}
        {props.message &&
          <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
        {props.apiError &&
          <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}
        {props.emailConfigMessage && <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.emailConfigMessage}</strong></div>}
        <div className="card-inner p-0">
          <div className="nk-block">
            <form method="post" onSubmit={handleSearchData}>
              <div className="row g-3">
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="dealerID">Channel</label>
                    <div className="form-control-wrap">
                      <select
                        name="channelId"
                        className="form-control"
                        id="channelId"
                        onChange={(e) => handleDealerChange(e)}
                      >
                        <option value="0" name="all">All</option>
                        {props.channelData && props.channelData.map(channel => (
                          <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group"><label className="form-label" htmlFor="stareDate">Status</label>
                    <div className="form-control-wrap">
                      <select
                        name="status"
                        className="form-control"
                        id="status"
                        onChange={(e) => handleDealerChange(e)}
                      >
                        <option value="0" name="all">All</option>
                        <option value="1" name="all">Suspended</option>
                        {/* <option value="2" name="all">InUse</option> */}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                    className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                    <span>Search</span> </button>
                  {/* <button className='btn btn-primary ' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        dataSearch ?
          <div className='card p-2'>
            <div className="card-inner p-0" >
              <div className='d-flex justify-content-between'>
                <FilterComponent
                  onFilter={e => setFilterText(e.target.value)}
                  onClear={handleClear}
                  filterText={filterText}
                /><Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
              </div>
              <DataTable
                pagination
                columns={columns}
                data={filterData}
                customStyles={customStyles}
                // subHeaderComponent={subHeaderComponent}
                progressPending={props.loading}
                progressComponent={<Spinner />}
                // subHeader
                striped
                responsive
              />
            </div>
            {addSideForm && <div className="toggle-overlay" data-target="addProduct"></div>}
          </div>
          : ''}

      {addSideForm && (
        <div
          className="nk-add-productdealer toggle-slide toggle-slide-right toggle-screen-any content-active"
          data-content="addProduct"
          data-toggle-screen="any"
          data-toggle-overlay="true"
          data-toggle-body="true"
          data-simplebar="init"
        >
          <div className="simplebar-wrapper" style={{ margin: "-24px" }}>
            <div className="simplebar-height-auto-observer-wrapper">
              <div className="simplebar-height-auto-observer"></div>
            </div>
            <div className="simplebar-mask">
              <div
                className="simplebar-offset"
                style={{ right: "0px", bottom: "0px" }}
              >
                <div
                  className="simplebar-content-wrapper"
                  tabIndex="0"
                  role="region"
                  aria-label="scrollable content"
                  style={{ height: "100%", overflow: "hidden scroll" }}
                >
                  <div
                    className="simplebar-content"
                    style={{ padding: "24px" }}
                  >
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h5 className="nk-block-title">{modalHeading}</h5>
                        <div className="nk-block-des">
                          <p>{modalPara}</p>
                          {props.modalMessage && (
                            <div className="example-alert">
                              <div className="alert alert-success alert-icon">
                                <em className="icon ni ni-check-circle"></em>{" "}
                                <strong>{props.modalMessage}</strong>
                              </div>
                            </div>
                          )}
                          {props.apiError && (
                            <div className="alert alert-danger alert-icon">
                              <em className="icon ni ni-cross-circle"></em>{" "}
                              <strong>{props.apiError}</strong>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="nk-block">
                      <form onSubmit={editFlag ? handleUpdate : handleSubmit}>
                        <div className="row g-3">
                          <div className="col-md-2">
                            <div className="form-group">
                              <label className="form-label" htmlFor="parentID">
                                Channel
                              </label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <select
                                    name="parentID"
                                    className="form-control"
                                    id="parentID"
                                    value={allDealerData.parentID}
                                    onChange={handleChange}
                                    autoComplete="off"
                                    style={{ 'border': parentID ? '1px solid red' : '' }}
                                    disabled={editFlag}
                                  >
                                    <option value="" key="0">Select Channel</option>
                                    {props.channelList && props.channelList.length > 0 && props.channelList.map(data => (
                                      <option name="parentID" value={data.ID} key={data.ID} >{data.ChannelName}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="dealerName"
                              >
                                Dealer Name
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="dealerName"
                                  className="form-control"
                                  id="dealerName"
                                  value={allDealerData.dealerName}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="Dealer Name"
                                  style={{ 'border': dealerName ? '1px solid red' : '' }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="contactPerson"
                              >
                                Contact Person
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="contactPerson"
                                  className="form-control"
                                  id="contactPerson"
                                  value={allDealerData.contactPerson}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="Contact Person"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="contactNumber">
                                Contact Number
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="number"
                                  name="contactNumber"
                                  className="form-control"
                                  id="contactNumber"
                                  value={allDealerData.contactNumber}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                  placeholder="Contact Number"
                                  onInput={() => { document.getElementById('contactError').innerHTML = ""; }}
                                />
                              </div>
                              <p className="text-danger" id="contactError"></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="contactEmailID">
                                Email ID
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="email"
                                  name="contactEmailID"
                                  className="form-control"
                                  id="contactEmailID"
                                  value={allDealerData.contactEmailID}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="Email ID"
                                // style={{ 'border': contactEmailID ? '1px solid red' : '' }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="address">
                                Address
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control"
                                  id="address"
                                  value={allDealerData.address}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="Address"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="city">
                                City
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="city"
                                  className="form-control"
                                  id="city"
                                  value={allDealerData.city}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="City"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="form-group">
                              <label className="form-label" htmlFor="countryID">
                                Country
                              </label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <select
                                    name="countryID"
                                    className="form-control"
                                    id="countryID"
                                    value={allDealerData.countryID}
                                    onChange={handleToCountry}
                                    autoComplete="off"
                                    style={{ 'border': countryID ? '1px solid red' : '' }}
                                  >
                                    <option value="" key="0">Select Country</option>
                                    {props.countries && props.countries.length > 0 && props.countries.map(country => (
                                      <option name="countryID" value={country.ID} key={country.ID}>{country.Name}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="stateID">
                                State
                              </label>
                              <div className="form-control-wrap">
                                <div className="form-control-select">
                                  <select
                                    name="stateID"
                                    className="form-control"
                                    id="stateID"
                                    value={allDealerData.stateID}
                                    onChange={handleTostate}
                                    autoComplete="off"
                                  >
                                    <option value="" key="0">Select State</option>
                                    {props.states && props.states.length > 0 && props.states.map(data => (
                                      <option name="stateID" value={data.ID} key={data.ID}>{data.StateName}</option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="zipCode">
                                Zip Code
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="number"
                                  name="zipCode"
                                  className="form-control"
                                  id="zipCode"
                                  value={allDealerData.zipCode}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="Zip Code"
                                  onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                  onInput={() => { document.getElementById('zipError').innerHTML = "" }}
                                />
                              </div>
                              <p className="text-danger" id="zipError"></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="tmobileUsername">
                                TMobile Username
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="tmobileUsername"
                                  className="form-control"
                                  id="tmobileUsername"
                                  value={allDealerData.tmobileUsername}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="TMobile Username"
                                  style={{ 'border': tmobileUsername ? '1px solid red' : '' }}
                                  pattern="^[a-zA-Z0-9_]*$"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="tmobilePassword">
                                TMobile Password
                              </label>
                              <div className="form-control-wrap">
                                <div className="form-icon form-icon-right" onClick={handleChangeTypeTMobilePassword}><em className={passTMobileIconClass}></em></div>
                                <input
                                  type={passwordTMobileType}
                                  name="tmobilePassword"
                                  className="form-control"
                                  id="tmobilePassword"
                                  value={allDealerData.tmobilePassword}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="TMobile Password"
                                  style={{ 'border': tmobilePassword ? '1px solid red' : '' }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="epayUsername">
                                EPAY Username
                              </label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  name="epayUsername"
                                  className="form-control"
                                  id="epayUsername"
                                  value={allDealerData.epayUsername}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="EPAY Username"
                                  pattern="^[a-zA-Z0-9_]*$"
                                  style={{ 'border': epayUsername ? '1px solid red' : '' }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label className="form-label" htmlFor="epayPassword">
                                EPAY Password
                              </label>
                              <div className="form-control-wrap">
                                <div className="form-icon form-icon-right" onClick={handleChangeTypeEMobilePassword}><em className={passEMobileIconClass}></em></div>
                                <input
                                  type={passwordEMobileType}
                                  name="epayPassword"
                                  className="form-control"
                                  id="epayPassword"
                                  value={allDealerData.epayPassword}
                                  onChange={handleChange}
                                  autoComplete="off"
                                  placeholder="EPAY Password"
                                  style={{ 'border': epayPassword ? '1px solid red' : '' }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex my-2">
                            <button type='submit' disabled={props.loading} className="btn btn-primary">{props.loading === true && <span
                              className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                              <span>Save</span> </button>
                            <button
                              className="btn btn-secondary mx-2"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="simplebar-placeholder"
              style={{ width: "auto", height: "864px" }}
            ></div>
          </div>
          <div
            className="simplebar-track simplebar-horizontal"
            style={{ visibility: "hidden" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{ width: "0px", display: "none" }}
            ></div>
          </div>
          <div
            className="simplebar-track simplebar-vertical"
            style={{ visibility: "visible" }}
          >
            <div
              className="simplebar-scrollbar"
              style={{
                height: "606px",
                display: "block",
                transform: "translate3d(0px, 0px, 0px)",
              }}
            ></div>
          </div>
        </div>
      )}

      {popUp && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-danger text-white">
              <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
            </div>
            <div className="modal-body">
              <p>{popUpDeleteContent}</p>
            </div>
            <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={() => handleStatusChange()}>Yes</div></div>
          </div>
        </div>
      </div>}

      {dealerSuspensionConfirmation && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Dealer Suspension Status</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setDealerSuspensionConfirmation(false)}><em className="icon ni ni-cross"></em></div>
            </div>
            <div className="modal-body">
              <h6 className="title">{configAcode ? `${configAcode} - ` : ''}{dealerSuspensionTitle}</h6>
              {configChannelName && configChannelName !== '' ? <p className="mb-2">{configChannelName}</p> : ''}
              {rowSuspension !== 0 ? <p>Are you sure, you want to suspend this dealer?</p> : <p>Are you sure, you want to unsuspend this dealer?</p>}
              <textarea className="form-control" style={suspensionRemarkError ? { border: '1px solid red' } : {}} onChange={(e) => setsuspensionRemark(e.target.value)} placeholder="Enter remark here.."></textarea>
              {/* {suspensionRemarkError ? <p style={styles.error}>{suspensionRemarkError}</p> : '' } */}
            </div>
            <div className="modal-footer"><button className="btn btn-dim btn-outline-danger" onClick={() => setDealerSuspensionConfirmation(false)}>Cancel</button>
              <button type='submit' disabled={props.loading} className="btn btn-dim btn-outline-primary" onClick={() => updateDealerSuspension()}>{props.loading === true && <span
                className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                <span>Ok</span></button>
            </div>
          </div>
        </div>
      </div>}

      {props.dealerSuspensionLogModal && <div className="modal fade show" tabIndex="-1" id="modalLarge" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header" style={{ backgroundColor: true ? '#fff8fb' : '' }}>
              {/* <div className='d-flex align-items-center'><h5 className="modal-title">{dealerSuspensionTitle}</h5></div> */}
              <div className='d-flex align-items-center'><h5 className="modal-title">Dealer Suspension Log</h5></div>
              <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => { }}><em className="icon ni ni-cross" onClick={() => dispatch(closeDealerSuspensionLog())}></em></div>
            </div>

            <div className="modal-body" style={{ backgroundColor: '#fff8fb' }}>
              <h6 className="title">{configAcode ? `${configAcode} - ` : ''}{dealerSuspensionTitle}</h6>
              {configChannelName && configChannelName !== '' ? <p className="mb-2">{configChannelName}</p> : ''}

              {props.dealerSuspensionLogs && props.dealerSuspensionLogs.length > 0 ? <div>

                <div className="timeline">
                  <div>
                    <ul className="timeline-list">
                      {
                        props.dealerSuspensionLogs.map((item, index) => {
                          return <li className="timeline-item" key={1}>
                            <div className={`timeline-status ${item.SuspensionFlag === 0 ? 'bg-success' : 'bg-danger'}`}></div>
                            <div className="mx-4">{true ? moment(new Date(item.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm") : null} PST</div>
                            <div className="timeline-data">
                              <div className=' d-flex'>
                                {0 === 0 ? <em className="icon ni ni-check-circle me-1" style={{ 'color': '#1ee0ac', 'fontSize': '20px' }}></em> : <em className="icon ni ni-cross-circle me-1" style={{ 'color': '#e85347', 'fontSize': '20px' }}></em>}
                                <h6 className="timeline-title mb-1">{item.Name}, </h6>
                                <span className='mx-1'>{item.Remark}</span></div>
                            </div>
                          </li>
                        })
                      }
                    </ul>
                  </div>
                </div>
              </div> : ''}
              {props.dealerSuspensionLogs.length == 0 && <h6 style={{ 'color': 'red' }}> No Data Found !</h6>}
            </div>
          </div>
        </div>
      </div>}

      {props.dealerConfigModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Dealer Configuration</h5>
              <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => dispatch(closeDealerConfigModal())}>
                <em className="icon ni ni-cross"></em>
              </div>
            </div>
            <div className="modal-body">
              <h6 className="title">{configAcode ? `${configAcode} - ` : ''}{dealerSuspensionTitle}</h6>
              {configChannelName && configChannelName !== '' ? <p className="mb-2">{configChannelName}</p> : ''}

              <div className="row" style={{ height: "40px" }}>

                <div className="col-lg-4 col-md-4 d-flex align-items-top">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" onChange={handleConfigCheckBoxChange} checked={props.dealerConfig.length > 0 ? props.dealerConfig[0].OthersAllowed : 0} value={props.dealerConfig.length > 0 ? props.dealerConfig[0].OthersAllowed : 0} id="OthersAllowed" />
                    <label className="form-check-label" htmlFor="OthersAllowed">
                      Others Allowed
                    </label>
                  </div>
                </div>

                {props.dealerConfig.length > 0 && props.dealerConfig[0].OthersAllowed ? <>
                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <input type="number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} value={props.dealerConfig[0].OthersAllowedCount != 0 ? props.dealerConfig[0].OthersAllowedCount : ''} min={1} style={countAllowedError ? { border: "1px solid red" } : { height: '31px' }} className="form-control form-control-sm" onChange={handleConfigInputChange} id="OthersAllowedCount" placeholder="Count allowed" />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <select className="form-select form-select-sm" style={countDurationError ? { border: "1px solid red" } : {}} id="OthersAllowedDuration" onChange={handleConfigInputChange}>
                        {props.dealerConfig[0].OthersAllowedDuration === 0 || !props.dealerConfig[0].OthersAllowedDuration ? <option selected disabled>For Duration</option> : ''}
                        {props.othersAllowedDuration.map((item, key) => {
                          return <option selected={props.dealerConfig[0].OthersAllowedDuration === item.ID} value={item.ID} key={key}>{item.Value}</option>
                        })}

                      </select>
                    </div>
                  </div>
                </> : ''}

              </div>

              <div className="row" style={{ height: "40px" }}>
                {/* <div className="col-lg-4 col-md-4">
                  <div className="form-check">
                    <input className="form-check-input" onChange={handleConfigCheckBoxChange} checked={props.dealerConfig.length > 0 ? props.dealerConfig[0].SelfActivation : 0} type="checkbox" value={props.dealerConfig.length > 0 ? props.dealerConfig[0].SelfActivation : 0} id="SelfActivation" />
                    <label className="form-check-label" htmlFor="SelfActivation">
                      Self Activation
                    </label>
                  </div>
                </div> */}

                <div className="col-lg-6 col-md-6">
                  <div className="form-check">
                    <input className="form-check-input" onChange={handleConfigCheckBoxChange} checked={props.dealerConfig.length > 0 ? props.dealerConfig[0].SelfBulkActivation : 0} type="checkbox" value={props.dealerConfig.length > 0 ? props.dealerConfig[0].SelfBulkActivation : 0} id="SelfBulkActivation" />
                    <label className="form-check-label" htmlFor="SelfBulkActivation">
                      Self Bulk Activation
                    </label>
                  </div>
                </div>
              </div>

              {/* {props.dealerConfig.length > 0 && props.dealerConfig[0].SelfActivation === 1 ?  : ''} */}
              {/* {props.dealerConfig.length > 0 && props.dealerConfig[0].SelfBulkActivation === 1 ?  : ''} */}

              <div className="row" style={{ height: "40px" }}>
                <div className="col-lg-4 col-md-4 d-flex align-items-top">
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" onChange={handleConfigCheckBoxChange} checked={props.dealerConfig.length > 0 ? props.dealerConfig[0].OthersBulkAllowed : 0} value={props.dealerConfig.length > 0 ? props.dealerConfig[0].OthersBulkAllowed : 0} id="OthersBulkAllowed" />
                    <label className="form-check-label text-nowrap" htmlFor="OthersBulkAllowed">
                      Other Bulk Allowed
                    </label>
                  </div>
                </div>

                {props.dealerConfig.length > 0 && props.dealerConfig[0].OthersBulkAllowed ? <>
                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <input type="number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} value={props.dealerConfig[0].OthersBulkCount != 0 ? props.dealerConfig[0].OthersBulkCount : ''} min={1} style={countBulkAllowedError ? { border: "1px solid red" } : { height: '31px' }} className="form-control form-control-sm" onChange={handleConfigInputChange} id="OthersBulkCount" placeholder="Count allowed" />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <div className="form-group">
                      <select className="form-select form-select-sm" style={countBulkDurationError ? { border: "1px solid red" } : {}} id="OthersBulkDuration" onChange={handleConfigInputChange}>
                        {props.dealerConfig[0].OthersBulkDuration === 0 || !props.dealerConfig[0].OthersBulkDuration ? <option selected disabled>For Duration</option> : ''}
                        {props.othersAllowedDuration.map((item, key) => {
                          if (item.ID != 35) {
                            return null;
                          } else {
                            return <option selected={props.dealerConfig[0].OthersBulkDuration === item.ID} value={item.ID} key={key}>{item.Value}</option>
                          }
                        })}
                      </select>
                    </div>
                  </div>
                </> : ''}

              </div>




            </div>
            <div className="modal-footer"><button className="btn btn-dim btn-outline-danger" onClick={() => dispatch(closeDealerConfigModal())}>Cancel</button>
              <button type='submit' disabled={props.loading} className="btn btn-dim btn-outline-primary" onClick={() => handleUpdateDealerConfig()}>{props.dealerConfigLoading === true && <span
                className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                <span>Update</span></button>
            </div>
          </div>
        </div>
      </div>}

      {/* {props.emailConfigModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Domain Configuration</h5>
              <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => dispatch(closeEmailConfigModal())}>
                <em className="icon ni ni-cross"></em>
              </div>
            </div>
            <div className="modal-body">
              <form className="form-validate is-alter" method="post" onSubmit={handleSubmitModal}>
                <div className="form-group">
                  <label className="form-label" htmlFor="domain">Domain</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      name="domain"
                      id="domain"
                      defaultValue={emailData}
                      placeholder="Enter Domain"
                      autoComplete="off"
                      pattern="^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                      title="Please enter a valid domain (e.g., gmail.com)"
                      onChange={handleInput}
                      required
                    />
                  </div>
                </div>
                <div className="modal-footer"><button className="btn btn-dim btn-outline-danger" onClick={() => dispatch(closeEmailConfigModal())}>Cancel</button>
                  <button type='submit' disabled={props.loading} className="btn btn-dim btn-outline-primary" >{props.emailConfigLoading === true && <span
                    className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                    <span>Update</span></button>
                </div>
              </form>
            </div>

          </div>
        </div>
      </div>} */}
    </div>
  );
}

const mapStateToProps = (state) => {
  const { channelData, dealerData, countries, states, apiError, message, loading, userId, modalMessage, channelList, dealerSuspensionLogModal, dealerSuspensionLogs, dealerConfigModal, dealerConfig, dealerConfigLoading, dealerConfigMessage, dealerConfigError, othersAllowedDuration, emailConfigModal, emailConfigLoading, emailConfigMessage } = state.dealer;
  return { channelData, dealerData, countries, states, apiError, message, loading, userId, modalMessage, channelList, dealerSuspensionLogModal, dealerSuspensionLogs, dealerConfigModal, dealerConfig, dealerConfigLoading, dealerConfigMessage, dealerConfigError, othersAllowedDuration, emailConfigModal, emailConfigLoading, emailConfigMessage };
};

const styles = {
  error: {
    color: '#f00',
    marginTop: '10px',
  }
}
// export default Users;
export default withRouter(connect(mapStateToProps, { getAllChannelList, dealerList, getCountryList, stateListing, editDealer, getChannelName, dealerFlagUpdate, getOthersAllowedDuration, closeEmailConfigModal, openEmailConfigModal, updateDealeremail })(Dealer));
