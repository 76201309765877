import { ASSET_LIST, ASSET_LIST_SUCCESSFUL, ASSET_API_ERROR, ADD_ASSET, ADD_ASSET_SUCCESS, RESET_ASSET_ALERT, EDIT_ASSET, DELETE_ASSET, DELETE_ASSET_SUCCESS, LOCATION_LIST, LOCATION_LIST_SUCCESSFUL, ADD_LOCATION, ADD_LOCATION_SUCCESS, EDIT_LOCATION, DELETE_LOCATION, DELETE_LOCATION_SUCCESS, ASSET_REPORT, ASSET_REPORT_SUCCESS, ASSET_REPORT_FAILED, ASSET_REPORT_ASSET_ID, ASSET_REPORT_ASSET_ID_SUCCESS, ASSET_REPORT_ASSET_ID_FAILED, ASSET_RUNNING_STATUS_CHANGE, ASSET_RUNNING_STATUS_CHANGE_SUCCESS } from "./actionTypes";

const initialState = {
    apiError: null, message: null, loading: false, assetData: [], locationData: [], assetReportData: [], eachAssetReportData: [], modalLoading: false, assetLoading: false, locationLoading: false, assetReportLoading: false, buttonLoading: false

}

const asset = (state = initialState, action) => {
    switch (action.type) {
        case ASSET_LIST:
            state = {
                ...state,
                assetLoading: true,
                apiError: null,
                message: null
            }
            break;
        case ASSET_LIST_SUCCESSFUL:
            state = {
                ...state,
                assetData: action.payload,
                assetLoading: false,
                apiError: null,
                message: null
            }
            break;
        case ADD_ASSET:
            state = {
                ...state,
                buttonLoading: true,
                asset: action.payload,
                apiError: null,
                message: null
            };
            break;
        case ADD_ASSET_SUCCESS:
            state = {
                ...state,
                buttonLoading: false,
                assetData: action.payload.assetData,
                message: action.payload.message
            }
            break;
        case ASSET_API_ERROR:
            state = {
                ...state,
                buttonLoading: false,
                apiError: action.payload,
            }
            break;
        case EDIT_ASSET:
            state = {
                ...state,
                buttonLoading: true,
                apiError: null,
                message: null
            }
            break;
        case DELETE_ASSET:
            state = {
                ...state,
                buttonLoading: true,
                apiError: null,
                message: null
            }
            break;
        case DELETE_ASSET_SUCCESS:
            state = {
                ...state,
                assetLoading: false,
                message: action.payload.message,
                assetData: action.payload.assetData,
            }
            break;
        case RESET_ASSET_ALERT:
            state = {
                ...state,
                buttonLoading: false,
                assetLoading: false,
                locationLoading: false,
                assetReportLoading: false,
                message: null,
                apiError: null,
            }
            break;
        case LOCATION_LIST:
            state = {
                ...state,
                locationLoading: true,
                apiError: null,
                message: null
            }
            break;
        case LOCATION_LIST_SUCCESSFUL:
            state = {
                ...state,
                locationData: action.payload,
                locationLoading: false,
                apiError: null,
                message: null
            }
            break;
        case ADD_LOCATION:
            state = {
                ...state,
                buttonLoading: true,
                location: action.payload,
                apiError: null,
                message: null
            };
            break;
        case ADD_LOCATION_SUCCESS:
            state = {
                ...state,
                buttonLoading: false,
                locationData: action.payload.locationData,
                message: action.payload.message,
                apiError: null
            }
            break;
        case EDIT_LOCATION:
            state = {
                ...state,
                buttonLoading: true,
                apiError: null,
                message: null
            }
            break;
        case DELETE_LOCATION:
            state = {
                ...state,
                locationLoading: true,
                apiError: null,
                message: null
            }
            break;
        case DELETE_LOCATION_SUCCESS:
            state = {
                ...state,
                locationLoading: false,
                message: action.payload.message,
                locationData: action.payload.locationData,
                apiError: null
            }
            break;
        case ASSET_REPORT:
            state = {
                ...state,
                assetReportLoading: true,
                message: null,
                apiError: null
            }
            break;
        case ASSET_REPORT_SUCCESS:
            state = {
                ...state,
                assetReportLoading: false,
                message: null,
                apiError: null,
                assetReportData: action.payload
            }
            break;
        case ASSET_REPORT_FAILED:
            state = {
                ...state,
                assetReportLoading: false,
                message: null,
                apiError: action.payload,
            }
            break;
        case ASSET_REPORT_ASSET_ID:
            state = {
                ...state,
                modalLoading: true,
                message: null,
                apiError: null
            }
            break;
        case ASSET_REPORT_ASSET_ID_SUCCESS:
            state = {
                ...state,
                modalLoading: false,
                message: null,
                apiError: null,
                eachAssetReportData: action.payload
            }
            break;
        case ASSET_REPORT_ASSET_ID_FAILED:
            state = {
                ...state,
                modalLoading: false,
                message: null,
                apiError: action.payload,
            }
            break;
        case ASSET_RUNNING_STATUS_CHANGE:
            state = {
                ...state,
                buttonLoading: true,
                apiError: null,
                message: null
            }
            break;
        case ASSET_RUNNING_STATUS_CHANGE_SUCCESS:
            state = {
                ...state,
                assetLoading: false,
                message: action.payload.message,
                assetData: action.payload.assetData,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default asset;