import { GET_ALL_CHANNEL_LIST, GET_ALL_CHANNEL_LIST_SUCCESS, PROCESSED_ACTIVATION, PROCESSED_ACTIVATION_SUCCESSFULL, RESET_DEALER_ACTIVATION_REPORT, SEARCH_DEALER_ACTIVATION, SEARCH_DEALER_ACTIVATION_REPORT_FAILED, SEARCH_DEALER_ACTIVATION_SUCCESSFULL } from "./actionTypes"


export const getAllChannelList = (dealerId) => {
    return {
        type: GET_ALL_CHANNEL_LIST,
        payload: dealerId
    }
}

export const getAllChannelListSuccessfull = (channelData) => {
    return {
        type: GET_ALL_CHANNEL_LIST_SUCCESS,
        payload: channelData
    }
}
export const searchDealerActivation= (searchData) =>{
    return {
        type: SEARCH_DEALER_ACTIVATION,
        payload: searchData
    }
}

export const searchDealerActivationSuccess =(searchReportData,searchExcelData)=>{
    return{
        type:SEARCH_DEALER_ACTIVATION_SUCCESSFULL,
        payload: {searchReportData,searchExcelData}
    }
}

export const searchDealerActivationFailed = (error) =>{
    return{
        type:SEARCH_DEALER_ACTIVATION_REPORT_FAILED,
        payload: error
    }
}

export const processedActivation = (processedData) =>{
    return{
        type:PROCESSED_ACTIVATION,
        payload:processedData
    }
}

export const processedActivationSuccessfull = (processedData) =>{
    return{
        type:PROCESSED_ACTIVATION_SUCCESSFULL,
        payload:processedData
    }
}
export const resetDealerActivationReportPage=()=>{
    return{
        type:RESET_DEALER_ACTIVATION_REPORT
    }
}