import { RESET_LATE_ACTIVATED_TXN_REPORT_PAGE, SEARCH_LATE_ACTIVATED_TXN, SEARCH_LATE_ACTIVATED_TXN_SUCCESSFULL, SEARCH_LATE_ACTIVATED_TXN_FAILED } from "./actionTypes"

export const searchLateActivatedTxn= (searchData) =>{
    return {
        type: SEARCH_LATE_ACTIVATED_TXN,
        payload: searchData
    }
}

export const searchLateActivatedTxnSuccessfull =(searchReportData)=>{
    return{
        type:SEARCH_LATE_ACTIVATED_TXN_SUCCESSFULL,
        payload: searchReportData
    }
}

export const searchLateActivatedTxnFailed = (error) =>{
    return{
        type:SEARCH_LATE_ACTIVATED_TXN_FAILED,
        payload: error
    }
}

export const resetChannelActivationReportPage = () =>{
    return{
        type:RESET_LATE_ACTIVATED_TXN_REPORT_PAGE,
    }
}