import { CONFIG_API_ERROR, CONFIG_CHANNEL_DATA_SUCCESSFULL, GET_CHANNEL_CONFIG, RESET_ALERT_CONFIG, UPDATE_CHANNEL_CONFIG_DATA, UPDATE_CHANNEL_CONFIG_DATA_SUCCESSFULL } from "./actionTypes";

const initialState = {
    apiError: null, loading: false
}

const channelConfig = (state = initialState, action) => {
    switch (action.type) {
        case GET_CHANNEL_CONFIG:
            state = {
                ...state,
                loading: true,
                apiError: null,
            }
            break;

        case CONFIG_CHANNEL_DATA_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                configData: action.payload,
                apiError: null,
            }
            break;

        case UPDATE_CHANNEL_CONFIG_DATA:
            state = {
                ...state,
                loading: true,
                apiError: null,
            }
            break;

        case UPDATE_CHANNEL_CONFIG_DATA_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                Message: action.payload.Message,
                configData: action.payload.configData,
                apiError: null,
            }
            break;

        case CONFIG_API_ERROR:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;
        case RESET_ALERT_CONFIG:
            state = {
                ...state,
                loading: false,
                Message: null,
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default channelConfig;