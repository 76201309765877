import {
    DEALER_EMAIL_CONFIG_LIST,
    DEALER_EMAIL_CONFIG_SUCCESSFUL,
    DEALER_EMAIL_CONFIG_ERROR,
    CLOSE_EMAIL_CONFIG_MODAL,
    OPEN_EMAIL_CONFIG_MODAL,
    ADD_DEALER_EMAIL_CONFIG,
    ADD_DEALER_EMAIL_CONFIG_SUCCESS,
    ADD_DEALER_EMAIL_CONFIG_FAILED,
    EDIT_DEALER_EMAIL_CONFIG,
    EDIT_DEALER_EMAIL_CONFIG_SUCCESS,
    EDIT_DEALER_EMAIL_CONFIG_FAILED,
    DELETE_DEALER_EMAIL_CONFIG,
    DELETE_DEALER_EMAIL_CONFIG_SUCCESS,
    DELETE_DEALER_EMAIL_CONFIG_FAILED,
    RESET_DEALER_EMAIL_CONFIG_MESSAGE
} from "./actionTypes"

const initialState = {
    error: null, message: null, loading: false, dealerEmailData: [], emailConfigModal: false,buttonLoading:false,searchLoading:false,
    editMessage:null,errormessage:null

}

const dealerEmailConfig = (state = initialState, action) => {
    switch (action.type) {
        case DEALER_EMAIL_CONFIG_LIST:
            state = {
                ...state,
                searchLoading: true,
                error: null,
                message: null
            }
            break;
        case DEALER_EMAIL_CONFIG_SUCCESSFUL:
            state = {
                ...state,
                searchLoading: false,
                dealerEmailData: action.payload,
                error: null,
                message: null
            }
            break;
        case DEALER_EMAIL_CONFIG_ERROR:
            state = {
                ...state,
                searchLoading: false,
                error: action.payload,
            }
            break;
        case OPEN_EMAIL_CONFIG_MODAL:
            state = {
                ...state,
                emailConfigModal: true,
            }
            break;
        case CLOSE_EMAIL_CONFIG_MODAL:
            state = {
                ...state,
                emailConfigModal: false,
            }
            break;

        case ADD_DEALER_EMAIL_CONFIG:
            state = {
                ...state,
                buttonLoading: true,
                errormessage: null,
                editMessage: null
            };
            break;
        case ADD_DEALER_EMAIL_CONFIG_SUCCESS:
            state = {
                ...state,
                buttonLoading: false,
                editMessage: action.payload.message,
                errormessage: null,
                emailConfigModal: false,
            }
            break;
        case ADD_DEALER_EMAIL_CONFIG_FAILED:
            state = {
                ...state,
                buttonLoading: false,
                dealerEmailData: action.payload.Data,
                editMessage: null,
                errormessage: action.payload.error,
                emailConfigModal: true,
            }
            break;
        case EDIT_DEALER_EMAIL_CONFIG:
            state = {
                ...state,
                buttonLoading: true,
                errormessage: null,
                editMessage: null
            };
            break;
        case EDIT_DEALER_EMAIL_CONFIG_SUCCESS:
            state = {
                ...state,
                buttonLoading: false,
                editMessage: action.payload.message,
                errormessage: null,
                emailConfigModal: false,
            }
            break;
        case EDIT_DEALER_EMAIL_CONFIG_FAILED:
            state = {
                ...state,
                buttonLoading: false,
                dealerEmailData: action.payload.Data,
                editMessage: null,
                errormessage: action.payload.error,
                emailConfigModal: true,
            }
            break;

        case DELETE_DEALER_EMAIL_CONFIG:
            state = {
                ...state,
                errormessage: null,
                editMessage: null
            }
            break;
        case DELETE_DEALER_EMAIL_CONFIG_SUCCESS:
            state = {
                ...state,
                editMessage: action.payload.message,
                errormessage: null,
            }
            break;

        case DELETE_DEALER_EMAIL_CONFIG_FAILED:
            state = {
                ...state,
                editMessage: null,
                errormessage: action.payload.error
            }
            break;
            case RESET_DEALER_EMAIL_CONFIG_MESSAGE:
                state = {
                    ...state,
                    searchLoading: false,
                    buttonLoading:false,
                    message: null,
                    errormessage: null,
                    editMessage:null,
                }
                break;
    


        default:
            state = { ...state };
            break;
    }
    return state;
}

export default dealerEmailConfig;