import { RESET_ASSET_PERFORMANCE_ACTIVATION_REPORT, SEARCH_ASSET_PERFORMANCE_ACTIVATION, SEARCH_ASSET_PERFORMANCE_ACTIVATION_FAILED, SEARCH_ASSET_PERFORMANCE_ACTIVATION_SUCCESS } from "./actionTypes";

const initialState = {
    apiError: null, loading: false, assetPerformanceActivationData: [], showAssetPerformancePage: false
}

const assetPerformanceReport = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_ASSET_PERFORMANCE_ACTIVATION:
            state = {
                ...state,
                loading: true,
                apiError: null,
                showAssetPerformancePage: true
            }
            break;
        case SEARCH_ASSET_PERFORMANCE_ACTIVATION_SUCCESS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                showAssetPerformancePage: true,
                assetPerformanceActivationData: action.payload
            }
            break;
        case SEARCH_ASSET_PERFORMANCE_ACTIVATION_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.error,
                showAssetPerformancePage: true,
            }
            break;
        case RESET_ASSET_PERFORMANCE_ACTIVATION_REPORT:
            state = {
                ...state,
                loading: false,
                apiError: null,
                showAssetPerformancePage: false,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default assetPerformanceReport;