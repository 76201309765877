export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NOTIFICATION_LIST_SUCCESSFUL = 'NOTIFICATION_LIST_SUCCESSFUL';
export const NOTIFICATION_API_ERROR ="NOTIFICATION_API_ERROR";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const EDIT_NOTIFICATION = "EDIT_NOTIFICATION";
export const NOTIFICATION_API_ERROR_MODAL = "NOTIFICATION_API_ERROR_MODAL";
export const RESET_NOTIFICATION_ALERT = "RESET_NOTIFICATION_ALERT";
export const SET_ISCURRENT_STATUS = "SET_ISCURRENT_STATUS";
export const SET_ISCURRENT_STATUS_SUCCESSFULL = "SET_ISCURRENT_STATUS_SUCCESSFULL";
