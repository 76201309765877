import React from 'react'

function FilterComponent({ filterText, onFilter }) {
    return (
        <div className="search-content">
            <div className="search-back btn btn-icon toggle-search active" data-target="search">
                <em className="icon ni ni-arrow-left"></em>
            </div>
            {/* <form> */}
            <input type="text" id="search" className="form-control border-transparent form-focus-none" autoComplete='off' style={{ "height": "calc(2rem + 0px)" }} placeholder="Filter table data..." value={filterText} onChange={onFilter} />
            <button className="search-submit btn btn-icon" style={{ 'paddingRight': '15px' }}></button>
            {/* </form> */}
        </div>
    )
}
export default FilterComponent;