import {
    GET_MAIL_BY_MSISDN,
    GET_MAIL_BY_MSISDN_SUCCESS,
    GET_MAIL_BY_MSISDN_FAIL,
    READ_MAIL,
    READ_MAIL_SUCCESS,
    READ_MAIL_FAIL,
    CLOSE_MAIL_TEMPLATE,
    RESET_TEMPLATE_DATA
} from "./actionTypes"


const initialState = {
    getMailByMsisdnLoading: false,
    getMailByMsisdnError: null,
    getMailByMsisdnMessage: null,
    getMailByMsisdn: [],
    readMailLoading: false,
    readMailError: null,
    readMailMessage: null,
}

const planWiseActivationReport = (state = initialState, action) => {
    switch (action.type) {
        case GET_MAIL_BY_MSISDN:
            state = {
                ...state,
                getMailByMsisdnLoading: true,
                getMailByMsisdnError: null,
                getMailByMsisdnMessage: null,
            }
            break;
        case GET_MAIL_BY_MSISDN_SUCCESS:
            state = {
                ...state,
                getMailByMsisdnLoading: false,
                getMailByMsisdnError: null,
                getMailByMsisdnMessage: null,
                getMailByMsisdn: action.payload.data,
            }
            break;
        case GET_MAIL_BY_MSISDN_FAIL:
            state = {
                ...state,
                getMailByMsisdnLoading: false,
                getMailByMsisdnError: action.payload.error,
                getMailByMsisdnMessage: null,
                getMailByMsisdn: [],
            }
            break;

        case READ_MAIL:
            let temp1 = state.getMailByMsisdn.map((item) => {
                let temp = item;
                if (item.ID == action.payload.mailID) {
                    temp.showMail = !temp.showMail;
                } else {
                    temp.showMail = false;
                }
                return temp;
            });

            state = {
                ...state,
                readMailLoading: true,
                readMailError: null,
                readMailMessage: null,
                getMailByMsisdn: temp1
            }
            break;
        case READ_MAIL_SUCCESS:
            let temp3 = state.getMailByMsisdn.map((item) => {
                let temp = item;
                if (item.ID == action.payload.mailID) {
                    temp.IsRead = 1;
                }
                return temp;
            });

            state = {
                ...state,
                readMailLoading: false,
                readMailError: null,
                readMailMessage: action.payload.message,
                getMailByMsisdn: temp3
            }
            break;
        case READ_MAIL_FAIL:
            state = {
                ...state,
                readMailLoading: false,
                readMailError: null,
                readMailMessage: null,
            }
            break;

        case CLOSE_MAIL_TEMPLATE:

            let temp2 = state.getMailByMsisdn.map((item) => {
                let temp = item;
                if (item.ID == action.payload.mailID) {
                    temp.showMail = !temp.showMail;
                }
                return temp;
            });

            state = {
                ...state,
                readMailLoading: false,
                readMailError: null,
                readMailMessage: null,
                getMailByMsisdn: temp2
            }
            break;

        case RESET_TEMPLATE_DATA:
            state = {
                ...state,
                getMailByMsisdn: [],
            }
            break;



        default:
            state = { ...state };
            break;
    }
    return state;
}

export default planWiseActivationReport;