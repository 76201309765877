import {
    GET_INSTANCES,
    GET_INSTANCES_SUCCESS,
    GET_INSTANCES_FAILED,
    INSTANCES_STOP,
    INSTANCES_STOP_SUCCESS,
    INSTANCES_STOP_FAILED,
    INSTANCES_START,
    INSTANCES_START_SUCCESS,
    INSTANCES_START_FAILED,
    INSTANCES_START_MODAL_OPEN,
    INSTANCES_START_MODAL_CLOSE,
    INSTANCES_STOP_MODAL_OPEN,
    INSTANCES_STOP_MODAL_CLOSE,
    INSTANCES_PASSWORD_MODAL_OPEN,
    INSTANCES_PASSWORD_MODAL_CLOSE,
    INSTANCES_PASSWORD_UPDATE,
    INSTANCES_PASSWORD_UPDATE_SUCCESS,
    INSTANCES_PASSWORD_UPDATE_FAILED,
    TOGGLE_INSTANCES_PASSWORD
} from "./actionTypes";

const initialState = {
    instanceData: [],
    loading: false,
    message: null,
    error: null,
    ec2Stoploading: false,
    ec2Stopmessage: null,
    ec2Stoperror: null,
    ec2Startloading: false,
    ec2Startmessage: null,
    ec2Starterror: null,
    ec2StartModal: false,
    ec2StopModal: false,
    ec2PasswordModal: false,
    ec2PasswordChangeloading: false,
    ec2PasswordChangeerror: null,
    ec2PasswordChangemessage: null,
}

const ec2Instance = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_INSTANCES_PASSWORD:
            console.log(action.payload.instanceId);
            let newState = state.instanceData.map((item) => {
                let temp = item;
                if(temp.InstanceId == action.payload.instanceId){
                    temp.show = !temp.show;
                }
                return temp;
            });
            state = {
                ...state,
                instanceData: newState,
            }
            break;

        case INSTANCES_PASSWORD_UPDATE:
            state = {
                ...state,
                ec2PasswordChangeloading: true,
                ec2PasswordChangeerror: null,
                ec2PasswordChangemessage: null
            }
            break;

        case INSTANCES_PASSWORD_UPDATE_SUCCESS:
            state = {
                ...state,
                ec2PasswordChangeloading: false,
                ec2PasswordChangeerror: false,
                ec2PasswordChangemessage: action.payload,
                ec2PasswordModal: false,
            }
            break;

        case INSTANCES_PASSWORD_UPDATE_FAILED:
            state = {
                ...state,
                ec2PasswordChangeloading: false,
                ec2PasswordChangeerror: action.payload,
                ec2PasswordChangemessage: action.payload,
            }
            break;


        case INSTANCES_PASSWORD_MODAL_CLOSE:
            state = {
                ...state,
                ec2PasswordModal: false,
            }
            break;

        case INSTANCES_PASSWORD_MODAL_OPEN:
            state = {
                ...state,
                ec2PasswordModal: true,
            }
            break;

        case INSTANCES_STOP_MODAL_CLOSE:
            state = {
                ...state,
                ec2StopModal: false,
            }
            break;

        case INSTANCES_STOP_MODAL_OPEN:
            state = {
                ...state,
                ec2StopModal: true,
            }
            break;

        case INSTANCES_START_MODAL_CLOSE:
            state = {
                ...state,
                ec2StartModal: false,
            }
            break;

        case INSTANCES_START_MODAL_OPEN:
            state = {
                ...state,
                ec2StartModal: true,
            }
            break;

        case INSTANCES_START:
            state = {
                ...state,
                ec2Startloading: true,
                ec2Startmessage: null,
                ec2Starterror: null
            }
            break;

        case INSTANCES_START_SUCCESS:
            state = {
                ...state,
                ec2Startloading: false,
                ec2Startmessage: action.payload,
                ec2Starterror: null,
                ec2StartModal: false,
            }
            break;

        case INSTANCES_START_FAILED:
            state = {
                ...state,
                ec2Startloading: false,
                ec2Startmessage: action.payload,
                ec2Starterror: action.payload,
            }
            break;


        case INSTANCES_STOP:
            state = {
                ...state,
                ec2Stoploading: true,
                ec2Stopmessage: null,
                ec2Stoperror: null
            }
            break;

        case INSTANCES_STOP_SUCCESS:
            state = {
                ...state,
                ec2Stoploading: false,
                ec2Stopmessage: action.payload,
                ec2Stoperror: null,
                ec2StopModal: false
            }
            break;

        case INSTANCES_STOP_FAILED:
            state = {
                ...state,
                ec2Stoploading: false,
                ec2Stopmessage: action.payload,
                ec2Stoperror: action.payload,
            }
            break;

        case GET_INSTANCES:
            state = {
                ...state,
                loading: true,
                message: null,
                error: null
            }
            break;

        case GET_INSTANCES_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: false,
                error: null,
                instanceData: action.payload,
                ec2Startmessage : null,
                ec2Stopmessage : null,
            }
            break;

        case GET_INSTANCES_FAILED:
            state = {
                ...state,
                loading: false,
                message: action.payload,
                error: action.payload,
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default ec2Instance;