import { GET_LOCKED_ACTIVATION_DATA, GET_LOCKED_ACTIVATION_DATA_FAILED, GET_LOCKED_ACTIVATION_DATA_SUCCESSFULL, UNLOCK_ACTIVATION_BY_OTHER, UNLOCK_ACTIVATION_BY_OTHER_FAILED, UNLOCK_ACTIVATION_BY_OTHER_SUCCESS } from "./actionTypes"

export const getLockedData = (companyId) => {
    return {
        type: GET_LOCKED_ACTIVATION_DATA,
        payload: companyId
    }
}

export const getLockedDataSuccess = (lockedData) => {
    return {
        type: GET_LOCKED_ACTIVATION_DATA_SUCCESSFULL,
        payload: lockedData
    }
}

export const getLockedDataFailed = (error) => {
    return {
        type: GET_LOCKED_ACTIVATION_DATA_FAILED,
        payload: error
    }
}

export const unlockActivationByOther = (unLockData) => {
    return {
        type: UNLOCK_ACTIVATION_BY_OTHER,
        payload: unLockData
    }
}

export const unLockActivationByOtherSuccess = (message, lockedData) => {
    return {
        type: UNLOCK_ACTIVATION_BY_OTHER_SUCCESS,
        payload: { message, lockedData }
    }
}

export const unLockActivationByOtherFailed = (message) => {
    return {
        type: UNLOCK_ACTIVATION_BY_OTHER_FAILED,
        payload: message
    }
}