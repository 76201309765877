import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from "moment";
import { pausedActivationList } from '../store/pausedActivations/actions';

function PausedActivations(props) {
    const dispatch = useDispatch();
    // const [companyId, setcompanyId] = useState("");
    const [refreshInterval, setRefreshInterval] = useState(60000);
    const CompID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
    // refresh function in every one minute
    const fetchMetrics = () => {
        // retrieve and then setData()
        dispatch(pausedActivationList(CompID));
    }
    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        // const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        // setcompanyId(CompanyID);
        props.pausedActivationList(CompanyID);

        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(fetchMetrics, refreshInterval);
            return () => clearInterval(interval);
        }

    }, [refreshInterval]);

    // setInterval('dispatch(pausedActivationList(companyId))', 10000);

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Paused Activation Request</h3>
                <div className="card p-2" style={{ "marginTop": '12px', "minHeight": "500px" }}>
                    <div className="card-inner p-0" >
                        <div className="container">
                            <div className="row">
                                {props.pausedActivation && props.pausedActivation.length > 0 ? props.pausedActivation.map((data, key) => {
                                    return (
                                        <div className="col-md-6 col-sm-12 col-lg-4 PausedCardview" id={key}>
                                            <div className="mt-5 shadow-sm p-3 PausedCard" style={{ "backgroundColor": `${(data.RequestTypeID === 7 && data.SimTypeID === 26) ? "#fdebe1" : (data.RequestTypeID === 7 && data.SimTypeID === 25) ? "#DEF3FF" : (data.RequestTypeID === 8 && data.SimTypeID === 26) ? "#F1FFE7" : (data.RequestTypeID === 8 && data.SimTypeID === 25) ? "#DFFDF8" : ""}`, "borderRadius": "15px" }}>
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <p><strong>CHANNEL TXN:-</strong> {data.ChannelTxnID}</p>
                                                        <hr />
                                                    </div>
                                                    <div className="col-md-4 text-right" >
                                                        <h6 className="esim-style" style={{ "backgroundColor": `${(data.RequestTypeID === 7 && data.SimTypeID === 26) ? "#FFA370" : (data.RequestTypeID === 7 && data.SimTypeID === 25) ? "#68C5FA" : (data.RequestTypeID === 8 && data.SimTypeID === 26) ? "#A0FF5B" : (data.RequestTypeID === 8 && data.SimTypeID === 25) ? "#33F3D3" : ""}` }}>{data.SimType}<p className='mt-1 text-white' style={{ "fontSize": "12px" }}>{data.RequestType}</p></h6>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <p><strong>STATUS:- </strong> {data.RequestStatus}</p>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <p><strong>DATE:- </strong> {moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p><strong>SERIAL/EID:- </strong> {data.SerialNumber}</p>
                                                        <p><strong>IMEI:-</strong> {data.IMEI}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p><strong>PLAN:-</strong> {data.PlanName}</p>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p><strong>REMARK:-</strong> {data.Remark}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                }) : <p className="text-center" style={{ "marginTop": "50px", "marginBottom": "50px" }}>No results found</p>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { pausedActivation, apiError, loading } = state.Paused
    return { pausedActivation, apiError, loading };
}

export default withRouter(connect(mapStateToProps, { pausedActivationList })(PausedActivations));