import { DELETE_JAR_DATA, DELETE_JAR_DATA_SUCCESSFULLY, EDIT_JAR_DATA, EDIT_JAR_DATA_SUCCESSFULLY, GET_JAR_LIST, GET_JAR_LIST_SUCCESSFULL, JAR_FAILED } from "./actionTypes";

const initialState = { apiError: null, loading: false }

const jar = (state = initialState, action) => {
    switch (action.type) {
        case GET_JAR_LIST:
            state = {
                ...state,
                loading: true,
            }
            break;

        case GET_JAR_LIST_SUCCESSFULL:
            state = {
                ...state,
                loading:false,
                jarData: action.payload
            }
            break;

        case JAR_FAILED:
            state = {
                ...state,
                message: action.payload
            }
            break;
        case EDIT_JAR_DATA:
            state={
                ...state,
            }
            break;
        case EDIT_JAR_DATA_SUCCESSFULLY:
            state={
                ...state,
                jarData:action.payload.jarData,
                message:action.payload.message,
            }
            break;
        case DELETE_JAR_DATA:
            state={
                ...state,
            }
            break;
        case DELETE_JAR_DATA_SUCCESSFULLY:
            state={
                ...state,
                jarData:action.payload.jarData,
                message:action.payload.message
            }
            break;
         
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default jar;
