import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import {
    GET_DEALER_POS,
    GET_DEALER_BY_CHANNEL_ID_AND_ACODE,
    POS_DEALER_MAP,
} from './actionTypes';
import {
    getDealerPosSuccessful,
    getDealerPosFailed,
    getDealerByChannelIdAndAcodeSuccessful,
    getDealerByChannelIdAndAcodeFailed,
    posDealerMapSuccessful,
    posDealerMapFailed,
} from './actions';
import { getList } from '../../helpers/fackBackend_Helper';


function* posDealer({ payload: { dealerPosId, dealerId, loginId } }) {
    try {
        const response = yield call(getList, '/posDealerMapping/'+ dealerPosId + '/' + dealerId + '/' + loginId);
        if (response.Status) {
            yield put(posDealerMapSuccessful(response.Message));
        }
        else {
            yield put(posDealerMapFailed(response.Message));
        }
    } catch (error) {
        yield put(posDealerMapFailed(error));
    }
}

export function* watchPosDealer() {
    yield takeEvery(POS_DEALER_MAP, posDealer)
}



function* getDealer({ payload: { channelId, acode } }) {
    try {
        const response = yield call(getList, '/getDealerByChannelIdAndAcode/'+ channelId + '/' + acode);
        if (response.Status) {
            yield put(getDealerByChannelIdAndAcodeSuccessful(response.Data));
        }
        else {
            yield put(getDealerByChannelIdAndAcodeFailed(response.Message));
        }
    } catch (error) {
        yield put(getDealerByChannelIdAndAcodeFailed(error));
    }
}

export function* watchGetDealer() {
    yield takeEvery(GET_DEALER_BY_CHANNEL_ID_AND_ACODE, getDealer)
}

function* getDealerPos({ payload: { channelId } }) {
    try {
        const response = yield call(getList, '/getPosDealer/'+ channelId);
        if (response.Status) {
            yield put(getDealerPosSuccessful(response.Data));
        }
        else {
            yield put(getDealerPosFailed(response.Message));
        }
    } catch (error) {
        yield put(getDealerPosFailed(error));
    }
}

export function* watchGetDealerPos() {
    yield takeEvery(GET_DEALER_POS, getDealerPos)
}

/*******************************************/


function* dealerPos() {
    yield all([
        fork(watchGetDealerPos),
        fork(watchGetDealer),
        fork(watchPosDealer),
    ]);
}

export default dealerPos;