import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { LOCATION_LIST, ASSET_LIST, ADD_ASSET, EDIT_ASSET, DELETE_ASSET, ADD_LOCATION, EDIT_LOCATION, DELETE_LOCATION, ASSET_REPORT, ASSET_REPORT_ASSET_ID, ASSET_RUNNING_STATUS_CHANGE } from './actionTypes';
import { assetApiError, assetListSuccessful, addAssetSuccess, deleteAssetSuccess, locationListSuccessful, addLocationSuccess, deleteLocationSuccess, assetReportSuccessFull, assetReportFailed, assetReportByAssetIDSuccessFull, assetReportByAssetIDFailed, assetRunningStatusChangeSuccess } from './actions';
import { getList, addData, editData, userDeleteData } from '../../helpers/fackBackend_Helper';

function* getLocationList() {
    try {
        const response = yield call(getList, '/getLocation');
        if (response.Status) {
            yield put(locationListSuccessful(response.locationData));
        }
        else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}

export function* watchGetLocationList() {
    yield takeEvery(LOCATION_LIST, getLocationList)
}

function* getAssetList({ payload: { companyId, channelId, assetType, Status } }) {
    try {
        const response = yield call(getList, `/getAsset/${companyId}/${channelId}/${assetType}/${Status}`);
        if (response.Status) {
            yield put(assetListSuccessful(response.assetData));
        }
        else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}

export function* watchGetAssetList() {
    yield takeEvery(ASSET_LIST, getAssetList)
}

function* addAsset({ payload: { asset, companyId, channelId, assetType, status } }) {
    try {
        const response = yield call(addData, '/asset', asset);
        if (response.Status) {
            const responseList = yield call(getList, '/getAsset/' + companyId + '/' + channelId + '/' + assetType + '/' + status);
            if (responseList.Status) {
                yield put(addAssetSuccess(responseList.assetData, response.Message))
            } else {
                yield put(assetApiError(responseList.Message));
            }
        } else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}

export function* watchAddAsset() {
    yield takeEvery(ADD_ASSET, addAsset)
}

function* addLocation({ payload: { location } }) {
    try {
        const response = yield call(addData, '/location', location);

        if (response.Status) {
            const responseList = yield call(getList, '/getLocation');
            if (responseList.Status) {
                yield put(addLocationSuccess(responseList.locationData, response.Message))
            } else {
                yield put(assetApiError(responseList.Message));
            }
        } else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}

export function* watchAddLocation() {
    yield takeEvery(ADD_LOCATION, addLocation)
}

function* editAsset({ payload: { asset, id, companyId, channelId, assetType, status } }) {
    try {
        const response = yield call(editData, '/asset/', asset, id);
        if (response.Status) {
            const responseList = yield call(getList, '/getAsset/' + companyId + '/' + channelId + '/' + assetType + '/' + status);
            if (responseList.Status) {
                yield put(addAssetSuccess(responseList.assetData, response.Message));
            }
            else {
                yield put(assetApiError(responseList.Message));
            }
        }
        else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}
export function* watchEditAsset() {
    yield takeEvery(EDIT_ASSET, editAsset)
}

function* editLocation({ payload: { location, id } }) {
    try {
        const response = yield call(editData, '/location/', location, id);
        if (response.Status) {
            const responseList = yield call(getList, '/getLocation');
            if (responseList.Status) {
                yield put(addLocationSuccess(responseList.locationData, response.Message));
            }
            else {
                yield put(assetApiError(responseList.Message));
            }
        }
        else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}
export function* watchEditLocation() {
    yield takeEvery(EDIT_LOCATION, editLocation)
}

function* deleteAsset({ payload: { id, status, deleteAssetData, companyId, channelId, assetType } }) {
    try {
        const response = yield call(userDeleteData, '/asset/' + id + '/' + status, deleteAssetData);
        if (response.Status) {
            const responseList = yield call(getList, '/getAsset/' + companyId + '/' + channelId + '/' + assetType + '/2');

            if (responseList.Status) {
                yield put(deleteAssetSuccess(responseList.assetData, response.Message));
            }
            else {
                yield put(assetApiError(responseList.Message));
            }
        }
        else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}

export function* watchDeleteAsset() {
    yield takeEvery(DELETE_ASSET, deleteAsset)
}

function* deleteLocation({ payload: { id, status, deleteLocData } }) {
    try {
        const response = yield call(userDeleteData, '/location/' + id + '/' + status, deleteLocData);
        if (response.Status) {
            const responseList = yield call(getList, '/getLocation');
            if (responseList.Status) {
                yield put(deleteLocationSuccess(responseList.locationData, response.Message));
            }
            else {
                yield put(assetApiError(responseList.Message));
            }
        }
        else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}

export function* watchDeleteLocation() {
    yield takeEvery(DELETE_LOCATION, deleteLocation)
}

function* getAssetReportResponse({ payload: { companyId } }) {
    try {
        const response = yield call(getList, '/assetReport/' + companyId);
        if (response.Status) {
            yield put(assetReportSuccessFull(response.assetData));
        }
        else {
            yield put(assetReportFailed(response.Message));
        }
    } catch (error) {
        yield put(assetReportFailed(error));
    }
}

export function* watchGetAssetReport() {
    yield takeEvery(ASSET_REPORT, getAssetReportResponse)
}

function* getAssetReportByAssetIdResponse({ payload: { searchData } }) {
    try {
        const response = yield call(addData, '/getAssetReportByAssetID', searchData);
        if (response.Status) {
            yield put(assetReportByAssetIDSuccessFull(response.assetData));
        }
        else {
            yield put(assetReportByAssetIDFailed(response.Message));
        }
    } catch (error) {
        yield put(assetReportByAssetIDFailed(error));
    }
}

export function* watchGetAssetReportByAssetId() {
    yield takeEvery(ASSET_REPORT_ASSET_ID, getAssetReportByAssetIdResponse)
}

function* assetRunningStatusChangeResponse({ payload: { id, status, deleteAssetData, companyId, channelId, assetType } }) {
    try {
        const response = yield call(userDeleteData, '/updateAssetRunningStatus/' + id + '/' + status, deleteAssetData);
        if (response.Status) {
            const responseList = yield call(getList, '/getAsset/' + companyId + '/' + channelId + '/' + assetType + '/2');

            if (responseList.Status) {
                yield put(assetRunningStatusChangeSuccess(responseList.assetData, response.Message));
            }
            else {
                yield put(assetApiError(responseList.Message));
            }
        }
        else {
            yield put(assetApiError(response.Message));
        }
    } catch (error) {
        yield put(assetApiError(error));
    }
}

export function* watchAssetRunningStatusChange() {
    yield takeEvery(ASSET_RUNNING_STATUS_CHANGE, assetRunningStatusChangeResponse)
}

function* assetSaga() {
    yield all([
        fork(watchGetLocationList),
        fork(watchGetAssetList),
        fork(watchAddAsset),
        fork(watchAddLocation),
        fork(watchEditAsset),
        fork(watchEditLocation),
        fork(watchDeleteAsset),
        fork(watchDeleteLocation),
        fork(watchGetAssetReport),
        fork(watchGetAssetReportByAssetId),
        fork(watchAssetRunningStatusChange),
    ]);
}

export default assetSaga;