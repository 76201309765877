import { ASSET_LIST, ASSET_LIST_SUCCESSFUL, ASSET_API_ERROR, ADD_ASSET, ADD_ASSET_SUCCESS, RESET_ASSET_ALERT, EDIT_ASSET, DELETE_ASSET, DELETE_ASSET_SUCCESS, LOCATION_LIST, LOCATION_LIST_SUCCESSFUL, ADD_LOCATION, ADD_LOCATION_SUCCESS, EDIT_LOCATION, DELETE_LOCATION, DELETE_LOCATION_SUCCESS, ASSET_REPORT, ASSET_REPORT_SUCCESS, ASSET_REPORT_FAILED, ASSET_REPORT_ASSET_ID, ASSET_REPORT_ASSET_ID_SUCCESS, ASSET_REPORT_ASSET_ID_FAILED, ASSET_RUNNING_STATUS_CHANGE, ASSET_RUNNING_STATUS_CHANGE_SUCCESS } from "./actionTypes"

export const assetList = (companyId, channelId, assetType, Status) => {
    return {
        type: ASSET_LIST,
        payload: { companyId, channelId, assetType, Status }
    }
}

export const assetListSuccessful = (assetData) => {
    return {
        type: ASSET_LIST_SUCCESSFUL,
        payload: assetData
    }
}

export const assetApiError = (error) => {
    return {
        type: ASSET_API_ERROR,
        payload: error
    }
}

export const addAsset = (asset, companyId,channelId,assetType,status) => {
    return {
        type: ADD_ASSET,
        payload: { asset, companyId,channelId,assetType,status }
    }
}

export const addAssetSuccess = (assetData, message) => {
    return {
        type: ADD_ASSET_SUCCESS,
        payload: { assetData, message }
    }
}

export const editAsset = (asset, id, companyId,channelId,assetType,status) => {
    return {
        type: EDIT_ASSET,
        payload: { asset, id, companyId,channelId,assetType,status }
    }
}

export const deleteAsset = (id, status, deleteAssetData, companyId, channelId, assetType) => {
    return {
        type: DELETE_ASSET,
        payload: { id, status, deleteAssetData, companyId, channelId, assetType }
    }
}

export const deleteAssetSuccess = (assetData, message) => {
    return {
        type: DELETE_ASSET_SUCCESS,
        payload: { assetData, message }
    }
}

export const resetAssetAlert = () => {
    return {
        type: RESET_ASSET_ALERT,
    }
}

export const locationList = () => {
    return {
        type: LOCATION_LIST,
    }
}

export const addLocation = (location) => {
    return {
        type: ADD_LOCATION,
        payload: { location }
    }
}

export const editLocation = (location, id) => {
    return {
        type: EDIT_LOCATION,
        payload: { location, id }
    }
}

export const deleteLocation = (id, status, deleteLocData) => {
    return {
        type: DELETE_LOCATION,
        payload: { id, status, deleteLocData }
    }
}

export const locationListSuccessful = (locationData) => {
    return {
        type: LOCATION_LIST_SUCCESSFUL,
        payload: locationData
    }
}

export const addLocationSuccess = (locationData, message) => {
    return {
        type: ADD_LOCATION_SUCCESS,
        payload: { locationData, message }
    }
}

export const deleteLocationSuccess = (locationData, message) => {
    return {
        type: DELETE_LOCATION_SUCCESS,
        payload: { locationData, message }
    }
}

export const assetReport = (companyId) => {
    return {
        type: ASSET_REPORT,
        payload: { companyId }
    }
}

export const assetReportSuccessFull = (assetReportData) => {
    return {
        type: ASSET_REPORT_SUCCESS,
        payload: assetReportData
    }
}

export const assetReportFailed = (error) => {
    return {
        type: ASSET_REPORT_FAILED,
        payload: error
    }
}

export const assetReportByAssetID = (searchData) => {
    return {
        type: ASSET_REPORT_ASSET_ID,
        payload: { searchData }
    }
}

export const assetReportByAssetIDSuccessFull = (eachAssetReportData) => {
    return {
        type: ASSET_REPORT_ASSET_ID_SUCCESS,
        payload: eachAssetReportData
    }
}

export const assetReportByAssetIDFailed = (error) => {
    return {
        type: ASSET_REPORT_ASSET_ID_FAILED,
        payload: error
    }
}

export const assetRunningStatusChange = (id, status, deleteAssetData, companyId, channelId, assetType) => {
    return {
        type: ASSET_RUNNING_STATUS_CHANGE,
        payload: { id, status, deleteAssetData, companyId, channelId, assetType }
    }
}

export const assetRunningStatusChangeSuccess = (assetData, message) => {
    return {
        type: ASSET_RUNNING_STATUS_CHANGE_SUCCESS,
        payload: { assetData, message }
    }
}

