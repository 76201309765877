import { ASSIGN_DEALER_ACTIVATION, ASSIGN_DEALER_ACTIVATION_SUCCESSFULL, ASSIGN_DEALER_ACTIVATION_FAILED, RESET_ASSIGN_DEALER } from "./actionTypes";

export const assignDealerToActivation = (channelId, assignData) => {
    return {
        type: ASSIGN_DEALER_ACTIVATION,
        payload: { channelId, assignData }
    }
}

export const assignDealerToActivationSuccess = (assignResponse) => {
    return {
        type: ASSIGN_DEALER_ACTIVATION_SUCCESSFULL,
        payload: { assignResponse }
    }
}

export const assignDealerToActivationFailed = (error) => {
    return {
        type: ASSIGN_DEALER_ACTIVATION_FAILED,
        payload: error
    }
}

export const resetAssignDealer = () => {
    return {
        type: RESET_ASSIGN_DEALER,
    }
}