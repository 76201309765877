import { UPLOAD_JAR_FILE,UPLOAD_JAR_FILE_SUCCESSFUL, UPLOAD_JAR_FILE_FAIL,GET_JAR_FILE,GET_JAR_FILE_SUCCESSFUL,GET_JAR_FILE_FAIL } from "./actionTypes"


export const uploadJarFile = (data) => {
    return {
        type: UPLOAD_JAR_FILE,
        payload: data
    }
}

export const uploadJarFileSuccessfull = (data) => {
    return {
        type: UPLOAD_JAR_FILE_SUCCESSFUL,
        payload: data
    }
}

export const uploadJarFileFail = (apiError) => {
    return {
        type: UPLOAD_JAR_FILE_FAIL,
        payload: apiError
    }
}

export const getJarFile = () => {
    return {
        type: GET_JAR_FILE,
    }
}

export const getJarFileSuccessfull = (data) => {
    return {
        type: GET_JAR_FILE_SUCCESSFUL,
        payload: data
    }
}

export const getJarFileFail = (apiError) => {
    return {
        type: GET_JAR_FILE_FAIL,
        payload: apiError
    }
}