import React from 'react';
import { Link } from 'react-router-dom';

export default function AccessDenied() {
  return (
    <div className="nk-content ">
      <div className="nk-block nk-block-middle wide-xs mx-auto">
        <div className="nk-block-content nk-error-ld text-center">
          <h1 className="nk-error-head">403</h1>
          <h3 className="nk-error-title">Access Denied</h3>
          <p className="nk-error-text">You do not have permission to access requested page.<br></br>Please check your credentials and try again.</p><Link to="/dashboard"
            className="btn btn-lg btn-primary mt-2">Back To Home</Link>
        </div>
      </div>
    </div>
  )
}
