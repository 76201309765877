import React, { createContext, useState, useContext } from 'react';
const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
    const [dashboardData, setDashboardData] = useState(null);

    return (
        <DataContext.Provider value={{ dashboardData, setDashboardData }}>
            {children}
        </DataContext.Provider>
    );
};