import { USER_LOGOUT, USER_LOGOUT_FAILED, USER_LOGOUT_SUCCESS, CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESSFUL, API_PASSWORD_ERROR, RESET_PASSWORD_ALERT, JAR_STATUS_UPDATE, JAR_STATUS_UPDATE_SUCCESS, GET_TO_SHOW_MARQUEE, GET_TO_SHOW_MARQUEE_SUCCESSFULL, MARQUEE_API_ERROR, VALIDATE_TOKEN, VALIDATE_TOKEN_SUCCESS, VALIDATE_TOKEN_FAILED, GET_COMPANY, GET_COMPANY_SUCCESS, GET_COMPANY_FAILED, RESET_HEADER_MESSAGE,T_MOBILE_STATUS_UPDATE,T_MOBILE_STATUS_UPDATE_SUCCESS, T_MOBILE_STATUS_UPDATE_FAILED, CLOSE_TMOBILE_STATUS_ERROR_MODAL } from "./actionTypes";

export const userLogout = (userId) => {
    return {
        type: USER_LOGOUT,
        payload: { userId }
    }
}

export const userLogoutSuccess = () => {
    return {
        type: USER_LOGOUT_SUCCESS,
    }
}

export const userLogoutFailed = (error) => {
    return {
        type: USER_LOGOUT_FAILED,
        payload: error
    }
}

export const changePassword = (passwordData) => {
    return {
        type: CHANGE_PASSWORD,
        payload: { passwordData }
    }
}

export const changePasswordSuccessful = (Message) => {
    return {
        type: CHANGE_PASSWORD_SUCCESSFUL,
        payload: Message
    }
}

export const apiPasswordError = (error) => {
    return {
        type: API_PASSWORD_ERROR,
        payload: error
    }
}

export const resetPasswordAlert = () => {
    return {
        type: RESET_PASSWORD_ALERT,
    }
}

export const jarStatusUpdate = (companyId, status, loginId) => {
    return {
        type: JAR_STATUS_UPDATE,
        payload: { companyId, status, loginId }
    }
}

export const jarStatusUpdateSuccessful = (Message) => {
    return {
        type: JAR_STATUS_UPDATE_SUCCESS,
        payload: Message
    }
}

export const getToShowMarquee = (companyId) => {
    return {
        type: GET_TO_SHOW_MARQUEE,
        payload: companyId
    }
}

export const getToShowMarqueeSuccessfull = (notification) => {
    return {
        type: GET_TO_SHOW_MARQUEE_SUCCESSFULL,
        payload: notification
    }
}

export const marqueeApiError = (error) => {
    return {
        type: MARQUEE_API_ERROR,
        payload: error
    }
}

export const validateToken = () => {
    return {
        type: VALIDATE_TOKEN,
    }
}

export const validateTokenSuccess = (Message) => {
    return {
        type: VALIDATE_TOKEN_SUCCESS,
        payload: Message
    }
}

export const validateTokenFailure = (error) => {
    return {
        type: VALIDATE_TOKEN_FAILED,
        payload: error
    }
}
export const getCompany=(companyId)=>{
    return{
        type: GET_COMPANY,
        payload: companyId
    }
}
export const getCompanyDataSuccessfull=(companyData)=>{
    return{
        type: GET_COMPANY_SUCCESS,
        payload: companyData
    }
}

export const getCompanyDataFailed=(error)=>{
    return{
        type: GET_COMPANY_FAILED,
        payload: error
    }
}
export const resetHeaderMessage = () =>{
    return {
        type:RESET_HEADER_MESSAGE
    }
}


export const tMobileStatusUpdate = (companyId, status, loginId) => {
    return {
        type: T_MOBILE_STATUS_UPDATE,
        payload: { companyId, status, loginId }
    }
}

export const tMobileStatusUpdateSuccessful = (Message) => {
    return {
        type: T_MOBILE_STATUS_UPDATE_SUCCESS,
        payload: Message
    }
}

export const tMobileStatusUpdateFailed = (error) => {
    return {
        type: T_MOBILE_STATUS_UPDATE_FAILED,
        payload: error
    }
}

export const closeTmobileStatusErrorModal = (error) => {
    return {
        type: CLOSE_TMOBILE_STATUS_ERROR_MODAL,
    }
}