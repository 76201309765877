import { VIEW_ACTIVATION_LIST_SUCCESSFULL, VIEW_ACTIVATION_LIST, VIEW_ACTIVATION_LIST_FAILED, RESET_ALERT_ACTIVATION, COMPELETE_ACTIVATION, COMPELETE_ACTIVATION_SUCCESSFULL, GET_ERROR_CODE, GET_ERROR_CODE_SUCCESSFULL, DATA_SUBMIT_FAILED, REMARK_CANCEL, REMARK_CANCEL_SUCCESSFULL, ACTIVATION_REQUEST_INFO, ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL, LOCK_ACTIVATION, LOCK_ACTIVATION_RESULT, LOCK_ACTIVATION_FAILED_RESULT, UNLOCK_ACTIVATION, UNLOCK_ACTIVATION_SUCCESS, RESET_FLAG_ALERT, ACCESS_TRANSACTION_MODAL, ACCESS_TRANSACTION_MODAL_SUCCESS,CLOSE_ACCESS_TRANSACTION_MODAL, ASSIGN_ACTIVATION, ASSIGN_ACTIVATION_SUCCESSFULL, UPDATE_REFUND_REQUEST, UPDATE_REFUND_REQUEST_SUCCESSFULL, UPDATE_REFUND_REQUEST_FAILED, MESSAGE_ALERT_HIDE, CLOSE_ACTIVATION_CANCEL_MODAL, NOTIFY_POS, NOTIFY_POS_SUCCESSFULL, NOTIFY_POS_FAILED } from "./actionTypes";

// , REPUSH_ACTIVATION, REPUSH_ACTIVATION_SUCCESS, REPUSH_ACTIVATION_FAILED

const initialState = {
    apiError: null,
    loading: false,
    message: null,
    messageModal: null,
    apiErrorModal: null,
    viewActivation: [],
    userData: [],
    modalFlag: false,
    cancelModal: false,
    activationRequestHistory: [],
    // assign to care
    assignToCareLoading: false,
    assignToCareMessage: null,
    assignToCareError: null,
    // notify POS
    notifyPosLoading: false,
    notifyPosError: null,
    notifyPosMessage: null,
    modalViewLoader: false,
    showTransactionModal:false,
}

const View = (state = initialState, action) => {
    let activationListNewState;
    switch (action.type) {
        case NOTIFY_POS:
            activationListNewState = state.viewActivation.map((item) => {
                let temp = item;
                if (item.ID == action.payload.activationID) {
                    temp.notifyPosLoading = !temp.notifyPosLoading;
                }
                return temp;
            });

            state = {
                ...state,
                notifyPosLoading: true,
                notifyPosError: null,
                notifyPosMessage: null,
                viewActivation: activationListNewState
            }
            break;

        case NOTIFY_POS_SUCCESSFULL:
            activationListNewState = state.viewActivation.map((item) => {
                let temp = item;
                if (item.ID == action.payload.activationID) {
                    temp.notifyPosLoading = !temp.notifyPosLoading;
                }
                return temp;
            });
            state = {
                ...state,
                notifyPosLoading: false,
                notifyPosError: null,
                notifyPosMessage: action.payload.message,
                viewActivation: activationListNewState
            }
            break;

        case NOTIFY_POS_FAILED:
            activationListNewState = state.viewActivation.map((item) => {
                let temp = item;
                if (item.ID == action.payload.activationID) {
                    temp.notifyPosLoading = !temp.notifyPosLoading;
                }
                return temp;
            });
            state = {
                ...state,
                notifyPosLoading: false,
                notifyPosError: action.payload.error,
                notifyPosMessage: null,
                viewActivation: activationListNewState
            }
            break;


        case VIEW_ACTIVATION_LIST:

            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null,
                messageModal: null,
                apiErrorModal: null,
                viewActivation: [],
                modalFlag: false,
                buttonLoading: false,
                assignToCareMessage: null,
                assignToCareError: null,
                notifyPosError: null,
                notifyPosMessage: null,
            }
            break;

        case VIEW_ACTIVATION_LIST_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                apiError: null,
                viewActivation: action.payload.viewActivation,
                buttonLoading: false
            }
            break;

        case VIEW_ACTIVATION_LIST_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                message: null,
                messageModal: null,
                apiErrorModal: null,
                buttonLoading: false,
                modalViewLoader: false
            }
            break;

        case RESET_ALERT_ACTIVATION:
            state = {
                ...state,
                loading: false,
                message: null,
                apiError: null,
                messageModal: null,
                apiErrorModal: null,
                modalFlag: false,
                LockFailAlert: false,
                // repushError: null,
                // repushMessage: null,
                modalFlag: false,
                buttonLoading: false,
                requestData: null
            }
            break;
        case RESET_FLAG_ALERT:
            state = {
                ...state,
                loading: false,
                LockFailAlert: false,
                locksuccessAlert: false,
                modalFlag: false,
                buttonLoading: false,
                requestData: null
            }
            break;

        case COMPELETE_ACTIVATION:
            state = {
                ...state,
                buttonLoading: true
            }
            break;

        case COMPELETE_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                messageModal: action.payload.message,
                loading: false,
                apiErrorModal: null,
                apiError: null,
                viewActivation: action.payload.activations,
                buttonLoading: false
            }
            break;

        case GET_ERROR_CODE:
            activationListNewState = state.viewActivation.map((item) => {
                let temp = item;
                if (item.ID == action.payload.activationId) {
                    temp.cancelModalLoading = !temp.cancelModalLoading;
                }
                return temp;
            });
            state = {
                ...state,
                loading: false,
                buttonLoading: false,
                viewActivation: activationListNewState
            }
            break;

        case GET_ERROR_CODE_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                errorList: action.payload,
                apiError: null,
                buttonLoading: false,
                cancelModal: true,
            }
            break;

        case REMARK_CANCEL:
            state = {
                ...state,
                buttonLoading: true,
            }
            break;

        case REMARK_CANCEL_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                messageModal: action.payload.message,
                apiError: null,
                apiErrorModal: null,
                viewActivation: action.payload.activations,
                buttonLoading: false
            }
            break;

        case DATA_SUBMIT_FAILED:
            state = {
                ...state,
                loading: false,
                apiErrorModal: action.payload,
                buttonLoading: false
            }
            break;

        case ACTIVATION_REQUEST_INFO:
            state = {
                ...state,
                modalFlag: false,
                buttonLoading: false
            }
            break;

        case ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL:
            state = {
                ...state,
                activationRequestHistory: action.payload,
                modalFlag: true,
                buttonLoading: false
            }
            break;

        case ACCESS_TRANSACTION_MODAL:
            state = {
                ...state,
                requestData: null,
                modalViewLoader:true,
                modalFlag: false,
                showTransactionModal:true,
            }
            break;

        case ACCESS_TRANSACTION_MODAL_SUCCESS:
            state = {
                ...state,
                requestData: action.payload,
                locksuccessAlert: true,
                modalViewLoader:false,
                showTransactionModal:true,
            }
            break;

            case CLOSE_ACCESS_TRANSACTION_MODAL:
            state = {
                ...state,
                requestData: null,
                modalViewLoader:false,
                modalFlag: false,
                showTransactionModal:false,
            }
            break;

        case LOCK_ACTIVATION:
            activationListNewState = state.viewActivation.map((item) => {
                let temp = item;
                if (item.ID == action.payload.lockActivationData.activationId) {
                    temp.lockLoading = !temp.lockLoading;
                }
                return temp;
            });

            state = {
                ...state,
                viewActivation: activationListNewState
            }
            break;

        case LOCK_ACTIVATION_RESULT:
            state = {
                ...state,
                Lockmessage: action.payload.Message,
                viewActivation: action.payload.activationData,
                locksuccessAlert: true,
                buttonLoading: false
            }
            break;

        case LOCK_ACTIVATION_FAILED_RESULT:
            state = {
                ...state,
                LockFailAlert: true,
                LockedFailedmessage: action.payload.Message,
                LockedByUser: action.payload.LockedByUser,
                buttonLoading: false
            }
            break;

        case UNLOCK_ACTIVATION:
            activationListNewState = state.viewActivation.map((item) => {
                let temp = item;
                if (item.ID == action.payload.unLockData.activationId) {
                    temp.releaseLoading = !temp.releaseLoading;
                }
                return temp;
            });
            state = {
                ...state,
                viewActivation: activationListNewState
            }
            break;

        case UNLOCK_ACTIVATION_SUCCESS:
            state = {
                ...state,
                unLockMessage: action.payload,
                viewActivation: action.payload.activationData,
                buttonLoading: false
            }
            break;
        case ASSIGN_ACTIVATION:
            state = {
                ...state,
                assignToCareLoading: true,
                assignToCareMessage: null,
                assignToCareError: null,
            }
            break;
        case ASSIGN_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                assigned: action.payload.assigned,
                viewActivation: action.payload.activationData,
                buttonLoading: false,
                assignToCareLoading: false,
                assignToCareMessage: action.payload.assigned,
                assignToCareError: null,
            }
            break;
        case UPDATE_REFUND_REQUEST:
            state = {
                ...state,
                buttonLoading: true
            }
            break;
        case UPDATE_REFUND_REQUEST_SUCCESSFULL:
            state = {
                ...state,
                messageModal: action.payload.message,
                apiErrorModal: null,
                apiError: null,
                viewActivation: action.payload.activations,
                buttonLoading: false
            }
            break;
        case UPDATE_REFUND_REQUEST_FAILED:
            state = {
                ...state,
                apiErrorModal: action.payload,
                buttonLoading: false
            }
            break;
        // case REPUSH_ACTIVATION:
        //     state = {
        //         ...state,
        //         loading: true
        //     }
        //     break;
        // case REPUSH_ACTIVATION_SUCCESS:
        //     state = {
        //         ...state,
        //         repushMessage: action.payload.message,
        //         apiErrorModal: null,
        //         loading: false,
        //         apiError: null,
        //         viewActivation: action.payload.activations,
        //     }
        //     break;
        // case REPUSH_ACTIVATION_FAILED:
        //     state = {
        //         ...state,
        //         loading: false,
        //         repushError: action.payload
        //     }
        //     break;
        case MESSAGE_ALERT_HIDE:
            state = {
                ...state,
                // repushError: null,
                // repushMessage: null,
                modalFlag: false,
                buttonLoading: false,
                requestData: null
            }
            break;

        case CLOSE_ACTIVATION_CANCEL_MODAL:
            activationListNewState = state.viewActivation.map((item) => {
                let temp = item;
                temp.cancelModalLoading = false;
                return temp;
            });

            state = {
                ...state,
                cancelModal: false,
                viewActivation: activationListNewState
            }
            break;

        default:
            state = { ...state };
    }
    return state;
}

export default View;