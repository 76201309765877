import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList,uploadFile } from '../../helpers/fackBackend_Helper';

import { uploadJarFileFail, uploadJarFileSuccessfull,getJarFileSuccessfull,getJarFileFail,getJarFile } from './actions';
import { UPLOAD_JAR_FILE,GET_JAR_FILE } from './actionTypes';

function* uploadJarFileFunction({ payload: data }) {
    try {
        const formData = new FormData();
        formData.append('jarfile', data.file);
        formData.append('jarVersion', data.jarVersion);
        formData.append('jarRemark', data.remark);

        const response = yield call(uploadFile, '/uploadJarFile/'+data.loginID, formData);
        if (response.Status) {
            yield put(uploadJarFileSuccessfull(response.Message));
            yield put(getJarFile());
        }
        else {
            yield put(uploadJarFileFail(response.Message));
        }
    } catch (error) {
        yield put(uploadJarFileFail(error));
    }
}

function* watchUploadJarFile() {
    yield takeEvery(UPLOAD_JAR_FILE, uploadJarFileFunction)
}

function* getJarFileFunction() {
  try {
    const response = yield call(getList, "/getUploadedJar");
    if (response.Status) {
      yield put(getJarFileSuccessfull(response.file));
    } else {
      yield put(getJarFileFail(response.Message));
    }
  } catch (error) {
    yield put(getJarFileFail('error in api'));
  }
}

function* watchGetJarFileFunction() {
    yield takeEvery(GET_JAR_FILE, getJarFileFunction)
}

function* uploadJarSaga() {
    yield all([
        fork(watchUploadJarFile),
        fork(watchGetJarFileFunction),
    ]);
}

export default uploadJarSaga;