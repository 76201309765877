export const REPUSH_ACTIVATION_LIST = "REPUSH_ACTIVATION_LIST";
export const REPUSH_ACTIVATION_SUCCESSFULL = "REPUSH_ACTIVATION_SUCCESSFULL";
export const REPUSH_ACTIVATION_FAILED = "REPUSH_ACTIVATION_FAILED";

export const REPUSH_ACTIVATION = "REPUSH_ACTIVATION";
export const REPUSH_ACTIVATION_ADD_SUCCESSFULL = "REPUSH_ACTIVATION_ADD_SUCCESSFULL";

export const ACTIVATION_REQUEST_INFO = "ACTIVATION_REQUEST_INFO";
export const MESSAGE_ALERT_HIDE = "MESSAGE_ALERT_HIDE";
export const RESET_ALERT_ACTIVATION = "RESET_ALERT_ACTIVATION";
export const GET_ERROR_CODE = "GET_ERROR_CODE";
export const ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL = "ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL";
export const VIEW_ACTIVATION_LIST_FAILED = "VIEW_ACTIVATION_LIST_FAILED";
export const GET_ACTIVATION_CHILD_LIST = 'GET_ACTIVATION_CHILD_LIST';
export const GET_ACTIVATION_CHILD_LIST_SUCCESSFUL = 'GET_ACTIVATION_CHILD_LIST_SUCCESSFUL';
export const GET_ACTIVATION_CHILD_LIST_FAIL = 'GET_ACTIVATION_CHILD_LIST_FAIL';
export const RESET_CHILD_ACTIVATION_MODAL = 'RESET_CHILD_ACTIVATION_MODAL';
