import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import FilterComponent from "./FilterComponent";
import moment from "moment";
import Spinner from '../components/layout/Spinner';
import DataTable from 'react-data-table-component';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { assetList } from "../store/asset/actions";
import { resetAssetPerformanceActivation, searchAssetPerformanceActivationData } from '../store/assetPerformanceReport/actions';
import ReactApexChart from "react-apexcharts";

function AssetPerformanceReport(props) {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const dispatch = useDispatch();
  const timezone = process.env.REACT_APP_TIMEZONE;
  const [filterText, setFilterText] = useState("");
  const [fDate, setFdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
  const [tDate, setTdate] = useState(new Date())
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [searchData, setSearchData] = useState({
    companyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyID : 0,
    dealerId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : 0,
    assetName: "",
    assetId: '',
    fromDate: fDate,
    toDate: tDate,
  });
 

  useEffect(() => {
    const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
    const dealerId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : null;
    props.assetList(companyId, dealerId, 0, 2);
    //companyId, channelId, assetType, Status
    props.resetAssetPerformanceActivation();
  }, [])

  const columns = [
    {
      name: "#",
      width: "50px",
      selector: row => row.serial,
    },
    {
      name: 'ASSET NAME',
      width: "350px",
      selector: row => row.assetName,
    },
    {
      name: 'COUNT',
      selector: row => row.count,
    }
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '5px', // override the cell padding for head cells
        paddingRight: '5px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '5px', // override the cell padding for data cells
        paddingRight: '5px',
      },
    },
  };

  const filteredItems = props.assetPerformanceActivationData && props.assetPerformanceActivationData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  var filterData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: key + 1,
      assetName: data.JARServerName,
      count: data.CountData
    }
  })

  const listData = props.assetPerformanceActivationData && props.assetPerformanceActivationData.map((data, key) => {
    return {
      SNo: key + 1,
      ASSETNAME: data.JARServerName,
      COUNT: data.CountData
    }
  });

  const handleAssetChange = (e) => {
    if (e.target.value != 0) {
      const selectedAsset = props.assetData && props.assetData.filter((item) => item.ID == e.target.value);
      setSearchData(prevState => ({
        ...prevState,
        assetId: e.target.value,
        assetName: selectedAsset[0].AssetName
      }));
    } else {
      setSearchData(prevState => ({
        ...prevState,
        assetId: "",
        assetName: ""
      }));

    }

  }

  const handleToDateChange = (d) => {
    setTdate(d);
    setSearchData(prevState => ({
      ...prevState,
      toDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
    }));
  }

  const handleFromDateChange = (d) => {
    setFdate(d);
    setSearchData(prevState => ({
      ...prevState,
      fromDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
    }))
  }

  const handleSearchData = (e) => {
    e.preventDefault();
    let searchDataManage = searchData;
    searchDataManage.fromDate = moment(searchDataManage.fromDate).format('YYYY-MM-DD');
    searchDataManage.toDate = moment(searchDataManage.toDate).format('YYYY-MM-DD');
    dispatch(searchAssetPerformanceActivationData(searchDataManage));
  }

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  }

  const exportToCSV = async () => {
    if (listData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(listData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      await FileSaver.saveAs(data, 'AssetPerformanceReport' + fileExtension);
    }
  }

  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }

  var assetNameData = [];
  var assetCountData = [];

  props.assetPerformanceActivationData && props.assetPerformanceActivationData.map((data, key) => {

    const modifiedAssetName = data.JARServerName !== null && data.JARServerName !== "" && data.JARServerName.slice(0, 20);

    assetNameData.push(modifiedAssetName);
    assetCountData.push(data.CountData);
  })

  const options = {
    chart: {
      width: '100%',
      height: 300,
      type: 'pie',
    },
    labels: assetNameData,
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 400
        },
        legend: {
          position: 'bottom', // Adjust legend position
          fontSize: '2px',  // Adjust legend font size
        }
      }
    }]
  }

  const series = assetCountData

  return (
    <>
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">Asset Performance Report</h3>
        <div className='card p-2'>
          <div className="card-inner p-0" >
            <div className="nk-block">
              <form onSubmit={handleSearchData} method="post">
                <div className="row g-3">
                  <div className="col-sm-3 col-md-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="agentId">Asset</label>
                      <div className="form-control-wrap">
                        <select
                          name="assetId"
                          className="form-control"
                          id="assetId"
                          onChange={(e) => handleAssetChange(e)}
                        // value={searchData.assetId}
                        >
                          <option value="" key="0" name="select">Select</option>
                          {/*{props.assetLoading === true ? <option value="" key="0" name="select">
                          <div className="text-center"><div className="spinner-border text-primary m-5" role="status">
                          <span className="visually-hidden">Loading...</span> </div></div></option> : 
                          props.assetData && props.assetData.map(asset => (
                            <option key={asset.ID} value={asset.ID}>{asset.AssetName} - {asset.LocationName}</option>
                          ))}*/}
                           {props.assetData && props.assetData.map(asset => (
                            <option key={asset.ID} value={asset.ID}>{asset.AssetName}</option>
                          ))} 
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-3">
                    <div className="form-group"><label className="form-label" htmlFor="fromDate">From Date</label>
                      <div className="form-control-wrap">
                        <DatePicker onChange={(d) => handleFromDateChange(d)} className={"form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="fromDate" closeOnScroll={true} selected={fDate} placeholderText="MM/DD/YYYY" />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-3">
                    <div className="form-group"><label className="form-label" htmlFor='toDate'>To Date</label>
                      <div className="form-control-wrap">
                        <DatePicker onChange={(d) => handleToDateChange(d)} className={"form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="toDate" closeOnScroll={true} selected={tDate} placeholderText="MM/DD/YYYY" />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-2 col-md-2'>
                    <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                      className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                      <span>Search</span> </button>
                    {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {props.showAssetPerformancePage && <div className="nk-block ">
          <div className="row">
            <div className='col-md-6 col-sm-6 col-xs-12 col-lg-6'>
              <div className="card my-2" style={{ height: '382px' }}>
                <div className="card-inner p-2  manual-activation-card">
                  <div className='d-flex my-2 justify-content-between'>
                    <FilterComponent
                      onFilter={e => setFilterText(e.target.value)}
                      onClear={handleClear}
                      filterText={filterText}
                    /><Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                  </div>
                  <DataTable
                    pagination={false}
                    columns={columns}
                    data={filterData}
                    customStyles={customStyles}
                    progressPending={props.loading}
                    progressComponent={<Spinner />}
                    responsive
                    striped
                    fixedHeader
                    className="manual-activation-datatable" />

                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-6 col-xs-12 col-lg-6'>
              <div className="card my-2" style={{ height: '382px' }}>
                <div className="card-inner p-0 manual-activation-card">
                  <ReactApexChart options={options} series={series} type="pie" width={500} />
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { assetData, assetLoading } = state.asset;
  const { apiError, loading, assetPerformanceActivationData, showAssetPerformancePage } = state.assetPerformanceReport

  return { assetData, assetLoading, apiError, loading, assetPerformanceActivationData, showAssetPerformancePage };
};
export default withRouter(connect(mapStateToProps, { assetList, resetAssetPerformanceActivation, searchAssetPerformanceActivationData })(AssetPerformanceReport));