import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_ALL_CHANNEL_LIST, DEALER_LIST, COUNTRY_LIST, STATE_LIST, ADD_DEALER, EDIT_DEALER, DELETE_DEALER, CHANNEL_NAME, DEALER_FLAG_UPDATE, NON_VERIFIED_DEALER_LIST, VERIFY_DEALER, CHANGE_VERIFY_USER_PASSWORD, GET_DEALER_SUSPENSION_LOG, GET_DEALER_CONFIG, UPDATE_DEALER_CONFIG, GET_OTHERS_ALLOWED_DURATION,UPDATE_DEALER_EMAIL} from './actionTypes';
import { getAllChannelListSuccessfull, dealerApiError, dealerListSuccessful, countryListSuccess, stateListSuccessfull, addDealerSuccess, dealerApiErrorModal, deleteDealerSuccess, ChannelNameSuccess, dealerFlagUpdateSuccessfull, nonVerifiedDealerListSuccessful, verifyDealerSuccess, updateVerifyUserPasswordChangeSuccessfully, getDealerSuspensionLogSuccessful, getDealerSuspensionLogFailed,
getDealerConfigSuccessful, getDealerConfigFailed, updateDealerConfigSuccessful, updateDealerConfigFailed, getOthersAllowedDurationSuccessful, getOthersAllowedDurationFailed,updateDealeremailSuccessful,updateDealeremailFailed} from './actions';
import { getList, addData, editData, userDeleteData } from '../../helpers/fackBackend_Helper';

function* getAllChannel({ payload: dealerId }) {
    try {
        const response = yield call(getList, '/getChannelName/' + dealerId);
        if (response.Status) {
            yield put(getAllChannelListSuccessfull(response.channel));
        }
        else {
            yield put(dealerApiError(response.Message));
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchChannelActivation() {
    yield takeEvery(GET_ALL_CHANNEL_LIST, getAllChannel)
}

function* getDealerList({ payload: { companyId, channelId, status } }) {
    try {
        const response = yield call(getList, '/dealers/' + companyId + '/' + channelId + '/' + status);
        if (response.Status) {
            yield put(dealerListSuccessful(response.dealer));
        }
        else {
            yield put(dealerApiError(response.Message));
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchGetDealerList() {
    yield takeEvery(DEALER_LIST, getDealerList)
}

function* addDealer({ payload: { dealer, companyId } }) {
    try {
        const response = yield call(addData, '/dealer', dealer);
        if (response.Status) {
            const responseList = yield call(getList, '/dealers/' + companyId + '/' + 0 + '/' + 0);
            if (responseList.Status) {
                yield put(addDealerSuccess(responseList.dealer, response.Message))
            } else {
                yield put(dealerApiError(responseList.Message));
            }
        } else {
            yield put(dealerApiError(response.Message));
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchAddDealer() {
    yield takeEvery(ADD_DEALER, addDealer)
}

function* editDealer({ payload: { dealer, id, companyId } }) {
    try {
        const response = yield call(editData, '/dealer/', dealer, id);
        if (response.Status) {
            const responseList = yield call(getList, '/dealers/' + companyId + '/' + 0 + '/' + 0);
            if (responseList.Status) {
                yield put(addDealerSuccess(responseList.dealer, response.Message));
            }
            else {
                yield put(dealerApiErrorModal(responseList.Message));
            }
        }
        else {
            yield put(dealerApiErrorModal(response.Message));
        }
    } catch (error) {
        yield put(dealerApiErrorModal(error));
    }
}
export function* watchEditDealer() {
    yield takeEvery(EDIT_DEALER, editDealer)
}

function* deleteDealer({ payload: { id, status, deleteData, companyId } }) {
    try {
        const response = yield call(userDeleteData, '/dealer/' + id + '/' + status, deleteData);
        if (response.Status) {
            const responseList = yield call(getList, '/dealers/' + companyId + '/' + 0 + '/' + 0);
            if (responseList.Status) {
                yield put(deleteDealerSuccess(responseList.dealer, response.Message));
            }
            else {
                yield put(dealerApiError(responseList.Message));
            }
        }
        else {
            yield put(dealerApiError(response.Message));
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchDeleteDealer() {
    yield takeEvery(DELETE_DEALER, deleteDealer)
}

function* getCountry() {
    try {
        const response = yield call(getList, '/country');
        if (response.Status) {
            yield put(countryListSuccess(response.countries))
        }
    } catch (error) {

        yield put(dealerApiError(error));
    }
}

export function* watchCountryList() {

    yield takeEvery(COUNTRY_LIST, getCountry)
}

function* getChannel() {
    try {
        const response = yield call(getList, '/getChannelName/0');
        if (response.Status) {
            yield put(ChannelNameSuccess(response.channel))
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchChannelList() {
    yield takeEvery(CHANNEL_NAME, getChannel)
}

function* getStates({ payload: countryID }) {
    try {
        const response = yield call(getList, '/states/' + countryID);
        if (response.Status) {
            yield put(stateListSuccessfull(response.state));
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchGetStates() {
    yield takeEvery(STATE_LIST, getStates);
}

function* dealerFlagUpdate({ payload: { flag, dealerId, flagType, companyId,LoginId, data } }) {
    try {
        const response = yield call(addData, '/updateDealerFlag/' + dealerId + '/' + flag + '/' + flagType + '/' + LoginId + '/1', data);
        if (response.Status) {
            const responseList = yield call(getList, '/dealers/' + companyId + '/' + 0 + '/' + 0);
            if (responseList.Status) {
                yield put(dealerFlagUpdateSuccessfull(responseList.dealer, response.Message));
            }
            else {
                yield put(dealerApiError(responseList.Message));
            }
        }
        else {
            yield put(dealerApiError(response.Message));
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchDealerFlagUpdate() {
    yield takeEvery(DEALER_FLAG_UPDATE, dealerFlagUpdate);
}

function* getNonVerifiedDealerList({ payload: companyId }) {
    try {
        const response = yield call(getList, '/getNonVerifiedDealer/' + companyId);
        if (response.Status) {
            yield put(nonVerifiedDealerListSuccessful(response.dealer));
        }
        else {
            yield put(dealerApiError(response.Message));
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchGetNonVerifiedDealerList() {
    yield takeEvery(NON_VERIFIED_DEALER_LIST, getNonVerifiedDealerList)
}

function* verifyDealer({ payload: { flag, dealerId, loginId, companyId } }) {
    try {
        const response = yield call(getList, '/verifyDealer/' + dealerId + '/' + flag + '/' + loginId);
        if (response.Status) {
            const responseList = yield call(getList, '/getNonVerifiedDealer/' + companyId);

            if (responseList.Status) {
                yield put(verifyDealerSuccess(responseList.dealer, response.Message));
            }
            else {
                yield put(dealerApiError(responseList.Message));
            }
        }
        else {
            yield put(dealerApiError(response.Message));
        }
    } catch (error) {
        yield put(dealerApiError(error));
    }
}

export function* watchVerifyDealer() {
    yield takeEvery(VERIFY_DEALER, verifyDealer)
}

function* editVerifyDealerUserAndPassword({ payload: { updateData, id, companyId } }) {
    try {
        const response = yield call(editData, '/verifyDealer/', updateData, id);
        if (response.Status) {
            const responseList = yield call(getList, '/getNonVerifiedDealer/' + companyId);
            if (responseList.Status) {
                yield put(updateVerifyUserPasswordChangeSuccessfully(responseList.dealer, response.Message));
            }
            else {
                yield put(dealerApiErrorModal(responseList.Message));
            }
        }
        else {
            yield put(dealerApiErrorModal(response.Message));
        }
    } catch (error) {
        yield put(dealerApiErrorModal(error));
    }
}
export function* watchEditVerifyDealerUserPassword() {
    yield takeEvery(CHANGE_VERIFY_USER_PASSWORD, editVerifyDealerUserAndPassword)
}

function* GetDealerSuspensionLog({ payload: { dealerId } }) {
    try {
        const response = yield call(getList, '/getDealerSuspensionLog/'+ dealerId);
        if (response.Status) {
            yield put(getDealerSuspensionLogSuccessful(response.SuspensionLog, dealerId));
        }
        else {
            yield put(getDealerSuspensionLogFailed(response.Message));
        }
    } catch (error) {
        yield put(getDealerSuspensionLogFailed(error));
    }
}
export function* watchGetDealerSuspensionLog() {
    yield takeEvery(GET_DEALER_SUSPENSION_LOG, GetDealerSuspensionLog)
}

function* GetDealerConfig({ payload: { dealerId } }) {
    try {
        const response = yield call(getList, '/getDealerConfig/' + dealerId);
        if (response.Status) {
            yield put(getDealerConfigSuccessful(response.DealerConfig, dealerId));
        } else {
            yield put(getDealerConfigFailed(response.Message));
        }
    } catch (error) {
        yield put(getDealerConfigFailed(error));
    }
}

export function* watchGetDealerConfig() {
    yield takeEvery(GET_DEALER_CONFIG, GetDealerConfig)
}

function* UpdateDealerConfig({ payload: { dealerId, loginId, data } }) {
    try {
        const response = yield call(addData, '/updateDealerConfig/'+ dealerId + '/' + loginId, data);
        if (response.Status) {
            yield put(updateDealerConfigSuccessful(response.Message));
        }
        else {
            yield put(updateDealerConfigFailed(response.Message));
        }
    } catch (error) {
        yield put(updateDealerConfigFailed(error));
    }
}
export function* watchUpdateDealerConfig() {
    yield takeEvery(UPDATE_DEALER_CONFIG, UpdateDealerConfig)
}

function* getOthersAllowedDuration() {
    try {
        const response = yield call(getList, '/getOthersAllowedDuration/');
        if (response.Status) {
            yield put(getOthersAllowedDurationSuccessful(response.othersAllowedDuration));
        } else {
            yield put(getOthersAllowedDurationFailed(response.Message));
        }
    } catch (error) {
        yield put(getOthersAllowedDurationFailed(error));
    }
}
export function* watchGetOthersAllowedDuration() {
    yield takeEvery(GET_OTHERS_ALLOWED_DURATION, getOthersAllowedDuration)
}

function* updatEmailDealer({ payload: { dealer, dealerId,companyId } }) {
    try {
        const response = yield call(addData, '/updateDealerEmailDomain/'+ dealerId, dealer);
        if (response.Status) {
            yield put(updateDealeremailSuccessful(response.Message));
            const responseList = yield call(getList, '/dealers/' + companyId + '/' + 0 + '/' + 0);
            if (responseList.Status) {
                yield put(addDealerSuccess(responseList.dealer, response.Message));
            }
            else {
                yield put(updateDealeremailFailed(responseList.Message));
            }
        }
        else {
            yield put(updateDealeremailFailed(response.Message));
        }
    } catch (error) {
        yield put(updateDealeremailFailed(error));
    }
}

export function* watchUpdateEmailDealer() {
    yield takeEvery(UPDATE_DEALER_EMAIL, updatEmailDealer)
}


function* dealerSaga() {
    yield all([
        fork(watchChannelActivation),
        fork(watchGetDealerList),
        fork(watchCountryList),
        fork(watchGetStates),
        fork(watchAddDealer),
        fork(watchEditDealer),
        fork(watchDeleteDealer),
        fork(watchChannelList),
        fork(watchDealerFlagUpdate),
        fork(watchGetNonVerifiedDealerList),
        fork(watchVerifyDealer),
        fork(watchEditVerifyDealerUserPassword),
        fork(watchGetDealerSuspensionLog),
        fork(watchGetDealerConfig),
        fork(watchUpdateDealerConfig),
        fork(watchGetOthersAllowedDuration),
        fork(watchUpdateEmailDealer),
    ]);
}

export default dealerSaga;