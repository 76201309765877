import { GET_ALL_USER_LIST, GET_ALL_USER_LIST_SUCCESS, SEARCH_ACTIVATION_FAILED, SEARCH_USER_ACTIVATION, SEARCH_USER_ACTIVATION_SUCCESSFULL,} from "./actionTypes"

export const getAllUserList = (DealerId) => {
    return {
        type: GET_ALL_USER_LIST,
        payload: DealerId
    }
}

export const getAllUserListSuccessfull = (userData) => {
    return {
        type: GET_ALL_USER_LIST_SUCCESS,
        payload: userData
    }
}
export const searchUserActivation= (searchData) =>{
    return {
        type: SEARCH_USER_ACTIVATION,
        payload: searchData
    }
}
export const searchUserActivationSuccess =(searchReportData)=>{
    return{
        type:SEARCH_USER_ACTIVATION_SUCCESSFULL,
        payload: searchReportData
    }
}
export const searchUserActivationFailed = (error) =>{
    return{
        type:SEARCH_ACTIVATION_FAILED,
        payload: error
    }
}