import {
    GET_SERVER_LIST,
    GET_SERVER_LIST_SUCCESSFULL,
    SERVER_FAILED,
  } from "./actionTypes";
  
  export const serverList = (companyId) => {
    return {
      type: GET_SERVER_LIST,
      payload: companyId,
    };
  };
  
  export const serverListSuccessFull = (serverData) => {
    return {
      type: GET_SERVER_LIST_SUCCESSFULL,
      payload: serverData,
    };
  };
  
  export const serverListFailed = (error) => {
    return {
      type: SERVER_FAILED,
      payload: error,
    };
  };
  