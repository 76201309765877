export const GET_MAIL_BY_MSISDN = 'GET_MAIL_BY_MSISDN';
export const GET_MAIL_BY_MSISDN_SUCCESS = 'GET_MAIL_BY_MSISDN_SUCCESS';
export const GET_MAIL_BY_MSISDN_FAIL = 'GET_MAIL_BY_MSISDN_FAIL';

export const READ_MAIL = 'READ_MAIL';
export const READ_MAIL_SUCCESS = 'READ_MAIL_SUCCESS';
export const READ_MAIL_FAIL = 'READ_MAIL_FAIL';

export const CLOSE_MAIL_TEMPLATE = 'CLOSE_MAIL_TEMPLATE';

export const RESET_TEMPLATE_DATA = 'RESET_TEMPLATE_DATA';