import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData } from '../../helpers/fackBackend_Helper';
import { assignDealerToActivationSuccess, assignDealerToActivationFailed } from './actions';
import { ASSIGN_DEALER_ACTIVATION } from './actionTypes';

function* assignDealerToActivationByAcode({ payload: { channelId, assignData } }) {
    try {
        const response = yield call(addData, '/assignActivationToDealer/'+channelId, assignData);
        if (response.Status) {
            yield put(assignDealerToActivationSuccess(response.assignResponse));
        } else {
            yield put(assignDealerToActivationFailed(response));
        }
    } catch (error) {
        yield put(assignDealerToActivationFailed(error));
    }
}

export function* watchAssignDealerToActivation() {
    yield takeEvery(ASSIGN_DEALER_ACTIVATION, assignDealerToActivationByAcode)
}

function* assignDealerSaga() {
    yield all([
        fork(watchAssignDealerToActivation)
    ]);
}

export default assignDealerSaga;

