import { USER_LOGOUT, USER_LOGOUT_FAILED, USER_LOGOUT_SUCCESS, CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESSFUL, RESET_PASSWORD_ALERT, API_PASSWORD_ERROR, JAR_STATUS_UPDATE, JAR_STATUS_UPDATE_SUCCESS, GET_TO_SHOW_MARQUEE, GET_TO_SHOW_MARQUEE_SUCCESSFULL, VALIDATE_TOKEN, VALIDATE_TOKEN_SUCCESS, VALIDATE_TOKEN_FAILED, GET_COMPANY, GET_COMPANY_SUCCESS, GET_COMPANY_FAILED, RESET_HEADER_MESSAGE, T_MOBILE_STATUS_UPDATE, T_MOBILE_STATUS_UPDATE_SUCCESS, T_MOBILE_STATUS_UPDATE_FAILED, CLOSE_TMOBILE_STATUS_ERROR_MODAL } from "./actionTypes";

const initialState = {
    apiError: null, Message: null, loading: false, logoutDone: false, jarMessage: null, tMobileStatusMessage: null, tmobileStatusErrorModal: false
}

const header = (state = initialState, action) => {
    switch (action.type) {
        case USER_LOGOUT:
            state = {
                ...state,
                loading: true,
            }
            break;

        case USER_LOGOUT_SUCCESS:
            state = {
                ...state,
                loading: false,
                logoutDone: true
            }
            break;

        case USER_LOGOUT_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload
            }
            break;
        case CHANGE_PASSWORD:
            state = {
                ...state,
                loading: true,
                apiError: null,
                Message: null
            }
            break;
        case CHANGE_PASSWORD_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                apiError: null,
                Message: action.payload,
            }
            break;
        case API_PASSWORD_ERROR:
            state = {
                ...state,
                loading: false,
                Message: null,
                apiError: action.payload,
            }
            break;
        case RESET_PASSWORD_ALERT:
            state = {
                ...state,
                loading: false,
                Message: null,
                apiError: null,
                jarMessage: null
            }
            break;

        case JAR_STATUS_UPDATE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                Message: null
            }
            break;

        case JAR_STATUS_UPDATE_SUCCESS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                jarMessage: action.payload,
            }
            break;
        case GET_TO_SHOW_MARQUEE:
            state = {
                ...state,
                loading: true,
                apiError: null,
            }
            break;
        case GET_TO_SHOW_MARQUEE_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                notification: action.payload,
                apiError: null,
            }
            break;

        case VALIDATE_TOKEN:
            state = {
                ...state,
                loading: true,
            }
            break;

        case VALIDATE_TOKEN_SUCCESS:
            state = {
                ...state,
                loading: false,
            }
            break;

        case VALIDATE_TOKEN_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload
            }
            break;

        case GET_COMPANY:
            state = {
                ...state,
            }
            break;

        case GET_COMPANY_SUCCESS:
            state = {
                ...state,
                loading: false,
                companyData: action.payload
            }
            break;

        case GET_COMPANY_FAILED:
            state = {
                ...state,
                loading: false,
                error: action.payload
            }
            break;
        case RESET_HEADER_MESSAGE:
            state = {
                ...state,
                loading: false,
                apiError: null,
                jarMessage: null,
                Message: null,
            }
            break;

        case T_MOBILE_STATUS_UPDATE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                Message: null,
                tmobileStatusErrorModal: false,
            }
            break;
        case T_MOBILE_STATUS_UPDATE_SUCCESS:
            state = {
                ...state,
                loading: false,
                apiError: null,
                jarMessage: action.payload,
                tmobileStatusErrorModal: false,
            }
            break;
        case T_MOBILE_STATUS_UPDATE_FAILED:
            state = {
                ...state,
                tmobileStatusErrorModal: true,
                loading: false,
                apiError: action.payload
            }
            break;

        case CLOSE_TMOBILE_STATUS_ERROR_MODAL:
            state = {
                ...state,
                tmobileStatusErrorModal: false,
            }
            break;


        default:
            state = { ...state };
            break;
    }
    return state;
}

export default header;