import { useEffect, useState, useRef } from "react";
import { Switch, Route, useLocation, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import './assets/styles/dashlite.css';
import './assets/styles/theme.css';
import './assets/styles/dashlite.min.css';
import SignIn from "./pages/SignIn";
import AccessDenied from "./pages/AccessDenied";
// import ResetPassword from "./pages/ResetPassword";
import Main from "./components/layout/Main";
import Dashboard from "./pages/Dashboard";
import Dealer from "./pages/Dealer";
import User from "./pages/User";
import ViewActivations from "./pages/ViewActivations";
import Plan from "./pages/Plan";
import PausedActivations from "./pages/PausedActivations";
import Addon from "./pages/Addon";
import DealerActivationReport from "./pages/DealerActivationReport"
import JarPath from "./pages/JarPath";
import VerifyDealer from "./pages/VerifyDealer";
import ActivationReport from "./pages/ActivationReport";
import ChannelConfig from "./pages/ChannelConfig";
import Notification from "./pages/Notification";
import PlanWiseActivationReport from "./pages/PlanWiseActivationReport";
import ChannelActivationReport from "./pages/ChannelActivationReport";
import { validateToken } from "./store/header/actions";
import { PERMISSIONS } from "./Permission/Permission";
import LockActivationRequest from "./pages/LockActivationRequest";
import JARConfiguration from "./pages/JARConfiguration";
import Server from "./pages/Server";
import RepushActivation from "./pages/RepushActivation";
import PendingActivation from "./pages/PendingActivation";
import ManualActivationReport from "./pages/ManualActivationReport";
import Asset from "./pages/Asset";
import { DataProvider } from "./pages/DataContext";
import AssetPerformanceReport from "./pages/AssetPerformanceReport";
import UploadJar from "./pages/UploadJar";
import PosDealerMapping from "./pages/PosDealerMapping";
import AwsEc2 from "./pages/AwsEc2";
import AssignDealer from "./pages/AssignDealer";
import ValidateEpayReport from "./pages/ValidateEpayReport";
import SimSwapRequest from "./pages/SimSwapRequest";
import DealerEmailConfig from './pages/DealerEmailConfig'
import EmailInbox from "./pages/EmailInbox";
import LateActivatedTxnReport from "./pages/LateActivatedTxnReport";
import UpdateZipcode from "./pages/UpdateZipcode";
import BulkCancelation from "./pages/BulkCancelation";

function App(props) {
  const [showSidebar, setShowSidebar] = useState(false);
  const filterArray = useRef([]);
  const [RoleID, setRoleId] = useState(1);
  const [LoginID, setLoginId] = useState(0);
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");

  useEffect(() => {
    for (const [key, value] of Object.entries(PERMISSIONS)) {
      if (key == RoleID) {
        filterArray.current.push(value);
      }
    }
  }, []);

  useEffect(() => {
    const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
    setRoleId(roleId);
    const loginId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).ID : null;
    setLoginId(loginId);

    if (pathname === 'dashboard' || pathname === 'dealer' || pathname === 'server' || pathname === 'user' || pathname === 'view-activation' || pathname === 'help' || pathname === "plan" || pathname === "paused-activation" || pathname === "addon-service" || pathname === "user-activation-report" || pathname === "dealer-activation-report" || pathname === "jar-path" || pathname === "verify-dealer" || pathname === "activation-report" || pathname === "password-config" || pathname === "notification" || pathname === "channel-activation-report" || pathname === "lock-activation" || pathname === "jar-config" || pathname === "repush-activation" || pathname === "pending-activation" || pathname === "manual-activation-report" || pathname === "asset" || pathname === "plan-wise-activation-report" || pathname === "asset-performance-report" || pathname === "upload-jar" || pathname === 'pos_dealer_mapping' || pathname === 'aws-instances' || pathname === 'assign-dealer' || pathname === 'validate-epay-report' || pathname === 'sim-swap-request' || pathname === 'dealer-email-config' || pathname === 'email-inbox' || pathname === "late-activated-txn-report" || pathname === 'update-zipcode' || pathname === 'bulk-cancellation') {
      props.validateToken();
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (props.apiError === 'Invalid token') {
      localStorage.removeItem("authUser");
      localStorage.removeItem("token");
      window.location.href = '/';
    }
  }, [props.apiError]);

  return (
    <div className="App">
      <DataProvider>
        <Switch>
          <Route path="/" exact component={SignIn} />
          {/* <Route path="/reset-password" exact component={ResetPassword} /> */}
          {showSidebar && <Main>
            <Route exact path='/dashboard' component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_dashboard_page") ? Dashboard : AccessDenied)}></Route>
            <Route exact path='/verify-dealer' component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_verify_dealer_page") ? VerifyDealer : AccessDenied)}></Route>
            <Route exact path="/user" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_user_page") ? User : AccessDenied)}></Route>
            <Route exact path="/dealer" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_dealer_page") ? Dealer : AccessDenied)}></Route>
            <Route exact path="/plan" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_plan_page") ? Plan : AccessDenied)} ></Route>
            <Route exact path="/addon-service" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_addon_page") ? Addon : AccessDenied)} ></Route>
            <Route exact path="/jar-path" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_jar_page") ? JarPath : AccessDenied)} ></Route>
            <Route exact path="/view-activation" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_activation_page") ? ViewActivations : AccessDenied)}></Route>
            {/* <Route exact path="/paused-activation" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_paused_activation_page") ? PausedActivations : AccessDenied)}></Route> */}
            <Route exact path="/dealer-activation-report" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_dealer_report_page") ? DealerActivationReport : AccessDenied)}></Route>
            <Route exact path="/activation-report" component={filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_Activation_report_page") ? ActivationReport : AccessDenied}></Route>
            <Route exact path="/password-config" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_password_config_page") ? ChannelConfig : AccessDenied)}></Route>
            <Route exact path="/notification" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_notification_page") ? Notification : AccessDenied)}></Route>
            <Route exact path="/jar-config" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("jar_configuration") ? JARConfiguration : AccessDenied)}></Route>
            <Route exact path="/channel-activation-report" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_Channel_Activation_report") ? ChannelActivationReport : AccessDenied)}></Route>
            <Route exact path="/lock-activation" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_lock_activation_request_page") ? LockActivationRequest : AccessDenied)}></Route>
            <Route exact path="/pending-activation" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("pending_activation") ? PendingActivation : AccessDenied)}></Route>
            <Route exact path="/repush-activation" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("repush_activation") ? RepushActivation : AccessDenied)}></Route>
            <Route exact path="/server" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_server_page") ? Server : AccessDenied)}></Route>
            <Route exact path="/asset" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_asset_page") ? Asset : AccessDenied)}></Route>
            <Route exact path="/manual-activation-report" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("view_manual_activation_report_page") ? ManualActivationReport : AccessDenied)}></Route>
            <Route exact path="/plan-wise-activation-report" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("plan_wise_activation_report") ? PlanWiseActivationReport : AccessDenied)}></Route>
            <Route exact path="/asset-performance-report" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("asset_performance_report") ? AssetPerformanceReport : AccessDenied)}></Route>
            <Route exact path="/upload-jar" component={(true ? UploadJar : AccessDenied)}></Route>
            <Route exact path="/pos_dealer_mapping" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("pos_dealer_mapping") ? PosDealerMapping : AccessDenied)}></Route>
            <Route exact path="/aws-instances" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("aws_ec2") ? AwsEc2 : AccessDenied)}></Route>
            <Route exact path="/assign-dealer" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("assign_dealer_activation") ? AssignDealer : AccessDenied)}></Route>
            {/* filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("jar_upload") */}
            <Route exact path="/validate-epay-report" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("validate_epay_report") ? ValidateEpayReport : AccessDenied)}></Route>
            <Route exact path="/sim-swap-request" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("sim_swap_request") ? SimSwapRequest : AccessDenied)}></Route>
            <Route exact path="/email-inbox" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("email_inbox") ? EmailInbox : AccessDenied)}></Route>
            <Route exact path="/dealer-email-config" component={((LoginID === 2) ? DealerEmailConfig : AccessDenied)} ></Route>
            <Route exact path="/late-activated-txn-report" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("late_activated_txn_report") ? LateActivatedTxnReport : AccessDenied)}></Route>
            <Route exact path="/update-zipcode" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("update_zipcode") ? UpdateZipcode : AccessDenied)}></Route>
            <Route exact path="/bulk-cancellation" component={(filterArray.current[0] && filterArray.current[0].length > 0 && filterArray.current[0].includes("bulk_cancellation") ? BulkCancelation : AccessDenied)}></Route>
          </Main>}
        </Switch>
      </DataProvider>
    </div>
  );
}

const mapStateToProps = state => {
  const { apiError, loading } = state.header;
  return { apiError, loading };
}

export default withRouter(connect(mapStateToProps, { validateToken })(App));

// export default App;
