import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import moment from "moment";
import FilterComponent from "./FilterComponent";
import { jarList, editJarData, deleteJarData } from '../store/jar/actions'
import Spinner from "../components/layout/Spinner";

function JarPath(props) {
    const dispatch = useDispatch();
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [editId, setEditId] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [disableEditID, setDisableEditID] = useState('');
    const [deleteId, setdeleteId] = useState('');
    const [deleteJar, setDeleteJar] = useState();
    const [editJar, seteditJar] = useState({
        currentXPath: ''
    })

    useEffect(() => {
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        seteditJar(prevState => ({
            ...prevState,
            modifiedBy: loginId
        }));
        setDeleteJar(prevState => ({
            ...prevState,
            modifiedBy: loginId
        }));
        props.jarList();
    }, [])

    const columns = [
        {
            name: "#",
            width: '60px',
            selector: row => row.serial,
        },
        {
            name: "NAME",
            width: "160px",
            selector: row => row.ElementName,
        },
        // {
        //     name: "PREVIOUS PATH",
        //     width: "400px",
        //     selector: row => row.PreviousXPath,
        // },
        {
            name: "X PATH",
            width: "600px",
            selector: row => row.CurrentXPath,
        },
        {
            name: "STATUS",
            selector: row => row.IsActive,
        },
        {
            name: "DATE",
            selector: row => row.CreatedDate,
        },
        {
            name: "ACTION",
            width: '180px',
            selector: row => row.Action,
        },

    ];

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;
        seteditJar(prevState => ({
            ...prevState,
            [fname]: fvalue
        }));
    }

    const customStyles = {
        rows: {
            style: {
                minHeight: '70px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '35px', // override the cell padding htmlFor head cells
                paddingRight: '0px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '35px', // override the cell padding htmlFor data cells
                paddingRight: '0px',
            },
        },
    };

    const handleEditForm = (id) => {
        var selectedData = props.jarData.filter(jar => jar.ID === id);
        seteditJar(prevState => ({
            ...prevState,
            currentXPath: selectedData[0].CurrentXPath,
        }))
        setEditId(id);
        if (id === selectedData[0].ID) {
            setEditFlag(true)
        }
    }

    const handleDiableEdit = (id) => {
        var selectedData = props.jarData.filter(jar => jar.ID === id);
        // seteditJar(prevState => ({
        //     ...prevState,
        //     currentXPath: selectedData[0].CurrentXPath,
        // }))
        debugger
        setDisableEditID(id);
        if (id === selectedData[0].ID) {
            setEditFlag(false)
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(editJarData(editId, editJar));
        setEditFlag(false);
    }

    const handleDelete = (id, status) => {
        setdeleteId(id)
        dispatch(deleteJarData(id, status, deleteJar))
    }

    const subHeaderComponent = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText("");
            }
        };

        return (
            <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
            />
        );
    }, [filterText, resetPaginationToggle]);

    const filteredItems = props.jarData && props.jarData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1

    );

    const filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            serial: (<p>{key + 1}</p>),
            ElementName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.ElementName}</p>),
            // PreviousXPath: (<p>{data.PreviousXPath}</p>),
            // CurrentXPath: (<p>{data.CurrentXPath}</p>),
            CurrentXPath: (editFlag === true && editId === data.ID ? <form onSubmit={handleSubmit} method="post">
                <div className="input-group mt-1">
                    <div className="form-group">
                        <div className="form-control-wrap">
                            <input value={editJar.currentXPath} type="text" onChange={handleChange} name="currentXPath" className="form-control " style={{ "width": '450px' }} id="default-01" placeholder="Input placeholder"></input></div></div>
                    <div className="input-group-append"><div className="form-group"><button type="submit" className="btn btn-outline-primary btn-dim"><em className="icon ni ni-arrow-right"></em></button></div></div></div></form> : <p style={{ "whiteSpace": "break-spaces" }}>{data.CurrentXPath}</p>),
            CreatedDate: (<p>{moment(new Date(data.CreatedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
            IsActive: (data.IsActive === 1 ? <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">Active</span> : <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">Inactive</span>),
            Action: (<div>{editFlag === true && editId === data.ID ?
                <em className="icon icon-circle bg-danger ni ni-cross" style={{ cursor: 'pointer' }} onClick={() => handleDiableEdit(data.ID)}></em> : <em className="icon icon-circle bg-warning ni ni-edit-alt" style={{ cursor: 'pointer' }} onClick={() => handleEditForm(data.ID)}></em>}
                <div className={`custom-control custom-switch mx-2`}>
                    {data.IsActive === 1 ? <input type="checkbox" defaultChecked={data.IsActive} className="custom-control-input" id={`customSwitch${data.ID}`} onClick={() => handleDelete(data.ID, 0)} /> : <input type="checkbox" className="custom-control-input" id={`customSwitch${data.ID}`} onClick={() => handleDelete(data.ID, 1)} />}<label className="custom-control-label" htmlFor={`customSwitch${data.ID}`}></label></div></div>)
        }
    })

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Jar X Path</h3>
            <div className="card p-2" style={{ marginTop: '12px' }}>
                <div className="card-inner p-0">
                    <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} subHeaderComponent={subHeaderComponent} subHeader responsive striped />
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { jarData, apiError, loading, message } = state.jar;
    return { jarData, apiError, loading, message };
}

export default withRouter(connect(mapStateToProps, { jarList })(JarPath));

