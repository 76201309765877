import React, { useState, useEffect } from "react";
import { getAllChannelList } from "../store/dealer/actions";
import { connect, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { PERMISSIONS } from '../Permission/Permission';
import Spinner from "../components/layout/Spinner";
import DataTable from 'react-data-table-component';
import { dealerEmailConfigList, closeEmailConfigModal, openEmailConfigModal, addDealerEmailConfig, editDealerEmailConfig, deleteDealerEmailConfig, resetDealerEmailConfigMessage } from "../store/dealerEmailConfig/actions"
import moment from "moment/moment";



function DealerEmailConfig(props) {
  const dispatch = useDispatch();
  const [dataSearch, setDataSearch] = useState(false);
  const [RoleID, setRoleID] = useState(null);
  const [modalHeading, setModalHeading] = useState('New Dealer Config');
  const [editFlag, setEditFlag] = useState(false);
  const [parentID, seteparentID] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [modalPara, setModalPara] = useState('Add information about new user.')
  var checkFlag = false;
  const [editID, setEditID] = useState(null);
  const [userStatus, setUserStatus] = useState(null)
  const [popUpDeleteContent, setPopUpDeleteContent] = useState('');
  const [popUp, setpopUp] = useState(false);
  const [searchDealer, setSearchDealer] = useState({
    companyID: '',
    channelId: 0,
  });
  const [formState, setFormState] = useState({
    ChannelID: "",
    EmailDomain: "",
    DistributorID: "",
    DistributorName: "",
  });
  const [deleteUserData, setDeleteUserData] = useState({
    ModifiedBy: null,
    ModifiedDtTm: ''
  })
  const timezone = process.env.REACT_APP_TIMEZONE;
  const loginId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).ID : null;
  const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
  const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;

  useEffect(() => {
    setSearchDealer((prevState) => ({
      ...prevState,
      companyID: companyId,
    }));
    setDeleteUserData(prevState => ({
      ...prevState,
      ModifiedBy: loginId,
      ModifiedDtTm: moment(new Date()).tz(timezone).format()
    }))
  }, [])

  useEffect(() => {
    dispatch(getAllChannelList(dealerId))
  }, [])


  useEffect(() => {
    setTimeout(() => {
      dispatch(resetDealerEmailConfigMessage())
    }, 3000)
  }, [props.message, props.error, props.editMessage, props.errormessage])

  var filterArray = [];
  for (const [key, value] of Object.entries(PERMISSIONS)) {
    if (key == RoleID) {
      filterArray.push(value);
    }
  }

  const columns = [
    {
      name: '#',
      width: "40px",
      selector: row => row.serial,
    },
    {
      name: 'CHANNEL NAME',
      selector: row => row.ChannelName,
    },
    {
      name: 'DISTRIBUTORID',
      selector: row => row.distributorID,
    },
    {
      name: 'DISTRIBUTOR NAME',
      selector: row => row.distributorName,
    },
    {
      name: 'EMAIL DOIMAIN',
      selector: row => row.emailDomain,
    },
    {
      name: 'STATUS',
      selector: row => row.status,
    },
    {
      name: 'CREATED DATE',
      selector: row => row.createdDate,
    },
    {
      name: 'MODIFY DATE',
      selector: row => row.modifyDate,
    },
    {
      name: 'ACTION',
      width: '150px',
      selector: row => row.actions,
    },
  ];



  const handleSearchData = (e) => {
    e.preventDefault();
    setDataSearch(true);
    dispatch(dealerEmailConfigList(searchDealer.companyID, searchDealer.channelId));
  }

  const handleChannelChange = (e) => {
    const fname = e.target.name;
    const fvalue = e.target.value;
    setSearchDealer(prevState => ({
      ...prevState,
      [fname]: fvalue,
    }));
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px',
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '0px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px',
        paddingRight: '0px',
      },
    },
  };
  const handleAddForm = () => {
    dispatch(openEmailConfigModal());
    setModalHeading('Dealer Email Config')
    setModalPara('Add information about new dealer config.');
    setEditFlag(false);
    setFormState({
      ChannelID: "",
      EmailDomain: "",
      DistributorID: "",
      DistributorName: "",
    });
  };

  const handleOpenPopUpAlertInActive = (userId, userStatus) => {
    setpopUp(true);
    setPopUpDeleteContent('Are you sure, you want to inactive the dealer email config ?')
    setEditID(userId);
    setUserStatus(userStatus);
  }

  const handleOpenPopUpAlertActive = (userId, userStatus) => {
    ;
    setpopUp(true);
    setPopUpDeleteContent('Are you sure, you want to active the dealer email config  ?')
    setEditID(userId);
    setUserStatus(userStatus);
  }

  const handleStatusChange = () => {
    dispatch(deleteDealerEmailConfig(deleteUserData, editID, userStatus, searchDealer));
    setpopUp(false);
  }
  const filteredItems = props.dealerEmailData && props.dealerEmailData.length && props.dealerEmailData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1

  );
  const filterData = filteredItems && filteredItems.map((item, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      ChannelName: (<p style={{ "whiteSpace": "break-spaces" }}>{item.ChannelName}</p>),
      distributorID: (<p>{item.DistributorID}</p>),
      distributorName: (<p>{item.DistributorName}</p>),
      emailDomain: (<p>{item.EmailDomain}</p>),
      status: (item.IsActive === 1 ? <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">Active</span> : <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">Inactive</span>),
      createdDate: (<p>{moment(new Date(item.CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
      modifyDate: (<p>{(item.ModifiedDtTm != null) ? moment(new Date(item.ModifiedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY") : ''}</p>),
      actions: (<span><em className="icon icon-circle bg-warning ni ni-edit-alt" style={{ cursor: 'pointer' }} onClick={() => handleEditForm(item)}></em>
        {item.IsActive === 1 ? <em className="icon icon-circle ni ni-trash-alt bg-danger mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleOpenPopUpAlertInActive(item.ID, 0)}></em> :
          <em className="icon icon-circle icon ni ni-check-thick bg-success mx-2" style={{ 'cursor': 'pointer' }} onClick={() => handleOpenPopUpAlertActive(item.ID, 1)}></em>}
      </span>)
    }
  })
  const handleUpdate = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    formDataObject.ModifiedBy = loginId;
    dispatch(editDealerEmailConfig(formDataObject, editID, searchDealer));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    formDataObject.CreatedBy = loginId;
    dispatch(addDealerEmailConfig(formDataObject, searchDealer));
  };

  const handleEditForm = (item) => {
    setEditFlag(true);
    setFormState({
      ChannelID: item.ChannelID,
      EmailDomain: item.EmailDomain,
      DistributorID: item.DistributorID,
      DistributorName: item.DistributorName,
    });
    setEditID(item.ID)
    dispatch(openEmailConfigModal());
  };


  const handleChange = (e) => {
  };

  const handleCancel = () => {
    dispatch(closeEmailConfigModal());
  }

  const handleInput = (event) => {
    const value = event.target.value;
    const domainPattern = /^[a-zA-Z0-9.-]*$/;
    const fullDomainPattern = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!domainPattern.test(value)) {
      event.target.value = value.replace(/[^a-zA-Z0-9.-]/g, '');
      return;
    }
    if (fullDomainPattern.test(value)) {
      event.target.setCustomValidity("");
    } else {
      event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");

    }

    const domainParts = value.split('.');
    if (domainParts.length > 1) {
      const tld = domainParts[domainParts.length - 1];
      const secondLevelDomain = domainParts[domainParts.length - 2];

      if (tld.length > 6 || (tld.length > 0 && tld.length < 2)) {
        event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");
      }
      if (secondLevelDomain.length === 0 || value.endsWith('.')) {
        event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");
      }
    } else {
      event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");
    }

    event.target.value = value.replace(/[^a-zA-Z0-9.-]/g, '');
    if (/\.{2,}/.test(value) || value.startsWith('.') || value.endsWith('.')) {
      event.target.setCustomValidity("Please enter a valid domain (e.g. gmail.com)");

    }
  };
  return (
    <div className="nk-block-head-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="nk-block-title page-title">Dealer Email Config</h3>
        <div
          data-target="addProduct"
          className="toggle btn btn-icon btn-primary d-md-none"
          onClick={(handleAddForm)}
        >
          <em className="icon ni ni-plus"></em>
        </div>
        <div
          data-target="addProduct"
          className="toggle btn btn-primary d-none d-md-inline-flex"
          onClick={(handleAddForm)}
        >
          <em className="icon ni ni-plus"></em>
          <span>Add Dealer Config</span>
        </div>
      </div>
      <div className="card p-2" style={{ marginTop: "12px" }}>
        {props.message &&
          <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
        {props.editMessage &&
          <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.editMessage}</strong></div>}
        {props.errormessage &&
          <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.errormessage}</strong></div>}
        {props.error &&
          <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.error}</strong></div>}
        <div className="card-inner p-0">
          <div className="nk-block">
            <form method="post" onSubmit={handleSearchData}>
              <div className="row g-3">
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="dealerID">Channel</label>
                    <div className="form-control-wrap">
                      <select
                        name="channelId"
                        className="form-control"
                        id="channelId"
                        onChange={(e) => handleChannelChange(e)}
                      >
                        <option value="0" name="all">Select</option>
                        {props.channelData && props.channelData.map(channel => (
                          <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className='col-sm-3'>
                  <button type='submit' disabled={props.searchLoading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.searchLoading === true && <span
                    className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                    <span>Search</span> </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {
        filterData ?
          <div className='card p-2'>
            <div className="card-inner p-0" >
              <DataTable
                pagination
                columns={columns}
                data={filterData}
                customStyles={customStyles}
                progressPending={props.searchLoading}
                progressComponent={<Spinner />}
                subHeader
                striped
                responsive
              />
            </div>
            {props.emailConfigModal && <div className="toggle-overlay" data-target="addProduct"></div>}
          </div>
          : ''}



      {props.emailConfigModal && <div className="nk-add-product toggle-slide toggle-slide-right toggle-screen-any content-active" data-content="addProduct"
        data-toggle-screen="any" data-toggle-overlay="true" data-toggle-body="true" data-simplebar="init" style={{ "zIndex": "1052" }}>
        <div className="simplebar-wrapper" style={{ 'margin': '-24px' }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer"></div>
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset" style={{ 'right': '0px', 'bottom': '0px' }}>
              <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content"
                style={{ 'height': '100%', 'overflow': 'hidden scroll' }}>
                <div className="simplebar-content" style={{ 'padding': '24px' }}>
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title">{modalHeading}</h5>
                      <div className="nk-block-des">
                        <p>{modalPara}</p>
                      </div>
                    </div>
                  </div>
                  <div className="nk-block">
                    <div className="row g-3">
                      <form onSubmit={editFlag ? handleUpdate : handleSubmit}>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label className="form-label" htmlFor="ChannelID">
                              Channel
                            </label>
                            <div className="form-control-wrap">
                              <div className="form-control-select">
                                <select
                                  name="ChannelID"
                                  className="form-control"
                                  id="parentID"
                                  onChange={handleChange}
                                  autoComplete="off"
                                  style={{ 'border': parentID ? '1px solid red' : '' }}
                                  required
                                  defaultValue={formState.ChannelID}
                                >
                                  <option value="" key="0">Select Channel</option>
                                  {props.channelData && props.channelData.length > 0 && props.channelData.map(data => (
                                    <option name="parentID" value={data.ID} key={data.ID} >{data.ChannelName}</option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                              <label className="form-label" htmlFor="EmailDomain">Domain</label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="EmailDomain"
                                  id="EmailDomain"
                                  placeholder="Enter Domain"
                                  autoComplete="off"
                                  pattern="^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
                                  title="Please enter a valid domain (e.g., gmail.com)"
                                  onChange={handleInput}
                                  required
                                  defaultValue={formState.EmailDomain}
                                />
                              </div>
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                              <label className="form-label" htmlFor="DistributorID">Distributor Id</label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="DistributorID"
                                  id="DistributorID"
                                  placeholder="Enter Distributor Id"
                                  autoComplete="off"
                                  required
                                  defaultValue={formState.DistributorID}
                                />
                              </div>
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                              <label className="form-label" htmlFor="DistributorName">Distributor Name</label>
                              <div className="form-control-wrap">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="DistributorName"
                                  id="DistributorName"
                                  placeholder="Enter Distributor Name"
                                  autoComplete="off"
                                  required
                                  defaultValue={formState.DistributorName}
                                />
                              </div>
                            </div>

                          </div>
                        </div>

                        <div className="d-flex my-3">
                          <button type='submit' disabled={props.buttonLoading} className="btn btn-primary">{props.buttonLoading === true && <span
                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                            <span>Save</span> </button>
                          <button className="btn btn-secondary mx-2" onClick={handleCancel}>Cancel</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="simplebar-placeholder" style={{ 'width': 'auto', 'height': '864px' }}></div>
        </div>
        <div className="simplebar-track simplebar-horizontal" style={{ 'visibility': 'hidden' }}>
          <div className="simplebar-scrollbar" style={{ 'width': '0px', 'display': 'none' }}></div>
        </div>
        <div className="simplebar-track simplebar-vertical simplebar-hover" style={{ 'visibility': 'visible' }}>
          <div className="simplebar-scrollbar" style={{ 'height': '606px', 'display': 'block', 'transform': 'translate3d(0px, 0px, 0px)' }}>
          </div>
        </div>

      </div>
      }
      {popUp && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-danger text-white">
              <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setpopUp(false)}></em></div>
            </div>
            <div className="modal-body">
              <p>{popUpDeleteContent}</p>
            </div>
            <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => setpopUp(false)}>No</div> <div className="btn btn btn-outline-success" onClick={() => handleStatusChange()}>Yes</div></div>
          </div>
        </div>
      </div>}
    </div>
  )
}

const mapStateToProps = (state) => {
  const { channelData } = state.dealer;
  const { dealerEmailData, emailConfigModal, message, error, buttonLoading, searchLoading, editMessage, errormessage } = state.dealerEmailConfig;
  return { channelData, dealerEmailData, emailConfigModal, message, error, buttonLoading, searchLoading, editMessage, errormessage };
};

export default withRouter(connect(mapStateToProps, { dealerEmailConfigList, openEmailConfigModal, closeEmailConfigModal, addDealerEmailConfig, editDealerEmailConfig, deleteDealerEmailConfig, resetDealerEmailConfigMessage })(DealerEmailConfig));

