import {
    GET_ALL_CHANNEL_LIST,
    GET_ALL_CHANNEL_LIST_SUCCESS, 
    DEALER_LIST, 
    DEALER_LIST_SUCCESSFUL, 
    DEALER_API_ERROR, 
    ADD_DEALER, 
    COUNTRY_LIST, 
    COUNTRY_LIST_SUCCESS, 
    STATE_LIST, 
    STATE_LIST_SUCCESS, 
    ADD_DEALER_SUCCESS, 
    RESET_ALERT, 
    EDIT_DEALER, 
    API_ERROR_MODAL, 
    DELETE_DEALER, 
    DELETE_DEALER_SUCCESS, 
    CHANNEL_NAME, 
    CHANNEL_NAME_SUCCESS, 
    DEALER_FLAG_UPDATE, 
    DEALER_FLAG_UPDATE_SUCCESS, 
    NON_VERIFIED_DEALER_LIST, 
    NON_VERIFIED_DEALER_LIST_SUCCESS, 
    VERIFY_DEALER, 
    VERIFY_DEALER_SUCCESS, 
    CHANGE_VERIFY_USER_PASSWORD, 
    CHANGE_VERIFY_USER_PASSWORD_SUCCESSFULLY,
    GET_DEALER_SUSPENSION_LOG, 
    GET_DEALER_SUSPENSION_LOG_SUCCESS, 
    GET_DEALER_SUSPENSION_LOG_FAILED,
    CLOSE_DEALER_SUSPENSION_LOG_MODAL, 
    GET_DEALER_CONFIG, GET_DEALER_CONFIG_SUCCESS, 
    GET_DEALER_CONFIG_FAILED, 
    CLOSE_DEALER_CONFIG_MODAL, 
    SET_DEALER_CONFIG_DATA, 
    UPDATE_DEALER_CONFIG, 
    UPDATE_DEALER_CONFIG_SUCCESS, 
    UPDATE_DEALER_CONFIG_FAILED,
    GET_OTHERS_ALLOWED_DURATION,
    GET_OTHERS_ALLOWED_DURATION_SUCCESS,
    GET_OTHERS_ALLOWED_DURATION_FAILED,
    OPEN_EMAIL_CONFIG_MODAL,
    CLOSE_EMAIL_CONFIG_MODAL,
    UPDATE_DEALER_EMAIL_FAILED,
    UPDATE_DEALER_EMAIL_SUCCESS,
    UPDATE_DEALER_EMAIL} from "./actionTypes"

export const getAllChannelList = (dealerId) => {
    return {
        type: GET_ALL_CHANNEL_LIST,
        payload: dealerId
    }
}

export const getAllChannelListSuccessfull = (channelData) => {
    return {
        type: GET_ALL_CHANNEL_LIST_SUCCESS,
        payload: channelData
    }
}
export const dealerList = (companyId,channelId,status) => {
    return {
        type: DEALER_LIST,
        payload: { companyId,channelId,status }
    }
}

export const dealerListSuccessful = (dealerData) => {
    return {
        type: DEALER_LIST_SUCCESSFUL,
        payload: dealerData
    }
}

export const dealerApiError = (error) => {
    return {
        type: DEALER_API_ERROR,
        payload: error
    }
}

export const addDealer = (dealer, companyId) => {
    return {
        type: ADD_DEALER,
        payload: { dealer, companyId }
    }
}

export const addDealerSuccess = (dealer, message) => {
    return {
        type: ADD_DEALER_SUCCESS,
        payload: { dealer, message }
    }
}
export const getCountryList = () => {
    return {
        type: COUNTRY_LIST,
    }
}

export const countryListSuccess = (country) => {
    return {
        type: COUNTRY_LIST_SUCCESS,
        payload: country
    }
}

export const getChannelName = () => {
    return {
        type: CHANNEL_NAME,
    }
}

export const ChannelNameSuccess = (channel) => {
    return {
        type: CHANNEL_NAME_SUCCESS,
        payload: channel
    }
}

export const editDealer = (dealer, id, companyId) => {
    return {
        type: EDIT_DEALER,
        payload: { dealer, id, companyId }
    }
}

export const deleteDealer = (id, status, deleteData, companyId) => {
    return {
        type: DELETE_DEALER,
        payload: { id, status, deleteData, companyId }
    }
}

export const deleteDealerSuccess = (dealer, message) => {
    return {
        type: DELETE_DEALER_SUCCESS,
        payload: { dealer, message }
    }
}

export const stateListing = (countryID) => {
    return {
        type: STATE_LIST,
        payload: countryID
    }
}

export const stateListSuccessfull = (states) => {
    return {
        type: STATE_LIST_SUCCESS,
        payload: states
    }
}

export const dealerApiErrorModal = (error) => {
    return {
        type: API_ERROR_MODAL,
        payload: error
    }
}

export const resetDealerAlert = () => {
    return {
        type: RESET_ALERT,
    }
}

export const dealerFlagUpdate = (flag, dealerId, flagType, companyId, LoginId, data) => {
    return {
        type: DEALER_FLAG_UPDATE,
        payload: { flag, dealerId, flagType, companyId,LoginId, data }
    }
}

export const dealerFlagUpdateSuccessfull = (dealer, message) => {
    return {
        type: DEALER_FLAG_UPDATE_SUCCESS,
        payload: { dealer, message }
    }
}

export const nonVerifiedDealerList = (companyId) => {
    return {
        type: NON_VERIFIED_DEALER_LIST,
        payload: companyId
    }
}

export const nonVerifiedDealerListSuccessful = (dealerData) => {
    return {
        type: NON_VERIFIED_DEALER_LIST_SUCCESS,
        payload: dealerData
    }
}

export const verifyDealer = (flag, dealerId, loginId, companyId) => {
    return {
        type: VERIFY_DEALER,
        payload: { flag, dealerId, loginId, companyId }
    }
}

export const verifyDealerSuccess = (dealerData, message) => {
    return {
        type: VERIFY_DEALER_SUCCESS,
        payload: { dealerData, message }
    }
}

export const updateVerifyUserPassword = (updateData, id, companyId) => {
    return {
        type: CHANGE_VERIFY_USER_PASSWORD,
        payload: { updateData, id, companyId }
    }
}

export const updateVerifyUserPasswordChangeSuccessfully = (dealerData, message) => {
    return {
        type: CHANGE_VERIFY_USER_PASSWORD_SUCCESSFULLY,
        payload: { dealerData, message }
    }
}


export const getDealerSuspensionLog = (dealerId) => {
    return {
        type: GET_DEALER_SUSPENSION_LOG,
        payload: { dealerId }
    }
}

export const getDealerSuspensionLogSuccessful = (data, dealerId) => {
    return {
        type: GET_DEALER_SUSPENSION_LOG_SUCCESS,
        data: data,
        dealerId
    }
}

export const getDealerSuspensionLogFailed = (error) => {
    return {
        type: GET_DEALER_SUSPENSION_LOG_FAILED,
        payload: error
    }
}

export const closeDealerSuspensionLog = () => {
    return {
        type: CLOSE_DEALER_SUSPENSION_LOG_MODAL,
    }
}

export const getDealerConfig = (dealerId) => {
    return {
        type: GET_DEALER_CONFIG,
        payload: { dealerId }
    }
}

export const getDealerConfigSuccessful = (data, dealerId) => {
    return {
        type: GET_DEALER_CONFIG_SUCCESS,
        data: data,
        dealerId
    }
}

export const getDealerConfigFailed = (error) => {
    return {
        type: GET_DEALER_CONFIG_FAILED,
        payload: error
    }
}

export const closeDealerConfigModal = () => {
    return {
        type: CLOSE_DEALER_CONFIG_MODAL,
    }
}

export const setDealerConfigData = (data) => {
    return {
        type: SET_DEALER_CONFIG_DATA,
        data
    }
}

export const updateDealerConfig = (dealerId, loginId, data) => {
    return {
        type: UPDATE_DEALER_CONFIG,
        payload: { dealerId, loginId, data }
    }
}

export const updateDealerConfigSuccessful = (data) => {
    return {
        type: UPDATE_DEALER_CONFIG_SUCCESS,
        data: data
    }
}

export const updateDealerConfigFailed = (error) => {
    return {
        type: UPDATE_DEALER_CONFIG_FAILED,
        error 
    }
}

export const getOthersAllowedDuration = () => {
    return {
        type: GET_OTHERS_ALLOWED_DURATION,
    }
}

export const getOthersAllowedDurationSuccessful = (data) => {
    return {
        type: GET_OTHERS_ALLOWED_DURATION_SUCCESS,
        data: data
    }
}

export const getOthersAllowedDurationFailed = (error) => {
    return {
        type: GET_OTHERS_ALLOWED_DURATION_FAILED,
        payload: error
    }
}
export const openEmailConfigModal = () => {
    return {
        type: OPEN_EMAIL_CONFIG_MODAL
    }
}
export const closeEmailConfigModal = () => {
    return {
        type: CLOSE_EMAIL_CONFIG_MODAL
    }
}
export const updateDealeremail = (dealer, dealerId,companyId) => {
    return {
        type: UPDATE_DEALER_EMAIL,
        payload: { dealer, dealerId,companyId}
    }
}

export const updateDealeremailSuccessful = (message) => {
    return {
        type: UPDATE_DEALER_EMAIL_SUCCESS,
        message
    }
}

export const updateDealeremailFailed = (error) => {
    return {
        type: UPDATE_DEALER_EMAIL_FAILED,
        error 
    }
}