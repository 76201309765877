import { PENDING_ACTIVATION_LIST, PENDING_ACTIVATION_SUCCESSFULL, PENDING_ACTIVATION_FAILED, PENDING_ACTIVATION_ADD, PENDING_ACTIVATION_ADD_SUCCESSFULL } from "./actionTypes";

const initialState = {
    apiError: null, loading: false, pendingData: null, pendingResponse: null
}

const pendingActivation = (state = initialState, action) => {
    switch (action.type) {
        case PENDING_ACTIVATION_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                pendingData: null,
                pendingResponse:null
            }
            break;

        case PENDING_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                pendingData: action.payload.pendingData,
                pendingResponse: null,
                apiError: action.payload.Message,
            }
            break;
        case PENDING_ACTIVATION_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload.Message,
            }
            break;
        case PENDING_ACTIVATION_ADD:
            state = {
                ...state,
                loading: true,
                apiError: null
            }
            break;

        case PENDING_ACTIVATION_ADD_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                pendingData: null,
                pendingResponse: action.payload.pendingResponse,
                apiError: null,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default pendingActivation;