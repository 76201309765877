import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList } from '../../helpers/fackBackend_Helper';
import { getActivationReportSuccess, getActivationReportFailed } from './actions';
import { GET_ACTIVATION_REPORT } from './actionTypes';

function* getActivationReportByDate({ payload: { reportStartDate, reportEndDate } }) {
    try {
        const response = yield call(getList, '/getActivationDataForValidation/'+reportStartDate+'/'+reportEndDate);
        if (response.Status) {
            yield put(getActivationReportSuccess(response.activationData));
        } else {
            yield put(getActivationReportFailed(response));
        }
    } catch (error) {
        yield put(getActivationReportFailed(error));
    }
}

export function* watchGetActivationReportByDate() {
    yield takeEvery(GET_ACTIVATION_REPORT, getActivationReportByDate)
}

function* validateEpayReportSaga() {
    yield all([
        fork(watchGetActivationReportByDate)
    ]);
}

export default validateEpayReportSaga;