import React from 'react'

export default function Footer() {
    return (
        <div className="nk-footer">
            <div className="container-fluid">
                <div className="nk-footer-wrap">
                    <div className="nk-footer-copyright"> &copy; <a href="https://www.virtuzo.in"  target="_blank"  rel="noreferrer">Virtuzo Infosystems Pvt. Ltd.</a>
                </div>
              </div>
            </div>
        </div>
    )
}
