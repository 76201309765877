import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import FilterComponent from "./FilterComponent";
import moment from "moment";
import Spinner from '../components/layout/Spinner';
import DataTable from 'react-data-table-component';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getAllChannelList } from '../store/dealerActivationReport/actions';
import { searchChannelActivation, resetChannelActivationReportPage } from '../store/channelActivationReport/actions';
import { getRepushActivation, addRepushActivation, getActivationChildList } from '../store/repushActivation/actions';
import MultilineModalComponent from '../components/MultilineModalComponent';


function ChannelActivationReport(props) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const dispatch = useDispatch();
    const timezone = process.env.REACT_APP_TIMEZONE;
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [sDate, setSdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [eDate, setEdate] = useState(new Date());
    const [filterText, setFilterText] = useState("");
    const [searchData, setSearchData] = useState({
        companyId: '',
        statusID: '',
        channelID: '',
        fromdate: sDate,
        todate: eDate,
    });

    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
        setSearchData(prevState => ({
            ...prevState,
            companyId: CompanyID,
            channelID: (dealerId > 0) ? dealerId : '',
            fromdate: sDate,
            todate: eDate,
        }));
        // const searchreport = {
        //     companyId: CompanyID,
        //     statusID: '',
        //     channelID: (dealerId > 0) ? dealerId : '',
        //     fromdate: sDate,
        //     todate: eDate,
        // }
        props.getAllChannelList(dealerId);
        props.resetChannelActivationReportPage();
        // props.searchChannelActivation(searchreport)
    }, [])

    const columns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'CHANNEL TXN ',
            width: "180px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'CHANNEL NAME',
            width: "130px",
            selector: row => row.ChannelName,
        },
        {
            name: 'STATUS',
            width: "150px",
            selector: row => row.MasterStatus,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'ORDER NUMBER',
            width: "170px",
            selector: row => row.OrderNumber,
        },
        {
            name: 'ACCOUNT NUMBER',
            width: "170px",
            selector: row => row.AccountNumber,
        },
        {
            name: 'MOBILE NUMBER',
            width: "150px",
            selector: row => row.MobileNumber,
        },
        {
            name: 'CHANNEL DEALER NAME ',
            width: "200px",
            selector: row => row.ChannelDealerName,
        },
        {
            name: 'CHANNEL DEALER HIERARCHY',
            width: "220px",
            selector: row => row.ChannelDealerHierarchy,
        },
        {
            name: 'DATE',
            selector: row => row.RequestedDtTm,
        },
        {
            name: 'TYPE',
            selector: row => row.RequestType,
        },
        {
            name: 'SIM',
            selector: row => row.SimType,
        },
        // {
        //     name: 'PLAN MONTH',
        //     width: "150px",
        //     selector: row => row.PlanMonth,
        // },
        {
            name: 'API STATUS',
            width: "120px",
            selector: row => row.RequestStatus,
        },
        // {
        //     name: 'REMARK',
        //     width: "600px",
        //     selector: row => row.Remark,
        // },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.searchReportData && props.searchReportData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const openTransactionModal = (id) => {
        dispatch(getActivationChildList(id))

    }

    var filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            serial: key + 1,
            ChannelTxnID: (
                <>
                    <p className={data.Multiline === 1 ? `badge rounded-pill bg-outline-danger px-1 d-inline` : 'd-inline'} >{data.ChannelTxnID}</p>
                    {data.Multiline === 1 ? <span onClick={() => openTransactionModal(data.ID)} style={{ 'position': 'absolute' }}><em className="icon ni ni-external" style={{ 'cursor': 'pointer', 'fontSize': '16px', 'marginLeft': '10px' }}></em></span> : ''}
                </>
            ),
            ChannelName: (<p>{data.ChannelName}</p>),
            MasterStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : (data.RequestStatusID === 32) ? 'bg-dark' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : (data.RequestStatusID === 32) ? 'Refunded' : 'Pending'} </span>),
            SerialNumber: (data.SerialNumber !== "null" ? <p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p> : ''),
            OrderNumber: (data.OrderNumber !== "null" ? <p style={{ "whiteSpace": "break-spaces" }}>{data.OrderNumber}</p> : ''),
            AccountNumber: (data.AccountNumber !== "null" ? <p>{data.AccountNumber}</p> : ''),
            EPayUsername: (<p style={{ "whiteSpace": "break-spaces" }}>{data.EPayUsername}</p>),
            MobileNumber: (data.MSISDN !== "null" ? <p>{data.MSISDN}</p> : ''),
            ChannelDealerName: (<p>{data.ChannelDealerName}</p>),
            PlanMonth: (<p>{data.PlanMonth}</p>),
            ChannelDealerHierarchy: (<p>{data.ChannelDealerHierarchy}</p>),
            RequestedDtTm: (<p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
            RequestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 'SimSwap' ? 'text-warning' : data.RequestType === 'PortIn' ? 'text-primary' : 'text-success'}`} >{data.RequestType[0]}</p>),
            activationSource: (data.RequestStatusID === 12 || data.RequestStatusID === 13 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? <p>{data.ActivationSource === 1 ? 'Manual' : 'Automation'}</p> : null),
            SimType: (<p >{data.SimType}</p>),
            // Remark: (<p>{data.Remark === 'null' ? '' : data.Remark}</p>),
            RequestStatus: (<span className={`${(data.RequestStatusID === 13 || data.RequestStatusID === 24) ? 'text-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'text-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{data.RequestStatus}</strong></span>),
        }
    })

    const listData = props.searchReportData && props.searchReportData.map((data, key) => {
        return {
            SNo: key + 1,
            CHANNELTXN: data.ChannelTxnID,
            CHANNELNAME: data.ChannelName,
            STATUS: (data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : 'Pending',
            SERIAL: data.SerialNumber,
            ORDERNUMBER: data.OrderNumber,
            ACCOUNTNUMBER: data.AccountNumber,
            MOBILENUMBER: data.MSISDN,
            CHANNELDEALERNAME: data.ChannelDealerName,
            CHANNELDEALERHIERARCHY: data.ChannelDealerHierarchy,
            DATE: (moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")),
            TYPE: data.RequestType,
            SIM: data.SimType,
            APISTATUS: data.RequestStatus
        }
    });

    const handleChannelChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            channelID: e.target.value
        }));
    }

    const handleStatusChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            statusID: e.target.value
        }));

    }

    const handleStareDateChange = (d) => {
        setSdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromdate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))
    }

    const handleEndDateChange = (d) => {
        setEdate(d);
        setSearchData(prevState => ({
            ...prevState,
            todate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        let searchDataManage = searchData;
        searchDataManage.fromdate = moment(searchDataManage.fromdate).format('YYYY-MM-DD');
        searchDataManage.todate = moment(searchDataManage.todate).format('YYYY-MM-DD');
        dispatch(searchChannelActivation(searchDataManage));
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    }
    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'ChannelActivation' + fileExtension);
        }
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (
        <>
        
        <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Channel Activation Report</h3>
            <div className='card p-2'>
                <div className="card-inner p-0" >
                    <div className="nk-block">
                        <form onSubmit={handleSearchData} method="post">
                            <div className="row g-3">
                                <div className="col-sm-3 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="channelID">Channel</label>
                                        <div className="form-control-wrap">
                                            <select
                                                name="channelID"
                                                className="form-control"
                                                id="channelID"
                                                onChange={(e) => handleChannelChange(e)}
                                            >
                                                <option value="" key="0" name="select">Select</option>
                                                {props.channelData && props.channelData.map(channel => (
                                                    <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="dealerID">Status</label>
                                        <div className="form-control-wrap">
                                            <select name="statusID" className="form-control" id="statusID" defaultValue={searchData.statusID} onChange={(e) => handleStatusChange(e)}>
                                                <option value="" name="All">All</option>
                                                <option value="22, 27" name="AssignedToCare">Assigned to Care</option>
                                                <option value="10" name="Pending">Pending</option>
                                                <option value="11, 18, 19, 20, 21, 22, 27" name="Processing">Processing</option>
                                                <option value="12, 23, 24" name="Processed">Processed</option>
                                                <option value="13" name="Cancelled">Cancelled</option>
                                                {/* <option value="32" name="Refunded">Refunded</option> */}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-3">
                                    <div className="form-group"><label className="form-label" htmlFor="stareDate">Start Date</label>
                                        <div className="form-control-wrap">
                                            <DatePicker onChange={(d) => handleStareDateChange(d)} className={"form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="stareDate" closeOnScroll={true} selected={sDate} placeholderText="MM/DD/YYYY" maxDate={new Date()} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-3">
                                    <div className="form-group"><label className="form-label" htmlFor='endDate'>End Date</label>
                                        <div className="form-control-wrap">
                                            <DatePicker onChange={(d) => handleEndDateChange(d)} className={"form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="endDate" closeOnScroll={true} selected={eDate} placeholderText="MM/DD/YYYY" maxDate={new Date()} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-2 col-md-1'>
                                    <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Search</span> </button>
                                    {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {props.showChannelReportPage && <div className="card p-2" style={{ marginTop: '12px' }}>
                <div className="card-inner p-0" >
                    <div className='d-flex justify-content-between'>
                        <FilterComponent
                            onFilter={e => setFilterText(e.target.value)}
                            onClear={handleClear}
                            filterText={filterText}
                        /><Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                    </div>
                    <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                </div>
            </div>}
        </div>

        {props.childDataModalShow && <MultilineModalComponent activationChildData={props.activationChildData} />}
        </>

    )
}

const mapStateToProps = state => {
    const { channelData } = state.dealerActivationReport;
    const { loading, apiError, searchReportData, showChannelReportPage } = state.channelActivationReport
    const { childDataModalShow, activationChildData } = state.repushActivation
    return { loading, apiError, channelData, searchReportData, showChannelReportPage,childDataModalShow, activationChildData };
}

// export default UserActivationReport
export default withRouter(connect(mapStateToProps, { getAllChannelList, searchChannelActivation, resetChannelActivationReportPage })(ChannelActivationReport));