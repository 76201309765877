import React, { useState, useEffect, useMemo } from 'react'
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { addonList } from '../store/addon/actions';
import DataTable from 'react-data-table-component';
import FilterComponent from "./FilterComponent";
import Spinner from "../components/layout/Spinner";

function Addon(props) {
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    props.addonList();
  }, [])

  const columns = [
    {
      name: "#",
      width: '60px',
      selector: row => row.serial,
    },
    {
      name: "ADDON SERVICE NAME",
      width: "600px",
      selector: row => row.AddonName,
    },
    {
      name: "ADDON SERVICE PRICE",
      selector: row => row.AddonPrice,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '0px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '0px',
      },
    },
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems = props.addon && props.addon.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1

  );

  const filterData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      AddonName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.ServiceName}</p>),
      AddonPrice: (<p>${data.ServicePrice}</p>),
    }
  })

  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }

  return (
    <div className="nk-block-head-content">
      <h3 className="nk-block-title page-title">Addon Services</h3>
      <div className="card p-2" style={{ marginTop: '12px' }}>
        <div className="card-inner p-0" >
          <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} subHeaderComponent={subHeaderComponent} subHeader responsive striped />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { addon, apiError, loading } = state.addon;
  return { addon, apiError, loading };
}

// export default Users;
export default withRouter(connect(mapStateToProps, { addonList })(Addon));