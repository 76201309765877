import React from 'react'
// import { useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from './Footer'
import Header from './Header'
import Sidenav from './Sidenav'

function Main({ children }) {
  // const [floatingButton, setfloatingButton] = useState(false)
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  let header = pathname.charAt(0).toUpperCase(0) + pathname.slice(1)

  return (
    <div className='nk-body bg-lighter npc-default has-sidebar '>
      <div className='nk-app-root'>
        <div className='nk-main'>
          <Sidenav></Sidenav>
          <div className='nk-wrap'>
            <Header></Header>
            <div className='nk-content' header={header}>{children}</div>
            <ul className="nk-sticky-toolbar">
                {/* <li className="demo-layout">
                    <Link to="#" className="toggle tipinfo" style={{'cursor':'pointer'}} data-target="demoML" title="Important Link" onClick={() => setfloatingButton(true)}><em className="icon ni ni-filter"></em></Link>
                </li> */}
            </ul>
            {/* {floatingButton && <div className="toggle-overlay" data-target="addProduct"></div>} */}
            {/* {floatingButton &&
                <div className="nk-demo-panel nk-demo-panel-2x toggle-slide toggle-slide-right toggle-screen-any content-active" data-content="demoML" data-toggle-overlay="true" data-toggle-body="true" data-toggle-screen="any">
                    <div className="nk-demo-head" style={{ marginTop: '20%' }}>
                        <h6 className="mb-0 ">Important Link</h6>
                        <a className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setfloatingButton(false)} ><em className="icon ni ni-cross"></em></a>
                    </div>
                    <div className="p-4">
                        <a href="#" className="btn btn-secondary my-1  w-100">Activate Preloaded SIM</a>


                        <a href="#" className="btn btn-secondary my-1  w-100">Add Funds</a>


                        <a href="#" className="btn btn-secondary my-1  w-100">Instant Preloaded</a>


                        <a href="#" className="btn btn-secondary my-1  w-100">Demo line Redemption</a>

                        <a href="#" className="btn btn-secondary my-1  w-100">Free Phone Activation</a>
                    </div>
                </div>
            } */}
            <Footer></Footer>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Main