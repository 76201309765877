import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData, getList, userDeleteData } from '../../helpers/fackBackend_Helper';
import { ACCESS_TRANSACTION_MODAL, ACTIVATION_REQUEST_INFO, ASSIGN_ACTIVATION, COMPELETE_ACTIVATION, GET_ERROR_CODE, LOCK_ACTIVATION, REMARK_CANCEL, REPUSH_ACTIVATION, UNLOCK_ACTIVATION, UPDATE_REFUND_REQUEST, VIEW_ACTIVATION_LIST, NOTIFY_POS } from './actionTypes';
import { viewActivationListSuccess, viewActivationListFailed, compeleteActivationSuccessfull, dataSubmittedFail, errorCodeListSuccessfull, remarkCancelSuccessfull, activationRequestInfoSuccessfull, lockActivationResultData, lockActivationFailedResult, unLockActivationSuccess, accessTransactionModalSuccess, assignActivationSuccessfull, updateRequestForRefundSuccessfull, updateRequestForRefundFailed, repushActivationSuccessfull, repushActivationFailed, notifyPosSuccess, notifyPosFailed } from './actions';

function* getViewActivationList({ payload: { companyId, loginId, searchData } }) {

    try {
        const response = yield call(addData, '/activationRequestList/' + companyId + '/' + loginId, searchData);
        if (response.Status) {

            yield put(viewActivationListSuccess(response.activations));
        } else {

            yield put(viewActivationListFailed(response));
        }
    } catch (error) {
        yield put(viewActivationListFailed(error));
    }
}

export function* watchGetViewActivation() {
    yield takeEvery(VIEW_ACTIVATION_LIST, getViewActivationList)
}

function* getCompeleteData({ payload: { infoData, id, companyId, loginId, searchData } }) {
    try {
        const response = yield call(userDeleteData, '/updateRequestForComplete/' + id, infoData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/activationRequestList/' + companyId + '/' + loginId, searchData);
            if (responseList.Status) {
                yield put(compeleteActivationSuccessfull(responseList.activations, response.response.Message))
            } else {
                yield put(viewActivationListFailed(response.response.Message));
            }
        } else {
            yield put(dataSubmittedFail(response.response.Message));
        }
    } catch (error) {
        yield put(dataSubmittedFail(error));
    }
}

export function* watchGetCompeleteData() {
    yield takeEvery(COMPELETE_ACTIVATION, getCompeleteData);
}

function* getErrorCode() {
    try {
        const response = yield call(getList, '/showOnPortalErrorCodes')
        if (response.Status) {
            yield put(errorCodeListSuccessfull(response.showOnPortalErrorCodes))
        } else {
            yield put(viewActivationListFailed(response))
        }
    } catch (error) {
        yield put(viewActivationListFailed(error))
    }
}

export function* watchErrorList() {
    yield takeEvery(GET_ERROR_CODE, getErrorCode);
}

function* submitRemark({ payload: { remarkContent, id, companyId, loginId, searchData } }) {
    try {
        const response = yield call(userDeleteData, '/updateRemark/' + id, remarkContent);
        if (response.response.Status) {
            const responseList = yield call(addData, '/activationRequestList/' + companyId + '/' + loginId, searchData);
            if (responseList.Status) {
                yield put(remarkCancelSuccessfull(responseList.activations, response.response.Message))
            } else {
                yield put(viewActivationListFailed(response.response.Message));
            }
        } else {
            yield put(dataSubmittedFail(response.response.Message));
        }
    } catch (error) {
        yield put(dataSubmittedFail(error));
    }
}

export function* watchSubmitRemark() {
    yield takeEvery(REMARK_CANCEL, submitRemark);
}

function* getActivationRequestInfo({ payload: { id } }) {
    try {
        const response = yield call(getList, '/getactivationrequesthistory/' + id);
        if (response.Status) {
            yield put(activationRequestInfoSuccessfull(response.activationHistory));
        } else {
            yield put(viewActivationListFailed(response));
        }
    } catch (error) {
        yield put(viewActivationListFailed(error));
    }
}

export function* watchActivvationRequestInfo() {
    yield takeEvery(ACTIVATION_REQUEST_INFO, getActivationRequestInfo);
}

function* getAccessTransactionModal({ payload: { id } }) {
    try {
        const response = yield call(getList, '/activationRequestInfo/' + id);
        if (response.Status) {
            yield put(accessTransactionModalSuccess(response.requestData));
        } else {
            yield put(viewActivationListFailed(response));
        }
    } catch (error) {
        yield put(viewActivationListFailed(error));
    }
}
export function* watchAccessTransactionModal() {
    yield takeEvery(ACCESS_TRANSACTION_MODAL, getAccessTransactionModal);
}

function* getLockActivation({ payload: { lockActivationData, searchData } }) {
    try {
        const response = yield call(addData, '/lockActivation', lockActivationData);
        if (response.lockData[0].Status) {
            const responseList = yield call(addData, '/activationRequestList/' + lockActivationData.companyId + '/' + lockActivationData.loginId, searchData);
            if (responseList.Status) {
                yield put(lockActivationResultData(response.lockData[0].Message, responseList.activations));
            }
        } else {
            yield put(lockActivationFailedResult(response.lockData[0]));
        }
    } catch (error) {
        yield put(viewActivationListFailed(error));
    }
}

export function* watchLockActivation() {
    yield takeEvery(LOCK_ACTIVATION, getLockActivation);
}

function* getUnlockActivation({ payload: { unLockData, searchData } }) {
    try {
        const response = yield call(addData, '/releaseLockActivation/', unLockData);
        if (response.lockData[0].Status) {
            const responseList = yield call(addData, '/activationRequestList/' + unLockData.companyId + '/' + unLockData.loginId, searchData);
            if (responseList.Status) {
                yield put(unLockActivationSuccess(response.lockData[0].Message, responseList.activations));
            }
        } else {
            yield put(lockActivationFailedResult(response.lockData[0]));
        }
    } catch (error) {
        yield put(viewActivationListFailed(error));
    }
}

export function* watchUnlockActivation() {
    yield takeEvery(UNLOCK_ACTIVATION, getUnlockActivation);
}

function* assignPendingActivation({ payload: { companyId, loginId, searchData, pendingRequests } }) {

    try {
        const response = yield call(addData, '/assignedToCareActivation/' + loginId, pendingRequests);
        if (response.Status) {

            const responseList = yield call(addData, '/activationRequestList/' + companyId + '/' + loginId, searchData);
            if (responseList.Status) {

                yield put(assignActivationSuccessfull(responseList.activations, response.assignedData[0].Message))
            } else {

                yield put(viewActivationListFailed(response.response.Message));
            }
        } else {
            yield put(viewActivationListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(viewActivationListFailed(error));
    }
}

export function* watchPendingAssignedActivation() {
    yield takeEvery(ASSIGN_ACTIVATION, assignPendingActivation)
}

function* updateRefundRequest({ payload: { refundData, requestId, companyId, loginId, searchData } }) {
    try {
        const response = yield call(userDeleteData, '/updateRequestForRefund/' + requestId, refundData);
        if (response.Status) {
            const responseList = yield call(addData, '/activationRequestList/' + companyId + '/' + loginId, searchData);
            if (responseList.Status) {
                yield put(updateRequestForRefundSuccessfull(responseList.activations, response.refundData.Message))
            } else {
                yield put(viewActivationListFailed(response.refundData.Message));
            }
        } else {
            yield put(updateRequestForRefundFailed(response.refundData.Message));
        }
    } catch (error) {
        yield put(updateRequestForRefundFailed(error));
    }
}

export function* watchRefundRequest() {
    yield takeEvery(UPDATE_REFUND_REQUEST, updateRefundRequest);
}

function* notifyPos({ payload: { data, activationID } }) {
    try {
        const response = yield call(addData, '/notifyPos', data);
        if (response.Status) {
            yield put(notifyPosSuccess(response.Message, activationID))
        } else {
            yield put(notifyPosFailed(response.Message, activationID));
        }
    } catch (error) {
        yield put(notifyPosFailed(error, activationID));
    }
}

export function* watchNotifyPos() {
    yield takeEvery(NOTIFY_POS, notifyPos);
}

// function* repushActivationRequest({ payload: { requestId, companyId, loginId, searchData } }) {
//     try {
//         const response = yield call(getList, '/repushActivation/' + requestId + '/' + loginId);
//         if (response.Status) {
//             const responseList = yield call(addData, '/activationRequestList/' + companyId + '/' + loginId, searchData);
//             if (responseList.Status) {
//                 yield put(repushActivationSuccessfull(responseList.activations, response.Message))
//             } else {
//                 yield put(viewActivationListFailed(response.Message));
//             }
//         } else {
//             yield put(repushActivationFailed(response.Message));
//         }
//     } catch (error) {
//         yield put(repushActivationFailed(error));
//     }
// }

// export function* watchRepushActivation() {
//     yield takeEvery(REPUSH_ACTIVATION, repushActivationRequest);
// }


function* viewActivationSaga() {
    yield all([
        fork(watchGetViewActivation),
        fork(watchGetCompeleteData),
        fork(watchErrorList),
        fork(watchSubmitRemark),
        fork(watchActivvationRequestInfo),
        fork(watchLockActivation),
        fork(watchUnlockActivation),
        fork(watchAccessTransactionModal),
        fork(watchPendingAssignedActivation),
        fork(watchRefundRequest),
        // fork(watchRepushActivation),
        fork(watchNotifyPos),
    ])
}
export default viewActivationSaga;