import DataTable from "react-data-table-component";
import Spinner from "../components/layout/Spinner";
import React, { useState, useEffect, useMemo } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import FilterComponent from "./FilterComponent";
import { PERMISSIONS } from "../Permission/Permission";
import { serverList } from "../store/server/actions";

function Server(props) {
  const dispatch = useDispatch();
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    const companyId = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).CompanyID
      : null;
    props.serverList(companyId);
  }, []);

  const columns = [
    {
      name: "#",
      width: "40px",
      selector: (row) => row.serial,
    },
    {
      name: "JAR SERVER NAME",
      selector: (row) => row.jarServerName,
    },
    {
      name: "STATUS",
      selector: (row) => row.status,
    },
    {
      name: "LAST ACCESSED ON",
      selector: (row) => row.lastAccessedOn,
    },
    {
      name: "LAST ACCESSED AT",
      selector: (row) => row.lastAccessedAt,
    },
    {
      name: "JAR STATUS",
      selector: (row) => row.timediff,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "0px",
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "0px",
      },
    },
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const filteredItems =
    props.serverData &&
    props.serverData.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
        -1
    );

  const filterData =
    filteredItems &&
    filteredItems.map((data, key) => {
      return {
        serial: <p>{key + 1}</p>,
        jarServerName: <p>{data.JARServerName}</p>,
        status: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatus === 13) ? 'bg-danger' : (data.RequestStatus === 11 || (data.RequestStatus >= 18 && data.RequestStatus <= 22) || data.RequestStatus === 27) ? 'bg-warning' : (data.RequestStatus === 12 || data.RequestStatus === 23 || data.RequestStatus === 24) ? 'bg-success' : (data.RequestStatus === 32) ? 'bg-dark' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatus === 13) ? 'Cancelled' : (data.RequestStatus === 11 || (data.RequestStatus >= 18 && data.RequestStatus <= 21)) ? 'Processing' : (data.RequestStatus === 12 || data.RequestStatus === 23 || data.RequestStatus === 24) ? 'Processed' : (data.RequestStatus === 22 || data.RequestStatus === 27) ? 'Assigned to Care' : (data.RequestStatus === 32) ? 'Refunded' : 'Pending'} </span>),
        timediff:
          data.timediff === 1 ? (
            <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">
              UP
            </span>
          ) : (
            <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">
              DOWN
            </span>
          ),
        lastAccessedOn: (
          <p>
            {data.lastAccessedOn !== null ? moment(new Date(data.lastAccessedOn))
              .format("MM/DD/YYYY") : ''}
          </p>
        ),
        lastAccessedAt: (
          <p>
            {data.lastAccessedOn !== null ? moment(new Date(data.lastAccessedOn))
              .format("hh:mm:ss a") : ''}
          </p>
        ),
        // CreatedDate: (
        //   <p>
        //     {moment(new Date(data.CreatedDate))
        //       .tz(process.env.REACT_APP_TIMEZONE)
        //       .format("MM/DD/YYYY")}
        //   </p>
        // ),
        // IsActive:
        //   data.IsActive === 1 ? (
        //     <span className="badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex">
        //       Active
        //     </span>
        //   ) : (
        //     <span className="badge badge-sm badge-dot has-bg bg-danger d-none d-sm-inline-flex">
        //       Inactive
        //     </span>
        //   ),
      };
    });

  return (
    <div className="nk-block-head-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="nk-block-title page-title">Server List</h3>
      </div>
      <div className="card p-2" style={{ marginTop: "12px" }}>
        <div className="card-inner p-0">
          <DataTable
            pagination
            columns={columns}
            data={filterData}
            customStyles={customStyles}
            subHeaderComponent={subHeaderComponent}
            progressPending={props.loading}
            progressComponent={<Spinner />}
            subHeader
            striped
            responsive
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { serverData, apiError, Message, loading } = state.server;
  return { serverData, apiError, Message, loading };
};
// export default Users;
export default withRouter(connect(mapStateToProps, { serverList })(Server));
