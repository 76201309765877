import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { CHECK_LOGIN } from './actionTypes';
import { apiError, loginUserSuccessful } from './actions';

// AUTH related methods
import { postLogin } from '../../helpers/fackBackend_Helper';


//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(postLogin, '/user_login', { username: user.username, password: user.password });
        if (response.Success) {
            localStorage.setItem("authUser", JSON.stringify(response.user));
            localStorage.setItem("token", JSON.stringify(response.token));
            // localStorage.setItem("userRights", JSON.stringify(response.userRights));
            yield put(loginUserSuccessful(response.user));
            if(response.user.RoleID === 30){
                history.push('/activation-report');
            }else{
                history.push('/dashboard');
            }
        }
        else {
            yield put(apiError(response.Message));
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
    ]);
}

export default loginSaga;