import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { getActivationReport, resetValidationReport } from '../store/validateEpayReport/actions';
import Spinner from '../components/layout/Spinner';
import Papa from "papaparse";
import moment from "moment";
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import * as XlsxStyle from 'xlsx-js-style';
import FileSaver from 'file-saver';

const fileExtension = '.xlsx';
const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

function ValidateEpayReport(props) {
    const dispatch = useDispatch();
    const [reportStartDate, setReportStartDate] = useState(new Date());
    const [reportEndDate, setReportEndDate] = useState(new Date());
    const [uploadFile, setUploadFile] = useState([]);
    const [uploadedCSVData, setUploadedCSVData] = useState(null);
    const [rStartDateError,setRStartDateError] = useState(false);
    const [rEndDateError,setREndDateError] = useState(false);
    const [activeTab, setActiveTab] = useState("epaytab");

    // useEffect(() => {
    //     const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
    // }, []);

    const columnsEpay = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'SubAccount',
            width: "100px",
            selector: row => row.SubAccountID,
        },
        {
            name: 'Dealer Code',
            width: "100px",
            selector: row => row.DealerCode,
        },
        {
            name: 'Dealer Name',
            width: "220px",
            selector: row => row.DealerName,
        },
        {
            name: 'Sales Clerk',
            width: "100px",
            selector: row => row.SalesClerk,
        },
        {
            name: 'Reference#',
            width: "120px",
            selector: row => row.ReferenceNumber,
        },
        {
            name: 'Phone#',
            width: "100px",
            selector: row => row.PhoneNumber,
        },
        {
            name: 'Serial Number',
            width: "180px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'IMEI',
            width: "140px",
            selector: row => row.IMEI,
        },
        {
            name: 'Amount',
            width: "80px",
            selector: row => row.Amount,
        },
        {
            name: 'EPay Timestamp',
            width: "130px",
            selector: row => row.EpayTimestamp,
        },
        {
            name: 'Care Timestamp',
            width: "130px",
            selector: row => row.CareTimestamp,
        },
    ];

    const columnsCare = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'Channel TXN ID',
            width: "130px",
            selector: row => row.ChannelTXNID,
        },
        {
            name: 'Dealer Name',
            width: "220px",
            selector: row => row.DealerName,
        },
        {
            name: 'Sales Clerk',
            width: "100px",
            selector: row => row.SalesClerk,
        },
        {
            name: 'Reference#',
            width: "120px",
            selector: row => row.ReferenceNumber,
        },
        {
            name: 'Phone#',
            width: "100px",
            selector: row => row.PhoneNumber,
        },
        {
            name: 'Serial Number',
            width: "180px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'IMEI',
            width: "140px",
            selector: row => row.IMEI,
        },
        {
            name: 'Amount',
            width: "80px",
            selector: row => row.Amount,
        },
        {
            name: 'EPay Timestamp',
            width: "130px",
            selector: row => row.EpayTimestamp,
        },
        {
            name: 'Care Timestamp',
            width: "130px",
            selector: row => row.CareTimestamp,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const handleExcelUpload = (e) => {
        setUploadFile(e.target.files[0]);
    }

    const handleReportStartDateChange = (d) => {
        setRStartDateError(false);
        setReportStartDate(d);
    }

    const handleReportEndDateChange = (d) => {
        setREndDateError(false);
        setReportEndDate(d);
    }

    const handleAssignDealerToRequest = (e) => {
        e.preventDefault();
        setActiveTab('epaytab');
        dispatch(resetValidationReport());
        var newReportStartDate = moment(new Date(reportStartDate)).format("YYYY-MM-DD");
        var newReportEndDate = moment(new Date(reportEndDate)).format("YYYY-MM-DD");

        if((!reportStartDate || reportStartDate == "") && (!reportEndDate || reportEndDate == "")){
            setRStartDateError(true);
            setREndDateError(true);
        }
        else if((!reportStartDate || reportStartDate == "")){
            setRStartDateError(true);
        }
        else if((!reportEndDate || reportEndDate == "")){
            setREndDateError(true);
        }
        else if(uploadFile){
            const fileReader = new FileReader();
        
            if (uploadFile) {
                fileReader.onload = ({target}) => {
                    const csvOutput = Papa.parse(target.result, {header: true});
                    const parsedData = csvOutput?.data;
                    const columnsCsv = Object.keys(parsedData[0]);

                    if(columnsCsv[4] === 'Sales Clerk' && columnsCsv[5] === 'epay Reference Number' 
                        && columnsCsv[8] === 'Phone Number at Time of Activation'
                        && columnsCsv[9] === 'SIM at Time of Activation'
                        && columnsCsv[10] === 'IMEI at Time of Activation'
                        && columnsCsv[14] === 'Activation Amount'){

                        setUploadedCSVData(parsedData);

                        dispatch(getActivationReport(newReportStartDate, newReportEndDate));
                    }
                    else{
                        alert(`Column headers not matching with expected E-Pay csv file`);
                        return false;
                    }
                };
                fileReader.readAsText(uploadFile);
            }
        }
        else{
            return false;
        }
    }

    var filterEpayData = props.activationData.length > 0 && uploadedCSVData && uploadedCSVData.filter((item) => item['IBO SubAccount ID'] != '').map((data, key) => {
        var checkData = props.activationData.filter((item) => (Number(item.EpayTransactionID) === Number(data['epay Reference Number'])
                                                                && item.MSISDN === data['Phone Number at Time of Activation']));

        var checkClass = 'text-success';
        var checkDealer = 'text-success';
        var checkSerial = 'text-success';
        var checkPhone = 'text-success';
        var checkIMEI = 'text-success';
        var checkAmount = 'text-success';
        var checkDate = 'text-success';
        if(checkData.length > 0){
            if(checkData[0].EPayUsername !== data['Sales Clerk']){
                checkDealer = 'text-danger';
            }
            
            if(!data['SIM at Time of Activation'].includes(checkData[0].SerialNumber)){
                checkSerial = 'text-danger';
            }

            if(checkData[0].MSISDN !== data['Phone Number at Time of Activation']){
                checkPhone = 'text-danger';
            }

            if(!data['IMEI at Time of Activation'].includes(checkData[0].IMEI) && !data['IMEI at Time of Activation'].includes(checkData[0].UsedIMEI)){
                checkIMEI = 'text-danger';
            }

            if(Number(checkData[0].AmountCharged) !== Number(data['Activation Amount'])){
                checkAmount = 'text-danger';
            }

            if(moment(new Date(checkData[0].ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD") !== moment.tz(data['epay Activation Timestamp'], "MM/DD/YYYY hh:mm:ss A", "America/Chicago").tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD")){
                checkDate = 'text-danger';
            }
        }
        else{
            checkClass = 'text-danger';
            checkDealer = 'text-danger';
            checkSerial = 'text-danger';
            checkPhone = 'text-danger';
            checkIMEI = 'text-danger';
            checkAmount = 'text-danger';
            checkDate = 'text-danger';
        }

        return {
            serial: key + 1,
            SubAccountID: (<p>{data['IBO SubAccount ID']}</p>),
            DealerCode: (<p style={{ "textAlign": "right" }}>{data['Dealer Code']}</p>),
            DealerName: (<p style={{ "whiteSpace": "break-spaces" }}>{data['Dealer Name']}</p>),
            SalesClerk: (<p className={checkDealer} style={{ "whiteSpace": "break-spaces" }}>{data['Sales Clerk']}</p>),
            ReferenceNumber: (<p className={checkClass}>{data['epay Reference Number']}</p>),
            PhoneNumber: (<p className={checkPhone}>{data['Phone Number at Time of Activation']}</p>),
            SerialNumber: (<p className={checkSerial} style={{ "whiteSpace": "break-spaces" }}>{data['SIM at Time of Activation'] && (data['SIM at Time of Activation'].replace('="', '')).replace('"', '')}</p>),
            IMEI: (<p className={checkIMEI}>{data['IMEI at Time of Activation'] && (data['IMEI at Time of Activation'].replace('="', '')).replace('"', '')}</p>),
            Amount: (<p className={checkAmount}>${Number(data['Activation Amount'])}</p>),
            EpayTimestamp: (<p className={checkDate}>{moment.tz(data['epay Activation Timestamp'], "MM/DD/YYYY hh:mm:ss A", "America/Chicago").tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm")}</p>),
            CareTimestamp: (<p>{checkData && checkData[0] && moment(new Date(checkData[0].ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm")}</p>),
        }
    });

    var filterCareData = props.activationData.length > 0 && uploadedCSVData && props.activationData.map((data, key) => {
        var checkData = uploadedCSVData.filter((item) => (Number(item['epay Reference Number']) === Number(data.EpayTransactionID)
                                                                && item['Phone Number at Time of Activation'] === data.MSISDN));

        var checkClass = 'text-success';
        var checkDealer = 'text-success';
        var checkSerial = 'text-success';
        var checkPhone = 'text-success';
        var checkIMEI = 'text-success';
        var checkAmount = 'text-success';
        var checkDate = 'text-success';
        if(checkData.length > 0){
            if(checkData[0]['Sales Clerk'] !== data.EPayUsername){
                checkDealer = 'text-danger';
            }
            
            if(!checkData[0]['SIM at Time of Activation'].includes(data.SerialNumber)){
                checkSerial = 'text-danger';
            }

            if(data.MSISDN !== checkData[0]['Phone Number at Time of Activation']){
                checkPhone = 'text-danger';
            }

            if(!checkData[0]['IMEI at Time of Activation'].includes(data.IMEI) && !checkData[0]['IMEI at Time of Activation'].includes(data.UsedIMEI)){
                checkIMEI = 'text-danger';
            }

            if(Number(data.AmountCharged) !== Number(checkData[0]['Activation Amount'])){
                checkAmount = 'text-danger';
            }

            if(moment(new Date(data.ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD") !== moment.tz(checkData[0]['epay Activation Timestamp'], "MM/DD/YYYY hh:mm:ss A", "America/Chicago").tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD")){
                checkDate = 'text-danger';
            }
        }
        else{
            checkClass = 'text-danger';
            checkDealer = 'text-danger';
            checkSerial = 'text-danger';
            checkPhone = 'text-danger';
            checkIMEI = 'text-danger';
            checkAmount = 'text-danger';
            checkDate = 'text-danger';
        }

        return {
            serial: key + 1,
            ChannelTXNID: (<p>{data.ChannelTxnID}</p>),
            DealerCode: (<p style={{ "textAlign": "right" }}>{data.ACode}</p>),
            DealerName: (<p style={{ "whiteSpace": "break-spaces" }}>{data.DealerName}</p>),
            SalesClerk: (<p className={checkDealer} style={{ "whiteSpace": "break-spaces" }}>{data.EPayUsername}</p>),
            ReferenceNumber: (<p className={checkClass}>{data.EpayTransactionID}</p>),
            PhoneNumber: (<p className={checkPhone}>{data.MSISDN}</p>),
            SerialNumber: (<p className={checkSerial} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>),
            IMEI: (<p className={checkIMEI}>{(data.UsedIMEI) ? data.UsedIMEI : data.IMEI}</p>),
            Amount: (<p className={checkAmount}>${Number(data.AmountCharged)}</p>),
            EpayTimestamp: (<p className={checkDate}>{checkData && checkData[0] && moment.tz(checkData[0]['epay Activation Timestamp'], "MM/DD/YYYY hh:mm:ss A", "America/Chicago").tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm")}</p>),
            CareTimestamp: (<p>{moment(new Date(data.ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm")}</p>),
        }
    });

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    // This is for excel export
    
    const exportToCSVEpay = async () => {
        let excelData = props.activationData.length > 0 && uploadedCSVData && uploadedCSVData.filter((item) => item['IBO SubAccount ID'] != '').map((data, key) => {
            var checkData = props.activationData.filter((item) => (Number(item.EpayTransactionID) === Number(data['epay Reference Number'])
                && item.MSISDN === data['Phone Number at Time of Activation']));

            var checkClass = '44bd32';
            var checkDealer = '44bd32';
            var checkSerial = '44bd32';
            var checkPhone = '44bd32';
            var checkIMEI = '44bd32';
            var checkAmount = '44bd32';
            var checkDate = '44bd32';
            if (checkData.length > 0) {
                if (checkData[0].EPayUsername !== data['Sales Clerk']) {
                    checkDealer = 'e74c3c';
                }

                if (!data['SIM at Time of Activation'].includes(checkData[0].SerialNumber)) {
                    checkSerial = 'e74c3c';
                }

                if (checkData[0].MSISDN !== data['Phone Number at Time of Activation']) {
                    checkPhone = 'e74c3c';
                }

                if (!data['IMEI at Time of Activation'].includes(checkData[0].IMEI) && !data['IMEI at Time of Activation'].includes(checkData[0].UsedIMEI)) {
                    checkIMEI = 'e74c3c';
                }

                if (Number(checkData[0].AmountCharged) !== Number(data['Activation Amount'])) {
                    checkAmount = 'e74c3c';
                }

                if (moment(new Date(checkData[0].ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD") !== moment.tz(data['epay Activation Timestamp'], "MM/DD/YYYY hh:mm:ss A", "America/Chicago").tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD")) {
                    checkDate = 'e74c3c';
                }
            }
            else {
                checkClass = 'e74c3c';
                checkDealer = 'e74c3c';
                checkSerial = 'e74c3c';
                checkPhone = 'e74c3c';
                checkIMEI = 'e74c3c';
                checkAmount = 'e74c3c';
                checkDate = 'e74c3c';
            }

            return {
                serial: key + 1,
                SubAccountID: {
                    v: data['IBO SubAccount ID'],
                },
                DealerCode: data['Dealer Code'],
                DealerName: data['Dealer Name'],
                SalesClerk: {
                    v: data['Sales Clerk'],
                    s: {
                        font: {
                            color: {
                                rgb: checkDealer
                            }
                        }
                    }
                },
                ReferenceNumber: {
                    v: Number(data['epay Reference Number']),
                    s: {
                        font: {
                            color: {
                                rgb: checkClass
                            }
                        }
                    }
                },
                PhoneNumber: {
                    v: data['Phone Number at Time of Activation'],
                    s: {
                        font: {
                            color: {
                                rgb: checkPhone
                            }
                        }
                    }
                },
                SerialNumber: {
                    v: data['SIM at Time of Activation'] && (data['SIM at Time of Activation'].replace('="', '')).replace('"', ''),
                    s: {
                        font: {
                            color: {
                                rgb: checkSerial
                            }
                        }
                    }
                },
                IMEI: {
                    v: data['IMEI at Time of Activation'] && (data['IMEI at Time of Activation'].replace('="', '')).replace('"', ''),
                    s: {
                        font: {
                            color: {
                                rgb: checkIMEI
                            }
                        }
                    }
                },
                Amount: {
                    v: `$${Number(data['Activation Amount'])}`,
                    s: {
                        font: {
                            color: {
                                rgb: checkAmount
                            }
                        }
                    }
                },
                EpayTimestamp: {
                    v: moment.tz(data['epay Activation Timestamp'], "MM/DD/YYYY hh:mm:ss A", "America/Chicago").tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm"),
                    s: {
                        font: {
                            color: {
                                rgb: checkDate
                            }
                        }
                    }
                },
                CareTimestamp: {
                    v: checkData && checkData[0] && moment(new Date(checkData[0].ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm"),
                },
            }
        });

        const tableDataEpay = excelData.map((item, index) => {
            return item;
        });

        if (tableDataEpay.length > 0) {
            const ws = XlsxStyle.utils.json_to_sheet(tableDataEpay);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XlsxStyle.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'EpayReport' + fileExtension);
        }
    }

    const exportToCSVCare = async () => {
        let excelData = props.activationData.length > 0 && uploadedCSVData && props.activationData.filter((item) => item['IBO SubAccount ID'] != '').map((data, key) => {
            var checkData = uploadedCSVData.filter((item) => (Number(item['epay Reference Number']) === Number(data.EpayTransactionID)
                && item['Phone Number at Time of Activation'] === data.MSISDN));

            var checkClass = '44bd32';
            var checkDealer = '44bd32';
            var checkSerial = '44bd32';
            var checkPhone = '44bd32';
            var checkIMEI = '44bd32';
            var checkAmount = '44bd32';
            var checkDate = '44bd32';
            if (checkData.length > 0) {
                if (checkData[0]['Sales Clerk'] !== data.EPayUsername) {
                    checkDealer = 'e74c3c';
                }

                if (!checkData[0]['SIM at Time of Activation'].includes(data.SerialNumber)) {
                    checkSerial = 'e74c3c';
                }

                if (data.MSISDN !== checkData[0]['Phone Number at Time of Activation']) {
                    checkPhone = 'e74c3c';
                }

                if (!checkData[0]['IMEI at Time of Activation'].includes(data.IMEI) && !checkData[0]['IMEI at Time of Activation'].includes(data.UsedIMEI)) {
                    checkIMEI = 'e74c3c';
                }

                if (Number(data.AmountCharged) !== Number(checkData[0]['Activation Amount'])) {
                    checkAmount = 'e74c3c';
                }

                if (moment(new Date(data.ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD") !== moment.tz(checkData[0]['epay Activation Timestamp'], "MM/DD/YYYY hh:mm:ss A", "America/Chicago").tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD")) {
                    checkDate = 'e74c3c';
                }
            }else {
                checkClass = 'e74c3c';
                checkDealer = 'e74c3c';
                checkSerial = 'e74c3c';
                checkPhone = 'e74c3c';
                checkIMEI = 'e74c3c';
                checkAmount = 'e74c3c';
                checkDate = 'e74c3c';
            }

            return {
                serial: key + 1,
                ChannelTXNID: data.ChannelTxnID,
                DealerCode: data.ACode,
                DealerName: data.DealerName,
                SalesClerk: {
                    v: data.EPayUsername,
                    s: {
                        font: {
                            color: {
                                rgb: checkDealer
                            }
                        }
                    }
                },
                ReferenceNumber: {
                    v: Number(data.EpayTransactionID),
                    s: {
                        font: {
                            color: {
                                rgb: checkClass
                            }
                        }
                    }
                },
                PhoneNumber: {
                    v: data.MSISDN,
                    s: {
                        font: {
                            color: {
                                rgb: checkPhone
                            }
                        }
                    }
                },
                SerialNumber: {
                    v: data.SerialNumber,
                    s: {
                        font: {
                            color: {
                                rgb: checkSerial
                            }
                        }
                    }
                },
                IMEI: {
                    v: data.IMEI,
                    s: {
                        font: {
                            color: {
                                rgb: checkIMEI
                            }
                        }
                    }
                },
                Amount: {
                    v: `$${Number(data.AmountCharged)}`,
                    s: {
                        font: {
                            color: {
                                rgb: checkAmount
                            }
                        }
                    }
                },
                EpayTimestamp: {
                    v: checkData && checkData[0] && moment.tz(checkData[0]['epay Activation Timestamp'], "MM/DD/YYYY hh:mm:ss A", "America/Chicago").tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm"),
                    s: {
                        font: {
                            color: {
                                rgb: checkDate
                            }
                        }
                    }
                },
                CareTimestamp: moment(new Date(data.ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm"),
            }
        });

        const tableDataEpay = excelData.map((item, index) => {
            return item;
        });

        if (tableDataEpay.length > 0) {
            const ws = XlsxStyle.utils.json_to_sheet(tableDataEpay);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XlsxStyle.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'CareReport' + fileExtension);
        }
    }

    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Validate E-Pay Report</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleAssignDealerToRequest} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="fromDate">Report Start Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleReportStartDateChange(d)} className={ rStartDateError ? "form-control date-picker border border-danger" : "form-control date-picker"}  autoComplete="off" dateFormat="MM/dd/yyyy" name="fromDate" closeOnScroll={true} selected={reportStartDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="toDate">Report End Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleReportEndDateChange(d)} className={ rEndDateError ? "form-control date-picker border border-danger" : "form-control date-picker"}  autoComplete="off" dateFormat="MM/dd/yyyy" name="toDate" closeOnScroll={true} selected={reportEndDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">    
                                            <label className="form-label" htmlFor="activationFile">Upload File</label>    
                                            <div className="form-control-wrap">        
                                                <div className="form-file">
                                                    <input type="file" onChange={(e) => handleExcelUpload(e)} className={ !uploadFile ? "form-control border border-danger form-file-label" : "form-control"} name='activationFile' id="activationFile" accept=".csv" selected={uploadFile} />
                                                </div>    
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2 col-md-1'>
                                        <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>
                                            {props.loading === true && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Validate</span> 
                                        </button>
                                    </div>                              
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {props.apiError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}
                {props.checkFlag && <><ul className="nav nav-tabs">
                    <li className="nav-item">
                        <a className={`nav-link ${(activeTab == 'epaytab') ? 'active' : ''}`} data-toggle="tab" href="#epaytab" onClick={() => setActiveTab('epaytab')}><span>E-Pay data based on care</span></a>
                    </li>
                    <li className="nav-item">
                        <a className={`nav-link ${(activeTab == 'caretab') ? 'active' : ''}`} data-toggle="tab" href="#caretab" onClick={() => setActiveTab('caretab')}><span>Care data based on e-pay</span></a>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className={`tab-pane ${(activeTab == 'epaytab') ? 'active' : ''}`} id="epaytab">
                        {filterEpayData && <div className="card p-2" style={{ marginTop: '12px' }}>
                                <div className='d-flex justify-content-between mt-2'>
                                    <Link to="#" style={{ marginLeft: 'auto' }} className="btn btn-success " onClick={() => exportToCSVEpay()}><em className="icon ni ni-download"></em> Export</Link>
                                </div>
                                <div className="card-inner p-0" >
                                    <DataTable columns={columnsEpay} data={filterEpayData} customStyles={customStyles} progressComponent={<Spinner />} progressPending={props.loading} responsive striped />
                                </div>
                        </div>}
                    </div>
                    <div className={`tab-pane ${(activeTab == 'caretab') ? 'active' : ''}`} id="caretab">
                        {filterCareData && <div className="card p-2" style={{ marginTop: '12px' }}>
                                <div className='d-flex justify-content-between mt-2'>
                                    <Link to="#" style={{ marginLeft: 'auto' }} className="btn btn-success " onClick={() => exportToCSVCare()}><em className="icon ni ni-download"></em> Export</Link>
                                </div>
                                <div className="card-inner p-0" >
                                    <DataTable columns={columnsCare} data={filterCareData} customStyles={customStyles} progressComponent={<Spinner />} progressPending={props.loading} responsive striped />
                                </div>
                        </div>}
                    </div>
                </div></>}
            </div>
        </>
    )
}

const mapStateToProps = state => {
    const { apiError, loading, checkFlag, activationData } = state.validateEpayReport
    return { apiError, loading, checkFlag, activationData };
}

export default withRouter(connect(mapStateToProps, { getActivationReport, resetValidationReport })(ValidateEpayReport));