import { REPUSH_ACTIVATION_LIST, REPUSH_ACTIVATION_SUCCESSFULL, REPUSH_ACTIVATION_FAILED, REPUSH_ACTIVATION, REPUSH_ACTIVATION_ADD_SUCCESSFULL,MESSAGE_ALERT_HIDE,RESET_ALERT_ACTIVATION,GET_ERROR_CODE,ACTIVATION_REQUEST_INFO,ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL,VIEW_ACTIVATION_LIST_FAILED, PREPARE_CHILD_DATA_MODAL, GET_ACTIVATION_CHILD_LIST,GET_ACTIVATION_CHILD_LIST_SUCCESSFUL,GET_ACTIVATION_CHILD_LIST_FAIL, RESET_CHILD_ACTIVATION_MODAL } from "./actionTypes"

export const getRepushActivation = (companyId, loginId, searchData) => {
    return {
        type: REPUSH_ACTIVATION_LIST,
        payload: { companyId, loginId, searchData }
    }
}

export const getRepushActivationSuccess = (repushData) => {
    return {
        type: REPUSH_ACTIVATION_SUCCESSFULL,
        payload: { repushData }
    }
}

export const getRepushActivationFailed = (error) => {
    return {
        type: REPUSH_ACTIVATION_FAILED,
        payload: error
    }
}

export const addRepushActivation = (companyId, loginId, searchData, repushRequests) => {
    return {
        type: REPUSH_ACTIVATION,
        payload: { companyId, loginId, searchData, repushRequests }
    }
}

export const addRepushActivationSuccess = (repushData, message) => {
    return {
        type: REPUSH_ACTIVATION_ADD_SUCCESSFULL,
        payload: { repushData, message }
    }
}


export const activationRequestInfo = (id) => {
    return {
        type: ACTIVATION_REQUEST_INFO,
        payload: { id }
    }
}

export const messageAlertHide = () => {
    return {
        type: MESSAGE_ALERT_HIDE
    }
}

export const resetAlertActivation = () => {
    return {
        type: RESET_ALERT_ACTIVATION
    }
}

export const errorCodeList = () => {
    return {
        type: GET_ERROR_CODE,
    }
}

export const activationRequestInfoSuccessfull = (requestData) => {
    return {
        type: ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL,
        payload: requestData
    }
}

export const viewActivationListFailed = (error) => {
    return {
        type: VIEW_ACTIVATION_LIST_FAILED,
        payload: error
    }
}

export const getActivationChildList = (activationId) => {
    return {
        type: GET_ACTIVATION_CHILD_LIST,
        payload: activationId
    }
}

export const getActivationChildListSuccessfull = (childDataResult) => {
    return {
        type: GET_ACTIVATION_CHILD_LIST_SUCCESSFUL,
        payload: childDataResult
    }
}

export const getActivationChildListFail = (apiError) => {
    return {
        type: GET_ACTIVATION_CHILD_LIST_FAIL,
        payload: apiError
    }
}

export const resetChildActivationModal = () => {
    return {
        type: RESET_CHILD_ACTIVATION_MODAL,
    }
}