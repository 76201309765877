import {takeEvery, fork, all} from 'redux-saga/effects';
import { RECOVER_PASSWORD } from './actionTypes';

function* forgetPassword(){

}

export function* watchForgetPassword(){
    yield takeEvery(RECOVER_PASSWORD,forgetPassword)
}

function* forgetPasswordSaga(){
    yield all([
        fork(watchForgetPassword),
    ]);
}
export default forgetPasswordSaga; 