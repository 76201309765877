import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import FilterComponent from "./FilterComponent";
import moment from "moment";
import Spinner from '../components/layout/Spinner';
import DataTable from 'react-data-table-component';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { userList } from '../store/user/actions';
import { searchManualActivationData, resetManualActivation } from '../store/manualActivationReport/actions';
import { getRepushActivation, addRepushActivation, getActivationChildList } from '../store/repushActivation/actions';
import MultilineModalComponent from '../components/MultilineModalComponent';
import { useDataContext } from './DataContext';
import { useHistory } from 'react-router-dom';

function ManualActivationReport(props) {
    const { dashboardData } = useDataContext();
    const history = useHistory();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const dispatch = useDispatch();
    const [days, setDays] = useState(0);
    const timezone = process.env.REACT_APP_TIMEZONE;
    const [filterText, setFilterText] = useState("");
    const [fDate, setFdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [tDate, setTdate] = useState(new Date())
    const [fDateError,setFdateError] = useState(false);
    const [tDateError,setTdateError] = useState(false);
    const [imeiSearch,setImeiSearch] = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [searchData, setSearchData] = useState({
        companyId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).CompanyID : 0,
        dealerId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : 0,
        agentId: '',
        serialNumber: '',
        fromDate: fDate,
        toDate: tDate,
    });

    useEffect(() => {
        const dealerId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : null;
        props.userList(dealerId);
        props.resetManualActivation();
    }, [])

    useEffect(() => {
        if (dashboardData && dashboardData !== null) {
            setSearchData(prevState => ({
                ...prevState,
                agentId: dashboardData.userId,
                fromDate: dashboardData.fromDate,
                toDate: dashboardData.toDate,
            }));
            setFdate(dashboardData.displayFromDate);
            setTdate(dashboardData.displayToDate);
            const data = {
                dealerId: searchData.dealerId,
                companyId: searchData.companyId,
                agentId: dashboardData.userId,
                serialNumber: '',
                fromDate: dashboardData.fromDate,
                toDate: dashboardData.toDate,
            }
            dispatch(searchManualActivationData(data));
        }
    }, [dashboardData])

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const columns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'LOCKED BY',
            selector: row => row.LockedBy,
        },
        {
            name: 'DATE',
            selector: row => row.RequestedDtTm,
        },
        {
            name: 'LOCK DATE',
            width: "200px",
            selector: row => row.LockedDate,
        },
        {
            name: 'COMPLETE DATE',
            width: "200px",
            selector: row => row.CompleteDate,
        },
        {
            name: 'TIME TAKEN',
            width: "150px",
            selector: row => row.CompleteDuration,
        },
        {
            name: 'STATUS',
            width: "150px",
            selector: row => row.Status,
        },
        {
            name: 'CHANNEL TXN ',
            width: "180px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },

        {
            name: 'ACCOUNT NUMBER',
            width: "170px",
            selector: row => row.AccountNumber,
        },
        {
            name: 'MOBILE NUMBER',
            width: "150px",
            selector: row => row.MobileNumber,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.manualActivationData && props.manualActivationData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    const openTransactionModal = (id) => {
        dispatch(getActivationChildList(id))

    }

    var filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            serial: key + 1,
            ChannelTxnID: (
                <>
                    <p className={data.Multiline === 1 ? `badge rounded-pill bg-outline-danger px-1 d-inline` : 'd-inline'} >{data.ChannelTxnID}</p>
                    {data.Multiline === 1 ? <span onClick={() => openTransactionModal(data.ID)} style={{ 'position': 'absolute' }}><em className="icon ni ni-external" style={{ 'cursor': 'pointer', 'fontSize': '16px', 'marginLeft': '10px' }}></em></span> : ''}
                </>
            ),
            SerialNumber: (data.SerialNumber !== "null" ? <p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p> : ''),
            AccountNumber: (data.AccountNumber !== "null" ? <p>{data.AccountNumber}</p> : ''),
            MobileNumber: (data.MSISDN !== "null" ? <p>{data.MSISDN}</p> : ''),
            RequestedDtTm: (data.RequestedDtTm !== "null" ? <p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p> : ''),
            LockedDate: (<p>{moment(new Date(data.CreatedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY h:mm:ss a")}</p>),
            CompleteDate: (<p>{moment(new Date(data.ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY h:mm:ss a")}</p>),
            CompleteDuration: (<p>{data.DurationFormatted}</p>),
            LockedBy: (data.Name !== "null" ? <p>{data.Name}</p> : ''),
            Status: (<span className={`${(data.IsCompleted === 2) ? 'text-danger' : (data.IsCompleted === 1) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{data.IsCompleted === 1 ? 'Processed' : 'Cancelled'}</strong></span>)
        }
    })

    const listData = props.manualActivationData && props.manualActivationData.map((data, key) => {
        return {
            SNo: key + 1,
            LOCKEDBY: data.Name,
            DATE: (moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY ")),
            LOCKEDATE: (moment(new Date(data.CreatedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY h:mm:ss A")),
            COMPLETEDATE: (moment(new Date(data.ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY h:mm:ss A")),
            TIMETAKEN: data.DurationFormatted,
            STATUS: (data.IsCompleted === 2) ? 'Cancelled' : (data.IsCompleted === 1) ? 'Processed' : '',
            CHANNELTXN: data.ChannelTxnID,
            SERIAL: data.SerialNumber,
            ACCOUNTNUMBER: data.AccountNumber,
            MOBILENUMBER: data.MSISDN,
        }
    });

    const handleAgentChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            agentId: e.target.value
        }));
    }

    const handleToDateChange = (d) => {
        setTdate(d);
        setSearchData(prevState => ({
            ...prevState,
            toDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
    }

    const handleFromDateChange = (d) => {
        setFdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        props.resetManualActivation();
        const dayDiff = Math.abs(moment(fDate).diff(moment(tDate), 'days'));
        setDays(dayDiff);
        
        let check = false;

        if((!searchData.fromDate || searchData.fromDate === "") && searchData.serialNumber === ""){
            setFdateError(true);
            check = true;
        }else{
            setFdateError(false);
        }

        if((!searchData.toDate || searchData.toDate === "") && searchData.serialNumber === ""){
            setTdateError(true);
            check = true;
        }else{
            setTdateError(false);
        }

        if( check ){
            return;
        }

        searchData.serialNumber === "" ? setImeiSearch(false) : setImeiSearch(true);

        let searchDataManage = searchData;
        searchDataManage.fromDate = moment(searchDataManage.fromDate).format('YYYY-MM-DD');
        searchDataManage.toDate = moment(searchDataManage.toDate).format('YYYY-MM-DD');

        dispatch(searchManualActivationData(searchDataManage));
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    }
    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'ManualActivation' + fileExtension);
        }
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }
    const handleBack = () => {
        history.push('/dashboard');
    }
    // const dayDiff = Math.abs(moment(fDate).diff(moment(tDate), 'days'));
    // console.log(dayDiff);

    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Manual Activation Report</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-3 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="agentId">User</label>
                                            <div className="form-control-wrap">
                                                <select
                                                    name="agentId"
                                                    className="form-control"
                                                    id="agentId"
                                                    onChange={(e) => handleAgentChange(e)}
                                                    value={searchData.agentId}
                                                >
                                                    <option value="" key="0" name="select">Select</option>
                                                    {props.userData && props.userData.map(user => (
                                                        <option key={user.ID} value={user.ID}>{user.Name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor="fromDate">From Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleFromDateChange(d)} className={ fDateError ? "form-control date-picker border border-danger" : "form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="fromDate" closeOnScroll={true} selected={fDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor='toDate'>To Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleToDateChange(d)} className={ tDateError ? "form-control date-picker border border-danger" : "form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="toDate" closeOnScroll={true} selected={tDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-4">
                                        <div className="form-group"><label className="form-label" htmlFor='serialNumber'>IMEI/Serial No/Channel TXN</label>
                                            <div className="form-control-wrap">
                                                <input type="text" className="form-control" id="serialNumber" name="serialNumber" placeholder="IMEI/Serial No/Channel TXN" onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2 col-md-1'>
                                        <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Search</span> </button>
                                        {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {props.showManualReportPage && <div className="card p-2" style={{...styles.tableContainer,width: !props.loading && filterData.length > 1000 && !imeiSearch ? '50%' : 'unset'}}>

                    {
                        filterData.length > 1000 && !imeiSearch ? <>
                            {props.loading ? <div className="card-inner p-5 text-center" ><Spinner /></div> : <>
                                <div className="card-inner p-5" >
                                    <div className='text-center py-5'>
                                        <h5 className='text-center'>Your report is ready for download! Click the link below to retrieve it.</h5>
                                        <p>Note : The dataset you requested is too large to display directly. Please download it using the link below in Excel format for better accessibility.</p>
                                        <Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Download</Link>
                                    </div>
                                </div>
                            </>}
                        </> : <>
                            <div className="card-inner p-0" >
                                <div className='d-flex justify-content-between'>
                                    <FilterComponent
                                        onFilter={e => setFilterText(e.target.value)}
                                        onClear={handleClear}
                                        filterText={filterText}
                                    /><Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                                </div>
                                <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                                <div className="text-right">
                                    <Link to="#" className="btn btn-danger" onClick={() => handleBack()}>Back</Link>
                                </div>
                            </div>
                        </>
                    }

                </div>}
            </div>

            {props.childDataModalShow && <MultilineModalComponent activationChildData={props.activationChildData} />}
        
        </>
    )
}

const styles = {
    tableContainer : {
        margin: '12px auto',
        webkitBoxShadow: 'rgb(190, 174, 172) 33px 35px 127px -41px',
    }
}

const mapStateToProps = state => {
    const { userData } = state.user
    const { loading, apiError, manualActivationData, showManualReportPage } = state.manualActivationReport;
    const { childDataModalShow, activationChildData } = state.repushActivation
    return { loading, apiError, manualActivationData, showManualReportPage, userData,childDataModalShow, activationChildData };
}

export default withRouter(connect(mapStateToProps, { userList, searchManualActivationData, resetManualActivation })(ManualActivationReport));