export const ROLES = {
  Admin: 1,
  Agent: 2,
  Support: 31,
  MIS: 30,
  ChannelAdmin: 33,
  ChannelAgent: 34,
};

export const SCOPES = {
  view_dashboard_page: "view_dashboard_page",
  view_verify_dealer_page: "view_verify_dealer_page",
  view_user_page: "view_user_page",
  view_dealer_page: "view_dealer_page",
  view_server_page: "view_server_page",
  view_plan_page: "view_plan_page",
  view_addon_page: "view_addon_page",
  view_password_config_page: "view_password_config_page",
  view_notification_page: "view_notification_page",
  view_activation_page: "view_activation_page",
  view_paused_activation_page: "view_paused_activation_page",
  view_dealer_report_page: "view_dealer_report_page",
  view_Activation_report_page: "view_Activation_report_page",
  view_Channel_Activation_report: "view_Channel_Activation_report",
  view_lock_activation_request_page: "view_lock_activation_request_page",
  view_jar_page: "view_jar_page",
  add_user: "add_user",
  edit_user: "edit_user",
  delete_user: "delete_user",
  reset_password: "reset_password",
  add_dealer: "add_dealer",
  suspend_dealer: "suspend_dealer",
  add_password_config: "add_password_config",
  add_notification: "add_notification",
  edit_notification: "edit_notification",
  activate_notification: "activate_notification",
  lock_activation: "lock_activation",
  complete_activation: "complete_activation",
  cancel_activation: "cancel_activation",
  refund_activation: "refund_activation",
  repush_activation: "repush_activation",
  assign_to_care: "assign_to_care",
  is_verified_verify_dealer: "is_verified_verify_dealer",
  edit_password_verify_dealer: "edit_password_verify_dealer",
  jar_status_update: "jar_status_update",
  lock_activation_release: "lock_activation_release",
  jar_configuration: "jar_configuration",
  repush_activation: "repush_activation",
  pending_activation: "pending_activation",
  view_manual_activation_report_page: "view_manual_activation_report_page",
  show_user_activation_count_report_on_dashboard: "show_user_activation_count_report_on_dashboard",
  view_asset_page: "view_asset_page",
  add_asset: "add_asset",
  edit_asset: "edit_asset",
  plan_wise_activation_report: "plan_wise_activation_report",
  asset_performance_report: "asset_performance_report",
  jar_upload: "jar_upload",
  pos_dealer_mapping: "pos_dealer_mapping",
  aws_ec2: "aws_ec2",
  dealer_config: "dealer_config",
  assign_dealer_activation: "assign_dealer_activation",
  validate_epay_report: "validate_epay_report",
  sim_swap_request: "sim_swap_request",
  add_sim_swap: "add_sim_swap",
  email_inbox: "email_inbox",
  late_activated_txn_report: "late_activated_txn_report",
  update_zipcode: "update_zipcode",
  bulk_cancellation: "bulk_cancellation"
};

export const PERMISSIONS = {
  [ROLES.Admin]: [SCOPES.view_dashboard_page, SCOPES.view_verify_dealer_page, SCOPES.view_server_page, SCOPES.view_user_page, SCOPES.view_dealer_page, SCOPES.view_plan_page, SCOPES.view_addon_page, SCOPES.view_password_config_page, SCOPES.view_jar_page, SCOPES.view_notification_page, SCOPES.view_activation_page, SCOPES.view_dealer_report_page, SCOPES.view_Activation_report_page, SCOPES.view_Channel_Activation_report, SCOPES.view_lock_activation_request_page, SCOPES.add_user, SCOPES.edit_user, SCOPES.delete_user, SCOPES.reset_password, SCOPES.add_dealer, SCOPES.suspend_dealer, SCOPES.add_password_config, SCOPES.add_notification, SCOPES.edit_notification, SCOPES.activate_notification, SCOPES.lock_activation, SCOPES.complete_activation, SCOPES.cancel_activation, SCOPES.refund_activation, SCOPES.assign_to_care, SCOPES.is_verified_verify_dealer, SCOPES.edit_password_verify_dealer, SCOPES.jar_status_update, SCOPES.lock_activation_release, SCOPES.repush_activation, SCOPES.jar_configuration, SCOPES.repush_activation, SCOPES.pending_activation, SCOPES.view_manual_activation_report_page, SCOPES.show_user_activation_count_report_on_dashboard, SCOPES.view_asset_page, SCOPES.add_asset, SCOPES.edit_asset, SCOPES.plan_wise_activation_report, SCOPES.asset_performance_report, SCOPES.jar_upload, SCOPES.pos_dealer_mapping, SCOPES.aws_ec2, SCOPES.dealer_config, SCOPES.assign_dealer_activation, SCOPES.validate_epay_report, SCOPES.sim_swap_request, SCOPES.add_sim_swap, SCOPES.email_inbox, SCOPES.late_activated_txn_report, SCOPES.update_zipcode,SCOPES.bulk_cancellation,],

  [ROLES.Agent]: [SCOPES.view_dashboard_page, SCOPES.view_verify_dealer_page, SCOPES.view_dealer_page, SCOPES.view_activation_page, SCOPES.view_dealer_report_page, SCOPES.view_Activation_report_page, SCOPES.view_Channel_Activation_report, SCOPES.add_dealer, SCOPES.suspend_dealer, SCOPES.lock_activation, SCOPES.complete_activation, SCOPES.cancel_activation, SCOPES.refund_activation, SCOPES.assign_to_care, SCOPES.is_verified_verify_dealer, SCOPES.edit_password_verify_dealer, SCOPES.view_manual_activation_report_page, SCOPES.view_manual_activation_report_page, SCOPES.plan_wise_activation_report, SCOPES.asset_performance_report, SCOPES.assign_dealer_activation, SCOPES.sim_swap_request, SCOPES.add_sim_swap, SCOPES.email_inbox, SCOPES.late_activated_txn_report, SCOPES.update_zipcode],

  [ROLES.Support]: [SCOPES.view_dashboard_page, SCOPES.view_verify_dealer_page, SCOPES.view_plan_page, SCOPES.view_addon_page, SCOPES.view_activation_page, SCOPES.view_Activation_report_page, SCOPES.view_Channel_Activation_report, SCOPES.view_lock_activation_request_page, SCOPES.repush_activation, SCOPES.pending_activation, SCOPES.view_manual_activation_report_page, SCOPES.assign_dealer_activation],

  [ROLES.MIS]: [SCOPES.view_Activation_report_page, SCOPES.view_activation_page, SCOPES.validate_epay_report],

  [ROLES.ChannelAdmin]: [SCOPES.view_dashboard_page, SCOPES.view_user_page, SCOPES.add_user, SCOPES.edit_user, SCOPES.delete_user, SCOPES.view_activation_page, SCOPES.view_Channel_Activation_report, SCOPES.lock_activation, SCOPES.complete_activation, SCOPES.cancel_activation, SCOPES.assign_to_care, SCOPES.show_user_activation_count_report_on_dashboard, SCOPES.view_manual_activation_report_page, SCOPES.plan_wise_activation_report, SCOPES.sim_swap_request, SCOPES.add_sim_swap, SCOPES.email_inbox, SCOPES.late_activated_txn_report, SCOPES.view_Activation_report_page],

  [ROLES.ChannelAgent]: [SCOPES.view_dashboard_page, SCOPES.view_activation_page, SCOPES.view_Channel_Activation_report, SCOPES.lock_activation, SCOPES.complete_activation, SCOPES.cancel_activation, SCOPES.assign_to_care, SCOPES.view_manual_activation_report_page, SCOPES.plan_wise_activation_report, SCOPES.sim_swap_request, SCOPES.add_sim_swap, SCOPES.email_inbox, SCOPES.late_activated_txn_report, SCOPES.view_Activation_report_page],
};
