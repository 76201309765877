import { ADDON_LIST, ADDON_LIST_SUCCESSFUL, ADDON_API_ERROR } from "./actionTypes"

export const addonList = () => {
    return {
        type: ADDON_LIST,
    }
}

export const addonListSuccessful = (addon) => {
    return {
        type: ADDON_LIST_SUCCESSFUL,
        payload: addon
    }
}

export const addonApiError = (error) => {
    return {
        type: ADDON_API_ERROR,
        payload: error
    }
}