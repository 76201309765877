import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { PLAN_LIST } from './actionTypes';
import { planListSuccessful, planApiError } from './actions';
import { getList } from '../../helpers/fackBackend_Helper';

function* getPlanList() {
    try {
        const response = yield call(getList, '/plan');
        if (response.Status) {
            yield put(planListSuccessful(response.Plan));
        }
        else {
            yield put(planApiError(response));
        }
    } catch (error) {
        yield put(planApiError(error));
    }
}

export function* watchGetPlanList() {
    yield takeEvery(PLAN_LIST, getPlanList)
}

function* planSaga() {
    yield all([
        fork(watchGetPlanList),
    ]);
}

export default planSaga;