import { PLAN_LIST, PLAN_LIST_SUCCESSFUL, PLAN_API_ERROR } from './actionTypes';

const initialState = {
    apiError: null, loading: false, plan: []
}

const plan = (state = initialState, action) => {
    switch (action.type) {
        case PLAN_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
            }
            break;

        case PLAN_LIST_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                plan: action.payload,
                apiError: null,
            }
            break;


        case PLAN_API_ERROR:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default plan;