export const VIEW_SIM_SWAP_REQUEST = "VIEW_SIM_SWAP_REQUEST";
export const VIEW_SIM_SWAP_REQUEST_SUCCESSFUL = "VIEW_SIM_SWAP_REQUEST_SUCCESSFUL";
export const VIEW_SIM_SWAP_REQUEST_FAILED = "VIEW_SIM_SWAP_REQUEST_FAILED";
export const SIM_SWAP_LOCK_ACTIVATION = "SIM_SWAP_LOCK_ACTIVATION";
export const SIM_SWAP_LOCK_ACTIVATION_RESULT = "SIM_SWAP_LOCK_ACTIVATION_RESULT";
export const SIM_SWAP_LOCK_ACTIVATION_FAILED_RESULT = "SIM_SWAP_LOCK_ACTIVATION_FAILED_RESULT";
export const SIM_SWAP_UNLOCK_ACTIVATION = "SIM_SWAP_UNLOCK_ACTIVATION";
export const SIM_SWAP_UNLOCK_ACTIVATION_SUCCESS = "SIM_SWAP_UNLOCK_ACTIVATION_SUCCESS";
export const RESET_SIM_SWAP_ALERT_ACTIVATION = "RESET_SIM_SWAP_ALERT_ACTIVATION";
export const GET_SIM_SWAP_ERROR_CODE = "GET_SIM_SWAP_ERROR_CODE";
export const GET_SIM_SWAP_ERROR_CODE_SUCCESSFULL = "GET_SIM_SWAP_ERROR_CODE_SUCCESSFULL";
export const SIM_SWAP_REMARK_CANCEL = "SIM_SWAP_REMARK_CANCEL";
export const SIM_SWAP_REMARK_CANCEL_SUCCESSFULL = "SIM_SWAP_REMARK_CANCEL_SUCCESSFULL";
export const SIM_SWAP_DATA_SUBMIT_FAILED = "SIM_SWAP_DATA_SUBMIT_FAILED";
export const SIM_SWAP_MESSAGE_ALERT_HIDE = "SIM_SWAP_MESSAGE_ALERT_HIDE";
export const CLOSE_SIM_SWAP_CANCEL_MODAL = "CLOSE_SIM_SWAP_CANCEL_MODAL";
export const RESET_SIM_SWAP_REQUEST_PAGE = "RESET_SIM_SWAP_REQUEST_PAGE";
export const COMPELETE_SIM_SWAP_ACTIVATION = "COMPELETE_SIM_SWAP_ACTIVATION";
export const COMPELETE_SIM_SWAP_ACTIVATION_SUCCESSFULL = "COMPELETE_SIM_SWAP_ACTIVATION_SUCCESSFULL";
export const GET_MSISDN_DETAILS = "GET_MSISDN_DETAILS";
export const GET_MSISDN_DETAILS_SUCCESS = "GET_MSISDN_DETAILS_SUCCESS";
export const GET_MSISDN_DETAILS_FAILED = "GET_MSISDN_DETAILS_FAILED";
export const ADD_SIM_SWAP_REQUEST = "ADD_SIM_SWAP_REQUEST";
export const ADD_SIM_SWAP_REQUEST_SUCCESS = "ADD_SIM_SWAP_REQUEST_SUCCESS";
export const ADD_SIM_SWAP_REQUEST_FAILED = "ADD_SIM_SWAP_REQUEST_FAILED";
export const GET_OTP = "GET_OTP";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_FAILED = "GET_OTP_FAILED";
export const RESET_COMPLETE_SIM_SWAP = "RESET_COMPLETE_SIM_SWAP";
export const CHECK_SIM_SWAP_COMPLETION = "CHECK_SIM_SWAP_COMPLETION";
export const CHECK_SIM_SWAP_COMPLETION_SUCCESS = "CHECK_SIM_SWAP_COMPLETION_SUCCESS";
export const CHECK_SIM_SWAP_COMPLETION_FAILED = "CHECK_SIM_SWAP_COMPLETION_FAILED";
