import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

import { getPlanWiseActivationReportListSuccessfull, getPlanWiseActivationReportListFail, } from './actions';
import { addData } from '../../helpers/fackBackend_Helper';
import { GET_PLAN_WISE_ACTIVATION_REPORT_LIST } from './actionTypes';

function* getPlanWiseActivationReportList({ payload: data }) {
    try {
        const response = yield call(addData, '/getPlanWiseActivationReport', data);
        if (response.Status) {
            yield put(getPlanWiseActivationReportListSuccessfull(response.planData));
        }
        else {
            yield put(getPlanWiseActivationReportListFail(response.Message));
        }
    } catch (error) {
        yield put(getPlanWiseActivationReportListFail(error));
    }
}

export function* watchgetPlanWiseActivationReportList() {
    yield takeEvery(GET_PLAN_WISE_ACTIVATION_REPORT_LIST, getPlanWiseActivationReportList)
}


function* planWiseReportSaga() {
    yield all([
        fork(watchgetPlanWiseActivationReportList),
    ]);
}

export default planWiseReportSaga;