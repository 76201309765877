import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList, addData } from '../../helpers/fackBackend_Helper';
import { getAllUserListSuccessfull, searchUserActivationFailed, searchUserActivationSuccess } from './actions';
import { GET_ALL_USER_LIST, SEARCH_USER_ACTIVATION } from './actionTypes';

function* getAllUser({ payload: DealerId }) {
    try {
        const response = yield call(getList, '/users/' + DealerId);
        if (response.Status) {
            yield put(getAllUserListSuccessfull(response.user));
        }
        else {
            yield put(searchUserActivationFailed(response.Message));
        }
    } catch (error) {
        yield put(searchUserActivationFailed(error));
    }
}

export function* watchUserActivation() {
    yield takeEvery(GET_ALL_USER_LIST, getAllUser)
}

function* getSearchUserActivation({ payload: searchData }) {
    try {
        const response = yield call(addData, '/activationDoneByUser', searchData);
        if (response.Status) {
            yield put(searchUserActivationSuccess(response.activationdata));
        }
        else {
            yield put(searchUserActivationFailed(response.message));
        }
    } catch (error) {
        yield put(searchUserActivationFailed(error));
    }
}

export function* watchSearchUserActivation() {
    yield takeEvery(SEARCH_USER_ACTIVATION, getSearchUserActivation)
}

function* userActivationReportSaga() {
    yield all([
        fork(watchUserActivation),
        fork(watchSearchUserActivation)
    ]);
}
export default userActivationReportSaga;

