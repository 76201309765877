import {
    GET_MAIL_BY_MSISDN,
    GET_MAIL_BY_MSISDN_SUCCESS,
    GET_MAIL_BY_MSISDN_FAIL,
    READ_MAIL,
    READ_MAIL_SUCCESS,
    READ_MAIL_FAIL,
    CLOSE_MAIL_TEMPLATE,
    RESET_TEMPLATE_DATA
} from "./actionTypes"


export const getMailByMsisdn = (data) => {
    return {
        type: GET_MAIL_BY_MSISDN,
        payload: { data }
    }
}

export const getMailByMsisdnSuccess = (data) => {
    return {
        type: GET_MAIL_BY_MSISDN_SUCCESS,
        payload: { data }
    }
}

export const getMailByMsisdnFail = (error) => {
    return {
        type: GET_MAIL_BY_MSISDN_FAIL,
        payload: { error }
    }
}

export const readMail = (mailID) => {
    return {
        type: READ_MAIL,
        payload: { mailID }
    }
}

export const readMailSuccess = (message, mailID) => {
    return {
        type: READ_MAIL_SUCCESS,
        payload: { message, mailID }
    }
}

export const readMailFail = (error) => {
    return {
        type: READ_MAIL_FAIL,
        payload: { error }
    }
}

export const closeMailTemplate = (mailID) => {
    return {
        type: CLOSE_MAIL_TEMPLATE,
        payload: { mailID }
    }
}

export const resetTemplateData = () => {
    return {
        type: RESET_TEMPLATE_DATA,
    }
}
