import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { checkLogin, resetLoginAlert } from '../store/login/actions';
import logologin from '../assets/images/virtuzo-logo.png';
import TMobile from '../assets/images/t-mobile.jpg';
import jwt_decode from "jwt-decode";
import banner1 from '../assets/images/slides/img-signin.07370cd7.jpg';

function SignIn(props) {
    const dispatch = useDispatch();
    const [redirect, setredirect] = useState(null);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passIconClass, setPassIconClass] = useState('form-icon form-icon-right passcode-switch lg is-shown');
    const [passwordType, setPasswordType] = useState('password');

    useEffect(() => {
        const token = localStorage.getItem("token");
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        if (token) {
            let decodedToken = jwt_decode(token);
            let currentDate = new Date();
            if (decodedToken.exp * 1000 > currentDate.getTime()) {
                if (roleId === 30) {
                    setredirect('/activation-report')
                } else {
                    setredirect('/dashboard')
                }
            }
        }
    }, [])


    const handleChangeUsername = (event) => {
        setUsername(event.target.value);
    }

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    }

    // console.log(props);
    const handleSubmitLoginFrom = (e) => {
        dispatch(resetLoginAlert());
        e.preventDefault();
        const formData = {
            username: username,
            password: password
        }
        dispatch(checkLogin(formData, props.history));
    }

    const handleChangeTypePassword = () => {
        passwordType === 'password' ? setPasswordType('text') : setPasswordType('password');
        passwordType === 'password' ? setPassIconClass('form-icon form-icon-right passcode-switch lg') : setPassIconClass('form-icon form-icon-right passcode-switch lg is-shown');
    }

    if (redirect) {
        return <Redirect to={redirect}></Redirect>
    }

    return (
        <div className='nk-body bg-white npc-general pg-auth'>
            <div className="nk-app-root">
                <div className="nk-main ">
                    <div className="nk-wrap nk-wrap-nosidebar">
                        <div className="nk-content ">
                            <div className="nk-split nk-split-page nk-split-md">
                                <div className="nk-split-content nk-split-stretch d-flex toggle-break-lg toggle-slide toggle-slide-right" data-toggle-body="true" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true" style={{ 'backgroundColor': '#000606' }}>
                                    <div className="slider-wrap w-100 p-3  m-auto" >
                                        <div className="slider-init" data-slick='{"dots":true, "arrows":false, "autoplay":true}'>
                                            <div className="text-center">
                                                <img src={banner1} alt="img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container bg-white">

                                    <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
                                        <div className="brand-logo pb-4 text-center">
                                            <Link to="#" className="logo-link">
                                                <img className="logo-dark logo-img logo-img-lg" src={logologin} alt="logo-dark" />
                                                <img className="logo-img-lg ms-3 mt-3" src={TMobile} alt="logo-dark" width="90px" />
                                            </Link>
                                        </div>
                                        <div className="card shadow">
                                            <div className="card-inner card-inner-lg">
                                                {props.loginError &&
                                                    <div className="example-alert">
                                                        <div className="alert alert-danger alert-icon alert-dismissible my-3"><em className="icon ni ni-cross-circle"></em>
                                                            <strong>{props.loginError}</strong></div>
                                                    </div>
                                                }
                                                <div className="nk-block-head">
                                                    <div className="nk-block-head-content">
                                                        <h4 className="nk-block-title">Sign-In</h4>
                                                        <div className="nk-block-des">
                                                            <p>Access the ENK Wireless panel using your username and password.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <form onSubmit={handleSubmitLoginFrom} method="POST">
                                                    <div className="form-group">
                                                        <div className="form-label-group">
                                                            <label className="form-label" htmlFor="username">Username</label>
                                                        </div>
                                                        <div className="form-control-wrap">
                                                            <input type="text" className="form-control form-control-lg" id="username" name="username" placeholder="Enter your username" autoComplete='off' value={username} onChange={handleChangeUsername} required />
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-label-group">
                                                            <label className="form-label" htmlFor="upassword">Password</label>
                                                        </div>
                                                        <div className="form-control-wrap">
                                                            {/* <div className={passIconClass} data-target="password" style={{ 'cursor': 'pointer' }} onClick={handleChangeTypePassword}> */}
                                                                {/* <em className="passcode-icon icon-show icon ni ni-eye"></em> */}
                                                                {/* <em className="passcode-icon icon-hide icon ni ni-eye-off"></em> */}
                                                            {/* </div> */}
                                                            <input type={passwordType} className="form-control form-control-lg" autoComplete='off' id="upassword" name="upassword" placeholder="Enter your password" value={password} onChange={handleChangePassword} required />
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group mt-4"> */}
                                                    {/* <button type='submit' className="btn btn-lg btn-secondary btn-block w-40">Sign in</button> */}

                                                    <button type='submit' disabled={props.loading} className="btn btn-lg btn-secondary btn-block w-40">{props.loading === true && <span
                                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                        <span>Sign in</span> </button>
                                                    {/* </div> */}
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { loading, loginError } = state.login;
    return { loading, loginError }
}
export default withRouter(connect(mapStateToProps, { checkLogin, resetLoginAlert })(SignIn));