import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData } from '../../helpers/fackBackend_Helper';
import { SEARCH_CHANNEL_ACTIVATION } from './actionTypes';
import { searchChannelActivationFailed, searchChannelActivationSuccessfull } from './actions';

function* getSearchChannelActivation({ payload: searchData }) {
    try {
        const response = yield call(addData, '/getAllActivationReportByChannelID', searchData);
        if (response.Status) {
            yield put(searchChannelActivationSuccessfull(response.activationdata));
        } else {
            yield put(searchChannelActivationFailed(response.message));
        }
    } catch (error) {
        yield put(searchChannelActivationFailed(error));
    }
}

export function* watchSearchChannelActivation() {
    yield takeEvery(SEARCH_CHANNEL_ACTIVATION, getSearchChannelActivation)
}

function* channelActivationReportSaga() {
    yield all([
        fork(watchSearchChannelActivation),
    ]);
}
export default channelActivationReportSaga;

