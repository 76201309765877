import { GET_ACTIVATION_REPORT, GET_ACTIVATION_REPORT_SUCCESSFULL, GET_ACTIVATION_REPORT_FAILED, RESET_VALIDATION_REPORT } from "./actionTypes";

export const getActivationReport = (reportStartDate, reportEndDate) => {
    return {
        type: GET_ACTIVATION_REPORT,
        payload: { reportStartDate, reportEndDate }
    }
}

export const getActivationReportSuccess = (activationData) => {
    return {
        type: GET_ACTIVATION_REPORT_SUCCESSFULL,
        payload: { activationData }
    }
}

export const getActivationReportFailed = (error) => {
    return {
        type: GET_ACTIVATION_REPORT_FAILED,
        payload: error
    }
}

export const resetValidationReport = () => {
    return {
        type: RESET_VALIDATION_REPORT,
    }
}