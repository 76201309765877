export const ASSET_LIST = 'asset_list';
export const ASSET_LIST_SUCCESSFUL = 'asset_list_successfull';

export const ADD_ASSET = 'ADD_ASSET';
export const ADD_ASSET_SUCCESS = 'ADD_ASSET_SUCCESS';

export const EDIT_ASSET = 'EDIT_ASSET';
export const DELETE_ASSET = 'DELETE_ASSET';
export const DELETE_ASSET_SUCCESS = 'delete_asset_success';

export const ASSET_API_ERROR = 'asset_api_failed';

export const RESET_ASSET_ALERT = 'reset_asset_alert';

export const LOCATION_LIST = 'location_list';
export const LOCATION_LIST_SUCCESSFUL = 'location_list_successfull';

export const ADD_LOCATION = 'ADD_LOCATION';
export const ADD_LOCATION_SUCCESS = 'ADD_LOCATION_SUCCESS';

export const EDIT_LOCATION = 'EDIT_LOCATION';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'delete_location_success';

export const ASSET_REPORT = 'ASSET_REPORT';
export const ASSET_REPORT_SUCCESS = 'ASSET_REPORT_SUCCESS';
export const ASSET_REPORT_FAILED = 'ASSET_REPORT_FAILED';
export const ASSET_REPORT_ASSET_ID = 'ASSET_REPORT_ASSET_ID';
export const ASSET_REPORT_ASSET_ID_SUCCESS = 'ASSET_REPORT_ASSET_ID_SUCCESS';
export const ASSET_REPORT_ASSET_ID_FAILED = 'ASSET_REPORT_ASSET_ID_FAILED';
export const ASSET_RUNNING_STATUS_CHANGE = 'ASSET_RUNNING_STATUS_CHANGE';
export const ASSET_RUNNING_STATUS_CHANGE_SUCCESS = 'ASSET_RUNNING_STATUS_CHANGE_SUCCESS';
