import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData, getList, userDeleteData } from '../../helpers/fackBackend_Helper';
import { compeleteSimSwapActivationSuccessfull, simSwapDataSubmittedFail, simSwapErrorCodeListSuccessfull, simSwapLockActivationFailedResult, simSwapLockActivationResultData, simSwapRemarkCancelSuccessfull, simSwapUnLockActivationSuccess, viewSimSwapRequestFailed, viewSimSwapRequestSuccessful, requestByMisisdnSuccess, requestByMisisdnFailed, addSIMSwapRequestFailed, getOtpSuccess, getOtpFailed, checkSimSwapCompletionSuccess, checkSimSwapCompletionFailed } from './actions';
import { COMPELETE_SIM_SWAP_ACTIVATION, GET_SIM_SWAP_ERROR_CODE, SIM_SWAP_LOCK_ACTIVATION, SIM_SWAP_REMARK_CANCEL, SIM_SWAP_UNLOCK_ACTIVATION, VIEW_SIM_SWAP_REQUEST, GET_MSISDN_DETAILS, ADD_SIM_SWAP_REQUEST, GET_OTP, CHECK_SIM_SWAP_COMPLETION } from './actionTypes';

function* getSimSwapRequestResponse({ payload: { companyId, loginId, searchData } }) {
    try {
        const response = yield call(addData, '/simSwapRequestList/' + companyId + '/' + loginId, searchData);
        if (response.Status) {
            yield put(viewSimSwapRequestSuccessful(response.SimSwaps, ''));
        } else {
            yield put(viewSimSwapRequestFailed(response));
        }
    } catch (error) {
        yield put(viewSimSwapRequestFailed(error));
    }
}

export function* watchSimSwapRequest() {
    yield takeEvery(VIEW_SIM_SWAP_REQUEST, getSimSwapRequestResponse)
}

function* getSimSwapLockActivation({ payload: { lockActivationData, searchData } }) {
    try {
        const response = yield call(addData, '/simSwapLockActivation', lockActivationData);
        if (response.lockData[0].Status) {
            const responseList = yield call(addData, '/simSwapRequestList/' + lockActivationData.companyId + '/' + lockActivationData.loginId, searchData);
            if (responseList.Status) {
                yield put(simSwapLockActivationResultData(response.lockData[0].Message, responseList.SimSwaps));
            }
        } else {
            yield put(simSwapLockActivationFailedResult(response.lockData[0]));
        }
    } catch (error) {
        yield put(viewSimSwapRequestFailed(error));
    }
}

export function* watchSimSwapLockActivation() {
    yield takeEvery(SIM_SWAP_LOCK_ACTIVATION, getSimSwapLockActivation);
}

function* getSimSwapUnlockActivation({ payload: { unLockData, searchData } }) {
    try {
        const response = yield call(addData, '/simSwapReleaseLockActivation/', unLockData);
        if (response.lockData[0].Status) {
            const responseList = yield call(addData, '/simSwapRequestList/' + unLockData.companyId + '/' + unLockData.loginId, searchData);
            if (responseList.Status) {
                yield put(simSwapUnLockActivationSuccess(response.lockData[0].Message, responseList.SimSwaps));
            }
        } else {
            yield put(simSwapLockActivationFailedResult(response.lockData[0]));
        }
    } catch (error) {
        yield put(viewSimSwapRequestFailed(error));
    }
}

export function* watchSimSwapUnlockActivation() {
    yield takeEvery(SIM_SWAP_UNLOCK_ACTIVATION, getSimSwapUnlockActivation);
}

function* getSimSwapErrorCode() {
    try {
        const response = yield call(getList, '/showOnPortalErrorCodes')
        if (response.Status) {
            yield put(simSwapErrorCodeListSuccessfull(response.showOnPortalErrorCodes))
        } else {
            yield put(viewSimSwapRequestFailed(response))
        }
    } catch (error) {
        yield put(viewSimSwapRequestFailed(error))
    }
}

export function* watchSimSwapErrorList() {
    yield takeEvery(GET_SIM_SWAP_ERROR_CODE, getSimSwapErrorCode);
}

function* submitSimSwapRemark({ payload: { remarkContent, id, companyId, loginId, searchData } }) {
    try {
        const response = yield call(userDeleteData, '/updateSimSwapRemark/' + id, remarkContent);
        if (response.response.Status) {
            const responseList = yield call(addData, '/simSwapRequestList/' + companyId + '/' + loginId, searchData);
            if (responseList.Status) {
                yield put(simSwapRemarkCancelSuccessfull(responseList.SimSwaps, response.response.Message))
            } else {
                yield put(viewSimSwapRequestFailed(response.response.Message));
            }
        } else {
            yield put(simSwapDataSubmittedFail(response.response.Message));
        }
    } catch (error) {
        yield put(simSwapDataSubmittedFail(error));
    }
}

export function* watchSubmitSimSwapRemark() {
    yield takeEvery(SIM_SWAP_REMARK_CANCEL, submitSimSwapRemark);
}

function* getCompeleteSimSwapData({ payload: { infoData, id, companyId, loginId, searchData } }) {
    try {
        const response = yield call(userDeleteData, '/updateSimSwapRequestForComplete/' + id, infoData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/simSwapRequestList/' + companyId + '/' + loginId, searchData);
            if (responseList.Status) {
                yield put(compeleteSimSwapActivationSuccessfull(responseList.SimSwaps, response.response.Message))
            } else {
                yield put(viewSimSwapRequestFailed(response.response.Message));
            }
        } else {
            yield put(simSwapDataSubmittedFail(response.response.Message));
        }
    } catch (error) {
        yield put(simSwapDataSubmittedFail(error));
    }
}

export function* watchGetCompeleteSimSwapData() {
    yield takeEvery(COMPELETE_SIM_SWAP_ACTIVATION, getCompeleteSimSwapData);
}

function* getRequestByMsisdn({ payload: { swapSearchData } }) {
    try {
        const response = yield call(addData, '/getActivationDetailsByMsisdn', swapSearchData);
        if (response.response !== undefined) {
            yield put(requestByMisisdnSuccess(response.response));
        }
        else {
            yield put(requestByMisisdnFailed('No data available'));
        }
    } catch (error) {
        yield put(requestByMisisdnFailed(error));
    }
}

export function* watchGetRequestByMisisdn() {
    yield takeEvery(GET_MSISDN_DETAILS, getRequestByMsisdn);
}

function* addSIMSwapRequest({ payload: { swapSearchData, companyId, loginId, searchData } }) {
    try {
        const response = yield call(addData, '/createSIMSwapRequest', swapSearchData);
        if (response.response.Status) {
            const responseList = yield call(addData, '/simSwapRequestList/' + companyId + '/' + loginId, searchData);
            if (responseList.Status) {
                yield put(viewSimSwapRequestSuccessful(responseList.SimSwaps, response.response.Message));
            } else {
                yield put(viewSimSwapRequestFailed(responseList));
            }
        }
        else {
            yield put(addSIMSwapRequestFailed(response.response));
        }
    } catch (error) {
        yield put(requestByMisisdnFailed(error));
    }
}

export function* watchAddSIMSwapRequest() {
    yield takeEvery(ADD_SIM_SWAP_REQUEST, addSIMSwapRequest);
}

function* getSwapOtp({ payload: { swapId } }) {
    try {
        const response = yield call(getList, '/getSwapOtp/' + swapId);
        if (response.otpData) {
            yield put(getOtpSuccess(response.otpData));
        }
    } catch (error) {
        yield put(getOtpFailed(error));
    }
}

export function* watchGetSwapOtp() {
    yield takeEvery(GET_OTP, getSwapOtp);
}

function* checkSimSwapCompletion({ payload: { swapId } }) {
    try {
        const response = yield call(getList, '/checkSimSwapCompletionStatus/' + swapId);
        if (response.Status) {
            yield put(checkSimSwapCompletionSuccess(response.Message));
        } else {
            yield put(checkSimSwapCompletionFailed(response.Message));
        }
    } catch (error) {
        yield put(checkSimSwapCompletionFailed(error.message));
    }
}

export function* watchCheckSimSwapCompletion() {
    yield takeEvery(CHECK_SIM_SWAP_COMPLETION, checkSimSwapCompletion);
}

function* simSwapRequestSaga() {
    yield all([
        fork(watchSimSwapRequest),
        fork(watchSimSwapLockActivation),
        fork(watchSimSwapUnlockActivation),
        fork(watchSimSwapErrorList),
        fork(watchSubmitSimSwapRemark),
        fork(watchGetCompeleteSimSwapData),
        fork(watchGetRequestByMisisdn),
        fork(watchAddSIMSwapRequest),
        fork(watchGetSwapOtp),
        fork(watchCheckSimSwapCompletion),
    ])
}

export default simSwapRequestSaga;