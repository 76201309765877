import { UPLOAD_JAR_FILE, UPLOAD_JAR_FILE_SUCCESSFUL, UPLOAD_JAR_FILE_FAIL,GET_JAR_FILE,GET_JAR_FILE_SUCCESSFUL,GET_JAR_FILE_FAIL } from "./actionTypes";

const initialState = {
    loading: false, jarUploadData: [], apiError: null, message: null,
    getJarLoading : false,
}

const jarUpload = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_JAR_FILE:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null,
            }
            break;

        case UPLOAD_JAR_FILE_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                apiError: null,
                message: action.payload
            }
            break;
        
        case UPLOAD_JAR_FILE_FAIL:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                message: null
            }
            break;
        
        case GET_JAR_FILE:
            state = {
                ...state,
                getJarLoading: true,
                apiError: null,
                // message: null,
            }
            break;

        case GET_JAR_FILE_SUCCESSFUL:
            state = {
                ...state,
                getJarLoading: false,
                apiError: null,
                jarUploadData: action.payload
            }
            break;
        
        case GET_JAR_FILE_FAIL:
            state = {
                ...state,
                getJarLoading: false,
                apiError: action.payload,
                message: null
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default jarUpload;