import { RESET_CHANNEL_ACTIVATION_REPORT_PAGE, SEARCH_CHANNEL_ACTIVATION, SEARCH_CHANNEL_ACTIVATION_REPORT_FAILED, SEARCH_CHANNEL_ACTIVATION_SUCCESSFULL } from "./actionTypes"

export const searchChannelActivation= (searchData) =>{
    return {
        type: SEARCH_CHANNEL_ACTIVATION,
        payload: searchData
    }
}

export const searchChannelActivationSuccessfull =(searchReportData)=>{
    return{
        type:SEARCH_CHANNEL_ACTIVATION_SUCCESSFULL,
        payload: searchReportData
    }
}

export const searchChannelActivationFailed = (error) =>{
    return{
        type:SEARCH_CHANNEL_ACTIVATION_REPORT_FAILED,
        payload: error
    }
}

export const resetChannelActivationReportPage = () =>{
    return{
        type:RESET_CHANNEL_ACTIVATION_REPORT_PAGE,
    }
}