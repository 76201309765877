import { ADD_NOTIFICATION, ADD_NOTIFICATION_SUCCESS, EDIT_NOTIFICATION, NOTIFICATION_API_ERROR, NOTIFICATION_API_ERROR_MODAL, NOTIFICATION_LIST, NOTIFICATION_LIST_SUCCESSFUL, RESET_NOTIFICATION_ALERT, SET_ISCURRENT_STATUS, SET_ISCURRENT_STATUS_SUCCESSFULL } from "./actionTypes"

export const notificationList = (companyId) => {
    return {
        type: NOTIFICATION_LIST,
        payload: companyId
    }
}

export const notificationListSuccess = (notification) => {
    return {
        type: NOTIFICATION_LIST_SUCCESSFUL,
        payload: notification
    }
}

export const notificationApiError = (error) => {
    return {
        type: NOTIFICATION_API_ERROR,
        payload: error
    }
}

export const addNotification = (notification, companyId) => {
    return {
        type: ADD_NOTIFICATION,
        payload: { notification, companyId }
    }
}

export const addNotificationSuccess = (notification,message) => {
    return {
        type: ADD_NOTIFICATION_SUCCESS,
        payload: { notification,message }
    }
}

export const editNotification = (notification, id, companyId) => {
    return {
        type: EDIT_NOTIFICATION,
        payload: { notification, id, companyId }
    }
}

export const setIsCurrentStatus = (id,status,notification) =>{
    return{
        type:SET_ISCURRENT_STATUS,
        payload:{id,status,notification}
    }
}
export const setIsCurrentStatusSuccessfull = (notification,message) =>{
    return{
        type:SET_ISCURRENT_STATUS_SUCCESSFULL,
        payload:{notification,message}
    }
}

export const notificationApiErrorModal = (message) => {
    return {
        type: NOTIFICATION_API_ERROR_MODAL,
        payload: message
    }
}

export const resetNotificationAlert = () =>{
    return{
        type:RESET_NOTIFICATION_ALERT
    }
}