import { CONFIG_API_ERROR, CONFIG_CHANNEL_DATA_SUCCESSFULL, GET_CHANNEL_CONFIG, RESET_ALERT_CONFIG, UPDATE_CHANNEL_CONFIG_DATA, UPDATE_CHANNEL_CONFIG_DATA_SUCCESSFULL } from "./actionTypes"

export const getConfigData = (companyId) => {
    return {
        type: GET_CHANNEL_CONFIG,
        payload: companyId
    }
}

export const getConfigDataSuccessfull = (configData) => {
    return {
        type: CONFIG_CHANNEL_DATA_SUCCESSFULL,
        payload: configData
    }
}

export const updateChannelConfig = (updateData,companyId) => {
    return {
        type: UPDATE_CHANNEL_CONFIG_DATA,
        payload: { updateData,companyId }
    }
}

export const updateChannelConfigSuccessfull = (Message, configData) => {    
    return {
        type: UPDATE_CHANNEL_CONFIG_DATA_SUCCESSFULL,
        payload: { Message, configData }
    }
}

export const configApiError = (error) => {
    return {
        type: CONFIG_API_ERROR,
        payload: error
    }
}

export const resetAlertConfig = () => {
    return {
        type: RESET_ALERT_CONFIG
    }
}