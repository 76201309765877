import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { ADDON_LIST } from './actionTypes';
import { addonListSuccessful, addonApiError } from './actions';
import { getList } from '../../helpers/fackBackend_Helper';

function* getAddonList() {
    try {
        const response = yield call(getList, '/addonServices');
        if (response.Status) {
            yield put(addonListSuccessful(response.AddonServices));
        }
        else {
            yield put(addonApiError(response));
        }
    } catch (error) {
        yield put(addonApiError(error));
    }
}

export function* watchGetAddonList() {
    yield takeEvery(ADDON_LIST, getAddonList)
}

function* addonSaga() {
    yield all([
        fork(watchGetAddonList),
    ]);
}

export default addonSaga;