import React from 'react';
import { useDispatch } from 'react-redux';
import { resetChildActivationModal } from '../store/repushActivation/actions';
const MultilineModalComponent = ({ activationChildData }) => {
    const dispatch = useDispatch();
    const handleBack = () => {
        dispatch(resetChildActivationModal())
    }

    return (
        <>
            <div className="modal fade show" tabIndex="-1" id="modalLarge" style={{ "display": "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        {activationChildData && activationChildData.length > 0 && activationChildData.map((data, index) => {
                            const parsedChildData = data.Multiline > 0 ? JSON.parse(data.ChildActivation) : null
                            return (<>
                                <div className="modal-header" style={{ backgroundColor: data.Multiline === 1 ? '#fff8fb' : '' }}>
                                    <div className='d-flex align-items-center'><h5 className="modal-title">CHANNEL TXN ID : <span className='text-muted'>{data.ChannelTxnID}</span></h5>{data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? <h6 className={`${data.ActivationSource === 1 ? 'text-danger' : 'text-primary'} mx-2`}>({data.ActivationSource === 1 ? 'Manual' : 'Automation'})</h6> : null}</div>
                                    <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={handleBack}><em className="icon ni ni-cross"></em></div>
                                </div>
                                <div className="modal-body" style={{ backgroundColor: data.Multiline === 1 ? '#fff8fb' : '' }}>
                                    <div className='border-bottom'>
                                        <h6 className="title">REQUEST INFORMATION</h6>
                                        <hr></hr>
                                        <div className={`row g-4 ${data.Multiline > 0 ? 'box-bor' : ''}`} style={{ top: '-33px !important' }}>
                                            <div className="col-sm-6">
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="IMEI">IMEI</label><p>{data.IMEI}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Serial">Serial #</label><p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{data.SerialNumber}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Request">Activation Type</label><p>{data.RequestType === 'PortIn' ? data.RequestType : 'Normal'}</p></div>
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Account">Account #</label><p>{data.AccountNumber}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Account">Account #</label><p>{data.AccountNumber}</p></div> : null}

                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Customer">Customer Email</label><p>{data.CustomerEmail}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Customer">WiFi Calling </label><p>{data.WiFiCalling && data.WiFiCalling ? 'Yes' : 'No'}</p></div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="SIM">SIM Type</label><p>{data.SimType}</p></div>
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Zip">Zip Code</label><p>{data.AreaCode}</p></div>
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{data.MSISDN}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{data.MSISDN}</p></div> : null}
                                                {data.RequestType === "Activation" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Order">Order #</label><p>{data.OrderNumber}</p></div> : null}
                                                {data.RequestType === "PortIn" ? <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Order">Order # </label><p>{data.OrderNumber}</p></div> : null}
                                                <div className='d-flex justify-content-between'><label className="form-label" htmlFor="SIM">T-Mobile PIN</label><p>{data.SimPin}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                    {data.Multiline > 0 && <div className='my-3'>
                                        <h6 className="title">CHILD ACTIVATION DATA</h6>
                                        <hr />
                                        {parsedChildData !== null && parsedChildData.map((item, key) => {
                                            return (
                                                <div className='row g-4 line-2'>
                                                    <h4>Line {item.LineNumber}</h4>
                                                    <div className="col-sm-6">
                                                        <div className='d-flex justify-content-between'><label className="form-label" htmlFor="IMEI">IMEI</label><p>{item.IMEI}</p></div>
                                                        <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Serial">Serial #</label><p className={item.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{item.SerialNumber}</p></div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className='d-flex justify-content-between'><label className="form-label" htmlFor="sim type">SIM Type</label><p>{item.SimType}</p></div>
                                                        <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{item.MSISDN}</p></div>
                                                    </div>
                                                </div>
                                                // <div className="row line-2">
                                                //     <div className="col-sm-3"><label className="form-label" htmlFor="Mobile">IMEI</label></div>
                                                //     <div className="col-sm-2"><label className="form-label" htmlFor="Mobile">SIM Type</label></div>
                                                //     <div className="col-sm-5"> <label className="form-label" htmlFor="Portin">Serial #</label></div>
                                                //     <div className="col-sm-2"><label className="form-label" htmlFor="Portin">Mobile #</label></div>
                                                //     {/* {childDataDisp1} */}
                                                //     <span key={key} className='line-2' style={{ border: 'none' }}>
                                                //         <h4>Line {item.LineNumber}</h4>
                                                //         <div className="row">
                                                //             <div className="col-sm-3"><p>{item.IMEI}</p></div>
                                                //             <div className="col-sm-2"><p>{item.SimType}</p></div>
                                                //             <div className="col-sm-5"><p className={item.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{item.SerialNumber}</p></div>
                                                //             <div className="col-sm-2"><p>{item.MSISDN}</p></div>
                                                //         </div>
                                                //         {/* <div className="row">
                                                //             <div className="col-sm-3"><p style={{ color: 'red' }}>Add On Services</p></div>
                                                //             {item.AddOnServices && item.AddOnServices.split(',').map((item, index) => {
                                                //                 var serviceData = item.split('_');
                                                //                 return <><div className={`col-sm-7 ${(index > 0) ? 'offset-3' : ''}`}><span><small><em className="icon ni ni-check text-success"></em> {serviceData[0]}</small></span></div><div className='col-sm-2'><span><small>${serviceData[1]}</small></span></div></>
                                                //             })}
                                                //             <div className="col-sm-9"><p>{item.AddOnServices}</p></div>   
                                                //         </div> */}
                                                //     </span>
                                                // </div>
                                            )
                                        })}
                                    </div>}

                                </div>
                            </>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default MultilineModalComponent
