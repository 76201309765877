import { ADDON_LIST, ADDON_LIST_SUCCESSFUL, ADDON_API_ERROR } from './actionTypes';

const initialState = {
    apiError: null, loading: false, addon: []
}

const addon = (state = initialState, action) => {
    switch (action.type) {
        case ADDON_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
            }
            break;

        case ADDON_LIST_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                addon: action.payload,
                apiError: null,
            }
            break;


        case ADDON_API_ERROR:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default addon;