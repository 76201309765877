export const GET_INSTANCES = 'GET_INSTANCES';
export const GET_INSTANCES_SUCCESS = 'GET_INSTANCES_SUCCESS';
export const GET_INSTANCES_FAILED = 'GET_INSTANCES_FAILED';

export const INSTANCES_STOP = 'INSTANCES_STOP';
export const INSTANCES_STOP_SUCCESS = 'INSTANCES_STOP_SUCCESS';
export const INSTANCES_STOP_FAILED = 'INSTANCES_STOP_FAILED';

export const INSTANCES_START = 'INSTANCES_START';
export const INSTANCES_START_SUCCESS = 'INSTANCES_START_SUCCESS';
export const INSTANCES_START_FAILED = 'INSTANCES_START_FAILED';

export const INSTANCES_START_MODAL_OPEN = 'INSTANCES_START_MODAL_OPEN';
export const INSTANCES_START_MODAL_CLOSE = 'INSTANCES_START_MODAL_CLOSE';

export const INSTANCES_STOP_MODAL_OPEN = 'INSTANCES_STOP_MODAL_OPEN';
export const INSTANCES_STOP_MODAL_CLOSE = 'INSTANCES_STOP_MODAL_CLOSE';

export const INSTANCES_PASSWORD_MODAL_OPEN = 'INSTANCES_PASSWORD_MODAL_OPEN';
export const INSTANCES_PASSWORD_MODAL_CLOSE = 'INSTANCES_PASSWORD_MODAL_CLOSE';

export const INSTANCES_PASSWORD_UPDATE = 'INSTANCES_PASSWORD_UPDATE';
export const INSTANCES_PASSWORD_UPDATE_SUCCESS = 'INSTANCES_PASSWORD_UPDATE_SUCCESS';
export const INSTANCES_PASSWORD_UPDATE_FAILED = 'INSTANCES_PASSWORD_UPDATE_FAILED';

export const TOGGLE_INSTANCES_PASSWORD= 'TOGGLE_INSTANCES_PASSWORD';
