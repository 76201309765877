import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import FilterComponent from "./FilterComponent";
import Spinner from "../components/layout/Spinner";
import { PERMISSIONS } from '../Permission/Permission';
import { getAllChannelList, dealerList, getCountryList, stateListing, editDealer, getChannelName, dealerFlagUpdate } from "../store/dealer/actions";
import {
  getDealerPos,
  getDealerByChannelIdAndAcode as getDealerByChannelIdAndAcodeAction,
  resetAddonForm, 
  posDealerMap as posDealerMapAction,
  posDealerMapModal
} from "../store/dealerPos/actions";

function PosDealerMapping(props) {
  const dispatch = useDispatch();
  const [RoleID, setRoleID] = useState(null);
  const [LoginId, setLoginID] = useState('');
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [modalDealerName, setModalDealerName] = useState('');
  const [modalDealerId, setModalDealerId] = useState(null);
  const [acode, setAcode] = useState('');
  const [acodeError, setAcodeError] = useState('');

  const [searchDealer, setSearchDealer] = useState({
    companyID: '',
    channelId: 0,
    status: 0,
  });
  useEffect(() => {
    const loginId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).ID : null;
    const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
    const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
    const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
    setRoleID(roleId);
    setLoginID(loginId);
    setSearchDealer((prevState) => ({
      ...prevState,
      companyID: companyId,
    }));

    props.getAllChannelList(dealerId);
  }, []);

  var filterArray = [];
  for (const [key, value] of Object.entries(PERMISSIONS)) {
    if (key == RoleID) {
      filterArray.push(value);
    }
  }

  const columns = [
    {
      name: "#",
      width: "30px",
      selector: row => row.serial,
    },
    {
      name: "DEALER NAME",
      selector: row => row.DealerName,
    },
    {
      name: "ACode",
      selector: row => row.ACode,
    },
    {
      name: "CHANNEL",
      selector: row => row.ChannelName,
    },
    {
      name: "ACTION",
      selector: row => row.action,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '0px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '0px',
      },
    },
  };

  const filteredItems = props.dealerPosData && props.dealerPosData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1

  );

  const showAcodeModal = (dealerId,dealername) => {
    dispatch(resetAddonForm());
    setAcodeError('');
    setModalDealerName(dealername);
    setModalDealerId(dealerId);
    dispatch(posDealerMapModal(true));
  }


  const filterData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      ChannelName: (<p>{data.ChannelName}</p>),
      DealerName: (<p>{data.DealerName}</p>),
      ACode: (<p>{data.ACode}</p>),
      action: (<span>
        <em className="icon icon-circle icon ni ni-menu bg-primary mx-2" style={{ 'cursor': 'pointer' }} onClick={() => showAcodeModal(data.ID,data.DealerName)}></em>
      </span>)
    }
  })


  const handleDealerChange = (e) => {
    const fname = e.target.name;
    const fvalue = e.target.value;
    setSearchDealer(prevState => ({
      ...prevState,
      [fname]: fvalue,
    }));
  }

  const handleSearchData = (e) => {
    e.preventDefault();
    dispatch(getDealerPos(searchDealer.channelId));
  }
  
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  }

  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }

  const fetchPOSDealer = () => {
    console.log('channel id',searchDealer.channelId,'under contruction');
  }

  const getDealerByChannelIdAndAcode = () => {
    if(acode == ''){
      setAcodeError('Acode is Required');
      return;
    }
    setAcodeError('');
    dispatch(getDealerByChannelIdAndAcodeAction(searchDealer.channelId, acode));

  }

  const posDealerMap = (dealerId) => {
    dispatch(posDealerMapAction(modalDealerId,dealerId,LoginId));
  }
  
  return (
    <div className="nk-block-head-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="nk-block-title page-title">POS Dealer Mapping</h3>

      </div>

      <div className="card p-2" style={{ marginTop: "12px" }}>
        {props.dealerMapMessage &&
          <div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.dealerMapMessage}</strong></div>}
        {props.dealerMapError &&
          <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.dealerMapError}</strong></div>}
        <div className="card-inner p-0">
          <div className="nk-block">
            <form method="post" onSubmit={handleSearchData}>
              <div className="row g-3">
                <div className="col-sm-3">
                  <div className="form-group">
                    <label className="form-label" htmlFor="dealerID">Channel</label>
                    <div className="form-control-wrap">
                      <select
                        name="channelId"
                        className="form-control"
                        id="channelId"
                        onChange={(e) => handleDealerChange(e)}
                      >
                        <option selected={true} disabled={true}>Select Channel</option>
                        {props.channelData && props.channelData.map(channel => (
                          <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>

                <div className='col-sm-3'>
                  <button type='submit' disabled={props.dealerPosLoading || searchDealer.channelId === 0 ? true : false} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.dealerPosLoading === true && <span
                    className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                    <span>Search</span> </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className='card p-2'>
        <div className="card-inner p-0" >
          <div className='d-flex justify-content-between'>
            <FilterComponent
              onFilter={e => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
            {searchDealer.channelId != 0 ? (<Link to="#" className="btn btn-success" onClick={() => fetchPOSDealer()}><em className="icon ni ni-upload-cloud"></em> Fetch POS Dealer</Link>) : '' }
          </div>
          <DataTable
            pagination
            columns={columns}
            data={filterData}
            customStyles={customStyles}
            progressPending={props.dealerPosLoading}
            progressComponent={<Spinner />}
            striped
            responsive
          />
        </div>
      </div>

      {props.dealerPosMapModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Search Dealer By Acode, ({modalDealerName})</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => dispatch(posDealerMapModal(false))}><em className="icon ni ni-cross"></em></div>
            </div>

            <div className="modal-body">
              {props.dealerMessage ? <div className="alert alert-danger d-flex align-items-center" role="alert">
                <em className="icon icon-circle icon ni ni-alert bg-danger mx-2"></em>
                <div>{props.dealerMessage}</div>
              </div>: '' }

              <div className="mb-3">
                <label htmlFor="dealerAcore" className="form-label">Enter ACode</label>
                <input type="text" className="form-control" onChange={(e) => setAcode(e.target.value)} id="dealerAcore" placeholder="Enter Dealer Acode Here" />
                <p style={styles.error}>{acodeError}</p>
              </div>
              <div className='mb-3 float-right' style={{float : 'right'}}>
                <button type='submit' disabled={props.dealerLoading} className="btn btn-primary" onClick={getDealerByChannelIdAndAcode}>{props.dealerLoading && <span
                  className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                  <span>Search</span> </button>
              </div>


            {
              props.dealerData.length > 0 ? <>
                <div className='container border-bottom my-3' style={styles.modalDealerData}>
                    <h6 className="title">DEALER INFORMATION</h6>
                    <hr></hr>
                    <div className="row">
                        <div className="col-sm-6 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Dealer Name</label>
                            <p>{props.dealerData[0].DealerName}</p>
                        </div>
                        <div className="col-sm-6 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Dealer ACode</label>
                            <p>{props.dealerData[0].ACode}</p>
                        </div>
                    </div>
                    <div className="row g-4">
                        <div className="col-sm-6">
                            <div className='d-flex justify-content-between ' ><label className="form-label" htmlFor="accountNumber">TMobile Username</label><span>{props.dealerData[0].TMobileUsername}</span></div>
                            <div className='d-flex justify-content-between' ><label className="form-label" htmlFor="accountNumber">EPay Username</label><span>{props.dealerData[0].EPayUsername}</span></div>
                        </div>
                        <div className="col-sm-6">
                            <div className='d-flex justify-content-between ' ><label className="form-label" htmlFor="accountNumber">TMobile Password</label><p className=''><span>{props.dealerData[0].TMobilePassword}</span></p></div>
                            <div className='d-flex justify-content-between '><label className="form-label" htmlFor="accountNumber">EPay Password</label><p className=''><span>{props.dealerData[0].EPayPassword}</span></p></div>
                        </div>
                    </div>
                </div>

                <div className='mb-3 float-right' style={{float : 'right'}}>
                    <button type='submit' disabled={props.dealerMapLoading} className="btn btn-primary" onClick={()=>posDealerMap(props.dealerData[0].DealerId)}>{props.dealerMapLoading && <span
                      className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                      <span>Submit</span> </button>
                </div>

              </>
              : ''
            }
            </div>

          </div>
        </div>
      </div>}

    </div>
  );
}

const styles = {
  error: {
    color: '#ff0000',
    padding: '7px 0 0',
  },
  modalDealerData: {
    padding: '55px 0 0 0',
  }
}

const mapStateToProps = (state) => {
  const { channelData, countries, states, apiError, message, loading, userId, modalMessage, channelList } = state.dealer;
  const {
    dealerPosData,
    dealerPosLoading,
    dealerLoading,
    dealerMessage,
    dealerData,
    dealerMapLoading,
    dealerMapMessage,
    dealerMapError,
    dealerPosMapModal,
  } = state.dealerPos;
  return {
    channelData,
    dealerData,
    countries,
    states,
    apiError,
    message,
    loading,
    userId,
    modalMessage,
    channelList,
    dealerPosData,
    dealerPosLoading, 
    dealerLoading,
    dealerMessage,
    dealerData,
    dealerMapLoading,
    dealerMapMessage,
    dealerMapError,
    dealerPosMapModal
  };
};

export default withRouter(connect(mapStateToProps, { getAllChannelList, dealerList, getCountryList, stateListing, editDealer, getChannelName, dealerFlagUpdate })(PosDealerMapping));
