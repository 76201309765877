import { RECOVER_PASSWORD, RECOVER_PASSWORD_FAILED, RECOVER_PASSWORD_SUCCESSFULL } from './actionTypes'
const initialState = {
    loginError: "", message: null, loading: false
}

const forgetPassword = (state = initialState, action) => {
    switch (action.type) {
        case RECOVER_PASSWORD:
            state = {
                ...state,
                loading: true
            }
            break;

        case RECOVER_PASSWORD_SUCCESSFULL:
            state = {
                ...state,
                loading: false
            }
            break;
        case RECOVER_PASSWORD_FAILED:
            state = {
                ...state,
                loading: false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}
export default forgetPassword;