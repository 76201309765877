import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import FilterComponent from "./FilterComponent";
import moment from "moment";
import Spinner from '../components/layout/Spinner';
import DataTable from 'react-data-table-component';
import { getAllChannelList } from '../store/dealerActivationReport/actions';
import { searchZipcodeActivation, updateZipcode } from '../store/updateZipcode/actions';

function UpdateZipcode(props) {
    const dispatch = useDispatch();
    const timezone = process.env.REACT_APP_TIMEZONE;
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [zipError, setZipError] = useState(false);
    const [newZipcodeError, setNewZipError] = useState(false);
    const [searchData, setSearchData] = useState({
        companyId: '',
        channelID: '',
        dealerId: '',
        zipcode: '',
        bulkFlag: '',
        newZipcode: ''
    });

    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;

        setSearchData(prevState => ({
            ...prevState,
            companyId: CompanyID,
            dealerId: (dealerId > 0) ? dealerId : '',
        }));
    
        props.getAllChannelList(dealerId);
    }, [])

    useEffect(() => {
        setSearchData(prevState => ({
            ...prevState,
            channelID: '',
            zipcode: '',
            bulkFlag: '',
            newZipcode: ''
        }));
    }, [props.message])

    const columns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'CHANNEL TXN ',
            width: "150px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'CHANNEL',
            width: "90px",
            selector: row => row.ChannelName,
        },
        {
            name: 'STATUS',
            width: "150px",
            selector: row => row.MasterStatus,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'DATE',
            selector: row => row.RequestedDtTm,
        },
        {
            name: 'TYPE',
            selector: row => row.RequestType,
        },
        {
            name: 'SIM',
            selector: row => row.SimType,
        },
        {
            name: 'ZIPCODE',
            width: "120px",
            selector: row => row.Areacode,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.activationData && props.activationData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    var filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            serial: key + 1,
            ChannelTxnID: (<p>{data.ChannelTxnID}</p>),
            ChannelName: (<p>{data.ChannelName}</p>),
            MasterStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : (data.RequestStatusID === 32) ? 'bg-dark' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : (data.RequestStatusID === 32) ? 'Refunded' : 'Pending'} </span>),
            SerialNumber: (data.SerialNumber !== "null" ? <p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p> : ''),
            RequestedDtTm: (<p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
            RequestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 'SimSwap' ? 'text-warning' : data.RequestType === 'PortIn' ? 'text-primary' : 'text-success'}`} >{data.RequestType[0]}</p>),
            SimType: (<p >{data.SimType}</p>),
            Areacode: data.AreaCode,
        }
    })

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        if(e.target.name === "zipcode" || e.target.name === "newZipcode"){
            if(e.target.value.length <= 5){
                setSearchData(prevState => ({
                    ...prevState,
                    [fname]: fvalue
                }))
            }
        }
        else{
            setSearchData(prevState => ({
                ...prevState,
                [fname]: fvalue
            }))
        }
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        setNewZipError(false);
        var checkFlag = false;
        if (searchData.zipcode.length !== 5) {
            setZipError(true);
            document.getElementById('zipError').innerHTML = "Zipcode must be of 5 digits!";
            checkFlag = true;
        }

        if (!checkFlag) {
            dispatch(searchZipcodeActivation(searchData));
        }
    }

    const handleZipcodeUpdate = () => {
        if (searchData.newZipcode.length != 5) {
            document.getElementById('newZipcodeError').innerHTML = "Zipcode must be of 5 digits!";
            setNewZipError(true);
        }
        // else if(searchData.newZipcode === searchData.zipcode){
        //     document.getElementById('newZipcodeError').innerHTML = "Zipcode must not be same!";
        //     setNewZipError(true);
        // }
        else{
            dispatch(updateZipcode(searchData));
        }
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    return (        
        <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Update Activation Zipcode</h3>
            <div className='card p-2'>
                <div className="card-inner p-0" >
                    <div className="nk-block">
                        <form onSubmit={handleSearchData} method="post">
                            <div className="row g-3">
                                <div className="col-sm-3 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="channelID">Channel</label>
                                        <div className="form-control-wrap">
                                            <select name="channelID" className="form-control" id="channelID" onChange={(e) => handleChange(e)}>
                                                <option value="" key="0" name="select">All</option>
                                                {props.channelData && props.channelData.map(channel => (
                                                    <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="bulkFlag">Bulk Flag</label>
                                        <div className="form-control-wrap">
                                            <select name="bulkFlag" className="form-control" id="bulkFlag" defaultValue={searchData.bulkFlag} onChange={(e) => handleChange(e)}>
                                                <option value="" name="All">All</option>
                                                <option value="1" name="Bulk">Bulk</option>
                                                <option value="0" name="Non-Bulk">Non-Bulk</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <div className="form-group"><label className="form-label" htmlFor='zipcode'>Zipcode</label>
                                        <div className="form-control-wrap">
                                            <input type="number" className="form-control" id="zipcode" name="zipcode" onInput={() => { document.getElementById('zipError').innerHTML = ""; setZipError(false) }} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} placeholder="Zipcode" onChange={(e) => handleChange(e)} style={{ 'border': zipError ? '1px solid red' : '' }} />
                                        </div>
                                    </div>
                                    <p className="text-danger" id="zipError"></p>
                                </div>
                                <div className='col-sm-2 col-md-1'>
                                    <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Search</span> </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {props.apiError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div>}
            {props.message && <div className="example-alert mb-2" style={{ 'marginTop': "0.75rem" }}><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div></div>}
            {props.showDataRecord && <div className="card p-2" style={{ marginTop: '12px' }}>
                <div className="card-inner p-0" >
                    <DataTable pagination={false} columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                    {filterData.length > 0 && <div className="row">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="form-label" htmlFor="newZipcode">New Zipcode</label>
                                <div className="form-control-wrap">
                                    <input type="number" name="newZipcode" onChange={handleChange} className="form-control" id="newZipcode" value={searchData.newZipcode} autoComplete="off" placeholder="New Zipcode" onInput={() => { document.getElementById('newZipcodeError').innerHTML = ""; setNewZipError(false) }} onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} style={{ 'border': newZipcodeError ? '1px solid red' : '' }} />
                                </div>
                                <p className="text-danger" id="newZipcodeError"></p>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="form-group">
                                <label className="form-label" htmlFor="newZipcodeBtn">&nbsp;</label>
                                <div className="form-control-wrap" style={{ 'textAlign': 'center' }}>
                                    <button type='button' onClick={handleZipcodeUpdate} disabled={props.updateLoading} className="btn btn-danger">{props.updateLoading === true && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}<span>Update Zipcode</span></button>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
            </div>}
        </div>
    )
}

const mapStateToProps = state => {
    const { channelData } = state.dealerActivationReport;
    const { loading, apiError, message, activationData, showDataRecord } = state.updateZipcode
    return { loading, apiError, message, channelData, activationData, showDataRecord };
}

export default withRouter(connect(mapStateToProps, { getAllChannelList, searchZipcodeActivation, updateZipcode })(UpdateZipcode));