import { CHECK_LOGIN, LOGIN_USER_SUCCESSFUL, API_ERROR, RESET_LOGIN_ALERT } from './actionTypes';

const initialState = {
    loginError: "", message: null, loading: false
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case CHECK_LOGIN:
            state = {
                ...state,
                loading: true
            }
            break;
        case LOGIN_USER_SUCCESSFUL:
            state = {
                ...state,
                loading: false
            }
            break;


        case API_ERROR:
            state = {
                ...state,
                loading: false,
                loginError: action.payload
            }
            break;

        case RESET_LOGIN_ALERT:
            state = {
                ...state,
                loading: false,
                loginError: null
            }
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;