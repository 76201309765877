import React, { useState, useEffect, useRef } from 'react';
import TMobile from '../../assets/images/t-mobile.jpg';
import profileback from '../../assets/images/profileback.jpg';
import { connect, useDispatch } from "react-redux";
import logologin from '../../assets/images/virtuzo-logo.png';
import { withRouter, Redirect, Link, NavLink, useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import moment from "moment/moment";
import { changePassword, resetPasswordAlert, jarStatusUpdate, getToShowMarquee, getCompany, resetHeaderMessage, tMobileStatusUpdate, closeTmobileStatusErrorModal } from "../../store/header/actions";
import { notificationList } from '../../store/notification/actions';
// import logologin from '../../assets/images/virtuzo-logo.png'
import logo from "../../assets/images/virtuzo-logo.png";
import { PERMISSIONS } from '../../Permission/Permission';

function Header(props) {
    const ref = useRef();
    const { pathname } = useLocation();
    const page = pathname.replace("/", "");
    const dispatch = useDispatch();
    // const [open, setOpen] = useState(false);
    // const [NotificationOpen, setNotificationOpen] = useState(false);
    const [ProfileOpen, setProfileOpen] = useState(false);
    const [showModal, setshowModal] = useState(false);
    const [LoginId, setLoginId] = useState('');
    const [RoleID, setRoleId] = useState(1);
    const [redirect, setRedirect] = useState(null);
    const [loginUserName, setLoginUserName] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [nameAavtar, setNameAvtar] = useState('');
    const [logoutModal, setLogoutModal] = useState(false)
    const [oldPassword, setoldPassword] = useState(false);
    const [newPassword, setnewPassword] = useState(false);
    const [confirmPassword, setconfirmPassword] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    const [adminMenu, setAdminMenu] = useState(false);
    const [activation, setActivation] = useState(false);
    const [report, setreport] = useState(false);
    const [JARFlag, setJarFlag] = useState('');
    const [companyID, setCompanyId] = useState('');
    const [passwordChangeData, setPasswordChangeData] = useState({
        userId: null,
        oldPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [jarConfirmation, setjarConfirmation] = useState(false);
    const [statusConfirmation, setStatusConfirmation] = useState(false);
    const [tMobileStatusFlag, setTMobileStatusFlag] = useState('');

    useEffect(() => {
        let token = localStorage.getItem("token");
        // let authUser = localStorage.getItem('authUser')
        if (token) {
            let decodedToken = jwt_decode(token);
            let currentDate = new Date();
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                localStorage.removeItem("authUser");
                localStorage.removeItem("token");
                setRedirect('/');
            }
        } else {
            localStorage.removeItem("authUser");
            localStorage.removeItem("token");
            setRedirect('/');
        }
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        const userName = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).Username : null;
        const email = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).EmailID : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        // const jarFlag = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).JARFlag : null;
        const companyId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;

        props.notificationList(companyId);
        props.getCompany(companyId);
        setRoleId(roleId);
        setCompanyId(companyId);
        setLoginId(loginId);
        setLoginUserName(userName);
        setLoginEmail(email);
        const avtar = userName && userName[0].toUpperCase();
        setNameAvtar(avtar);
        setPasswordChangeData(prevState => ({
            ...prevState,
            userId: loginId
        }), [ref]);

        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                // setOpen(false);
                setProfileOpen(false);
                // setNotificationOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [])
    // 

    // if (props.companyData && props.companyData[0].JARFlag) {
    //     setJarFlag(props.companyData[0].JARFlag);
    // }
    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    // function callDropdown() {
    //     setOpen(true)
    //     setNotificationOpen(false)
    //     setProfileOpen(false)
    //     if (open == true) {
    //         setOpen(false);
    //     }
    // }
    // function notificationDropdown() {
    //     setNotificationOpen(true)
    //     setOpen(false)
    //     setProfileOpen(false)
    //     if (NotificationOpen == true) {
    //         setNotificationOpen(false);
    //     }
    // }

    function profileDropdown() {
        setProfileOpen(true)
        // setOpen(false)
        // setNotificationOpen(false)
        if (ProfileOpen === true) {
            setProfileOpen(false);
        }
    }

    const openPasswordModal = () => {
        dispatch(resetPasswordAlert());
        setProfileOpen(false);
        setshowModal(true);
        setPasswordChangeData(prevState => ({
            ...prevState,
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }));
    }

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setPasswordChangeData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleChangePassword = (e) => {
        e.preventDefault()
        setnewPassword(false);
        setoldPassword(false);
        setconfirmPassword(false);
        const userPassword = document.getElementById('newPassword').value
        const userConfirmPassword = document.getElementById('confirmPassword').value
        if (passwordChangeData.newPassword === '' && passwordChangeData.oldPassword === '' && passwordChangeData.confirmPassword === '') {
            setnewPassword(true);
            setoldPassword(true);
            setconfirmPassword(true);
            return false;
        } else {
            if (passwordChangeData.oldPassword === '') {
                setnewPassword(true);
                return false;
            }
            if (passwordChangeData.newPassword === '') {
                setoldPassword(true);
                return false;
            }
            if (passwordChangeData.confirmPassword === '') {
                setconfirmPassword(true);
                return false;
            }
            if (userPassword !== userConfirmPassword) {
                document.getElementById('passwordError').innerHTML = "Confirm password does not match!";
                return false;
            } else {
                setnewPassword(false);
                setoldPassword(false);
                setconfirmPassword(false);
                dispatch(changePassword(passwordChangeData));
            }
        }
    }

    const handleError = () => {
        document.getElementById('passwordError').innerHTML = ""
    }

    const handleLogoutModal = () => {
        setLogoutModal(true)
        setProfileOpen(false)
    }

    const handleSignout = () => {
        setTimeout(() => {
            localStorage.removeItem("authUser");
            localStorage.removeItem("token");
            setRedirect('/');
        }, 800);
    }

    const handleCancel = (e) => {
        setshowModal(false);
        setnewPassword(false);
        setoldPassword(false);
        setconfirmPassword(false);
    }

    function sideBarOpen() {
        // setCollapseSidebar(true);
        setSideBar(true);
    }

    function handleAdmin() {
        adminMenu === false ? setAdminMenu(true) : setAdminMenu(false);
        setActivation(false);
        setreport(false);
    }

    function handleActivation() {
        activation === false ? setActivation(true) : setActivation(false);
        setAdminMenu(false);
        setreport(false);
    }

    function handleReport() {
        report === false ? setreport(true) : setreport(false);
        setActivation(false);
        setAdminMenu(false);
    }

    const handleShowTmobileStatusConfirmation = () => {
        dispatch(resetHeaderMessage());
        setStatusConfirmation(true);
        setSideBar(false);
    }

    const handleShowJarConfirmation = () => {
        dispatch(resetHeaderMessage());
        setjarConfirmation(true);
        setSideBar(false);
    }

    const handleTMobileStatusOnOff = (tmobileStatusFlag) => {
        let flag = tmobileStatusFlag == 0 ? 1 : 0;
        setTMobileStatusFlag(flag);
        dispatch(tMobileStatusUpdate(companyID, flag, LoginId));
        // setStatusConfirmation(false);
    }

    const handleJAROnOff = (jarFlag) => {
        var flag = jarFlag == 0 ? 1 : 0;
        setJarFlag(flag);
        dispatch(jarStatusUpdate(companyID, flag, LoginId));
    }

    if (props.Message) {
        setTimeout(() => {
            setshowModal(false);
        }, 1000);
    }
    if (props.jarMessage && props.jarMessage !== null) {
        setTimeout(() => {
            setjarConfirmation(false);
            setStatusConfirmation(false);
            // props.getCompany(companyID);
        }, 200);
    }

    if (props.apiError && props.apiError !== null) {
        setTimeout(() => {
            setjarConfirmation(false);
            setStatusConfirmation(false);
            // props.getCompany(companyID);
        }, 200);
    }

    if (redirect) {
        return <Redirect to={redirect}></Redirect>
    }

    function handleToggleSideBar() {
        setSideBar(false);
    }

    return (
        <>
            <div className="nk-header nk-header-fixed is-light">
                <div className="container-fluid">
                    <div className="nk-header-wrap">
                        <div className="nk-menu-trigger d-xl-none ms-n1">
                            <Link to="#" className="nk-nav-toggle nk-quick-nav-icon" onClick={sideBarOpen} data-target="sidebarMenu"><em className="icon ni ni-menu"></em></Link>
                        </div>
                        <div className="nk-header-brand d-xl-none">
                            <Link to="/dashboard" className="logo-link">
                                <img className="logo-dark logo-img" src={logologin} alt="logo-dark" />
                            </Link>
                        </div>
                        <div style={{ 'width': '35%' }}>{props.notification && props.notification.map((data, key) => {
                            var endd = moment(new Date(data.EndDate)).format("MM/DD/YYYY HH:mm:ss");
                            var curd = moment(new Date()).format("MM/DD/YYYY HH:mm:ss");
                            var strtd = moment(new Date(data.StartDate)).format("MM/DD/YYYY HH:mm:ss");
                            // var a = Date.parse(endd);
                            if (strtd < curd && endd > curd) {
                                return (
                                    data.isCurrent === 1 ? <marquee style={{ 'color': 'red', 'fontSize': '1rem', 'fontWeight': 'bold' }} scrollamount="10" direction="left" attribute_name="attribute_value" id={key} attributes="true">
                                        {data.NotificationContent}
                                    </marquee> : ''
                                )
                            } else {
                                <div></div>
                            }
                        }
                        )}</div>
                        <div className="nk-header-tools">
                            <ul className="nk-quick-nav">

                                {RoleID === 1 && LoginId === 1 &&
                                    props.companyData && props.companyData.length > 0 && <li className='jar-flag-box desktop-device-jar'>
                                        <span className="show-message"><strong>T-Mobile Status: &nbsp;&nbsp;&nbsp;</strong></span>
                                        <label className="switch" onClick={handleShowTmobileStatusConfirmation}>
                                            <input type="checkbox" checked={props.companyData[0].TMobileStatus} />
                                            <span className="status round" ></span>
                                        </label>
                                    </li>}

                                {RoleID === 1 && LoginId === 1 &&
                                    props.companyData && props.companyData.length > 0 && <li className='jar-flag-box desktop-device-jar'>
                                        <span className="show-message"><strong>JAR Status: &nbsp;&nbsp;&nbsp;</strong></span>
                                        <label className="switch" onClick={handleShowJarConfirmation}>
                                            <input type="checkbox" checked={props.companyData[0].JARFlag} />
                                            <span className="slider round"></span>
                                        </label>
                                    </li>}
                                <li><img src={TMobile} className="logo-img" width='85px' /></li>
                                {/* checked={jarFlag ? true : false} */}

                                <li className="dropdown user-dropdown">
                                    <div className="dropdown-toggle me-n1" data-bs-toggle="dropdown" onClick={profileDropdown}>
                                        <div className="user-toggle">
                                            <div className="user-avatar sm">
                                                <span>{nameAavtar}</span>
                                            </div>
                                            <div className="user-info d-none d-xl-block">
                                                {/* <div className="user-status user-status-unverified">Verified</div> */}
                                                <div className="user-name dropdown-indicator">{loginUserName}</div>
                                            </div>
                                        </div>
                                    </div>
                                    {ProfileOpen && <div className="dropdown-menu dropdown-menu-md dropdown-menu-end show" ref={ref}>
                                        <div style={{ "backgroundColor": "#c1c1c1" }}><img src={profileback} alt="profileback" style={{ "width": "100%", "height": "70px" }} /></div>
                                        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                            <center>
                                                <div className="user-avatar" style={{ "marginTop": "-40px" }}>
                                                    <span>{nameAavtar}</span>
                                                </div>
                                                <span className="lead-text" style={{ "marginTop": "20px" }}>{loginUserName}</span>
                                                <span className="sub-text">{loginEmail}</span>
                                            </center>
                                        </div>
                                        <div className="dropdown-inner p-3" onClick={openPasswordModal} style={{ 'cursor': 'pointer' }}>
                                            <p className="fs-15px fw-bold"><em className="icon icon-circle icon ni ni-lock-alt-fill mx-2"></em>Change Password</p>
                                        </div>
                                        <div className="dropdown-inner p-3" onClick={handleLogoutModal} style={{ 'cursor': 'pointer' }}>
                                            <p className="fs-15px fw-bold"><em className=" icon icon-circle icon ni ni-signout mx-2"></em>Sign out</p>
                                        </div>
                                    </div>}

                                    {showModal && <div className="modal fade show" tabIndex="-1" id="modalTop" style={{ 'display': 'block' }} aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-top" role="document">
                                            <div className="modal-content"><div className="modal-header">
                                                <h5 className="modal-title">Change Password</h5>
                                                <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setshowModal(false)}><em className="icon ni ni-cross"></em></div>
                                            </div>
                                                <div className="modal-body">
                                                    {props.Message && <div className="example-alert"><div className="alert alert-primary alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.Message}</strong></div></div>}
                                                    {props.apiError && <div className="example-alert"><div className="alert alert-danger alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.apiError}</strong></div></div>}
                                                    <form className="form-validate is-alter" onSubmit={handleChangePassword}>
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="oldPassword">Old Password</label>
                                                            <div className="form-control-wrap">
                                                                <input type="password" className="form-control" name="oldPassword" id="oldPassword" placeholder='Old Password' autoComplete='off' onChange={handleChange} style={{ 'border': oldPassword ? '1px solid red' : '' }} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="newPassword">New Password</label>
                                                            <div className="form-control-wrap">
                                                                <input type="password" className="form-control" name="newPassword" id="newPassword" placeholder='New Password' autoComplete='off' onChange={handleChange} style={{ 'border': newPassword ? '1px solid red' : '' }} />
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="form-label" htmlFor="confirmPassword">Confirm Password</label>
                                                            <div className="form-control-wrap">
                                                                <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" onInput={handleError} placeholder='Confirm Password' autoComplete='off' onChange={handleChange} style={{ 'border': confirmPassword ? '1px solid red' : '' }} />
                                                            </div>
                                                            <p style={{ 'color': 'red' }} id="passwordError"></p>
                                                        </div>
                                                        <div className="form-group text-right">
                                                            <button type='submit' disabled={props.loading} className="btn btn-primary">{props.loading === true && <span
                                                                className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                                <span>Save Password</span> </button>
                                                            {/* <button type="submit" className="btn btn-lg btn-primary">Save Password</button> */}
                                                            <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleCancel}>Cancel</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {logoutModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Logout</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setLogoutModal(false)}><em className="icon ni ni-cross"></em></div>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Are you sure, you want to logout?</p>
                                                </div>
                                                <div className="modal-footer"><div className="btn btn-dim btn-outline-danger" onClick={() => setLogoutModal(false)}>Cancel</div> <div className="btn btn-dim btn-outline-primary" onClick={handleSignout}>Ok</div></div>
                                            </div>
                                        </div>
                                    </div>}

                                    {statusConfirmation && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Update T-Mobile Status</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setStatusConfirmation(false)}><em className="icon ni ni-cross"></em></div>
                                                </div>
                                                <div className="modal-body">
                                                    <p>Are you sure, you want to change T-Mobile website status?</p>
                                                </div>
                                                <div className="modal-footer"><button className="btn btn-dim btn-outline-danger" onClick={() => setStatusConfirmation(false)}>Cancel</button>
                                                    <button type='submit' disabled={props.loading} className="btn btn-dim btn-outline-primary" onClick={() => handleTMobileStatusOnOff(props.companyData[0].TMobileStatus)}>{props.loading === true && <span
                                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                        <span>Ok</span></button>
                                                    {/* <button className="btn btn-dim btn-outline-primary" onClick={() => handleJAROnOff(props.companyData[0].JARFlag)}>Ok</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {jarConfirmation && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Update JAR Status</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setjarConfirmation(false)}><em className="icon ni ni-cross"></em></div>
                                                </div>
                                                <div className="modal-body">
                                                    {props.companyData[0].JARFlag === 0 ? <p>Are you sure, you want to start JAR?</p> : <p>Are you sure, you want to stop JAR?</p>}
                                                </div>
                                                <div className="modal-footer"><button className="btn btn-dim btn-outline-danger" onClick={() => setjarConfirmation(false)}>Cancel</button>
                                                    <button type='submit' disabled={props.loading} className="btn btn-dim btn-outline-primary" onClick={() => handleJAROnOff(props.companyData[0].JARFlag)}>{props.loading === true && <span
                                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                                        <span>Ok</span></button>
                                                    {/* <button className="btn btn-dim btn-outline-primary" onClick={() => handleJAROnOff(props.companyData[0].JARFlag)}>Ok</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                    {props.tmobileStatusErrorModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                                        <div className="modal-dialog" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title">Error</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => dispatch(closeTmobileStatusErrorModal())}><em className="icon ni ni-cross"></em></div>
                                                </div>
                                                <div className="modal-body">
                                                    <p>There are some technical issues</p>
                                                </div>
                                                <div className="modal-footer">
                                                    <button className="btn btn-dim btn-outline-danger" onClick={() => dispatch(closeTmobileStatusErrorModal())}>OK</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {sideBar &&
                <div className="nk-sidebar nk-sidebar-fixed is-light nk-sidebar-active" data-content="sidebarMenu">
                    <div className="nk-sidebar-element nk-sidebar-head">
                        <div className="nk-sidebar-brand">
                            <Link to="#" className="logo-link nk-sidebar-logo">
                                <img className="logo-dark logo-img" src={logo} width="130px" alt="logo-dark" />
                                <img className="logo-small logo-img logo-img-small" src={logo} alt="logo-small" />
                            </Link>
                        </div>
                        <div className="nk-menu-trigger me-n2">
                            <Link to="#" onClick={() => setSideBar(false)} className="nk-nav-toggle nk-quick-nav-icon d-xl-none toggle-active" data-target="sidebarMenu"><em className="icon ni ni-arrow-left"></em></Link>
                            <Link to="#" className="nk-nav-compact nk-quick-nav-icon d-none d-xl-inline-flex toggle-active" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></Link>
                        </div>
                    </div>
                    <div className="nk-sidebar-element">
                        <div className="nk-sidebar-content">

                            <div className="nk-sidebar-menu" data-simplebar="init"><div className="simplebar-wrapper" style={{ "margin": "-24px 0px -48px" }}><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask"><div className="simplebar-offset" style={{ "right": "0px", "bottom": "0px" }}><div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{ "height": "100%", "overflow": "hidden scroll" }}><div className="simplebar-content" style={{ "padding": "24px 0px 48px" }}>
                                {RoleID === 1 && LoginId === 1 &&
                                    props.companyData && props.companyData.length > 0 && <li className='jar-flag-box mx-2 mobile-device-jar'>
                                        <span className="show-message"><strong>T-Mobile Status: &nbsp;&nbsp;&nbsp;</strong></span>
                                        <label className="switch" onClick={handleShowTmobileStatusConfirmation}>
                                            <input type="checkbox" checked={props.companyData[0].TMobileStatus} />
                                            <span className="status round" ></span>
                                        </label>
                                    </li>}
                                    
                                {RoleID === 1 && LoginId === 1 &&
                                    props.companyData && props.companyData.length > 0 && <li className='jar-flag-box mx-2 mobile-device-jar'>
                                        <span className="show-message"><strong>JAR Status: &nbsp;&nbsp;&nbsp;</strong></span>
                                        <label className="switch" onClick={handleShowJarConfirmation}>
                                            <input type="checkbox" checked={props.companyData[0].JARFlag} />
                                            <span className="slider round"></span>
                                        </label>
                                    </li>}
                                <ul className="nk-menu">
                                    {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dashboard_page") && <li className="nk-menu-item">
                                        <NavLink to="/dashboard" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                            <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                                            <span className="nk-menu-text" style={{ background: page === "dashboard" }}>Dashboard</span>
                                        </NavLink>
                                    </li>}
                                    {filterArray && filterArray.length > 0 && filterArray[0].includes("view_verify_dealer_page") && <li className="nk-menu-item">
                                        <NavLink to="/verify-dealer" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                            <span className="nk-menu-icon"><em className="icon ni ni-user-check"></em></span>
                                            <span className="nk-menu-text">Verify Dealer</span>
                                        </NavLink>
                                    </li>}

                                    {filterArray && filterArray.length > 0 && filterArray[0].includes("assign_dealer_activation") && <li className="nk-menu-item">
                                        <NavLink to="/assign-dealer" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                            <span className="nk-menu-icon"><em className="icon ni ni-link-alt"></em></span>
                                            <span className="nk-menu-text">Assign Dealer</span>
                                        </NavLink>
                                    </li>}

                                    {RoleID !== 30 && RoleID !== 34 && <li className="nk-menu-item has-sub">
                                        <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleAdmin}>
                                            <span className="nk-menu-icon"><em className="icon ni ni-user-list-fill"></em></span>
                                            <span className="nk-menu-text mx-2">Admin</span>
                                        </div>
                                        {adminMenu && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_user_page") && <li className="nk-menu-item">
                                                <NavLink to="/user" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-user"></em></span>
                                                    <span className="nk-menu-text">User</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dealer_page") && <li className="nk-menu-item">
                                                <NavLink to="/dealer" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-users"></em></span>
                                                    <span className="nk-menu-text">Dealer</span>
                                                </NavLink>
                                            </li>}
                                            {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("pos_dealer_mapping") && <li className="nk-menu-item">
                                            <NavLink to="/pos_dealer_mapping" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-link"></em></span>
                                                <span className="nk-menu-text">POS Dealer Mapping</span>
                                            </NavLink>
                                        </li>} */}
                                            {LoginId === 2 && <li className="nk-menu-item">
                                                <NavLink to="/dealer-email-config" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-mail"></em></span>
                                                    <span className="nk-menu-text">Dealer Email Config</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_plan_page") && <li className="nk-menu-item">
                                                <NavLink to="/plan" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-file-text"></em></span>
                                                    <span className="nk-menu-text">Plan</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_addon_page") && <li className="nk-menu-item">
                                                <NavLink to="/addon-service" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-row-view"></em></span>
                                                    <span className="nk-menu-text">Addon Service</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_password_config_page") && <li className="nk-menu-item">
                                                <NavLink to="/password-config" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-setting-fill"></em></span>
                                                    <span className="nk-menu-text">Password Config</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_notification_page") && <li className="nk-menu-item">
                                                <NavLink to="/notification" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-notice"></em></span>
                                                    <span className="nk-menu-text">Notification</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_server_page") && <li className="nk-menu-item">
                                                <NavLink to="/server" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-list-index"></em></span>
                                                    <span className="nk-menu-text">Server List</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_asset_page") && <li className="nk-menu-item">
                                                <NavLink to="/asset" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-text-a"></em></span>
                                                    <span className="nk-menu-text">Asset</span>
                                                </NavLink>
                                            </li>}
                                            {/* filterArray && filterArray.length > 0 && filterArray[0].includes("jar_upload") */}
                                            {true && <li className="nk-menu-item">
                                                <NavLink to="/upload-jar" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-b-chrome"></em></span>
                                                    <span className="nk-menu-text">JAR Download</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("aws_ec2") && <li className="nk-menu-item">
                                                <NavLink to="/aws-instances" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-amazon"></em></span>
                                                    <span className="nk-menu-text">AWS Instances</span>
                                                </NavLink>
                                            </li>}
                                            {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("jar_configuration") && <li className="nk-menu-item">
                                            <NavLink to="/jar-config" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-notice"></em></span>
                                                <span className="nk-menu-text">JAR Configuration</span>
                                            </NavLink>
                                        </li>} */}
                                            {/* <li className="nk-menu-item">
                                            <NavLink to="/jar-path" className="nk-menu-link" activeclassname="active">
                                                <span className="nk-menu-icon"><em className="icon ni ni-share-alt"></em></span>
                                                <span className="nk-menu-text">Jar X Path</span>
                                            </NavLink>
                                        </li> */}
                                        </ul>}
                                    </li>}

                                    {true && <li className="nk-menu-item has-sub">
                                        <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleActivation}>
                                            <span className="nk-menu-icon"><em className="icon ni ni-regen"></em></span>
                                            <span className="nk-menu-text mx-2">Activation</span>
                                        </div>
                                        {activation && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_activation_page") && <li className="nk-menu-item">
                                                <NavLink to="/view-activation" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-swap"></em></span>
                                                    <span className="nk-menu-text">Activation Request</span>
                                                </NavLink>
                                            </li>}
                                            {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("view_paused_activation_page") && <li className="nk-menu-item">
                                                <NavLink to="/paused-activation" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-pause-fill"></em></span>
                                                    <span className="nk-menu-text">Paused Request</span>
                                                </NavLink>
                                            </li>} */}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_lock_activation_request_page") && <li className="nk-menu-item">
                                                <NavLink to="/lock-activation" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-lock-alt-fill"></em></span>
                                                    <span className="nk-menu-text">Locked Activation</span>
                                                </NavLink>
                                            </li>}

                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("pending_activation") && <li className="nk-menu-item">
                                                <NavLink to="/pending-activation" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-clock-fill"></em></span>
                                                    <span className="nk-menu-text">Pending Request</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("repush_activation") && <li className="nk-menu-item">
                                                <NavLink to="/repush-activation" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-redo"></em></span>
                                                    <span className="nk-menu-text">Repush Activation</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("sim_swap_request") && <li className="nk-menu-item">
                                                <NavLink to="/sim-swap-request" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em class="icon ni ni-swap-alt"></em> </span>
                                                    <span className="nk-menu-text">SIM Swap Request</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("email_inbox") && <li className="nk-menu-item">
                                                <NavLink to="/email-inbox" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em class="icon ni ni-mail"></em> </span>
                                                    <span className="nk-menu-text">E-Mail Inbox</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("update_zipcode") && <li className="nk-menu-item">
                                                <NavLink to="/update-zipcode" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-location"></em></span>
                                                    <span className="nk-menu-text">Update Zipcode</span>
                                                </NavLink>
                                            </li>}
                                        </ul>}

                                    </li>}

                                    <li className="nk-menu-item has-sub">
                                        <div className="nk-menu-link nk-menu-toggle" activeclassname="active" data-bs-original-title="" title="" onClick={handleReport}>
                                            <span className="nk-menu-icon"><em className="icon ni ni-sign-idr-alt"></em></span>
                                            <span className="nk-menu-text mx-2">Report</span>
                                        </div>
                                        {report && <ul className="nk-menu-sub" style={{ 'display': 'block' }}>
                                            {/* <li className="nk-menu-item">
                                                <NavLink to="/user-activation-report" className="nk-menu-link" activeclassname="active">
                                                    <span className="nk-menu-icon"><em className="icon ni ni-expand"></em></span>
                                                    <span className="nk-menu-text">User Activation Report</span>
                                                </NavLink>
                                            </li> */}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_dealer_report_page") && <li className="nk-menu-item">
                                                <NavLink to="/dealer-activation-report" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-report-profit"></em></span>
                                                    <span className="nk-menu-text">Dealer Activation Report</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_Activation_report_page") && <li className="nk-menu-item">
                                                <NavLink to="/activation-report" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-layers"></em></span>
                                                    <span className="nk-menu-text">Activation Report</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_Channel_Activation_report") && <li className="nk-menu-item">
                                                <NavLink to="/channel-activation-report" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-layers-fill"></em></span>
                                                    <span className="nk-menu-text">Channel Activation Report</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("view_manual_activation_report_page") && <li className="nk-menu-item">
                                                <NavLink to="/manual-activation-report" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-shuffle"></em></span>
                                                    <span className="nk-menu-text">Manual Activation Report</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("plan_wise_activation_report") && <li className="nk-menu-item">
                                                <NavLink to="/plan-wise-activation-report" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-shuffle"></em></span>
                                                    <span className="nk-menu-text">Plan Wise Activation Report</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("asset_performance_report") && <li className="nk-menu-item">
                                                <NavLink to="/asset-performance-report" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-growth"></em></span>
                                                    <span className="nk-menu-text">Asset Performance Report</span>
                                                </NavLink>
                                            </li>}
                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("validate_epay_report") && <li className="nk-menu-item">
                                                <NavLink to="/validate-epay-report" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-file-check"></em></span>
                                                    <span className="nk-menu-text">Validate Epay Report</span>
                                                </NavLink>
                                            </li>}

                                            {filterArray && filterArray.length > 0 && filterArray[0].includes("late_activated_txn_report") && <li className="nk-menu-item">
                                                <NavLink to="/late-activated-txn-report" className="nk-menu-link" activeclassname="active" onClick={handleToggleSideBar}>
                                                    <span className="nk-menu-icon"><em className="icon ni ni-shuffle"></em></span>
                                                    <span className="nk-menu-text">Late Activation TXN Report</span>
                                                </NavLink>
                                            </li>}
                                        </ul>}


                                    </li>

                                    {/* <li className="nk-menu-item" style={{ "marginTop": "88%", "borderTop": "1px solid #cacdd1" }}>
                                        <NavLink to="/help" className="nk-menu-link" activeclassname="active" data-bs-original-title="" title="">
                                            <span className="nk-menu-icon"><em className="icon ni ni-help"></em></span>
                                            <span className="nk-menu-text">Help</span>
                                        </NavLink>
                                    </li> */}
                                </ul>
                            </div></div></div></div><div className="simplebar-placeholder" style={{ "width": "auto", "height": "1060px" }}></div></div><div className="simplebar-track simplebar-horizontal" style={{ "visibility": "hidden" }}><div className="simplebar-scrollbar" style={{ "width": "0px", "display": "none" }}></div></div><div className="simplebar-track simplebar-vertical" style={{ "visibility": "visible" }}><div className="simplebar-scrollbar" style={{ "height": "680px", "transform": "translate3d(0px, 16px, 0px)", "display": "block" }}></div></div></div>
                        </div>
                    </div>
                </div>}
            {sideBar && <div className="nk-sidebar-overlay" data-target="sidebarMenu"></div>}

        </>
    )
}

const mapStateToProps = state => {
    const { apiError, Message, loading, logoutDone, jarMessage, companyData, tmobileStatusErrorModal } = state.header;
    const { notification } = state.notification
    return { apiError, Message, loading, logoutDone, jarMessage, notification, companyData, tmobileStatusErrorModal };
}
export default withRouter(connect(mapStateToProps, { changePassword, resetPasswordAlert, jarStatusUpdate, getToShowMarquee, notificationList, getCompany })(Header));