import CryptoJS from 'crypto-js';

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

const decrypt = (str) => {
    if(str == null || str == ""){
        return str;
    }    
    const bytes  = CryptoJS.AES.decrypt(str, SECRET_KEY);
    let encrypted = bytes.toString(CryptoJS.enc.Utf8);
    // encrypted = encrypted.slice(1, -1);
    return encrypted;
};


export {decrypt}