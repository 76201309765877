import React, { useState, useEffect } from "react";
import FileSaver from 'file-saver';
import { withRouter, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import FilterComponent from "./FilterComponent";
import Spinner from "../components/layout/Spinner";
import { PERMISSIONS } from '../Permission/Permission';
import {
  getInstances,
  instanceStop,
  instanceStart,
  instanceStartModalOpen,
  instanceStartModalClose,
  instanceStopModalOpen,
  instanceStopModalClose,
  instancePasswordModalOpen,
  instancePasswordModalClose,
  instancePasswordUpdate,
  toggleInstancePassword
} from "../store/awsInstances/actions";

const ec2InstanceStateCode = {
  0: 'pending',
  16: 'running',
  32: 'shutting-down',
  48: 'terminated',
  64: 'stopping',
  80: 'stopped'
}

function AwsEc2(props) {
  const dispatch = useDispatch();
  const [RoleID, setRoleID] = useState(null);
  const [LoginId, setLoginID] = useState('');
  const [updatedPassword, setUpdatedPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [filterText, setFilterText] = useState("");
  const [ec2InstanceId, setEc2InstanceId] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);

  useEffect(() => {
    const loginId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).ID : null;
    const companyId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).CompanyID : null;
    const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
    const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
    setRoleID(roleId);
    setLoginID(loginId);

    dispatch(getInstances());
  }, []);

  var filterArray = [];
  for (const [key, value] of Object.entries(PERMISSIONS)) {
    if (key == RoleID) {
      filterArray.push(value);
    }
  }

  const columns = [
    {
      name: "#",
      width: "30px",
      selector: row => row.serial,
    },
    {
      name: "INSTANCE NAME",
      selector: row => row.Value,
    },
    {
      name: "PLATFORM",
      selector: row => row.Platform,
    },
    {
      name: "STATE",
      selector: row => row.state,
    },
    {
      name: "PUBLIC IP",
      selector: row => row.PublicIpAddress,
    },
    {
      name: "PASSWORD",
      width: "350px",
      selector: row => row.Password,
    },
    {
      name: "ACTION",
      width: "200px",
      selector: row => row.action,
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '8px', // override the cell padding for head cells
        paddingRight: '0px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '0px',
      },
    },
  };

  const filteredItems = props.instanceData && props.instanceData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1

  );

  const watchStopInstance = (InstanceId) => {
    let data ={
      instanceId : InstanceId
    }
    dispatch(instanceStop(data));
  }
  
  const watchStartInstance = (InstanceId) => {
    let data ={
      instanceId : InstanceId
    }
    dispatch(instanceStart(data));
  }

  const handleWatchStartInstance = (InstanceId) => {
    setEc2InstanceId(InstanceId);
    dispatch(instanceStartModalOpen());
  }
  
  const handleWatchStopInstance = (InstanceId) => {
    setEc2InstanceId(InstanceId);
    dispatch(instanceStopModalOpen());
  }

  const handlePasswordChangeModal = (InstanceId) => {
    setPasswordError('');
    setEc2InstanceId(InstanceId);
    dispatch(instancePasswordModalOpen());
  }
  
  const handlePasswordChange = () => {
    setPasswordError('');
    let data ={
      InstanceID : ec2InstanceId,
      Password : updatedPassword
    }
    if(updatedPassword == ''){
      setPasswordError('password required');
      return;
    }

    dispatch(instancePasswordUpdate(ec2InstanceId, data));

  }

  const openRdpDownloadModal = (InstanceId) => {
    setDownloadModal(true);
    setEc2InstanceId(InstanceId);
  }

  const togglePasswordField = (instanceId) => {
    dispatch(toggleInstancePassword(instanceId));
  }

  const filterData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      Value: (<p>{data.Value}</p>),
      Platform: (<p>{data.Platform}</p>),
      state: (<p>{ec2InstanceStateCode[data.State.Code]}</p>),
      PublicIpAddress: (<p>{data.PublicIpAddress}</p>),
      Password: (<p>{ data.show ? data.Password : '**********'} <em title={data.show ? "Hide Password" : "Show Password"} style={{fontSize : '14px'}} onClick={() => togglePasswordField(data.InstanceId)} className={data.show ? 'icon ni ni-eye-off' : 'icon ni ni-eye'}></em></p>),
      action: (<span>
        <em title="Update Password" onClick={() => handlePasswordChangeModal(data.InstanceId)} className="icon icon-circle icon ni ni-lock bg-success" style={data.State.Code !== 16 || props.ec2Stoploading || props.ec2Startloading ? styles.disabled : { cursor: 'pointer' }}></em>

        {data.State.Code !== 16 ?
          <em title="Start Instance" onClick={() => handleWatchStartInstance(data.InstanceId)}
            className="icon icon-circle icon ni ni-play-fill bg-success mx-2"
            style={props.ec2Startloading || data.State.Code === 64 || data.State.Code === 32 || data.State.Code === 48 || data.State.Code === 0 ? styles.disabled : { cursor: 'pointer' }}></em> :
          <><em title="Stop Instance" onClick={() => handleWatchStopInstance(data.InstanceId)}
            className="icon icon-circle icon ni ni-na bg-danger mx-2" style={props.ec2Stoploading ? styles.disabled : { cursor: 'pointer' }}></em>
          </>}
        <em title="Download RDP file" onClick={() => openRdpDownloadModal(data.InstanceId)} className='icon icon-circle icon ni ni-download bg-primary' style={data.State.Code !== 16 || props.ec2Stoploading || props.ec2Startloading ? styles.disabled : { cursor: 'pointer' }}>
        </em>

      </span>)
    }
  })
  
  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  }

  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }
  
  const downloadRdp = (InstanceId) => {
    setDownloadLoading(true);
    const url = process.env.REACT_APP_BASEURL + '/downloadRdpAwsInstances/' + InstanceId;
    let selectedInstance = props.instanceData.filter((item) => item.InstanceId === InstanceId);
    let filename = selectedInstance[0].Value+'.rdp';
    FileSaver.saveAs(url,filename);
    setTimeout(() => {
      setDownloadLoading(false);
      setDownloadModal(false);
    }, 2000)
  }

  return (
    <div className="nk-block-head-content">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="nk-block-title page-title">EC2 Instances</h3>

      </div>


      {props.ec2Startmessage && <div className="card p-2" style={{ marginTop: "12px" }}><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.ec2Startmessage}</strong></div></div>}
      {props.ec2Stopmessage && <div className="card p-2" style={{ marginTop: "12px" }}><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.ec2Stopmessage}</strong></div></div>}
      {/* {props.dealerMapError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.dealerMapError}</strong></div>} */}



      <div className='card p-2 mt-3'>
        <div className="card-inner p-0" >
          <div className='d-flex justify-content-between'>
            <FilterComponent
              onFilter={e => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
            />
          </div>
          <DataTable
            pagination={true}
            columns={columns}
            data={filterData}
            customStyles={customStyles}
            progressPending={props.loading}
            progressComponent={<Spinner />}
            striped
            responsive
          />
        </div>
      </div>
      
      
      {props.ec2StartModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-danger text-white">
                        <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => dispatch(instanceStartModalClose())}></em></div>
                    </div>
                    <div className="modal-body">
                        <p>Are you sure you want to start this instance.</p>
                    </div>
                    <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => dispatch(instanceStartModalClose())}>No</div> <div className="btn btn btn-outline-success" onClick={() => watchStartInstance(ec2InstanceId)}>Yes</div></div>
                </div>
            </div>
        </div>}

      {props.ec2StopModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header bg-danger text-white">
                      <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => dispatch(instanceStopModalClose())}></em></div>
                  </div>
                  <div className="modal-body">
                      <p>Are you sure you want to stop this instance.</p>
                  </div>
                  <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => dispatch(instanceStopModalClose())}>No</div> <div className="btn btn btn-outline-success" onClick={() => watchStopInstance(ec2InstanceId)}>Yes</div></div>
              </div>
          </div>
      </div>}
      

      {props.ec2PasswordModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header bg-danger text-white">
                      <h5 className="modal-title">UPDATE PASSWORD</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => dispatch(instancePasswordModalClose())}></em></div>
                  </div>
                  <div className="modal-body">
                      <input className="form-control" placeholder="Enter Updated Password" onChange={(e)=>setUpdatedPassword(e.target.value)} />
                      {passwordError !== '' ? <p className="text-danger mt-1">{passwordError}</p> : ''}
                  </div>
                  <div className="modal-footer"><div className="btn btn btn-outline-danger" onClick={() => dispatch(instancePasswordModalClose())}>Cancel</div> <div className="btn btn btn-outline-success" onClick={() => handlePasswordChange()}>Submit</div></div>
              </div>
          </div>
      </div>}

      {downloadModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
          <div className="modal-dialog" role="document">
              <div className="modal-content">
                  <div className="modal-header bg-danger text-white">
                      <h5 className="modal-title">CONFIRM</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" ><em className="icon ni ni-cross text-white" onClick={() => setDownloadModal(false)}></em></div>
                  </div>
                  <div className="modal-body">
                      <p>Are you sure you want to download RDP of this instance.</p>
                  </div>
                  <div className="modal-footer">
                    <div className="btn btn btn-outline-danger" onClick={() => setDownloadModal(false)}>No</div> 
                    <div className="btn btn btn-outline-success" onClick={() => downloadRdp(ec2InstanceId)}>{downloadLoading ? 'Loading..' : 'Yes'}</div>
                  </div>
              </div>
          </div>
      </div>}

    </div>
  );
}

const styles = {
  disabled : {
    pointerEvents: 'none',
    cursor : 'pointer',
    opacity : '0.5'
  }
}

const mapStateToProps = (state) => {
  const { apiError } = state.dealer;
  const { instanceData, loading, ec2Stoploading, ec2Startloading, ec2Startmessage, ec2Stopmessage, ec2StartModal, ec2StopModal, ec2PasswordModal } = state.ec2Instance;
  return {
    apiError,
    instanceData,
    loading,
    ec2Stoploading,
    ec2Startloading,
    ec2Startmessage,
    ec2Stopmessage,
    ec2StartModal,
    ec2StopModal,
    ec2PasswordModal
  };
};

export default withRouter(connect(mapStateToProps)(AwsEc2));
