import { CLOSE_SIM_SWAP_CANCEL_MODAL, COMPELETE_SIM_SWAP_ACTIVATION, COMPELETE_SIM_SWAP_ACTIVATION_SUCCESSFULL, GET_SIM_SWAP_ERROR_CODE, GET_SIM_SWAP_ERROR_CODE_SUCCESSFULL, MESSAGE_ALERT_HIDE, RESET_SIM_SWAP_ALERT_ACTIVATION, RESET_SIM_SWAP_REQUEST_PAGE, SIM_SWAP_DATA_SUBMIT_FAILED, SIM_SWAP_LOCK_ACTIVATION, SIM_SWAP_LOCK_ACTIVATION_FAILED_RESULT, SIM_SWAP_LOCK_ACTIVATION_RESULT, SIM_SWAP_MESSAGE_ALERT_HIDE, SIM_SWAP_REMARK_CANCEL, SIM_SWAP_REMARK_CANCEL_SUCCESSFULL, SIM_SWAP_UNLOCK_ACTIVATION, SIM_SWAP_UNLOCK_ACTIVATION_SUCCESS, VIEW_SIM_SWAP_REQUEST, VIEW_SIM_SWAP_REQUEST_FAILED, VIEW_SIM_SWAP_REQUEST_SUCCESSFUL, GET_MSISDN_DETAILS, GET_MSISDN_DETAILS_FAILED, GET_MSISDN_DETAILS_SUCCESS, ADD_SIM_SWAP_REQUEST, ADD_SIM_SWAP_REQUEST_FAILED, GET_OTP, GET_OTP_FAILED, GET_OTP_SUCCESS, RESET_COMPLETE_SIM_SWAP, CHECK_SIM_SWAP_COMPLETION, CHECK_SIM_SWAP_COMPLETION_SUCCESS, CHECK_SIM_SWAP_COMPLETION_FAILED } from "./actionTypes"

export const viewSimSwapRequest = (companyId, loginId, searchData) => {
    return {
        type: VIEW_SIM_SWAP_REQUEST,
        payload: { companyId, loginId, searchData }
    }
}

export const viewSimSwapRequestSuccessful = (simSwapRequestData, message) => {
    return {
        type: VIEW_SIM_SWAP_REQUEST_SUCCESSFUL,
        payload: { simSwapRequestData, message }
    }
}

export const viewSimSwapRequestFailed = (error) => {
    return {
        type: VIEW_SIM_SWAP_REQUEST_FAILED,
        payload: error
    }
}

export const simSwapLockActivation = (lockActivationData, searchData) => {
    return {
        type: SIM_SWAP_LOCK_ACTIVATION,
        payload: { lockActivationData, searchData }
    }
}
export const simSwapLockActivationResultData = (lockData, activationData) => {
    return {
        type: SIM_SWAP_LOCK_ACTIVATION_RESULT,
        payload: { lockData, activationData }
    }
}
export const simSwapLockActivationFailedResult = (lockData) => {
    return {
        type: SIM_SWAP_LOCK_ACTIVATION_FAILED_RESULT,
        payload: lockData
    }
}

export const simSwapUnLockActivation = (unLockData, searchData) => {
    return {
        type: SIM_SWAP_UNLOCK_ACTIVATION,
        payload: { unLockData, searchData }
    }
}

export const simSwapUnLockActivationSuccess = (unLockData, activationData) => {
    return {
        type: SIM_SWAP_UNLOCK_ACTIVATION_SUCCESS,
        payload: { unLockData, activationData }
    }
}
export const resetSimSwapAlertActivation = () => {
    return {
        type: RESET_SIM_SWAP_ALERT_ACTIVATION
    }
}
export const simSwapErrorCodeList = (activationId) => {
    return {
        type: GET_SIM_SWAP_ERROR_CODE,
        payload: { activationId }
    }
}

export const simSwapErrorCodeListSuccessfull = (errorList) => {
    return {
        type: GET_SIM_SWAP_ERROR_CODE_SUCCESSFULL,
        payload: errorList
    }
}

export const simSwapRemarkCancel = (remarkContent, id, companyId, loginId, searchData) => {
    return {
        type: SIM_SWAP_REMARK_CANCEL,
        payload: { remarkContent, id, companyId, loginId, searchData }
    }
}

export const simSwapRemarkCancelSuccessfull = (activations, message) => {
    debugger
    return {
        type: SIM_SWAP_REMARK_CANCEL_SUCCESSFULL,
        payload: { activations, message }
    }
}

export const simSwapDataSubmittedFail = (error) => {
    return {
        type: SIM_SWAP_DATA_SUBMIT_FAILED,
        payload: error
    }
}

export const simSwapMessageAlertHide = () => {
    return {
        type: SIM_SWAP_MESSAGE_ALERT_HIDE
    }
}

export const closeSimSwapCancelModal = () => {
    return {
        type: CLOSE_SIM_SWAP_CANCEL_MODAL,
    }
}

export const resetSimSwapRequestPage = () => {
    return {
        type: RESET_SIM_SWAP_REQUEST_PAGE,
    }
}

export const compeleteSimSwapActivation = (infoData, id, companyId, loginId, searchData) => {
    return {
        type: COMPELETE_SIM_SWAP_ACTIVATION,
        payload: { infoData, id, companyId, loginId, searchData }
    }
}
export const compeleteSimSwapActivationSuccessfull = (activations, message) => {
    return {
        type: COMPELETE_SIM_SWAP_ACTIVATION_SUCCESSFULL,
        payload: { activations, message }
    }
}

export const getMsisdnData = (swapSearchData) => {
    return {
        type: GET_MSISDN_DETAILS,
        payload: { swapSearchData }
    }
}

export const requestByMisisdnSuccess = (requestDetails) => {
    return {
        type: GET_MSISDN_DETAILS_SUCCESS,
        payload: { requestDetails }
    }
}

export const requestByMisisdnFailed = (error) => {
    return {
        type: GET_MSISDN_DETAILS_FAILED,
        payload: error
    }
}

export const addSIMSwapRequest = (swapSearchData, companyId, loginId, searchData) => {
    return {
        type: ADD_SIM_SWAP_REQUEST,
        payload: { swapSearchData, companyId, loginId, searchData }
    }
}

export const addSIMSwapRequestFailed = (error) => {
    return {
        type: ADD_SIM_SWAP_REQUEST_FAILED,
        payload: error
    }
}

export const getOtp = (swapId) => {
    return {
        type: GET_OTP,
        payload: { swapId }
    }
}

export const getOtpSuccess = (otpDetails) => {
    return {
        type: GET_OTP_SUCCESS,
        payload: { otpDetails }
    }
}

export const getOtpFailed = (error) => {
    return {
        type: GET_OTP_FAILED,
        payload: error
    }
}

export const resetCompleteSimSwap = () => {
    return {
        type: RESET_COMPLETE_SIM_SWAP,
    }
}


export const checkSimSwapCompletion = (swapId) => {
    return {
        type: CHECK_SIM_SWAP_COMPLETION,
        payload: { swapId }
    }
}

export const checkSimSwapCompletionSuccess = (message) => {
    return {
        type: CHECK_SIM_SWAP_COMPLETION_SUCCESS,
        payload: { message }
    }
}

export const checkSimSwapCompletionFailed = (error) => {
    return {
        type: CHECK_SIM_SWAP_COMPLETION_FAILED,
        payload: { error }
    }
}
