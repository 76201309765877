
export const VALIDATE_REQUEST_FOR_BULK_CANCELLATION = 'VALIDATE_REQUEST_FOR_BULK_CANCELLATION';
export const VALIDATE_REQUEST_FOR_BULK_CANCELLATION_SUCCESS = 'VALIDATE_REQUEST_FOR_BULK_CANCELLATION_SUCCESS';
export const VALIDATE_REQUEST_FOR_BULK_CANCELLATION_FAILED = 'VALIDATE_REQUEST_FOR_BULK_CANCELLATION_FAILED';

export const FORM_RESET = 'FORM_RESET';
export const RESET = 'RESET';

export const UPDATE_REMARK_FOR_BULK_CANCELLATION = 'UPDATE_REMARK_FOR_BULK_CANCELLATION';
export const UPDATE_REMARK_FOR_BULK__CANCELLATION_SUCCESS = 'UPDATE_REMARK_FOR_BULK__CANCELLATION_SUCCESS';
export const UPDATE_REMARK_FOR_BULK_CANCELLATION_FAILED = 'UPDATE_REMARK_FOR_BULK_CANCELLATION_FAILED';

export const GET_ERROR_CODE_LIST = 'GET_ERROR_CODE_LIST';
export const GET_ERROR_CODE_LIST_SUCCESS = 'GET_ERROR_CODE_LIST_SUCCESS';
export const GET_ERROR_CODE_LIST_FAILED = 'GET_ERROR_CODE_LIST_FAILED';