import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from "moment";
import momentTimezone from 'moment-timezone';
import DataTable from 'react-data-table-component';
import { getActivationReport, resetActivationReportPage } from '../store/activationReport/actions';
import FilterComponent from "./FilterComponent";
import Spinner from '../components/layout/Spinner';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getAllChannelList } from '../store/dealerActivationReport/actions';

function ActivationReport(props) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const dispatch = useDispatch();
    const timezone = process.env.REACT_APP_TIMEZONE;
    const [filterText, setFilterText] = useState("");
    const [days, setDays] = useState(0);
    const [fDateError, setFdateError] = useState(false);
    const [tDateError, setTdateError] = useState(false);
    const [imeiSearch, setImeiSearch] = useState(false);
    const [companyId, setcompanyId] = useState("");
    const [fDate, setFdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [tDate, setTdate] = useState(new Date())
    const [loginID, setloginID] = useState(null)
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [fromdate, setFromdate] = useState(false);
    const [toDate, setTodate] = useState(false);
    const [searchData, setSearchData] = useState({
        statusID: '',
        serialNumber: '',
        dealerId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : 0,
        fromDate: fDate,
        toDate: tDate,
        channelID:''
    });

    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
        setloginID(loginId);
        setcompanyId(CompanyID);
        props.resetActivationReportPage();
        props.getAllChannelList(dealerId);
        // props.getActivationReport(CompanyID, loginId, searchData)
    }, []);

    const columns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'CHANNEL TXN ',
            width: "150px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'CHANNEL',
            width: "100px",
            selector: row => row.ChannelName,
        },
        {
            name: 'STATUS',
            width: "150px",
            selector: row => row.MasterStatus,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'ORDER NUMBER',
            width: "170px",
            selector: row => row.OrderNumber,
        },
        {
            name: 'ACCOUNT#',
            width: "120px",
            selector: row => row.AccountNumber,
        },
        {
            name: 'E-PAY USERNAME',
            width: "150px",
            selector: row => row.EPayUsername,
        },
        {
            name: 'TERMINAL ID',
            width: "120px",
            selector: row => row.EPayTerminalId,
        },
        {
            name: 'EPAY TRANSACTIONID ',
            width: "180px",
            selector: row => row.EpayTransactionID,
        },
        {
            name: 'MOBILE NUMBER',
            width: "150px",
            selector: row => row.MobileNumber,
        },
        {
            name: 'CHANNEL DEALER NAME ',
            width: "200px",
            selector: row => row.ChannelDealerName,
        },
        {
            name: 'CHANNEL DEALER HIERARCHY',
            width: "220px",
            selector: row => row.ChannelDealerHierarchy,
        },
        {
            name: 'DISTRIBUTOR ID',
            width: "150px",
            selector: row => row.DistributorID,
        },
        {
            name: 'SUBMITTED DATE',
            width: "145px",
            selector: row => row.RequestedDtTm,
        },
        {
            name: 'COMPLETION DATE',
            width: "145px",
            selector: row => row.CompletionDate,
        },
        {
            name: 'TYPE',
            selector: row => row.RequestType,
        },
        {
            name: 'SIM',
            selector: row => row.SimType,
        },
        {
            name: 'MONTH',
            width: "80px",
            selector: row => row.PlanMonth,
        },
        {
            name: 'AMOUNT',
            width: "80px",
            selector: row => row.AmountCharged,
        },
        {
            name: 'API STATUS',
            width: "120px",
            selector: row => row.RequestStatus,
        },
        {
            name: 'SOURCE',
            width: "120px",
            selector: row => row.activationSource,
        },
        {
            name: 'AGENT',
            width: "120px",
            selector: row => row.AgentName,
        },
        {
            name: 'PLAN',
            width: "150px",
            selector: row => row.PlanName,
            wrap: true
        },
        {
            name: 'REMARK',
            width: "600px",
            selector: row => row.Remark,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.activationData && props.activationData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    );

    // console.log(props.activationData.length);
    var filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            serial: key + 1,
            ChannelTxnID: (<p>{data.ChannelTxnID}</p>),
            ChannelName: (<p>{data.ChannelName}</p>),
            MasterStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : (data.RequestStatusID === 32) ? 'bg-dark' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : data.RequestStatusID === 27 ? 'Assigned to Care' : (data.RequestStatusID === 32) ? 'Refunded' : 'Pending'} </span>),
            SerialNumber: (data.SerialNumber !== "null" ? <p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p> : ''),
            OrderNumber: (data.OrderNumber !== "null" ? <p style={{ "whiteSpace": "break-spaces" }}>{data.OrderNumber}</p> : ''),
            AccountNumber: (data.AccountNumber !== "null" ? <p>{data.AccountNumber}</p> : ''),
            EPayUsername: (<p style={{ "whiteSpace": "break-spaces" }}>{data.EPayUsername}</p>),
            EPayTerminalId: (<p>{data.EpayTerminalID}</p>),
            EpayTransactionID: (<p>{data.EpayTransactionID}</p>),
            MobileNumber: (data.MSISDN !== "null" ? <p>{data.MSISDN}</p> : ''),
            ChannelDealerName: (<p>{data.ChannelDealerName}</p>),
            PlanMonth: (<p>{data.PlanMonth}</p>),
            AmountCharged: (<p>${data.AmountCharged}</p>),
            ChannelDealerHierarchy: (<p>{data.ChannelDealerHierarchy}</p>),
            DistributorID: (<p>{data.DistributerID}</p>),
            RequestedDtTm: (<p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm:ss")}</p>),
            CompletionDate: (data.CompletionDate) ? (<p>{moment(new Date(data.CompletionDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm:ss")}</p>) : '',
            RequestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 'SimSwap' ? 'text-warning' : data.RequestType === 'PortIn' ? 'text-primary' : 'text-success'}`} >{data.RequestType[0]}</p>),
            // activationSource: (data.RequestStatusID === 12 || data.RequestStatusID === 13 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? <p>{data.ActivationSource === 1 ? 'Manual' : 'Automation'}</p> : null),
            activationSource: <p>{data.ActivitySourceText}</p>,
            AgentName: (<p>{data.AgentName}</p>),
            SimType: (<p >{data.SimType}</p>),
            PlanName: (<p>{data.PlanName}</p>),
            Remark: (<p>{data.Remark === 'null' ? '' : data.Remark}</p>),
            RequestStatus: (<span className={`${(data.RequestStatusID === 13 || data.RequestStatusID === 24) ? 'text-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'text-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{data.RequestStatus}</strong></span>),
        }
    })

    const listData = props.activationData && props.activationData.map((data, key) => {
        return {
            SNo: key + 1,
            CHANNELTXN: data.ChannelTxnID,
            CHANNELNAME: data.ChannelName,
            STATUS: (data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : data.RequestStatusID === 27 ? 'Assigned to Care' : 'Pending',
            SERIAL: data.SerialNumber,
            ORDERNUMBER: data.OrderNumber,
            ACCOUNTNUMBER: data.AccountNumber,
            EPAYUSERNAME: (data.EPayUsername),
            EPAYTERMINALID: (data.EPayTerminalId),
            EPAYTRANSACTIONID: (Number(data.EpayTransactionID)),
            MOBILENUMBER: data.MSISDN,
            CHANNELDEALERNAME: data.ChannelDealerName,
            CHANNELDEALERHIERARCHY: data.ChannelDealerHierarchy,
            DISTRIBUTORID: data.DistributerID,
            SUBMITTEDDATE: (moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm:ss")),
            COMPLETIONDATE: (data.CompletionDate) ? (moment(new Date(data.CompletionDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm:ss")) : '',
            TYPE: data.RequestType,
            SIM: data.SimType,
            PLANMONTH: data.PlanMonth,
            AMOUNTCHARGED: '$' + data.AmountCharged,
            APISTATUS: data.RequestStatus,
            SOURCE: (data.RequestStatusID === 12 || data.RequestStatusID === 13 || data.RequestStatusID === 23 || data.RequestStatusID === 24 ? data.ActivationSource === 1 ? 'Manual' : 'Automation' : null),
            AGENTNAME: data.AgentName,
            PLANNAME: data.PlanName,
            REMARK: data.Remark,
        }
    });

    const handleChange = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }
    const handleToDateChange = (d) => {
        setTdate(d);
        setSearchData(prevState => ({
            ...prevState,
            toDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
        if (!d) {
            setTodate(true)
        }
        else {
            setTodate(false)
        }
    }

    const handleFromDateChange = (d) => {
        setFdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))

        if (!d) {
            setFromdate(true)
        }
        else {
            setFromdate(false)
        }
    }

    const handleStatusChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            statusID: e.target.value
        }));
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        props.resetActivationReportPage();
        const dayDiff = Math.abs(moment(fDate).diff(moment(tDate), 'days'));
        setDays(dayDiff);

        let check = false;

        if ((!fDate || fDate === "") && searchData.serialNumber === "") {
            setFdateError(true);
            check = true;
        } else {
            setFdateError(false);
        }

        if ((!tDate || tDate === "") && searchData.serialNumber === "") {
            setTdateError(true);
            check = true;
        } else {
            setTdateError(false);
        }

        if (check) {
            return;
        }

        searchData.serialNumber === "" ? setImeiSearch(false) : setImeiSearch(true);
        let searchDataManage = searchData;
        searchDataManage.fromDate = fromdate ? '' : moment(searchDataManage.fromDate).format('YYYY-MM-DD');
        searchDataManage.toDate = toDate ? '' : moment(searchDataManage.toDate).format('YYYY-MM-DD');

        dispatch(getActivationReport(companyId, loginID, searchData));
        setFilterText("");
    }

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    };

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'ActivationDetails' + fileExtension);
        }
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    const handleChannelChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            channelID: e.target.value
        }));
    }

    // const dayDiff = Math.abs(moment(fDate).diff(moment(tDate), 'days'));
    // console.log(dayDiff);


    return (
        <>
            <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">Activation Report</h3>
                <div className='card p-2'>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                <div className="col-sm-3 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="channelID">Channel</label>
                                        <div className="form-control-wrap">
                                            <select
                                                name="channelID"
                                                className="form-control"
                                                id="channelID"
                                                onChange={(e) => handleChannelChange(e)}
                                            >
                                                <option value="" key="0" name="select">Select</option>
                                                {props.channelData && props.channelData.map(channel => (
                                                    <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                    <div className="col-sm-3 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="statusID">Status</label>
                                            <div className="form-control-wrap">
                                                <select name="statusID" className="form-control" id="statusID" defaultValue={searchData.statusID} onChange={(e) => handleStatusChange(e)}>
                                                    <option value="" name="All">All</option>
                                                    <option value="27" name="AssignedToCare">Assigned to Care</option>
                                                    <option value="10" name="Pending">Pending</option>
                                                    <option value="11, 18, 19, 20, 21, 22, 27" name="Processing">Processing</option>
                                                    <option value="12, 23, 24" name="Processed">Processed</option>
                                                    <option value="13" name="Cancelled">Cancelled</option>
                                                    {/* <option value="32" name="Refunded">Refunded</option> */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor="fromDate">From Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleFromDateChange(d)} className={fDateError ? "form-control date-picker border border-danger" : "form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="fromDate" closeOnScroll={true} selected={fDate} placeholderText="MM/DD/YYYY"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor='toDate'>To Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleToDateChange(d)} className={tDateError ? "form-control date-picker border border-danger" : "form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="toDate" closeOnScroll={true} selected={tDate} placeholderText="MM/DD/YYYY"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-3">
                                        <div className="form-group"><label className="form-label" htmlFor='serialNumber'>IMEI/Serial No/Channel TXN/Mobile No</label>
                                            <div className="form-control-wrap">
                                                <input type="text" className="form-control" id="serialNumber" name="serialNumber" placeholder="IMEI/Serial No/Channel TXN/Mobile No" onChange={(e) => handleChange(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2 col-md-1'>
                                        <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Search</span> </button>
                                        {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {props.showactivationReportPage && <div className="card p-2" style={{ ...styles.tableContainer, width: !props.loading && filterData.length > 1000 && !imeiSearch ? '50%' : 'unset' }}>

                    {filterData.length > 1000 && !imeiSearch ? <>
                        {props.loading ? <div className="card-inner p-5 text-center" ><Spinner /></div> : <>
                            <div className="card-inner p-5" >
                                <div className='text-center py-5'>
                                    <h5 className='text-center'>Your report is ready for download! Click the link below to retrieve it.</h5>
                                    <p>Note : The dataset you requested is too large to display directly. Please download it using the link below in Excel format for better accessibility.</p>
                                    <Link to="#" className="btn btn-success mt-2" onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Download</Link>
                                </div>
                            </div>
                        </>}
                    </> : <>
                        <div className="card-inner p-0" >
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                <FilterComponent
                                    onFilter={e => setFilterText(e.target.value)}
                                    onClear={handleClear}
                                    filterText={filterText}
                                />
                                <div className='d-flex flex-direction-row'>
                                    <Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                                </div>
                            </div>
                            <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                        </div>
                    </>}

                </div>}
            </div>
        </>
    )
}

const styles = {
    tableContainer: {
        margin: '12px auto',
        WebkitBoxShadow: 'rgb(190, 174, 172) 33px 35px 127px -41px',
    }
}

const mapStateToProps = state => {
    const { channelData } = state.dealerActivationReport;
    const { activationData, apiError, loading, showactivationReportPage } = state.activationReport
    return { activationData, apiError, loading, showactivationReportPage,channelData };
}

export default withRouter(connect(mapStateToProps, {getAllChannelList, getActivationReport, resetActivationReportPage })(ActivationReport));