import { VIEW_ACTIVATION_LIST, VIEW_ACTIVATION_LIST_SUCCESSFULL, VIEW_ACTIVATION_LIST_FAILED, COMPELETE_ACTIVATION, COMPELETE_ACTIVATION_SUCCESSFULL, GET_ERROR_CODE, GET_ERROR_CODE_SUCCESSFULL, DATA_SUBMIT_FAILED, RESET_ALERT_ACTIVATION, REMARK_CANCEL, REMARK_CANCEL_SUCCESSFULL, ACTIVATION_REQUEST_INFO, ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL, LOCK_ACTIVATION, LOCK_ACTIVATION_RESULT, LOCK_ACTIVATION_FAILED_RESULT, UNLOCK_ACTIVATION, UNLOCK_ACTIVATION_SUCCESS, RESET_FLAG_ALERT, ACCESS_TRANSACTION_MODAL, ACCESS_TRANSACTION_MODAL_SUCCESS,CLOSE_ACCESS_TRANSACTION_MODAL, ASSIGN_ACTIVATION, ASSIGN_ACTIVATION_SUCCESSFULL, UPDATE_REFUND_REQUEST, UPDATE_REFUND_REQUEST_SUCCESSFULL, UPDATE_REFUND_REQUEST_FAILED, MESSAGE_ALERT_HIDE, CLOSE_ACTIVATION_CANCEL_MODAL, NOTIFY_POS, NOTIFY_POS_SUCCESSFULL, NOTIFY_POS_FAILED } from "./actionTypes";

// , REPUSH_ACTIVATION, REPUSH_ACTIVATION_SUCCESS, REPUSH_ACTIVATION_FAILED

export const notifyPos = (data, activationID) => {
    return {
        type: NOTIFY_POS,
        payload: { data, activationID }
    }
}

export const notifyPosSuccess = (message, activationID) => {
    return {
        type: NOTIFY_POS_SUCCESSFULL,
        payload: { message, activationID }
    }
}

export const notifyPosFailed = (error, activationID) => {
    return {
        type: NOTIFY_POS_FAILED,
        payload: { error, activationID }
    }
}


export const viewActivationList = (companyId, loginId, searchData) => {

    return {
        type: VIEW_ACTIVATION_LIST,
        payload: { companyId, loginId, searchData }
    }
}

export const viewActivationListSuccess = (viewActivation, message) => {

    return {
        type: VIEW_ACTIVATION_LIST_SUCCESSFULL,
        payload: { viewActivation, message }
    }
}

export const viewActivationListFailed = (error) => {
    return {
        type: VIEW_ACTIVATION_LIST_FAILED,
        payload: error
    }
}

export const compeleteActivation = (infoData, id, companyId, loginId, searchData) => {
    return {
        type: COMPELETE_ACTIVATION,
        payload: { infoData, id, companyId, loginId, searchData }
    }
}
export const compeleteActivationSuccessfull = (activations, message) => {
    return {
        type: COMPELETE_ACTIVATION_SUCCESSFULL,
        payload: { activations, message }
    }
}
export const errorCodeList = (activationId) => {
    return {
        type: GET_ERROR_CODE,
        payload : {activationId}
    }
}

export const errorCodeListSuccessfull = (errorList) => {
    return {
        type: GET_ERROR_CODE_SUCCESSFULL,
        payload: errorList
    }
}

export const remarkCancel = (remarkContent, id, companyId, loginId, searchData) => {
    return {
        type: REMARK_CANCEL,
        payload: { remarkContent, id, companyId, loginId, searchData }
    }
}

export const remarkCancelSuccessfull = (activations, message) => {
    return {
        type: REMARK_CANCEL_SUCCESSFULL,
        payload: { activations, message }
    }
}
export const dataSubmittedFail = (error) => {
    return {
        type: DATA_SUBMIT_FAILED,
        payload: error
    }
}
export const resetAlertActivation = () => {
    return {
        type: RESET_ALERT_ACTIVATION
    }
}
export const resetFlagAlertActivation = () => {
    return {
        type: RESET_FLAG_ALERT
    }
}

export const activationRequestInfo = (id) => {
    return {
        type: ACTIVATION_REQUEST_INFO,
        payload: { id }
    }
}

export const activationRequestInfoSuccessfull = (requestData) => {
    return {
        type: ACTIVATION_REQUEST_INFO_DATA_SUCCESSFULL,
        payload: requestData
    }
}

export const accessTransactionModal = (id) => {
    return {
        type: ACCESS_TRANSACTION_MODAL,
        payload: { id }
    }
}

export const accessTransactionModalSuccess = (requestData) => {
    return {
        type: ACCESS_TRANSACTION_MODAL_SUCCESS,
        payload: requestData
    }
}

export const closeAccessTransactionModal = () => {
    return {
        type: CLOSE_ACCESS_TRANSACTION_MODAL,
    }
}

export const lockActivation = (lockActivationData, searchData) => {
    return {
        type: LOCK_ACTIVATION,
        payload: { lockActivationData, searchData }
    }
}
export const lockActivationResultData = (lockData, activationData) => {
    return {
        type: LOCK_ACTIVATION_RESULT,
        payload: { lockData, activationData }
    }
}
export const lockActivationFailedResult = (lockData) => {
    return {
        type: LOCK_ACTIVATION_FAILED_RESULT,
        payload: lockData
    }
}

export const unLockActivation = (unLockData, searchData) => {
    return {
        type: UNLOCK_ACTIVATION,
        payload: { unLockData, searchData }
    }
}

export const unLockActivationSuccess = (unLockData, activationData) => {
    return {
        type: UNLOCK_ACTIVATION_SUCCESS,
        payload: { unLockData, activationData }
    }
}

export const assignActivation = (companyId, loginId, searchData, pendingRequests) => {

    return {
        type: ASSIGN_ACTIVATION,
        payload: { companyId, loginId, searchData, pendingRequests }
    }
}

export const assignActivationSuccessfull = (activationData, assigned) => {

    return {
        type: ASSIGN_ACTIVATION_SUCCESSFULL,
        payload: { activationData, assigned }
    }
}

export const updateRequestforRefund = (refundData, requestId, companyId, loginId, searchData) => {
    return {
        type: UPDATE_REFUND_REQUEST,
        payload: { refundData, requestId, companyId, loginId, searchData }
    }
}

export const updateRequestForRefundSuccessfull = (activations, message) => {
    return {
        type: UPDATE_REFUND_REQUEST_SUCCESSFULL,
        payload: { activations, message }
    }
}

export const updateRequestForRefundFailed = (message) => {
    return {
        type: UPDATE_REFUND_REQUEST_FAILED,
        payload: message
    }
}

// export const repushActivation = (requestId, companyId, loginId, searchData) => {
//     return {
//         type: REPUSH_ACTIVATION,
//         payload: { requestId, companyId, loginId, searchData }
//     }
// }

// export const repushActivationSuccessfull = (activations, message) => {
//     return {
//         type: REPUSH_ACTIVATION_SUCCESS,
//         payload: { activations, message }
//     }
// }

// export const repushActivationFailed = (message) => {
//     return {
//         type: REPUSH_ACTIVATION_FAILED,
//         payload: message
//     }
// }

export const closeActivationCancelModal = () => {
    return {
        type: CLOSE_ACTIVATION_CANCEL_MODAL,
    }
}

export const messageAlertHide = () => {
    return {
        type: MESSAGE_ALERT_HIDE
    }
}