import { RESET_CHANNEL_ACTIVATION_REPORT_PAGE, SEARCH_CHANNEL_ACTIVATION, SEARCH_CHANNEL_ACTIVATION_REPORT_FAILED, SEARCH_CHANNEL_ACTIVATION_SUCCESSFULL } from "./actionTypes";

const initialState = {
    apiError: null, Message: null, loading: false, modalMessage: null, searchReportData: [], showChannelReportPage: false
}

const channelActivationReport = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_CHANNEL_ACTIVATION:
            state = {
                ...state,
                loading: true,
                showChannelReportPage: true
            }
            break;
        case SEARCH_CHANNEL_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                searchReportData: action.payload,
                showChannelReportPage: true
            }
            break;

        case SEARCH_CHANNEL_ACTIVATION_REPORT_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                showChannelReportPage: true
            }
            break;
        case RESET_CHANNEL_ACTIVATION_REPORT_PAGE:
            state = {
                ...state,
                loading: false,
                apiError: null,
                showChannelReportPage: false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default channelActivationReport;