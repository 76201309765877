import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getMailByMsisdnSuccess, getMailByMsisdnFail, readMailSuccess, readMailFail } from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';
import { GET_MAIL_BY_MSISDN, READ_MAIL } from './actionTypes';

function* getMailByMsisdn({ payload: { data } }) {
    try {
        const response = yield call(addData, '/getMailsByMsisdn', data);
        if (response.response.Status) {
            yield put(getMailByMsisdnSuccess(response.response.Emails));
        }
        else {
            yield put(getMailByMsisdnFail(response.response.Message));
        }
    } catch (error) {
        yield put(getMailByMsisdnFail(error.message));
    }
}

export function* watchgetMailByMsisdn() {
    yield takeEvery(GET_MAIL_BY_MSISDN, getMailByMsisdn)
}

function* readMail({ payload: { mailID } }) {
    try {
        const response = yield call(getList, '/readMail/' + mailID);
        if (response.response.Status) {
            yield put(readMailSuccess(response.response.Message, mailID));
        }
        else {
            yield put(readMailFail(response.response.Message));
        }
    } catch (error) {
        yield put(readMailFail(error.message));
    }
}

export function* watchReadMail() {
    yield takeEvery(READ_MAIL, readMail)
}


function* emailInboxSaga() {
    yield all([
        fork(watchgetMailByMsisdn),
        fork(watchReadMail),
    ]);
}

export default emailInboxSaga;