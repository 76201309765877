import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { getList, addData } from '../../helpers/fackBackend_Helper';
import {  getAllChannelListSuccessfull, processedActivationSuccessfull, searchDealerActivationFailed, searchDealerActivationSuccess } from './actions';
import {  GET_ALL_CHANNEL_LIST, PROCESSED_ACTIVATION, SEARCH_DEALER_ACTIVATION } from './actionTypes';

function* getAllChannel({ payload: dealerId }) {
    try {
        const response = yield call(getList, '/getChannelName/' + dealerId);
        if (response.Status) {
            yield put(getAllChannelListSuccessfull(response.channel));
        }
        else {
            yield put(searchDealerActivationFailed(response.Message));
        }
    } catch (error) {
        yield put(searchDealerActivationFailed(error));
    }
}

export function* watchChannelActivation() {
    yield takeEvery(GET_ALL_CHANNEL_LIST, getAllChannel)
}

function* getSearchDealerActivation({ payload: searchData }) {
    try {
        const response = yield call(addData, '/getAllActivationRequestByDealer', searchData);
        if (response.Status) {
            const responseExcelData = yield call(addData, '/getAllProcessedActivationRequestInExcelByChannelDealerID', searchData);
            if(responseExcelData.Status){
                yield put(searchDealerActivationSuccess(response.activationdata,responseExcelData.allActivationData));
            }else {
                yield put(searchDealerActivationFailed(response.message));
            }
        }
        else {
            yield put(searchDealerActivationFailed(response.message));
        }
    } catch (error) {
        yield put(searchDealerActivationFailed(error));
    }
}

export function* watchSearchDealerActivation() {
    yield takeEvery(SEARCH_DEALER_ACTIVATION, getSearchDealerActivation)
}

function* getProcessedActivation({ payload: processedData }) {
    try {
        const response = yield call(addData, '/getAllProcessedActivationRequestByDealerID', processedData);
        if (response.Status) {
            yield put(processedActivationSuccessfull(response.activationdata));
        }
        else {
            yield put(searchDealerActivationFailed(response.message));
        }
    } catch (error) {
        yield put(searchDealerActivationFailed(error));
    }
}

export function* watchProcessedActivation() {
    yield takeEvery(PROCESSED_ACTIVATION, getProcessedActivation)
}
function* dealerActivationReportSaga() {
    yield all([
        fork(watchChannelActivation),
        fork(watchSearchDealerActivation),
        fork(watchProcessedActivation),
    ]);
}
export default dealerActivationReportSaga;

