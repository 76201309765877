import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from "moment";
import momentTimezone from 'moment-timezone';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import FilterComponent from "./FilterComponent";
import DataTable from 'react-data-table-component';
import Spinner from '../components/layout/Spinner';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { viewSimSwapRequest, simSwapLockActivation, simSwapUnLockActivation, resetSimSwapAlertActivation, simSwapErrorCodeList, simSwapMessageAlertHide, closeSimSwapCancelModal, simSwapRemarkCancel, resetSimSwapRequestPage, compeleteSimSwapActivation, getMsisdnData, addSIMSwapRequest, getOtp, resetCompleteSimSwap, checkSimSwapCompletion } from '../store/simSwapRequest/actions';
import { PERMISSIONS } from '../Permission/Permission';

function SimSwapRequest(props) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const timezone = process.env.REACT_APP_TIMEZONE;
    const dispatch = useDispatch();
    const [fDate, setFdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [tDate, setTdate] = useState(new Date());
    const [RoleID, setRoleID] = useState(null);
    const [companyId, setcompanyId] = useState("");
    const [loginID, setloginID] = useState(null);
    const [editId, seteditId] = useState(null);
    const [dataSearch, setDataSearch] = useState(false);
    const [filterText, setFilterText] = useState("");
    const [showModal, setshowModal] = useState(false);
    const [errorCodeField, setErrorCodeField] = useState(false);
    const [remarkContentField, setRemarkContentField] = useState(false);
    const [fDateError, setFdateError] = useState(false);
    const [tDateError, setTdateError] = useState(false);
    const [imeiSearch, setImeiSearch] = useState(false);
    const [addSwapForm, setAddSwapForm] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [newSIMError, setNewSIMError] = useState(false);
    const [showEmail, setShowEmail] = useState(false);

    const [searchData, setSearchData] = useState({
        statusID: '',
        serialNumber: '',
        dealerId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : 0,
        fromDate: fDate,
        toDate: tDate,
    });

    const [swapSearchData, setSwapSearchData] = useState({
        mobileNumber: '',
        dealerId: (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : 0,
        newSIMNumber: '',
        swapDate: moment(new Date()).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
    });

    const [remarkContent, setremarkContent] = useState({
        remark: '',
        errorCode: '',
        ChannelTxnID: "",
        SerialNumber: "",
    });

    const [infoData, setinfoData] = useState({
        remark: ""
    });

    var filterArray = [];
    for (const [key, value] of Object.entries(PERMISSIONS)) {
        if (key == RoleID) {
            filterArray.push(value);
        }
    }

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
        const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;
        const dealerId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : null;
        setRoleID(roleId);
        setloginID(loginId);
        setcompanyId(CompanyID);

        setSearchData(prevState => ({
            ...prevState,
            dealerId: dealerId,
        }));
        setremarkContent(prevState => ({
            ...prevState,
            modifiedDtTm: moment(new Date()).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
            modifiedBy: loginId
        }))
        setinfoData(prevState => ({
            ...prevState,
            modifiedDtTm: moment(new Date()).tz(timezone).format("YYYY-MM-DD HH:mm:ss"),
            modifiedBy: loginId,
        }));
        setSwapSearchData(prevState => ({
            ...prevState,
            dealerId: dealerId,
        }));
        props.resetSimSwapRequestPage();
        // props.viewActivationList(CompanyID, loginId, searchData)
    }, []);

    const handleStatusChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            statusID: e.target.value
        }));
    }

    const handleChange1 = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setSearchData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleChange3 = (e) => {
        const fname = e.target.name;
        const fvalue = e.target.value;

        setremarkContent(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))

        setinfoData(prevState => ({
            ...prevState,
            [fname]: fvalue
        }))
    }

    const handleToDateChange = (d) => {
        setTdate(d);
        setSearchData(prevState => ({
            ...prevState,
            toDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
    }

    const handleFromDateChange = (d) => {
        setFdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromDate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))
    }

    const handleSearchData = (e) => {
        e.preventDefault();
        setDataSearch(true);
        props.resetSimSwapRequestPage();
        let check = false;

        if ((!fDate || fDate === "") && searchData.serialNumber === "") {
            setFdateError(true);
            check = true;
        } else {
            setFdateError(false);
        }

        if ((!tDate || tDate === "") && searchData.serialNumber === "") {
            setTdateError(true);
            check = true;
        } else {
            setTdateError(false);
        }

        if (check) {
            return;
        }
        searchData.serialNumber === "" ? setImeiSearch(false) : setImeiSearch(true);
        let searchDataManage = searchData;
        searchDataManage.fromDate = moment(searchDataManage.fromDate).format('YYYY-MM-DD');
        searchDataManage.toDate = moment(searchDataManage.toDate).format('YYYY-MM-DD');
        dispatch(viewSimSwapRequest(companyId, loginID, searchDataManage))
        setFilterText("");
    }

    const handleMsisdnChange = (e) => {
        setSwapSearchData(prevState => ({
            ...prevState,
            mobileNumber: e.target.value.slice(0, 10)
        }))

        if (e.target.value.length === 10) {
            setMobileError(false);
        }
    }

    const handleSIMChange = (e) => {
        setSwapSearchData(prevState => ({
            ...prevState,
            newSIMNumber: e.target.value.slice(0, 19)
        }))

        if (e.target.value.length === 19) {
            setNewSIMError(false);
        }
    }

    const handleClear = () => {
        if (filterText) {
            setFilterText("");
        }
    };

    const handleRefreshData = () => {
        setFilterText("");
        dispatch(simSwapMessageAlertHide())
        dispatch(viewSimSwapRequest(companyId, loginID, searchData));
    }

    const handleLockActivation = (activationID) => {
        const lockActivationData = {
            activationId: activationID,
            loginId: loginID,
            companyId: companyId
        }
        dispatch(simSwapLockActivation(lockActivationData, searchData));
    }

    const handleUnLockActivation = (activationID) => {
        const unLockData = {
            activationId: activationID,
            loginId: loginID,
            companyId: companyId
        }
        dispatch(simSwapUnLockActivation(unLockData, searchData));
    }

    const handleCompleteModalOpen = (id) => {
        dispatch(resetCompleteSimSwap());
        dispatch(simSwapMessageAlertHide());
        dispatch(resetSimSwapAlertActivation());
        setShowEmail(false);
        setshowModal(true);
        seteditId(id);

        setinfoData(prevState => ({
            ...prevState,
            remark: ""
        }));

        setremarkContent(prevState => ({
            ...prevState,
            remark: '',
            errorCode: '',
            ChannelTxnID: '',
            SerialNumber: '',
        }));
    }

    const handleRemarkModalOpen = (id, TXNID, SIMNo) => {
        dispatch(resetSimSwapAlertActivation());
        dispatch(simSwapMessageAlertHide())
        seteditId(id);
        // setRemarkModal(true);
        setremarkContent(prevState => ({
            ...prevState,
            remark: '',
            errorCode: '',
            ChannelTxnID: TXNID,
            SerialNumber: SIMNo,
        }));
        dispatch(simSwapErrorCodeList(id));
    }

    const handleCancelAlert = () => {
        dispatch(resetSimSwapAlertActivation());
        dispatch(viewSimSwapRequest(companyId, loginID, searchData));
    }

    const filteredItems = props.simSwapRequestData && props.simSwapRequestData.filter(
        item =>
            JSON.stringify(item)
                .toLowerCase()
                .indexOf(filterText.toLowerCase()) !== -1
    )

    const columns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'ACTION',
            width: "155px",
            selector: row => row.action,
        },
        {
            name: 'TXN #',
            width: "170px",
            selector: row => row.TransactionID,
        },
        {
            name: 'MOBILE #',
            width: "110px",
            selector: row => row.MobileNumber,
        },
        {
            name: 'SERIAL/EID',
            width: "250px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'STATUS',
            width: "120px",
            selector: row => row.MasterStatus,
        },
        {
            name: 'REQUEST DATE',
            width: "120px",
            selector: row => row.RequestDate,
        },
        {
            name: 'CHANNEL',
            width: "90px",
            selector: row => row.ChannelName,
        },
        {
            name: 'SWAP DATE',
            width: "120px",
            selector: row => row.SwapDate,
        },
        {
            name: 'SIM',
            selector: row => row.SimType,
        },
    ];

    const filterData = filteredItems && filteredItems.map((data, key) => {
        return {
            serial: key + 1,
            action: (data.RequestStatusID !== 12 && data.RequestStatusID !== 13 && data.RequestStatusID !== 24 ? (
                data.lockFlag === 0 ? <span>{(data.lockFlag === 0) && (data.otherLockFlag === 0) ?
                    <span>
                        {
                            data.lockLoading ? <em title="To Lock" className="icon icon-circle ni ni-eye bg-indigo lo-be button-loader-spinner" style={{ 'cursor': 'pointer' }}>
                                <div id="loading-bar-spinner-button" className="spinner-button"><div className="spinner-icon"></div></div>
                            </em> : <em className='icon icon-circle icon ni ni-lock-alt bg-indigo' data-bs-toggle="tooltip" data-bs-placement="top" title="To Lock" style={{ 'cursor': 'pointer' }} onClick={() => handleLockActivation(data.ID)}></em>
                        }
                    </span> :
                    <em className='icon icon-circle icon ni ni-na bg-danger' data-bs-toggle="tooltip" data-bs-placement="top" title="To Lock" style={{ 'cursor': 'pointer' }} onClick={() => handleLockActivation(data.ID)}></em>}</span> :
                    <>
                        <em className="icon icon-circle icon bg-success ni ni-done" data-bs-toggle="tooltip" data-bs-placement="top" title="Complete" style={{ 'cursor': 'pointer' }} onClick={() => handleCompleteModalOpen(data.ID)}></em>
                        {
                            data.cancelModalLoading ? <em title="Cancel" className="icon icon-circle ni ni-eye bg-danger lo-be button-loader-spinner mx-2" style={{ 'cursor': 'pointer' }}>
                                <div id="loading-bar-spinner-button" className="spinner-button"><div className="spinner-icon"></div></div>
                            </em> : <em className="icon icon-circle icon bg-danger ni ni-cross mx-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel" style={{ 'cursor': 'pointer' }} onClick={() => handleRemarkModalOpen(data.ID, data.TransactionID, data.SerialNumber)}></em>
                        }
                        {
                            data.releaseLoading ? <em title="Release" className="icon icon-circle ni ni-eye bg-orange lo-be button-loader-spinner" style={{ 'cursor': 'pointer' }}>
                                <div id="loading-bar-spinner-button" className="spinner-button"><div className="spinner-icon"></div></div>
                            </em> : <em className="icon icon-circle icon ni ni-unlock bg-orange" data-bs-toggle="tooltip" data-bs-placement="top" title="Release" onClick={() => handleUnLockActivation(data.ID)} style={{ 'cursor': 'pointer' }}></em>
                        }

                        {/* {
                        data.notifyPosLoading ? <em title="Release" className="icon icon-circle ni ni-eye bg-primary lo-be button-loader-spinner" style={{ 'cursor': 'pointer', marginLeft: '10px' }}>
                            <div id="loading-bar-spinner-button" className="spinner-button"><div className="spinner-icon"></div></div>
                        </em> : <em className="icon icon-circle icon bg-primary ni ni-notify" data-bs-toggle="tooltip" data-bs-placement="top" title="Complete" style={{ 'cursor': 'pointer', marginLeft: '10px' }} onClick={() => handleNotifyPos(data.ID)}></em>
                    } */}


                    </>
            ) : ''
            ),
            TransactionID: (<p>{data.TransactionID}</p>),
            ChannelName: (<p>{data.ChannelName}</p>),
            MasterStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : (data.RequestStatusID === 32) ? 'bg-dark' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : data.RequestStatusID === 27 ? 'Assigned to Care' : (data.RequestStatusID === 32) ? 'Refunded' : 'Pending'} </span>),
            SerialNumber: (data.SerialNumber !== "null" ? <p className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p> : ''),
            MobileNumber: (data.MSISDN !== "null" ? <p>{data.MSISDN}</p> : ''),
            AccountNumber: (data.AccountNumber !== "null" ? <p>{data.AccountNumber}</p> : ''),
            RequestDate: (<p>{moment(new Date(data.RequestDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
            SwapDate: (<p>{moment(new Date(data.SwapDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
            SimType: (<p >{data.SimType}</p>),
            RequestStatus: (<span className={`${(data.RequestStatusID === 13 || data.RequestStatusID === 24) ? 'text-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'text-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23) ? 'text-success' : 'text-secondary'} d-none d-sm-inline-flex`}><strong>{data.RequestStatus}</strong></span>),
            // activationSource: <p>{data.ActivationSource}</p>,

        }
    })

    const listData = props.simSwapRequestData && props.simSwapRequestData.map((data, key) => {
        return {
            SNo: key + 1,
            TXNID: data.TransactionID,
            CHANNELNAME: data.ChannelName,
            STATUS: (data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : data.RequestStatusID === 27 ? 'Assigned to Care' : 'Pending',
            REQUESTDATE: (moment(new Date(data.RequestDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")),
            SWAPDATE: (moment(new Date(data.SwapDate)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")),
            SERIAL: data.SerialNumber,
            MOBILENUMBER: data.MSISDN,
            SIM: data.SimType,
        }
    });

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'SimSwapRequest' + fileExtension);
        }
    }

    const handleBack = () => {
        setShowEmail(false);
        setremarkContent(prevState => ({
            ...prevState,
            remark: '',
            errorCode: '',
            ChannelTxnID: '',
            SerialNumber: '',
        }))

        setinfoData(prevState => ({
            ...prevState,
            remark: ""
        }));
        setshowModal(false)

        // setRemarkModal(false);
        dispatch(closeSimSwapCancelModal());
        setRemarkContentField(false);
        setErrorCodeField(false);
        // setRefundModal(false);
        // setchildDatashowFlag(false);
        // setchildFormDataShow(null);

    }

    const handleCompeletedData = (e) => {
        e.preventDefault();
        setShowEmail(false);
        setremarkContent(prevState => ({
            ...prevState,
            remark: '',
            errorCode: '',
        }))

        dispatch(compeleteSimSwapActivation(infoData, editId, companyId, loginID, searchData));
    }

    const handleRemarkSubmit = (e) => {
        e.preventDefault();
        // if (remarkContent.errorCode === "") {
        //     setErrorCodeField(true);
        //     return false;
        // }
        // setErrorCodeField(false);
        setShowEmail(false);
        if (remarkContent.remark === "") {
            setRemarkContentField(true);
            return false;
        }
        setRemarkContentField(false);
        dispatch(simSwapRemarkCancel(remarkContent, editId, companyId, loginID, searchData))
    }

    const handleAddForm = () => {
        setShowEmail(false);
        setAddSwapForm(true);
    }

    const handleCancel = () => {
        setAddSwapForm(false);
        setShowEmail(false);
        setSwapSearchData(prevState => ({
            ...prevState,
            mobileNumber: '',
            newSIMNumber: ''
        }))

        setMobileError(false);
        dispatch(resetSimSwapAlertActivation());
    }

    const handleMsisdnSearch = () => {
        setShowEmail(false);
        if (swapSearchData.mobileNumber.length != 10) {
            setMobileError(true);
        }
        else {
            setMobileError(false);
            dispatch(getMsisdnData(swapSearchData));
        }
    }

    const handleSimSwapRequest = () => {
        setShowEmail(false);
        if (swapSearchData.newSIMNumber.length != 19) {
            setNewSIMError(true);
        }
        else {
            setNewSIMError(false);
            let addSwapData = {
                "SerialNumber": swapSearchData.newSIMNumber,
                "MSISDN": swapSearchData.mobileNumber,
                "SwapDate": swapSearchData.swapDate,
                "ChannelDealer": props.msisdnDetails.ChannelDealer,
                "DistributerID": null,
                "CustomerEmail": props.msisdnDetails.CustomerEmail,
            }

            dispatch(addSIMSwapRequest(addSwapData, companyId, loginID, searchData));
        }
    }

    const handleGetOTP = () => {
        setShowEmail(false);
        dispatch(getOtp(editId));
    }

    const handleEmailView = () => {
        setShowEmail(true);
    }

    function extractSixDigitCode(textString) {
        // Regular expression to match a 6-digit number
        const regex = /\b\d{6}\b/;
        // Find the first match in the text
        const match = textString.match(regex);
        // Return the matched 6-digit code or null if not found
        return match ? match[0] : null;
    }

    if (props.messageModal) {
        setTimeout(() => {
            // setRemarkModal(false);
            dispatch(closeSimSwapCancelModal());
            setshowModal(false);
        }, 2000);
    }

    if (props.apiError === 'Invalid token') {
        localStorage.removeItem("authUser");
        localStorage.removeItem("token");
        window.location.href = '/';
    }

    const handleConfirmation = () => {
        setShowEmail(false);
        dispatch(checkSimSwapCompletion(editId));
    }


    return (
        <>
            <div className="nk-block-head-content">
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                    <h3 className="nk-block-title page-title">SIM Swap Request</h3>
                    {filterArray && filterArray.length > 0 && filterArray[0].includes("add_sim_swap") && <>
                        <div data-target="addProduct" className="toggle btn btn-icon btn-primary d-md-none" onClick={(handleAddForm)}>
                            <em className="icon ni ni-plus"></em>
                        </div>
                        <div data-target="addProduct" className="toggle btn btn-primary d-none d-md-inline-flex" onClick={(handleAddForm)}>
                            <em className="icon ni ni-plus"></em>
                            <span>Add SIM Swap</span>
                        </div>
                    </>}
                </div>
                <div className='card p-2' style={{ marginTop: "12px" }}>
                    <div className="card-inner p-0" >
                        <div className="nk-block">
                            <form onSubmit={handleSearchData} method="post">
                                <div className="row g-3">
                                    <div className="col-sm-3 col-md-2">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="statusID">Status</label>
                                            <div className="form-control-wrap">
                                                <select name="statusID" className="form-control" id="statusID" defaultValue={searchData.statusID} onChange={(e) => handleStatusChange(e)}>
                                                    <option value="" name="All">All</option>
                                                    <option value="27" name="AssignedToCare">Assigned to Care</option>
                                                    <option value="10" name="Pending">Pending</option>
                                                    <option value="11, 18, 19, 20, 21, 22, 27" name="Processing">Processing</option>
                                                    <option value="12, 23, 24" name="Processed">Processed</option>
                                                    <option value="13" name="Cancelled">Cancelled</option>
                                                    {/* <option value="32" name="Refunded">Refunded</option> */}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor="fromDate">From Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleFromDateChange(d)} className={fDateError ? "form-control date-picker border border-danger" : "form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="fromDate" closeOnScroll={true} selected={fDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-2 col-md-2">
                                        <div className="form-group"><label className="form-label" htmlFor='toDate'>To Date</label>
                                            <div className="form-control-wrap">
                                                <DatePicker onChange={(d) => handleToDateChange(d)} className={tDateError ? "form-control date-picker border border-danger" : "form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="toDate" closeOnScroll={true} selected={tDate} placeholderText="MM/DD/YYYY" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-3 col-md-4">
                                        <div className="form-group"><label className="form-label" htmlFor='serialNumber'>IMEI/Serial No/Channel TXN/Mobile No</label>
                                            <div className="form-control-wrap">
                                                <input type="text" className="form-control" id="serialNumber" name="serialNumber" placeholder="IMEI/Serial No/Channel TXN/Mobile No" onChange={(e) => handleChange1(e)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-sm-2 col-md-1'>
                                        <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                            className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                            <span>Search</span> </button>
                                        {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {props.showSimSwapPage ?
                    <div className="card p-2" style={{ marginTop: '12px' }}>
                        <div className="card-inner p-0" >
                            {props.notifyPosMessage && <div className="example-alert mb-2"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.notifyPosMessage}</strong></div></div>}
                            {props.assignToCareMessage && <div className="example-alert mb-2"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.assignToCareMessage}</strong></div></div>}
                            {props.notifyPosError && <div className="example-alert mb-2"><div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.notifyPosError}</strong></div></div>}
                            <div className='d-flex justify-content-between align-items-center mb-3'>
                                <FilterComponent
                                    onFilter={e => setFilterText(e.target.value)}
                                    onClear={handleClear}
                                    filterText={filterText}
                                />
                                <div className='d-flex flex-direction-row'>
                                    <em className="icon icon-circle icon bg-light icon ni ni-reload mx-4" onClick={handleRefreshData} style={{ 'cursor': 'pointer' }}></em>
                                    <Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                                </div>
                            </div>
                            <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                            {/* {filterArray && filterArray.length > 0 && filterArray[0].includes("assign_to_care") && (showAssign && <Link to="#" style={{ 'cursor': 'pointer' }} onClick={handlePendingAssign} className="btn btn-info mx-2">{props.assignToCareLoading ? 'Loading..' : 'Assign To Care' }</Link>)} */}
                        </div>
                        {addSwapForm && <div className="toggle-overlay" data-target="addProduct"></div>}
                    </div>
                    : ''}
            </div>

            {addSwapForm && (<div className="nk-add-productdealer toggle-slide toggle-slide-right toggle-screen-any content-active" data-content="addProduct" data-toggle-screen="any" data-toggle-overlay="true" data-toggle-body="true" data-simplebar="init">
                <div className="simplebar-wrapper" style={{ margin: "-24px" }}>
                    <div className="simplebar-height-auto-observer-wrapper">
                        <div className="simplebar-height-auto-observer"></div>
                    </div>
                    <div className="simplebar-mask">
                        <div className="simplebar-offset" style={{ right: "0px", bottom: "0px" }}>
                            <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{ height: "100%", overflow: "hidden scroll" }}>
                                <div className="simplebar-content" style={{ padding: "24px" }}>
                                    <div className="nk-block-head">
                                        <div className="nk-block-head-content">
                                            <h5 className="nk-block-title">SIM Swap</h5>
                                            <div className="nk-block-des">
                                                <p>Search MSISDN for details</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="nk-block">
                                        <div className="row g-3">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="mobileNumber">MSISDN</label>
                                                    <div className="form-control-wrap">
                                                        <input type="number" name="mobileNumber" onChange={handleMsisdnChange} className="form-control" id="mobileNumber" value={swapSearchData.mobileNumber} autoComplete="off" placeholder="MSISDN" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} style={{ 'border': mobileError ? '1px solid red' : '' }} />
                                                        {/*  */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label className="form-label">&nbsp;</label>
                                                <div className="form-control-wrap">
                                                    <button type='button' onClick={handleMsisdnSearch} disabled={props.loading} className="btn btn-primary">{props.loading === true && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}<span>Fetch</span></button>
                                                    <button className="btn btn-secondary mx-2" onClick={handleCancel}>Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {props.message && <div className="alert alert-success alert-icon" style={{ 'marginTop': '15px' }}><em className="icon ni ni-check-circle"></em> <strong>{props.message}</strong></div>}
                                    {props.msisdnDetails && <div className="modal-body" style={{ 'border': '1px solid', 'marginTop': '15px' }}>
                                        {props.apiSwapError && <div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiSwapError}</strong></div>}
                                        <div className='border-bottom'>
                                            <h6 className="title">REQUEST INFORMATION</h6>
                                            <hr></hr>
                                            <div className={`row g-4`} style={{ top: '-33px !important' }}>
                                                <div className="col-sm-7">
                                                    <div className='d-flex justify-content-between'><label className="form-label" htmlFor="IMEI">IMEI</label><p>{props.msisdnDetails.IMEI}</p></div>
                                                    <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Serial">Serial #</label><p className={props.msisdnDetails.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''}>{props.msisdnDetails.SerialNumber}</p></div>
                                                    <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Request">Activation Type</label><p>{props.msisdnDetails.RequestType === 'PortIn' ? props.msisdnDetails.RequestType : 'Normal'}</p></div>
                                                    <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Customer">Customer Email</label><p>{props.msisdnDetails.CustomerEmail}</p></div>
                                                    <div className="d-flex justify-content-between"><label className="form-label" htmlFor="Dealer">Dealer Name</label><p>{props.msisdnDetails.ChannelDealerName}</p></div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className='d-flex justify-content-between'><label className="form-label" htmlFor="SIM">SIM Type</label><p>{props.msisdnDetails.SimType}</p></div>
                                                    <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Mobile">Mobile #</label><p>{props.msisdnDetails.MSISDN}</p></div>
                                                    <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Account">Account #</label><p>{props.msisdnDetails.AccountNumber}</p></div>
                                                    <div className='d-flex justify-content-between'><label className="form-label" htmlFor="Wifi">WiFi Calling </label><p>{props.msisdnDetails.WiFiCalling && props.msisdnDetails.WiFiCalling ? 'Yes' : 'No'}</p></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='border-bottom mb-3 mt-3'>
                                            <h6 className="title">PLAN INFORMATION</h6>
                                            <hr></hr>
                                            <div className='row'>
                                                <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Category</label></div>
                                                <div className='col-sm-9'><p>{props.msisdnDetails.PlanCategory}</p></div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Name</label></div>
                                                <div className='col-sm-9'><p>{props.msisdnDetails.PlanName}</p></div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Price</label></div>
                                                <div className='col-sm-3'><p>${props.msisdnDetails.PlanPrice}</p></div>
                                                <div className='col-sm-3'><label className="form-label" htmlFor="Plan">Plan Month</label></div>
                                                <div className='col-sm-3 text-right'><p>{props.msisdnDetails.PlanMonth} month(s)</p></div>
                                            </div>
                                            {/* <div className='row pb-1'>
                                                <div className='col-sm-3'><label className="form-label mb-0" htmlFor="Services">Add On Services</label></div>
                                                {props.msisdnDetails.AddOnServices && props.msisdnDetails.AddOnServices.split(',').map((item, index) => {
                                                    var serviceData = item.split('_');
                                                    return <><div className={`col-sm-7 ${(index > 0) ? 'offset-3' : ''}`}><span><em className="icon ni ni-check text-success"></em> {serviceData[0]}</span></div><div className='col-sm-2 text-right'><span>${serviceData[1]}</span></div></>
                                                })}
                                            </div> */}
                                        </div>
                                        {/* <div className='border-bottom my-3'>
                                            <h6 className="title">CUSTOMER INFORMATION</h6>
                                            <hr></hr>
                                            <div className="row">
                                                <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">First Name</label>
                                                    <p>{props.msisdnDetails.FirstName ?? props.msisdnDetails.FirstName}</p>
                                                </div>
                                                <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Middle Name</label>
                                                    <p>{props.msisdnDetails.MiddleInitial ?? props.msisdnDetails.MiddleInitial}</p>
                                                </div>
                                                <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Last Name</label>
                                                    <p>{props.msisdnDetails.LastName ?? props.msisdnDetails.LastName}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Address 1</label>
                                                    <p>{props.msisdnDetails.Address1 ?? props.msisdnDetails.Address1}</p>
                                                </div>
                                                <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">Address 2</label>
                                                    <p>{props.msisdnDetails.Address2 ?? props.msisdnDetails.Address2}</p>
                                                </div>
                                                <div className="col-sm-4 d-flex justify-content-between"> <label className="form-label" htmlFor="accountNumber">ZipCode</label>
                                                    <p>{props.msisdnDetails.ZipCode ?? props.msisdnDetails.ZipCode}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="newSerialNumber">New SIM Number</label>
                                                    <div className="form-control-wrap">
                                                        <input type="number" name="newSerialNumber" onChange={handleSIMChange} className="form-control" id="newSerialNumber" value={swapSearchData.newSIMNumber} autoComplete="off" placeholder="New SIM Number" onWheel={(e) => e.target.blur()} onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()} style={{ 'border': newSIMError ? '1px solid red' : '' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label" htmlFor="newSerialNumberBtn">&nbsp;</label>
                                                    <div className="form-control-wrap" style={{ 'textAlign': 'center' }}>
                                                        <button type='button' onClick={handleSimSwapRequest} disabled={props.addLoading} className="btn btn-danger">{props.addLoading === true && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}<span>Add for SIM Swap</span></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                    {props.apiError && <div className="modal-body"><div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiError}</strong></div></div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}

            {props.LockFailAlert === true ? <div className="swal2-container swal2-center swal2-backdrop-show" style={{ "overflowY": "auto" }}>
                <div aria-labelledby="swal2-title" aria-describedby="swal2-content" className="swal2-popup swal2-modal swal2-show"
                    tabIndex="-1" role="dialog" aria-live="assertive" aria-modal="true" style={{ "display": "flex" }}>
                    <div className="swal2-header">
                        <div className="swal2-icon swal2-error swal2-icon-show" style={{ "display": "flex" }}><span className="swal2-x-mark">
                            <span className="swal2-x-mark-line-left"></span>
                            <span className="swal2-x-mark-line-right"></span>
                        </span>
                        </div>
                        <h2 className="swal2-title" id="swal2-title" style={{ "display": "flex", "fontSize": '1.2rem' }}>{props.LockedFailedmessage} </h2>
                        <h4>{props.LockedByUser && ` by ${props.LockedByUser}`}</h4>
                        <button
                            type="button" className="swal2-close" aria-label="Close this dialog" style={{ "display": "none" }}>×</button>
                    </div>

                    <div className="swal2-actions"><button type="button" className="swal2-confirm swal2-styled" aria-label=""
                        style={{ "display": "inline-block", "borderLeftColor": "rgb(30, 224, 172)", "borderRightColor": "rgb(30, 224, 172)" }} onClick={handleCancelAlert}>OK</button>
                    </div>
                </div>
            </div> : null}

            {/* remark modal */}
            {props.cancelModal && <div className="modal fade show" tabIndex="-1" id="modalLarge" style={{ 'display': 'block' }} aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content"><div className="modal-header">
                        <div><h5 className="modal-title">Cancel SIM Swap Request - {remarkContent.ChannelTxnID}</h5></div>
                        {/* <div className='d-flex justify-content-between'><p>{remarkContent.SerialNumber}</p></div> */}
                        <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={handleBack}><em className="icon ni ni-cross"></em></div>
                    </div>
                        <div className="modal-body">
                            {props.apiErrorModal && <div className="example-alert"><div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiErrorModal} !</strong></div></div>}
                            {props.messageModal && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.messageModal}</strong></div></div>}
                            <form className="form-validate is-alter" onSubmit={handleRemarkSubmit} method="post">
                                {/* <div className="form-group">
                                    <label className="form-label" htmlFor="errorCode">Errors</label>
                                    <div className="form-control-wrap">
                                        <select
                                            name="errorCode"
                                            className="form-control"
                                            id="errorCode"
                                            onChange={handleChange3}
                                            value={remarkContent.errorCode}
                                            style={{ 'border': errorCodeField ? '1px solid red' : '' }}
                                        >
                                            <option value="" name="select">Select</option>
                                            {props.errorList && props.errorList.map(error => (
                                                <option value={error.ErrorCode} name="errorCode" key={error.ErrorCode}>{error.ErrorDesc}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div> */}
                                <div className="form-group"><label className="form-label" htmlFor="remark">Remark</label>
                                    <div className="form-control-wrap"><textarea className="form-control"
                                        id="remark" placeholder='Enter Remark here....' name="remark" value={remarkContent.remark} onChange={handleChange3} style={{ 'border': remarkContentField ? '1px solid red' : '' }}></textarea></div>
                                </div>
                                <div className="form-group text-right">
                                    <hr></hr>
                                    <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleBack}>Back</button>
                                    <button type='submit' disabled={props.buttonLoading} className="btn btn-lg btn-primary">{props.buttonLoading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Submit</span> </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>}

            {/* compelete activation modal */}

            {showModal && <div className="modal fade zoom show" tabIndex="-1" id="modalZoom" style={{ "display": "block" }} aria-modal="true" role="dialog">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Complete SIM Swap Request</h5><div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={handleBack}><em className="icon ni ni-cross"></em></div>
                        </div>
                        <div className="modal-body">
                            {props.apiErrorModal && <div className="example-alert"><div className="alert alert-danger alert-icon"><em className="icon ni ni-cross-circle"></em> <strong>{props.apiErrorModal} !</strong></div></div>}
                            {props.messageModal && <div className="example-alert"><div className="alert alert-success alert-icon"><em className="icon ni ni-check-circle"></em> <strong>{props.messageModal}</strong></div></div>}

                            <div style={{ 'position': 'relative' }}>
                                <p>Are you sure you want to proceed?</p>
                                {props.otpDetails === null || props.otpDetails.length === 0 ? <button type='button' onClick={handleGetOTP} disabled={props.addLoading} className="btn btn-sm btn-danger" style={{ cursor: 'pointer', position: 'absolute', right: '0', top: '0' }}>{props.getOtpLoading === true && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}<span>Get OTP</span></button> : ''}
                                {props.otpDetails !== null && props.otpDetails.length > 0 ? <button className="btn btn-sm" disabled={props.checkSimSwapCompletionMessage} onClick={() => handleConfirmation()} style={{ cursor: 'pointer', position: 'absolute', right: '0', top: '0', background: props.checkSimSwapCompletionMessage ? '#2ACB13' : '#ffa353', color: '#fff' }}>{props.checkSimSwapCompletionLoading === true && <span className="spinner-border spinner-border-sm" aria-hidden="true"></span>}<span>{props.checkSimSwapCompletionMessage ? 'Completed' :'Check Confirmation'}</span></button> : ''}
                            </div>

                            <div style={{ 'height': '60px' }}>{props.showOtp && props.otpDetails &&
                                (props.otpDetails.length > 0) ? <div>
                                <p className='pb-0 mb-0' style={{ 'color': 'green', 'fontSize': '24px', 'fontWeight': '700', 'textAlign': 'center' }}>{extractSixDigitCode(props.otpDetails[0].EmailText)}</p><p style={{ 'display': 'flex', 'justifyContent': 'space-between', 'fontSize': '14px' }}><span style={{ 'textAlign': 'left', 'padding': '0', 'fontStyle': 'italic' }}><>Received at: {moment(new Date(props.otpDetails[0].CreatedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY HH:mm:ss")} PST</></span><span style={{ 'textAlign': 'right', 'display': 'block', 'padding': '0' }}><em className="icon ni ni-mail" data-bs-toggle="tooltip" data-bs-placement="top" title="View Email" style={{ 'cursor': 'pointer' }} onClick={() => handleEmailView()}></em></span></p></div> : (props.showOtp) ? <div><p style={{ 'color': 'red', 'textAlign': 'center' }}>OTP not received yet</p></div> : ''
                            }</div>

                            {showEmail && <div className='content p-1' style={{ border: '1px solid', overflow: 'scroll' }} dangerouslySetInnerHTML={{ __html: JSON.parse(props.otpDetails[0].ReceivedData).data.html }} ></div>}

                            {props.checkSimSwapCompletionError !== null ? <div> <p style={{ color: 'red', textAlign: 'center' }}>{props.checkSimSwapCompletionError}</p> </div> : ''}

                            {props.checkSimSwapCompletionMessage !== null ? <div> <p style={{ color: '#2ACB13', textAlign: 'center' }}>{props.checkSimSwapCompletionMessage}</p> </div> : ''}

                            <form className="form-validate is-alter" onSubmit={handleCompeletedData} method="post">
                                <div className="form-group"><label className="form-label" htmlFor="remark">Remark</label>
                                    <div className="form-control-wrap"><textarea className="form-control"
                                        id="remark" placeholder='Enter Remark here....' name="remark" value={infoData.remark} onChange={handleChange3} ></textarea></div>
                                </div>


                                <div className="form-group text-right">
                                    <hr></hr>
                                    <button type="button" className="btn btn-lg btn-secondary mx-2" onClick={handleBack}>Back</button>
                                    <button type='submit' disabled={props.buttonLoading} className="btn btn-lg btn-primary">{props.buttonLoading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Submit</span> </button>
                                </div>
                            </form>
                        </div>
                        {/* <div className="modal-footer"><div className="btn btn-dim btn-outline-danger" onClick={handleBack}>Cancel</div> <div className="btn btn-dim btn-outline-primary" onClick={handleCompeletedData}>Ok</div></div> */}
                    </div>
                </div>
            </div>}
        </>
    )
}

const mapStateToProps = state => {
    const { simSwapRequestData, loading, buttonLoading, apiError, LockedFailedmessage, LockFailAlert, LockedByUser, apiErrorModal, messageModal, modalFlag, cancelModal, modalViewLoader, errorList, showSimSwapPage, resetSimSwapRequestPage, msisdnDetails, addLoading, apiSwapError, message, otpDetails, showOtp, getOtpLoading, checkSimSwapCompletionMessage, checkSimSwapCompletionError, checkSimSwapCompletionLoading } = state.simSwapRequest
    return { simSwapRequestData, loading, buttonLoading, apiError, LockedFailedmessage, LockFailAlert, LockedByUser, apiErrorModal, messageModal, modalFlag, cancelModal, modalViewLoader, errorList, showSimSwapPage, resetSimSwapRequestPage, msisdnDetails, addLoading, apiSwapError, message, otpDetails, showOtp, getOtpLoading, checkSimSwapCompletionMessage, checkSimSwapCompletionError, checkSimSwapCompletionLoading };
}

export default withRouter(connect(mapStateToProps, { viewSimSwapRequest, resetSimSwapRequestPage })(SimSwapRequest));