import { SEARCH_ZIPCODE_ACTIVATION, SEARCH_ZIPCODE_ACTIVATION_FAILED, SEARCH_ZIPCODE_ACTIVATION_SUCCESSFULL, UPDATE_ZIPCODE_ACTIVATION, UPDATE_ZIPCODE_ACTIVATION_SUCCESSFULL } from "./actionTypes";

const initialState = {
    apiError: null, message: null, loading: false, activationData: [], showDataRecord: false, updateLoading: false
}

const updateZipcode = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_ZIPCODE_ACTIVATION:
            state = {
                ...state,
                loading: true,
                showDataRecord: false,
                message: null,
                apiError: null
            }
            break;
        case SEARCH_ZIPCODE_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                activationData: action.payload,
                showDataRecord: true
            }
            break;

        case SEARCH_ZIPCODE_ACTIVATION_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
            }
            break;
        case UPDATE_ZIPCODE_ACTIVATION:
            state = {
                ...state,
                updateLoading: true,
                apiError: null,
                message: null
            }
            break;
        case UPDATE_ZIPCODE_ACTIVATION_SUCCESSFULL:
            state = {
                ...state,
                updateLoading: false,
                apiError: null,
                message: action.payload,
                showDataRecord: false,
                activationData: null
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default updateZipcode;