export const DEALER_LIST = 'dealer_list';
export const DEALER_LIST_SUCCESSFUL = 'dealer_list_successfull';

export const ADD_DEALER = 'ADD_DEALER';
export const ADD_DEALER_SUCCESS = 'ADD_DEALER_SUCCESS';

export const EDIT_DEALER = 'EDIT_DEALER';
export const DELETE_DEALER = 'DELETE_DEALER';
export const DELETE_DEALER_SUCCESS = 'delete_dealer_success';

export const DEALER_API_ERROR = 'dealer_api_failed';
export const API_ERROR_MODAL = 'api_error_modal';

export const RESET_ALERT = 'reset_alert';
export const COUNTRY_LIST = "country_list";
export const COUNTRY_LIST_SUCCESS = "country_list_success";
export const STATE_LIST="STATE_LIST";
export const STATE_LIST_SUCCESS="STATE_LIST_SUCCESS";
export const CHANNEL_NAME = "channel_name";
export const CHANNEL_NAME_SUCCESS = "channel_name_success";

export const DEALER_FLAG_UPDATE = 'DEALER_FLAG_UPDATE';
export const DEALER_FLAG_UPDATE_SUCCESS = 'DEALER_FLAG_UPDATE_SUCCESS';

export const NON_VERIFIED_DEALER_LIST = 'NON_VERIFIED_DEALER_LIST';
export const NON_VERIFIED_DEALER_LIST_SUCCESS = 'NON_VERIFIED_DEALER_LIST_SUCCESS';

export const VERIFY_DEALER = 'VERIFY_DEALER';
export const VERIFY_DEALER_SUCCESS = 'VERIFY_DEALER_SUCCESS';

export const CHANGE_VERIFY_USER_PASSWORD = "CHANGE_VERIFY_USER_PASSWORD";
export const CHANGE_VERIFY_USER_PASSWORD_SUCCESSFULLY = "CHANGE_VERIFY_USER_PASSWORD_SUCCESSFULLY";

export const GET_ALL_CHANNEL_LIST= "GET_ALL_CHANNEL_LIST";
export const GET_ALL_CHANNEL_LIST_SUCCESS = "GET_ALL_CHANNEL_LIST_SUCCESS"; 

export const GET_DEALER_SUSPENSION_LOG = 'GET_DEALER_SUSPENSION_LOG';
export const GET_DEALER_SUSPENSION_LOG_SUCCESS = 'GET_DEALER_SUSPENSION_LOG_SUCCESS';
export const GET_DEALER_SUSPENSION_LOG_FAILED = 'GET_DEALER_SUSPENSION_LOG_FAILED';
export const CLOSE_DEALER_SUSPENSION_LOG_MODAL = 'CLOSE_DEALER_SUSPENSION_LOG_MODAL';


export const GET_DEALER_CONFIG = 'GET_DEALER_CONFIG';
export const GET_DEALER_CONFIG_SUCCESS = 'GET_DEALER_CONFIG_SUCCESS';
export const GET_DEALER_CONFIG_FAILED = 'GET_DEALER_CONFIG_FAILED';
export const CLOSE_DEALER_CONFIG_MODAL = 'CLOSE_DEALER_CONFIG_MODAL';
export const SET_DEALER_CONFIG_DATA = 'SET_DEALER_CONFIG_DATA';

export const UPDATE_DEALER_CONFIG = 'UPDATE_DEALER_CONFIG';
export const UPDATE_DEALER_CONFIG_SUCCESS = 'UPDATE_DEALER_CONFIG_SUCCESS';
export const UPDATE_DEALER_CONFIG_FAILED = 'UPDATE_DEALER_CONFIG_FAILED';

export const GET_OTHERS_ALLOWED_DURATION = 'GET_OTHERS_ALLOWED_DURATION';
export const GET_OTHERS_ALLOWED_DURATION_SUCCESS = 'GET_OTHERS_ALLOWED_DURATION_SUCCESS';
export const GET_OTHERS_ALLOWED_DURATION_FAILED = 'GET_OTHERS_ALLOWED_DURATION_FAILED';

export const OPEN_EMAIL_CONFIG_MODAL = 'OPEN_EMAIL_CONFIG_MODAL';
export const CLOSE_EMAIL_CONFIG_MODAL = 'CLOSE_EMAIL_CONFIG_MODAL';

export const UPDATE_DEALER_EMAIL = 'UPDATE_DEALER_EMAIL';
export const UPDATE_DEALER_EMAIL_SUCCESS = 'UPDATE_DEALER_EMAIL_SUCCESS';
export const UPDATE_DEALER_EMAIL_FAILED = 'UPDATE_DEALER_EMAIL_FAILED';

