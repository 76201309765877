import React, { useEffect, useState } from 'react'
import moment from "moment";
import DatePicker from 'react-datepicker';
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Spinner from '../components/layout/Spinner';
import "react-datepicker/dist/react-datepicker.css";
import { getAllChannelList } from '../store/dealerActivationReport/actions';
import DataTable from 'react-data-table-component';
import FilterComponent from "./FilterComponent";
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { searchLateActivatedTxn, resetChannelActivationReportPage } from '../store/lateActivatedTxnReport/actions'
import { accessTransactionModal } from '../store/viewActivations/actions';
import ActivationViewModal from './ActivationViewModal';


const LateActivatedTxnReport = (props) => {
    const dispatch = useDispatch();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const [sDate, setSdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
    const [eDate, setEdate] = useState(new Date());
    const [filterText, setFilterText] = useState("");
    const [selectChannelID, setSelectChannelID] = useState("");
    const [selectChannelName, setSelectChannelName] = useState("");
    const [fromdate, setFromdate] = useState(false);
    const [toDate, setTodate] = useState(false);
    const [showFromDateFlag, setShowFromDateFlag] = useState(false);
    const [showToDateFlag, setShowToDateFlag] = useState(false);



    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [searchData, setSearchData] = useState({
        companyId: '',
        bulkFlag: '',
        channelID: '',
        fromdate: sDate,
        todate: eDate,
        dealerId: '',
    });
    const roleId = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).RoleID : null;


    useEffect(() => {
        const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
        const dealerId = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).DealerId : null;
        setSearchData(prevState => ({
            ...prevState,
            companyId: CompanyID,
            channelID: roleId == 33 || roleId == 34 ? selectChannelID : '',
            fromdate: sDate,
            todate: eDate,
            dealerId: (dealerId > 0) ? dealerId : '',
        }));
        props.getAllChannelList(dealerId);
        props.resetChannelActivationReportPage();
    }, [])

    useEffect(() => {
        if (props.channelData && props.channelData.length == 1) {
            setSelectChannelID(props.channelData[0].ID)
            setSelectChannelName(props.channelData[0].ChannelName)
            setSearchData(prevState => ({
                ...prevState,
                channelID: props.channelData[0].ID,
            }));
        }
    }, [props.channelData])

    const columns = [
        {
            name: "#",
            width: "50px",
            selector: row => row.serial,
        },
        {
            name: 'CHANNEL TXN ',
            width: "180px",
            selector: row => row.ChannelTxnID,
        },
        {
            name: 'CHANNEL NAME',
            width: "130px",
            selector: row => row.ChannelName,
        },
        {
            name: 'STATUS',
            width: "150px",
            selector: row => row.RequestStatus,
        },
        {
            name: 'SERIAL/EID',
            width: "270px",
            selector: row => row.SerialNumber,
        },
        {
            name: 'ORDER NUMBER',
            width: "170px",
            selector: row => row.OrderNumber,
        },
        {
            name: 'MOBILE NUMBER',
            width: "150px",
            selector: row => row.MobileNumber,
        },
        {
            name: 'CHANNEL DEALER NAME ',
            width: "200px",
            selector: row => row.ChannelDealerName,
        },
        {
            name: 'CHANNEL DEALER HIERARCHY',
            width: "220px",
            selector: row => row.ChannelDealerHierarchy,
        },
        {
            name: 'REQUESTED DATE',
            width: "200px",
            selector: row => row.RequestedDt,
        },
        {
            name: 'MODIFIED DATE',
            width: "200px",
            selector: row => row.ModifiedDate,
        },

        {
            name: 'TIME DIFFERENCE',
            width: "200px",
            selector: row => row.TimeDifference,
        },

        {
            name: 'ACTIVATION SOURCE',
            width: "200px",
            selector: row => row.ActivationSource,
        },
    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '50px', // override the row height
            },
        },
        headCells: {
            style: {
                paddingLeft: '5px', // override the cell padding for head cells
                paddingRight: '5px',
                fontWeight: 'bold',
                fontSize: '14px'
            },
        },
        cells: {
            style: {
                paddingLeft: '5px', // override the cell padding for data cells
                paddingRight: '5px',
            },
        },
    };

    const filteredItems = props.searchReportData && props.searchReportData.length > 0 ? props.searchReportData.filter(
        item => JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    ) : [];

    const openTransactionModal = (id) => {
        dispatch(accessTransactionModal(id));
    }

    const filterData = filteredItems && filteredItems.length > 0 ? filteredItems.map((data, key) => {
        return {
            serial: key + 1,
            ChannelTxnID: (<Link to="#" className={data.Multiline ? `badge rounded-pill bg-outline-danger px-1` : ''} style={{ 'textDecoration': 'underline' }} onClick={() => openTransactionModal(data.ID)}>{data.ChannelTxnID}</Link>),
            ChannelName: data.ChannelName,
            SerialNumber: data.SerialNumber,
            OrderNumber: data.OrderNumber,
            MobileNumber: data.MSISDN,
            ChannelDealerName: data.ChannelDealerName,
            ChannelDealerHierarchy: data.ChannelDealerHierarchy,
            RequestedDt: <p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm:ss")}</p>,
            ModifiedDate: <p>{moment(new Date(data.ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm:ss")}</p>,
            TimeDifference: `${data.TimeDifferenceInMinutes} min`,
            RequestStatus: <span className={`badge badge-sm badge-dot has-bg bg-success d-none d-sm-inline-flex`}>{data.RequestStatus}</span>,
            ActivationSource: <p className={`${data.ActivationSource === 1 ? 'text-danger' : 'text-primary'}`}>{data.ActivationSource === 1 ? 'Manual' : 'Automation'}</p>,
        }
    }) : [];


    const listData = props.searchReportData && props.searchReportData.length > 0 && props.searchReportData.map((data, key) => {
        return {
            SNo: key + 1,
            'CHANNEL TXN': data.ChannelTxnID,
            'CHANNEL NAME': data.ChannelName,
            STATUS: data.RequestStatus,
            'SERIAL/EID': data.SerialNumber,
            'ORDER NUMBER': data.OrderNumber,
            'MOBILE NUMBER': data.MSISDN,
            'CHANNEL DEALER NAME': data.ChannelDealerName,
            'CHANNEL DEALER HIERARCHY': data.ChannelDealerHierarchy,
            'REQUESTED DATE': moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm:ss"),
            'MODIFIED DATE': moment(new Date(data.ModifiedDate)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD HH:mm:ss"),
            'TIME DIFFERENCE': `${data.TimeDifferenceInMinutes} min`,
            'ACTIVATION SOURCE': data.ActivationSource === 1 ? 'Manual' : 'Automation',
        }
    });

    const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText("");
        }
    }

    const exportToCSV = async () => {
        if (listData.length > 0) {
            const ws = XLSX.utils.json_to_sheet(listData);
            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: fileType });
            await FileSaver.saveAs(data, 'LateActivatedTXN' + fileExtension);
        }
    }


    const handleSearchData = (e) => {
        e.preventDefault();
        let searchDataManage = searchData;
        searchDataManage.fromdate = moment(searchDataManage.fromdate).format('YYYY-MM-DD');
        searchDataManage.todate = moment(searchDataManage.todate).format('YYYY-MM-DD');
        if (fromdate) {
            setShowFromDateFlag(true)
            props.resetChannelActivationReportPage();
        }
        if (toDate) {
            setShowToDateFlag(true)
            props.resetChannelActivationReportPage();
        }
        if (!fromdate && !toDate) {
            dispatch(searchLateActivatedTxn(searchDataManage));
        }
    }

    const handleBulkFlagChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            bulkFlag: e.target.value
        }));

    }

    const handleChannelChange = (e) => {
        setSearchData(prevState => ({
            ...prevState,
            channelID: e.target.value
        }));
    }

    const handleStareDateChange = (d) => {
        setSdate(d);
        setSearchData(prevState => ({
            ...prevState,
            fromdate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }))
        if (!d) {
            setFromdate(true)
        }
        else {
            setFromdate(false)
            setShowFromDateFlag(false)
        }
    }
    const handleEndDateChange = (d) => {
        setEdate(d);
        setSearchData(prevState => ({
            ...prevState,
            todate: d ? moment(new Date(d)).format("YYYY-MM-DD") : ''
        }));
        if (!d) {
            setTodate(true)
        }
        else {
            setTodate(false)
            setShowToDateFlag(false)
        }
    }

    return (
        <div className="nk-block-head-content">
            <h3 className="nk-block-title page-title">Late Activation Report</h3>
            <div className='card p-2'>
                <div className="card-inner p-0" >
                    <div className="nk-block">
                        <form onSubmit={handleSearchData} method="post">
                            <div className="row">
                                <div className="col-sm-3 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="channelID">Channel</label>
                                        <div className="form-control-wrap">
                                            <select
                                                name="channelID"
                                                className="form-control"
                                                id="channelID"
                                                onChange={(e) => handleChannelChange(e)}
                                            >
                                                {roleId == 33 || roleId == 34 ? <option value={selectChannelID} key={selectChannelID} name={selectChannelID} >{selectChannelName}</option> : ''}
                                                {roleId == 1 || roleId == 2 ? <>
                                                    <option value="" key="0" name="select" >Select</option>
                                                    <option value="" key="0" name="select" >All</option>
                                                    {props.channelData && props.channelData.map((channel, ind) => (
                                                        <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                                                    ))}</> : ''}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-2 col-md-2">
                                    <div className="form-group">
                                        <label className="form-label" htmlFor="bulkFlag">Bulk Flag</label>
                                        <div className="form-control-wrap">
                                            <select name="bulkFlag" className="form-control" id="bulkFlag" defaultValue={searchData.bulkFlag} onChange={(e) => handleBulkFlagChange(e)}>
                                                <option value="" key="0" name="select" disabled>Select</option>
                                                <option value="1" name="1">Bulk</option>
                                                <option value="0" name="0">Non-Bulk</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <div className="form-group"><label className="form-label" htmlFor="stareDate">Start Date</label>
                                        <div className="form-control-wrap" style={{ 'border': showFromDateFlag ? '1px solid red' : '', borderRadius: showFromDateFlag ? '5px' : '' }}>
                                            <DatePicker onChange={(d) => handleStareDateChange(d)} className={"form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="stareDate" closeOnScroll={true} selected={sDate} placeholderText="MM/DD/YYYY" maxDate={new Date()} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 col-md-2">
                                    <div className="form-group"><label className="form-label" htmlFor='endDate'>End Date</label>
                                        <div className="form-control-wrap" style={{ 'border': showToDateFlag ? '1px solid red' : '', borderRadius: showToDateFlag ? '5px' : '' }}>
                                            <DatePicker onChange={(d) => handleEndDateChange(d)} className={"form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="endDate" closeOnScroll={true} selected={eDate} placeholderText="MM/DD/YYYY" maxDate={new Date()} />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-2 col-md-1'>
                                    <button type='submit' className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                                        className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                                        <span>Search</span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {props.showLateActivatedTxnReportPage &&
                <div className="card p-2" style={{ marginTop: '12px' }}>
                    <div className="card-inner p-0" >
                        <div className='d-flex justify-content-between'>
                            <FilterComponent
                                onFilter={e => setFilterText(e.target.value)}
                                onClear={handleClear}
                                filterText={filterText}
                            /><Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
                        </div>
                        <DataTable pagination columns={columns} data={filterData ? filterData : ''} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
                    </div>
                </div>
            }

            <ActivationViewModal />
        </div>
    )
}

const mapStateToProps = state => {
    const { channelData } = state.dealerActivationReport;
    const { loading, apiError, searchReportData, showLateActivatedTxnReportPage } = state.lateActivatedTxnReport
    return { loading, apiError, channelData, searchReportData, showLateActivatedTxnReportPage, };
}

export default withRouter(connect(mapStateToProps, { getAllChannelList, resetChannelActivationReportPage })(LateActivatedTxnReport));
