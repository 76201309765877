import { PAUSED_ACTIVATION_LIST_SUCCESSFULL, PAUSED_ACTIVATION_LIST, PAUSED_ACTIVATION_LIST_FAILED } from "./actionTypes";

const initialState = {
    apiError: null, loading: false, pausedActivation: []
}

const Paused = (state = initialState, action) => {
    switch (action.type) {
        case PAUSED_ACTIVATION_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
            }
            break;

        case PAUSED_ACTIVATION_LIST_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                apiError: null,
                pausedActivation: action.payload.pausedActivation
            }
            break;

        case PAUSED_ACTIVATION_LIST_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                message: null,
            }
            break;

        default:
            state = { ...state };
    }
    return state;
}

export default Paused;