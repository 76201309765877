import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch,useSelector } from "react-redux";
import { uploadJarFile,getJarFile } from "../store/jarUpload/actions";
import Spinner from "../components/layout/Spinner";
import { getCompany } from "../store/header/actions";
import { PERMISSIONS } from "../Permission/Permission";

function UploadJar(props) {
  const timezone = process.env.REACT_APP_TIMEZONE;
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [RoleID, setRoleID] = useState(null);
  const [CompanyId, setCompanyId] = useState("");
  const [loginID, setLoginID] = useState("");
  const [dealerID, setDealerID] = useState("");
  const [jarFile, setJarFile] = useState(null);
  const [jarFileError, setJarFileError] = useState("");
  const [jarVersion, setJarVersion] = useState("");
  const [remark, setRemark] = useState("");
  const [jarVersionError, setJarVersionError] = useState("");
  const [remarkError, setRemarkError] = useState("");

  useEffect(() => {
    const loginId = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).ID
      : null;
    const dealerId = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).DealerId
      : null;
    const companyId = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).CompanyID
      : null;
    const roleId = localStorage.getItem("authUser")
      ? JSON.parse(localStorage.getItem("authUser")).RoleID
      : null;
    setRoleID(roleId);
    setCompanyId(companyId);
    setLoginID(loginId);
    setDealerID(dealerId);
    props.getCompany(companyId);
    dispatch(getJarFile());
  }, []);

  // permission data manage
  var filterArray = [];
  for (const [key, value] of Object.entries(PERMISSIONS)) {
    if (key == RoleID) {
      filterArray.push(value);
    }
  }

  if (props.apiError === "Invalid token") {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = "/";
  }

  // page logic starts from here
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setJarFile(file);
  };

  const handleJarUpload = () => {
    let fileNameArr;
    let fileExt;
    if(jarFile){
      fileNameArr = jarFile.name.split('.');
      fileExt = fileNameArr[fileNameArr.length - 1];
    }

    if (!jarFile) {
      setJarFileError("Please select a file.");
      return
    } else if (!["jar"].includes(fileExt.toLowerCase())) {
      setJarFileError("Invalid file type. Only JAR file allowed.");
      return
    } else {
      setJarFileError("");
    }

    if(jarVersion == ""){
      setJarVersionError('Version Required');
      return;
    }else{
      setJarVersionError('');
    }
    
    if(remark == ""){
      setRemarkError('Remark Required');
      return
    }else{
      setRemarkError('');
    }

    if(jarFile && remarkError == '' && jarVersionError == ''){
      dispatch(uploadJarFile({file : jarFile,jarVersion,remark,loginID}));
      inputRef.current.value = null;
      setJarVersion('');
      setRemark('');
      setJarFile(null);
    }

  };

  return (
    <>
    <div className="nk-block-head-content">
      {props.message ? (
        <div className="alert alert-success alert-icon">
          <em className="icon ni ni-check-circle"></em>{" "}
          <strong>Jar Uploaded Success</strong>
        </div>
      ) : (
        ""
      )}

      {props.jarUploadData.length > 0 ? (
        <div
          className="card p-2"
          style={{ marginTop: "12px", margin: "12px auto", width: "50%" }}
        >
          <div className="card-inner d-flex justify-content-between">
            <h3>
              Existing JAR{" "}
              <span style={styles.version}>
                {props.companyData && props.companyData[0]
                  ? props.companyData[0].JARVersion
                  : ""}
              </span>
            </h3>
            <a href={props.jarUploadData[0].url} className="btn btn-primary">
              Download
            </a>
          </div>
        </div>
      ) : !props.getJarLoading ? (
        <div className="card p-2" style={{ marginTop: "12px", margin: "12px auto", width: "50%" }}>
          <div className="card-inner d-flex justify-content-between">
            <h3>Existing JAR</h3>
            <p className="text-danger pt-1">No JAR Found</p>
          </div>
        </div>
      ) : (
        ""
      )}
      {props.getJarLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        ""
      )}

      {filterArray &&
      filterArray.length > 0 &&
      filterArray[0].includes("jar_upload") ? (
        <>
        <div
          className="card p-2"
          style={{ marginTop: "12px", margin: "12px auto", width: "50%" }}
        >
          <div className="card-inner d-flex justify-content-between">
            <h3>Upload New JAR</h3>
          </div>

          <div className="card-inner d-flex justify-content-center flex-column">
            <div className="container">
                <input className="form-control" value={jarVersion} onChange={(e) => setJarVersion(e.target.value)} placeholder="Enter Version Here"  />
                <p style={styles.commonError}>{jarVersionError}</p>
                <textarea class="form-control mt-3" onChange={(e) => setRemark(e.target.value)} style={styles.textarea} placeholder="Enter Remark Here" rows="3" value={remark}></textarea>
                <p style={styles.commonError}>{remarkError}</p>
            </div>

            <div className="container text-center mt-3 d-flex justify-content-between">
              <div className="container d-flex" style={{padding : 'unset'}}>
                <input
                  type="file"
                  className="form-control-file"
                  onChange={handleFileChange}
                  ref={inputRef}
                />

                {jarFileError !== "" ? <p style={error}>{jarFileError}</p> : ""}
              </div>

              <button
                className="btn btn-primary"
                style={{ position: "absolute", right: "48px" }}
                disabled={props.loading}
                onClick={handleJarUpload}
              >
                {props.loading ? (
                  <span
                    className="spinner-border spinner-border-sm"
                    aria-hidden="true"
                  ></span>
                ) : (
                  ""
                )}
                <span>Upload</span>
              </button>
            </div>
          </div>
        </div>
        </>  

      ) : (
        ""
      )}
    </div>

    </> 

  );
}

// Style here
const styles = {
  version: {
    fontSize: '16px',
    fontWeight: '400',
    letterSpacing: '1px',
  },
  textarea: {
    resize: 'none'
  },
  commonError : {
    color: "#f00",
  }
}

const error = {
  position: "absolute",
  bottom: "10px",
  float: "left",
  color: "#f00",
};


const mapStateToProps = state => {
  const { loading, message, jarUploadData, getJarLoading } = state.jarUpload
  const { companyData } = state.header;
  return { loading, message, jarUploadData, getJarLoading, companyData};
}

export default withRouter(connect(mapStateToProps, { uploadJarFile,getCompany })(UploadJar));