import { all } from 'redux-saga/effects';

import loginSaga from './login/saga';
import forgetPasswordSaga from './forgetPassword/saga';
import userSaga from './user/saga';
import dealerSaga from './dealer/saga';
import viewActivationSaga from './viewActivations/saga';
import headerSaga from './header/saga';
import dashboardSaga from './dashboard/saga';
import planSaga from './plan/saga';
import pausedActivationSaga from './pausedActivations/saga';
import addonSaga from './addon/saga';
import userActivationReportSaga from './userActivationReport/saga';
import dealerActivationReportSaga from './dealerActivationReport/saga';
import jarSaga from './jar/saga';
import channelConfigSaga from './channelConfig/saga';
import notificationSaga from './notification/saga';
import channelActivationReportSaga from './channelActivationReport/saga';
import lockedActivationDataSaga from './lockActivationData/saga';
import jarConfigSaga from './jarConfiguration/saga';
import activationReportSaga from './activationReport/saga';
import serverSaga from './server/saga';
import repushActivationSaga from './repushActivation/saga';
import pendingActivationSaga from './pendingActivation/saga';
import manualActivationReportSaga from './manualActivationReport/saga';
import planWiseReportSaga from './planWiseActivationReport/saga';
import assetPerformanceReportSaga from './assetPerformanceReport/saga';
import assetSaga from './asset/saga';
// import multiLineModalSaga from './multiLineModal/saga';
import uploadJarSaga from './jarUpload/saga';
import dealerPos from './dealerPos/saga';
import ec2Instances from './awsInstances/saga';
import assignDealerSaga from './assignDealer/saga';
import validateEpayReportSaga from './validateEpayReport/saga';
import simSwapRequestSaga from './simSwapRequest/saga';
import dealerEmailConfigSaga from './dealerEmailConfig/saga'
import emailInboxSaga from './emailInbox/saga';
import lateActivatedTxnReportSaga from './lateActivatedTxnReport/saga'
import updateZipcodeSaga from './updateZipcode/saga';
import bulkCancelationSaga from './bulkCancelation/saga';

export default function* rootSaga() {
    yield all([
        loginSaga(),
        forgetPasswordSaga(),
        userSaga(),
        dealerSaga(),
        viewActivationSaga(),
        headerSaga(),
        dashboardSaga(),
        planSaga(),
        pausedActivationSaga(),
        addonSaga(),
        userActivationReportSaga(),
        dealerActivationReportSaga(),
        jarSaga(),
        channelConfigSaga(),
        notificationSaga(),
        channelActivationReportSaga(),
        lockedActivationDataSaga(),
        jarConfigSaga(),
        activationReportSaga(),
        serverSaga(),
        repushActivationSaga(),
        pendingActivationSaga(),
        manualActivationReportSaga(),
        assetSaga(),
        planWiseReportSaga(),
        assetPerformanceReportSaga(),
        uploadJarSaga(),
        // multiLineModalSaga(),
        dealerPos(),
        ec2Instances(),
        assignDealerSaga(),
        validateEpayReportSaga(),
        simSwapRequestSaga(),
        dealerEmailConfigSaga(),
        emailInboxSaga(),
        lateActivatedTxnReportSaga(),
        updateZipcodeSaga(),
        bulkCancelationSaga(),
    ])
}