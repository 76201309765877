import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { VALIDATE_REQUEST_FOR_BULK_CANCELLATION,UPDATE_REMARK_FOR_BULK_CANCELLATION,GET_ERROR_CODE_LIST} from './actionTypes';
import { addData,getList } from '../../helpers/fackBackend_Helper';
import { validateRequestForBulkCancelationSuccess, validateRequestForBulkCancelationFailed,
    updateRemarkForBulkCancelationSuccess,updateRemarkForBulkCancelationFailed,
    getErrorCodeListSuccessfull,getErrorCodeListFailed} from './actions';

function* validateRequestForBulkCancelationResponse({ payload: data }) {
    try {
        const response = yield call(addData, '/validateBulkCancelation', data);
        if (response.Status) {
            yield put(validateRequestForBulkCancelationSuccess(response.data));
        }
        else {
            yield put(validateRequestForBulkCancelationFailed(response.Message));
        }
    } catch (error) {
        yield put(validateRequestForBulkCancelationFailed(error));
    }
}
export function* watchValidateRequestForBulkCancelationResponse() {
    yield takeEvery(VALIDATE_REQUEST_FOR_BULK_CANCELLATION, validateRequestForBulkCancelationResponse)
}

function* updateRemarkForBulkCancelationResponse({ payload: data }) {
    try {
        const response = yield call(addData, '/updateRemarkForBulkCancelation', data);
        if (response.Status) {
            yield put(updateRemarkForBulkCancelationSuccess(response.Message));
        }
        else {
            yield put(updateRemarkForBulkCancelationFailed(response.Message));
        }
    } catch (error) {
        yield put(updateRemarkForBulkCancelationFailed(error));
    }
}
export function* watchUpdateRemarkForBulkCancelationResponse() {
    yield takeEvery(UPDATE_REMARK_FOR_BULK_CANCELLATION, updateRemarkForBulkCancelationResponse)
}

function* getErrorCodeResponse() {
    try {
        const response = yield call(getList, '/showOnPortalErrorCodes')
        if (response.Status) {
            yield put(getErrorCodeListSuccessfull(response.showOnPortalErrorCodes))
        } else {
            yield put(getErrorCodeListFailed(response))
        }
    } catch (error) {
        yield put(getErrorCodeListFailed(error))
    }
}

export function* watchErrorCodeList() {
    yield takeEvery(GET_ERROR_CODE_LIST, getErrorCodeResponse);
}


function* bulkCancelationSaga() {
    yield all([ fork(watchValidateRequestForBulkCancelationResponse), ]);
    yield all([ fork(watchUpdateRemarkForBulkCancelationResponse), ]);
    yield all([ fork(watchErrorCodeList), ]);
}

export default bulkCancelationSaga;