import { RESET_MANUAL_ACTIVATION_REPORT, SEARCH_MANUAL_ACTIVATION, SEARCH_MANUAL_ACTIVATION_FAILED, SEARCH_MANUAL_ACTIVATION_SUCCESS } from "./actionTypes"

export const searchManualActivationData = (searchData) => {
    return {
        type: SEARCH_MANUAL_ACTIVATION,
        payload: searchData
    }
}

export const searchManualActivationDataSuccess = (manualActivationData) => {
    return {
        type: SEARCH_MANUAL_ACTIVATION_SUCCESS,
        payload: manualActivationData
    }
}

export const searchManualActivationDataFailed = (error) => {
    return {
        type: SEARCH_MANUAL_ACTIVATION_FAILED,
        payload: error
    }
}

export const resetManualActivation = () => {
    return {
        type: RESET_MANUAL_ACTIVATION_REPORT,
    }
}