import { RESET_LATE_ACTIVATED_TXN_REPORT_PAGE, SEARCH_LATE_ACTIVATED_TXN, SEARCH_LATE_ACTIVATED_TXN_SUCCESSFULL, SEARCH_LATE_ACTIVATED_TXN_FAILED } from "./actionTypes"

const initialState = {
    apiError: null, Message: null, loading: false, modalMessage: null, searchReportData: [], showLateActivatedTxnReportPage: false
}

const lateActivatedTxnReport = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_LATE_ACTIVATED_TXN:
            state = {
                ...state,
                loading: true,
                showLateActivatedTxnReportPage: true
            }
            break;
        case SEARCH_LATE_ACTIVATED_TXN_SUCCESSFULL:
            state = {
                ...state,
                loading: false,
                searchReportData: action.payload,
                showLateActivatedTxnReportPage: true
            }
            break;

        case SEARCH_LATE_ACTIVATED_TXN_FAILED:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                showLateActivatedTxnReportPage: true
            }
            break;
        case RESET_LATE_ACTIVATED_TXN_REPORT_PAGE:
            state = {
                ...state,
                loading: false,
                apiError: null,
                showLateActivatedTxnReportPage: false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default lateActivatedTxnReport;