import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import moment from "moment";
import FilterComponent from "./FilterComponent";
import Spinner from '../components/layout/Spinner';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { getAllChannelList, processedActivation, searchDealerActivation, resetDealerActivationReportPage } from '../store/dealerActivationReport/actions';
import { getRepushActivation, addRepushActivation, getActivationChildList } from '../store/repushActivation/actions';
import MultilineModalComponent from '../components/MultilineModalComponent';

function DealerActivationReport(props) {
  const dispatch = useDispatch();
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const [sDate, setSdate] = useState(new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000));
  const [eDate, setEdate] = useState(new Date());
  const [CompanyId, setcompanyId] = useState('');
  const [filterText, setFilterText] = useState("");
  const [modalFromDate, setmodalFromDate] = useState('');
  const [modalToDate, setmodalToDate] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [showModal, setshowModal] = useState(false);

  const [searchData, setSearchData] = useState({
    dealerID: '',
    fromdate: sDate,
    todate: eDate,
  });

  useEffect(() => {
    const CompanyID = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).CompanyID : null;
    // const loginId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).ID : null;
    const dealerId = (localStorage.getItem('authUser')) ? JSON.parse(localStorage.getItem('authUser')).DealerId : null;
    setcompanyId(CompanyID);

    setSearchData(prevState => ({
      ...prevState,
      companyId: CompanyID
    }))

    // const searchreport = {
    //   companyId: CompanyID,
    //   dealerID: '',
    //   fromdate: sDate,
    //   todate: eDate,
    // }

    props.getAllChannelList(dealerId);
    // props.searchDealerActivation(searchreport)
    props.resetDealerActivationReportPage()

  }, [])

  const columns = [
    {
      name: '#',
      width: "40px",
      selector: row => row.serial,
    },
    {
      name: 'DEALER NAME',
      selector: row => row.dealerName,
    },
    {
      name: 'PROCESSED',
      selector: row => row.count,
    },
    {
      name: 'CHANNEL NAME',
      selector: row => row.channelName,
    },
    {
      name: 'ACTION',
      selector: row => row.action,
    },
  ];

  const modalColumns = [
    {
      name: '#',
      width: "40px",
      selector: row => row.serial,
    },
    {
      name: 'CHANNEL TXN',
      width: "120px",
      selector: row => row.channelTxnID,
    },
    {
      name: 'CHANNEL NAME',
      width: "120px",
      selector: row => row.channelName,
    },
    {
      name: 'SERIAL/EID',
      width: "250px",
      selector: row => row.serialNumber,
    },
    {
      name: 'DATE',
      width: "120px",
      selector: row => row.requestedDtTm,
    },
    {
      name: 'IMEI',
      width: "180px",
      selector: row => row.imei,
    },
    {
      name: 'SIM',
      selector: row => row.simType,
    },
    {
      name: 'STATUS',
      width: '120px',
      selector: row => row.requestStatus,
    },
    {
      name: 'TYPE',
      width: "120px",
      selector: row => row.requestType,
    },
    // {
    //   name: 'NETWORK NAME',
    //   width: "140px",
    //   selector: row => row.networkName,
    // },
  ];

  const filteredItems = props.searchReportData && props.searchReportData.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const filterData = filteredItems && filteredItems.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      dealerName: (<p>{data.DealerName}</p>),
      count: (<p>{data.Processed}</p>),
      channelName: (<p>{data.ChannelName}</p>),
      action: (<Link to="#" className="btn btn-info" onClick={() => handleModalOpen(data.DealerID)}>View</Link>)
    }
  })

  const listData = props.searchExcelData && props.searchExcelData.map((data, key) => {
    return {
      SNo: key + 1,
      CHANNELTXN: data.ChannelTxnID,
      CHANNELNAME: data.ChannelName,
      DEALERNAME: data.DealerName,
      SERIAL: data.SerialNumber,
      DATE: (moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")),
      IMEI: data.IMEI,
      SIM: data.SimType,
      STATUS: (data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : 'Pending',
      TYPE: data.RequestType,
    }
  });



  const openTransactionModal = (id) => {
      dispatch(getActivationChildList(id))

  }


  const modalData = props.processedData && props.processedData.map((data, key) => {
    return {
      serial: (<p>{key + 1}</p>),
      channelTxnID: (
        <>
            <p className={data.Multiline === 1 ? `badge rounded-pill bg-outline-danger px-1 d-inline` : 'd-inline'} >{data.ChannelTxnID}</p>
            {data.Multiline === 1 ? <span onClick={() => openTransactionModal(data.ID)} style={{ 'position': 'absolute' }}><em className="icon ni ni-external" style={{ 'cursor': 'pointer', 'fontSize': '16px', 'marginLeft': '10px' }}></em></span> : ''}
        </>
      ),
      serialNumber: (<p 
        className={data.SerialNumber.toString().length > 19 ? 'serial-number-size-reduce' : ''} style={{ "whiteSpace": "break-spaces" }}>{data.SerialNumber}</p>),
      requestedDtTm: (<p>{moment(new Date(data.RequestedDtTm)).tz(process.env.REACT_APP_TIMEZONE).format("MM/DD/YYYY")}</p>),
      imei: (<p style={{ "whiteSpace": "break-spaces" }}>{data.IMEI}</p>),
      simType: (<p>{data.SimType}</p>),
      requestStatus: (<span className={`badge badge-sm badge-dot has-bg ${(data.RequestStatusID === 13) ? 'bg-danger' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 22) || data.RequestStatusID === 27) ? 'bg-warning' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'bg-success' : 'bg-secondary'} d-none d-sm-inline-flex`}>{(data.RequestStatusID === 13) ? 'Cancelled' : (data.RequestStatusID === 11 || (data.RequestStatusID >= 18 && data.RequestStatusID <= 21)) ? 'Processing' : (data.RequestStatusID === 12 || data.RequestStatusID === 23 || data.RequestStatusID === 24) ? 'Processed' : (data.RequestStatusID === 22 || data.RequestStatusID === 27) ? 'Assigned to Care' : 'Pending'} </span>),
      requestType: (<p style={{ 'fontWeight': 'bold' }} className={`${data.RequestType === 'SimSwap' ? 'text-warning' : data.RequestType === 'PortIn' ? 'text-primary' : 'text-success'}`} >{data.RequestType[0]}</p>),
      // networkName: (<p>{data.NetworkName}</p>),
      channelName: (<p>{data.ChannelName}</p>),
    }
  })

  const customStyles = {
    rows: {
      style: {
        minHeight: '50px', // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: '5px', // override the cell padding for head cells
        paddingRight: '5px',
        fontWeight: 'bold',
        fontSize: '14px'
      },
    },
    cells: {
      style: {
        paddingLeft: '5px', // override the cell padding for data cells
        paddingRight: '5px',
      },
    },
  };

  // const subHeaderComponent = useMemo(() => {
  //   const handleClear = () => {
  //     if (filterText) {
  //       setResetPaginationToggle(!resetPaginationToggle);
  //       setFilterText("");
  //     }
  //   };
  //   return (
  //     <>
  //       <FilterComponent
  //         onFilter={e => setFilterText(e.target.value)}
  //         onClear={handleClear}
  //         filterText={filterText}
  //       />
  //     </>
  //   );
  // }, [filterText, resetPaginationToggle]);

  const handleDealerChange = (e) => {
    setSearchData(prevState => ({
      ...prevState,
      dealerID: e.target.value
    }));
  }

  const handleStareDateChange = (d) => {
    setSdate(d);
    setSearchData(prevState => ({
      ...prevState,
      fromdate: d ? moment(new Date(d)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD") : ''
    }))
  }

  const handleEndDateChange = (d) => {
    setEdate(d);
    setSearchData(prevState => ({
      ...prevState,
      todate: d ? moment(new Date(d)).tz(process.env.REACT_APP_TIMEZONE).format("YYYY-MM-DD") : ''
    }));
  }

  const handleSearchData = (e) => {
    e.preventDefault();
    setFilterText("");
    setmodalFromDate(searchData.fromdate);
    setmodalToDate(searchData.todate);

    let searchDataManage = searchData;
    searchDataManage.fromdate = moment(searchDataManage.fromdate).format('YYYY-MM-DD');
    searchDataManage.todate = moment(searchDataManage.todate).format('YYYY-MM-DD');

    dispatch(searchDealerActivation(searchDataManage));
  }

  const handleModalOpen = (dealerId) => {
    setshowModal(true)
    const processedData = {
      companyId: CompanyId,
      dealerID: dealerId,
      fromdate: modalFromDate,
      todate: modalToDate
    }
    dispatch(processedActivation(processedData));
  }

  const handleClear = () => {
    if (filterText) {
      setResetPaginationToggle(!resetPaginationToggle);
      setFilterText("");
    }
  }

  const exportToCSV = async () => {
    if (listData.length > 0) {
      const ws = XLSX.utils.json_to_sheet(listData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      await FileSaver.saveAs(data, 'DealerActivationDetails' + fileExtension);
    }
  }

  if (props.apiError === 'Invalid token') {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    window.location.href = '/';
  }

  return (
    <>
      <div className="nk-block-head-content">
        <h3 className="nk-block-title page-title">Dealer Activation Report</h3>
        <div className='card p-2'>
          <div className="card-inner p-0" >
            <div className="nk-block">
              <form onSubmit={handleSearchData} method="post">
                <div className="row g-3">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label className="form-label" htmlFor="dealerID">Channel</label>
                      <div className="form-control-wrap">
                        <select
                          name="dealerID"
                          className="form-control"
                          id="dealerID"
                          onChange={(e) => handleDealerChange(e)}
                        >
                          <option value="" key="0" name="select">Select</option>
                          {props.channelData && props.channelData.map(channel => (
                            <option key={channel.ID} value={channel.ID}>{channel.ChannelName}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group"><label className="form-label" htmlFor="stareDate">Start Date</label>
                      <div className="form-control-wrap">
                        <DatePicker onChange={(d) => handleStareDateChange(d)} className={"form-control date-picker"} autoComplete="off" dateFormat="MM/dd/yyyy" name="stareDate" closeOnScroll={true} selected={sDate} placeholderText="MM/DD/YYYY" maxDate={new Date()} />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group"><label className="form-label" htmlFor='endDate'>End Date</label>
                      <div className="form-control-wrap">
                        <DatePicker onChange={(d) => handleEndDateChange(d)} className={"form-control date-picker"} autoComplete='off' dateFormat="MM/dd/yyyy" name="endDate" closeOnScroll={true} selected={eDate} placeholderText="MM/DD/YYYY" maxDate={new Date()} />
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-3'>
                    <button type='submit' disabled={props.loading} className="btn btn-primary" style={{ 'marginTop': '1.9rem' }}>{props.loading === true && <span
                      className="spinner-border spinner-border-sm" aria-hidden="true"></span>}
                      <span>Search</span> </button>
                    {/* <button className='btn btn-primary' type="submit" style={{ 'marginTop': '1.9rem' }}>Search</button> */}
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
        {props.showReportPage === true && <div className="card p-2" style={{ marginTop: '12px' }}>
          <div className="card-inner p-0" >
            <div className='d-flex justify-content-between'>
              <FilterComponent
                onFilter={e => setFilterText(e.target.value)}
                onClear={handleClear}
                filterText={filterText}
              /><Link to="#" className="btn btn-success " onClick={() => exportToCSV()}><em className="icon ni ni-download"></em> Export</Link>
            </div>
            <DataTable pagination columns={columns} data={filterData} customStyles={customStyles} progressPending={props.loading} progressComponent={<Spinner />} responsive striped />
          </div>
        </div>}
      </div>

      {showModal && <div className="modal fade show" tabIndex="-1" id="modalTop" style={{ "display": "block" }} aria-modal="true" role="dialog">
        <div className="modal-dialog modal-dialog-top modal-xl" role="document">
          <div className="modal-content" >
            <div className="modal-header">
              <h5 className="modal-title">PROCESSED INFORMATION</h5>
              <div className="nk-demo-close toggle btn btn-icon btn-trigger revarse mr-n2 active" data-target="demoML" onClick={() => setshowModal(false)}><em className="icon ni ni-cross"></em></div>
            </div>
            <div className="modal-body">
              <DataTable pagination columns={modalColumns} data={modalData} customStyles={customStyles} progressPending={props.modalLoading} progressComponent={<Spinner />} responsive striped />
            </div>
          </div>
        </div>
      </div>}

      {props.childDataModalShow && <MultilineModalComponent activationChildData={props.activationChildData} />}


    </>
  )
}

const mapStateToProps = state => {
  const { loading, apiError, channelData, searchReportData, processedData, searchExcelData, modalLoading, showReportPage } = state.dealerActivationReport;
  const { childDataModalShow, activationChildData } = state.repushActivation
  return { loading, apiError, channelData, searchReportData, processedData, searchExcelData, modalLoading, showReportPage,childDataModalShow, activationChildData };
}

// export default UserActivationReport
export default withRouter(connect(mapStateToProps, { getAllChannelList, searchDealerActivation, resetDealerActivationReportPage })(DealerActivationReport));