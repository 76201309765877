import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData, getList } from '../../helpers/fackBackend_Helper';
import { searchAssetPerformanceActivationDataFailed, searchAssetPerformanceActivationDataSuccess } from './actions';
import { SEARCH_ASSET_PERFORMANCE_ACTIVATION } from './actionTypes'

function* getSearchAssetPerformanceReportData({ payload: searchData }) {
    try {
        const response = yield call(addData, '/getAssetPerformanceReport', searchData);
        if (response.Status) {
            yield put(searchAssetPerformanceActivationDataSuccess(response.activationdata));
        }
        else {
            yield put(searchAssetPerformanceActivationDataFailed(response.message));
        }
    } catch (error) {
        yield put(searchAssetPerformanceActivationDataFailed(error));
    }
}

export function* watchGetSearchAssetPerformanceReport() {
    yield takeEvery(SEARCH_ASSET_PERFORMANCE_ACTIVATION, getSearchAssetPerformanceReportData)
}

function* assetPerformanceReportSaga() {
    yield all([
        fork(watchGetSearchAssetPerformanceReport)
    ]);
}
export default assetPerformanceReportSaga;

