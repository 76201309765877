import { combineReducers } from "redux";

import login from "./login/reducer";
import forgetPassword from "./forgetPassword/reducer";
import user from "./user/reducer";
import dealer from "./dealer/reducer";
import View from "./viewActivations/reducer";
import header from "./header/reducer";
import dashboard from './dashboard/reducer';
import plan from "./plan/reducer";
import Paused from "./pausedActivations/reducer";
import addon from "./addon/reducer";
import userActivationReport from "./userActivationReport/reducer";
import dealerActivationReport from "./dealerActivationReport/reducer";
import jar from "./jar/reducer";
import channelConfig from "./channelConfig/reducer";
import notification from "./notification/reducer";
import channelActivationReport from "./channelActivationReport/reducer";
import lockedActivationData from "./lockActivationData/reducer";
import jarConfiguration from "./jarConfiguration/reducer";
import activationReport from "./activationReport/reducer";
import server from "./server/reducer";
import repushActivation from "./repushActivation/reducer";
import pendingActivation from "./pendingActivation/reducer";
import manualActivationReport from './manualActivationReport/reducer';
import asset from './asset/reducer';
import planWiseActivationReport from './planWiseActivationReport/reducer';
import assetPerformanceReport from "./assetPerformanceReport/reducer";
// import multiLineModal from "./multiLineModal/reducer";
import jarUpload from "./jarUpload/reducer";
import dealerPos from './dealerPos/reducer';
import ec2Instance from "./awsInstances/reducer";
import assignDealer from "./assignDealer/reducer";
import validateEpayReport from "./validateEpayReport/reducer";
import simSwapRequest from "./simSwapRequest/reducer";
import dealerEmailConfig from "./dealerEmailConfig/reducer";
import emailInbox from './emailInbox/reducer'
import lateActivatedTxnReport from "./lateActivatedTxnReport/reducer";
import updateZipcode from "./updateZipcode/reducer";
import bulkCancelation from "./bulkCancelation/reducer";

const rootReducer = combineReducers({
    login,
    forgetPassword,
    user,
    dealer,
    View,
    header,
    dashboard,
    plan,
    Paused,
    addon,
    userActivationReport,
    dealerActivationReport,
    jar,
    channelConfig,
    notification,
    channelActivationReport,
    lockedActivationData,
    jarConfiguration,
    activationReport,
    server,
    repushActivation,
    pendingActivation,
    manualActivationReport,
    asset,
    planWiseActivationReport,
    assetPerformanceReport,
    jarUpload,
    // multiLineModal,
    dealerPos,
    ec2Instance,
    assignDealer,
    validateEpayReport,
    simSwapRequest,
    dealerEmailConfig,
    emailInbox,
    lateActivatedTxnReport,
    updateZipcode,
    bulkCancelation
});

export default rootReducer;