import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { addData, getList } from '../../helpers/fackBackend_Helper';
import { getRepushActivationSuccess, getRepushActivationFailed, addRepushActivationSuccess, activationRequestInfoSuccessfull, viewActivationListFailed, getActivationChildListSuccessfull, getActivationChildListFail } from './actions';
import { REPUSH_ACTIVATION_LIST, REPUSH_ACTIVATION, ACTIVATION_REQUEST_INFO, GET_ACTIVATION_CHILD_LIST } from './actionTypes';

function* getRepushActivationData({ payload: { companyId, loginId, searchData } }) {
    try {
        const response = yield call(addData, '/repushActivationRequestList/' + companyId + '/' + loginId, searchData);
        if (response.Status) {
            yield put(getRepushActivationSuccess(response.repushData));
        } else {
            yield put(getRepushActivationFailed(response));
        }
    } catch (error) {
        yield put(getRepushActivationFailed(error));
    }
}

export function* watchGetRepushActivation() {
    yield takeEvery(REPUSH_ACTIVATION_LIST, getRepushActivationData)
}

function* addRepushActivationData({ payload: { companyId, loginId, searchData, repushRequests } }) {
    try {
        const response = yield call(addData, '/repushActivation/' + loginId, repushRequests);

        if (response.Status) {
            const responseList = yield call(addData, '/repushActivationRequestList/' + companyId + '/' + loginId, searchData);

            if (responseList.Status) {
                yield put(addRepushActivationSuccess(responseList.repushData, response.Message))
            } else {
                yield put(getRepushActivationFailed(response.Message));
            }
        } else {
            yield put(getRepushActivationFailed(response));
        }
    } catch (error) {
        yield put(getRepushActivationFailed(error));
    }
}

export function* watchAddRepushActivation() {
    yield takeEvery(REPUSH_ACTIVATION, addRepushActivationData)
}

function* getActivationRequestInfo({ payload: { id } }) {
    try {
        const response = yield call(getList, '/getactivationrequesthistory/' + id);
        if (response.Status) {
            yield put(activationRequestInfoSuccessfull(response.activationHistory));
        } else {
            yield put(viewActivationListFailed(response));
        }
    } catch (error) {
        yield put(viewActivationListFailed(error));
    }
}

export function* watchActivvationRequestInfo() {
    yield takeEvery(ACTIVATION_REQUEST_INFO, getActivationRequestInfo);
}


function* getActivationChildList({ payload: activationId }) {
    try {
        const response = yield call(getList, '/getActivationRequestChildsByActivationId/' + activationId);
        if (response.Status) {
            yield put(getActivationChildListSuccessfull(response.activationRequestChild));
        }
        else {
            yield put(getActivationChildListFail(response.Message));
        }
    } catch (error) {
        yield put(getActivationChildListFail(error));
    }
}

export function* watchGetActivationChild() {
    yield takeEvery(GET_ACTIVATION_CHILD_LIST, getActivationChildList)
}

function* repushActivationSaga() {
    yield all([
        fork(watchGetRepushActivation),
        fork(watchAddRepushActivation),
        fork(watchActivvationRequestInfo),
        fork(watchGetActivationChild),
    ]);
}
export default repushActivationSaga;

