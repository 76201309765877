import {
    VALIDATE_REQUEST_FOR_BULK_CANCELLATION,
    VALIDATE_REQUEST_FOR_BULK_CANCELLATION_SUCCESS,
    VALIDATE_REQUEST_FOR_BULK_CANCELLATION_FAILED,
    FORM_RESET,
    UPDATE_REMARK_FOR_BULK_CANCELLATION,
    UPDATE_REMARK_FOR_BULK__CANCELLATION_SUCCESS,
    UPDATE_REMARK_FOR_BULK_CANCELLATION_FAILED,
    RESET,
    GET_ERROR_CODE_LIST,
    GET_ERROR_CODE_LIST_SUCCESS,
    GET_ERROR_CODE_LIST_FAILED,
    } from "./actionTypes"


export const validateRequestForBulkCancelation = (data) => {
    return {
        type: VALIDATE_REQUEST_FOR_BULK_CANCELLATION,
        payload: { data }
    }
}

export const validateRequestForBulkCancelationSuccess = (result) => {
    return {
        type: VALIDATE_REQUEST_FOR_BULK_CANCELLATION_SUCCESS,
        result,
    }
}

export const validateRequestForBulkCancelationFailed = (error) => {
    return {
        type: VALIDATE_REQUEST_FOR_BULK_CANCELLATION_FAILED,
        error
    }
}

export const updateRemarkForBulkCancelation = (data) => {
    return {
        type: UPDATE_REMARK_FOR_BULK_CANCELLATION,
        payload: { data }
    }
}

export const updateRemarkForBulkCancelationSuccess = (message) => {
    return {
        type: UPDATE_REMARK_FOR_BULK__CANCELLATION_SUCCESS,
        message,
    }
}

export const updateRemarkForBulkCancelationFailed = (error) => {
    return {
        type: UPDATE_REMARK_FOR_BULK_CANCELLATION_FAILED,
        error
    }
}

export const formReset = () => {
    return {
        type: FORM_RESET,
    }
}

export const reset = () => {
    return {
        type: RESET,
    }
}

export const getErrorCodeList = () => {
    return {
        type: GET_ERROR_CODE_LIST,
    }
}
export const getErrorCodeListSuccessfull = (errorCodeList) => {
    return {
        type: GET_ERROR_CODE_LIST_SUCCESS,
        payload: errorCodeList
    }
}
export const getErrorCodeListFailed = (error) => {
    return {
        type: GET_ERROR_CODE_LIST_FAILED,
        error
    }
}



