import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_INSTANCES, INSTANCES_STOP, INSTANCES_START, INSTANCES_PASSWORD_UPDATE } from './actionTypes';
import { getInstancesFailed, getInstancesSuccessful, instancePasswordUpdateSuccessful, instancePasswordUpdateFailed, instanceStopFailed, instanceStopSuccessful, instanceStartSuccessful, instanceStartFailed, getInstances as getInstancesAction } from './actions';
import { getList, addData } from '../../helpers/fackBackend_Helper';

function* passwordChangeInstance({ payload: {instanceId,data} }) {
    try {
        const response = yield call(addData, '/updateRdpPasswordAwsInstances/'+instanceId, data);
        if (response.Status) {
            yield put(instancePasswordUpdateSuccessful(response.Message));
            yield put(getInstancesAction()); 
        } else {
            yield put(instancePasswordUpdateFailed(response));
        }

    } catch (error) {
        yield put(instancePasswordUpdateFailed(error));
    }
}

export function* watchPasswordChangeInstance() {
    yield takeEvery(INSTANCES_PASSWORD_UPDATE, passwordChangeInstance)
}


function* startInstances({ payload: data }) {
    try {
        const response = yield call(addData, '/startAwsInstances', data);
        if (response.Status) {
            yield put(instanceStartSuccessful(response.Message));
            yield put(getInstancesAction());
        } else {
            yield put(instanceStartFailed(response));
        }

    } catch (error) {
        yield put(instanceStartFailed(error));
    }
}

export function* watchStartInstance() {
    yield takeEvery(INSTANCES_START, startInstances)
}


function* stopInstances({ payload: data }) {
    try {
        const response = yield call(addData, '/stopAwsInstances', data);
        if (response.Status) {
            yield put(instanceStopSuccessful(response.Message));
            yield put(getInstancesAction());
        } else {
            yield put(instanceStopFailed(response));
        }

    } catch (error) {
        yield put(instanceStopFailed(error));
    }
}

export function* watchStopInstance() {
    yield takeEvery(INSTANCES_STOP, stopInstances)
}


function* getInstances() {
    try {
        const response = yield call(getList, '/getAwsInstances/');
        if (response.Status) {
            yield put(getInstancesSuccessful(response.Instances));
        }else {
            yield put(getInstancesFailed(response));
        }

    } catch (error) {
        yield put(getInstancesFailed(error));
    }
}

export function* watchGetInstance() {
    yield takeEvery(GET_INSTANCES, getInstances)
}

/*******************************************/


function* ec2Instances() {
    yield all([
        fork(watchGetInstance),
        fork(watchStopInstance),
        fork(watchStartInstance),
        fork(watchPasswordChangeInstance),
    ]);
}

export default ec2Instances;