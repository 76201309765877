import { GET_PLAN_WISE_ACTIVATION_REPORT_LIST, GET_PLAN_WISE_ACTIVATION_REPORT_LIST_FAIL, GET_PLAN_WISE_ACTIVATION_REPORT_LIST_SUCCESSFUL } from "./actionTypes";

const initialState = {
    loading: false, planWiseActivationReport: [], apiError: null, message: null
}

const planWiseActivationReport = (state = initialState, action) => {
    switch (action.type) {
        case GET_PLAN_WISE_ACTIVATION_REPORT_LIST:
            state = {
                ...state,
                loading: true,
                apiError: null,
                message: null,
            }
            break;

        case GET_PLAN_WISE_ACTIVATION_REPORT_LIST_SUCCESSFUL:
            state = {
                ...state,
                loading: false,
                planWiseActivationReport: action.payload,
                apiError: null,
                message: null
            }
            break;
        case GET_PLAN_WISE_ACTIVATION_REPORT_LIST_FAIL:
            state = {
                ...state,
                loading: false,
                apiError: action.payload,
                message: null
            }
            break;


        default:
            state = { ...state };
            break;
    }
    return state;
}

export default planWiseActivationReport;