import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { GET_CHANNEL_CONFIG, UPDATE_CHANNEL_CONFIG_DATA } from './actionTypes';
import { getConfigDataSuccessfull, configApiError, updateChannelConfigSuccessfull } from './actions';
import { addData, getList } from '../../helpers/fackBackend_Helper';

function* getConfig({ payload: companyId }) {
    try {
        const response = yield call(getList, '/channelConfig/' + companyId);
        if (response.Status) {
            yield put(getConfigDataSuccessfull(response.config));
        }
        else {
            yield put(configApiError(response));
        }
    } catch (error) {
        yield put(configApiError(error));
    }
}

export function* watchConfig() {
    yield takeEvery(GET_CHANNEL_CONFIG, getConfig)
}

function* updateChannelConfigData({ payload: { updateData,companyId } }) {
    try {
        const response = yield call(addData, '/updateChannelConfig', updateData);
        if (response.Status) {
            const responseList = yield call(getList, '/channelConfig/' + companyId);
            if (responseList.Status) {
                yield put(updateChannelConfigSuccessfull(response.Message, responseList.config));
            } else {
                yield put(configApiError(response));
            }
        }
        else {
            yield put(configApiError(response));
        }
    } catch (error) {
        yield put(configApiError(error));
    }
}

export function* watchUpdateChannelConfig() {
    yield takeEvery(UPDATE_CHANNEL_CONFIG_DATA, updateChannelConfigData)
}

function* channelConfigSaga() {
    yield all([
        fork(watchConfig),
        fork(watchUpdateChannelConfig)
    ]);
}

export default channelConfigSaga;